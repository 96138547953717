import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../../../../../../../reducers/types";
import { setOpenModal } from "../../../../../../actions/actions";
import { ChangeCarlModalContent } from "./component";

const mapStateToProps = (_: IStoreState) => ({});

const mapDispatchToProps = {
  setOpenModal,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type ChangeCarlModalContentConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedChangeCarlModalContent = connector(
  ChangeCarlModalContent
);
