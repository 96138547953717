import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router-dom";
import { IStoreState } from "../../../../reducers/types";
import {
  selectedFlightDisruptionProtectionItinerarySelector,
  selectedFlightDisruptionProtectionItineraryCoverageCapSelector,
  selectedFlightDisruptionProtectionEligibleSliceSelector,
  selectedFlightDisruptionProtectionEligibleSliceDestinationSelector,
  getFetchFlightDisruptionsCallState,
  disruptionOverviewEligibilityDpExerciseFactsPropertiesSelector,
  selectedFlightDisruptionProtectionEligibleTravelItinerarySegmentSelector,
  isSelectingReturnFlightSelector,
  disruptedFlightDpExerciseFactsPropertiesSelector,
  selectedFlightDisruptionProtectionEligibleSegmentSelector,
} from "../../reducer/selectors";
import {
  getAirportMap,
  getAirlinesMap,
} from "../../../TripsList/reducer/selectors";
import { DisruptionProtectionRebook } from "./component";

const mapStateToProps = (state: IStoreState) => {
  return {
    coverageCap:
      selectedFlightDisruptionProtectionItineraryCoverageCapSelector(state),
    eligibleSlice:
      selectedFlightDisruptionProtectionEligibleSliceSelector(state),
    eligibleSegment:
      selectedFlightDisruptionProtectionEligibleSegmentSelector(state),
    destination:
      selectedFlightDisruptionProtectionEligibleSliceDestinationSelector(state),
    fetchFlightDisruptionsCallState: getFetchFlightDisruptionsCallState(state),
    disruptionProtectionItinerary:
      selectedFlightDisruptionProtectionItinerarySelector(state),
    airportMap: getAirportMap(state),
    airlineMap: getAirlinesMap(state),
    disruptionOverviewEligibilityDpExerciseFactsProperties:
      disruptionOverviewEligibilityDpExerciseFactsPropertiesSelector(state),
    disruptedFlightDpExerciseFactsProperties:
      disruptedFlightDpExerciseFactsPropertiesSelector(state),
    selectedFlightDisruptionProtectionEligibleTravelItinerarySegment:
      selectedFlightDisruptionProtectionEligibleTravelItinerarySegmentSelector(
        state
      ),
    isSelectingReturnFlight: isSelectingReturnFlightSelector(state),
  };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type DisruptionProtectionRebookConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedDisruptionProtectionRebook = connector(
  withRouter(DisruptionProtectionRebook)
);
