import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { IStoreState } from "../../../../../../reducers/types";
import {
  fetchPackages,
  populateTripQueryParams,
  setOpenModal,
  setSelectedPackage,
  setSelectedPackageFlight,
  setSelectedPackageHotel,
} from "../../../../actions/actions";
import { PackageCard } from "./component";
import {
  getAirlinesMap,
  getAirportMap,
  getTripsFilter,
} from "../../../../reducer";

const mapStateToProps = (state: IStoreState) => ({
  tripsFilter: getTripsFilter(state),
  airportMap: getAirportMap(state),
  airlineMap: getAirlinesMap(state),
});

const mapDispatchToProps = {
  fetchPackages,
  populateTripQueryParams,
  setOpenModal,
  setSelectedPackageHotel,
  setSelectedPackageFlight,
  setSelectedPackage,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PackageCardConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedPackageCard = connector(withRouter(PackageCard));
