import React, { useEffect } from "react";
import {
  Airline,
  BookedFlightItineraryWithDepartureTime,
  getDepartureSlice,
  TravelItineraryEnum,
  SingleTravelItinerary,
  MultiTravelItinerary,
  VIEWED_CONTACT_MODAL,
} from "redmond";
import { ActionButton, ActionLink } from "halifax";
import { Box, Typography } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CancelFlightModalContentConnectorProps } from "./container";
import * as constants from "./constants";
import "./styles.scss";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { trackEvent } from "../../../../../../../../api/v1/analytics/trackEvent";
import { getViewedCancelFlightContactModalProperties } from "../../../../../../reducer";
import { CONTACT_SUPPORT_URL } from "../../../../../../../../utils/paths";

export interface ICancelFlightModalContentProps
  extends CancelFlightModalContentConnectorProps {
  flight: BookedFlightItineraryWithDepartureTime;
}

export const CancelFlightModalContent = (
  props: ICancelFlightModalContentProps
) => {
  const { setOpenModal, airlineMap, flight } = props;
  const agentCanCancel =
    flight.bookedItinerary.travelItinerary.TravelItinerary ===
    TravelItineraryEnum.SingleTravelItinerary
      ? (flight.bookedItinerary.travelItinerary as SingleTravelItinerary)
          .supportContact === "Hopper"
      : (flight.bookedItinerary.travelItinerary as MultiTravelItinerary)
          .travelItineraries[0].supportContact === "Hopper";

  useEffect(() => {
    if (flight) {
      trackEvent({
        eventName: VIEWED_CONTACT_MODAL,
        properties: {
          ...getViewedCancelFlightContactModalProperties(flight),
          agent_locator_provider: agentCanCancel ? "sabre" : "travelfusion",
        },
      });
    }
  }, [flight]);

  const getAirlineWebLink = (
    flight: BookedFlightItineraryWithDepartureTime,
    airlineMap: { [key: string]: Airline }
  ) => {
    const airline = getDepartureSlice(flight.bookedItinerary).segments[0]
      .marketingAirline.code;
    if (!!airlineMap[airline]) {
      return airlineMap[airline]!.webLinks.manageBooking;
    } else {
      return "";
    }
  };
  const redirectToAirline = (
    flight: BookedFlightItineraryWithDepartureTime
  ) => {
    const redirectUrl = getAirlineWebLink(flight, airlineMap);
    window.open(redirectUrl, "_blank");
    setOpenModal({ type: null, selectedItinerary: null });
  };

  return (
    <Box className="cancel-flight-modal-content sabre-redirect-container">
      <Typography className="cancel-flight-title" variant="h2">
        {constants.HEADING}
      </Typography>
      <Typography className="cancel-flight-subtitle" variant="h5">
        {agentCanCancel
          ? constants.SUBHEADING_SABRE
          : constants.SUBHEADING_TRAVELFUSION}
      </Typography>
      <Box className="cancel-flight-buttons">
        {agentCanCancel ? (
          <ActionButton
            className="cancel-flight-button"
            defaultStyle="h4r-primary"
            message={constants.PRIMARY_ACTION_SABRE}
            onClick={() => {
              window.open(CONTACT_SUPPORT_URL, "_blank")?.focus();
            }}
          />
        ) : (
          <ActionLink
            className="b2b"
            content={
              <>
                {constants.PRIMARY_ACTION_TRAVELFUSION}
                <FontAwesomeIcon icon={faExternalLinkAlt} />
              </>
            }
            onClick={() => {
              redirectToAirline(flight);
            }}
          />
        )}
        <ActionButton
          defaultStyle="h4r-secondary"
          message={constants.CANCEL}
          onClick={() => setOpenModal({ type: null, selectedItinerary: null })}
        />
      </Box>
    </Box>
  );
};
