import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../../../../../../reducers/types";
import {
  deleteWatch,
  setDeleteWatchCallState,
} from "../../../../../actions/actions";
import { getOpenModal, getDeleteWatchCallState } from "../../../../../reducer";
import { StopWatchingModalContent } from "./component";

const mapStateToProps = (state: IStoreState) => ({
  openModal: getOpenModal(state),
  deleteWatchCallState: getDeleteWatchCallState(state),
});

const mapDispatchToProps = {
  deleteWatch,
  setDeleteWatchCallState,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type StopWatchingModalContentConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedStopWatchingModalContent = connector(
  StopWatchingModalContent
);
