export const COULD_NOT_LOAD_CANCELLATION_TITLE =
  "We couldn’t load your flight cancellation information.";
export const COULD_NOT_LOAD_CANCELLATION_SUBTITLE =
  "Please try again or contact support.";

export const CANCELLATION_FAILED_TITLE = "Cancellation Failed";
export const CANCELLATION_FAILED_SUBTITLE =
  "Pleaes contact support for further assistance.";

export const TRY_AGAIN = "Try Again";
export const CONTACT_SUPPORT = "Contact Support";
