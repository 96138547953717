import { Box, Typography } from "@material-ui/core";
import { ButtonWrap, Icon, IconName } from "halifax";
import clsx from 'clsx';
import React, { FC } from "react";
import * as TextConstants from "../textConstants";
import "./../styles.scss";
import {TravelItinerary} from "redmond/build/trips-module";

interface FlightMissedConnectionGuaranteeProps {
  travelItinerary: TravelItinerary;
  isMobile?: boolean;
  onClick: () => void;
}

export const FlightMissedConnectionGuarantee: FC<FlightMissedConnectionGuaranteeProps> = ({
  travelItinerary,
  isMobile = false,
  onClick,
}) => {
  // Virtual Interline Flights will offer only one type of fare the most basic is safe to only check the first index
  const isVITripSelected = travelItinerary.slices.some((slice) =>
    slice.segments.some((segment) => segment.isSelfTransferLayover)
  );

  return <Box className={clsx("flight-missed-connection-wrapper", {mobile: isMobile})}>
    <ButtonWrap
      aria-labelledby="flight-missed-connection-popover"
      onClick={onClick}
    >
      <Icon name={IconName.CheckCircleFilledGreen}/>
      <Typography variant="subtitle2" className="title-wrapper">
        <span className="title" dangerouslySetInnerHTML={{ __html:
          isVITripSelected
            ? TextConstants.LAYOVER_PROTECTED_MISSED_CONNECTION_REBOOKING
            : TextConstants.LAYOVER_PROTECTED_MISSED_CONNECTION_GUARANTEE
        }}/>
      </Typography>
      <Icon name={IconName.InfoCircle} className="info-icon"/>
    </ButtonWrap>
  </Box>
}
