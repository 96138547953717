import axios from "axios";
import {
  CancellationResponse,
  CancellationRequest,
  HotelItinerary,
  SelfServeEvents,
} from "redmond";

import { trackEvent } from "../analytics/trackEvent";
import { config } from "../../config";
import { cancelHotelByReservationPath } from "../paths";

/**
 * @description The first of two endpoints in the self serve cancel hotels flow
 * @param {HotelCancellationInfoRequest} req Contains the reservation id and cancellation policy
 * @param {HotelItinerary} hotel Contains the `provider_name` for better tracking
 * @return {FlightCancelInfoResponse} The cancellation info for the itinerary passed in
 */
const confirmHotelCancellation = (
  req: CancellationRequest,
  hotel: HotelItinerary
): Promise<CancellationResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await axios.post(cancelHotelByReservationPath, req, {
        baseURL: config.baseURL,
      });

      const { Response, errors, value } = res.data;

      // call can succeed with a "Failure" Response so handle both cases
      if (Response === "Success") {
        trackEvent({
          eventName: SelfServeEvents.ResponseSuccess,
          properties: {
            product: "hotel",
            lob: "hotel",
            ...hotel.reservation.trackingPropertiesV2.properties,
          },
          encryptedProperties: [
            hotel.reservation.trackingPropertiesV2.encryptedProperties ?? "",
          ],
        });
        resolve(value);
      } else {
        let errString = "";

        if (typeof errors === "string") {
          errString = errors;
        } else if (Array.isArray(errors)) {
          errString = errors
            .map((err: any) => `${err.code} - ${err.details}`)
            .join(", ");
        }

        trackEvent({
          eventName: SelfServeEvents.ResponseFailure,
          properties: {
            product: "hotel",
            lob: "hotel",
            ...hotel.reservation.trackingPropertiesV2.properties,
            reason: errString,
          },
          encryptedProperties: [
            hotel.reservation.trackingPropertiesV2.encryptedProperties ?? "",
          ],
        });
        reject(errString);
      }
    } catch (e) {
      reject(e);
    }
  });

export default confirmHotelCancellation;
