import React, { useEffect, useMemo } from "react";
import { Box } from "@material-ui/core";
import { useDeviceTypes } from "halifax";
import clsx from "clsx";
import { RouteComponentProps } from "react-router";

import { PriceFreezeConnectorProps } from "./container";
import { HotelPriceFreezeOverview } from "./components/HotelPriceFreezeOverview";
import { RefundPriceFreezeModal } from "./components/RefundPriceFreezeModal";
import { parseQueryString } from "../../utils/queryStringHelpers";
import { PRICE_FREEZE_ID_QUERY_PARAM } from "redmond";

export interface IPriceFreezeProps
  extends RouteComponentProps,
    PriceFreezeConnectorProps {}

export const PriceFreeze = (props: IPriceFreezeProps) => {
  const {
    history,
    isHotelPriceFreezeLodgingNotAvailable,
    setRefundHotelPriceFreezeCallStateNotCalled,
  } = props;
  const { matchesMobile } = useDeviceTypes();

  const hotelBookParsedQuery = useMemo(
    () => parseQueryString(history),
    [history]
  );

  useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0), 0);
  }, [history.location.pathname]);

  useEffect(() => {
    // note: it resets PF refund states when the page will unmount
    return () => {
      setRefundHotelPriceFreezeCallStateNotCalled();
    };
  }, []);

  return (
    <Box
      className={clsx("price-freeze-root", {
        mobile: matchesMobile,
      })}
    >
      <HotelPriceFreezeOverview
        voucherId={hotelBookParsedQuery[PRICE_FREEZE_ID_QUERY_PARAM]}
      />
      {isHotelPriceFreezeLodgingNotAvailable && <RefundPriceFreezeModal />}
    </Box>
  );
};
