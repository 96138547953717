import {
  ItinerarySeats,
  Person,
  TripSegment,
  AncillaryKindEnum,
} from "../apis";
import {
  AdditionalInfo,
  AssociatedPassenger,
  RewardsAmount,
  TransactionStoreId,
} from "../apis/tysons/payment-machine";
import { SegmentShelf } from "../apis/tysons/shop-summary";
import { CoverageOverriding } from "../apis/tysons/ancillaries";
import {
  Coordinates,
  DateTime,
  BaggageInfoEnum,
  PenaltiesInfoEnum,
  Prices,
  Uuid,
  Amount,
  PaymentAmount,
  IPerson,
  FiatAmount,
} from "../common";
import { Restriction, ShopSymbol } from "../flights";
import { ScheduleChange } from "./scheduleChange";
import { uniq } from "lodash";
import { PortalType } from "../capone-corporate";

export interface Adult extends PassengerType {}

export interface AdultPrivate extends PassengerType {}

export interface AdultWithLapInfant {
  adult: AssociatedPassenger;
  infant: AssociatedPassenger;
}

export interface AgeChild extends PassengerType {
  ageCode: string;
}

export interface AgelessChild extends PassengerType {}

export interface AgelessChildPrivate extends PassengerType {}

export interface AgentLocator {
  provider?: string;
  unscopedValue: string;
  value?: string;
}

export interface Airline {
  code: string;
  displayName: string;
  fullName: string;
  phone?: PhoneNumber;
  webLinks: WebLinks;
  customHashTag?: string;
  cabinClassMappings: { [key: string]: string };
  isLcc: boolean;
}

export interface AirlineMap {
  [airlineCode: string]: Airline;
}

export interface Airport {
  name: string;
  code: string;
  geography: GeopoliticalInfo;
  coordinates: Coordinates;
  cityName: string;
  servedCity: string;
  servedCityName: string;
  military: boolean;
}

export interface AirportMap {
  [airportCode: string]: Airport;
}

export interface Allowed extends BaggageInfoBase {
  allowance: BaggageAllowance;
  charges: BaggageCharge[];
}

export interface AmadeusBrand {
  brandId: string;
  brandName?: string;
}

export enum Assistance {
  WCOB = "WCOB",
  WCHS = "WCHS",
  WCBW = "WCBW",
  STCR = "STCR",
  WCHC = "WCHC",
  WCBD = "WCBD",
  BLND = "BLND",
  DEAF = "DEAF",
  WCHR = "WCHR",
  WCMP = "WCMP",
  WCLB = "WCLB",
}

export interface AssociatedContact {
  nameNumber: NameNumber;
  contact: Contact;
}

export interface BaggageAllowance {
  pieces: number;
  descriptions: string[];
  maximumMass?: Mass;
}

export interface BaggageCharge {
  amount: number;
  currency: string;
  firstPiece: number;
  lastPiece: number;
  descriptions: string[];
  maximumMass?: Mass;
}

export type BaggageInfo = Allowed | NotAllowed | Unknown;

export interface BaggageInfoBase {
  BaggageInfo: BaggageInfoEnum;
}

export enum MultiTicketTypeEnum {
  Single = "single",
  HackerFare = "hackerFare",
  VI = "virtualInterline",
}

export enum PriceDropProtectionEnum {
  IsEligible = "IsEligible",
  NotEligible = "NotEligible",
}

export interface InsuranceId {
  productId: string;
}

export interface CfarId {
  productId: string;
  policyId: string;
  quoteId?: string;
}

export interface MissedConnectionId {
  productId: string;
  policyId: string;
}

export interface ChfarId {
  productId: string;
  policyId: string;
}

export interface CancelOrChangeId {
  productId: string;
  policyId: string;
}

export interface DelayId {
  productId: string;
  policyId: string;
  quoteId?: string;
}

export interface LuggageId {
  productId: string;
}

export interface TripAncillaryIds {
  insuranceId?: InsuranceId;
  cfarId?: CfarId;
  missedConnectionId?: MissedConnectionId;
  chfarId?: ChfarId;
  cancelOrChangeId?: CancelOrChangeId;
  delayId?: DelayId;
  luggageId?: LuggageId;
}

export interface BookedFlightItinerary {
  additionalInfo?: AdditionalInfo;
  id: string;
  faresRules: FareRules;
  totalPayment: PaymentAmount;
  sellingPricing: TripPricingSummary;
  restrictions?: Restrictions;
  passengers: SeatedPassengers;
  payment?: Payment;
  travelItinerary: TravelItinerary;
  freeCancellation: Availability;
  scheduleChange?: ScheduleChange;
  multiTicketType: MultiTicketTypeEnum;
  priceDropEligibility: PriceDropProtectionEnum;
  paymentBreakdown: PaymentBreakdown;
  seats?: ItinerarySeats;
  transactionStoreId?: TransactionStoreId;
  tripAncillaries: TripAncillaryIds;
  source?: ItinerarySource;

  /**
   * @deprecated Use `paymentBreakdown` instead
   */
  paymentAmountInfo: IPaymentAmountInfo;
}

export interface ItinerarySource {
  sequenceNumber?: number; // Monotonically indicates revisions to the PNR; may not be available on all providers
  created: DateTime; // Indicate the booking creation time.
  updated: DateTime; // If the booking is never updated, this should have the same value as `created`
}

export interface PaymentBreakdown {
  payments: PaymentLineItem[];
}

export type PaymentLineItem =
  | PaymentLineItemUserCard
  | PaymentLineItemRewards
  | PaymentLineItemAncillaryCredit
  | PaymentLineItemTravelWallet;

export enum PaymentLineItemEnum {
  UserCard = "UserCard",
  Rewards = "Rewards",
  AncillaryCredit = "AncillaryCredit",
  TravelWallet = "TravelWallet",
}

export interface PaymentLineItemUserCard {
  cardNumberDisplay: string;
  amount: Amount;
  PaymentLineItem: PaymentLineItemEnum;
}

export interface PaymentLineItemRewards {
  accountDisplayName: string;
  amount: RewardsAmount;
  PaymentLineItem: PaymentLineItemEnum;
}

export type PaymentLineItemAncillaryCredit =
  | PaymentLineItemPriceFreezeCredit
  | HotelPriceFreezeDepositCredit
  | HotelPriceFreezeDiscountCredit
  | HotelPriceFreezeExerciseCredit;

export enum PaymentLineItemAncillaryCreditEnum {
  PriceFreezeCredit = "PriceFreezeCredit",
  HotelPriceFreezeDepositCredit = "HotelPriceFreezeDepositCredit",
  HotelPriceFreezeDiscountCredit = "HotelPriceFreezeDiscountCredit",
  HotelPriceFreezeExerciseCredit = "HotelPriceFreezeExerciseCredit",
}

export interface PaymentLineItemPriceFreezeCredit {
  amount: Amount;
  PaymentLineItem: PaymentLineItemEnum.AncillaryCredit;
  AncillaryCredit: PaymentLineItemAncillaryCreditEnum.PriceFreezeCredit;
}

export interface HotelPriceFreezeDepositCredit {
  amount: Amount;
  PaymentLineItem: PaymentLineItemEnum.AncillaryCredit;
  AncillaryCredit: PaymentLineItemAncillaryCreditEnum.HotelPriceFreezeDepositCredit;
}

export interface HotelPriceFreezeDiscountCredit {
  amount: Amount;
  PaymentLineItem: PaymentLineItemEnum.AncillaryCredit;
  AncillaryCredit: PaymentLineItemAncillaryCreditEnum.HotelPriceFreezeDiscountCredit;
}

export interface HotelPriceFreezeExerciseCredit {
  amount: Amount;
  PaymentLineItem: PaymentLineItemEnum.AncillaryCredit;
  AncillaryCredit: PaymentLineItemAncillaryCreditEnum.HotelPriceFreezeExerciseCredit;
}

export type PaymentLineItemTravelWallet =
  | PaymentLineItemStatementCredit
  | PaymentLineItemTravelWalletCredit
  | PaymentLineItemTravelWalletOffer;

export enum PaymentLineItemTravelWalletEnum {
  TravelWalletOffer = "TravelWalletOffer",
  TravelWalletCredit = "TravelWalletCredit",
}

export interface PaymentLineItemTravelWalletOffer {
  amount: Amount;
  PaymentLineItem: PaymentLineItemEnum.TravelWallet;
  TravelWallet: PaymentLineItemTravelWalletEnum.TravelWalletOffer;
  percentage?: number;
}

export interface TravelWalletCreditDetail {
  amount: FiatAmount;
  description: string;
}

export interface PaymentLineItemTravelWalletCredit {
  amount: Amount;
  breakdown?: TravelWalletCreditDetail[];
  PaymentLineItem: PaymentLineItemEnum.TravelWallet;
  TravelWallet: PaymentLineItemTravelWalletEnum.TravelWalletCredit;
}

export interface PaymentLineItemStatementCredit {
  amount: Amount;
  PaymentLineItem: PaymentLineItemEnum.TravelWallet;
  TravelWallet: PaymentLineItemTravelWalletEnum.TravelWalletCredit;
}

export type IPaymentAmountInfo =
  | FiatAmountInfo
  | RewardsAmountInfo
  | SplitAmountInfo;

export type Credit = OfferCredit | PriceFreezeExerciseCredit;

export enum CreditEnum {
  Offer = "Offer",
  PriceFreezeExerciseCredit = "PriceFreezeExerciseCredit",
}
export interface OfferCredit {
  amount: Amount;
  Credit: CreditEnum.Offer;
}

export interface PriceFreezeExerciseCredit {
  amount: Amount;
  Credit: CreditEnum.PriceFreezeExerciseCredit;
}

export enum PaymentTypeEnum {
  FiatAmountInfo = "FiatAmountInfo",
  RewardsAmountInfo = "RewardsAmountInfo",
  SplitAmountInfo = "SplitAmountInfo",
}
export interface FiatAmountInfo {
  PaymentAmountInfo: PaymentTypeEnum.FiatAmountInfo;
  numberDisplay: string;
  amount: Amount;
  credits: Credit[];
}

export interface RewardsAmountInfo {
  PaymentAmountInfo: PaymentTypeEnum.RewardsAmountInfo;
  accountDisplayName: string;
  amount: Omit<RewardsAmount, "PaymentAmount">;
  credits: Credit[];
}

export interface SplitAmountInfo {
  PaymentAmountInfo: PaymentTypeEnum.SplitAmountInfo;
  fiatInfo: {
    numberDisplay: string;
    amount: Amount;
  };
  rewardsInfo: {
    accountDisplayName: string;
    amount: Omit<RewardsAmount, "PaymentAmount">;
  };
  credits: Credit[];
}

export type Payment = CreditCard;

export enum PaymentEnum {
  CreditCard = "CreditCard",
}

export type Availability =
  | Available
  | AvailableAfter
  | AvailableUntil
  | Unavailable;

export enum AvailabilityEnum {
  Available,
  AvailableAfter,
  AvailableUntil,
  Unavailable,
}

export enum Reason {
  AlreadyDone,
  ContactAirline,
  ContactSupport,
  NotAllowed,
}
export interface BaseAvailability {
  Availability: AvailabilityEnum;
}

export interface Unavailable extends BaseAvailability {
  reason: Reason;
}

export interface Available extends BaseAvailability {}

export interface AvailableAfter extends BaseAvailability {
  time: string;
}

export interface AvailableUntil extends BaseAvailability {
  time: string;
}

export enum PortalItineraryStatusEnum {
  Canceled = "Canceled",
  Confirmed = "Confirmed",
  Modified = "Modified",
  Pending = "Pending",
}

export interface VoidExtension {
  days: number;
  at: string;
}

export interface CfarOfferPolicyData {
  premiumPercentage?: number;
  cashCoveragePercentage: number;
  offerType: string;
}

export interface CfarContract {
  id: CfarId;
  voidExtension?: VoidExtension;
  created: string;
  expired: string;
  policyData?: CfarOfferPolicyData;
}

export interface MinimumDelay {
  minutes: number;
}

export interface DelayPolicyDetails {
  premium: Amount;
  coverage?: Amount;
  minimumDelay: MinimumDelay;
}

export interface DelayContract {
  id: DelayId;
  created: string;
  status: DisruptionContractStatus;
  policyDetails?: DelayPolicyDetails;
  postBookingPaymentId?: string;
  paxCount?: number;
}

export type DisruptionContractStatus =
  | DisruptionContractStatusPurchase
  | DisruptionContractStatusRebook
  | DisruptionContractStatusRefund
  | DisruptionContractStatusRemove
  | DisruptionContractStatusCancel;

export enum DisruptionContractStatusEnum {
  Purchase = "Purchase",
  Rebook = "Rebook",
  Refund = "Refund",
  Remove = "Remove",
  Cancel = "Cancel",
}

export interface DisruptionContractStatusPurchase {
  DisruptionContractStatus: DisruptionContractStatusEnum.Purchase;
}

export interface DisruptionContractStatusRebook {
  DisruptionContractStatus: DisruptionContractStatusEnum.Rebook;
}

export interface DisruptionContractStatusRefund {
  DisruptionContractStatus: DisruptionContractStatusEnum.Refund;
}

export interface DisruptionContractStatusRemove {
  DisruptionContractStatus: DisruptionContractStatusEnum.Remove;
}

export interface DisruptionContractStatusCancel {
  DisruptionContractStatus: DisruptionContractStatusEnum.Cancel;
}

export enum PolicyDetailsEnum {
  FixedPolicyDetails = "FixedPolicyDetails",
  PercentagePolicyDetails = "PercentagePolicyDetails",
}

export interface FixedPolicyDetails {
  premium: Amount;
  coverage: Amount;
  PolicyDetails: PolicyDetailsEnum.FixedPolicyDetails;
}

export interface PercentagePolicyDetails {
  premium: number;
  coverage: number;
  PolicyDetails: PolicyDetailsEnum.PercentagePolicyDetails;
}

export type MissedConnectionPolicyDetails =
  | FixedPolicyDetails
  | PercentagePolicyDetails;

export interface MissedConnectionContract {
  id: MissedConnectionId;
  created: string;
  status: DisruptionContractStatus;
  policyDetails?: MissedConnectionPolicyDetails;
  postBookingPaymentId?: string;
  paxCount?: number;
}

export interface TripAncillaryContracts {
  cfar?: CfarContract;
  delay?: DelayContract;
  missedConnection?: MissedConnectionContract;
  missedConnectionVi?: MissedConnectionContract;
}

export enum DisruptionProtectionRebookEnum {
  NotRebook = "NotRebook",
  IsRebook = "IsRebook",
}

export type DisruptionProtectionRebook =
  | DisruptionProtectionRebookIsRebook
  | DisruptionProtectionRebookNotRebook;

export interface DisruptionProtectionRebookIsRebook {
  DisruptionProtectionRebook: DisruptionProtectionRebookEnum.IsRebook;
  originalItineraryId: string;
}

export interface DisruptionProtectionRebookNotRebook {
  DisruptionProtectionRebook: DisruptionProtectionRebookEnum.NotRebook;
}

export interface BookedFlightItineraryWithDepartureTime {
  bookedItinerary: BookedFlightItinerary;
  departureTime?: DateTime;
  emailAddress?: string;
  hoursToDeparture?: number;
  status: PortalItineraryStatusEnum;
  travelCredit?: TravelCredit;
  travelCreditCopy?: string;
  ancillaries: TripAncillaryContracts;
  disruptionProtectionRebook?: DisruptionProtectionRebook;
  portalType?: PortalType;
}

export interface Canceled {
  PostTicketingStatus: PostTicketingStatus;
}

export interface Cancelled extends FlightItinerarySegmentStatus {}
export interface Confirmed extends FlightItinerarySegmentStatus {}

export interface ConfirmedPendingNewChange
  extends FlightItinerarySegmentStatus {}

export interface Contact {
  phoneNumber: string;
  emailAddress: string;
  givenName?: string;
  surname?: string;
}

export interface CountryCode {
  code: string;
}

export interface CreditCard {
  id: string;
  token: string;
  firstName: string;
  lastName: string;
  cardType: string;
  numberDisplay: string;
  month: number;
  year: number;
  zip: string;
  country: string;
  alias?: string;
  createdAt: string;
  updatedAt?: string;
  scanned: string;
  last4?: string;
  bin?: string;
  Payment: PaymentEnum;
}

export interface Declined extends FlightItinerarySegmentStatus {}

export interface DriverLicense {
  country: CountryCode;
  cityCode: string;
  street: string;
  postCode: string;
}

export interface ExternallyManaged extends PenaltiesInfo {
  code: string;
}

export interface FareBasis {
  code: string;
}

export interface FareBrand {
  sabreFareBrand?: SabreBrand;
  travelportFareBrand?: TravelportBrand;
  gdxFareBrand?: GdxBrand;
  amadeusFareBrand?: AmadeusBrand;
  travelFusionFareBrand?: TravelFusionBrand;
}

export interface FareRule {
  title: string;
  text: string;
}

export interface FareRuleIndex {
  fareBasisCode: FareBasis;
  segment: number;
}

export interface FareRules {
  rules: ItineraryFareRules[];
}

export interface FlightItineraryRequest {
  states: FlightItineraryState[];
  referenceDateTime: string;
}

export interface FlightItineraryResponse {
  itineraries: { [key: string]: BookedFlightItineraryWithDepartureTime[] };
  airports: { [key: string]: Airport };
  airlines: { [key: string]: Airline };
}

export interface SingleFlightItineraryRequest {
  itineraryId: string;
  referenceDateTime: string;
}

export interface TripContext {
  airports: AirportMap;
  airlines: AirlineMap;
}

export interface SingleFlightItineraryResponse {
  itinerary: BookedFlightItineraryWithDepartureTime;
  context: TripContext;
}

export interface FlightItinerarySegment {
  bookingClassCode: string;
  cabinClassName?: string;
  codeSharedCarrier?: string;
  destination: Location;
  destinationTimeZone: string;
  elapsedTime: number;
  equipmentType?: string;
  fareBrand?: FareBrand;
  fareCode?: string;
  marketingAirline: SegmentAirlineInfo;
  operatingAirline?: SegmentAirlineInfo;
  origin: Location;
  originTimeZone: string;
  plusDays?: number;
  scheduledArrival: string;
  scheduledDeparture: string;
  segmentNumber: number;
  status: FlightItinerarySegmentStatusEnum;
  stopoverDurationMinutes?: number;
  stops: number;
  ticketIndex?: number;
  updatedArrival: string;
  updatedDeparture: string;
  zonedScheduledArrival?: string;
  zonedScheduledDeparture?: string;
  zonedUpdatedArrival?: string;
  zonedUpdatedDeparture?: string;
  isSelfTransferLayover?: boolean;
}

export interface FlightItinerarySegmentStatus {
  FlightItinerarySegmentStatus: FlightItinerarySegmentStatusEnum;
}

export enum FlightItinerarySegmentStatusEnum {
  Confirmed = "Confirmed",
  UnMapped = "UnMapped",
  UnMappedPersisted = "UnMappedPersisted",
  Pending = "Pending",
  Declined = "Declined",
  ConfirmedPendingNewChange = "ConfirmedPendingNewChange",
  Cancelled = "Cancelled",
  Canceled = "Canceled",
}

export interface FlightItinerarySlice {
  segments: FlightItinerarySegment[];
  restrictions?: Restrictions;
  fareShelf?: SegmentShelf;
}

export enum FlightItineraryState {
  Canceled = "Canceled",
  Past = "Past",
  Future = "Future",
  Invalid = "Invalid",
  Present = "Present",
}

export interface FrequentFlyerNumber {
  value: string;
}

export interface GdxBrand {
  brandId: string;
  brandName?: string;
}

export enum Gender {
  F = "F",
  M = "M",
}

export interface GeopoliticalInfo {
  countryCode: CountryCode | string;
  regionCode?: string;
  currency: string;
  timeZone: string;
}

export interface InfantNotIssued extends TicketNumberType {}

export interface ItineraryFareRules {
  fareRuleIndex: FareRuleIndex;
  rules: FareRule[];
}

export interface Known extends PenaltiesInfo {
  changePenalty: Penalty;
  cancelPenalty: Penalty;
}

export interface LapInfant extends PassengerType {}

export interface LapInfantPrivate extends PassengerType {}

export interface Location {
  locationCode: string;
  terminalName?: string;
  terminalCode?: string;
}

export interface Mass {
  value: number;
  unit: string;
}

export interface Modified {
  PostTicketingStatus: PostTicketingStatus;
}

export interface MultiProvider {
  agent: AgentLocator;
  b2b: string;
  children: SingleProvider[];
}

export type TravelItinerary = MultiTravelItinerary | SingleTravelItinerary;

export interface TravelItineraryBase {
  TravelItinerary: TravelItineraryEnum;
  hasEmbeddedRoundTrip: boolean;
  slices: FlightItinerarySlice[];
}

export interface MultiTravelItinerary extends TravelItineraryBase {
  locators?: MultiProvider;
  travelItineraries: SingleTravelItinerary[];
  TravelItinerary: TravelItineraryEnum.MultiTravelItinerary;
}

export interface NameNumber {
  value: string;
}

export interface Nationality {
  country: CountryCode;
}

export interface NoPenalty extends Penalty {}

export interface NotAllowed extends BaggageInfoBase {}

export interface NotPossible extends Penalty {}

export interface PassengerAncillaryPricing {
  kind: AncillaryKindEnum;
  premium: Prices;
  coverageAmount?: Amount;
  coverageOverriding?: CoverageOverriding;
}

export interface PassengerTripPricing {
  currency: string;
  baseWithoutMargin: Prices;
  taxes: Prices;
  passengerFee: Prices;
  passengerType: PassengerTypeEnum;
  additionalMargin?: Prices;
  ancillaries: PassengerAncillaryPricing[];
  person: Person;
  seats: Seat[];
  subtotal: Prices;
  total: Prices;
}

export interface PassengerType {
  PassengerType: PassengerTypeEnum;
}

export enum PassengerTypeEnum {
  AgelessChild = "AgelessChild",
  LapInfantPrivate = "LapInfantPrivate",
  LapInfant = "LapInfant",
  SeatedInfant = "SeatedInfant",
  AgelessChildPrivate = "AgelessChildPrivate",
  AdultPrivate = "AdultPrivate",
  AgeChild = "AgeChild",
  Adult = "Adult",
  SeatedInfantPrivate = "SeatedInfantPrivate",
}

export interface Passport {
  countryOfIssue: CountryCode;
  number: string;
  expiration: string;
}

export interface PenaltiesInfo {
  PenaltiesInfo: PenaltiesInfoEnum;
}

export interface Penalty {
  Penalty: PenaltyEnum;
}

export enum PenaltyEnum {
  NoPenalty = "NoPenalty",
  NotPossible = "NotPossible",
  Unknown = "Unknown",
  WithFee = "WithFee",
}

export interface Pending extends FlightItinerarySegmentStatus {}

export interface PhoneNumber {
  value: string;
}

export enum PostTicketingStatus {
  Canceled = "Canceled",
  Modified = "Modified",
  Rejected = "Rejected",
  Ticketed = "Ticketed",
}

export interface PurchaseTimeDiscount {
  discount: Prices;
  lineItemName: string;
}

export interface Rejected {
  PostTicketingStatus: PostTicketingStatus;
}

export interface Restrictions {
  penaltiesInfo: PenaltiesInfo;
  baggageInfo: BaggageInfo;
  seatMapInfoAvailable: boolean;
}

export interface SabreBrand {
  brandId: string;
  brandName?: string;
}

export interface Seat {
  amount: Amount;
  id: string;
}

export interface SeatedInfant extends PassengerType {}

export interface SeatedInfantPrivate extends PassengerType {}

export interface SeatedPassengers {
  withLapInfants: AdultWithLapInfant[];
  alone: AssociatedPassenger[];
  contact: AssociatedContact;
}

export interface SegmentAirlineInfo {
  code: string;
  flightNumber: number;
  locator?: string;
}

export interface SingleProvider {
  agent: AgentLocator;
  b2b: string;
}

export interface SingleTravelItinerary extends TravelItineraryBase {
  locators?: SingleProvider;
  slices: FlightItinerarySlice[];
  ticketing: Ticketing[];
  postTicketingStatus?: PostTicketingStatus;
  supportContact: string;
  TravelItinerary: TravelItineraryEnum.SingleTravelItinerary;
}

export interface TicketNumber extends TicketNumberType {
  number: string;
}

export interface TicketNumberType {
  TicketNumberType: TicketNumberTypeEnum;
  number?: string;
}

export enum TicketNumberTypeEnum {
  InfantNotIssued = "InfantNotIssued",
  TicketNumber = "TicketNumber",
}

export interface Ticketed {
  PostTicketingStatus: PostTicketingStatus;
}

export interface Ticketing {
  eTicketNumber: TicketNumberType;
}

export interface TotalAncillaryPricing {
  kind: AncillaryKindEnum;
  premium: Prices;
}

export interface TotalTripPricing {
  currency: string;
  baseWithoutMargin: Prices;
  taxes: Prices;
  passengerFee: Prices;
  additionalMargin?: Prices;
  ancillaries: TotalAncillaryPricing[];
  discounts: PurchaseTimeDiscount[];
  tripFee: Prices;
  total: Prices;
  subtotal: Prices;
  seats: Seat[];
}

export interface TravelCredit {
  credit: Amount;
  id: Uuid;
  penalty?: Amount;
  rebookDeadline?: DateTime;
  route: Location[];
  status: TravelCreditStatus;
  travelDeadline?: DateTime;
}

export enum TravelCreditStatus {
  Available = "Available",
  Expired = "Expired",
  Redeemed = "Redeemed",
}

export enum TravelItineraryEnum {
  MultiTravelItinerary = "MultiTravelItinerary",
  SingleTravelItinerary = "SingleTravelItinerary",
}

export interface TravelFusionBrand {
  brandId: string;
  brandName?: string;
}

export interface TravelportBrand {
  brandId: string;
  brandName?: string;
  brandTier?: number;
}

export interface TripPricingSummary {
  totalPricing: TotalTripPricing;
  pricingByPassenger: PassengerTripPricing[];
}

export interface UnMapped extends FlightItinerarySegmentStatus {}

export interface UnMappedPersisted extends FlightItinerarySegmentStatus {}

export interface Unknown extends PenaltiesInfo {}

export interface Unknown extends BaggageInfoBase {}

export interface Unknown extends Penalty {}

export interface WebLinks {
  homePage?: string;
  baggageFees?: string;
  wifiFee?: string;
  otherFee?: string;
  seatSelection?: string;
  seatUpgrade?: string;
  manageBooking?: string;
  checkInPolicy?: string;
  changeCancelPolicy?: string;
}

export interface WithFee extends Penalty {
  amount: number;
  currency: string;
}

export const getTripSegmentsFromItinerarySegment = ({
  segment,
  airportMap,
  airlineMap,
  useCityName,
}: {
  segment: FlightItinerarySegment;
  airportMap: { [key: string]: Airport };
  airlineMap: { [key: string]: Airline };
  useCityName?: boolean;
}): TripSegment => {
  const origin = airportMap[segment.origin.locationCode];
  const destination = airportMap[segment.destination.locationCode];

  return {
    cabinClassName: segment.cabinClassName,
    marketingAirline: {
      name: segment.marketingAirline
        ? airlineMap[segment.marketingAirline?.code]?.displayName || ""
        : "",
      code: segment.marketingAirline?.code || "",
    },
    operatingAirline: {
      name: segment.operatingAirline
        ? airlineMap[segment.operatingAirline?.code]?.displayName || ""
        : "",
      code: segment.operatingAirline?.code || "",
    },
    airlineCode: segment.marketingAirline.code,
    airlineName: airlineMap[segment.marketingAirline.code]?.displayName || "",
    flightNumber: `${segment.marketingAirline.flightNumber}`,
    departureTime:
      segment.zonedUpdatedDeparture ||
      segment.zonedScheduledDeparture ||
      segment.scheduledDeparture,
    originCode: segment.origin.locationCode,
    originName: (useCityName ? origin?.cityName : origin?.name) ?? "",
    arrivalTime:
      segment.zonedUpdatedArrival ||
      segment.zonedScheduledArrival ||
      segment.scheduledArrival,
    destinationCode: segment.destination.locationCode,
    destinationName:
      (useCityName ? destination?.cityName : destination?.name) ?? "",
    stops: segment.stops,
    stopoverDurationMinutes: segment.stopoverDurationMinutes,
    isSelfTransferLayover: segment.isSelfTransferLayover,
    plusDays: segment.plusDays,
  };
};

export const getTripSegmentsFromItinerarySegments = ({
  segments,
  airportMap,
  airlineMap,
  useCityName,
}: {
  segments: FlightItinerarySegment[];
  airportMap: { [key: string]: Airport };
  airlineMap: { [key: string]: Airline };
  useCityName?: boolean;
}): TripSegment[] => {
  return segments.map((segment) => {
    return getTripSegmentsFromItinerarySegment({
      segment,
      airportMap,
      airlineMap,
      useCityName,
    });
  });
};

export const getPlusDaysFromItinerarySegments = (
  segments: (FlightItinerarySegment | TripSegment)[]
): number => {
  return segments
    .filter((s) => s.plusDays && s.plusDays > 0)
    .reduce((total, segment) => total + (segment.plusDays ?? 0), 0);
};

export const getPlusDays = (trip: FlightItinerarySlice): number => {
  // TODO: confirm with the BE to see whether this is the only way to get plus-days
  return getPlusDaysFromItinerarySegments(trip.segments);
};

export const getItinerarySummaryProps = (
  flight: BookedFlightItineraryWithDepartureTime,
  isOutgoing: boolean,
  airportMap: { [key: string]: Airport },
  airlineMap: { [key: string]: Airline },
  multicitySliceIndex?: number
) => {
  let tripSlice;
  if (multicitySliceIndex !== undefined) {
    const slices = getSlicesFromTravelItinerary(
      flight.bookedItinerary.travelItinerary
    );
    tripSlice = slices[multicitySliceIndex];
  } else {
    tripSlice = isOutgoing
      ? getDepartureSlice(flight.bookedItinerary)
      : getReturnSlice(flight.bookedItinerary);
  }

  return getItinerarySummaryPropsFromSlice(tripSlice!, airportMap, airlineMap);
};

export const getItinerarySummaryPropsFromSlice = (
  tripSlice: FlightItinerarySlice,
  airportMap: { [key: string]: Airport },
  airlineMap: { [key: string]: Airline }
) => {
  const plusDays = getPlusDays(tripSlice!);
  return {
    tripSlice,
    segments: getTripSegmentsFromItinerarySegments({
      segments: tripSlice.segments,
      airportMap,
      airlineMap,
    }),
    departureTime:
      tripSlice.segments[0]?.zonedUpdatedDeparture ||
      tripSlice.segments[0]?.zonedScheduledDeparture ||
      tripSlice.segments[0]?.scheduledDeparture ||
      "",
    // TODO find out where to get planeInfo and fareClass info
    planeInfo: "",
    fareClass: tripSlice.fareShelf?.shortBrandName || "",
    plusDays,
    arrivalTime:
      tripSlice.segments[0]?.zonedUpdatedArrival ||
      tripSlice.segments[0]?.zonedScheduledArrival ||
      tripSlice.segments[0]?.scheduledArrival ||
      "",
  };
};

export const getSlicesFromTravelItinerary = (
  travelItinerary: TravelItinerary
): FlightItinerarySlice[] => {
  switch (travelItinerary?.TravelItinerary) {
    case TravelItineraryEnum.SingleTravelItinerary:
      return travelItinerary.slices;
    case TravelItineraryEnum.MultiTravelItinerary:
      return travelItinerary.travelItineraries.flatMap((itinerary) => {
        return itinerary.slices;
      });
  }
};

export const getDepartureSliceFromTravelItinerary = (
  travelItinerary: TravelItinerary
): FlightItinerarySlice => {
  return getSlicesFromTravelItinerary(travelItinerary)[0];
};

export const isViMultiTicket = (multiTicketType: MultiTicketTypeEnum) => {
  return (
    multiTicketType === MultiTicketTypeEnum.VI ||
    multiTicketType === MultiTicketTypeEnum.HackerFare
  );
};

export const getSliceFromViMultiTicket = (
  bookedItinerary: BookedFlightItinerary,
  sliceIndex: number
) => {
  return bookedItinerary.travelItinerary.slices[sliceIndex];
};

//  TODO show changed itinerary times if a minor schedule change exists
export const getDepartureSlice = (
  bookedItinerary: BookedFlightItinerary
): FlightItinerarySlice => {
  if (isViMultiTicket(bookedItinerary.multiTicketType)) {
    return getSliceFromViMultiTicket(bookedItinerary, 0);
  }
  return getDepartureSliceFromTravelItinerary(bookedItinerary.travelItinerary);
};

export const getItineraryDepartureDate = (itinerary: TravelItinerary) => {
  const firstSegment =
    getDepartureSliceFromTravelItinerary(itinerary).segments[0];
  return firstSegment.zonedUpdatedDeparture ?? firstSegment.updatedDeparture;
};

export const getReturnSliceFromTravelItinerary = (
  travelItinerary: TravelItinerary
): FlightItinerarySlice | undefined => {
  const slices = getSlicesFromTravelItinerary(travelItinerary);
  if (slices.length === 2) return slices[1];
  else return undefined;
};

// fetch the final slice (leg) the user will travel on. if the itinerary only
// contains one slice then return `undefined`
export const getLastSliceFromTravelItinerary = (
  travelItinerary: TravelItinerary
): FlightItinerarySlice | undefined => {
  const slices = getSlicesFromTravelItinerary(travelItinerary);
  if (slices.length > 1) return slices[slices.length - 1];
  else return undefined;
};

export const getReturnSlice = (
  bookedItinerary: BookedFlightItinerary
): FlightItinerarySlice | undefined => {
  if (isViMultiTicket(bookedItinerary.multiTicketType)) {
    return getSliceFromViMultiTicket(bookedItinerary, 1);
  }
  return getReturnSliceFromTravelItinerary(bookedItinerary.travelItinerary);
};

// fetch the final slice (leg) the user will travel on. if the itinerary only
// contains one slice then return `undefined`
export const getLastSlice = (
  bookedItinerary: BookedFlightItinerary
): FlightItinerarySlice | undefined => {
  return getLastSliceFromTravelItinerary(bookedItinerary.travelItinerary);
};

export const getMulticitySlice = (
  bookedItinerary: BookedFlightItinerary,
  sliceIndex: number
) => {
  const travelItinerary =
    bookedItinerary.travelItinerary as SingleTravelItinerary;
  return travelItinerary.slices[sliceIndex];
};

export const getItineraryReturnDate = (itinerary: TravelItinerary) => {
  const firstSegment =
    getReturnSliceFromTravelItinerary(itinerary)?.segments[0];
  return firstSegment?.zonedUpdatedDeparture ?? firstSegment?.updatedDeparture;
};

export const getAirportCodesFromSlice = (slice: FlightItinerarySlice) => {
  const sliceOriginAirportCode = slice.segments[0]?.origin.locationCode;
  const sliceDestinationAirportCode =
    slice.segments[slice.segments.length - 1].destination.locationCode;
  return [sliceOriginAirportCode, sliceDestinationAirportCode];
};

// true if the itinerary is currently considered a multi-city itinerary, and not categorized as
// oneway or round trip. If a trip was originally booked as a multi-city itinerary but then had
// leg cancellations, it can switch to be considered a oneway or even round trip, itinerary.
// 2-slice multicity trips where the first slice origin city matches the second slice destination city
// and where the first slice destination city matches second slice origin city are treated as round trip itineraries.
// If the multiticketType is VI, we should still treat this itinerary as roundtrip
export const isMultiCityItinerary = (
  bookedItinerary: BookedFlightItinerary,
  airportMap: AirportMap
): boolean => {
  if (bookedItinerary.multiTicketType === MultiTicketTypeEnum.VI) return false;
  const slices = getSlicesFromTravelItinerary(bookedItinerary.travelItinerary);
  if (slices.length < 2) return false;
  if (slices.length === 2) {
    const departureSlice = getDepartureSlice(bookedItinerary);
    const returnSlice = getReturnSlice(bookedItinerary);
    if (!returnSlice) return false;

    const [slice1OriginAirportCode, slice1DestinationAirportCode] =
      getAirportCodesFromSlice(departureSlice);
    const [slice2OriginAirportCode, slice2DestinationAirportCode] =
      getAirportCodesFromSlice(returnSlice!);

    const slice1OriginServedCityName =
      airportMap[slice1OriginAirportCode].servedCityName;
    const slice1DestinationServedCityName =
      airportMap[slice1DestinationAirportCode].servedCityName;
    const slice2OriginServedCityName =
      airportMap[slice2OriginAirportCode].servedCityName;
    const slice2DestinationServedCityName =
      airportMap[slice2DestinationAirportCode].servedCityName;

    const matchedCities =
      slice1OriginServedCityName === slice2DestinationServedCityName &&
      slice1DestinationServedCityName === slice2OriginServedCityName;

    return !matchedCities;
  }
  return true;
};

export const getUniqueCitiesFromTripSlices = (
  slices: FlightItinerarySlice[],
  airportMap: AirportMap
) => {
  const allLocations = slices.reduce((locations: string[], slice) => {
    const firstSegment = slice.segments[0];
    const lastSegment = slice.segments[slice.segments.length - 1];

    const originCity =
      airportMap[firstSegment.origin.locationCode].servedCityName;
    const destinationCity =
      airportMap[lastSegment.destination.locationCode].servedCityName;

    return locations.concat([originCity, destinationCity]);
  }, []);
  return uniq(allLocations);
};

export const getTruncatedCityList = (cities: string[]) => {
  const firstThree = cities.slice(0, 3);
  return firstThree.join(", ") + (cities.length > 3 ? "..." : "");
};

export const getFlightInfoDetails = (
  locators: MultiProvider | SingleProvider | undefined,
  departureSlice: FlightItinerarySlice,
  returnSlice: FlightItinerarySlice | undefined,
  formatDate: (date: string) => string,
  airports: { [key: string]: Airport },
  airlines: { [key: string]: Airline }
) => {
  // Gets the last segment to avoid using the layover destinationCode
  const departureLastSegmentIndex = departureSlice
    ? departureSlice.segments.length - 1
    : 0;
  const departureDestinationSegment = departureSlice
    ? departureSlice.segments[departureLastSegmentIndex]
    : undefined;
  const destinationCode =
    departureDestinationSegment?.destination.locationCode ?? "";

  const firstLegDeparture = departureSlice?.segments[0];
  const firstLegReturn = returnSlice ? returnSlice.segments[0] : null;
  const originCode = firstLegDeparture?.origin.locationCode ?? "";
  const airlineCode = firstLegDeparture?.marketingAirline.code ?? "";
  return {
    title: `${airports[originCode]?.cityName || originCode} to ${
      airports[destinationCode]?.cityName || destinationCode
    }`,
    confirmationCode: `H-${locators?.agent.unscopedValue}` ?? "",
    startDate: formatDate(firstLegDeparture?.scheduledDeparture ?? ""),
    endDate: firstLegReturn
      ? formatDate(firstLegReturn.scheduledDeparture)
      : "",
    subtitle: returnSlice ? "Round-trip" : "One-way",
    airlineCode,
    airlineName:
      airlines[firstLegDeparture?.marketingAirline.code ?? ""]?.displayName ||
      airlineCode,
    flightNumber:
      airlineCode +
        " " +
        firstLegDeparture?.marketingAirline.flightNumber.toString() ?? "",
  };
};

export const getMulticityFlightInfoDetails = (
  bookedItinerary: BookedFlightItinerary,
  formatDate: (date: string) => string,
  airports: { [key: string]: Airport },
  airlines: { [key: string]: Airline }
) => {
  const travelItinerary = bookedItinerary.travelItinerary;
  const tripSlices = getSlicesFromTravelItinerary(travelItinerary);

  const uniqueCityNames = getUniqueCitiesFromTripSlices(tripSlices, airports);
  const title = getTruncatedCityList(uniqueCityNames);

  const lastSlice = tripSlices[tripSlices.length - 1];
  const firstLegDeparture = tripSlices[0].segments[0];
  const lastLegReturn = lastSlice.segments[lastSlice.segments.length - 1];
  const airlineCode = firstLegDeparture?.marketingAirline.code ?? "";

  return {
    title,
    confirmationCode:
      `H-${travelItinerary?.locators?.agent.unscopedValue}` ?? "",
    startDate: formatDate(firstLegDeparture?.scheduledDeparture ?? ""),
    endDate: formatDate(lastLegReturn.scheduledDeparture),
    subtitle: "Multi-city",
    airlineCode,
    airlineName:
      airlines[firstLegDeparture?.marketingAirline.code ?? ""]?.displayName ||
      airlineCode,
    flightNumber:
      airlineCode +
        " " +
        firstLegDeparture?.marketingAirline.flightNumber.toString() ?? "",
  };
};

const getJetBluePenaltyInfo = (
  penalty: Penalty,
  penaltyName: string,
  brandName?: string
) => {
  switch (brandName) {
    case "Blue":
    case "Blue Plus":
    case "Blue Extra":
    case "Mint":
      return {
        name: penaltyName,
        description:
          penaltyName === CANCELLATIONS ? `Cancel for a credit` : `Free`,
        symbol: ShopSymbol.INCLUDED,
      };
    case "Blue Ref":
    case "Blue Plus Ref":
    case "Blue Extra Ref":
    case "Mint Ref":
      return {
        name: penaltyName,
        description: `Free`,
        symbol: ShopSymbol.INCLUDED,
      };
    default:
      return getPenaltyInfo(penalty, penaltyName);
  }
};

const isJetBlueFromBrand = (brandName?: string) => {
  switch (brandName) {
    case "Blue Basic":
    case "Blue":
    case "Blue Ref":
    case "Blue Plus":
    case "Blue Plus Ref":
    case "Blue Extra":
    case "Blue Extra Ref":
    case "Mint":
    case "Mint Ref":
      return true;
    default:
      return false;
  }
};

export const getCombinedRestrictionInfo = (
  flightRestrictionInfo: Restrictions | undefined,
  sliceRestricionInfo: Restrictions | undefined,
  isTF: boolean = false,
  brandName?: string
) => {
  const restrictions: Restriction[] = [];
  const baggageRestrictionInfo =
    sliceRestricionInfo &&
    sliceRestricionInfo?.baggageInfo.BaggageInfo != BaggageInfoEnum.NotAllowed
      ? sliceRestricionInfo
      : flightRestrictionInfo;
  switch (baggageRestrictionInfo?.baggageInfo.BaggageInfo) {
    case BaggageInfoEnum.Allowed:
      const info = baggageRestrictionInfo.baggageInfo as Allowed;
      if (info.allowance.descriptions.length > 0) {
        restrictions.push({
          name: "",
          description: info.allowance.descriptions.join(" "),
          symbol: ShopSymbol.INCLUDED,
        });
      }
      if (info.charges.length > 0) {
        restrictions.push({
          name: "Checked Bag Allowance",
          symbol: ShopSymbol.PURCHASABLE,
          description: info.charges.reduce((prev, curr, index, charges) => {
            let string = `Bag ${curr.firstPiece} at $${curr.amount} ${curr.currency}`;
            if (index < charges.length - 1) {
              string += `, `;
            }
            return (prev += string);
          }, ""),
        });
      }
      break;
    case BaggageInfoEnum.NotAllowed:
      restrictions.push({
        name: "Checked Bag Allowance",
        symbol: ShopSymbol.PURCHASABLE,
        description: "Not Allowed",
      });
      break;
    case BaggageInfoEnum.Unknown:
      break;
    default:
      break;
  }

  const penaltiesRestrictionInfo =
    sliceRestricionInfo?.penaltiesInfo.PenaltiesInfo !=
    PenaltiesInfoEnum.Unknown
      ? sliceRestricionInfo
      : flightRestrictionInfo;
  switch (penaltiesRestrictionInfo?.penaltiesInfo.PenaltiesInfo) {
    case PenaltiesInfoEnum.Known:
      const info = penaltiesRestrictionInfo?.penaltiesInfo as Known;
      if (isJetBlueFromBrand(brandName)) {
        restrictions.push(
          getJetBluePenaltyInfo(info.cancelPenalty, CANCELLATIONS, brandName)
        );
        restrictions.push(
          getJetBluePenaltyInfo(info.changePenalty, EXCHANGES, brandName)
        );
      } else {
        restrictions.push(getPenaltyInfo(info.cancelPenalty, CANCELLATIONS));
        restrictions.push(getPenaltyInfo(info.changePenalty, EXCHANGES));
      }
      break;
    case PenaltiesInfoEnum.ExternallyManaged:
    case PenaltiesInfoEnum.Unknown:
      restrictions.push({
        name: CANCELLATIONS,
        description: isTF
          ? "Click 'Cancel Flight' above to view eligibility"
          : "Contact airline",
        symbol: ShopSymbol.UNKNOWN,
      });
      break;
    default:
      break;
  }

  return restrictions;
};

export const CANCELLATIONS = "Cancellations";
export const EXCHANGES = "Exchanges";
export const getRestrictionInfo = (
  flight: BookedFlightItineraryWithDepartureTime,
  isTF: boolean = false,
  slice: FlightItinerarySlice | undefined,
  brandName?: string
): Restriction[] => {
  const flightRestrictionInfo = flight.bookedItinerary.restrictions;
  const sliceRestricionInfo = slice?.restrictions;

  return getCombinedRestrictionInfo(
    flightRestrictionInfo,
    sliceRestricionInfo,
    isTF,
    brandName
  );
};

const getPenaltyInfo = (penalty: Penalty, name: string): Restriction => {
  switch (penalty.Penalty) {
    case PenaltyEnum.WithFee:
      const info = penalty as WithFee;
      return {
        name,
        description: `for a fee of $${info.amount} ${info.currency}`,
        symbol: ShopSymbol.PURCHASABLE,
      };
    case PenaltyEnum.Unknown:
      return {
        name,
        description: `Contact support`,
        symbol: ShopSymbol.UNAVAILABLE,
      };
    case PenaltyEnum.NotPossible:
      return {
        name,
        description: `Non-refundable`,
        symbol: ShopSymbol.UNAVAILABLE,
      };
    case PenaltyEnum.NoPenalty:
      return {
        name,
        description: `Free`,
        symbol: ShopSymbol.INCLUDED,
      };
  }
};

export const getDurationFromSegments = (
  segments: FlightItinerarySegment[],
  getTimeDiff: (departure: string, arrival: string) => number
): number => {
  const departureSeg = segments[0];
  const arrivalSeg = segments[segments.length - 1];
  const departureTime =
    departureSeg.zonedUpdatedDeparture ||
    departureSeg.zonedScheduledDeparture ||
    departureSeg.scheduledDeparture;
  const arrivalTime =
    arrivalSeg.zonedUpdatedArrival ||
    arrivalSeg.zonedScheduledArrival ||
    arrivalSeg.scheduledArrival;
  return getTimeDiff(departureTime, arrivalTime);
};

export const getStopsFromSegments = (
  segments: FlightItinerarySegment[] = []
) => {
  return segments.length ? segments.length - 1 : 0;
};

export const getSegmentCounts = (itinerary: TravelItinerary) => {
  return {
    outboundFlight:
      getDepartureSliceFromTravelItinerary(itinerary).segments.length,
    returnFlight: getReturnSliceFromTravelItinerary(itinerary)?.segments.length,
  };
};

export const getSegmentStopCounts = (itinerary: TravelItinerary) => {
  const { outboundFlight, returnFlight } = getSegmentCounts(itinerary);
  return {
    outboundFlight: outboundFlight - 1,
    returnFlight: returnFlight ? returnFlight - 1 : undefined,
  };
};

export const getCurrencyPrefix = (
  flight: BookedFlightItineraryWithDepartureTime
) => {
  const { sellingPricing } = flight.bookedItinerary;
  return `${sellingPricing.totalPricing.currency} ${sellingPricing.totalPricing.baseWithoutMargin.fiat.currencySymbol}`;
};

export const getPriceWithDecimal = (
  flight: BookedFlightItineraryWithDepartureTime
) => {
  const { total } = flight.bookedItinerary.sellingPricing.totalPricing;
  return total.fiat.value.toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export enum BookingType {
  Air = "Air",
  Ground = "Ground",
  Hotels = "Hotels",
  Home = "Homes",
}

export enum EmailTypeTag {
  FlightConfirmation = "FlightConfirmation",
  HotelConfirmation = "HotelConfirmation",
  GroundConfirmation = "GroundConfirmation",
  LuxuryHotelConfirmation = "HotelLuxuryBookingConfirmation",
  LifestyleHotelConfirmation = "HotelLifestyleBookingConfirmation",
  HomesLifestyleBookingConfirmation = "HomesLifestyleBookingConfirmation",
  HomesPremierBookingConfirmation = "HomesPremierBookingConfirmation",
}

export interface FlightConfirmation {
  locator: string;
  itineraryId: string;
  EmailType: EmailTypeTag;
}

export interface HotelConfirmation {
  sessionId: string;
  reservationId: string;
  EmailType: EmailTypeTag;
}

export interface GroundConfirmation {
  sessionId: string;
  groundBookingId: string;
  EmailType: EmailTypeTag;
}

export type EmailType =
  | FlightConfirmation
  | HotelConfirmation
  | GroundConfirmation
  | HomeConfirmation;

export interface ResendConfirmationReq {
  sendTo: string;
  emailType: EmailType;
}

export interface HomeConfirmation {
  homesBookingId: string;
  EmailType: EmailTypeTag;
}

export type MaybeFlightItinerary =
  BookedFlightItineraryWithDepartureTime | null;

export interface IPersonWithNameNumber extends IPerson {
  nameNumber?: string;
}

export const getAllSlicesFromTravelItinerary = (
  travelItinerary: TravelItinerary
): FlightItinerarySlice[] => {
  switch (travelItinerary?.TravelItinerary) {
    case TravelItineraryEnum.SingleTravelItinerary:
      return travelItinerary.slices;
    case TravelItineraryEnum.MultiTravelItinerary:
      return travelItinerary.travelItineraries.flatMap((i) => i.slices);
  }
};
