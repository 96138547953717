import React, { useState, useEffect, useMemo, useContext } from "react";
import { RouteComponentProps } from "react-router-dom";
import {
  Box,
  Typography,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import {
  ActionButton,
  PolicyInfoModal,
  GenericItemsList,
  GenericFlightSummary,
  IFlightSummaryData,
  getPriceString,
  getCurrencySymbol,
  getPricesWithComma,
  useFocusRef,
} from "halifax";
import {
  FlightDisruptionReasonEnum,
  CAP1_VIEWED_REBOOKING_CHOICE,
  Cap1DpExerciseFactsProperties,
  Cap1DpExerciseItineraryFactsProperties,
  CAP1_SELECTED_REBOOKING_CHOICE,
  CallState,
  isMultiCityItinerary,
} from "redmond";
import clsx from "clsx";
import { findLastIndex } from "lodash";
import { PATH_DISRUPTION_PROTECTION_REBOOK_FLIGHT } from "../../../../utils/paths";
import {
  pushToDisruptionOverview,
  pushToRebookFlightList,
} from "../../../../utils/queryStringHelpers";
import { getIsSelectingReturnFromTravelItinerary } from "../../../../utils/helpers";
import { hasUpcomingReturnFlightInBookedItinerary } from "../../../TripsList/utils";
import { ClientContext } from "../../../../App";
import { trackEvent } from "../../../../api/v1/analytics/trackEvent";
import { DisruptionProtectionRebookConnectorProps } from "./container";
import { RebookFlightSummaryData, FlightSummaryType } from "./types";
import { getRebookFlightSummaryData } from "./utils";
import * as constants from "./constants";
import "./styles.scss";
import { usePrevious } from "../../../../hooks/usePrevious";
import { isEqual } from "lodash-es";
import { BookedFlightItinerary } from "redmond/trips-module/itinerary";

export interface IDisruptionProtectionRebookProps
  extends DisruptionProtectionRebookConnectorProps,
    RouteComponentProps {
  isMobile: boolean;
}

export const DisruptionProtectionRebook = (
  props: IDisruptionProtectionRebookProps
) => {
  const {
    destination,
    coverageCap,
    eligibleSlice,
    eligibleSegment,
    airportMap,
    airlineMap,
    isMobile,
    disruptionProtectionItinerary,
    fetchFlightDisruptionsCallState,
    disruptionOverviewEligibilityDpExerciseFactsProperties,
    disruptedFlightDpExerciseFactsProperties,
    isSelectingReturnFlight,
    history,
  } = props;
  const [rebookOrigin, setRebookOrigin] = useState<string>();
  const [rebookDestination, setRebookDestination] = useState<string>();
  const [openLuggageModal, setOpenLuggageModal] = useState<boolean>(false);
  const [openReturnFlightModal, setOpenReturnFlightModal] =
    useState<boolean>(false);
  const clientContext = useContext(ClientContext);

  const initialFocusRef = useFocusRef([]);

  const { isAgentPortal } = clientContext;

  const bookedItinerary = disruptionProtectionItinerary?.bookedItinerary;
  const isMultiCity = isMultiCityItinerary(
    bookedItinerary as BookedFlightItinerary,
    airportMap
  );
  const hasUpcomingReturnFlight = hasUpcomingReturnFlightInBookedItinerary(
    bookedItinerary,
    isMultiCity
  );
  const knowBeforeRebookItems = (() => {
    const items = [
      { message: constants.KNOW_BEFORE_REBOOKING_POINT_ONE },
      {
        message: constants.KNOW_BEFORE_REBOOKING_POINT_TWO,
        button: {
          message: constants.LEARN_MORE,
          ariaLabel: constants.KNOW_BEFORE_REBOOKING_POINT_TWO_ARIA_LABEL,
          onClick: () => setOpenLuggageModal(true),
          suffix: constants.PERIOD_TEXT,
        },
      },
    ];

    if (hasUpcomingReturnFlight) {
      items.push({
        message: constants.KNOW_BEFORE_REBOOKING_POINT_THREE,
        button: {
          message: constants.LEARN_MORE,
          ariaLabel: constants.KNOW_BEFORE_REBOOKING_POINT_THREE_ARIA_LABEL,
          onClick: () => setOpenReturnFlightModal(true),
          suffix: constants.PERIOD_TEXT,
        },
      });
    }

    return items;
  })();

  const importInfoItems = (() => {
    const items = [
      { message: constants.IMPORTANT_INFORMATION_POINT_ONE },
      { message: constants.IMPORTANT_INFORMATION_POINT_TWO },
    ];

    if (coverageCap) {
      const cap = getPricesWithComma(
        getPriceString({
          price: coverageCap.amount,
          currencySymbol: getCurrencySymbol(coverageCap.currency),
        })
      );
      items.push({
        message: constants.IMPORTANT_INFORMATION_POINT_THREE(cap),
      });
    }

    items.push({ message: constants.IMPORTANT_INFORMATION_POINT_FOUR });

    return items;
  })();

  const flightSummaryData = useMemo(() => {
    if (!!disruptionProtectionItinerary) {
      const travelItinerary =
        disruptionProtectionItinerary.bookedItinerary.travelItinerary;

      if (!!eligibleSlice) {
        return getRebookFlightSummaryData({
          sliceIndex: eligibleSlice.index,
          travelItinerary,
          eligibleSlice: eligibleSlice.slice,
          airportMap,
          airlineMap,
        });
      } else if (isAgentPortal) {
        const isSelectingReturn =
          getIsSelectingReturnFromTravelItinerary(travelItinerary);
        const sliceIndex: 0 | 1 = isSelectingReturn ? 1 : 0;

        return getRebookFlightSummaryData({
          sliceIndex,
          travelItinerary,
          airportMap,
          airlineMap,
          useSelectAll: true,
        });
      }
    }

    return undefined;
  }, [eligibleSlice, disruptionProtectionItinerary, airportMap, airlineMap]);

  const productRedeemChoice =
    history.location.pathname === PATH_DISRUPTION_PROTECTION_REBOOK_FLIGHT
      ? "delay"
      : "missed_connection";

  const activeDisruption =
    disruptionOverviewEligibilityDpExerciseFactsProperties?.active_disruption;

  const handleTrackSelectedRebookingChoice = (
    firstSegment: IFlightSummaryData | undefined,
    lastSegment: IFlightSummaryData | undefined
  ) =>
    trackEvent({
      eventName: CAP1_SELECTED_REBOOKING_CHOICE,
      properties: {
        active_disruption: activeDisruption,
        product_redeem_choice: productRedeemChoice,
        departure_date: firstSegment?.tripSlice.departureTime,
        ...disruptedFlightDpExerciseFactsProperties,
        rebook_origin: firstSegment?.tripSlice.originCode,
        rebook_destination: lastSegment?.tripSlice.destinationCode,
      } as Cap1DpExerciseFactsProperties &
        Cap1DpExerciseItineraryFactsProperties,
    });

  useEffect(() => {
    window.scrollTo({ behavior: "smooth", left: 0, top: 0 });
  }, []);

  useEffect(() => {
    if (
      !isAgentPortal &&
      !eligibleSlice &&
      (fetchFlightDisruptionsCallState === CallState.Success ||
        fetchFlightDisruptionsCallState === CallState.Failed)
    ) {
      pushToDisruptionOverview({
        history,
        itineraryId: disruptionProtectionItinerary?.bookedItinerary.id,
      });
    }
  }, [
    eligibleSlice,
    fetchFlightDisruptionsCallState,
    disruptionProtectionItinerary,
  ]);

  useEffect(() => {
    if (
      disruptionOverviewEligibilityDpExerciseFactsProperties &&
      disruptedFlightDpExerciseFactsProperties
    ) {
      trackEvent({
        eventName: CAP1_VIEWED_REBOOKING_CHOICE,
        properties: {
          active_disruption:
            disruptionOverviewEligibilityDpExerciseFactsProperties?.active_disruption,
          product_redeem_choice: productRedeemChoice,
          ...disruptedFlightDpExerciseFactsProperties,
        } as Cap1DpExerciseFactsProperties &
          Cap1DpExerciseItineraryFactsProperties,
      });
    }
  }, [
    disruptionOverviewEligibilityDpExerciseFactsProperties,
    disruptedFlightDpExerciseFactsProperties,
  ]);

  return (
    <>
      <Box
        className={clsx("disruption-protection-rebook-root", {
          mobile: isMobile,
        })}
      >
        <Box className="disruption-protection-rebook-container">
          <Box className={clsx("rebook-content", "header-section")}>
            <Typography
              tabIndex={0}
              innerRef={initialFocusRef}
              className="header-copy"
              variant="h2"
            >
              {constants.DISRUPTION_PROTECTION_REBOOK_TITLE(
                destination,
                history.location.pathname ===
                  PATH_DISRUPTION_PROTECTION_REBOOK_FLIGHT
                  ? "flight"
                  : "connection"
              )}
            </Typography>
            <Typography className="subtitle-copy" variant="subtitle1">
              {flightSummaryData?.FlightSummaryType ===
              FlightSummaryType.Selection
                ? constants.DISRUPTION_PROTECTION_REBOOK_SELECTION_SUBTITLE
                : constants.DISRUPTION_PROTECTION_REBOOK_SUBTITLE}
            </Typography>
          </Box>
          {flightSummaryData && (
            <Box className={clsx("rebook-content", "flight-summary-section")}>
              <RebookFlightSummary
                flightSummaryData={flightSummaryData}
                setRebookOrigin={setRebookOrigin}
                setRebookDestination={setRebookDestination}
                onChangeSelection={handleTrackSelectedRebookingChoice}
                isSelectingReturnFlight={isSelectingReturnFlight}
                isMobile={isMobile}
              />
            </Box>
          )}
          <Box className={clsx("rebook-content", "know-before-rebook-section")}>
            <GenericItemsList
              className="know-before-rebook"
              title={constants.KNOW_BEFORE_REBOOKING_TITLE}
              items={knowBeforeRebookItems}
              theme={{
                color: "blue",
                padding: isMobile ? "20px" : "30px",
              }}
            />
          </Box>
          <Box className={clsx("rebook-content", "info-section")}>
            <GenericItemsList
              className="important-info"
              title={constants.IMPORTANT_INFORMATION}
              items={importInfoItems}
              useNumberedList
            />
          </Box>
          <Box
            className={clsx("rebook-content", "button-section", {
              floating: isMobile,
            })}
          >
            <ActionButton
              className="search-button"
              defaultStyle="h4r-primary"
              disabled={!rebookOrigin || !rebookDestination}
              onClick={() => {
                pushToRebookFlightList({
                  history,
                  origin: rebookOrigin,
                  destination: rebookDestination,
                  itineraryId:
                    disruptionProtectionItinerary?.bookedItinerary.id,
                  /*
                    note: when it's on the agent portal and the eligibility endpoint is not returning any disruption,
                    the slice index should be determined by the isSelectingReturnFlight boolean 
                  */
                  sliceIndex:
                    eligibleSlice?.index ??
                    (isAgentPortal
                      ? isSelectingReturnFlight
                        ? 1
                        : 0
                      : undefined),
                  /*
                    note: when it's on the agent portal and the eligibility endpoint is not returning any disruption,
                    we will assume that the entire itinerary is eligible for disruption rebook.
                  */
                  segmentIndex:
                    eligibleSegment?.index ?? (isAgentPortal ? 0 : undefined),
                  productRedeemChoice,
                  activeDisruption,
                });
              }}
              message={
                isMobile
                  ? constants.SEARCH_FOR_FLIGHTS_MOBILE
                  : constants.SEARCH_FOR_FLIGHTS
              }
              ariaLabelText={constants.SEARCH_FOR_FLIGHTS}
            />
          </Box>
        </Box>
      </Box>
      <PolicyInfoModal
        isMobile={isMobile}
        preset="disruption-protection-luggage"
        openModal={openLuggageModal}
        onClose={() => setOpenLuggageModal(false)}
      />
      {hasUpcomingReturnFlight && (
        <PolicyInfoModal
          isMobile={isMobile}
          preset="disruption-protection-return-flight"
          openModal={openReturnFlightModal}
          onClose={() => setOpenReturnFlightModal(false)}
        />
      )}
    </>
  );
};

interface IRebookFlightSummaryProps {
  flightSummaryData: RebookFlightSummaryData | undefined;
  setRebookOrigin: (origin: string) => void;
  setRebookDestination: (destination: string) => void;
  onChangeSelection: (
    firstSegment: IFlightSummaryData | undefined,
    lastSegment: IFlightSummaryData | undefined
  ) => void;
  isSelectingReturnFlight?: boolean;
  isMobile?: boolean;
}

export const RebookFlightSummary = (props: IRebookFlightSummaryProps) => {
  const {
    flightSummaryData,
    setRebookOrigin,
    setRebookDestination,
    onChangeSelection,
    isSelectingReturnFlight,
    isMobile,
  } = props;
  const [checkBoxValues, setCheckBoxValues] = useState<boolean[]>();
  const reasonTag: any = (() => {
    switch (flightSummaryData?.reason) {
      case FlightDisruptionReasonEnum.Delayed:
        return {
          message: constants.DELAYED_FLIGHT,
          theme: "yellow",
        };
      case FlightDisruptionReasonEnum.MissedConnection:
        return {
          message: constants.MISSED_CONNECTION,
          theme: "yellow",
        };
      case FlightDisruptionReasonEnum.AirlineCancellation:
        return {
          message: constants.CANCELED_FLIGHT,
          theme: "red",
        };
      default:
        return undefined;
    }
  })();

  const FlightSummarySelection = ({
    segments,
  }: {
    segments: IFlightSummaryData[];
  }) => {
    const handleClick = (segmentIndex: number) => {
      if (checkBoxValues !== undefined) {
        // uncheck all checkboxes up to segmentIndex (inclusive)
        if (checkBoxValues[segmentIndex]) {
          setCheckBoxValues((values) =>
            values?.map((_, index) => index > segmentIndex)
          );
        }
        // check all checkboxes after segmentIndex (inclusive)
        else {
          setCheckBoxValues((values) =>
            values?.map((_, index) => index >= segmentIndex)
          );
        }
      }
    };

    return (
      <FormControl
        className="flight-summary-selection-form-control"
        component="fieldset"
      >
        <FormLabel
          className="flight-summary-selection-label"
          component="legend"
          disabled
        >
          {constants.REBOOK_SEGMENT_SELECTION}
        </FormLabel>
        <FormGroup
          className="flight-summary-selection-group"
          aria-label="position"
          row
        >
          {segments.map((segment, segmentIndex) => {
            return (
              <FormControlLabel
                className="flight-summary-selection-control-label"
                key={segment.tripSlice.originName}
                value="end"
                labelPlacement="end"
                control={
                  <Checkbox
                    checked={checkBoxValues && checkBoxValues[segmentIndex]}
                    tabIndex={0}
                    onClick={(event) => {
                      event.preventDefault();
                      handleClick(segmentIndex);
                    }}
                    className="flight-summary-selection-checkbox"
                  />
                }
                label={
                  <GenericFlightSummary
                    {...segment}
                    headerType="from-origin-to-destination"
                    tag={segmentIndex === 0 ? reasonTag : undefined}
                    showFareShelfBrandName={true}
                    useChevron={false}
                    useFullWidth={!isMobile}
                    isMobile={isMobile}
                  />
                }
              />
            );
          })}
        </FormGroup>
      </FormControl>
    );
  };

  // set origin & destination on mount
  useEffect(() => {
    switch (flightSummaryData?.FlightSummaryType) {
      case FlightSummaryType.NonstopSlice: {
        // only has 1 segment since it's nonstop
        const segment = flightSummaryData.slice.tripSlice.segments[0];
        setRebookOrigin(segment?.originCode);
        setRebookDestination(segment?.destinationCode);
        break;
      }
      case FlightSummaryType.SingleConnection: {
        // only has 1 segment since it's a single connection
        const segment = flightSummaryData.segment.tripSlice.segments[0];
        setRebookOrigin(segment?.originCode);
        setRebookDestination(segment?.destinationCode);
        break;
      }
      // note: when it's Selection, checkboxes are left unchecked on start (no origin / destination is selected)
      case FlightSummaryType.Selection: {
        setCheckBoxValues(Array(flightSummaryData.segments.length).fill(false));
        break;
      }
      default:
        break;
    }
  }, []);

  const oldCheckBoxValues = usePrevious(checkBoxValues);

  // set origin & destination when selection options are changed
  useEffect(() => {
    if (
      flightSummaryData?.FlightSummaryType === FlightSummaryType.Selection &&
      checkBoxValues &&
      checkBoxValues.length > 0
    ) {
      const firstSegmentIndex = checkBoxValues.findIndex((value) => value);
      const lastSegmentIndex = findLastIndex(
        checkBoxValues,
        (value: boolean) => value
      );

      // Ensure the onChangeSelection is NOT fired on first loading of the checkbox options
      if (
        !isEqual(oldCheckBoxValues, checkBoxValues) &&
        oldCheckBoxValues !== undefined
      ) {
        onChangeSelection(
          flightSummaryData.segments[firstSegmentIndex],
          flightSummaryData.segments[lastSegmentIndex]
        );
      }

      // in FlightSummaryType.Selection, tripSlice.segments is having a length of 1
      setRebookOrigin(
        flightSummaryData.segments[firstSegmentIndex]?.tripSlice.segments[0]
          .originCode
      );
      setRebookDestination(
        flightSummaryData.segments[lastSegmentIndex]?.tripSlice.segments[0]
          .destinationCode
      );
    }
  }, [flightSummaryData, checkBoxValues]);

  switch (flightSummaryData?.FlightSummaryType) {
    case FlightSummaryType.Selection:
      return <FlightSummarySelection segments={flightSummaryData.segments} />;
    case FlightSummaryType.NonstopSlice:
      return (
        <GenericFlightSummary
          {...flightSummaryData.slice}
          headerType={isSelectingReturnFlight ? "return" : "outbound"}
          tag={reasonTag}
          buttons={
            !isMobile
              ? [
                  {
                    message: constants.VIEW_DETAILS,
                    onClick: "open-modal",
                  },
                ]
              : undefined
          }
          useChevron={isMobile}
          useFullWidth={!isMobile}
          isMobile={isMobile}
        />
      );
    case FlightSummaryType.SingleConnection:
      return (
        <GenericFlightSummary
          {...flightSummaryData.segment}
          headerType="from-origin-to-destination"
          tag={reasonTag}
          showFareShelfBrandName={true}
          useChevron={false}
          useFullWidth={!isMobile}
          isMobile={isMobile}
        />
      );
    default:
      return null;
  }
};
