import React, { MouseEvent } from "react";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import { ActionButton } from "halifax";
import { TravelCredit } from "redmond";

import {
  BOOK_WITH_CREDIT,
  TC_WORTH,
} from "../../components/FlightCard/constants";

import "./styles.scss";

export interface IRedeemFTCButtonProps {
  isMobile: boolean;
  onClick: (e?: MouseEvent) => void;
  travelCredit: TravelCredit;
}

const defaultProps: Partial<IRedeemFTCButtonProps> = {
  isMobile: false,
};

const RedeemFTCButton = (props: IRedeemFTCButtonProps): JSX.Element => {
  const { isMobile, onClick, travelCredit } = props;

  return (
    <Box className={clsx("book-with-credit-container", { mobile: isMobile })}>
      <ActionButton
        className="book-with-credit-btn"
        message={BOOK_WITH_CREDIT}
        onClick={onClick}
      />
      <Typography
        className="tc-worth"
        dangerouslySetInnerHTML={{
          __html: TC_WORTH(travelCredit?.credit),
        }}
        variant="caption"
      />
    </Box>
  );
};

RedeemFTCButton.defaultProps = defaultProps;

export default RedeemFTCButton;
