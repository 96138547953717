import React from "react";
import { LocationBookDateTime } from "redmond";
import { Typography, Box } from "@material-ui/core";
import { GoogleMap } from "@react-google-maps/api";
import dayjs from "dayjs";
import * as t from "../../../CarCard/constants";
import "./styles.scss";
import { convertICoordinatesToGoogleMapCoords } from "../../../../../../../../utils/googleMapsHelpers";
import { SmallHotelMapContent } from "../../../CarCard/components/CarMap/component";

interface ILocationCarModalContentProps {
  location: LocationBookDateTime;
  isPickUp: boolean;
}

export const LocationCarModalContent = ({
  location,
  isPickUp,
}: ILocationCarModalContentProps) => {
  const formatTime = (date: string) => dayjs(date).format("h:mm A");
  const center =
    location.location.coordinates &&
    convertICoordinatesToGoogleMapCoords(location.location.coordinates);
  return (
    <Box className="location-modal-content">
      <Box className="location-title-container">
        <Typography variant="h4">
          {`${isPickUp ? t.PICKED_UP : t.DROP_OFF}`}
        </Typography>
      </Box>
      <Box className="location-details-container">
        <Typography variant="body2" className="title">
          {`${isPickUp ? t.PICKED_UP : t.DROP_OFF} on ${dayjs(location.dateTime).format(t.DATE_FORMAT)} | ${formatTime(location.dateTime)}`}
        </Typography>
        <Typography variant="subtitle2" className="content">
          {location.location.address}
        </Typography>
      </Box>
      <Box className="location-map-container">
        <Box className="map-container">
          <GoogleMap
            id="location-car-map"
            mapContainerStyle={{ width: "100%" }}
            options={{
              clickableIcons: false,
              zoomControl: true,
              streetViewControl: false,
              mapTypeControl: false,
              draggable: true,
              fullscreenControl: false,
              draggableCursor: "pointer",
            }}
            center={center}
            zoom={16}
          >
            {center ? <SmallHotelMapContent center={center} /> : null}
          </GoogleMap>
        </Box>
      </Box>
    </Box>
  );
};
