import axios from "axios";
import { ConfirmFlightCfarCancelRequest, CancellationResponse } from "redmond";

import { config } from "../../config";
import { confirmFlightCfarCancellationPath } from "../paths";

/**
 * @version v2
 * @description The second endpoint in the self serve cancel flight flow
 * @param {ConfirmFlightCfarCancelRequest} req The context of the cancellation
 * @return {CancellationResponse} The copy to show in the cancellation result state
 */
const confirmFlightCfarCancellation = (
  req: ConfirmFlightCfarCancelRequest
): Promise<CancellationResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await axios.post(confirmFlightCfarCancellationPath, req, {
        baseURL: config.baseURL,
      });
      const { Response, errors, value } = res.data;

      // call can succeed with a "Failure" Response so handle both cases
      if (Response === "Success") {
        // TODO: handle tracking
        resolve(value);
      } else {
        let errString = "";

        if (typeof errors === "string") {
          errString = errors;
        } else if (Array.isArray(errors)) {
          errString = errors
            .map((err: any) => `${err.code} - ${err.details}`)
            .join(", ");
        }

        // TODO: handle tracking
        reject(errString);
      }
    } catch (e) {
      reject(e);
    }
  });

export default confirmFlightCfarCancellation;
