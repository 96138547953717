import { takeLatest } from "redux-saga/effects";
import { actionTypes } from "../actions";
import { fetchHotelPriceFreezeDetailsSaga } from "./fetchHotelPriceFreezeDetailsSaga";
import { refundHotelPriceFreezeSaga } from "./refundHotelPriceFreezeSaga";

export function* watchFetchHotelPriceFreezeDetailsSaga() {
  yield takeLatest(
    actionTypes.FETCH_HOTEL_PRICE_FREEZE_DETAILS,
    fetchHotelPriceFreezeDetailsSaga
  );
}

export function* watchRefundHotelPriceFreezeSaga() {
  yield takeLatest(
    actionTypes.REFUND_HOTEL_PRICE_FREEZE,
    refundHotelPriceFreezeSaga
  );
}
