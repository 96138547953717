import {
  CallState,
  MessageMethod,
  MessageMethodEnum,
  UpdateWatchAlertRequest,
  WatchAlertViews,
} from "redmond";
import { put } from "redux-saga/effects";
import { updateWatch } from "../../../api/v1/price-watch/updateWatch";
import { listWatches } from "../../../api/v1/price-watch/listWatches";

import { actions } from "../actions";

export function* updateWatchSaga(action: actions.IUpdateWatch) {
  try {
    const originalAlert = action.watch;
    const alertKey = originalAlert.key;
    const method: MessageMethod =
      originalAlert.method.MessageMethod === MessageMethodEnum.MultiMethod
        ? {
            ...originalAlert.method,
            email: {
              email: action.email,
              MessageMethod: MessageMethodEnum.Email,
            },
          }
        : {
            email: action.email ?? "",
            MessageMethod: MessageMethodEnum.Email,
          };
    const request: UpdateWatchAlertRequest = {
      key: alertKey,
      method: method,
    };
    yield updateWatch(request);
    const watches: WatchAlertViews = yield listWatches();
    yield put(actions.setWatches(watches));
    yield put(actions.setUpdateWatchCallState(CallState.Success));
  } catch (e) {
    yield put(actions.setUpdateWatchCallState(CallState.Failed));
  }
}
