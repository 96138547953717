import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router-dom";
import { IStoreState } from "../../../../reducers/types";
import {
  getAreSomeDisruptionInfoFetchingsInProcess,
  getFlightDisruptions,
  selectedFlightDisruptionProtectionItinerarySelector,
  disruptionOverviewEligibilityDpExerciseFactsPropertiesSelector,
  selectedFlightDisruptionAncillaryCoverageSelector,
  selectedFlightDisruptionProtectionItineraryIdSelector,
} from "../../reducer/selectors";
import { setProductRedeemChoice } from "../../actions/actions";
import { DisruptionProtectionLanding } from "./component";

const mapStateToProps = (state: IStoreState) => ({
  disruptionProtectionItinerary:
    selectedFlightDisruptionProtectionItinerarySelector(state),
  flightDisruptions: getFlightDisruptions(state),
  areSomeDisruptionInfoFetchingsInProcess:
    getAreSomeDisruptionInfoFetchingsInProcess(state),
  disruptionOverviewEligibilityDpExerciseFactsProperties:
    disruptionOverviewEligibilityDpExerciseFactsPropertiesSelector(state),
  coverage: selectedFlightDisruptionAncillaryCoverageSelector(state),
  selectedFlightDisruptionProtectionItineraryId: selectedFlightDisruptionProtectionItineraryIdSelector(state),
});

const mapDispatchToProps = {
  setProductRedeemChoice,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type DisruptionProtectionLandingConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedDisruptionProtectionLanding = connector(
  withRouter(DisruptionProtectionLanding)
);
