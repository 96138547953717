import { IFlightSummaryData } from "halifax";
import { FlightDisruptionReasonEnum } from "redmond";

export type RebookFlightSummaryData =
  | FlightSummarySelection
  | FlightSummaryNonstopSlice
  | FlightSummarySingleConnection;

export enum FlightSummaryType {
  Selection = "selection",
  NonstopSlice = "nonstop-slice",
  SingleConnection = "single-connection",
}

interface FlightSummarySelection {
  segments: IFlightSummaryData[];
  reason?: FlightDisruptionReasonEnum;
  FlightSummaryType: FlightSummaryType.Selection;
}

interface FlightSummaryNonstopSlice {
  slice: IFlightSummaryData;
  reason?: FlightDisruptionReasonEnum;
  FlightSummaryType: FlightSummaryType.NonstopSlice;
}

interface FlightSummarySingleConnection {
  segment: IFlightSummaryData;
  reason?: FlightDisruptionReasonEnum;
  FlightSummaryType: FlightSummaryType.SingleConnection;
}
