import axios from "axios";
import { config } from "../../config";

import { CarItineraryResponse, CarItineraryRequest } from "redmond";
import { groundItinerariesApiPrefix } from "../paths";

export const fetchCars = async (
  req: CarItineraryRequest
): Promise<CarItineraryResponse> => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await axios.put(`${groundItinerariesApiPrefix}`, req, {
        baseURL: config.baseURL,
      });
      resolve(res.data);
    } catch (e) {
      reject(e);
    }
  });
};
