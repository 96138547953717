import React from "react";
import { Box } from "@material-ui/core";
import clsx from "clsx";
import {
  GenericVirtualInterliningModal,
  PriceDropProtectionImage,
  MobilePriceDropProtectionImage,
  Icon,
  IconName,
  MobilePopoverCard,
  DesktopPopupModal,
} from "halifax";

import "./styles.scss";
import { getPropsForVariant } from "./helpers";
import { PATH_MISSED_CONNECTION_GUARANTEE } from "../../../utils/paths";
import { MISSED_CONNECTION_GUARANTEE_BODY } from "redmond";

export type IVirtualInterliningVariant =
  | "selfCheck"
  | "missedConnectionProtection"
  | "missedConnectionGuarantee";

export interface IVirtualInterliningModalProps {
  className?: string;
  onClose: () => void;
  variant: IVirtualInterliningVariant;
  isOpen: boolean;
  isMobile?: boolean;
}

export const VirtualIinterliningModal = ({
  variant,
  className,
  onClose,
  isOpen,
  isMobile = false,
}: IVirtualInterliningModalProps) => {
  const renderContent = () => {
    return (
      <GenericVirtualInterliningModal
        {...getPropsForVariant(variant)}
        image={
          isMobile ? MobilePriceDropProtectionImage : PriceDropProtectionImage
        }
        onClose={onClose}
        isMobile={isMobile}
        desktopTermsConditionURL={PATH_MISSED_CONNECTION_GUARANTEE}
        mobileTermsConditionCopy={MISSED_CONNECTION_GUARANTEE_BODY}
        icon={
          <Icon
            name={
              variant === "selfCheck"
                ? IconName.BookTravel
                : IconName.FlightSearch
            }
          />
        }
      />
    );
  };

  if (isMobile) {
    return (
      <MobilePopoverCard
        role="tooltip"
        id="virtual-interlining-modal-modal"
        open={isOpen}
        className="mobile-virtual-interlining-modal-popup"
        contentClassName="mobile-flight-virtual-interlining-modal-content-wrapper"
        onClose={onClose}
      >
        {renderContent()}
      </MobilePopoverCard>
    );
  }
  return (
    <Box
      className={clsx("virtual-interlining-root", className, {
        mobile: isMobile,
      })}
    >
      <DesktopPopupModal
        open={isOpen}
        className="virtual-interlining-popup"
        onClose={onClose}
        invisibleBackdrop={false}
        hideXButton
      >
        <Box className="virtual-interlining-wrapper">{renderContent()}</Box>
      </DesktopPopupModal>
    </Box>
  );
};
