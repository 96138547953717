import { TripAncillaryContracts } from "redmond";

export const getProductRedeemChoiceFromAncillaries = (
  ancillaries: TripAncillaryContracts | undefined
) => {
  if (ancillaries) {
    const { delay, missedConnection } = ancillaries;
    return !!delay
      ? "delay"
      : !!missedConnection
      ? "missed_connection"
      : undefined;
  }

  return undefined;
};
