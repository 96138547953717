import React from "react";
import { Box } from "@material-ui/core";
import { ActionLink } from "halifax";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { RouteComponentProps } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";

import { HotelShopProgressBarConnectorProps } from "./container";
import * as constants from "./constants";
import "./styles.scss";
import { HOTEL_PATH_BOOK } from "../../../../utils/paths";
import {
  HOTEL_BOOK_TYPE,
  HotelBookType,
  PRICE_FREEZE_ID_QUERY_PARAM,
} from "redmond";
import queryStringParser from "query-string";

interface IHotelPriceFreezeProgressBarProps
  extends HotelShopProgressBarConnectorProps,
    RouteComponentProps {
  voucherId: string;
}

enum HotelPriceFreezeStep {
  REVIEW_PRICE_FREEZE_STEP = "review-price-freeze-step",
  BOOK_STEP = "book-step",
}

// Commenting out because this is currently not used but might be used for extending this component
// enum CompareStepsResult {
//   Before,
//   Same,
//   After,
//   Unknown,
// }
// const compareSteps = (
//   step1: HotelPriceFreezeStep,
//   step2: HotelPriceFreezeStep
// ): CompareStepsResult => {
//   const stepOrdering: HotelPriceFreezeStep[] = [
//     HotelPriceFreezeStep.REVIEW_PRICE_FREEZE_STEP,
//     HotelPriceFreezeStep.BOOK_STEP,
//   ];

//   const index1 = stepOrdering.findIndex((s) => s === step1);
//   const index2 = stepOrdering.findIndex((s) => s === step2);

//   if (index1 === -1 || index2 === -1) {
//     return CompareStepsResult.Unknown;
//   } else if (index1 === index2) {
//     return CompareStepsResult.Same;
//   } else if (index1 < index2) {
//     return CompareStepsResult.Before;
//   } else {
//     return CompareStepsResult.After;
//   }
// };

export const HotelPriceFreezeProgressBar = (
  props: IHotelPriceFreezeProgressBarProps
) => {
  const { history, voucherId } = props;

  const goToPriceFreezeExerciseCheckout = () => {
    history.push({
      pathname: HOTEL_PATH_BOOK,
      search: queryStringParser.stringify({
        [PRICE_FREEZE_ID_QUERY_PARAM]: voucherId,
        [HOTEL_BOOK_TYPE]: HotelBookType.PRICE_FREEZE_EXERCISE,
      }),
    });
  };

  const ProgressButtons = ({
    progresses,
  }: {
    progresses: HotelPriceFreezeStep[];
  }) => {
    const selectedStep: HotelPriceFreezeStep = (() => {
      // This instance of the progress bar is only ever shown on the Hotel PF overview page
      // so the Review step will always be the selected one
      return HotelPriceFreezeStep.REVIEW_PRICE_FREEZE_STEP;
    })();

    return (
      <>
        {progresses.map((progress: HotelPriceFreezeStep, index: number) => {
          return (
            <React.Fragment key={index}>
              <HotelPriceFreezeProgressButton
                key={`button-${index}`}
                selectedStep={selectedStep}
                progress={progress}
                goToPriceFreezeExerciseCheckout={
                  goToPriceFreezeExerciseCheckout
                }
              />
              {index < progresses.length - 1 && (
                <FontAwesomeIcon
                  key={`separator-${index}`}
                  className="progress-button-separator"
                  icon={faChevronRight}
                />
              )}
            </React.Fragment>
          );
        })}
      </>
    );
  };

  const getProgresses = (): HotelPriceFreezeStep[] => {
    return [
      HotelPriceFreezeStep.REVIEW_PRICE_FREEZE_STEP,
      HotelPriceFreezeStep.BOOK_STEP,
    ];
  };

  return (
    <Box className="hotel-price-freeze-progress-bar-root">
      <Box className="hotel-price-freeze-progress-bar-container">
        <ProgressButtons progresses={getProgresses()} />
      </Box>
    </Box>
  );
};

interface IHotelPriceFreezeProgressButtonProps {
  selectedStep: HotelPriceFreezeStep;
  progress: HotelPriceFreezeStep;
  goToPriceFreezeExerciseCheckout: () => void;
}

const HotelPriceFreezeProgressButton = (
  props: IHotelPriceFreezeProgressButtonProps
) => {
  const { selectedStep, progress, goToPriceFreezeExerciseCheckout } = props;

  const getProgressText = (progress: HotelPriceFreezeStep) => {
    switch (progress) {
      case HotelPriceFreezeStep.REVIEW_PRICE_FREEZE_STEP:
        return constants.REVIEW_TEXT;

      case HotelPriceFreezeStep.BOOK_STEP:
        return constants.BOOK_TEXT;

      default:
        return "";
    }
  };

  const isDisabled = (progress: HotelPriceFreezeStep) => {
    switch (progress) {
      // This instance of the progress bar is only ever shown on the Hotel PF overview page
      // so the Review step will always be disabled since that's always the current step of
      // this progress bar
      case HotelPriceFreezeStep.REVIEW_PRICE_FREEZE_STEP:
        return false;

      case HotelPriceFreezeStep.BOOK_STEP:
        return true;

      default:
        return false;
    }
  };

  const handleOnClick = () => {
    if (progress == HotelPriceFreezeStep.BOOK_STEP) {
      if (goToPriceFreezeExerciseCheckout) goToPriceFreezeExerciseCheckout();
    }
  };

  return (
    <ActionLink
      className={clsx(
        "hotel-price-freeze-progress-button",
        { selected: selectedStep === progress },
        "b2b"
      )}
      onClick={handleOnClick}
      content={getProgressText(progress)}
      disabled={isDisabled(progress)}
    />
  );
};
