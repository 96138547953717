export type Provider =
  | Sabre
  | Amadeus
  | Travelport
  | Travelfusion
  | Datalex
  | Farelogix
  | Kiwi
  | Gdx
  | Navitaire;

export interface SingleProvider {
  pcc: string;
  Provider: ProviderType;
}

export interface Sabre extends SingleProvider {
  fare: SabreFareConfiguration;
}

export type FareConfiguration =
  | SabreFareConfiguration
  | TravelportFareConfiguration;

export interface SabreFareConfiguration {
  upsellFares: boolean;
  isMultiTicket: boolean;
  accountCodes: string[];
  isPrivateOnly: boolean;
  isPrivate: boolean;
  bulkPassengerType: boolean;
}

export enum ProviderType {
  Sabre,
  Travelport,
}

export interface Amadeus extends SingleProvider {}

export interface Travelport extends SingleProvider {
  fare: FareConfiguration;
}

export interface TravelportFareConfiguration {
  upsellFares: boolean;
}

export interface Datalex extends SingleProvider {}

export interface Farelogix extends SingleProvider {
  carrier: string;
}

export interface Kiwi extends SingleProvider {}

export interface Travelfusion {
  queryOrigin: string;
  queryDestination: string;
  queryOriginType?: string;
  queryDestinationType?: string;
  isRoundtrip: boolean;
}

export interface Gdx extends SingleProvider {
  cachedShops: number;
  subProviders: string[];
}

export interface Navitaire {
  base: NavitaireBase;
  carrier: NavitaireCarrier;
}

export interface NavitaireBase {
  outJourneyKey: string;
  outFareKey: string;
  inJourneyKey?: string;
  inFareKey?: string;
}

export enum NavitaireCarrier {
  Scoot,
  Volaris,
}
