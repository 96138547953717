import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router-dom";
import { IStoreState } from "../../../../reducers/types";
import { submitDisruptionFlightRefund } from "../../actions/actions";
import {
  selectedFlightDisruptionProtectionItinerarySelector,
  getPreparedRefundInfo,
  getSubmitDisruptionFlightRefundCallState,
  getDisruptionFlightRefundResult,
  eligibilityDpExerciseProductRedeemChoiceSelector,
  disruptionOverviewEligibilityDpExerciseFactsPropertiesSelector,
  isFintechCsatEnabledSelector,
} from "../../reducer/selectors";
import { DisruptionProtectionRefundStatusPopup } from "./component";

const mapStateToProps = (state: IStoreState) => {
  return {
    disruptedItinerary:
      selectedFlightDisruptionProtectionItinerarySelector(state),
    prepareRefundInfo: getPreparedRefundInfo(state),
    submitDisruptionFlightRefundCallState:
      getSubmitDisruptionFlightRefundCallState(state),
    disruptionFlightRefundResult: getDisruptionFlightRefundResult(state),
    eligibilityDpExerciseProductRedeemChoice:
      eligibilityDpExerciseProductRedeemChoiceSelector(state),
    disruptionOverviewEligibilityDpExerciseFactsProperties:
      disruptionOverviewEligibilityDpExerciseFactsPropertiesSelector(state),
    isFintechCsatEnabled: isFintechCsatEnabledSelector(state),
  };
};

const mapDispatchToProps = {
  submitDisruptionFlightRefund,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type DisruptionProtectionRefundStatusPopupConnectorProps =
  ConnectedProps<typeof connector>;

export const ConnectedDisruptionProtectionRefundStatusPopup = connector(
  withRouter(DisruptionProtectionRefundStatusPopup)
);
