import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../../../../reducers/types";
import {
  setOpenModal,
  setDeleteWatchCallState,
  setUpdateWatchCallState,
} from "../../../actions/actions";
import { getOpenModal } from "../../../reducer";
import { WatchItineraryModal } from "./component";

const mapStateToProps = (state: IStoreState) => ({
  openModal: getOpenModal(state),
});

const mapDispatchToProps = {
  setOpenModal,
  setDeleteWatchCallState,
  setUpdateWatchCallState,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type WatchItineraryModalConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedWatchItineraryModal = connector(WatchItineraryModal);
