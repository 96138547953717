import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router-dom";
import { IStoreState } from "../../reducers/types";
import { fetchFlights } from "../TripsList/actions/actions";
import {
  getAreAllFlightFetchingsInTerminalState,
  getAreAllFlightFetchingsNotCalled,
} from "../TripsList/reducer/selectors";
import {
  fetchFlightDisruptions,
  setSelectedFlightDisruptionProtectionItineraryId,
  fetchDisruptionPreparedFlightRefund,
  setDisruptionFlightRefundResult,
  setFetchDisruptionPreparedFlightRefundCallState,
  setPreparedRefundInfo,
  setSubmitDisruptionFlightRefundCallState,
} from "./actions/actions";
import {
  getSelectedFlightDisruptionProtectionItineraryId,
  selectedFlightDisruptionProtectionItinerarySelector,
  getAreSomeDisruptionInfoFetchingsInProcess,
  isDisruptionProtection24hRuleSatisfiedSelector,
  selectedFlightDisruptionProtectionEligibleSliceSelector,
  getFetchDisruptionPreparedFlightRefundCallState,
  isPostBookingOfferMyTripsEnabledSelector,
} from "./reducer/selectors";
import { DisruptionProtection } from "./component";

const mapStateToProps = (state: IStoreState) => {
  return {
    disruptionProtectionItinerary:
      selectedFlightDisruptionProtectionItinerarySelector(state),
    areAllFlightFetchingNotCalled: getAreAllFlightFetchingsNotCalled(state),
    areAllFlightFetchingInTerminalState:
      getAreAllFlightFetchingsInTerminalState(state),
    selectedFlightDisruptionProtectionItineraryId:
      getSelectedFlightDisruptionProtectionItineraryId(state),
    areSomeDisruptionInfoFetchingsInProcess:
      getAreSomeDisruptionInfoFetchingsInProcess(state),
    isDisruptionProtection24hRuleSatisfied:
      isDisruptionProtection24hRuleSatisfiedSelector(state),
    eligibleSlice:
      selectedFlightDisruptionProtectionEligibleSliceSelector(state),
    fetchDisruptionPreparedFlightRefundCallState:
      getFetchDisruptionPreparedFlightRefundCallState(state),
    isPostBookingOfferMyTripsEnabled:
      isPostBookingOfferMyTripsEnabledSelector(state),
  };
};

const mapDispatchToProps = {
  fetchFlights,
  setSelectedFlightDisruptionProtectionItineraryId,
  fetchDisruptionPreparedFlightRefund,
  fetchFlightDisruptions,
  setDisruptionFlightRefundResult,
  setFetchDisruptionPreparedFlightRefundCallState,
  setPreparedRefundInfo,
  setSubmitDisruptionFlightRefundCallState,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type DisruptionProtectionConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedDisruptionProtection = connector(
  withRouter(DisruptionProtection)
);
