import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import { HotelPriceFreezeProgressBar } from "./component";
import { IStoreState } from "../../../../reducers/types";

const mapStateToProps = (_: IStoreState) => {
  return {};
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type HotelShopProgressBarConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedHotelPriceFreezeProgressBar = connector(
  withRouter(HotelPriceFreezeProgressBar)
);
