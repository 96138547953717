import axios from "axios";
import { IFlightVoidAndExchangePolicyRes } from "redmond";

import { config } from "../../config";
import {
  getFlightExchangeEligibilityQuotePath,
} from "../paths";

const fetchFlightExchangePolicyV2 = (
  itineraryId: string,
): Promise<IFlightVoidAndExchangePolicyRes> =>
  new Promise(async (resolve, reject) => {
    try {
      const reservationId = itineraryId;
      const req = {reservationId};
      const res = await axios.post(getFlightExchangeEligibilityQuotePath, req, {
        baseURL: config.baseURL,
      });

      if (res.data.AirExchangeEligibilityQuoteResponse === "AirExchangeEligibilityQuoteSuccess") {
        resolve(res.data);
      } else {
        reject(res.data.error);
      }

    } catch (e) {
      reject(e);
    }
  });

export default fetchFlightExchangePolicyV2;
