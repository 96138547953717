import { GetExerciseEligibilitiesResponseV1 } from "redmond";
import { put } from "redux-saga/effects";
import { getFlightDisruptions } from "../../../api/v1/itinerary/getFlightDisruptions";

import { actions } from "../actions";

export function* fetchFlightDisruptionsSaga(
  action: actions.IFetchFlightDisruptions
) {
  try {
    const response: GetExerciseEligibilitiesResponseV1 =
      yield getFlightDisruptions(action.request);

    // The entire response contains disruptions info.
    const flightDisruptions = response;

    yield put(actions.setFlightDisruptions(flightDisruptions));
  } catch (e) {
    yield put(actions.setFetchFlightDisruptionsCallStateFailed());
  }
}
