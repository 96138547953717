import React, { useContext } from "react";
import { Box, Typography } from "@material-ui/core";
import {
  TripsHeader,
  DesktopPopupModal,
  MobilePopoverCard,
  CloseButtonIcon,
  ButtonWrap,
  useDeviceTypes,
  SecondaryHeader,
  TravelSalesEventBanner,
} from "halifax";
import clsx from "clsx";

import "./styles.scss";
import * as constants from "./constants";
import { ClientContext } from "../../../App";
import {
  CONTACT_SUPPORT_URL,
  BASE_PATH_HOME,
  PATH_TRAVEL_SALE,
} from "../../../utils/paths";
import H from "history";
import {
  useExperiments,
  getExperimentVariantCustomVariants,
  ActiveExperiments,
  TRAVEL_SALE_VARIANTS,
  CONTROL,
  TRAVEL_SALE_ACTIVE,
} from "../../../context/experiments";
import {
  TRAVEL_SALES_EVENT_ACTIVE_CTA,
  TRAVEL_SALES_EVENT_ACTIVE_SUBTITLE,
  TRAVEL_SALES_EVENT_LEADUP_CTA,
  TRAVEL_SALES_EVENT_LEADUP_SUBTITLE,
} from "../../TripsList/constants";

export interface IMyTripsHeaderProps {
  className?: string;
  tripHeaderCopy?: {
    title?: string;
    subtitle?: string;
  };
  onClick?: () => void;
  hidden?: boolean;
  // note: this prop allows openContactModal & setOpenContactModal to be passed in through the consumer
  contactModalController?: {
    openContactModal: boolean;
    setOpenContactModal: React.Dispatch<React.SetStateAction<boolean>>;
  };
  history?: H.History;
  useSecondaryHeader?: boolean;
}

export const MyTripsHeader = (props: IMyTripsHeaderProps) => {
  const {
    className,
    tripHeaderCopy,
    onClick,
    hidden,
    contactModalController,
    history,
    useSecondaryHeader,
  } = props;
  const { matchesMobile } = useDeviceTypes();
  const clientContext = useContext(ClientContext);
  const [openContactModalLocal, setOpenContactModalLocal] =
    React.useState<boolean>(false);

  const expState = useExperiments();

  const travelSaleVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    ActiveExperiments.TRAVEL_SALE,
    TRAVEL_SALE_VARIANTS
  );

  const openContactModal =
    contactModalController?.openContactModal ?? openContactModalLocal;
  const setOpenContactModal =
    contactModalController?.setOpenContactModal ?? setOpenContactModalLocal;

  const handleOnClose = () => {
    setOpenContactModal(false);
  };

  const ClientLogo = onClick ? (
    <Box className="client-icon-wrapper">
      <ButtonWrap onClick={onClick}>{clientContext.logo}</ButtonWrap>
    </Box>
  ) : undefined;

  const TripsHeaderContent = (
    <>
      <Typography variant="h2" className="content-header">
        {constants.CONTACT_SUPPORT_HEADER}
      </Typography>
      <Typography variant="h4" className="content-text">
        {constants.CONTACT_SUPPORT_TEXT}
      </Typography>
      <Typography variant="h2" className="content-phone">
        {constants.CONTACT_SUPPORT_NUMBER}
      </Typography>
    </>
  );

  const headerBreadCrumbLinks = history
    ? [
        {
          onClick: () => {
            history.push(BASE_PATH_HOME);
          },
          label: constants.SEARCH,
        },
        { label: constants.TITLE },
      ]
    : undefined;

  return (
    <>
      {!hidden &&
        (useSecondaryHeader && !matchesMobile ? (
          <SecondaryHeader
            title={constants.TITLE}
            subtitle={matchesMobile ? undefined : constants.REDESIGN_SUBTITLE}
            className="trips"
            breadCrumbLinks={headerBreadCrumbLinks}
            rightContent={
              history && travelSaleVariant !== CONTROL ? (
                <TravelSalesEventBanner
                  variant={
                    travelSaleVariant === TRAVEL_SALE_ACTIVE
                      ? "default"
                      : "with-timer"
                  }
                  onClick={() =>
                    window.open(
                      `${PATH_TRAVEL_SALE}?entryType=my_trips`,
                      "_blank"
                    )
                  }
                  subtitle={
                    travelSaleVariant === TRAVEL_SALE_ACTIVE
                      ? TRAVEL_SALES_EVENT_ACTIVE_SUBTITLE
                      : TRAVEL_SALES_EVENT_LEADUP_SUBTITLE
                  }
                  buttonText={
                    travelSaleVariant === TRAVEL_SALE_ACTIVE
                      ? TRAVEL_SALES_EVENT_ACTIVE_CTA
                      : TRAVEL_SALES_EVENT_LEADUP_CTA
                  }
                />
              ) : undefined
            }
          />
        ) : (
          <TripsHeader
            icon={ClientLogo}
            tripHeaderCopy={{
              title: tripHeaderCopy?.title ?? constants.TITLE,
              subtitle: tripHeaderCopy?.subtitle ?? constants.SUBTITLE,
              faqTitle: constants.FAQ_TITLE,
              faqCTA: constants.FAQ_CTA,
            }}
            className={clsx("my-trips-header-root", className, {
              mobile: matchesMobile,
            })}
            isMobile={matchesMobile}
            onFAQClick={() => {
              window.open(CONTACT_SUPPORT_URL, "_blank")?.focus();
            }}
          />
        ))}
      {matchesMobile ? (
        <MobilePopoverCard
          className="mobile-contact-support-popup"
          open={openContactModal}
          onClose={handleOnClose}
          centered
          topRightButton={<CloseButtonIcon onClick={handleOnClose} />}
        >
          <Box className="mobile-contact-support-popup-content">
            {TripsHeaderContent}
          </Box>
        </MobilePopoverCard>
      ) : (
        <DesktopPopupModal
          open={openContactModal}
          className="desktop-contact-support-popup"
          onClose={handleOnClose}
          invisibleBackdrop={false}
        >
          <Box className="desktop-contact-support-popup-content">
            {TripsHeaderContent}
          </Box>
        </DesktopPopupModal>
      )}
    </>
  );
};
