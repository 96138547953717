import React from "react";
import { Box, Divider, Typography } from "@material-ui/core";
import {
  Airline,
  AirlineMap,
  Airport,
  AssociatedPassenger,
  getDepartureSlice,
  getFlightInfoDetails,
  getItinerarySummaryProps,
  getRestrictionInfo,
  getReturnSlice,
  IOpenModal,
  IPerson,
  ItineraryEnum,
  ItineraryWithType,
  MyTripsFilter,
  MyTripsModalTypes,
  PackageItinerary,
  ScheduleChangeSeverity,
} from "redmond";
import dayjs from "dayjs";
import {
  MyTripsCard,
  TripInfoDetails,
  ActionLinks,
  IconName,
  IActionLink,
  useDeviceTypes,
  ActionLink,
  FlightDetailsSummary,
  MixedCabinToolTip,
  Restriction,
  SelectedSeatsConfirmation,
  StatusTag,
} from "halifax";
import {
  MOBILE_OFFSET_SCROLL,
  DESKTOP_OFFSET_SCROLL,
} from "../../../constants";
import { getStatusTag, getTitleTag } from "../../FlightCard/component";
import clsx from "clsx";
import * as textConstants from "../constants";
import { isTFBooking, isViMultiTicket } from "../../../../../utils";
import { airlinesCountFlightItinerarySegment } from "../../FlightCard/helpers";
import { ConfirmationSummary } from "../../ConfirmationSummary";
import { getSeatSegments } from "../../FlightCard/components/DesktopFlightCard/component";
import { FlightVICombinationBanner } from "../../FlightVICombinationBanner";
import SeatUpdateInstructions from "../../SeatUpdateInstructions/component";
import { SummaryCard } from "../../SummaryCard";
import { TravelersSummary } from "../../TravelersSummary";
import { getIsMixedClass } from "../../../../../constants";
import "./styles.scss";
import { getCurrencyString } from "../../../../../../../utils/helpers";
import { PaymentSummary } from "../../PaymentSummary";
import { getFlightConfirmationNumbers } from "../../ItinerariesModal/components/ConfirmationModalPackageContent/component";

interface IDesktopFlightCardProps {
  airlineMap: { [key: string]: Airline };
  airportMap: { [key: string]: Airport };
  expandedCard: string;
  packageItinerary: PackageItinerary;
  banner?: JSX.Element;
  confirmationCodeClassName?: string;
  isPastTrips: boolean;
  isCanceled?: boolean;
  isMobile?: boolean;
  onExpandCard: (cardId: string) => void;
  setOpenModal: (modalType: IOpenModal) => void;
  tripsFilter: MyTripsFilter;
}

export const addPackageType = (
  packageItinerary: PackageItinerary
): ItineraryWithType => ({
  ...packageItinerary,
  type: ItineraryEnum.Package,
});

export const DesktopFlightCard = (props: IDesktopFlightCardProps) => {
  const {
    airlineMap,
    airportMap,
    expandedCard,
    packageItinerary,
    isMobile,
    banner,
    confirmationCodeClassName,
    // isPastTrips,
    isCanceled,
    onExpandCard,
    setOpenModal,
    tripsFilter,
  } = props;

  const { matchesMobile } = useDeviceTypes();
  const { pricingBreakdown, paymentBreakdown, flight } = packageItinerary;

  const flightItinerary = flight.itinerary;

  const { bookedItinerary } = flightItinerary;
  const {
    id: itineraryId,
    passengers,
    scheduleChange,
    seats,
    travelItinerary,
  } = bookedItinerary;
  const isTF = isTFBooking(travelItinerary);

  const passengerMap = {} as Record<string, IPerson>;

  passengers.alone.reduce((map, p) => {
    if (!map[p.person.id]) {
      map[p.person.id] = p.person;
    }
    return passengerMap;
  }, passengerMap);

  const travelerNames = Object.values(passengerMap).map(
    (person) => `${person?.givenName} ${person?.surname}`
  );

  const departureSlice = getDepartureSlice(bookedItinerary);
  const returnSlice = getReturnSlice(bookedItinerary);

  const isMultiTicketType = isViMultiTicket(bookedItinerary.multiTicketType);

  const onOpenModal = (type: MyTripsModalTypes) =>
    setOpenModal({
      type,
      selectedItinerary: addPackageType(packageItinerary),
    });

  const airlinesCount =
    (isMultiTicketType &&
      airlinesCountFlightItinerarySegment(departureSlice.segments)) ||
    (returnSlice && airlinesCountFlightItinerarySegment(returnSlice.segments));

  const hasMajorScheduleChange =
    scheduleChange?.severity === ScheduleChangeSeverity.Major;

  const showSeatsSection =
    tripsFilter === MyTripsFilter.UPCOMING_TRIPS || Boolean(seats);
  const showSeatsInstr = tripsFilter === MyTripsFilter.UPCOMING_TRIPS;

  const isOutgoingMixedClass = getIsMixedClass(departureSlice);
  const isReturnMixedClass = !!returnSlice
    ? getIsMixedClass(returnSlice)
    : false;
  const departureStatusTag = getStatusTag(
    flightItinerary,
    0,
    scheduleChange,
    airportMap,
    airlineMap as AirlineMap,
    isTF
  );
  const returnStatusTag = getStatusTag(
    flightItinerary,
    1,
    scheduleChange,
    airportMap,
    airlineMap as AirlineMap,
    isTF
  );

  const getActions = () => {
    const actions: IActionLink[] = [];
    if (!isCanceled) {
      actions.push({
        content: textConstants.CANCEL_FLIGHT,
        onClick: () => onOpenModal(MyTripsModalTypes.SelfServeCancelFlight),
      });
    }
    actions.push({
      content: textConstants.RESEND_CONFIRMATION,
      onClick: () => onOpenModal(MyTripsModalTypes.ResendConfirmation),
    });
    return actions;
  };

  const renderViewScheduleLink = () => (
    <ActionLink
      className="view-update-link"
      onClick={() => {
        setOpenModal({
          type: MyTripsModalTypes.ScheduleChange,
          selectedItinerary: {
            ...flightItinerary,
            type: ItineraryEnum.Flight,
          },
        });
      }}
      content={textConstants.MAJOR_SCHEDULE_CHANGE_CTA_LABEL}
    />
  );

  return (
    <Box
      id={itineraryId}
      key={itineraryId}
      className={clsx("package-flight-trip-container", {
        mobile: isMobile,
        expanded: expandedCard === itineraryId,
      })}
    >
      <MyTripsCard
        className="trip-card-title"
        banner={banner}
        content={
          <TripInfoDetails
            confirmationCodeClassName={confirmationCodeClassName}
            hideTitleTag={isCanceled || hasMajorScheduleChange}
            isMultiTicketType={isMultiTicketType}
            airlinesCount={airlinesCount}
            titles={{
              confirmationLabel: textConstants.CONFIRMATION,
              startLabel: `${textConstants.DEPARTURE}:`,
              endLabel: `${textConstants.RETURN}:`,
              titleTag: getTitleTag(
                flightItinerary,
                airlineMap as AirlineMap,
                scheduleChange?.severity === ScheduleChangeSeverity.Major
              ),
              ...getFlightInfoDetails(
                bookedItinerary.travelItinerary.locators,
                getDepartureSlice(bookedItinerary),
                getReturnSlice(bookedItinerary),
                (date: string) => dayjs(date).format(textConstants.DATE_FORMAT),
                airportMap,
                airlineMap
              ),
            }}
          />
        }
        actions={<ActionLinks actions={getActions()} />}
        isExpanded={expandedCard === itineraryId}
        expandString={
          expandedCard === itineraryId
            ? textConstants.VIEW_LESS
            : textConstants.VIEW_DETAILS
        }
        expandIcon={
          expandedCard === itineraryId
            ? IconName.MinusBlueCircle
            : IconName.PlusBlueCircle
        }
        onExpandClick={() => {
          onExpandCard(itineraryId);
          setTimeout(() => {
            const OFFSET = matchesMobile
              ? MOBILE_OFFSET_SCROLL
              : DESKTOP_OFFSET_SCROLL;
            const cardTop =
              document?.getElementById(itineraryId)?.getBoundingClientRect()
                .top || 0;
            window.scrollBy({
              top: (cardTop as number) - OFFSET,
              behavior: matchesMobile ? "auto" : "smooth",
            });
          }, 100);
        }}
        travelers={travelerNames}
      />
      {itineraryId === expandedCard && (
        <Box className="desktop-expanded-container">
          <Box className="details-info-container">
            <Box className="slice-info">
              <Box className="slice-info-title">
                <Typography variant="subtitle2">
                  <span className="direction-label">
                    {textConstants.OUTBOUND}
                  </span>
                  {textConstants.getItineraryDetailsHeader(
                    flightItinerary,
                    true,
                    airportMap
                  )}
                  {isOutgoingMixedClass && <MixedCabinToolTip />}
                </Typography>
                {departureStatusTag && (
                  <>
                    <StatusTag tagInfo={departureStatusTag} />
                    {hasMajorScheduleChange && renderViewScheduleLink()}
                  </>
                )}
              </Box>
              <FlightDetailsSummary
                className="outgoing"
                flightCombinationBanner={
                  airlinesCountFlightItinerarySegment(departureSlice.segments) >
                    0 && <FlightVICombinationBanner isMobile={matchesMobile} />
                }
                showTitle={false}
                {...getItinerarySummaryProps(
                  flightItinerary,
                  true,
                  airportMap,
                  airlineMap
                )}
                isMixedCabinClass={isOutgoingMixedClass}
              />

              <Divider className="divider" />
              <Typography className="fare-details-header" variant="subtitle1">
                {textConstants.FARE_DETAILS}
              </Typography>
              <Box className="trip-itinerary-restrictions">
                {getRestrictionInfo(
                  flightItinerary,
                  isTF,
                  departureSlice,
                  departureSlice.fareShelf?.brandName
                ).map((restriction, index) => (
                  <Restriction
                    key={`restriction-${index}`}
                    name={restriction.name}
                    descriptionString={restriction.description}
                    symbol={restriction.symbol}
                  />
                ))}
              </Box>
              {showSeatsSection && (
                <>
                  <Divider className="divider" />
                  <Box className="seat-selection">
                    <Typography
                      className="seat-selection-header"
                      variant="subtitle1"
                    >
                      {textConstants.SEAT_SELECTION}
                    </Typography>
                    {showSeatsInstr && (
                      <SeatUpdateInstructions
                        airlineMap={airlineMap as AirlineMap}
                        slice={departureSlice}
                      />
                    )}
                    {seats && (
                      <Box className="outbound-seat-selection">
                        <SelectedSeatsConfirmation
                          outboundSeatSegments={getSeatSegments(
                            seats,
                            departureSlice.segments,
                            passengerMap,
                            true
                          )}
                          outboundOriginCode={
                            departureSlice.segments[0]?.origin.locationCode
                          }
                          returnOriginCode={
                            returnSlice?.segments[0]?.origin.locationCode
                          }
                          airports={airportMap}
                        />
                      </Box>
                    )}
                  </Box>
                </>
              )}
            </Box>
            {returnSlice && (
              <Box className="slice-info return">
                <Box className="slice-info-title">
                  <Typography variant="subtitle2">
                    <span className="direction-label">
                      {textConstants.RETURN}
                    </span>
                    {textConstants.getItineraryDetailsHeader(
                      flightItinerary,
                      false,
                      airportMap
                    )}
                    {isReturnMixedClass && <MixedCabinToolTip />}
                  </Typography>
                  {returnStatusTag && (
                    <>
                      <StatusTag tagInfo={returnStatusTag} />
                      {hasMajorScheduleChange && renderViewScheduleLink()}
                    </>
                  )}
                </Box>
                <FlightDetailsSummary
                  showTitle={false}
                  flightCombinationBanner={
                    airlinesCountFlightItinerarySegment(returnSlice.segments) >
                      0 && (
                      <FlightVICombinationBanner isMobile={matchesMobile} />
                    )
                  }
                  className="return"
                  {...getItinerarySummaryProps(
                    flightItinerary,
                    false,
                    airportMap,
                    airlineMap
                  )}
                  isMixedCabinClass={isReturnMixedClass}
                />

                <Divider className="divider" />
                <Typography className="fare-details-header" variant="subtitle1">
                  {textConstants.FARE_DETAILS}
                </Typography>
                <Box className="trip-itinerary-restrictions">
                  {getRestrictionInfo(
                    flightItinerary,
                    isTF,
                    returnSlice,
                    returnSlice.fareShelf?.brandName
                  ).map((restriction, index) => (
                    <Restriction
                      key={`restriction-${index}`}
                      name={restriction.name}
                      descriptionString={restriction.description}
                      symbol={restriction.symbol}
                    />
                  ))}
                </Box>
                {showSeatsSection && (
                  <>
                    <Divider className="divider" />
                    <Box className="seat-selection">
                      <Typography
                        className="seat-selection-header"
                        variant="subtitle1"
                      >
                        {textConstants.SEAT_SELECTION}
                      </Typography>
                      {showSeatsInstr && (
                        <SeatUpdateInstructions
                          airlineMap={airlineMap as AirlineMap}
                          slice={returnSlice}
                        />
                      )}
                      {seats && (
                        <Box className="return-seat-selection">
                          <SelectedSeatsConfirmation
                            outboundSeatSegments={getSeatSegments(
                              seats,
                              returnSlice.segments,
                              passengerMap,
                              false
                            )}
                            outboundOriginCode={
                              departureSlice?.segments[0]?.origin.locationCode
                            }
                            returnOriginCode={
                              returnSlice?.segments[0]?.origin.locationCode
                            }
                            airports={airportMap}
                          />
                        </Box>
                      )}
                    </Box>
                  </>
                )}
              </Box>
            )}
          </Box>
          <Box className="summary-info-container">
            <SummaryCard
              className="travellers-summary"
              action={
                <ActionLink
                  className="details-link"
                  onClick={() => onOpenModal(MyTripsModalTypes.TravelersModal)}
                  content="Details"
                />
              }
            >
              <TravelersSummary
                travelers={passengers.withLapInfants
                  .reduce(
                    (acc, awli) => [
                      ...acc,
                      awli.adult as AssociatedPassenger,
                      awli.infant as AssociatedPassenger,
                    ],
                    [] as AssociatedPassenger[]
                  )
                  .concat(passengers.alone)
                  .map(({ person }: AssociatedPassenger) => person?.givenName)
                  .join(", ")}
              />
            </SummaryCard>
            {paymentBreakdown && (
              <SummaryCard
                className="payment-summary"
                action={
                  <ActionLink
                    className="details-link"
                    onClick={() => onOpenModal(MyTripsModalTypes.PaymentModal)}
                    content="Details"
                  />
                }
              >
                <PaymentSummary
                  tripTotalAmount={getCurrencyString(
                    pricingBreakdown.subtotal.totalAmount.fiat
                  )}
                  cardLabel={
                    textConstants.getLabelsFromPaymentBreakdown(
                      paymentBreakdown
                    ).cardLabel
                  }
                  cardAmount={
                    textConstants.getValues(paymentBreakdown).cardValue
                  }
                  rewardsLabel={
                    textConstants.getLabelsFromPaymentBreakdown(
                      paymentBreakdown
                    ).rewardLabel
                  }
                  rewardsAmount={
                    textConstants.getValues(paymentBreakdown).rewardValue
                  }
                  showCardLabel={Boolean(
                    textConstants.getLabelsFromPaymentBreakdown(
                      paymentBreakdown
                    ).cardLabel
                  )}
                  showRewardsLabel={Boolean(
                    textConstants.getLabelsFromPaymentBreakdown(
                      paymentBreakdown
                    ).rewardLabel
                  )}
                />
              </SummaryCard>
            )}

            <SummaryCard
              className="confirmation-summary"
              action={
                <ActionLink
                  className="details-link"
                  onClick={() =>
                    onOpenModal(MyTripsModalTypes.ConfirmationModal)
                  }
                  content={`View all (${
                    getFlightConfirmationNumbers({
                      flight: flightItinerary,
                      airlineMap,
                    }).length + 2
                  })`}
                />
              }
            >
              <ConfirmationSummary
                confirmationCode={`H-${packageItinerary.customerConfirmationId}`}
              />
            </SummaryCard>
          </Box>
        </Box>
      )}
    </Box>
  );
};
