import { setSliceSelectedForMcpVi } from "./../../actions/actions";
import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router-dom";
import { IStoreState } from "../../../../reducers/types";

import { MissedConnectionProtectionLanding } from "./component";
import {
  getFlightDisruptions,
  getSelectedSliceIndexForMCPVirtualInterline,
  isDisruptionProtection24hRuleEnabledSelector,
  selectedFlightDisruptionProtectionItinerarySelector,
  selectedFlightDisruptionProtectionItinerarySelectorAgentPortal,
} from "../../reducer";
import { getAirportMap } from "../../../TripsList/reducer";

interface IOwnProps {
  isAgentPortal?: boolean;
}

const mapStateToProps = (state: IStoreState, {isAgentPortal}: IOwnProps) => ({
  flight: isAgentPortal 
    ? selectedFlightDisruptionProtectionItinerarySelectorAgentPortal(state) 
    : selectedFlightDisruptionProtectionItinerarySelector(state),
  airports: getAirportMap(state),
  flightDisruptions: getFlightDisruptions(state),
  sliceIndex: getSelectedSliceIndexForMCPVirtualInterline(state),
  isDisruptionProtection24hRuleEnabled:
    isDisruptionProtection24hRuleEnabledSelector(state),
});

const mapDispatchToProps = {
  setSliceSelectedForMcpVi,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MissedConnectionProtectionLandingConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedMissedConnectionProtectionLanding = connector(
  withRouter(MissedConnectionProtectionLanding)
);
