import { put } from "redux-saga/effects";
import {
  IFetchHotelPriceFreezeDetails,
  setFetchHotelPriceFreezeDetailsCallStateFailed,
  setHotelPriceFreezeDetails,
} from "../../../pages/PriceFreeze/actions/actions";
import {
  GetHotelDetailResult,
  ResultEnum,
  GetHotelDetailResultOk,
} from "redmond";
import { fetchHotelPriceFreezeDetails } from "../../../api/v1/priceFreeze/fetchHotelPriceFreezeDetails";
import Logger from "../../../utils/logger";

export function* fetchHotelPriceFreezeDetailsSaga(
  action: IFetchHotelPriceFreezeDetails
) {
  try {
    const { request } = action;

    const response: GetHotelDetailResult = yield fetchHotelPriceFreezeDetails(
      request
    );

    if (response.Result == ResultEnum.Ok) {
      const successResponse: GetHotelDetailResultOk =
        response as GetHotelDetailResultOk;

      yield put(
        setHotelPriceFreezeDetails({ detail: successResponse.value.detail })
      );
    } else {
      yield put(setFetchHotelPriceFreezeDetailsCallStateFailed());
    }
  } catch (e) {
    yield put(setFetchHotelPriceFreezeDetailsCallStateFailed());
    Logger.debug(e);
  }
}
