import React from "react";
import { Box, Typography } from "@material-ui/core";
import { CarReservation } from "redmond";

import { CANCELLATION_POLICY, FUEL_POLICY } from "./constants";
import "./styles.scss";

export interface ICarInfoContent {
  car: CarReservation;
}

export const CarCancellationPolicy = ({ car }: ICarInfoContent) => {
  const description = car.bookResult.rentalRate.extensions.cancellation.desc;
  if (!description) return null;
  return (
    <>
      <Typography variant="body1" className="cancellation-title">
        {CANCELLATION_POLICY}
      </Typography>
      <Typography variant="body1">{description}</Typography>
    </>
  );
};

export const FuelPolicy = ({ car }: ICarInfoContent) => {
  const fuelPolicy = car.bookResult.rentalRate.extensions.fuelPolicy?.desc;
  if (!fuelPolicy) return null;
  return (
    <>
      <Typography variant="body1" className="fuel-policy-title">
        {FUEL_POLICY}
      </Typography>
      <Typography variant="body1">{fuelPolicy}</Typography>
    </>
  );
};

export const CarInfoContent = ({
  car,
}: ICarInfoContent): React.ReactElement | null => {
  if (!car) {
    return null;
  }
  return (
    <Box className="car-shop-room-type-room-details-list">
      <CarCancellationPolicy car={car} />
      <FuelPolicy car={car} />
    </Box>
  );
};
