import { B2BColorConfig, ColorConfig, defaultColors } from "../color-config";

export const installColorConfig = (config: ColorConfig): void =>
  Object.keys(config).forEach((key) => {
    document.body.style.setProperty(`--${key}`, (config as any)[key]);
  });

export const defaultCorpColors: B2BColorConfig = {
  ...defaultColors,
  "primary-blue": "rgba(2, 118, 177, 1.0)",
  "secondary-blue": "rgba(2, 118, 177, 1.0)",
  "tertiary-blue": "rgba(2, 101, 151, 1.0)",
  "blue-9": "rgba(1, 61, 88, 1.0)",
  "blue-10": "rgba(0, 192, 229, .1)",
  "blue-14-transparent": "rgba(16, 37, 63, 0.9)",
  white: "rgba(255, 255, 255, 1.0)",
  "white-banner-text": "rgba(255, 255, 255, 0.75)",
  "white-transparent": "rgba(255, 255, 255, 0.48)",
  "box-shadow": "rgba(0, 0, 0, 0.05)",
  "backdrop-shadow": "rgba(0, 0, 0, 0.25)",
  "primary-text-grey": "rgba(20, 20, 20, 1.0)",
  "secondary-text-grey": "rgba(103, 104, 104, 1.0)",
  "tertiary-text-grey": "rgba(206, 207, 205, 1.0)",
  "grey-5": "rgba(206, 207, 205, 1.0)",
  "grey-6": "rgba(103, 104, 104, 1.0)",
  "grey-7": "rgba(103, 104, 104, 1.0)",
  "grey-8": "rgba(206, 207, 205, 1.0)",
  "grey-9": "rgba(103, 104, 104, 1.0)",
  "grey-10": "rgba(206, 207, 205, 1.0)",
  "grey-11": "rgba(206, 207, 205, 1.0)",
  "grey-11-transparent": "#FBFBFB",
  "grey-12": "rgba(103, 104, 104, 1.0)",
  "grey-13": "rgba(103, 104, 104, 1.0)",
  "grey-14": "rgba(103, 104, 104, 1.0)",
  "inactive-grey": "rgba(246, 246, 246, 1.0)",
  "inactive-button-icon-grey": "rgba(206, 207, 205, 1.0)",
  "action-button-inactive-background": "rgba(244, 244, 244, 1.0)",
  "action-button-inactive-text": "rgba(103, 104, 104, 1.0)",
  "action-button-inactive-border": "rgba(206, 207, 205, 1.0)",
  "action-button-active-background": "rgba(36, 96, 130, 1.0)",
  "action-button-active-text": "rgba(255, 255, 255, 1.0)",
  "action-link-active-text": "rgba(36, 96, 130, 1.0)",
  "info-details-popup-background": "rgba(251, 251, 251, 1.0)",
  "info-details-popup-border": "rgba(216, 216, 216, 1.0)",
  "calendar-green": "rgba(111, 194, 139, 1.0)",
  "calendar-yellow": "rgba(240, 160, 42, 1.0)",
  "calendar-orange": "rgba(227, 102, 36, 1.0)",
  "calendar-red": "rgba(194, 10, 33, 1.0)",
  "slider-bar-highlighted": "rgba(0, 129, 64, 1.0)",
  "slider-bar-inactive": "rgba(244, 244, 244, 1.0)",
  "badge-green-background": "rgba(240, 245, 243, 1.0)",
  "text-green": "rgba(99, 182, 127, 1.0)",
  "text-green-2": "rgba(0, 129, 64, 1.0)",
  "red-1": "rgba(250, 104, 102, 1.0)",
  "red-2": "rgba(224, 32, 32, 1.0)",
  "red-3": "",
  "red-4": "rgba(171, 51, 43, 1)",
  "yellow-1": "rgba(217, 148, 34, 1.0)",
  "yellow-2": "rgba(247, 181, 0, 1.0)",
  "yellow-3": "rgba(230, 145, 0, 1.0)",
  "green-1": "rgba(56, 185, 122, 1.0)",
  "light-spinner-blue": "rgba(58, 188, 253, 1.0)",
  "ftux-background-blue": "rgba(18, 39, 65, 1)",
  "ftux-background-blue-transparent": "rgba(18, 39, 65, 0.95)",
  "takeover-background-blue": "rgba(225, 245, 255, 1)",
  "price-freeze-text": "",
  // CALENDAR COLORS
  "bucket-0-color": "rgba(0, 129, 64, 1.0)",
  "bucket-1-color": "rgba(214, 129, 0, 1)",
  "bucket-2-color": "rgba(227, 102, 36, 1.0)",
  "bucket-3-color": "rgba(204, 36, 39, 1.0)",
  "white-font-color": "rgba(255, 255, 255, 1.0)",
  "grey-font-color": "rgba(20, 20, 20, 1.0)",
  "date-hover-color": "rgba(0, 118, 193, 1.0)",
  "date-selected-color": "rgba(2, 101, 151, .1)",
  "date-range-limit-color": "rgba(0, 118, 193, 1.0)",
  "date-disabled-color": "rgba(244, 244, 244, 1.0)",
  // ALGOMERCH COLORS
  "algomerch-airline": "rgba(204, 36, 39, 1.0)",
  "algomerch-fareclass": "rgba(0, 118, 193, 1.0)",
  "algomerch-price": "rgba(0, 129, 64, 1.0)",
  "algomerch-duration": "rgba(240, 160, 42, 1.0)",
  "algomerch-stops": "rgba(1, 61, 88, 1.0)",
  // PRICE PREDICTION COLORS
  "price-prediction-wait": "rgba(199, 1, 22, 1.0)",
  "price-prediction-fair": "rgba(89, 158, 42, 1)",
  "price-prediction-good": "rgba(43, 143, 54, 1)",
  "price-prediction-great": "rgba(13, 134, 61, 1)",
  // PRICE PREDICTION GRADIENT COLORS
  "price-prediction-gradient-red": "rgba(199, 1, 22, 1.0)",
  "price-prediction-gradient-orange": "rgba(236, 130, 65, 1.0)",
  "price-prediction-gradient-yellow": "rgba(249, 209, 0, 1.0)",
  "price-prediction-gradient-green": "rgba(0, 129, 64, 1.0)",
  "price-prediction-gradient-green-transparent": "rgba(0, 129, 64, 0.05)",
  "tag-background-grey": "rgba(216, 216, 216, 0.2)",
  // SCHEDULE CHANGE COLORS
  "red-error-color": "rgba(204, 36, 39, 1.0)",
  "red-error-color-transparent": "rgba(252, 213, 209, 1.0)",
  "warning-error-color": "rgba(249, 198, 6, 1.0)",
  "warning-error-color-light": "rgba(249, 198, 6, 0.12)",
  "warning-error-color-transparent": "rgba(255, 241, 208, 1.0)",
  "loading-modal-background": "#10253F",
  "fare-card-background": "rgba(255, 255, 255, 1.0)",
  "luxury-branding-blue": "#255F82",
};
