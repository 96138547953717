export enum CfarFlightModalStep {
  LoadingCancellationPolicy,
  // note: only available in "Future Travel Credit Available, With Waiver or Without Waiver"
  Choice,
  Details,
  // note: only available in workflows that don't require picking an option
  Confirmation,
  CancellationInProgress,
  // note: it can be either Success or Fail
  Complete,
}
