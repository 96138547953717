import React from "react";
import { Box, Link } from "@material-ui/core";
import { LodgingData } from "redmond";
import { HotelName, HotelAddress, HotelMapPin } from "halifax";
import { GoogleMap, OverlayView } from "@react-google-maps/api";
import { convertICoordinatesToGoogleMapCoords } from "../../../../../../../../utils/googleMapsHelpers";
import "./styles.scss";

export interface IHotelMapProps {
  lodging: LodgingData;
  hideLabel?: boolean;
}

export const HotelMap = (props: IHotelMapProps) => {
  const { lodging, hideLabel } = props;
  if (!lodging?.location) {
    return <></>;
  }
  const center = convertICoordinatesToGoogleMapCoords(
    lodging.location.coordinates
  );

  return (
    <Box className="hotel-map-container">
      <GoogleMap
        id="hotel-map"
        mapContainerStyle={{ height: "100%", width: "100%" }}
        options={{
          clickableIcons: false,
          zoomControl: false,
          streetViewControl: false,
          mapTypeControl: false,
          draggable: false,
          fullscreenControl: false,
          draggableCursor: "pointer",
        }}
        center={center}
        zoom={16}
      >
        <SmallHotelMapContent {...props}></SmallHotelMapContent>
      </GoogleMap>
      {!hideLabel && (
        <Link component="button" className="hotel-map-label">
          <HotelName name={lodging?.name} />
          <HotelAddress address={lodging.address} showFullAddress />
        </Link>
      )}
    </Box>
  );
};

const SmallHotelMapContent = (props: IHotelMapProps) => {
  const { lodging } = props;
  if (!lodging.location) {
    return <></>;
  }
  const center = convertICoordinatesToGoogleMapCoords(
    lodging.location.coordinates
  );

  return (
    <>
      <OverlayView
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        position={center}
      >
        <HotelMapPin />
      </OverlayView>
    </>
  );
};
