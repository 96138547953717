import {
  CallState,
  GetDisruptionRefundRequest,
  PreparedRefundInfo,
  GetExerciseEligibilitiesRequestV1,
  GetExerciseEligibilitiesResponseV1,
  SubmitDisruptionRefundRequest,
  DisruptionFlightRefundResult,
} from "redmond";
import * as actionTypes from "./constants";
import { IDisruptionProtectionExperiments } from "../reducer";

export interface IFetchFlightDisruptions {
  type: actionTypes.FETCH_FLIGHT_DISRUPTIONS;
  request: GetExerciseEligibilitiesRequestV1;
}

export const fetchFlightDisruptions = (
  request: GetExerciseEligibilitiesRequestV1
): IFetchFlightDisruptions => ({
  type: actionTypes.FETCH_FLIGHT_DISRUPTIONS,
  request,
});

export interface ISetFetchFlightDisruptionsCallStateFailed {
  type: actionTypes.SET_FETCH_FLIGHT_DISRUPTIONS_CALL_STATE_FAILED;
}

export const setFetchFlightDisruptionsCallStateFailed =
  (): ISetFetchFlightDisruptionsCallStateFailed => ({
    type: actionTypes.SET_FETCH_FLIGHT_DISRUPTIONS_CALL_STATE_FAILED,
  });

export interface ISetFlightDisruptions {
  type: actionTypes.SET_FLIGHT_DISRUPTIONS;
  flightDisruptions: GetExerciseEligibilitiesResponseV1;
}

export const setFlightDisruptions = (
  flightDisruptions: GetExerciseEligibilitiesResponseV1
): ISetFlightDisruptions => ({
  type: actionTypes.SET_FLIGHT_DISRUPTIONS,
  flightDisruptions,
});

export interface ISetSelectedFlightDisruptionProtectionItineraryId {
  type: actionTypes.SET_SELECTED_FLIGHT_DISRUPTION_PROTECTION_ITINERARY_ID;
  itineraryId: string | null;
}

export const setSelectedFlightDisruptionProtectionItineraryId = (
  itineraryId: string | null
): ISetSelectedFlightDisruptionProtectionItineraryId => ({
  type: actionTypes.SET_SELECTED_FLIGHT_DISRUPTION_PROTECTION_ITINERARY_ID,
  itineraryId,
});

export interface IFetchDisruptionPreparedFlightRefund {
  type: actionTypes.FETCH_DISRUPTION_PREPARED_FLIGHT_REFUND;
  request: GetDisruptionRefundRequest;
}

export const fetchDisruptionPreparedFlightRefund = (
  request: GetDisruptionRefundRequest
): IFetchDisruptionPreparedFlightRefund => ({
  type: actionTypes.FETCH_DISRUPTION_PREPARED_FLIGHT_REFUND,
  request,
});

export interface ISetPreparedRefundInfo {
  type: actionTypes.SET_PREPARED_REFUND_INFO;
  preparedRefundInfo: PreparedRefundInfo | null;
}

export const setPreparedRefundInfo = (
  preparedRefundInfo: PreparedRefundInfo | null
): ISetPreparedRefundInfo => ({
  type: actionTypes.SET_PREPARED_REFUND_INFO,
  preparedRefundInfo,
});

export interface ISetFetchDisruptionPreparedFlightRefundCallState {
  type: actionTypes.SET_FETCH_FLIGHT_DISRUPTION_PREPARED_FLIGHT_REFUND_CALL_STATE;
  callState: CallState;
}

export const setFetchDisruptionPreparedFlightRefundCallState = (
  callState: CallState
): ISetFetchDisruptionPreparedFlightRefundCallState => ({
  type: actionTypes.SET_FETCH_FLIGHT_DISRUPTION_PREPARED_FLIGHT_REFUND_CALL_STATE,
  callState,
});

export interface ISubmitDisruptionFlightRefund {
  type: actionTypes.SUBMIT_DISRUPTION_FLIGHT_REFUND;
  request: SubmitDisruptionRefundRequest;
}

export const submitDisruptionFlightRefund = (
  request: SubmitDisruptionRefundRequest
): ISubmitDisruptionFlightRefund => ({
  type: actionTypes.SUBMIT_DISRUPTION_FLIGHT_REFUND,
  request,
});

export interface ISetDisruptionFlightRefundResult {
  type: actionTypes.SET_DISRUPTION_FLIGHT_REFUND_RESULT;
  result: DisruptionFlightRefundResult | null;
}

export const setDisruptionFlightRefundResult = (
  result: DisruptionFlightRefundResult | null
): ISetDisruptionFlightRefundResult => ({
  type: actionTypes.SET_DISRUPTION_FLIGHT_REFUND_RESULT,
  result,
});

export interface ISetSubmitDisruptionFlightRefundCallState {
  type: actionTypes.SET_SUBMIT_DISRUPTION_FLIGHT_REFUND_CALL_STATE;
  callState: CallState;
}

export const setSubmitDisruptionFlightRefundCallState = (
  callState: CallState
): ISetSubmitDisruptionFlightRefundCallState => ({
  type: actionTypes.SET_SUBMIT_DISRUPTION_FLIGHT_REFUND_CALL_STATE,
  callState,
});

export interface ISetProductRedeemChoice {
  type: actionTypes.SET_PRODUCT_REDEEM_CHOICE;
  choice: "missed_connection" | "delay";
}

export const setProductRedeemChoice = (
  choice: "missed_connection" | "delay"
): ISetProductRedeemChoice => ({
  type: actionTypes.SET_PRODUCT_REDEEM_CHOICE,
  choice,
});

export interface ISetDisruptionProtectionExperiments {
  type: actionTypes.SET_DISRUPTION_PROTECTION_EXPERIMENTS;
  experiments: IDisruptionProtectionExperiments;
}

export const setDisruptionProtectionExperiments = (
  experiments: IDisruptionProtectionExperiments
): ISetDisruptionProtectionExperiments => ({
  type: actionTypes.SET_DISRUPTION_PROTECTION_EXPERIMENTS,
  experiments,
});

export interface ISetSliceSelectedForMcpVi {
  type: actionTypes.SET_SLICE_SELECTED_FOR_MCP_VI;
  sliceIndex: number;
}

export const setSliceSelectedForMcpVi = (
  sliceIndex: number
): ISetSliceSelectedForMcpVi => ({
  type: actionTypes.SET_SLICE_SELECTED_FOR_MCP_VI,
  sliceIndex,
});

export type DisruptionProtectionActions =
  | ISetSelectedFlightDisruptionProtectionItineraryId
  | ISetFetchFlightDisruptionsCallStateFailed
  | IFetchFlightDisruptions
  | ISetFlightDisruptions
  | IFetchDisruptionPreparedFlightRefund
  | ISetPreparedRefundInfo
  | ISetFetchDisruptionPreparedFlightRefundCallState
  | ISubmitDisruptionFlightRefund
  | ISetDisruptionFlightRefundResult
  | ISetSubmitDisruptionFlightRefundCallState
  | ISetProductRedeemChoice
  | ISetDisruptionProtectionExperiments
  | ISetSliceSelectedForMcpVi;
