import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import { IStoreState } from "../../../../../../../../reducers/types";
import { PostBookingOfferBanner } from "./component";
import { setSelectedFlightDisruptionProtectionItineraryId } from "../../../../../../../DisruptionProtection/actions/actions";
import { getMyTripsPostBookingOffers } from "../../../../../../reducer";

const mapStateToProps = (state: IStoreState) => {
  return {
    myTripsPostBookingOffers: getMyTripsPostBookingOffers(state),
  };
};

const mapDispatchToProps = {
  setSelectedFlightDisruptionProtectionItineraryId,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PostBookingOfferBannerConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedPostBookingOfferBanner = connector(
  withRouter(PostBookingOfferBanner)
);
