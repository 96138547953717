import React, { useMemo } from "react";
import { ApprovalStatus, PendingHotelItinerary } from "redmond";
import dayjs from "dayjs";
import { Box, Typography } from "@material-ui/core";
import {
  ApprovalStatusChip,
  MyTripsCardTraveler,
  TripInfoDetails,
} from "halifax";
import { LodgingAddressExact } from "redmond";
import { SHORT_DATE_FORMAT, LONG_DATE_FORMAT } from "./constants";
import { PersonalBusinessLabel } from "../../PersonalBusinessLabel";

type Props = {
  pendingItinerary: PendingHotelItinerary;
};

export const HotelCard = ({ pendingItinerary }: Props) => {
  const { checkInDate, checkOutDate } = pendingItinerary.pendingReservation;

  const titles = {
    title: pendingItinerary.pendingReservation.lodgingData.name,
    confirmationLabel: "",
    startLabel: "Check in",
    startDate: dayjs(checkInDate).format(SHORT_DATE_FORMAT),
    endLabel: "Check out",
    endDate: dayjs(checkOutDate).format(SHORT_DATE_FORMAT),
    subtitle: `${dayjs(checkOutDate).diff(checkInDate, "days")} Nights`,
    phoneNum: pendingItinerary.pendingReservation.lodgingData?.phone || "",
    streetAddress: (
      pendingItinerary.pendingReservation.lodgingData
        ?.address as LodgingAddressExact
    )?.line1,
  };
  const approvalStatus = pendingItinerary.pendingReservation.isExpired
    ? "unavailable"
    : (pendingItinerary.approvalInfo.status.toLowerCase() as ApprovalStatus);

  const travelerNames = useMemo(
    () =>
      pendingItinerary.pendingReservation.guests.map(
        (guest) => `${guest.givenName} ${guest.surname}`
      ),
    [pendingItinerary]
  );

  return (
    <article className="pending-itinerary pending-itinerary-hotel">
      <Box className="pending-itinerary-title-row">
        <Typography className="pending-itinerary-title">
          <strong>Hotel</strong> | Requested on{" "}
          {dayjs(pendingItinerary.approvalInfo.requestedAt).format(
            LONG_DATE_FORMAT
          )}
        </Typography>
        <PersonalBusinessLabel isCorporate />
      </Box>
      <div className="pending-itinerary-card">
        <MyTripsCardTraveler travelers={travelerNames} isTripRequest />
        <TripInfoDetails titles={titles} />
        <ApprovalStatusChip approvalStatus={approvalStatus} />
      </div>
    </article>
  );
};
