import { actions, actionTypes } from "../actions";
import { CreditDetail, TravelWalletCredit, TravelWalletOffer } from "redmond";

export interface IMyTripsTravelWalletState {
  offers: TravelWalletOffer[];
  credit?: TravelWalletCredit;
  creditBreakdown: CreditDetail[];
  fetchTravelWalletDetailsCallState: CallState;
}

export enum CallState {
  NotCalled,
  InProcess,
  Success,
  Failed,
}

export const initialState: IMyTripsTravelWalletState = {
  offers: [],
  credit: undefined,
  creditBreakdown: [],
  fetchTravelWalletDetailsCallState: CallState.NotCalled,
};

export function reducer(
  state: IMyTripsTravelWalletState = initialState,
  action: actions.MyTripsTravelWalletActions
): IMyTripsTravelWalletState {
  switch (action.type) {
    case actionTypes.FETCH_TRAVEL_WALLET_DETAILS:
      return {
        ...state,
        fetchTravelWalletDetailsCallState: CallState.InProcess,
      };

    case actionTypes.SET_FETCH_TRAVEL_WALLET_DETAILS_CALL_STATE_FAILED:
      return {
        ...state,
        fetchTravelWalletDetailsCallState: CallState.Failed,
      };

    case actionTypes.SET_TRAVEL_WALLET_OFFERS:
      return {
        ...state,
        offers: action.payload,
        fetchTravelWalletDetailsCallState: CallState.Success,
      };

    case actionTypes.SET_TRAVEL_WALLET_CREDIT:
      return {
        ...state,
        credit: action.credit,
        creditBreakdown: action.creditBreakdown,
        fetchTravelWalletDetailsCallState: CallState.Success,
      };
    default:
      return state;
  }
}

export * from "./selectors";
