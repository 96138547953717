import {
  CallState,
  DisruptionFlightRefundResult,
  SubmitDisruptionRefundResponse,
  SubmitDisruptionRefundResponseEnum,
} from "redmond";
import { put } from "redux-saga/effects";
import submitDisruptionFlightRefund from "../../../api/v1/disruptions/submitDisruptionFlightRefund";

import { actions } from "../actions";

export function* submitDisruptionFlightRefundSaga(
  action: actions.ISubmitDisruptionFlightRefund
) {
  try {
    const response: SubmitDisruptionRefundResponse =
      yield submitDisruptionFlightRefund(action.request);

    switch (response.SubmitDisruptionRefundResponse) {
      case SubmitDisruptionRefundResponseEnum.Success:
        yield put(
          actions.setDisruptionFlightRefundResult(
            DisruptionFlightRefundResult.Success
          )
        );
        break;

      case SubmitDisruptionRefundResponseEnum.Failure:
        yield put(
          actions.setDisruptionFlightRefundResult(
            DisruptionFlightRefundResult.Failure
          )
        );
        break;
    }

    yield put(
      actions.setSubmitDisruptionFlightRefundCallState(CallState.Success)
    );
  } catch (e) {
    yield put(
      actions.setSubmitDisruptionFlightRefundCallState(CallState.Failed)
    );
    yield put(actions.setDisruptionFlightRefundResult(null));
  }
}
