import React from "react";
import "./styles.scss";
import { Box, Typography } from "@material-ui/core";
import { NoResults, ActionButton, Icon, IconName } from "halifax";
import { RouteComponentProps } from "react-router";
import clsx from "clsx";
import { CustomerAccountRole } from "redmond";
import { isCorpTenant } from "@capone/common";
import * as textConstants from "./constants";
import { NoTripResultsConnectorProps } from "./container";
import { PATH_CARS, PATH_FLIGHTS, PATH_HOTELS } from "../../../../utils/paths";
import { config } from "../../../../api/config";

interface INoTripResultsProps
  extends NoTripResultsConnectorProps,
    RouteComponentProps {
  isMobile?: boolean;
}
export const NoTripResults = ({
  hasTripsToDisplay,
  hasError,
  tripsFilter,
  history,
  isMobile,
  largestValueAccount,
}: INoTripResultsProps) => {
  const showEarnText =
    !!largestValueAccount &&
    (!isCorpTenant(config.TENANT) ||
      largestValueAccount.customerAccountRole === CustomerAccountRole.Primary);
  return (
    <Box className={clsx({ mobile: isMobile }, "no-trips-results")}>
      {hasError && !hasTripsToDisplay && (
        <>
          <NoResults
            className="no-trips-container"
            title={textConstants.getErrorMessage(tripsFilter) ?? ""}
            subtitle={textConstants.ERROR_SUBTITLE}
          />
          <Box className="no-trips-buttons">
            <ActionButton
              className="retry"
              message={textConstants.TRY_AGAIN}
              defaultStyle="h4r-primary"
              onClick={() => window.location.reload()}
            />
          </Box>
        </>
      )}
      {!hasTripsToDisplay && !hasError && (
        <>
          <Typography className="no-trips-title">
            {textConstants.getNoResultsTitle(tripsFilter)}
          </Typography>
          {!!textConstants.getNoResultsSubtitle(tripsFilter) && (
            <Typography className="no-trips-subtitle">
              {textConstants.getNoResultsSubtitle(tripsFilter)}
            </Typography>
          )}
          {showEarnText && (
            <Typography className="no-trips-earn">
              {textConstants.getEarnText(largestValueAccount)}
            </Typography>
          )}

          <Box className="no-trips-ctas">
            <Box className="no-trips-cta">
              <Box className="no-trips-cta-icon-wrapper">
                <Icon name={IconName.HotelFunnelIcon} />
              </Box>
              <ActionButton
                className="book-a-trip"
                message={textConstants.HOTELS_CTA}
                defaultStyle="h4r-secondary"
                onClick={() => {
                  history.push(PATH_HOTELS);
                }}
              />
            </Box>
            <Box className="no-trips-cta">
              <Box className="no-trips-cta-icon-wrapper">
                <Icon name={IconName.FlightFunnelIcon} />
              </Box>
              <ActionButton
                className="book-a-trip"
                message={textConstants.FLIGHTS_CTA}
                defaultStyle="h4r-secondary"
                onClick={() => {
                  history.push(PATH_FLIGHTS);
                }}
              />
            </Box>
            <Box className="no-trips-cta">
              <Box className="no-trips-cta-icon-wrapper">
                <Icon name={IconName.CarFunnelIcon} />
              </Box>
              <ActionButton
                className="book-a-trip"
                message={textConstants.CARS_CTA}
                defaultStyle="h4r-secondary"
                onClick={() => {
                  history.push(PATH_CARS);
                }}
              />
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};
