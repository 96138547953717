import { Airline, FlightItinerarySegment } from "redmond";
import { IConfirmationNumber } from "./component";
import { getPortalName } from "@capone/common";

// TODO: Remove capital one constants
export const CAPITAL_ONE_LABEL = getPortalName();
export const CAPITAL_ONE_WEBSITE = "https://www.capitalonetravel.com/";
export const OUTBOUND_TEXT = "Outbound";
export const RETURN_TEXT = "Return";
export const COPY_AND_GO = "Copy & Go";
export const CONFIRMATION_TITLE = "Confirmation Numbers";
export const MODAL_SUBTITLE = `To manage your trip from your airlines website, click the 
link next to the portion of the flight to be redirected.`;

interface IGetConfirmationNumberProps {
  segment: FlightItinerarySegment;
  airlineMap: {
    [key: string]: Airline;
  };
  isReturn?: boolean;
}

export const getConfirmationNumber = ({
  segment,
  airlineMap,
  isReturn,
}: IGetConfirmationNumberProps): IConfirmationNumber | false => {
  const selectedAirline = airlineMap[segment.marketingAirline.code];
  if (!selectedAirline) return false;
  return {
    label: `${selectedAirline.displayName} ${
      isReturn ? RETURN_TEXT : OUTBOUND_TEXT
    }: ${segment.origin.locationCode} to ${segment.destination.locationCode}`,
    locator: segment.marketingAirline.locator || "",
    redirectUrl: selectedAirline.webLinks.manageBooking || "",
  };
};
