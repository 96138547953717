import {
  CallState,
  GetDisruptionRefundResponse,
  GetDisruptionRefundResponseEnum,
  GetDisruptionRefundResponseSuccess,
  PreparedRefundInfo,
} from "redmond";
import { put } from "redux-saga/effects";
import getDisruptionPrepareFlightRefund from "../../../api/v1/disruptions/getDisruptionPrepareFlightRefund";

import { actions } from "../actions";

export function* fetchDisruptionPreparedFlightRefundSaga(
  action: actions.IFetchDisruptionPreparedFlightRefund
) {
  try {
    const response: GetDisruptionRefundResponse =
      yield getDisruptionPrepareFlightRefund(action.request);

    if (
      response.GetDisruptionRefundResponse ===
        GetDisruptionRefundResponseEnum.NoDisruptionDetected ||
      response.GetDisruptionRefundResponse ===
        GetDisruptionRefundResponseEnum.NoDisruptionProtectionAttached
    ) {
      yield put(actions.setPreparedRefundInfo(null));
      return;
    }

    const successResponse = response as GetDisruptionRefundResponseSuccess;

    const { rewardsCredit, userCardCredit, totalAmount, preparedPayment } =
      successResponse;

    const preparedRefundInfo: PreparedRefundInfo = {
      rewardsCredit,
      userCardCredit,
      totalAmount,
      preparedPayment,
    };

    yield put(
      actions.setFetchDisruptionPreparedFlightRefundCallState(CallState.Success)
    );
    yield put(actions.setPreparedRefundInfo(preparedRefundInfo));
  } catch (e) {
    yield put(
      actions.setFetchDisruptionPreparedFlightRefundCallState(CallState.Failed)
    );
    yield put(actions.setPreparedRefundInfo(null));
  }
}
