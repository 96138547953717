export const FLIGHT_FORM_SUBTITLE =
  "For minor corrections and updates to frequent flyer, known traveler or redress numbers, please contact the airline for assistance.";
export const FLIGHT_FORM_SUBTITLE_MINOR_CORRECTIONS =
  "If you've noticed a mistake, require special assistance, or would like to update frequent flyer, known traveler or TSA redress numbers, please edit below or visit ";
export const MINOR_CORRECTIONS = "minor corrections.";
export const FLIGHT_FORM_TITLE = "Traveler Details";
export const GROUND_FORM_SUBTITLE =
  "For minor corrections, please contact the rental agency directly for assistance.";
export const GROUND_FORM_TITLE = "Driver Details";
export const HOTEL_FORM_SUBTITLE =
  "For minor corrections, please contact the hotel directly for assistance.";
export const HOTEL_FORM_TITLE = "Guest Details";
export const REVIEWING_REQUEST = "We're Reviewing Your Request";
export const REQUEST_TRAVELER_EDIT_SUCCESS_SUBTITLE =
  "You'll receive an email confirmation once complete, generally within 12 hours. We'll contact you in the event of any issues.";
export const TRY_AGAIN = "Try Again";
export const SOMETHING_WENT_WRONG = "Something went wrong";
export const PLEASE_TRY_AGAIN = "Please try again";
