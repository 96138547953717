import { put } from "redux-saga/effects";
import { PendingRequestsResponse, ApprovalStatus } from "redmond";

import { actions } from "../actions";
import { fetchPendingRequests } from "../../../api/v1/itinerary/fetchPendingRequests";

const filterRequests = <T extends keyof PendingRequestsResponse>(
  pendingResponse: PendingRequestsResponse[T]
) => {
  const filtered = Object.entries(pendingResponse).reduce(
    (newFlights, [key, value]) => {
      if (key !== "autoApproved") {
        return [...newFlights, ...value];
      }
      return newFlights;
    },
    [] as PendingRequestsResponse[T][ApprovalStatus]
  );

  return filtered;
};

export function* fetchPendingRequestsSaga() {
  try {
    const pendingRequestsResponse: PendingRequestsResponse =
      yield fetchPendingRequests();

    const {
      flights: pendingFlights,
      ground: pendingGround,
      hotels: pendingHotels,
    } = pendingRequestsResponse;

    const tripRequests = {
      flights: filterRequests<"flights">(pendingFlights),
      cars: filterRequests<"ground">(pendingGround),
      hotels: filterRequests<"hotels">(pendingHotels),
    };
    yield put(actions.setPendingRequests(tripRequests));
  } catch (e) {
    yield put(actions.fetchPendingRequestsFailed());
    console.error(e);
  }
}
