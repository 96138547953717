import { DP_SUPPORT_PHONE_NUMBER } from "redmond";

export const DISRUPTION_PROTECTION_REBOOK_TITLE = (
  destination: string | undefined,
  category: "flight" | "connection"
) => `Rebook my ${category}${destination ? ` to ${destination}` : ""}`;

export const DISRUPTION_PROTECTION_REBOOK_SUBTITLE =
  "Please review this information before continuing to rebook your flight.";
export const DISRUPTION_PROTECTION_REBOOK_SELECTION_SUBTITLE =
  "Please select the connections that you would like to rebook. Your new trip must end at your final destination.";

export const KNOW_BEFORE_REBOOKING_TITLE = "Know before you rebook...";

export const KNOW_BEFORE_REBOOKING_POINT_ONE = `If your airline has offered you a rebooking option, you can still choose to rebook your flight with us.`;
export const KNOW_BEFORE_REBOOKING_POINT_TWO = `You should check with your airline to see if you need to re-check any luggage onto your new flight. Additional luggage fees may apply in some cases.`;
export const KNOW_BEFORE_REBOOKING_POINT_TWO_ARIA_LABEL =
  "Learn more about luggage details";
export const KNOW_BEFORE_REBOOKING_POINT_THREE = `After rebooking, you should check with your airline to confirm the status of your original return flight.`;
export const KNOW_BEFORE_REBOOKING_POINT_THREE_ARIA_LABEL =
  "Learn more about the return flight";

export const LEARN_MORE = "Learn more";
export const PERIOD_TEXT = ".";

export const IMPORTANT_INFORMATION = "Important information";

export const IMPORTANT_INFORMATION_POINT_ONE = `Online booking is only available if booking for all original travelers. If you would only like to rebook some of the passengers included in your original booking, please call us at ${DP_SUPPORT_PHONE_NUMBER.boldText}.`;
export const IMPORTANT_INFORMATION_POINT_TWO = `When rebooking, you'll choose from flights that: depart on the same day or following day as your original flight, arrive and depart from the same airports and are the same or a lower fare class.`;
export const IMPORTANT_INFORMATION_POINT_THREE = (cap: string) =>
  `You can choose from flights that are within the ${cap} cap per traveler limit.`;
export const IMPORTANT_INFORMATION_POINT_FOUR = `You can only rebook with this service <strong>one time</strong> per trip.`;

export const SEARCH_FOR_FLIGHTS = "Search for flights";
export const SEARCH_FOR_FLIGHTS_MOBILE = "Search flights";

export const DELAYED_FLIGHT = "Delayed flight";
export const MISSED_CONNECTION = "Missed connection";
export const CANCELED_FLIGHT = "Canceled flight";

export const VIEW_DETAILS = "View details";
export const REBOOK_SEGMENT_SELECTION = "Rebook segment selection";
