import axios from "axios";

import { deleteUserPassengerPath } from "../paths";

export const deleteUserPassenger = async (
  personId: string
): Promise<IDeleteUserPassengerResponse> => {
  const res = await axios.post(deleteUserPassengerPath, { personId });

  return res.data;
};

export interface IDeleteUserPassengerResponse {}
