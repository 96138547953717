import React from "react";
import { Box, Typography } from "@material-ui/core";

import "./styles.scss";
import * as textConstants from "./constants";
import clsx from "clsx";

interface ITravelersSummary {
  travelers: string;
  className?: string;
  label?: string;
}

export const TravelersSummary = ({
  travelers,
  className,
  label = textConstants.TRAVELERS_TEXT,
}: ITravelersSummary) => {
  return (
    <Box className={clsx("travelers-summary-container", className)}>
      <Typography variant="body2" className="travelers-text">
        {label}
      </Typography>
      <Typography variant="subtitle2" className="traveler-names">
        {travelers}
      </Typography>
    </Box>
  );
};
