import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../../../../../../../reducers/types";
import { setOpenModal } from "../../../../../../actions/actions";
import { getAirlinesMap, getAirportMap } from "../../../../../../reducer";
import { CancelFlightModalContent } from "./component";

const mapStateToProps = (state: IStoreState) => ({
  airportMap: getAirportMap(state),
  airlineMap: getAirlinesMap(state),
});

const mapDispatchToProps = {
  setOpenModal,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type CancelFlightModalContentConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedCancelFlightModalContent = connector(
  CancelFlightModalContent
);
