import { Box, Typography } from "@material-ui/core";
import {
  BannerSeverity,
  Icon,
  IconName,
  MyTripsMobileCard,
  NotificationBanner,
} from "halifax";
import React, { useRef } from "react";
import {
  getDepartureSlice,
  getFlightInfoDetails,
  getReturnSlice,
  HotelItineraryState,
  ItineraryEnum,
  MyTripsFilter,
  MyTripsModalTypes,
  PackageItinerary,
  PortalItineraryStatusEnum,
  ScheduleChangeSeverity,
} from "redmond";
import { PersonalBusinessLabel } from "../../../capone-corporate/PersonalBusinessLabel";
import * as textConstants from "./constants";
import clsx from "clsx";
import { RouteComponentProps } from "react-router-dom";
import { PackageCardConnectorProps } from "./container";
import { DesktopPackageHotelCard } from "./DesktopPackageHotelCard/component";
import "./styles.scss";
import { DesktopFlightCard } from "./DesktopPackageFlightCard/component";
import { formatDate } from "@capone/common/src/utils/getHotelApprovalRequest";
import { getStatusBanner } from "../FlightCard/component";

export const getHotelStatusBanner = (status?: HotelItineraryState) => {
  switch (status) {
    case HotelItineraryState.Canceled:
    case HotelItineraryState.CancellationFailure:
    case HotelItineraryState.Cancelled:
      return (
        <NotificationBanner
          className="status-banner"
          label={textConstants.CANCELED_RES}
          severity={BannerSeverity.ERROR}
          icon={<Icon name={IconName.ErrorAlert} />}
        />
      );
    default:
      return undefined;
  }
};

export interface IPackageCardProps
  extends PackageCardConnectorProps,
    RouteComponentProps {
  isMobile?: boolean;
  packageItinerary: PackageItinerary;
  expandedCard: string;
  onExpandCard: (cardId: string, itineraryType: "flight" | "hotel") => void;
  isCorporate?: boolean;
}

export const PackageCard = (props: IPackageCardProps) => {
  const {
    history,
    packageItinerary,
    isMobile,
    populateTripQueryParams,
    setSelectedPackageHotel,
    setSelectedPackageFlight,
    onExpandCard,
    expandedCard,
    setOpenModal,
    isCorporate,
    tripsFilter,
    airlineMap,
    airportMap,
    setSelectedPackage,
  } = props;

  const packageRef = useRef<HTMLDivElement | null>(null);
  const isPastTrips = tripsFilter === MyTripsFilter.PAST_TRIPS;

  const { state } = packageItinerary.hotel;
  const statusBanner = getHotelStatusBanner(state);
  const isCancel = [
    HotelItineraryState.Canceled,
    HotelItineraryState.CancellationFailure,
    HotelItineraryState.Cancelled,
  ].includes(state);
  const confirmationCodeClassName = isCancel ? "warning" : "";

  const travelerNames = packageItinerary.hotel.itinerary.reservation.guests.map(
    (person) => `${person?.givenName} ${person?.surname}`
  );

  const isCanceled =
    packageItinerary.flight.itinerary.status ===
    PortalItineraryStatusEnum.Canceled;
  const flight = packageItinerary.flight.itinerary;

  const { status, bookedItinerary } = flight;
  const { scheduleChange } = bookedItinerary;

  const hasMajorScheduleChange =
    scheduleChange?.severity === ScheduleChangeSeverity.Major;

  const hasMinorScheduleChange =
    scheduleChange?.severity === ScheduleChangeSeverity.Minor ||
    (status === PortalItineraryStatusEnum.Modified && !scheduleChange);

  const desktopFlightBanner = getStatusBanner(
    flight,
    hasMajorScheduleChange,
    hasMinorScheduleChange,
    false,
    airlineMap,
    () => {
      setOpenModal({
        type: MyTripsModalTypes.ScheduleChange,
        selectedItinerary: { ...flight, type: ItineraryEnum.Flight },
      });
    }
  );

  const mobileFlightBanner = getStatusBanner(
    flight,
    hasMajorScheduleChange,
    hasMinorScheduleChange,
    true,
    airlineMap,
    () => {
      setOpenModal({
        type: MyTripsModalTypes.ScheduleChange,
        selectedItinerary: { ...flight, type: ItineraryEnum.Flight },
      });
    }
  );

  return (
    <Box className={clsx({ mobile: isMobile }, "package-list")}>
      {" "}
      {isMobile ? (
        <>
          <MyTripsMobileCard
            onClick={() => {
              setSelectedPackage(packageItinerary);
              setSelectedPackageHotel(packageItinerary.hotel.itinerary);
              populateTripQueryParams(history);
            }}
            banner={statusBanner}
            confirmationCodeClassName={confirmationCodeClassName}
            confirmationLabel={textConstants.CONFIRMATION}
            label={"Packages"}
            iconComponent={
              <div className="packages-icon-wrapper">
                <Icon name={IconName.HotelFunnelIcon} />
                <Typography className="icon-plus-separator">+</Typography>
                <Icon name={IconName.FlightFunnelIcon} />
              </div>
            }
            personalBusinessLabel={
              <PersonalBusinessLabel isCorporate={isCorporate} />
            }
            travelerNames={travelerNames}
            {...textConstants.getHotelInfoDetails(
              packageItinerary.hotel.itinerary
            )}
            titleIcon={IconName.HotelFunnelIcon}
          />{" "}
          <MyTripsMobileCard
            onClick={() => {
              setSelectedPackage(packageItinerary);
              setSelectedPackageFlight(packageItinerary.flight.itinerary);
              populateTripQueryParams(history);
            }}
            banner={mobileFlightBanner}
            confirmationCodeClassName={confirmationCodeClassName}
            confirmationLabel={textConstants.CONFIRMATION}
            travelerNames={travelerNames}
            {...getFlightInfoDetails(
              flight.bookedItinerary.travelItinerary?.locators,
              getDepartureSlice(flight.bookedItinerary),
              getReturnSlice(flight.bookedItinerary),
              formatDate,
              airportMap,
              airlineMap
            )}
            startDate={undefined}
            titleIcon={IconName.FlightFunnelIcon}
          />
        </>
      ) : (
        <div ref={packageRef}>
          <Box className="package-card-icon-title">
            <div className="packages-icon-wrapper">
              <Icon name={IconName.HotelFunnelIcon} />
              <Typography className="icon-plus-separator">+</Typography>
              <Icon name={IconName.FlightFunnelIcon} />
            </div>
            <Typography variant="body1" className="summary-label">
              {textConstants.getHeaderText(
                packageItinerary.hotel.itinerary.reservation.checkInDate,
                packageItinerary.hotel.itinerary.reservation.checkOutDate
              )}
              <PersonalBusinessLabel isCorporate={isCorporate} />
            </Typography>
          </Box>
          <DesktopPackageHotelCard
            banner={statusBanner}
            isPastTrips={isPastTrips}
            packageItinerary={packageItinerary}
            isCancel={isCancel}
            confirmationCodeClassName={confirmationCodeClassName}
            isMobile={isMobile}
            expandedCard={expandedCard}
            onExpandCard={(cardId: string) => {
              onExpandCard(cardId, "hotel");
            }}
            setOpenModal={setOpenModal}
            travelerNames={travelerNames}
            tripsFilter={tripsFilter}
            airlineMap={airlineMap}
          />{" "}
          <DesktopFlightCard
            banner={desktopFlightBanner}
            isCanceled={isCanceled}
            packageItinerary={packageItinerary}
            isPastTrips={isPastTrips}
            isMobile={isMobile}
            confirmationCodeClassName={confirmationCodeClassName}
            expandedCard={expandedCard}
            onExpandCard={(cardId: string) => {
              onExpandCard(cardId, "flight");
            }}
            airlineMap={airlineMap}
            airportMap={airportMap}
            setOpenModal={setOpenModal}
            tripsFilter={tripsFilter}
          />
        </div>
      )}
    </Box>
  );
};
