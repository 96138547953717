import { isDisruptionProtection24hRuleEnabledSelector } from "./../../../../../../../DisruptionProtection/reducer/selectors/index";
import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import { IStoreState } from "../../../../../../../../reducers/types";
import { DesktopFlightCard } from "./component";
import { setSelectedFlightDisruptionProtectionItineraryId } from "../../../../../../../DisruptionProtection/actions/actions";
import {
  getFlightDisruptions,
  isDisruptionProtection24hRuleSatisfiedSelector,
} from "../../../../../../../DisruptionProtection/reducer";
import {
  getPaymentMethods,
  upcomingItinerariesSelector,
} from "../../../../../../reducer";

const mapStateToProps = (state: IStoreState) => {
  return {
    flightDisruptions: getFlightDisruptions(state),
    upcomingItineraries: upcomingItinerariesSelector(state),
    isDisruptionProtection24hRuleEnabled:
      isDisruptionProtection24hRuleEnabledSelector(state),
    isDisruptionProtection24hRuleSatisfied:
      isDisruptionProtection24hRuleSatisfiedSelector(state),
    paymentMethods: getPaymentMethods(state),
  };
};

const mapDispatchToProps = {
  setSelectedFlightDisruptionProtectionItineraryId,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type DesktopFlightCardConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedDesktopFlightCard = connector(
  withRouter(DesktopFlightCard)
);
