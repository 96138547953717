export type AddOn = ICfarAddOn | IDisruptionProtectionAddOn;

export enum AddOnEnum {
  DisruptionProtection,
  Cfar,
}

interface IAddOn {
  onClick: () => void;
  isEnabled: boolean;
  type: AddOnEnum;
}

interface ICfarAddOn extends IAddOn {
  expiryDate: string;
  expiredTz: string;
  type: AddOnEnum.Cfar;
}

interface IDisruptionProtectionAddOn extends IAddOn {
  type: AddOnEnum.DisruptionProtection;
}
