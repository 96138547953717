import React, { useEffect, useState, useRef, useContext } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Box, Typography } from "@material-ui/core";
import {
  AccordionCollection,
  AccordionContent,
  ActionButton,
  ActionLink,
  BannerSeverity,
  GenericDetailsCard,
  GenericDetailsCardComponent,
  GenericDetailsCardComponentEnum,
  GenericDetailsCardTopSectionComponent,
  GenericDetailsCardTopSectionComponentEnum,
  Icon,
  IconName,
  NotificationBanner,
  useDeviceTypes,
  getPriceString,
  getCurrencySymbol,
  useFocusRef,
} from "halifax";
import clsx from "clsx";

import {
  ExerciseEligibilityV1Enum,
  VIEWED_DISRUPTION_PAGE,
  MISSED_CONNECTION_REDEEM,
  DELAYS_REDEEM,
  Cap1DpExerciseFactsProperties,
  PartialEligibilityDpExerciseFacts,
  PartialFlightsListDpExerciseFacts,
  DISRUPTION_REDEEM_CHOICE_REFUND,
  DISRUPTION_REDEEM_CHOICE_REBOOK,
} from "redmond";
import {
  CONTACT_SUPPORT_URL,
  PATH_TERMS_DISRUPTION_PROTECTION,
} from "../../../../utils/paths";
import { trackEvent } from "../../../../api/v1/analytics/trackEvent";
import { DisruptionProtectionLandingConnectorProps } from "./container";
import * as constants from "./constants";
import "./styles.scss";
import { ClientContext } from "../../../../App";
import { pushToDisruptionOverview } from "../../../../utils/queryStringHelpers";
import { isViMultiTicket } from "../../../TripsList/utils";

export interface IDisruptionProtectionLandingProps
  extends DisruptionProtectionLandingConnectorProps,
    RouteComponentProps {}

export const DisruptionProtectionLanding = (
  props: IDisruptionProtectionLandingProps
) => {
  const {
    history,
    disruptionProtectionItinerary,
    flightDisruptions,
    coverage,
    areSomeDisruptionInfoFetchingsInProcess,
    disruptionOverviewEligibilityDpExerciseFactsProperties,
    setProductRedeemChoice,
    selectedFlightDisruptionProtectionItineraryId,
  } = props;
  const { matchesMobile } = useDeviceTypes();
  const [undetectedModalOpen, setUndetectedModalOpen] =
    useState<boolean>(false);
  const hasFiredViewedDisruptionPageEvent = useRef<boolean>(false);
  const clientContext = useContext(ClientContext);

  const initialFocusRef = useFocusRef([]);

  const { isAgentPortal } = clientContext;

  const exerciseEligibility = flightDisruptions?.eligibilityByItinerary[
    disruptionProtectionItinerary?.bookedItinerary.id ?? ""
  ]

  const undetected =
    !areSomeDisruptionInfoFetchingsInProcess && exerciseEligibility?.ExerciseEligibilityV1 !== ExerciseEligibilityV1Enum.IsEligible;
  // [DIS-1695] For FDG contracts (Delay/MC), use `delayMinimumMinutes` to determine copy
  // exerciseEligibility depends on disruptionProtectionItinerary which can be empty when `c1-fintech-disruption-24-hours-rule` LD flag is on. Therefore, here it uses selectedFlightDisruptionProtectionItineraryId so we can find the ExerciseEligibilityV1 to obtain `delayMinimumMinutes`
  const exerciseEligibilityForDelayThreshold = flightDisruptions?.eligibilityByItinerary[
    selectedFlightDisruptionProtectionItineraryId ?? ""
  ]
  const delayThresholdStringInHours = exerciseEligibilityForDelayThreshold?.delayMinimumMinutes && exerciseEligibilityForDelayThreshold?.delayMinimumMinutes === 180 ? "3" : "2"

  const coverageCopy = coverage
    ? getPriceString({
        price: coverage.amount,
        currencySymbol: getCurrencySymbol(coverage.currency),
        priceFormatter: (price) => price.toLocaleString("en-US"),
      })
    : undefined;

  const RebookAndRefundSection = ({
    rebookType,
    redeemChoice,
  }: {
    rebookType: "rebook-flight" | "rebook-connection";
    redeemChoice: "missed_connection" | "delay";
  }) => {
    const handlePushToDisruptionOverview = ({
      path,
    }: {
      path: "rebook-flight" | "rebook-connection" | "refund";
    }) => {
      setProductRedeemChoice(redeemChoice);
      pushToDisruptionOverview({
        history,
        path,
        itineraryId: disruptionProtectionItinerary?.bookedItinerary.id,
      });
    };
    const handleDisruptionRedeemChoiceEvent = ({
      path,
    }: {
      path: "rebook-flight" | "rebook-connection" | "refund";
    }) => {
      trackEvent({
        eventName:
          path === "refund"
            ? DISRUPTION_REDEEM_CHOICE_REFUND
            : DISRUPTION_REDEEM_CHOICE_REBOOK,
        properties: {
          active_disruption:
            disruptionOverviewEligibilityDpExerciseFactsProperties?.active_disruption,
          product_redeem_choice: redeemChoice,
          page: "disruption-page",
        } as Cap1DpExerciseFactsProperties,
      });
    };

    return (
      <Box className="rebook-and-refund-section">
        <Box className="description-section">
          <Typography className="title-copy" variant="subtitle2">
            {redeemChoice === "missed_connection"
              ? constants.REBOOK_TITLE_MISSED_CONNECTION
              : constants.REBOOK_TITLE}
          </Typography>
          {coverageCopy && (
            <Typography
              className="subtitle-copy"
              variant="body2"
              dangerouslySetInnerHTML={{
                __html:
                  redeemChoice === "missed_connection"
                    ? constants.REBOOK_BODY_MISSED_CONNECTION(coverageCopy)
                    : constants.REBOOK_BODY(coverageCopy, delayThresholdStringInHours),
              }}
            />
          )}
        </Box>
        <Box className="cta-section">
          <ActionButton
            className={clsx("cta-button", "rebook")}
            defaultStyle="h4r-primary"
            onClick={() => {
              if (undetected && !isAgentPortal) {
                setUndetectedModalOpen(true);
              } else {
                handlePushToDisruptionOverview({ path: rebookType });
              }
              handleDisruptionRedeemChoiceEvent({ path: rebookType });
            }}
            ariaLabelText={
              redeemChoice === "missed_connection"
                ? constants.REBOOK_CTA_COPY_MISSED_CONNECTION
                : constants.REBOOK_CTA_COPY
            }
            message={
              redeemChoice === "missed_connection"
                ? constants.REBOOK_CTA_COPY_MISSED_CONNECTION
                : constants.REBOOK_CTA_COPY
            }
          />
          <ActionButton
            className={clsx("cta-button", "refund")}
            defaultStyle="h4r-secondary"
            onClick={() => {
              if (undetected && !isAgentPortal) {
                setUndetectedModalOpen(true);
              } else {
                handlePushToDisruptionOverview({ path: "refund" });
              }
              handleDisruptionRedeemChoiceEvent({ path: "refund" });
            }}
            ariaLabelText={constants.REFUND_CTA_COPY}
            message={constants.REFUND_CTA_COPY}
          />
        </Box>
      </Box>
    );
  };

  const getAccordionContents = (): AccordionContent[] => {
    const accordions: AccordionContent[] = [];
    const isDelayIncluded = !!disruptionProtectionItinerary?.ancillaries.delay;
    const isMissedConnectionIncluded =
      !!disruptionProtectionItinerary?.ancillaries.missedConnection;

    const handleTrackEvent = (
      eventName: typeof DELAYS_REDEEM | typeof MISSED_CONNECTION_REDEEM
    ) => {
      trackEvent({
        eventName,
        properties: {
          active_disruption:
            disruptionOverviewEligibilityDpExerciseFactsProperties?.active_disruption,
          delay_duration:
            disruptionOverviewEligibilityDpExerciseFactsProperties?.delay_duration,
        } as Omit<
          PartialEligibilityDpExerciseFacts & PartialFlightsListDpExerciseFacts,
          "disruption_product"
        >,
      });
    };

    if (isDelayIncluded || isMissedConnectionIncluded) {
      accordions.push({
        title: (
          <Typography className="header-copy" variant="h3">
            {constants.FLIGHT_GOT_DELAYED}
          </Typography>
        ),
        body: (
          <RebookAndRefundSection
            rebookType="rebook-flight"
            redeemChoice="delay"
          />
        ),
        onOpen: () => {
          handleTrackEvent(DELAYS_REDEEM);
        },
      });
    }
    if (isMissedConnectionIncluded) {
      accordions.push({
        title: (
          <Typography className="header-copy" variant="h3">
            {constants.MISSED_CONNECTION}
          </Typography>
        ),
        body: (
          <RebookAndRefundSection
            rebookType="rebook-connection"
            redeemChoice="missed_connection"
          />
        ),
        onOpen: () => {
          handleTrackEvent(MISSED_CONNECTION_REDEEM);
        },
      });
    }

    return accordions;
  };

  const topContent: GenericDetailsCardTopSectionComponent = {
    className: "undetected-modal-header",
    header: constants.CONTACT_US,
    icon: IconName.UnableToProcess,
    centered: true,
    component: GenericDetailsCardTopSectionComponentEnum.GenericHeader,
  };

  const mainContent: GenericDetailsCardComponent[] = [
    {
      className: "undetected-modal-footer",
      type: "subtitle",
      copy: constants.NOTIFICATION_UNDETECTED_MODAL,
      position: "center",
      component: GenericDetailsCardComponentEnum.GenericCopy,
    },
  ];

  useEffect(() => {
    if (
      disruptionOverviewEligibilityDpExerciseFactsProperties &&
      !hasFiredViewedDisruptionPageEvent.current
    ) {
      trackEvent({
        eventName: VIEWED_DISRUPTION_PAGE,
        properties: disruptionOverviewEligibilityDpExerciseFactsProperties,
      });
      hasFiredViewedDisruptionPageEvent.current = true;
    }
  }, [disruptionOverviewEligibilityDpExerciseFactsProperties]);

  const isViFlight : boolean = disruptionProtectionItinerary? isViMultiTicket(disruptionProtectionItinerary.bookedItinerary.multiTicketType):false;
  const LandingSubtitle = isViFlight? constants.DISRUPTION_PROTECTION_LANDING_SUBTITLE_FOR_VI
                                             : constants.DISRUPTION_PROTECTION_LANDING_SUBTITLE

  return (
    <Box
      className={clsx("disruption-protection-landing-root", {
        mobile: matchesMobile,
      })}
    >
      <Box className="disruption-protection-landing-container">
        <Box className="header-section">
          <Typography
            tabIndex={0}
            innerRef={initialFocusRef}
            className="title-copy"
            variant="h2"
          >
            {constants.DISRUPTION_PROTECTION_LANDING_TITLE}
          </Typography>
          <Typography
            className="subtitle-copy"
            variant="subtitle2"
            dangerouslySetInnerHTML={{
              __html: LandingSubtitle || "",
            }}
          />
        </Box>
        {undetected && (
          <NotificationBanner
            className="status-banner"
            icon={<Icon name={IconName.WarningAlert} />}
            html={constants.NOTIFICATION_UNDETECTED_BANNER(delayThresholdStringInHours)}
            severity={BannerSeverity.WARNING}
          />
        )}
        <Box className="accordion-section">
          <AccordionCollection
            className="disruption-protection-landing-accordions"
            accordionContents={getAccordionContents()}
            collapseIcon={<Icon name={IconName.MinusBlueCircle} />}
            expandIcon={<Icon name={IconName.PlusBlueCircle} />}
          />
        </Box>
        <Box className="link-section">
          <ActionLink
            className={clsx("cta-link", "read-terms")}
            onClick={() => {
              window.open(PATH_TERMS_DISRUPTION_PROTECTION, "_blank")?.focus();
            }}
            ariaProps={{ "aria-label": constants.READ_TERMS_COPY }}
            content={constants.READ_TERMS_COPY}
          />
          <Typography className="separator">|</Typography>
          <ActionLink
            className={clsx("cta-link", "faq")}
            onClick={() => window.open(CONTACT_SUPPORT_URL, "_blank")?.focus()}
            ariaProps={{ "aria-label": constants.FAQ_COPY }}
            content={constants.FAQ_COPY}
          />
        </Box>
        <GenericDetailsCard
          contentClassName="undetected-modal-content"
          openModal={undetectedModalOpen}
          onClose={() => setUndetectedModalOpen(false)}
          topContent={topContent}
          mainContent={mainContent}
        />
      </Box>
    </Box>
  );
};
