import React from "react";
import { Box, Typography } from "@material-ui/core";
import {
  Airline,
  Airport,
  FlightItinerarySlice,
  getStopsFromSegments,
} from "redmond";
import { Icon, IconName } from "halifax";

import "./styles.scss";
import * as textConstants from "./constants";
import { AirlineIcon } from "halifax";
import clsx from "clsx";
import { getStopText } from "../../../../../../../../../../utils/helpers";

interface ICfarItinerarySummary {
  flightSlice: FlightItinerarySlice;
  airlineMap: { [key: string]: Airline };
  airportMap: { [key: string]: Airport };
  isOutgoing: boolean;
  className?: string;
  isMultiCity?: boolean;
}

const defaultProps: Partial<ICfarItinerarySummary> = {
  isOutgoing: false,
};

export const CfarItinerarySummary = ({
  flightSlice,
  className,
  isOutgoing,
  airlineMap,
  airportMap,
  isMultiCity,
}: ICfarItinerarySummary) => {
  if (!flightSlice) return null;

  const airline = flightSlice.segments[0]?.marketingAirline;
  const numStops = getStopsFromSegments(flightSlice.segments);

  return (
    <Box className={clsx("cfar-itinerary-summary-root", className)}>
      <Box className="icon-section">
        <Icon
          name={IconName.B2BAirplaneIcon}
          className={clsx("cfar-flight-icon", {
            return: !isOutgoing && !isMultiCity,
          })}
        />
      </Box>
      <Box className="content-section">
        <Typography variant="subtitle2" className="itinerary-summary-header">
          <span className="itinerary-summary-label">
            {isOutgoing
              ? textConstants.OUTBOUND
              : !isMultiCity
              ? textConstants.RETURN
              : null}
          </span>
          {isMultiCity
            ? textConstants.getMultiCityItinerarySummaryHeader(
                flightSlice,
                airportMap
              )
            : textConstants.getItinerarySummaryHeaderFromSlice(
                flightSlice,
                airportMap
              )}
        </Typography>
        <Box className="itinerary-summary-details-section">
          <Box className={clsx("date", "section-area")}>
            <Typography variant="body2" className="itinerary-date">
              {textConstants.getFormattedDateFromSlice(flightSlice, isOutgoing)}
            </Typography>
          </Box>
          <Box className={clsx("time", "section-area")}>
            <Typography variant="body2" className="itinerary-times">
              {textConstants.getTimeFromSlice(flightSlice)}
            </Typography>
          </Box>
          <Box className={clsx("duration-and-stop", "section-area")}>
            <Typography variant="body2" className="itinerary-duration">
              {`${textConstants.getDurationFromSlice(flightSlice)}, `}
            </Typography>
            <Typography variant="body2" className="itinerary-stops">
              {getStopText(numStops)}
            </Typography>
          </Box>
          <Box className={clsx("airline", "section-area")}>
            <AirlineIcon airlineCode={airline.code} />
            <Typography variant="body2">
              {airlineMap[airline.code]?.displayName}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

CfarItinerarySummary.defaultProps = defaultProps;
