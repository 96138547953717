import { DP_SUPPORT_PHONE_NUMBER } from "redmond";

export const REFUND_IN_PROGRESS_TITLE = "Your refund is being processed..";
export const REFUND_IN_PROGRESS_SUBTITLE =
  "Please do not exit until the refund is confirmed.";

export const REFUND_SUCCESS_TITLE = "Refund Confirmed";
export const REFUND_SUCCESS_SUBTITLE =
  "Your refund is being processed. You'll receive a confirmation email shortly.";
export const REFUND_SUCCESS_BUTTON_DONE = "Done";

export const REFUND_FAILURE_TITLE = "Refund Failed";
export const REFUND_FAILURE_SUBTITLE = `Please try again or contact support at ${DP_SUPPORT_PHONE_NUMBER.boldLink}.`;
export const REFUND_FAILURE_BUTTON_TRY_AGAIN = "Try Again";
