import React from "react";
import { Box, Typography } from "@material-ui/core";
import {
  Airline,
  AirlineMap,
  Airport,
  BookedFlightItineraryWithDepartureTime,
  getDepartureSlice,
  getReturnSlice,
  getStopsFromSegments,
  SingleTravelItinerary,
} from "redmond";

import "./styles.scss";
import * as textConstants from "./constants";
import { AirlineIcon, StatusTag, MixedCabinToolTip } from "halifax";
import clsx from "clsx";
import { getStatusTag } from "../FlightCard/component";
import { getIsMixedClass } from "../../../../constants";
import { isTFBooking, isViMultiTicket } from "../../../../utils";
import { getStopText } from "../../../../../../utils/helpers";
import { airlinesCountFlightItinerarySegment } from "../FlightCard/helpers";

interface IItinerarySummary {
  flight: BookedFlightItineraryWithDepartureTime;
  airlineMap: { [key: string]: Airline };
  airportMap: { [key: string]: Airport };
  isOutgoing: boolean;
  className?: string;
  multicitySliceIndex?: number;
}

const defaultProps: Partial<IItinerarySummary> = {
  isOutgoing: false,
};

export const ItinerarySummary = ({
  flight,
  className,
  isOutgoing,
  airlineMap,
  airportMap,
  multicitySliceIndex,
}: IItinerarySummary) => {
  const { bookedItinerary } = flight;
  const { scheduleChange, travelItinerary } = bookedItinerary;
  const departureSlice = getDepartureSlice(bookedItinerary);
  const returnSlice = getReturnSlice(bookedItinerary);
  const [isMixedCabinClass, setIsMixedCabinClass] = React.useState(false);
  const isTF = isTFBooking(travelItinerary);
  const isMulticity = multicitySliceIndex !== undefined;

  const isMultiTicketType = isViMultiTicket(bookedItinerary.multiTicketType);

  const multicityDepartureSlice = isMulticity
    ? (flight.bookedItinerary.travelItinerary as SingleTravelItinerary).slices[
        multicitySliceIndex
      ]
    : undefined;

  const renderingSlice = isMulticity
    ? multicityDepartureSlice
    : isOutgoing
    ? departureSlice
    : returnSlice;

  React.useEffect(() => {
    if (isMulticity) {
      setIsMixedCabinClass(getIsMixedClass(multicityDepartureSlice!));
    } else if (isOutgoing) {
      setIsMixedCabinClass(getIsMixedClass(departureSlice));
    } else if (!isOutgoing && returnSlice) {
      setIsMixedCabinClass(getIsMixedClass(returnSlice));
    } else {
      return;
    }
  }, [flight]);

  if (!isOutgoing && !returnSlice) return null; // no return summary for one-way flights

  const airline = renderingSlice?.segments[0]?.marketingAirline;
  const numStops = getStopsFromSegments(renderingSlice?.segments);
  const airlinesCount =
    isMultiTicketType &&
    airlinesCountFlightItinerarySegment(
      isOutgoing ? departureSlice.segments : returnSlice!.segments
    );
  const statusTagInfo = getStatusTag(
    flight,
    isMulticity ? multicitySliceIndex : isOutgoing ? 0 : 1,
    scheduleChange,
    airportMap,
    airlineMap as AirlineMap,
    isTF
  );

  return (
    <Box className={clsx("itinerary-summary-container", className)}>
      {!!statusTagInfo && (
        <Box className="mobile-status-tag">
          <StatusTag tagInfo={statusTagInfo} />
        </Box>
      )}
      <Typography variant="subtitle2" className="itinerary-summary-header">
        <span className="itinerary-summary-label">
          {isMulticity
            ? ""
            : isOutgoing
            ? textConstants.OUTBOUND
            : textConstants.RETURN}
        </span>
        {isMulticity
          ? textConstants.getMulticityItinerarySummaryHeader(
              flight,
              airportMap,
              multicitySliceIndex
            )
          : textConstants.getItinerarySummaryHeader(
              flight,
              airportMap,
              isOutgoing
            )}
        {isMixedCabinClass && <MixedCabinToolTip />}
      </Typography>
      <Box className="itinerary-summary-details-section">
        <Box className={clsx("time-and-airline", "section-area")}>
          <Typography variant="body1" className="itinerary-times">
            {textConstants.getTime(flight, isOutgoing, multicitySliceIndex)}
          </Typography>
          <Box className="itinerary-airline">
            <AirlineIcon airlineCode={airline!.code} />
            <Typography variant="body2">
              {airlineMap[airline!.code]?.displayName}
            </Typography>
            {isMultiTicketType && (airlinesCount || 0) > 0 && (
              <span className="vi-more red-text">
                {`+${airlinesCount} other`}
              </span>
            )}
          </Box>
        </Box>
        <Box className={clsx("duration-and-stop", "section-area")}>
          <Typography variant="body1" className="itinerary-duration">
            {textConstants.getDuration(flight, isOutgoing, multicitySliceIndex)}
          </Typography>
          <Typography variant="body2" className="itinerary-stops">
            {getStopText(numStops)}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

ItinerarySummary.defaultProps = defaultProps;
