import {
  CallState,
  HotelCfarCancellationResponse,
  HotelCfarCancellationSuccess,
} from "redmond";
import { put } from "redux-saga/effects";
import { confirmHotelCfarCancellation } from "../../../api/v1/itinerary/confirmHotelCfarCancellation";

import { actions } from "../actions";
import { IConfirmHotelCfarCancellation } from "../actions/actions";

export function* confirmHotelCfarCancellationSaga(
  action: IConfirmHotelCfarCancellation
) {
  try {
    const { request } = action;

    const response: HotelCfarCancellationResponse =
      yield confirmHotelCfarCancellation(request);

    const successRes: HotelCfarCancellationSuccess =
      response as HotelCfarCancellationSuccess;

    if (successRes.value.success) {
      yield put(actions.setHotelCfarCancelled());
    } else {
      yield put(
        actions.setConfirmHotelCfarCancellationCallState(CallState.Failed)
      );
    }
  } catch (e) {
    yield put(
      actions.setConfirmHotelCfarCancellationCallState(CallState.Failed)
    );
  }
}
