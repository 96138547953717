import { formatInterval } from "halifax";
import { Airport } from "redmond";

const HOW_IT_WORKS = "How it works";
export const FLIGHT_INCLUDES = "This flight includes a";
const WHAT_IS_INCLUDED = "What's included";
const TERMS = "Read terms and conditions";
const MISSED_CONNECTION = `
  If you’re going to miss a connecting flight or have a flight canceled by the airline while traveling, you can book a new flight to your destination on any airline available through Capital One Travel. Choose between flights  within a <b>$5,000 cap per traveler</b>. No validation with the airline required—it’s that simple.
  <span class="modal-space how-to-title">
    <b>How to rebook a flight through Capital One Travel</b>
  </span>
  <span class="modal-space step-title">
    <b>Step 1:</b>
  </span>
  <span class="modal-space step-info">
    Sign in to Capital One Travel and visit My Trips to rebook your connecting flight <b>within 24 hours of the flight's departure.</b>
  </span>
  <span class="modal-space step-title">
    <b>Step 2:</b>
  </span>
  <span class="modal-space step-info">
    Choose from flights that depart on the same day or following day of your original flight, arrive at the final intended destination of your original flight, and are the same or a lower fare class.
  </span>
`;

export const SELF_CHECK = {
  header: "<b>Why do I have to</b> claim and re-check my bags?",
  title: HOW_IT_WORKS,
  subtitle: `
    Capital One Travel lets you purchase flights that operate through multiple airlines to provide you with more options to get to your destination, but the airlines aren’t able to coordinate baggage transfers between the flights.
    <span class="modal-space">
      <b>If you check your baggage, follow these steps during layovers.</b>
    </span>
    <span class="modal-space">
     <b>Step 1:</b>
    </span>
    <span class="modal-space">
      Pick up your bags from baggage claim and re-check them with the next airline.
    </span>
    <span class="modal-space">
      <b>Step 2:</b>
    </span>
    <span class="modal-space">
      Re-enter the airport gate through security.
    </span>
    <span class="modal-space">
      <b>International flights:</b>
    </span>
    <span class="modal-space">
      If you’re traveling on an international flight, you may be required to go through customs during layovers.
    </span>
    
  `,
};

export const MISSED_CONNECTION_PROTECTION = {
  header: "<b>Missed connection</b> protection",
  title: HOW_IT_WORKS,
  subtitle: MISSED_CONNECTION,
  ctaText: TERMS,
};

// export const MISSED_CONNECTION_GUARANTEE = {
//   header: "Missed connection guarantee",
//   title: WHAT_IS_INCLUDED,
//   subtitle: MISSED_CONNECTION,
//   ctaText: TERMS,
// };

/** @see https://hopper-jira.atlassian.net/browse/COTA-1093 */
export const MISSED_CONNECTION_REBOOKING = {
  header: "Missed connection rebooking",
  title: WHAT_IS_INCLUDED,
  subtitle: MISSED_CONNECTION,
  ctaText: TERMS,
};

export const formatLayoverBannerContent = ({
  stopoverDurationMinutes,
  destinationCode,
  airports = {},
}: {
  stopoverDurationMinutes?: number;
  destinationCode: string;
  airports: {
    [key: string]: Airport;
  };
}) => {
  const { cityName } = airports && airports[destinationCode];
  if (stopoverDurationMinutes) {
    return `<b>${formatInterval(stopoverDurationMinutes)}</b> layover in ${
      cityName ? cityName : ""
    } (${destinationCode})`;
  }
  return null;
};
