export const PRICE_BREAKDOWN = "Price Breakdown";
export const ROOM = "Room";
export const NIGHT = "night";
export const TAXES_AND_FEES = "Taxes & Fees";
export const PAID_AT_TIME_OF_BOOKING = "Paid at time of booking";
export const PRICE_FREEZE_PAYMENT_APPLIED = "Price freeze payment applied";
export const CANCEL_FOR_ANY_REASON = "Cancel for any reason";
export const ADDITIONAL_SERVICE_FEE =
  "Additional Service Fee (Due at check-in)";
export const TRIP_TOTAL = "Total";
