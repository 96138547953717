import React from "react";

import { PendingTripListConnectorProps } from "./container";
import { FlightCard, HotelCard, CarCard } from "./components";
import "./styles.scss";

export interface IPendingTripList extends PendingTripListConnectorProps {
  isMobile?: boolean;
}

const PendingTripList = ({ pendingItinerariesToDisplay }: IPendingTripList) => {
  return (
    <div className="pending-trip-list">
      {pendingItinerariesToDisplay.map((pendingItinerary, index) => {
        const getCard = () => {
          // Flights
          if ("pendingFlightItinerary" in pendingItinerary) {
            return (
              <FlightCard
                pendingItinerary={pendingItinerary}
                key={pendingItinerary.pendingFlightItinerary.id}
              />
            );
          }

          // Hotels
          if ("pendingReservation" in pendingItinerary) {
            return (
              <HotelCard
                pendingItinerary={pendingItinerary}
                key={pendingItinerary.pendingReservation.id}
              />
            );
          }

          // Cars
          return (
            <CarCard
              pendingItinerary={pendingItinerary}
              key={pendingItinerary.id}
            />
          );
        };

        return (
          <>
            {index > 0 && <div className="divider" />}
            {getCard()}
          </>
        );
      })}
    </div>
  );
};

export default PendingTripList;
