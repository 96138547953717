import React, { useState } from "react";
import { Typography, TextField, InputAdornment, Box } from "@material-ui/core";
import { debounce } from "lodash-es";
import clsx from "clsx";
import { isCorpTenant } from "@capone/common";
import { Icon, IconName } from "halifax";
import { ISetTripSearchQuery } from "../../../../actions/actions";
import { config } from "../../../../../../api/config";

import "./styles.scss";

export const TripsTabTitle = ({
  title,
  subTitle,
  setTripSearchQuery,
  isMobile,
}: {
  title: string;
  subTitle: string;
  setTripSearchQuery: (searchQuery: string) => ISetTripSearchQuery;
  isMobile: boolean;
}) => {
  const [localSearchQuery, setLocalSearchQuery] = useState("");
  const debouncedSetSearchQuery = debounce(setTripSearchQuery, 500);

  return (
    <Box className={clsx("trips-tab-title-container", { mobile: isMobile })}>
      <Box>
        <Typography variant="h2" className="trips-tab-title">
          {title}
        </Typography>
        <Typography variant="body2" className="trips-tab-subtitle">
          {subTitle}
        </Typography>
      </Box>
      {isCorpTenant(config.TENANT) && (
        <Box className="trips-search-container">
          <TextField
            className="trips-search-input"
            variant="outlined"
            aria-label="search"
            id="search-input"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon name={IconName.MagnifyingGlass} />
                </InputAdornment>
              ),
            }}
            placeholder="Search by user, location, etc"
            onChange={(event) => {
              setLocalSearchQuery(event.target.value);
              debouncedSetSearchQuery(event.target.value);
            }}
            value={localSearchQuery}
          />
        </Box>
      )}
    </Box>
  );
};
