import { isCorpTenant } from "@capone/common";
import { Tenant } from "redmond";
import { CONTACT_SUPPORT_URL } from "../../../../../../../../utils/paths";

export const buttonText = {
  CHANGE_FLIGHT: "View Change Options",
  CLOSE: "Close",
  CONTACT_AIRLINE: (airlineName: string) => {
    if (airlineName.endsWith("s")) {
      return `Continue to ${airlineName}' website`;
    }

    return `Continue to ${airlineName}'s website`;
  },
  CONTACT_SUPPORT: "Contact Support",
  TRY_AGAIN: "Try Again",
  VOIDABLE: "Cancel Flight",
};

export const copy = {
  FLIGHT_CANCELLED_SUBTITLE: "Canceled flights cannot be exchanged",
  FLIGHT_CANCELLED_TITLE: "This flight was canceled",
  ITINERARY_NOT_FOUND_SUBTITLE:
    "We could not find your itinerary, please contact support",
  ITINERARY_NOT_FOUND_TITLE: "Itinerary not found",
  LOADING: "Loading exchange policy...",
  LOAD_FAILED_SUBTITLE: (tenant: Tenant) =>
    isCorpTenant(tenant)
      ? "Please contact our support team and we’d be happy to help."
      : "Please try again or contact support.",
  LOAD_FAILED_TITLE: "We couldn't load your flight change information",
  TOO_MANY_ATTEMPTS_SUPPORT_SUBTITLE:
    "after too many attempts finding eligible flight",
  TOO_MANY_ATTEMPTS_SUPPORT_TITLE: "Lets have an agent help you",
};

export const openSupportInNewTab = () => {
  window.open(CONTACT_SUPPORT_URL, "_target")?.focus();
};
