import axios from "axios";
import { config } from "../../config";

import { DeleteWatchAlertRequest, DeleteWatchAlertResponse } from "redmond";
import { watchApiPrefix } from "../paths";

export const deleteWatch = (
  request: DeleteWatchAlertRequest
): Promise<DeleteWatchAlertResponse> => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await axios.post(`${watchApiPrefix}/delete`, request, {
        baseURL: config.baseURL,
      });
      resolve(res.data);
    } catch (e) {
      reject(e);
    }
  });
};
