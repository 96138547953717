import React from "react";
import { Box, Link, Typography } from "@material-ui/core";
import { HomesItinerary } from "redmond";
import { HotelMapPin, HotelName } from "halifax";
import { GoogleMap, OverlayView } from "@react-google-maps/api";
import { convertICoordinatesToGoogleMapCoords } from "../../../../../../../../utils/googleMapsHelpers";
import "./styles.scss";

export interface IHomeMapProps {
  home: HomesItinerary;
  hideLabel?: boolean;
}

export const HomeMap = (props: IHomeMapProps) => {
  const { home, hideLabel } = props;
  const { reservation } = home;
  if (
    !reservation?.listing.address ||
    !reservation.listing.location?.coordinates
  ) {
    return <></>;
  }
  const center = convertICoordinatesToGoogleMapCoords(
    reservation.listing.location?.coordinates
  );

  return (
    <Box className="home-map-container">
      <GoogleMap
        id="home-map"
        mapContainerStyle={{ height: "100%", width: "100%" }}
        options={{
          clickableIcons: false,
          zoomControl: false,
          streetViewControl: false,
          mapTypeControl: false,
          draggable: false,
          fullscreenControl: false,
          draggableCursor: "pointer",
        }}
        center={center}
        zoom={16}
      >
        <SmallHomeMapContent {...props}></SmallHomeMapContent>
      </GoogleMap>
      {!hideLabel && (
        <Link component="button" className="home-map-label">
          <HotelName name={reservation.listing.name} />
          <Box className="mobile-hotel-address">
            <Typography variant="body1" className="home-address-line-2">
              {home.reservation.listing.areaName}
              {home.reservation.listing.state
                ? `, ${home.reservation.listing.state}`
                : ""}
            </Typography>
          </Box>
        </Link>
      )}
    </Box>
  );
};

const SmallHomeMapContent = (props: IHomeMapProps) => {
  const { home } = props;
  const { reservation } = home;

  if (!reservation.listing.location?.coordinates) {
    return <></>;
  }
  const center = convertICoordinatesToGoogleMapCoords(
    reservation.listing.location?.coordinates
  );

  return (
    <>
      <OverlayView
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        position={center}
      >
        <HotelMapPin />
      </OverlayView>
    </>
  );
};
