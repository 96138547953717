import axios from "axios";
import {
  AcceptScheduleChangeRequest,
  AcceptScheduleChangeResponse,
} from "redmond";
import { airBookingApi } from "@b2bportal/air-booking-api";

export const acceptScheduleChange = async (
  req: AcceptScheduleChangeRequest
): Promise<AcceptScheduleChangeResponse> => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await airBookingApi(
        axios
      ).apiV0ItineraryFlightAcceptScheduleChangePost(req);
      resolve(res.data);
    } catch (e) {
      reject(e);
    }
  });
};
