import { CallState, IPerson } from "redmond";
import { put } from "redux-saga/effects";

import fetchUserPassengers from "../../../api/v1/user/fetchUserPassengers";
import { setUserPassengers, setUserPassengersCallState } from "../reducer";

export function* fetchUserPassengersSaga() {
  try {
    const userPassengers: IPerson[] = yield fetchUserPassengers();

    yield put(setUserPassengers(userPassengers));
  } catch (e) {
    yield put(setUserPassengersCallState(CallState.Failed));
  }
}
