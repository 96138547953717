import dayjs from "dayjs";
import React from "react";
import { HomesItinerary } from "redmond";

export const CHECK_IN = "Check in";
export const CHECK_IN_ON = "Check in on";
export const CHECK_OUT = "Check out";
export const CHECKED_IN = "Checked in on";
export const CONFIRMATION = "Confirmation:";
export const HOME_HEADER_TEXT = "Vacation Rental";
export const PREMIER_COLLECTION_HEADER_TEXT =
  "Premier Collection Vacation Rental";
export const LIFESYLE_COLLECTION_HEADER_TEXT =
  "Lifestyle Collection Vacation Rental";
export const VIEW_DETAILS = "View details";
export const VIEW_LESS = "View less";
export const DATE_FORMAT = "ddd, MMM D";
export const CANCELED = "Canceled";
export const CANCELED_RES = "This reservation has been canceled.";
export const MOBILE_PREMIUM_VR_LABEL = "Premium Vacation Rental";
export const MOBILE_VR_LABEL = "Vacation Rental";
export const VACATION_RENTAL_TYPE = "Vacation rental type";

export const GUEST_INFORMATION = "Guest Information";

export const CANCEL_VACATION_RENTAL = "Cancel vacation rental";
export const RESEND_CONFIRMATION = "Resend Confirmation";
export const VIEW_ITEMIZED_RECEIPT = "View Itemized Receipt";

export const getHeaderText = (
  checkInDate?: string,
  checkOutDate?: string,
  isLuxuryCollection?: boolean,
  isLifestyleCollection?: boolean
) => {
  return checkInDate ? (
    <>
      <strong>
        {isLuxuryCollection
          ? PREMIER_COLLECTION_HEADER_TEXT
          : isLifestyleCollection
          ? LIFESYLE_COLLECTION_HEADER_TEXT
          : HOME_HEADER_TEXT}
      </strong>{" "}
      <span className="separator">|</span>{" "}
      {dayjs(checkInDate).format(DATE_FORMAT)} -{" "}
      {dayjs(checkOutDate).format(DATE_FORMAT)}
    </>
  ) : (
    <strong>
      {isLuxuryCollection
        ? PREMIER_COLLECTION_HEADER_TEXT
        : isLifestyleCollection
        ? LIFESYLE_COLLECTION_HEADER_TEXT
        : HOME_HEADER_TEXT}
    </strong>
  );
};

export const getHomeInfoDetails = (
  { reservation }: HomesItinerary,
  nameAsTitle?: boolean
) => {
  const { listing } = reservation;
  return {
    title: nameAsTitle
      ? listing.name
      : `${listing.areaName}${listing.state ? `, ${listing.state}` : ""}`,
    confirmationCode: reservation.id.value,
    startDate: dayjs(reservation.stayDates.from).format(DATE_FORMAT),
    endDate: dayjs(reservation.stayDates.until).format(DATE_FORMAT),
    subtitle: "",
  };
};
