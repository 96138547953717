import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useMediaQuery } from "@material-ui/core";
import clsx from "clsx";
import {
  GenericDetailsCard,
  GenericDetailsCardComponentEnum,
  GenericDetailsCardComponent,
  GenericDetailsCardTopSectionComponentEnum,
  GenericDetailsCardTopSectionComponent,
  IconName,
  useDeviceTypes,
  GenericModalContent,
  ActionButton,
  Icon,
} from "halifax";
import { RouteComponentProps, StaticContext } from "react-router";
import {
  CallState,
  ContractStateEnum,
  FLIGHT_CFAR_TERMS_BODY,
  HotelItinerary,
  HotelCfarCancelScenarioEnum,
  SelfServeEvents,
  getHotelCfarExerciseFactsFromCfarContractAndReservation,
} from "redmond";
import {
  ErrorScreen,
  ErrorType,
  CfarItinerarySummary,
  LoadingScreen,
  LoadingType,
  CompleteScreen,
} from "./components";
import * as constants from "./constants";
import { ICfarHotelModalContentConnectorProps } from "./container";
import { CfarHotelModalStep } from "./types";
import { trackEvent } from "../../../../../../../../api/v1/analytics/trackEvent";
import {
  PATH_HOME,
  PATH_TERMS_CFAR,
} from "../../../../../../../../utils/paths";
import "./styles.scss";

export interface ICfarHotelModalContentProps
  extends ICfarHotelModalContentConnectorProps,
    RouteComponentProps<{}, StaticContext, { prevPath?: string }> {
  hotel: HotelItinerary;
  onClose: () => void;
  setDisableOnClose: (disable: boolean) => void;
  setUsePartialScroll: (usePartialScroll: boolean) => void;
  setHideXButton: (hide: boolean) => void;
}

export const CfarHotelModalContent = (props: ICfarHotelModalContentProps) => {
  const {
    fetchHotelCfarCancellationPolicy,
    hotelCfarCancellationPolicyCallState,
    hotelCfarCancellationPolicyScenario,
    hotelCfarCancellationPolicy,
    resetHotelCfarCancellation,
    confirmHotelCfarCancellation,
    confirmHotelCfarCancellationCallState,
    hotel,
    selectedHotelCfarReservationId,
    onClose,
    setDisableOnClose,
    setUsePartialScroll,
    setHideXButton,
    history,
    isHotelCfarRefundDisplayShowAmountAdditionalPlaces,
    isHotelCfarRefundDisplayNonRefPartialEnabled,
    isFintechCsatEnabled,
  } = props;
  const [currentStep, setCurrentStep] = useState<CfarHotelModalStep>(
    CfarHotelModalStep.LoadingCancellationPolicy
  );
  const [error, setError] = useState<ErrorType | null>(null);
  const [topContent, setTopContent] =
    useState<GenericDetailsCardTopSectionComponent>();
  const [mainContent, setMainContent] =
    useState<GenericDetailsCardComponent[]>();
  const [bottomContent, setBottomContent] =
    useState<GenericDetailsCardComponent[]>();
  const { matchesMobile } = useDeviceTypes();
  const usePartialScroll = useMediaQuery(
    constants.getPartialScrollThreshold(),
    {
      noSsr: true,
    }
  );
  const isPartialScrollActive = useMemo(() => {
    return !matchesMobile && usePartialScroll;
  }, [matchesMobile, usePartialScroll]);
  const isOnCfarWorkflow =
    CfarHotelModalStep.Details <= currentStep &&
    currentStep <= CfarHotelModalStep.Confirmation;
  const isLoadingPolicy =
    currentStep === CfarHotelModalStep.LoadingCancellationPolicy;
  const [isCancellationPending, setIsCancellationPending] =
    useState<boolean>(false);
  const isCancellationInProgress =
    currentStep === CfarHotelModalStep.CancellationInProgress;
  const isComplete = currentStep === CfarHotelModalStep.Complete;

  const hotelCfarExerciseTrackingProperties = useMemo(() => {
    return {
      product: "hotel",
      lob: "hotel",
      ...getHotelCfarExerciseFactsFromCfarContractAndReservation({
        reservation: hotel.reservation,
        cfar: hotel.ancillaries.cfar,
        cfarCancelScenario: hotelCfarCancellationPolicyScenario ?? undefined,
      }),
    };
  }, [hotelCfarCancellationPolicyCallState]);

  const onReset = () => {
    setCurrentStep(0);
    resetHotelCfarCancellation();
    setError(null);
    setIsCancellationPending(false);
    setTopContent(undefined);
    setMainContent(undefined);
    setBottomContent(undefined);
  };

  // note: when the user has opened this modal through a different itinerary, it should reset everything.
  useEffect(() => {
    if (
      selectedHotelCfarReservationId !== hotel.reservation.reservationBookingId
    ) {
      onReset();
    }
  }, [selectedHotelCfarReservationId, hotel]);

  useEffect(() => {
    /*
      note: when the contract state isn't Purchase, the FE is expected to display an error message;
      see https://hopchat.slack.com/archives/C02LKB2MVFY/p1679930903425729?thread_ts=1679689837.542369&cid=C02LKB2MVFY
    */
    if (
      hotel.ancillaries.cfar?.state?.ContractState !==
      ContractStateEnum.Purchase
    ) {
      setError(ErrorType.FailedToLoadCancellation);
    }
    if (isLoadingPolicy) {
      switch (hotelCfarCancellationPolicyCallState) {
        case CallState.Success:
          if (
            hotelCfarCancellationPolicyScenario?.HotelCfarCancelScenario ===
            HotelCfarCancelScenarioEnum.Pending
          ) {
            setIsCancellationPending(true);
          } else if (
            hotelCfarCancellationPolicyScenario?.HotelCfarCancelScenario ===
            HotelCfarCancelScenarioEnum.ContactCustomerService
          ) {
            setError(ErrorType.FailedToSelfServeCancel);
          } else if (
            hotelCfarCancellationPolicyScenario?.HotelCfarCancelScenario ===
            HotelCfarCancelScenarioEnum.CancellationFailure
          ) {
            setError(ErrorType.FailedToLoadCancellation);
          } else {
            setCurrentStep(CfarHotelModalStep.Details);
          }
          break;
        case CallState.Failed:
          setError(ErrorType.FailedToLoadCancellation);
          break;
        case CallState.NotCalled:
          fetchHotelCfarCancellationPolicy({
            reservationId: hotel.reservation.reservationBookingId,
          });
          break;
        default:
          break;
      }
    }
  }, [
    isLoadingPolicy,
    hotelCfarCancellationPolicyCallState,
    hotelCfarCancellationPolicyScenario,
    hotel,
  ]);

  useEffect(() => {
    if (isCancellationInProgress) {
      switch (confirmHotelCfarCancellationCallState) {
        case CallState.Success:
          setCurrentStep(CfarHotelModalStep.Complete);
          break;
        case CallState.Failed:
          setError(ErrorType.FailedToCancel);
          break;
        default:
          break;
      }
    }
  }, [isCancellationInProgress, confirmHotelCfarCancellationCallState]);

  // Note: This useEffect is for handling the setHideXButtonRef and setDisableOnClose logic
  useEffect(() => {
    switch (currentStep) {
      case CfarHotelModalStep.LoadingCancellationPolicy:
      case CfarHotelModalStep.CancellationInProgress:
        setHideXButton(error === null);
        setDisableOnClose(error === null);
        break;
      case CfarHotelModalStep.Complete:
        setHideXButton(true);
        setDisableOnClose(false);
        break;
      default:
        setHideXButton(false);
        setDisableOnClose(false);
        break;
    }
  }, [currentStep, error, setHideXButton]);

  const ReservationSummaryRow = useMemo(
    () => (
      <div
        className={clsx(
          "reservation-data-row",
          CfarHotelModalStep[currentStep].toLowerCase(),
          {
            mobile: matchesMobile,
          }
        )}
        key={`ReservationSummary_${hotel.reservation.reservationId}`}
      >
        <CfarItinerarySummary hotel={hotel} className="hotel-reservation" />
      </div>
    ),
    [currentStep, hotel]
  );

  const getReadTermsLink = (
    className?: string
  ): GenericDetailsCardComponent => ({
    className,
    message: constants.READ_TERMS_AND_CONDITIONS,
    onClick: () => window.open(PATH_TERMS_CFAR, "_blank")?.focus(),
    position: "left",
    component: GenericDetailsCardComponentEnum.ClickableLink,
  });
  const getTermsAndConditions = useCallback(
    (className?: string): GenericDetailsCardComponent => {
      return (
        matchesMobile
          ? {
              className,
              content: [
                {
                  title: constants.READ_TERMS_AND_CONDITIONS,
                  body: FLIGHT_CFAR_TERMS_BODY,
                },
              ],
              component: GenericDetailsCardComponentEnum.AccordionGroup,
            }
          : getReadTermsLink(className)
      ) as GenericDetailsCardComponent;
    },
    [matchesMobile]
  );

  useEffect(() => {
    switch (currentStep) {
      case CfarHotelModalStep.Details: {
        setTopContent({
          header: constants.CANCEL_FOR_ANY_REASON_TITLE,
          icon: IconName.CheckShieldBlue,
          component: GenericDetailsCardTopSectionComponentEnum.GenericHeader,
        });
        const cta: GenericDetailsCardComponent = {
          message: constants.CONTINUE_BUTTON_COPY,
          onClick: () => {
            trackEvent({
              eventName: SelfServeEvents.ClickCancel,
              properties: hotelCfarExerciseTrackingProperties,
            });
            setCurrentStep(CfarHotelModalStep.Confirmation);
          },
          ariaLabel: constants.CONTINUE_BUTTON_COPY,
          fill: "blue",
          size: "small",
          component: GenericDetailsCardComponentEnum.GenericCta,
        };
        const content: GenericDetailsCardComponent[] = [
          {
            className: clsx(
              "cfar-hotel-exercise-modal-subtitle",
              CfarHotelModalStep[currentStep].toLowerCase()
            ),
            type: "secondary",
            copy: constants.getCfarCashSubtitle(
              isHotelCfarRefundDisplayShowAmountAdditionalPlaces ||
                isHotelCfarRefundDisplayNonRefPartialEnabled
                ? {
                    cash: hotelCfarCancellationPolicy?.cashRefundAmount,
                    reward:
                      hotelCfarCancellationPolicy?.rewardsRefundAmount
                        ?.rewardsAmount.rewardsPrice,
                    travelCredit:
                      hotelCfarCancellationPolicy?.travelCreditRefundAmount,
                    copyVariant: "amount",
                  }
                : {
                    coveragePercent:
                      hotelCfarCancellationPolicy?.coveragePercentage,
                    copyVariant: "percent",
                  }
            ),
            component: GenericDetailsCardComponentEnum.GenericCopy,
          },
          !isPartialScrollActive
            ? getTermsAndConditions(
                "cfar-hotel-full-refund-terms-and-conditions"
              )
            : undefined,
          {
            content: ReservationSummaryRow,
            component: GenericDetailsCardComponentEnum.Custom,
          },
          {
            className: "cfar-hotel-cancellation-information",
            title: constants.CANCELLATION_INFORMATION_HEADER,
            items: constants.getCfarConfirmInformation(),
            component: GenericDetailsCardComponentEnum.PointsGroup,
          },
          !isPartialScrollActive ? cta : undefined,
        ].filter(
          (content) => content !== undefined
        ) as GenericDetailsCardComponent[];
        setMainContent(content);
        if (isPartialScrollActive) {
          setBottomContent([
            getTermsAndConditions(
              "cfar-hotel-full-refund-terms-and-conditions"
            ),
            cta,
          ]);
        }
        break;
      }
      case CfarHotelModalStep.Confirmation: {
        const confirmCta: GenericDetailsCardComponent = {
          message: constants.CONFIRM_CANCELLATION_BUTTON_COPY,
          onClick: () => {
            if (hotelCfarCancellationPolicy) {
              trackEvent({
                eventName: SelfServeEvents.ConfirmCancellation,
                properties: hotelCfarExerciseTrackingProperties,
              });
              confirmHotelCfarCancellation({
                reservationId: selectedHotelCfarReservationId
                  ? selectedHotelCfarReservationId
                  : hotel.reservation.reservationBookingId,
                cancelScenario: hotelCfarCancellationPolicy,
              });
              setCurrentStep(CfarHotelModalStep.CancellationInProgress);
            }
          },
          ariaLabel: constants.CONFIRM_CANCELLATION_BUTTON_COPY,
          fill: "red",
          size: "small",
          component: GenericDetailsCardComponentEnum.GenericCta,
        };
        const goBackCta: GenericDetailsCardComponent = {
          message: constants.GO_BACK,
          onClick: () => {
            setCurrentStep(CfarHotelModalStep.Details);
          },
          position: "center",
          component: GenericDetailsCardComponentEnum.ClickableLink,
        };

        setTopContent({
          header: constants.CONFIRM_CANCELLATION_TITLE,
          component: GenericDetailsCardTopSectionComponentEnum.GenericHeader,
        });
        setMainContent(
          [
            {
              className: clsx(
                "cfar-hotel-exercise-modal-subtitle",
                CfarHotelModalStep[currentStep].toLowerCase()
              ),
              type: "secondary",
              copy: constants.getCfarRefundAmountSubtitle({
                cash: hotelCfarCancellationPolicy?.cashRefundAmount,
                reward:
                  hotelCfarCancellationPolicy?.rewardsRefundAmount
                    ?.rewardsAmount.rewardsPrice,
                travelCredit:
                  hotelCfarCancellationPolicy?.travelCreditRefundAmount,
              }),
              component: GenericDetailsCardComponentEnum.GenericCopy,
            },
            {
              content: ReservationSummaryRow,
              component: GenericDetailsCardComponentEnum.Custom,
            },
            {
              className: "cfar-hotel-complete-cancellation",
              type: "secondary",
              copy: constants.COMPLETE_CANCELLATION_SUBTITLE,
              component: GenericDetailsCardComponentEnum.GenericCopy,
            },
            !isPartialScrollActive ? confirmCta : undefined,
            !isPartialScrollActive ? goBackCta : undefined,
          ].filter(
            (content) => content !== undefined
          ) as GenericDetailsCardComponent[]
        );
        if (isPartialScrollActive) {
          setBottomContent([goBackCta, confirmCta]);
        }
        break;
      }
      default:
        break;
    }
  }, [
    currentStep,
    matchesMobile,
    ReservationSummaryRow,
    getTermsAndConditions,
    isPartialScrollActive,
  ]);

  useEffect(() => {
    if (
      !!hotelCfarCancellationPolicyScenario &&
      confirmHotelCfarCancellationCallState === CallState.NotCalled
    ) {
      trackEvent({
        eventName: SelfServeEvents.ViewedCancelModal,
        properties: hotelCfarExerciseTrackingProperties,
      });
    }
  }, [hotelCfarCancellationPolicyScenario]);

  // note: handle the tracking for final cancellation success and failure response
  useEffect(() => {
    if (currentStep === CfarHotelModalStep.CancellationInProgress && !!error) {
      trackEvent({
        eventName: SelfServeEvents.ResponseFailure,
        properties: hotelCfarExerciseTrackingProperties,
      });
    } else if (currentStep === CfarHotelModalStep.Complete) {
      trackEvent({
        eventName: SelfServeEvents.ResponseSuccess,
        properties: hotelCfarExerciseTrackingProperties,
      });
    }
  }, [currentStep, error]);

  useEffect(() => {
    // note: set the css rule to partial-scroll when it satisfies the medium query and it's on desktop
    if (isPartialScrollActive) {
      setUsePartialScroll(true);
    } else {
      setUsePartialScroll(false);
      setBottomContent(undefined);
    }
  }, [isPartialScrollActive]);

  useEffect(() => {
    return () => {
      setUsePartialScroll(false);
    };
  }, []);

  if (error !== null) {
    return <ErrorScreen type={error} />;
  } else if (isCancellationPending) {
    return (
      <GenericModalContent
        actions={
          <ActionButton
            defaultStyle="h4r-primary"
            message={constants.CLOSE_BUTTON_COPY}
            onClick={onClose}
          />
        }
        image={<Icon className="error-icon" name={IconName.ErrorState} />}
        title={constants.PENDING_CANCELLATION_TITLE}
        subtitle={constants.PENDING_CANCELLATION_SUBTITLE}
      />
    );
  } else if (isLoadingPolicy || isCancellationInProgress) {
    return (
      <LoadingScreen
        type={
          isLoadingPolicy
            ? LoadingType.FetchingPolicy
            : LoadingType.CancellationInProgress
        }
      />
    );
  } else if (isOnCfarWorkflow && topContent && mainContent) {
    return (
      <GenericDetailsCard
        contentClassName="cfar-hotel-modal-content-root"
        topContent={topContent}
        mainContent={mainContent}
        bottomContent={bottomContent}
        openModal={true}
        isMobile={matchesMobile}
        contentOnly={true}
        scrollOption={
          isPartialScrollActive ? "partial-scroll" : "default-scroll"
        }
      />
    );
  } else if (isComplete) {
    return (
      <CompleteScreen
        onClose={() => {
          onClose();
          history.push(
            `${PATH_HOME}?tripId=${hotel.reservation.reservationId}`
          );
          window.location.reload();
        }}
        isFintechCsatEnabled={isFintechCsatEnabled}
      />
    );
  } else {
    return null;
  }
};
