import { combineReducers } from "redux";

import passengersReducer from "../modules/passengers/reducer";
import { reducer as rewards } from "../modules/rewards/reducer";
import { reducer as tripsList } from "../pages/TripsList/reducer";
import { reducer as disruptionProtection } from "../pages/DisruptionProtection/reducer";
import { reducer as myTripsTravelWallet } from "../modules/travel-wallet/reducer";
import { reducer as priceFreeze } from "../pages/PriceFreeze/reducer";

export const rootReducer = combineReducers({
  passengers: passengersReducer,
  tripsList,
  rewards,
  disruptionProtection,
  myTripsTravelWallet,
  priceFreeze,
});
