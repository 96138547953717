import {
  GetHotelDetailRequest,
  HotelPriceFreezeDetail,
  LodgingPriceFreezeRefundResult,
} from "redmond";
import { IPriceFreezeExperiments } from "../reducer";
import * as actionTypes from "./constants";

export interface IFetchHotelPriceFreezeDetails {
  type: actionTypes.FETCH_HOTEL_PRICE_FREEZE_DETAILS;
  request: GetHotelDetailRequest;
}

export const fetchHotelPriceFreezeDetails = (
  request: GetHotelDetailRequest
): IFetchHotelPriceFreezeDetails => {
  return {
    type: actionTypes.FETCH_HOTEL_PRICE_FREEZE_DETAILS,
    request,
  };
};

export interface IRefundHotelPriceFreeze {
  type: actionTypes.REFUND_HOTEL_PRICE_FREEZE;
  id: string;
}

export const refundHotelPriceFreeze = (args: {
  id: string;
}): IRefundHotelPriceFreeze => ({
  type: actionTypes.REFUND_HOTEL_PRICE_FREEZE,
  ...args,
});

export interface ISetRefundHotelPriceFreezeResult {
  type: actionTypes.SET_REFUND_HOTEL_PRICE_FREEZE_RESULT;
  result: LodgingPriceFreezeRefundResult;
}

export const setRefundHotelPriceFreezeResult = (args: {
  result: LodgingPriceFreezeRefundResult;
}): ISetRefundHotelPriceFreezeResult => ({
  type: actionTypes.SET_REFUND_HOTEL_PRICE_FREEZE_RESULT,
  ...args,
});

export interface ISetRefundHotelPriceFreezeCallStateFailed {
  type: actionTypes.SET_REFUND_HOTEL_PRICE_FREEZE_CALL_STATE_FAILED;
}

export const setRefundHotelPriceFreezeCallStateFailed =
  (): ISetRefundHotelPriceFreezeCallStateFailed => ({
    type: actionTypes.SET_REFUND_HOTEL_PRICE_FREEZE_CALL_STATE_FAILED,
  });

export interface ISetRefundHotelPriceFreezeCallStateNotCalled {
  type: actionTypes.SET_REFUND_HOTEL_PRICE_FREEZE_CALL_STATE_NOT_CALLED;
}

export const setRefundHotelPriceFreezeCallStateNotCalled =
  (): ISetRefundHotelPriceFreezeCallStateNotCalled => ({
    type: actionTypes.SET_REFUND_HOTEL_PRICE_FREEZE_CALL_STATE_NOT_CALLED,
  });

export type HotelPriceFreezeActions =
  | IFetchHotelPriceFreezeDetails
  | ISetHotelPriceFreezeDetails
  | ISetFetchHotelPriceFreezeDetailsCallStateFailed
  | IRefundHotelPriceFreeze
  | ISetRefundHotelPriceFreezeResult
  | ISetRefundHotelPriceFreezeCallStateFailed
  | ISetRefundHotelPriceFreezeCallStateNotCalled
  | ISetPriceFreezeExperiments;

export interface ISetHotelPriceFreezeDetails {
  type: actionTypes.SET_HOTEL_PRICE_FREEZE_DETAILS;
  detail: HotelPriceFreezeDetail;
}

export const setHotelPriceFreezeDetails = (args: {
  detail: HotelPriceFreezeDetail;
}): ISetHotelPriceFreezeDetails => ({
  type: actionTypes.SET_HOTEL_PRICE_FREEZE_DETAILS,
  ...args,
});

export interface ISetFetchHotelPriceFreezeDetailsCallStateFailed {
  type: actionTypes.SET_FETCH_HOTEL_PRICE_FREEZE_DETAILS_CALL_STATE_FAILED;
}

export const setFetchHotelPriceFreezeDetailsCallStateFailed =
  (): ISetFetchHotelPriceFreezeDetailsCallStateFailed => ({
    type: actionTypes.SET_FETCH_HOTEL_PRICE_FREEZE_DETAILS_CALL_STATE_FAILED,
  });

export interface ISetPriceFreezeExperiments {
  type: actionTypes.SET_PRICE_FREEZE_EXPERIMENTS;
  experiments: IPriceFreezeExperiments;
}

export const setPriceFreezeExperiments = (
  experiments: IPriceFreezeExperiments
): ISetPriceFreezeExperiments => ({
  type: actionTypes.SET_PRICE_FREEZE_EXPERIMENTS,
  experiments,
});
