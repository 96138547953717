import { Airline, Airport, PackageItineraryResponse } from "redmond";
import { put, select } from "redux-saga/effects";
import { fetchPackages } from "../../../api/v1/itinerary/fetchPackages";
import { actions } from "../actions";
import { getAirlinesMap, getAirportMap } from "../reducer";

export function* fetchPackagesSaga(action: actions.IFetchPackages) {
  try {
    const packagesItinerariesResponse: PackageItineraryResponse =
      yield fetchPackages(action.request);

    const {
      future: newFuture,
      canceled: newCanceled,
      past: newPast,
      present: newPresent,
    } = packagesItinerariesResponse.itineraries;

    yield put(
      actions.setPackages(
        {
          present: newPresent ? newPresent.map((x) => x) : [],
          canceled: newCanceled ? newCanceled.map((x) => x) : [],
          past: newPast ? newPast.map((x) => x) : [],
          future: newFuture ? newFuture.map((x) => x) : [],
        },
        action.request.states
      )
    );
    const airlines: { [key: string]: Airline } = yield select(getAirlinesMap);
    const responseAirlinesMapArr = Object.keys(
      packagesItinerariesResponse.context.airlines
    );
    for (let i = 0; i < responseAirlinesMapArr.length; i++) {
      const currentAirline = responseAirlinesMapArr[i];
      if (!airlines[responseAirlinesMapArr[i]]) {
        airlines[currentAirline] =
          packagesItinerariesResponse.context.airlines[currentAirline];
      }
    }

    const airports: { [key: string]: Airport } = yield select(getAirportMap);
    const responseAirportMapArr = Object.keys(
      packagesItinerariesResponse.context.airports
    );
    for (let i = 0; i < responseAirportMapArr.length; i++) {
      if (!airlines[responseAirportMapArr[i]]) {
        const currentAirport = responseAirportMapArr[i];
        airports[currentAirport] =
          packagesItinerariesResponse.context.airports[currentAirport];
      }
    }
  } catch (e) {
    yield put(actions.fetchPackagesFailed(action.request));
    console.error(e);
  }
}
