import { Amount } from ".";
import { EarnResponse, RewardsAccount } from "../apis/tysons";

export interface FiatPrice {
  currencyCode: string;
  currencySymbol: string;
  value: number;
}

export interface Prices {
  fiat: FiatPrice;
  rewards: { [key: string]: RewardsPrice };
  accountSpecific?: { [key: string]: RewardsPrice };
}

export interface RewardsPrice {
  currency: string;
  value: number;
  currencyDescription?: string;
}

export type PaymentAmount = RewardsAmount | FiatAmount | SplitAmount;

export enum PaymentAmountEnum {
  RewardsAmount = "RewardsAmount",
  FiatAmount = "FiatAmount",
  SplitAmount = "SplitAmount",
}

export interface RewardsAmount extends BasePaymentAmount {
  rewardsAccountId: string;
  fiatValue: Amount;
  rewardsPrice: RewardsPrice;
  PaymentAmount: PaymentAmountEnum.RewardsAmount;
}

export interface FiatAmount extends BasePaymentAmount {
  currency: string;
  amount: number;
  PaymentAmount: PaymentAmountEnum.FiatAmount;
}

export interface SplitAmount extends BasePaymentAmount {
  fiatAmount: FiatAmount;
  rewardsAmount: RewardsAmount;
  PaymentAmount: PaymentAmountEnum.SplitAmount;
}

export interface BasePaymentAmount {
  PaymentAmount: PaymentAmountEnum;
}

export enum RewardsAccountMinimumRequirementState {
  ALL_ACCOUNT_MEET_MINIMUM_REQUIREMENT,
  ONE_ACCOUNT_DO_NOT_MEET_MINIMUM_REQUIREMENT,
  ALL_ACCOUNT_DO_NOT_MEET_MINIMUM_REQUIREMENT,
}

export const getRewardsAccountMinimumRequirementState = (
  rewardsAccounts: RewardsAccount[]
): RewardsAccountMinimumRequirementState => {
  //initilize with TRUE to meet the running condition.
  let allAccountsDoNotMeetMinimumReqirements = true;
  let oneAccountDoNotMeetMinimumRequrement = false;
  rewardsAccounts.map((account) => {
    if (
      account &&
      account.redemptionMin?.value > account.rewardsBalance.value
    ) {
      oneAccountDoNotMeetMinimumRequrement = true;
      if (allAccountsDoNotMeetMinimumReqirements)
        allAccountsDoNotMeetMinimumReqirements = true;
    } else {
      allAccountsDoNotMeetMinimumReqirements = false;
    }
  });

  if (allAccountsDoNotMeetMinimumReqirements) {
    return RewardsAccountMinimumRequirementState.ALL_ACCOUNT_DO_NOT_MEET_MINIMUM_REQUIREMENT;
  } else if (oneAccountDoNotMeetMinimumRequrement) {
    return RewardsAccountMinimumRequirementState.ONE_ACCOUNT_DO_NOT_MEET_MINIMUM_REQUIREMENT;
  } else {
    return RewardsAccountMinimumRequirementState.ALL_ACCOUNT_MEET_MINIMUM_REQUIREMENT;
  }
};

export interface ProductEarnValues {
  accountReferenceId: string;
  earnForProduct: EarnResponse;
}

export interface Percentage {
  ratio: number;
  multiplier: number;
}
