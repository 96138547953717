import axios from "axios";
import { ResendConfirmationReq } from "redmond";

import { config } from "../../config";

import { resendEmailPrefix } from "../paths";

const resendConfirmationEmail = (
  bookingType: string,
  req: ResendConfirmationReq
): Promise<void> =>
  new Promise(async (resolve, reject) => {
    const resendEndPoint = `${resendEmailPrefix}/${bookingType}/confirmation`;

    try {
      await axios.post(resendEndPoint, req, {
        baseURL: config.baseURL,
      });

      resolve();
    } catch (e) {
      reject(e);
    }
  });

export default resendConfirmationEmail;
