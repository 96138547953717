import React, { useRef, useState } from "react";
import "./styles.scss";
import {
  ActionLinks,
  CarBasicInfo,
  IActionLink,
  Icon,
  IconName,
  MyTripsCard,
  TripInfoDetails,
  useDeviceTypes,
} from "halifax";
import {
  CarReservation,
  DistKindEnum,
  IOpenModal,
  ItineraryEnum,
  ItineraryWithType,
  MyTripsModalTypes,
  Reservation,
} from "redmond";
import clsx from "clsx";
import { Box, Popover, Typography } from "@material-ui/core";

import { CarInfoContent } from "../CarInfoContent";
import { CarMap } from "../CarMap";
import { ConfirmationSummary } from "../../../ConfirmationSummary";
import { getLabels, getValues } from "../../../FlightCard/helpers";
import { PaymentSummary } from "../../../PaymentSummary";
import { SummaryCard } from "../../../SummaryCard";
import { TravelersSummary } from "../../../TravelersSummary";
import * as textConstants from "../../constants";
import {
  DESKTOP_OFFSET_SCROLL,
  HOW_REFUNDS_WORK_LINK,
  HOW_REFUNDS_WORK_TEXT,
  MOBILE_OFFSET_SCROLL,
} from "../../../../constants";
import { getCarSizeText } from "./helpers";
import { getCurrencyString } from "../../../../../../../../utils/helpers";

interface IDesktopCarCardProps {
  expandedCard: string;
  car: CarReservation;
  banner?: JSX.Element;
  isCancel?: boolean;
  isPastTrips: boolean;
  confirmationCodeClassName?: string;
  isMobile?: boolean;
  onExpandCard: (cardId: string) => void;
  setOpenModal: (modalType: IOpenModal) => void;
  driverName?: string[];
}

export const addCarType = (car: CarReservation): ItineraryWithType => ({
  ...car,
  type: ItineraryEnum.Car,
});

export const getPostalCode = (reservation: Reservation): string => {
  const { lodgingData } = reservation;
  const city = lodgingData?.city ? `${lodgingData.city},` : "";
  const state = lodgingData?.state?.code ? `${lodgingData.state.code},` : "";
  const country = lodgingData?.country ? `${lodgingData.country}` : "";
  return `${city} ${state} ${country}`;
};

export const DesktopCarCard = (props: IDesktopCarCardProps) => {
  const {
    expandedCard,
    car,
    isMobile,
    isPastTrips,
    onExpandCard,
    setOpenModal,
    banner,
    isCancel,
    confirmationCodeClassName,
    driverName,
  } = props;
  const { bookResult, paymentAmountInfo } = car;
  const { rentalRate, vehicle, supplier } = bookResult;
  const { passengerQty, bagsInfo } = vehicle;
  const bags = Object.values(bagsInfo).reduce((a, b) => a + b, 0);
  const onOpenModal = (type: MyTripsModalTypes) =>
    setOpenModal({
      type,
      selectedItinerary: addCarType(car),
    });
  const { matchesMobile } = useDeviceTypes();
  const refundPopoverRef = useRef<HTMLButtonElement>(null);
  const [refundPopoverOpen, setRefundPopoverOpen] = useState(false);

  // TODO replace with real actions when self-serve is ready
  const actions: IActionLink[] = [];
  const showCancelButton = !isPastTrips;

  const closeRefundPopover = () => setRefundPopoverOpen(false);

  const openRefundPopover = () => setRefundPopoverOpen(true);

  if (!isCancel && showCancelButton) {
    actions.push(
      {
        content: "Change Car Rental",
        onClick: () => onOpenModal(MyTripsModalTypes.ChangeCar),
      },
      {
        content: "Cancel Car Rental",
        onClick: () => onOpenModal(MyTripsModalTypes.CancelCar),
      }
    );
  } else if (isCancel) {
    actions.push({
      content: (
        <>
          {HOW_REFUNDS_WORK_LINK}
          <Icon className="info-icon" name={IconName.InfoCircle} />
        </>
      ),
      linkClassName: "how-refunds-work-link",
      linkRef: refundPopoverRef,
      onClick: openRefundPopover,
    });
  }

  actions.push({
    content: "Resend Confirmation",
    onClick: () => onOpenModal(MyTripsModalTypes.ResendConfirmation),
  });

  return (
    <Box
      id={bookResult.groundBookingId}
      key={bookResult.groundBookingId}
      className={clsx("car-trip-container", {
        mobile: isMobile,
        expanded: expandedCard === bookResult.groundBookingId,
      })}
    >
      <MyTripsCard
        className="trip-card-title"
        banner={banner}
        content={
          <TripInfoDetails
            hideTitleTag={isCancel}
            confirmationCodeClassName={confirmationCodeClassName}
            titles={{
              confirmationLabel: textConstants.CONFIRMATION,
              startLabel: `${textConstants.PICK_UP}:`,
              endLabel: `${textConstants.DROP_OFF}:`,
              streetAddress: bookResult.vendor.name,
              postalCode: bookResult.pickUp.location.name,
              phoneNum: bookResult.pickUp.location.address,
              subtitle:
                bookResult.vehicle.styledName || bookResult.vehicle.name || "",
              ...textConstants.getCarInfoDetails(car),
            }}
          />
        }
        actions={<ActionLinks actions={actions} />}
        expandString={
          expandedCard === bookResult.groundBookingId
            ? textConstants.VIEW_LESS
            : textConstants.VIEW_DETAILS
        }
        expandIcon={
          expandedCard === bookResult.groundBookingId
            ? IconName.MinusBlueCircle
            : IconName.PlusBlueCircle
        }
        isExpanded={expandedCard === bookResult.groundBookingId}
        topRightAction={
          bookResult.supplier.logo ? (
            <img
              className="supplier-icon"
              src={bookResult.supplier.logo}
              alt="Supplier logo"
            />
          ) : undefined
        }
        onExpandClick={() => {
          onExpandCard(bookResult.groundBookingId);
          setTimeout(() => {
            const OFFSET = matchesMobile
              ? MOBILE_OFFSET_SCROLL
              : DESKTOP_OFFSET_SCROLL;
            const cardTop =
              document
                ?.getElementById(bookResult.groundBookingId)
                ?.getBoundingClientRect().top || 0;
            window.scrollBy({
              top: (cardTop as number) - OFFSET,
              behavior: matchesMobile ? "auto" : "smooth",
            });
          }, 100);
        }}
        travelers={driverName}
      />

      {bookResult.groundBookingId === expandedCard && (
        <Box className="car-desktop-expanded-container">
          <Box className="car-summary-container">
            <CarMap car={car} />
            <Box className="car-info-container">
              <CarBasicInfo
                carModelDetails={{
                  carSize: getCarSizeText(vehicle.kind),
                  // TODO: ask about where we get multi-car entries
                  carModels: [vehicle.name],
                }}
                carDetailsText={{
                  orText: "or",
                  orSimilarText: "or similar",
                  unlimitedMileageText: "Unlimited Mileage",
                  freeCancellationText: "Free Cancellation",
                  nonRefundableText: "Non refundable",
                  selectButtonText: "Select",
                }}
                carInfoIconDetails={{
                  passengerCapacity: passengerQty
                    ? `${passengerQty} ${
                        passengerQty > 1 ? "Passengers" : "Passenger"
                      }`
                    : "0 Passengers",
                  bagCapacity: `${bags} ${bags === 1 ? "Bag" : "Bags"}`,
                  transmissionType: vehicle.transmissionType,
                  airConditioning: vehicle.airConditioning
                    ? "Air Conditioning"
                    : undefined,
                }}
                carAvailabilityTagDetails={{
                  freeCancellation: rentalRate.extensions.cancellation.free,
                  hasUnlimitedMileage:
                    rentalRate.extensions.distance.DistKind ===
                    DistKindEnum.Unlimited,
                }}
                topRightElement={
                  supplier.logo ? (
                    <SupplierIcon supplierIconUrl={supplier.logo} />
                  ) : (
                    <></>
                  )
                }
                isMobile={isMobile}
              />
              <CarInfoContent car={car} />
            </Box>
          </Box>
          <Box className="summary-info-container">
            <SummaryCard
              className="travellers-summary"
              action={
                <Typography
                  variant="body2"
                  className="details-link"
                  onClick={() => onOpenModal(MyTripsModalTypes.DriversModal)}
                >
                  Details
                </Typography>
              }
            >
              <TravelersSummary
                label={"Driver Information"}
                travelers={`${bookResult.driver?.givenName} ${bookResult.driver?.surname}`}
              />
            </SummaryCard>
            <SummaryCard
              className="payment-summary"
              action={
                <Typography
                  variant="body2"
                  className="details-link"
                  onClick={() => onOpenModal(MyTripsModalTypes.PaymentModal)}
                >
                  Details
                </Typography>
              }
            >
              <PaymentSummary
                tripTotalAmount={getCurrencyString(rentalRate.payNow.fiat)}
                cardLabel={getLabels(paymentAmountInfo).cardLabel}
                cardAmount={getValues(paymentAmountInfo).cardValue}
                rewardsLabel={getLabels(paymentAmountInfo).rewardLabel}
                rewardsAmount={getValues(paymentAmountInfo).rewardValue}
                showCardLabel={Boolean(getLabels(paymentAmountInfo).cardLabel)}
                showRewardsLabel={Boolean(
                  getLabels(paymentAmountInfo).rewardLabel
                )}
              />
            </SummaryCard>
            <SummaryCard
              className="confirmation-summary"
              // action={
              //   <Typography
              //     variant="body2"
              //     className="details-link"
              //     onClick={() =>
              //       onOpenModal(MyTripsModalTypes.ConfirmationModal)
              //     }
              //   >
              //     Details
              //   </Typography>
              // }
            >
              <ConfirmationSummary
                confirmationCode={
                  `H-${
                    bookResult.confirmationInfo?.supplierBookingId ||
                      bookResult.confirmationInfo?.providerBookingId ||
                      bookResult.groundBookingId
                  }` || "No confirmation number"
                }
              />
            </SummaryCard>
          </Box>
        </Box>
      )}
      {isCancel && (
        <Popover
          anchorEl={refundPopoverRef.current}
          anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
          className="how-refunds-work-popover"
          onClose={closeRefundPopover}
          open={refundPopoverOpen}
        >
          <Typography className="subtitle">{HOW_REFUNDS_WORK_TEXT}</Typography>
        </Popover>
      )}
    </Box>
  );
};

const SupplierIcon = ({ supplierIconUrl }: { supplierIconUrl: string }) => {
  return (
    <Box className="car-details-supplier-icon-wrapper">
      <img
        alt="supplier icon"
        className="supplier-icon"
        src={supplierIconUrl}
      />
    </Box>
  );
};
