import React from "react";
import { Tab, Tabs, Typography, Box } from "@material-ui/core";
import { RouteComponentProps, StaticContext } from "react-router";
import clsx from "clsx";
import { MyTripsFilter } from "redmond";
import { ActionLink, removeStyleSheetByMetaName } from "halifax";

import { IDesktopFilterTabsConnectorProps } from "./container";
import "./styles.scss";
import { getTabs } from "../../utils/getTabs";
import { CONTACT_SUPPORT_URL } from "../../../../utils/paths";
import { FAQ_CTA, FAQ_TITLE } from "../../constants";

const defaultTabs = {
  tabs: [
    { label: MyTripsFilter.UPCOMING_TRIPS },
    { label: MyTripsFilter.TRIP_REQUESTS },
    { label: MyTripsFilter.WATCHED_TRIPS },
    { label: MyTripsFilter.PRIZE_FREEZES },
    { label: MyTripsFilter.TRAVEL_CREDITS },
    { label: MyTripsFilter.PAST_TRIPS },
  ],
};

export interface IDesktopFilterTabsProps
  extends IDesktopFilterTabsConnectorProps,
    RouteComponentProps<{}, StaticContext, { prevPath?: string }> {}

export const DesktopFilterTabs = ({
  history,
  tripsFilter,
  tripsCounts,
  setSelectedCar,
  setSelectedFlight,
  setSelectedHotel,
  setTripsFilter,
  populateTripQueryParams,
}: IDesktopFilterTabsProps) => {
  const tabs = getTabs(defaultTabs.tabs);
  const handleChange = (
    _event: React.ChangeEvent<{}>,
    value: MyTripsFilter
  ) => {
    if (tripsFilter !== value) {
      setSelectedCar(null);
      setSelectedFlight(null);
      setSelectedHotel(null);
    }
    setTripsFilter(value);
    populateTripQueryParams(history);
  };

  // note: styleSheets with meta names starting with "Private" are being translated to css rules with the jss prefix on the production build;
  // they are loaded in whenever its respective components are mounted. (see https://hopper-jira.atlassian.net/wiki/spaces/ENGWEB/pages/2291728425/Observed+production-build+issues+caused+by+Material-UI+Private+classNames)
  React.useEffect(() => {
    removeStyleSheetByMetaName("PrivateTabIndicator");
  }, [tripsFilter]);

  return (
    <Box className="desktop-filter-tabs-wrapper">
      <Tabs
        value={tripsFilter}
        onChange={handleChange}
        aria-controls="desktop-trips-tabs-panel"
        className={"desktop-filter-tabs-container"}
        TabIndicatorProps={{
          // note: PrivateTabIndicator style sheet is loaded in when TabIndicator component is mounted
          onLoad: () => removeStyleSheetByMetaName("PrivateTabIndicator"),
        }}
      >
        {tabs.map((tab) => (
          <Tab
            key={tab.label}
            id={tab.label}
            value={tab.label}
            className="tab"
            tabIndex={tab.label === tripsFilter ? 0 : -1}
            label={
              <Typography
                className={clsx(
                  "label",
                  tab.label === tripsFilter && "selected"
                )}
              >
                {`${tab.label}${
                  tripsCounts[tab.label] !== undefined
                    ? ` (${tripsCounts[tab.label]})`
                    : ""
                }`}
              </Typography>
            }
          />
        ))}
      </Tabs>
      <Box className="faq-container">
        <Typography className="faq-title" variant="body1">
          {FAQ_TITLE}
        </Typography>

        <ActionLink
          className={"faq-cta"}
          content={FAQ_CTA}
          onClick={() => {
            window.open(CONTACT_SUPPORT_URL, "_blank")?.focus();
          }}
        />
      </Box>
    </Box>
  );
};
