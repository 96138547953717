import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { IStoreState } from "../../../../../../../../reducers/types";
import {
  confirmHotelCfarCancellation,
  fetchHotelCfarCancellationPolicy,
  resetHotelCfarCancellation,
} from "../../../../../../actions/actions";
import {
  getConfirmHotelCfarCancellationCallState,
  getHotelCfarCancellationPolicy,
  getHotelCfarCancellationPolicyCallState,
  getHotelCfarCancellationPolicyScenario,
  getSelectedHotelCfarReservationId,
  isHotelCfarRefundDisplayNonRefPartialEnabledSelector,
  isHotelCfarRefundDisplayShowAmountAdditionalPlacesEnabledSelector,
  isFintechCsatEnabledSelector,
} from "../../../../../../reducer/selectors";

import { CfarHotelModalContent } from "./component";

const mapStateToProps = (state: IStoreState) => ({
  hotelCfarCancellationPolicyCallState:
    getHotelCfarCancellationPolicyCallState(state),
  hotelCfarCancellationPolicyScenario:
    getHotelCfarCancellationPolicyScenario(state),
  hotelCfarCancellationPolicy: getHotelCfarCancellationPolicy(state),
  selectedHotelCfarReservationId: getSelectedHotelCfarReservationId(state),
  confirmHotelCfarCancellationCallState:
    getConfirmHotelCfarCancellationCallState(state),
  isHotelCfarRefundDisplayShowAmountAdditionalPlaces:
    isHotelCfarRefundDisplayShowAmountAdditionalPlacesEnabledSelector(state),
  isHotelCfarRefundDisplayNonRefPartialEnabled:
    isHotelCfarRefundDisplayNonRefPartialEnabledSelector(state),
  isFintechCsatEnabled: isFintechCsatEnabledSelector(state),
});

const mapDispatchToProps = {
  fetchHotelCfarCancellationPolicy,
  resetHotelCfarCancellation,
  confirmHotelCfarCancellation,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type ICfarHotelModalContentConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedCfarHotelModalContent = connector(
  withRouter(CfarHotelModalContent)
);
