import { MyTripsFilter } from "redmond";

export const CONTACT_SUPPORT_HEADER = "Contact Support";
export const CONTACT_SUPPORT_TEXT =
  "For any questions regarding your trip, please contact our support team and we'd be happy to help.";
export const CONTACT_SUPPORT_NUMBER = "844-422-6922";

export const getMissingTripsBannerText = (tripFilter: MyTripsFilter) => {
  switch (tripFilter) {
    case MyTripsFilter.PAST_TRIPS:
      return `If you have past trips that are not being displayed, please <strong>try
            again</strong> or <strong>contact support</strong>.`;
    case MyTripsFilter.UPCOMING_TRIPS:
      return `If you have upcoming trips that are not being displayed, please <strong>try
        again</strong> or <strong>contact support</strong>.`;
    case MyTripsFilter.TRAVEL_CREDITS:
      return `If you have airline travel credits that are not being displayed, please <strong>try
      again</strong> or <strong>contact support</strong>.`;
    default:
      return null;
  }
};
