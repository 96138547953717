import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../../../../reducers/types";
import {
  populateTripQueryParams,
  setSelectedFlight,
  setSelectedHotel,
  setSelectedCar,
} from "../../../actions/actions";
import PendingTripList from "./component";
import {
  getAirlinesMap,
  getAirportMap,
  getSelectedFlight,
  getSelectedHotel,
  getSelectedCar,
  hasFetchTripsError,
  getTripsFilter,
  getTripId,
  pendingItinerariesToDisplaySelector,
} from "../../../reducer";

const mapStateToProps = (state: IStoreState) => {
  return {
    airlineMap: getAirlinesMap(state),
    airportMap: getAirportMap(state),
    selectedFlight: getSelectedFlight(state),
    selectedHotel: getSelectedHotel(state),
    selectedCar: getSelectedCar(state),
    tripsFilter: getTripsFilter(state),
    selectedTripId: getTripId(state),
    pendingItinerariesToDisplay: pendingItinerariesToDisplaySelector(state),
    hasError: hasFetchTripsError(state),
  };
};

const mapDispatchToProps = {
  populateTripQueryParams,
  setSelectedFlight,
  setSelectedHotel,
  setSelectedCar,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PendingTripListConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedPendingTripList = connector(PendingTripList);
