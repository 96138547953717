import { faCreditCard } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography } from "@material-ui/core";
import React from "react";
import {
  FiatPrice,
  HotelItinerary,
  PaymentLineItemAncillaryCredit,
  PaymentLineItemAncillaryCreditEnum,
  PaymentLineItemEnum,
} from "redmond";
import {
  getCurrencySymbol,
  getTotalPriceText,
  Icon,
  IconName,
  twoDecimalFormatter,
} from "halifax";
import { getHotelStayDuration } from "../../../../../../utils/constants";
import { formatPaymentBreakdown, ILineItem } from "../../../FlightCard/helpers";
import * as textConstants from "./constants";
import "./styles.scss";
import {
  calculateHotelTotals,
  getCurrencyString,
} from "../../../../../../../../utils/helpers";

interface IHotelPaymentModalContentProps {
  hotelItinerary: HotelItinerary;
}

export const HotelPaymentModalContent = ({
  hotelItinerary,
}: IHotelPaymentModalContentProps) => {
  const { reservation, paymentBreakdown, ancillaries, redeemedEarnOffers } =
    hotelItinerary;
  const { pricing } = reservation;
  const { payNowTotal, rooms, taxes, feeBreakdown } = pricing;
  const { cfar } = ancillaries;
  const additionalFees = feeBreakdown?.total;

  const { paidTotal, calculatedTripTotal } = calculateHotelTotals({
    reservation,
    paymentBreakdown,
  });

  const paymentAncillaryCredits: PaymentLineItemAncillaryCredit[] =
    paymentBreakdown.payments.filter(
      (p) => p.PaymentLineItem == PaymentLineItemEnum.AncillaryCredit
    ) as PaymentLineItemAncillaryCredit[];
  const priceFreezeDiscountCredit = paymentAncillaryCredits.find(
    (p) =>
      p.AncillaryCredit ==
      PaymentLineItemAncillaryCreditEnum.HotelPriceFreezeDiscountCredit
  );
  const priceFreezeDepositCredit = paymentAncillaryCredits.find(
    (p) =>
      p.AncillaryCredit ==
      PaymentLineItemAncillaryCreditEnum.HotelPriceFreezeDepositCredit
  );

  const lineItems = formatPaymentBreakdown(paymentBreakdown);

  const renderPaymentForTravelers = () => {
    const roomPriceAmount = priceFreezeDiscountCredit
      ? rooms.fiat.value - priceFreezeDiscountCredit.amount.amount
      : rooms.fiat.value;
    const roomPrice: FiatPrice = {
      currencyCode: rooms.fiat.currencyCode,
      currencySymbol: rooms.fiat.currencySymbol,
      value: roomPriceAmount,
    };

    const priceFreezeDepositCreditPrice = priceFreezeDepositCredit
      ? {
          currencyCode: priceFreezeDepositCredit.amount.currency,
          currencySymbol: getCurrencySymbol(
            priceFreezeDepositCredit.amount.currency
          ),
          value: -priceFreezeDepositCredit?.amount.amount,
        }
      : null;

    const paidAtTimeOfBooking: FiatPrice = paidTotal
      ? {
          currencyCode: paidTotal.currencyCode,
          currencySymbol: paidTotal.currencySymbol,
          value: paidTotal.value,
        }
      : payNowTotal.fiat;
    return (
      <Box className="hotel-payment-container">
        <Box className="hotel-payment-details-container">
          <Typography className="payment-price-breakdown" variant="h4">
            {textConstants.PRICE_BREAKDOWN}
          </Typography>
          <Box className="hotel-pricing-row">
            <Typography variant="body2">
              {`${textConstants.ROOM} x ${getHotelStayDuration(reservation)}`}
            </Typography>
            <Typography variant="body2">
              {getTotalPriceText({
                price: roomPrice,
                priceFormatter: twoDecimalFormatter,
              })}
            </Typography>
          </Box>
          <Box className="hotel-pricing-row">
            <Typography variant="body2">
              {textConstants.TAXES_AND_FEES}
            </Typography>
            <Typography variant="body2">
              {getTotalPriceText({
                price: taxes.fiat,
                priceFormatter: twoDecimalFormatter,
              })}
            </Typography>
          </Box>
          {cfar?.premium && (
            <Box className="hotel-pricing-row">
              <Typography variant="body2" className="hotel-pricing-row-cfar">
                <Icon
                  name={IconName.CheckShieldBlue}
                  className="hotel-pricing-row-cfar-icon"
                />
                {textConstants.CANCEL_FOR_ANY_REASON}
              </Typography>
              <Typography variant="body2">
                {getTotalPriceText({
                  price: cfar.premium.amount.fiat,
                  priceFormatter: twoDecimalFormatter,
                })}
              </Typography>
            </Box>
          )}
          {priceFreezeDepositCredit && priceFreezeDepositCreditPrice ? (
            <Box className="hotel-pricing-row">
              <Typography variant="body2" className="hotel-pricing-row-pf">
                <Icon
                  name={IconName.FrozenPrice}
                  className="hotel-pricing-row-pf-icon"
                />
                {textConstants.PRICE_FREEZE_PAYMENT_APPLIED}
              </Typography>
              <Typography variant="body2">
                {getTotalPriceText({
                  price: priceFreezeDepositCreditPrice,
                  priceFormatter: twoDecimalFormatter,
                })}
              </Typography>
            </Box>
          ) : null}
          <Box className="hotel-pricing-row">
            <Typography variant="body2" className="price-bold-row">
              {textConstants.PAID_AT_TIME_OF_BOOKING}
            </Typography>
            <Typography variant="body2" className="price-bold-row">
              {getTotalPriceText({
                price: paidAtTimeOfBooking,
                priceFormatter: twoDecimalFormatter,
              })}
            </Typography>
          </Box>
          {additionalFees && additionalFees.value !== 0 && (
            <Box className="hotel-pricing-row">
              <Typography variant="body2">
                {textConstants.ADDITIONAL_SERVICE_FEE}
              </Typography>
              <Typography variant="body2">
                {getTotalPriceText({
                  price: additionalFees,
                  priceFormatter: twoDecimalFormatter,
                })}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    );
  };

  const renderTripTotal = () => {
    return (
      <Box className="trip-total-row">
        <Typography variant="body1" className="trip-total-text">
          {textConstants.TRIP_TOTAL}
        </Typography>
        <Typography variant="body1" className="trip-total-text">
          {getCurrencyString({
            currencyCode: calculatedTripTotal.currencyCode,
            currencySymbol: calculatedTripTotal.currencySymbol,
            value: calculatedTripTotal.value,
          })}
        </Typography>
      </Box>
    );
  };

  const renderRewards = () => {
    const rewards = lineItems.rewards;
    if (!rewards) {
      return null;
    }
    return (
      <Box className="rewards-container">
        <Typography variant="body2">{rewards.label}</Typography>
        <Typography variant="body1" className="rewards-points">
          {rewards.amount}
        </Typography>
      </Box>
    );
  };

  const renderPayment = () => {
    const userCard = lineItems.userCard;
    if (!userCard) {
      return null;
    }
    return (
      <Box className="payment-card-container">
        <Box className="payment-card-details-container">
          <FontAwesomeIcon icon={faCreditCard} className="credit-card-icon" />
          <Typography variant="body2" className="payment-card-details">
            {userCard.label}
          </Typography>
        </Box>
        <Typography variant="body1" className="payment-card-amount">
          {userCard.amount}
        </Typography>
      </Box>
    );
  };

  const renderTravelWallet = (
    travelWallet: ILineItem | null,
    icon: IconName
  ) => {
    if (!travelWallet) return null;
    return (
      <Box className="offers-container">
        <Box className="offers-icon-label">
          <Icon name={icon} />
          <Typography variant="body2">{travelWallet.label}</Typography>
        </Box>
        <Typography variant="body1" className="offer-amount">
          {travelWallet.amount}
        </Typography>
      </Box>
    );
  };

  const renderOffers = () =>
    renderTravelWallet(lineItems.travelWalletOffer, IconName.OfferTag);

  const renderCredits = () =>
    lineItems.travelWalletCredit?.map((credit) =>
      renderTravelWallet(credit, IconName.PiggyBank)
    );

  const renderEarnOffer = () => (
    <Box className="earn-offer-container">
      {redeemedEarnOffers?.map((offer) => (
        <Box
          className="earn-offer-banner"
          key={`hotel-itinerary-earn-offer-${offer.description}`}
        >
          <Icon name={IconName.CheckCircleFilledGreen} />
          <Typography className="earn-offer-banner-text">
            {offer.description}
          </Typography>
        </Box>
      ))}
    </Box>
  );

  return (
    <Box className="hotel-payment-modal-content">
      <Box className="hotel-payment-title-container">
        <Typography variant="h4">{textConstants.TRIP_TOTAL}</Typography>
      </Box>
      {renderPaymentForTravelers()}
      {renderTripTotal()}
      {renderOffers()}
      {renderCredits()}
      {renderRewards()}
      {renderPayment()}
      {renderEarnOffer()}
    </Box>
  );
};
