import React from "react";
import { Box, Typography } from "@material-ui/core";
import { Icon, IconName, useDeviceTypes } from "halifax";
import clsx from "clsx";
import * as constants from "./constants";
import "./styles.scss";
import { DisruptionProtectionFooterConnectorProps } from "./container";

export interface DisruptionProtectionFooterProps
  extends DisruptionProtectionFooterConnectorProps {
  isMissedConnectionProtecion: boolean;
}

export const DisruptionProtectionFooter = ({
  isMissedConnectionProtecion,
}: DisruptionProtectionFooterProps) => {
  const { matchesMobile } = useDeviceTypes();

  return (
    <Box
      className={clsx("disruption-protection-footer-root", {
        mobile: matchesMobile,
      })}
    >
      <Box className="disruption-protection-footer-container">
        <Box className="icon-section">
          <Icon
            name={IconName.CallBellBlueCircle}
            className="contact-support-icon"
          />
        </Box>
        <Box className="details-section">
          <Typography
            className="contact-support-copy"
            variant="body2"
            dangerouslySetInnerHTML={{
              __html: isMissedConnectionProtecion
                ? constants.CONTACT_SUPPORT_DETAILS_COPY_MCP
                : constants.CONTACT_SUPPORT_DETAILS_COPY,
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};
