import axios from "axios";
import { IFlightVoidAndExchangePolicyRes } from "redmond";

import { config } from "../../config";
import {
  getFlightExchangePolicyPath,
} from "../paths";

const fetchFlightExchangePolicy = (
  itineraryId: string,
): Promise<IFlightVoidAndExchangePolicyRes> =>
  new Promise(async (resolve, reject) => {
    try {
      const req = {itineraryId};
      const res = await axios.post(getFlightExchangePolicyPath, req, {
        baseURL: config.baseURL,
      });

      if (res.data.Response === "Success") {
        const voidAndExchangePolicy: IFlightVoidAndExchangePolicyRes = {
          voidScenario: undefined,
          exchangeScenario: res.data.value,
        };
        resolve(voidAndExchangePolicy);
      } else {
        reject(res.data.error);
      }
    } catch (e) {
      reject(e);
    }
  });

export default fetchFlightExchangePolicy;
