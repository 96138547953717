import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router-dom";
import { IStoreState } from "../../../../../../../../reducers/types";
import { setOpenModal } from "../../../../../../actions/actions";
import { ChangeHotelModalContent } from "./component";

const mapStateToProps = (_: IStoreState) => ({});

const mapDispatchToProps = {
  setOpenModal,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type ChangeHotelModalContentConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedChangeHotelModalContent = connector(
  withRouter(ChangeHotelModalContent)
);
