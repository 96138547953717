import React, { useMemo } from "react";
import {
  PendingFlightItinerary,
  getPendingDepartureSlice,
  getPendingReturnSlice,
  ApprovalStatus,
  Airline,
  Airport,
  AssociatedPassenger,
} from "redmond";
import dayjs from "dayjs";
import { Box, Typography } from "@material-ui/core";
import {
  ApprovalStatusChip,
  MyTripsCardTraveler,
  TripInfoDetails,
} from "halifax";
import { FlightCardConnectorProps } from "./FlightCard.connected";
import { SHORT_DATE_FORMAT, LONG_DATE_FORMAT } from "./constants";
import { PersonalBusinessLabel } from "../../PersonalBusinessLabel";

type Props = FlightCardConnectorProps & {
  pendingItinerary: PendingFlightItinerary;
};

export const getFlightInfoDetails = (
  pendingFlight: PendingFlightItinerary,
  formatDate: (date: string) => string,
  airports: { [key: string]: Airport | undefined },
  airlines: { [key: string]: Airline | undefined }
) => {
  const departureSlice = getPendingDepartureSlice(pendingFlight);
  const returnSlice = getPendingReturnSlice(pendingFlight);

  // Gets the last segment to avoid using the layover destinationCode
  const departureLastSegmentIndex = departureSlice.segments.length - 1;
  const departureDestinationSegment =
    departureSlice.segments[departureLastSegmentIndex];
  const destinationCode = departureDestinationSegment.destination.locationCode;

  const firstLegDeparture = departureSlice.segments[0];
  const firstLegReturn = returnSlice ? returnSlice.segments[0] : null;
  const originCode = firstLegDeparture.origin.locationCode;
  const airlineCode = firstLegDeparture.marketingAirline.code;
  return {
    title: `${airports[originCode]?.cityName || originCode} to ${
      airports[destinationCode]?.cityName || destinationCode
    }`,
    confirmationCode: "",
    startDate: formatDate(firstLegDeparture.scheduledDeparture),
    endDate: firstLegReturn
      ? formatDate(firstLegReturn.scheduledDeparture)
      : "",
    subtitle: returnSlice ? "Round-trip Flight" : "One-way Flight",
    airlineCode,
    airlineName:
      airlines[firstLegDeparture.marketingAirline.code]?.displayName ||
      airlineCode,
    flightNumber:
      airlineCode +
        " " +
        firstLegDeparture.marketingAirline.flightNumber.toString() ?? "",
  };
};

export const FlightCard = ({
  pendingItinerary,
  airlineMap,
  airportMap,
}: Props) => {
  const approvalStatus = pendingItinerary.pendingFlightItinerary.isExpired
    ? "unavailable"
    : (pendingItinerary.approvalInfo.status.toLowerCase() as ApprovalStatus);

  const travelers = useMemo(() => {
    const travelerNames: string[] = [];
    const pushTravelerName = ({ person }: AssociatedPassenger) => {
      if (!person) {
        return;
      }

      travelerNames.push(`${person.givenName} ${person.surname}`);
    };

    Object.values(pendingItinerary.pendingFlightItinerary.passengers)
      .flat()
      .map(pushTravelerName);

    return travelerNames;
  }, [pendingItinerary]);

  return (
    <article className="pending-itinerary pending-itinerary-flight">
      <Box className="pending-itinerary-title-row">
        <Typography className="pending-itinerary-title">
          <strong>Flight</strong> | Requested on{" "}
          {dayjs(pendingItinerary.approvalInfo.requestedAt).format(
            LONG_DATE_FORMAT
          )}
        </Typography>
        <PersonalBusinessLabel isCorporate />
      </Box>
      <div className="pending-itinerary-card">
        <MyTripsCardTraveler travelers={travelers} isTripRequest />
        <TripInfoDetails
          titles={{
            confirmationLabel: "",
            startLabel: "Departure",
            endLabel: "Return",
            ...getFlightInfoDetails(
              pendingItinerary,
              (str) => dayjs(str).format(SHORT_DATE_FORMAT),
              airportMap,
              airlineMap
            ),
          }}
        />
        <ApprovalStatusChip approvalStatus={approvalStatus} />
      </div>
    </article>
  );
};
