import { SliceDirection } from "redmond";

export const FLIGHT_DETAILS_POPOVER_HEADER = (
  sliceDirection: SliceDirection,
  destination: string,
  date: string
) => {
  return `<strong>${sliceDirection}</strong> to ${destination} on ${date}`;
};

export const FLIGHT_DETAILS_POPOVER_FARE_DETAILS_HEADER = "Fare Details";

