import {
  ActiveExperiments,
  DefaultVariantType,
} from "../../../context/experiments";
import {
  CallState,
  HotelPriceFreezeDetail,
  LodgingPriceFreezeRefundResult,
} from "redmond";
import { actionTypes, actions } from "../actions";

export interface IPriceFreezeState extends IPriceFreezeExperimentState {
  fetchHotelPriceFreezeDetailsCallState: CallState;
  hotelPriceFreezeDetails: HotelPriceFreezeDetail | null;
  refundHotelPriceFreezeCallState: CallState;
  refundHotelPriceFreezeResult: LodgingPriceFreezeRefundResult | null;
}

export const initialState: IPriceFreezeState = {
  fetchHotelPriceFreezeDetailsCallState: CallState.NotCalled,
  hotelPriceFreezeDetails: null,
  refundHotelPriceFreezeCallState: CallState.NotCalled,
  refundHotelPriceFreezeResult: null,
  experiments: null,
};

export interface IPriceFreezeExperimentState {
  experiments: IPriceFreezeExperiments | null;
}

export interface IPriceFreezeExperiments {
  [ActiveExperiments.HOTELS_PRICE_FREEZE]: DefaultVariantType;
}

export const getExperimentState = (
  state: IPriceFreezeState | undefined
): IPriceFreezeExperimentState => {
  return {
    experiments: state?.experiments ?? initialState.experiments,
  };
};

export function reducer(
  state: IPriceFreezeState = initialState,
  action: actions.HotelPriceFreezeActions
): IPriceFreezeState {
  switch (action.type) {
    case actionTypes.FETCH_HOTEL_PRICE_FREEZE_DETAILS:
      return {
        ...state,
        fetchHotelPriceFreezeDetailsCallState: CallState.InProcess,
      };
    case actionTypes.SET_FETCH_HOTEL_PRICE_FREEZE_DETAILS_CALL_STATE_FAILED:
      return {
        ...state,
        fetchHotelPriceFreezeDetailsCallState: CallState.Failed,
      };
    case actionTypes.SET_HOTEL_PRICE_FREEZE_DETAILS:
      return {
        ...state,
        fetchHotelPriceFreezeDetailsCallState: CallState.Success,
        hotelPriceFreezeDetails: action.detail,
      };
    case actionTypes.REFUND_HOTEL_PRICE_FREEZE:
      return {
        ...state,
        refundHotelPriceFreezeCallState: CallState.InProcess,
        refundHotelPriceFreezeResult: null,
      };
    case actionTypes.SET_REFUND_HOTEL_PRICE_FREEZE_RESULT:
      return {
        ...state,
        refundHotelPriceFreezeCallState: CallState.Success,
        refundHotelPriceFreezeResult: action.result,
      };
    case actionTypes.SET_REFUND_HOTEL_PRICE_FREEZE_CALL_STATE_FAILED:
      return {
        ...state,
        refundHotelPriceFreezeCallState: CallState.Failed,
      };
    case actionTypes.SET_REFUND_HOTEL_PRICE_FREEZE_CALL_STATE_NOT_CALLED:
      return {
        ...state,
        refundHotelPriceFreezeCallState: CallState.NotCalled,
        refundHotelPriceFreezeResult: null,
      };
    case actionTypes.SET_PRICE_FREEZE_EXPERIMENTS:
      return {
        ...state,
        experiments: action.experiments,
      };
    default:
      return state;
  }
}

export * from "./selectors";
