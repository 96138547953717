import React from "react";
import { Box, Typography } from "@material-ui/core";
import * as textConstants from "./constants";
import { CallState, WatchAlertView } from "redmond";
import "./styles.scss";
import { ActionButton, B2BSpinner, Icon, IconName } from "halifax";
import { StopWatchingModalContentConnectorProps } from "./container";
import clsx from "clsx";

interface IStopWatchingModalContentProps
  extends StopWatchingModalContentConnectorProps {
  onClose: () => void;
}

export const StopWatchingModalContent = ({
  openModal,
  deleteWatch,
  deleteWatchCallState,
  setDeleteWatchCallState,
  onClose,
}: IStopWatchingModalContentProps) => {
  const getModalContent = () => {
    switch (deleteWatchCallState) {
      case CallState.InProcess:
        return <B2BSpinner />;
      case CallState.Failed:
        return (
          <React.Fragment>
            <Typography className="stop-watching-title" variant="h2">
              {textConstants.STOP_WATCHING_FAILURE_TITLE}
            </Typography>
            <Typography className="stop-watching-subtitle" variant="h5">
              {textConstants.STOP_WATCHING_FAILURE_SUBTITLE}
            </Typography>
            <Box className="stop-watching-buttons">
              <ActionButton
                className={clsx("stop-watching-button", "b2b")}
                onClick={() => {
                  setDeleteWatchCallState(CallState.NotCalled);
                }}
                message={textConstants.RETRY_CTA}
                defaultStyle="h4r-primary"
              />
              <ActionButton
                className="cancel-button"
                onClick={() => onClose()}
                message={textConstants.CANCEL_CTA}
                defaultStyle="h4r-secondary"
              />
            </Box>
          </React.Fragment>
        );
      case CallState.Success:
        return (
          <React.Fragment>
            <Box className="icon-wrapper">
              <Icon name={IconName.Checked} ariaLabel="" className="icon" />
            </Box>
            <Typography className="stop-watching-title" variant="h2">
              {textConstants.STOP_WATCHING_SUCCESS}
            </Typography>
            <Box className="stop-watching-buttons">
              <ActionButton
                className={clsx("cancel-button", "b2b")}
                onClick={() => onClose()}
                message={textConstants.DONE_CTA}
                defaultStyle="h4r-primary"
              />
            </Box>
          </React.Fragment>
        );
      default:
        return (
          <React.Fragment>
            <Typography className="stop-watching-title" variant="h2">
              {textConstants.STOP_WATCHING_TITLE}
            </Typography>
            <Typography className="stop-watching-subtitle" variant="h5">
              {textConstants.STOP_WATCHING_SUBTITLE}
            </Typography>
            <Box className="stop-watching-buttons">
              <ActionButton
                className={clsx("stop-watching-button", "b2b")}
                onClick={() => {
                  deleteWatch(openModal.selectedItinerary as WatchAlertView);
                }}
                message={textConstants.STOP_WATCHING_CTA}
                defaultStyle="h4r-primary"
              />
              <ActionButton
                className="cancel-button"
                onClick={() => onClose()}
                message={textConstants.CANCEL_CTA}
                defaultStyle="h4r-secondary"
              />
            </Box>
          </React.Fragment>
        );
    }
  };
  return <Box className="stop-watching-modal-content">{getModalContent()}</Box>;
};
