import {
  CarItineraryResponse,
  CarItineraryResponseEnum,
  CarItineraryResponseSuccess,
  CarReservationState,
  // CarReservationState,
  CarReservation,
  MyTripsFilter,
  State,
} from "redmond";
import { call, put, select } from "redux-saga/effects";
import { fetchCars } from "../../../api/v1/itinerary/fetchCars";
import { actions } from "../actions";
import { getCars } from "../reducer";
import { setUpMyTripsParams } from "./setUpTripsParams";

function filterFailures(reservations: CarReservation[]) {
  return reservations.filter(
    (r) => r.bookResult.status.State !== State.Failure
  );
}

export function* fetchCarsSaga(action: actions.IFetchCars) {
  try {
    const { tripId } = yield call(setUpMyTripsParams, action);
    const carItinerariesResponse: CarItineraryResponse = yield fetchCars(
      action.request
    );
    const carReservations: { [key: string]: CarReservation[] } = yield select(
      getCars
    );
    const updatedResponse = {
      ...carItinerariesResponse,
    };

    if (
      carItinerariesResponse.CarItineraryResponse ===
      CarItineraryResponseEnum.Success
    ) {
      const resp = carItinerariesResponse as CarItineraryResponseSuccess;

      const {
        future = [...carReservations.future],
        present = [...carReservations.present],
        past = [...carReservations.past],
        canceled = [...carReservations.canceled],
      } = resp.reservations;
      const filteredFuture = filterFailures(future);
      const filteredPast = filterFailures(past);
      const filteredPresent = filterFailures(present);
      const filterCanceled = filterFailures(canceled);
      let isSelectedCarInPastTrips = false;
      let selectedCar = [...filteredFuture, ...filteredPresent].find(
        (car) => car.bookResult.groundBookingId === tripId
      );

      // the reservations we set in Redux is the filtered reservations, not resp.reservations
      (updatedResponse as CarItineraryResponseSuccess).reservations = {
        canceled: filterCanceled,
        future: filteredFuture,
        past: filteredPast,
        present: filteredPresent,
      };

      if (!selectedCar) {
        const pastSelectedCar = filteredPast.find(
          (car) => car.bookResult.groundBookingId === tripId
        );
        selectedCar = pastSelectedCar;
        isSelectedCarInPastTrips = !!pastSelectedCar;
      }
      if (!selectedCar) {
        const canceledCar = filterCanceled.find(
          (car) => car.bookResult.groundBookingId === tripId
        );
        selectedCar = canceledCar;
      }
      if (isSelectedCarInPastTrips) {
        yield put(actions.setTripsFilter(MyTripsFilter.PAST_TRIPS));
        yield put(actions.populateTripQueryParams(action.history, { tripId }));
      }
      if (selectedCar) {
        yield put(actions.setSelectedCar(selectedCar));
      }
      const requestState = action.request.states;
      switch (true) {
        case requestState.includes(CarReservationState.Past) &&
          requestState.includes(CarReservationState.Canceled):
          yield put(
            actions.setCars(
              {
                reservations: {
                  ...carReservations,
                  past: filteredPast,
                  canceled: filterCanceled,
                },
                CarItineraryResponse: CarItineraryResponseEnum.Success,
              },
              [CarReservationState.Past, CarReservationState.Canceled]
            )
          );
          break;
        case requestState.includes(CarReservationState.Present) &&
          requestState.includes(CarReservationState.Future):
          yield put(
            actions.setCars(
              {
                reservations: {
                  ...carReservations,
                  present: filteredPresent,
                  future: filteredFuture,
                },
                CarItineraryResponse: CarItineraryResponseEnum.Success,
              },
              [CarReservationState.Present, CarReservationState.Future]
            )
          );
          break;
        default:
          break;
      }
    } else {
      yield put(actions.setCars(updatedResponse, action.request.states));
      throw Error("Car Itineraries call failed");
    }
  } catch (e) {
    yield put(actions.fetchCarsFailed(action.request));
    console.error(e);
  }
}
