import React from "react";
import "./styles.scss";
import { PriceFreezeListConnectorProps } from "./container";
import { Box, Typography } from "@material-ui/core";
import { RouteComponentProps } from "react-router";
import {
  PriceFreezeView,
  PRICE_FREEZE_ID_QUERY_PARAM,
  VIEWED_FROZEN_PRICES,
  ViewedFrozenPricesTrips,
  COMPLETE_BOOKING_PF,
  CompleteBookinPF,
  PriceFreezeWithType,
  CallState,
  COMPLETE_BOOKING_HOTEL_PRICE_FREEZE,
  CompleteBookingHotelPriceFreeze,
} from "redmond";
import queryStringParser from "query-string";
import dayjs from "dayjs";
import * as textConstants from "./constants";
import { Divider } from "@material-ui/core";
import {
  PATH_FLIGHT_FREEZE_OVERVIEW,
  PATH_HOTEL_FREEZE_OVERVIEW,
} from "../../../../utils/paths";
import { trackEvent } from "../../../../api/v1/analytics/trackEvent";
import { FlightPriceFreezeCard } from "../FlightPriceFreezeCard";
import { HotelPriceFreezeCard } from "../HotelPriceFreezeCard";
import { Icon, IconName } from "halifax";

export interface IPriceFreezeListProps
  extends PriceFreezeListConnectorProps,
    RouteComponentProps {
  isMobile?: boolean;
}

export function PriceFreezeList({
  isMobile,
  notPastTripEndDateCombinedPriceFreezesSorted,
  listPriceFreezeCallState,
  listHotelPriceFreezeCallState,
  priceFreezeCounts,
  airlines,
  airports,
  history,
}: IPriceFreezeListProps) {
  React.useEffect(() => {
    if (
      (listPriceFreezeCallState === CallState.Success ||
        listPriceFreezeCallState === CallState.Failed) &&
      (listHotelPriceFreezeCallState === CallState.Success ||
        listHotelPriceFreezeCallState === CallState.Failed)
    ) {
      trackEvent({
        eventName: VIEWED_FROZEN_PRICES,
        properties: {
          price_freeze_active: priceFreezeCounts.flightActive,
          price_freeze_expired: priceFreezeCounts.flightExpired,
          hotel_price_freeze_active: priceFreezeCounts.hotelActive,
          hotel_price_freeze_expired: priceFreezeCounts.hotelExpired,
        } as ViewedFrozenPricesTrips,
      });
    }
  }, [
    notPastTripEndDateCombinedPriceFreezesSorted,
    listPriceFreezeCallState,
    listHotelPriceFreezeCallState,
  ]);

  const onFreezeAgainFlight = (_pf: PriceFreezeView) => {
    // TODO: add redirect logic see: https://hopper-jira.atlassian.net/browse/CFTC-133
    return;
  };

  const onContinueToBookingFlight = (id: string) => {
    trackEvent({
      eventName: COMPLETE_BOOKING_PF,
      properties: {
        price_freeze_id: id,
      } as CompleteBookinPF,
    });
    history.push({
      pathname: PATH_FLIGHT_FREEZE_OVERVIEW,
      state: {
        fromTrips: true,
      },
      search: queryStringParser.stringify({
        [PRICE_FREEZE_ID_QUERY_PARAM]: id,
      }),
    });
  };

  const onContinueToBookingHotel = (id: string) => {
    trackEvent({
      eventName: COMPLETE_BOOKING_HOTEL_PRICE_FREEZE,
      properties: {
        lob: "hotel",
        price_freeze_id: id,
      } as CompleteBookingHotelPriceFreeze,
    });
    history.push({
      pathname: PATH_HOTEL_FREEZE_OVERVIEW,
      state: {
        fromTrips: true,
      },
      search: queryStringParser.stringify({
        [PRICE_FREEZE_ID_QUERY_PARAM]: id,
      }),
    });
  };

  return (
    <Box className="price-freeze-list">
      {notPastTripEndDateCombinedPriceFreezesSorted.map(
        (priceFreezeWithType: PriceFreezeWithType) => {
          if (priceFreezeWithType.type === "flight") {
            const flightPriceFreeze = priceFreezeWithType.priceFreeze;
            const flightPriceFreezeCard = (
              <FlightPriceFreezeCard
                flightPriceFreeze={flightPriceFreeze}
                airports={airports}
                airlines={airlines}
                isMobile={isMobile}
                onFreezeAgain={onFreezeAgainFlight}
                onContinueToBooking={onContinueToBookingFlight}
              ></FlightPriceFreezeCard>
            );

            return isMobile ? (
              flightPriceFreezeCard
            ) : (
              <Box key={flightPriceFreeze.priceFreeze.id}>
                <Box className="summary-label-container">
                  <Icon
                    className="summary-label-icon"
                    name={IconName.FlightFunnelIcon}
                  />
                  <Typography
                    variant="body1"
                    className="summary-label"
                    dangerouslySetInnerHTML={{
                      __html: `${textConstants.FROZEN_HEADER_FLIGHT}${dayjs(
                        flightPriceFreeze.tripDetails.slices[0].departureTime
                      ).format("ddd, MMM D")}`,
                    }}
                  />
                </Box>
                {flightPriceFreezeCard}
                <Divider className="itinerary-card-divider" />
              </Box>
            );
          } else if (priceFreezeWithType.type === "hotel") {
            const hotelPriceFreeze = priceFreezeWithType.priceFreeze;
            const hotelPriceFreezeCard = (
              <HotelPriceFreezeCard
                hotelPriceFreeze={hotelPriceFreeze}
                isMobile={isMobile}
                onContinueToBooking={onContinueToBookingHotel}
              ></HotelPriceFreezeCard>
            );

            return isMobile ? (
              hotelPriceFreezeCard
            ) : (
              <Box key={hotelPriceFreeze.voucher.id}>
                <Box className="summary-label-container">
                  <Icon
                    className="summary-label-icon"
                    name={IconName.HotelFunnelIcon}
                  />
                  <Typography
                    variant="body1"
                    className="summary-label"
                    dangerouslySetInnerHTML={{
                      __html: `${textConstants.FROZEN_HEADER_HOTEL}${dayjs(
                        hotelPriceFreeze.voucher.reservation.start
                      ).format("ddd, MMM D")}`,
                    }}
                  />
                </Box>
                {hotelPriceFreezeCard}
                <Divider className="itinerary-card-divider" />
              </Box>
            );
          } else {
            return null;
          }
        }
      )}
    </Box>
  );
}
