import * as actionTypes from "./constants";
import { CreditDetail, TravelWalletCredit, TravelWalletOffer } from "redmond";

export const fetchTravelWalletDetails = (): IFetchTravelOffersDetails => ({
  type: actionTypes.FETCH_TRAVEL_WALLET_DETAILS,
});

export interface IFetchTravelOffersDetails {
  type: actionTypes.FETCH_TRAVEL_WALLET_DETAILS;
}

export const fetchTravelWalletDetailsCallStateFailed =
  (): IFetchTravelWalletDetailsCallStateFailed => ({
    type: actionTypes.SET_FETCH_TRAVEL_WALLET_DETAILS_CALL_STATE_FAILED,
  });

export interface IFetchTravelWalletDetailsCallStateFailed {
  type: actionTypes.SET_FETCH_TRAVEL_WALLET_DETAILS_CALL_STATE_FAILED;
}

export const setTravelWalletOffers = (
  offers: TravelWalletOffer[]
): ISetTravelWalletOffers => ({
  type: actionTypes.SET_TRAVEL_WALLET_OFFERS,
  payload: offers,
});

export interface ISetTravelWalletOffers {
  type: actionTypes.SET_TRAVEL_WALLET_OFFERS;
  payload: TravelWalletOffer[];
}

export const setTravelWalletCredit = (
  creditBreakdown: CreditDetail[],
  credit?: TravelWalletCredit
): ISetTravelWalletCredit => ({
  type: actionTypes.SET_TRAVEL_WALLET_CREDIT,
  credit,
  creditBreakdown
});

export interface ISetTravelWalletCredit {
  type: actionTypes.SET_TRAVEL_WALLET_CREDIT;
  credit?: TravelWalletCredit;
  creditBreakdown: CreditDetail[];
}

export type MyTripsTravelWalletActions =
  | IFetchTravelOffersDetails
  | IFetchTravelWalletDetailsCallStateFailed
  | ISetTravelWalletOffers
  | ISetTravelWalletCredit;
