import { Dayjs } from "dayjs";
import { getFullTimeString } from "halifax";

export const DATE_FORMAT = "ddd, MMM DD";
export const ROUND_TRIP = "Round-trip Flight";
export const ONE_WAY_TRIP = "One-way Flight";
export const COMPLETE_BOOKING = "Continue to Booking";
export const freezeAgain = (price: string) => `Freeze Again | $${price}`;
export const EXPIRES_ON = "Expires on";
export const FROZEN_HEADER_MOBILE_FLIGHT = "Flight";
export const CONFIRMATION = "Confirmation:";
export const DEPARTURE = "Departure";
export const RETURN = "Return";
export const formatBanner = (dateObject: Dayjs): string =>
    `This frozen price expired on ${getFullTimeString(dateObject)}.`;
