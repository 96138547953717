import React from "react";
import { HotelConfirmationCodeEnum, HotelItinerary } from "redmond";
import { Typography, Box } from "@material-ui/core";
import clsx from "clsx";

import { ConfirmationModalHotelContentConnectorProps } from "./container";
import "./styles.scss";
import * as textConstants from "./constants";

interface IConfirmationModalHotelContentProps
  extends ConfirmationModalHotelContentConnectorProps {
  hotel: HotelItinerary;
}

export interface IConfirmationNumber {
  label: string;
  locator: string;
}

export const getConfirmationNumber = ({
  hotel,
}: {
  hotel: HotelItinerary;
}): IConfirmationNumber | null => {
  if (hotel.reservation.hotelConfirmationCode.HotelConfirmationCode ==
    HotelConfirmationCodeEnum.HotelConfirmationConfirmed
  ) {
    return {
      label: textConstants.HOTEL_CONFIRMATION_LABEL,
      locator: hotel.reservation.hotelConfirmationCode["confirmationCode"],
    };
  } else {
    return null;
  }
};

export const getCapOneConfirmationNumber = ({
  hotel,
}: {
  hotel: HotelItinerary;
}): IConfirmationNumber | null => {
    return {
      label: textConstants.CAPITAL_ONE_LABEL,
      locator: "H-" + hotel.reservation.reservationId,
    };
};

export const ConfirmationModalHotelContent = ({
  hotel,
}: IConfirmationModalHotelContentProps) => {
  const confirmationNumberCapOne = getCapOneConfirmationNumber({ hotel });
  const confirmationNumberHotel = getConfirmationNumber({ hotel });

  const renderConfirmationNumbers = (confirmationNumber: IConfirmationNumber | null) => {
    if (confirmationNumber) {
      return (
        <Box className="confirmation-container">
          <Box
            key={`${confirmationNumber.locator}`}
            className={clsx("confirmation-details-container container-border hotel")}
          >
            <Box className="confirmation-details">
              <Typography className="confirmation-label" variant="body2">
                {confirmationNumber.label}
              </Typography>
              <Typography variant="body2" className="confirmation-locator">
                {confirmationNumber.locator}
              </Typography>
            </Box>
          </Box>
        </Box>
      );
    }
    return null;
  };

  return (
    <Box className="confirmation-modal-content">
      <Box className="confirmation-title-container">
        <Typography variant="h4">
          {`${textConstants.CONFIRMATION_TITLE}`}
        </Typography>
        <Typography variant="body2" className="confirmation-text">
          {textConstants.MODAL_SUBTITLE}
        </Typography>
      </Box>
      {renderConfirmationNumbers(confirmationNumberCapOne)}
      {renderConfirmationNumbers(confirmationNumberHotel)}
    </Box>
  );
};
