import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import {
  ActionLinks,
  BannerSeverity,
  IActionLink,
  Icon,
  IconName,
  NotificationBanner,
  StatusTag,
  VacationRentalOccupancyInfo,
} from "halifax";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import {
  HomesReservationStatus,
  ListingCollectionEnum,
  MyTripsFilter,
  MyTripsModalTypes,
  TagColors,
} from "redmond";

import { ConfirmationSummary } from "../ConfirmationSummary";

import * as textConstants from "../HomeCard/constants";
import { MobileActionLinks } from "../MobileActionLinks";
import { SummaryCard } from "../SummaryCard";
import { MobileHomeItineraryDetailsConnectorProps } from "./container";

import "./styles.scss";
import {
  HomeAddress,
  HomeCancellationPolicy,
  HomeCheckInCheckOutPolicy,
} from "../HomeCard/components/HomeInfoParts/component";
import { HomeMap } from "../HomeCard/components/HomeMap";
import { TravelersSummary } from "../TravelersSummary";
import { addHomeType } from "../HomeCard/components/DesktopHomeCard/component";
import { PaymentSummary } from "../PaymentSummary";
import {
  calculateHomeTotals,
  getCurrencyString,
} from "../../../../../../utils/helpers";
import { getLabels, getValues } from "../FlightCard/helpers";

export interface IMobileHomeItineraryDetailsProps
  extends RouteComponentProps,
    MobileHomeItineraryDetailsConnectorProps {}

export const MobileHomeItineraryDetails = ({
  home,
  history,
  setOpenModal,
  setSelectedHome,
  tripsFilter,
}: IMobileHomeItineraryDetailsProps) => {
  if (!home) {
    return null;
  }

  const { title, confirmationCode, startDate, endDate, subtitle } =
    textConstants.getHomeInfoDetails(home, true);
  const onOpenModal = (type: MyTripsModalTypes) =>
    setOpenModal({
      type,
      selectedItinerary: addHomeType(home),
    });

  const { paymentBreakdown, reservation, paymentsAndCredits } = home;
  const resState = reservation.status;

  const { calculatedTripTotal } = calculateHomeTotals(
    reservation,
    paymentBreakdown
  );

  const isCancelled =
    resState.ReservationStatus === HomesReservationStatus.Cancelled;
  const isPastTrips = tripsFilter === MyTripsFilter.PAST_TRIPS;

  const actions: IActionLink[] = [];
  const topActions: IActionLink[] = []; // render at the top of the page

  if (!isCancelled && !isPastTrips) {
    actions.push({
      content: "Cancel Reservation",
      onClick: () => onOpenModal(MyTripsModalTypes.CancelHome),
    });
  }

  actions.push({
    content: "Resend Confirmation",
    onClick: () => onOpenModal(MyTripsModalTypes.ResendConfirmation),
  });

  if (
    reservation.listingCollection !== ListingCollectionEnum.Premier &&
    reservation.listingCollection !== ListingCollectionEnum.Lifestyle &&
    (reservation.hostInfo?.contacts || []).length > 0
  ) {
    actions.push({
      content: "Contact Host",
      onClick: () => onOpenModal(MyTripsModalTypes.ContactHostHome),
    });
  }

  if (!isCancelled) {
    actions.push({
      content: "View Itemized Receipt",
      onClick: () => onOpenModal(MyTripsModalTypes.ItemizedHomeReceipt),
    });
  }

  return (
    <Box className="mobile-trip-details">
      <Box className="mobile-trip-details-header">
        <FontAwesomeIcon
          icon={faChevronLeft}
          onClick={() => {
            history.goBack();
            setSelectedHome(null);
          }}
        />
        <Box className="header-info">
          <Typography variant="subtitle1">{title}</Typography>
          <Typography variant="caption">
            {reservation.lengthOfStay}{" "}
            {reservation.lengthOfStay > 1 ? "nights" : "night"}
          </Typography>
        </Box>
      </Box>
      {isCancelled && (
        <NotificationBanner
          className="status-banner"
          icon={<Icon name={IconName.ErrorAlert} />}
          label={textConstants.CANCELED_RES}
          severity={BannerSeverity.ERROR}
        />
      )}
      {topActions.length ? <ActionLinks actions={topActions} /> : null}
      <Box className={clsx("mobile-trip-card", "mobile-vr-summary-card")}>
        {reservation.listingCollection === ListingCollectionEnum.Premier && (
          <Icon name={IconName.PremierCollectionRibbon} />
        )}
        {reservation.listingCollection === ListingCollectionEnum.Lifestyle && (
          <Icon name={IconName.LifestyleCollectionRibbon} />
        )}

        {isCancelled && (
          <StatusTag
            className="title-status-tag"
            tagInfo={{
              label: textConstants.CANCELED,
              type: TagColors.RED,
            }}
          />
        )}
        <Typography variant="body1" className="trips-title">
          {title}
        </Typography>
        {subtitle && <Typography variant="caption">{subtitle}</Typography>}
        <Box className="info-container">
          <Typography className="label" variant="caption">
            {textConstants.CONFIRMATION}
          </Typography>
          <Typography
            className={clsx("code", { warning: isCancelled })}
            variant="caption"
          >
            {confirmationCode}
          </Typography>
        </Box>
      </Box>
      <SummaryCard className="mobile-trip-card mobile-location-details">
        <Box className="mobile-location-details-container">
          <Box className="mobile-home-dates">
            <Typography variant="subtitle2" className="date-label">
              {`${textConstants.CHECK_IN}: `}
            </Typography>
            <Typography variant="subtitle2">{startDate}</Typography>
            <Typography variant="subtitle2">|</Typography>
            <Typography variant="subtitle2" className="date-label">
              {`${textConstants.CHECK_OUT}: `}
            </Typography>
            <Typography variant="subtitle2">{endDate}</Typography>
          </Box>
          <HomeMap home={home} hideLabel />
        </Box>
      </SummaryCard>
      <SummaryCard className="mobile-trip-card">
        <HomeAddress listing={home.reservation.listing} />
      </SummaryCard>
      <SummaryCard className="mobile-trip-card mobile-vr-type">
        <Box className="vr-type-container">
          <Typography className="vr-type-title">
            {`${textConstants.VACATION_RENTAL_TYPE}`}
          </Typography>
          <VacationRentalOccupancyInfo
            maxGuestsOccupancy={reservation.listing.maxGuests}
            bedsCount={reservation.listing.numberOfBeds}
          />
        </Box>
      </SummaryCard>
      <SummaryCard className="mobile-trip-card">
        <HomeCheckInCheckOutPolicy home={home} includeCheckInInfoCopy />
      </SummaryCard>
      <SummaryCard className="mobile-trip-card">
        <HomeCancellationPolicy
          cancellationPolicy={reservation.cancellationPolicy}
        />
      </SummaryCard>
      <SummaryCard className="mobile-trip-card">
        <TravelersSummary
          label="Guest Information"
          travelers={`${reservation.primaryGuest.givenName} ${reservation.primaryGuest.surname}`}
        />
      </SummaryCard>
      <SummaryCard
        className="mobile-payment-summary mobile-trip-card"
        action={
          <FontAwesomeIcon
            className="mobile-right-chevron"
            onClick={() => onOpenModal(MyTripsModalTypes.PaymentModal)}
            icon={faChevronRight}
          />
        }
      >
        <PaymentSummary
          // TODO: Fix fields when hotels itinerary, payment field is completed
          tripTotalAmount={getCurrencyString({
            currencyCode: calculatedTripTotal.currencyCode,
            currencySymbol: calculatedTripTotal.currencySymbol,
            value: calculatedTripTotal.value,
          })}
          cardLabel={getLabels(paymentsAndCredits).cardLabel}
          cardAmount={getValues(paymentsAndCredits).cardValue}
          rewardsLabel={getLabels(paymentsAndCredits).rewardLabel}
          rewardsAmount={getValues(paymentsAndCredits).rewardValue}
          showCardLabel={Boolean(getLabels(paymentsAndCredits).cardLabel)}
          showRewardsLabel={Boolean(getLabels(paymentsAndCredits).rewardLabel)}
        />
      </SummaryCard>
      <SummaryCard className="mobile-trip-card confirmation-summary">
        <ConfirmationSummary confirmationCode={confirmationCode} />
      </SummaryCard>
      <MobileActionLinks actions={actions} />
    </Box>
  );
};
