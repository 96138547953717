import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { IStoreState } from "../../../../../../reducers/types";
import {
  fetchHotels,
  setSelectedHotel,
  populateTripQueryParams,
  setOpenModal,
} from "../../../../actions/actions";
import { HotelCard } from "./component";
import { getSelectedHotel, getTripsFilter } from "../../../../reducer";

const mapStateToProps = (state: IStoreState) => ({
  tripsFilter: getTripsFilter(state),
  selectedHotel: getSelectedHotel(state),
});

const mapDispatchToProps = {
  fetchHotels,
  setSelectedHotel,
  populateTripQueryParams,
  setOpenModal,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type HotelCardConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedHotelCard = connector(withRouter(HotelCard));
