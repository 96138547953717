import { takeEvery } from "redux-saga/effects";

import { fetchUserPassengersSaga } from "./fetchUserPassengersSaga";
import { fetchUserPassengers } from "../reducer";
import { getConfigTenant, isCaponeTenant } from "@capone/common";
import { fetchCorpUserPassengersSaga } from "./capone-corporate/fetchCorpUserPassengersSaga";

export function* watchFetchUserPassengers() {
  yield takeEvery(
    fetchUserPassengers,
    isCaponeTenant(getConfigTenant())
      ? fetchUserPassengersSaga
      : fetchCorpUserPassengersSaga
  );
}
