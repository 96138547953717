import axios from "axios";
import {
  SubmitDisruptionRefundRequest,
  SubmitDisruptionRefundResponse,
} from "redmond";

import { config } from "../../config";
import { submitFlightRefundPath } from "../paths";

/**
 * @description Submit the prepared refund byte array to realize the refund
 * @param {SubmitDisruptionRefundRequest} req Contains the itinerary and the prepared refund byte array to submit
 * @return {SubmitDisruptionRefundResponse} Response indicating success or failure
 */
const submitDisruptionFlightRefund = (
  req: SubmitDisruptionRefundRequest
): Promise<SubmitDisruptionRefundResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await axios.post(submitFlightRefundPath, req, {
        baseURL: config.baseURL,
      });

      resolve(res.data);
    } catch (e) {
      reject(e);
    }
  });

export default submitDisruptionFlightRefund;
