import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../../../../../../../reducers/types";
import { ConfirmationModalHotelContent } from "./component";

const mapStateToProps = (_: IStoreState) => ({});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type ConfirmationModalHotelContentConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedConfirmationModalHotelContent = connector(
  ConfirmationModalHotelContent
);
