import axios from "axios";
import { CancellationInfoRequest } from "redmond";

import { config } from "../../config";
import { getHomesCancelQuotePath } from "../paths";
import { HomesCancelQuoteResponse } from "@b2bportal/homes-api";

/**
 * @description one of two homes cancellation endpoint (this one gets policy)
 * @param {CancellationInfoRequest} req Contains the customer reservation id
 * @return {HomeCancelInfoResponse} The cancellation info for the itinerary passed in
 */
const getHomesCancelQuote = (
  req: CancellationInfoRequest
): Promise<HomesCancelQuoteResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await axios.post(getHomesCancelQuotePath, req, {
        baseURL: config.baseURL,
      });

      resolve(res.data);
    } catch (e) {
      reject(e);
    }
  });

export default getHomesCancelQuote;
