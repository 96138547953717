import { createSelector } from "@reduxjs/toolkit";
import { ActiveExperiments, AVAILABLE } from "../../../../context/experiments";
import { IStoreState } from "../../../../reducers/types";
import {
  ResultEnum,
  CallState,
  LodgingPriceFreezeRefundResponseEnum,
  GetMatchingRoomErrorEnum,
  GetLodgingErrorEnum,
  HotelPriceFreezeRefundOutcomeProperties,
  HotelPriceFreezeRefundChoiceProperties,
  getHotelPriceFreezeRefundOutcomeProperties,
  getHotelPriceFreezeRefundChoiceProperties,
} from "redmond";

export const priceFreezeExperimentsSelector = (state: IStoreState) =>
  state.priceFreeze.experiments;

export const hotelPriceFreezeVariantSelector = createSelector(
  priceFreezeExperimentsSelector,
  (experiments) => experiments?.[ActiveExperiments.HOTELS_PRICE_FREEZE]
);

export const isHotelPriceFreezeEnabledSelector = createSelector(
  hotelPriceFreezeVariantSelector,
  (hotelPriceFreezeVariant) => hotelPriceFreezeVariant === AVAILABLE
);

export const fetchHotelPriceFreezeDetailsCallStateSelector = (
  state: IStoreState
) => state.priceFreeze.fetchHotelPriceFreezeDetailsCallState;

export const hotelPriceFreezeDetailsSelector = (state: IStoreState) =>
  state.priceFreeze.hotelPriceFreezeDetails;

export const refundHotelPriceFreezeResultSelector = (state: IStoreState) =>
  state.priceFreeze.refundHotelPriceFreezeResult;

export const refundHotelPriceFreezeCallStateSelector = (state: IStoreState) =>
  state.priceFreeze.refundHotelPriceFreezeCallState;

export const hotelPriceFreezeLodgingResultSelector = createSelector(
  hotelPriceFreezeDetailsSelector,
  (hotelPriceFreezeDetails) => hotelPriceFreezeDetails?.lodging
);

export const hotelPriceFreezeLodgingSelector = createSelector(
  hotelPriceFreezeLodgingResultSelector,
  (hotelPriceFreezeLodgingResult) => {
    return hotelPriceFreezeLodgingResult?.Result == ResultEnum.Ok
      ? hotelPriceFreezeLodgingResult.value
      : undefined;
  }
);

export const hotelPriceFreezeLodgingErrorSelector = createSelector(
  hotelPriceFreezeLodgingResultSelector,
  (hotelPriceFreezeLodgingResult) => {
    return hotelPriceFreezeLodgingResult?.Result == ResultEnum.Err
      ? hotelPriceFreezeLodgingResult.err
      : undefined;
  }
);

export const hotelPriceFreezeVoucherSelector = createSelector(
  hotelPriceFreezeDetailsSelector,
  (hotelPriceFreezeDetails) => hotelPriceFreezeDetails?.voucher
);

export const hotelPriceFreezeVoucherActionSelector = createSelector(
  hotelPriceFreezeVoucherSelector,
  (voucher) => voucher?.action
);

export const hotelPriceFreezeVoucherReservationSelector = createSelector(
  hotelPriceFreezeVoucherSelector,
  (voucher) => voucher?.reservation
);

export const hotelPriceFreezeFrozenPricingListSelector = createSelector(
  hotelPriceFreezeVoucherSelector,
  (voucher) => voucher?.pricings
);

export const hotelPriceFreezeTotalFrozenPricingSelector = createSelector(
  hotelPriceFreezeFrozenPricingListSelector,
  (hotelPriceFreezeFrozenPricingList) =>
    hotelPriceFreezeFrozenPricingList?.total
);

export const hotelPriceFreezeNightlyFrozenPricingSelector = createSelector(
  hotelPriceFreezeFrozenPricingListSelector,
  (hotelPriceFreezeFrozenPricingList) =>
    hotelPriceFreezeFrozenPricingList?.nightly
);

export const hotelPriceFreezeRoomWithPricingResultSelector = createSelector(
  hotelPriceFreezeDetailsSelector,
  (hotelPriceFreezeDetails) => hotelPriceFreezeDetails?.roomWithPricing
);

export const hotelPriceFreezeRoomWithPricingSelector = createSelector(
  hotelPriceFreezeRoomWithPricingResultSelector,
  (hotelPriceFreezeRoomWithPricingResult) => {
    return hotelPriceFreezeRoomWithPricingResult?.Result == ResultEnum.Ok
      ? hotelPriceFreezeRoomWithPricingResult.value
      : undefined;
  }
);

export const hotelPriceFreezeRoomWithPricingErrorSelector = createSelector(
  hotelPriceFreezeRoomWithPricingResultSelector,
  (hotelPriceFreezeRoomWithPricingResult) => {
    return hotelPriceFreezeRoomWithPricingResult?.Result == ResultEnum.Err
      ? hotelPriceFreezeRoomWithPricingResult.err
      : undefined;
  }
);

export const hotelPriceFreezeCurrentPricingListSelector = createSelector(
  hotelPriceFreezeRoomWithPricingSelector,
  (hotelPriceFreezeRoomWithPricing) => hotelPriceFreezeRoomWithPricing?.pricings
);

export const hotelPriceFreezeTotalCurrentPricingSelector = createSelector(
  hotelPriceFreezeCurrentPricingListSelector,
  (hotelPriceFreezeCurrentPricingList) =>
    hotelPriceFreezeCurrentPricingList?.total
);

export const hotelPriceFreezeNightlyCurrentPricingSelector = createSelector(
  hotelPriceFreezeCurrentPricingListSelector,
  (hotelPriceFreezeCurrentPricingList) =>
    hotelPriceFreezeCurrentPricingList?.nightly
);

export const hotelPriceFreezeRoomInfoSelector = createSelector(
  hotelPriceFreezeRoomWithPricingSelector,
  (hotelPriceFreezeRoomWithPricing) => hotelPriceFreezeRoomWithPricing?.roomInfo
);

export const hasSuccessfullyRefundedHotelPriceFreezeSelector = createSelector(
  refundHotelPriceFreezeResultSelector,
  refundHotelPriceFreezeCallStateSelector,
  (refundResult, refundCallState) =>
    refundCallState === CallState.Success &&
    refundResult?.Result === ResultEnum.Ok &&
    refundResult.value.RefundResponse ===
      LodgingPriceFreezeRefundResponseEnum.Success
);

export const hotelPriceFreezeRefundOutcomePropertiesSelector = createSelector(
  hotelPriceFreezeVoucherSelector,
  refundHotelPriceFreezeResultSelector,
  (voucher, refundResult): HotelPriceFreezeRefundOutcomeProperties => {
    return getHotelPriceFreezeRefundOutcomeProperties({
      voucher,
      refundResult: refundResult ?? undefined,
    });
  }
);

export const hotelPriceFreezeRefundChoicePropertiesSelector = createSelector(
  hotelPriceFreezeVoucherSelector,
  hotelPriceFreezeCurrentPricingListSelector,
  refundHotelPriceFreezeResultSelector,
  (
    voucher,
    currentPricingList,
    refundResult
  ): HotelPriceFreezeRefundChoiceProperties => {
    return getHotelPriceFreezeRefundChoiceProperties({
      voucher,
      pricingsFromGetDetail: currentPricingList,
      refundResult: refundResult ?? undefined,
    });
  }
);

export const hotelPriceFreezeLodgingErrSelector = createSelector(
  hotelPriceFreezeLodgingResultSelector,
  (hotelPriceFreezeLodgingResult) => {
    return hotelPriceFreezeLodgingResult?.Result == ResultEnum.Err
      ? hotelPriceFreezeLodgingResult.err
      : null;
  }
);

export const hotelPriceFreezeRoomWithPricingErrSelector = createSelector(
  hotelPriceFreezeRoomWithPricingResultSelector,
  (hotelPriceFreezeRoomWithPricingResult) => {
    return hotelPriceFreezeRoomWithPricingResult?.Result == ResultEnum.Err
      ? hotelPriceFreezeRoomWithPricingResult.err
      : undefined;
  }
);

export const isHotelPriceFreezeLodgingNotAvailableSelector = createSelector(
  hotelPriceFreezeLodgingErrSelector,
  hotelPriceFreezeRoomWithPricingErrSelector,
  fetchHotelPriceFreezeDetailsCallStateSelector,
  (
    lodgingErr,
    roomWithPricingErr,
    fetchHotelPriceFreezeDetailsCallState
  ): boolean => {
    const hasSuccessfullyFetchedHotelPriceFreezeDetails =
      fetchHotelPriceFreezeDetailsCallState === CallState.Success;
    const isRoomPricingNotAvailable =
      !!roomWithPricingErr?.GetMatchingRoomError &&
      [
        GetMatchingRoomErrorEnum.NoLodgingDetailAvailable,
        GetMatchingRoomErrorEnum.LodgingNotAvailable,
        GetMatchingRoomErrorEnum.NoMatchingRoomForVoucher,
      ].includes(roomWithPricingErr.GetMatchingRoomError);
    const isLodgingNotAvailable =
      !!lodgingErr?.GetLodgingError &&
      lodgingErr.GetLodgingError === GetLodgingErrorEnum.LodgingNotFound;

    return (
      hasSuccessfullyFetchedHotelPriceFreezeDetails &&
      (isRoomPricingNotAvailable || isLodgingNotAvailable)
    );
  }
);
