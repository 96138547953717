export const CANCEL_INFO_TITLE = "Cancellation Information";
export const LOADING_POLICY = "Loading home details and cancellation policy...";
export const PROCESSING_CANCEL = "Processing cancellation...";
export const DEFAULT_CUSTOMER_SERVICE_CANCEL_TITLE = "This booking is unique";
export const DEFAULT_CUSTOMER_SERVICE_CANCEL_BODY =
  "Unfortunately, this booking cannot be canceled via self-service. To cancel, please call customer support at 844-422-6922.";
export const BACK_TO_TRIPS = "Back to trips";

export const CANCEL_VACATION_RENTAL_TITLE =
  "Cancel your vacation rental reservation";

// Non refundable cases will be handled via customer service even though they can be cancelled via self-service.
export const NON_REFUNDABLE_CANCEL_BODY =
  "<strong>Your booking is non refundable.</strong> To cancel, please call customer support at 844-422-6922.";
