import { Box, Typography } from "@material-ui/core";
import React from "react";
import { Airline, FlightItinerarySlice, SelfServeEvents } from "redmond";

import { trackEvent } from "../../../../../../api/v1/analytics/trackEvent";
import { SEAT_UPDATE_INSTR } from "../FlightCard/constants";
import { getSliceSeatsLinks } from "../FlightCard/helpers";

import "./styles.scss";

export interface ISeatUpdateInstructionsProps {
  airlineMap: { [key: string]: Airline };
  slice: FlightItinerarySlice;
}

const defaultProps: Partial<ISeatUpdateInstructionsProps> = {};

const SeatUpdateInstructions = (props: ISeatUpdateInstructionsProps) => {
  const { airlineMap, slice } = props;
  const seatLinks = getSliceSeatsLinks(airlineMap, slice);

  const sendSeatsEvent = (airlineName: string, airlineLink: string) => {
    trackEvent({
      eventName: SelfServeEvents.ClickSeatsLink,
      properties: {
        airlineName,
        airlineLink
      }
    });
  };

  return (
    <Typography className="seat-update-instructions" variant="body2">
      {SEAT_UPDATE_INSTR.PREFIX}
      {Object.keys(seatLinks).map((airlineName, idx) => {
        const link = seatLinks[airlineName];
        const linkText = `${airlineName}${
          airlineName.endsWith("s") ? "'" : "'s"
        } website`;

        return (
          <Box className="link-container" component="span" key={linkText}>
            {idx !== 0 && <span>{SEAT_UPDATE_INSTR.SEPARATOR}</span>}
            {link ? (
              <a
                className="action-link"
                href={link}
                onClick={() => sendSeatsEvent(airlineName, link)}
                target="_blank"
              >
                {linkText}
              </a>
            ) : (
              <span className="action-link">{linkText}</span>
            )}
          </Box>
        )
      })}
      {SEAT_UPDATE_INSTR.SUFFIX}
    </Typography>
  );
};

SeatUpdateInstructions.defaultProps = defaultProps;

export default SeatUpdateInstructions;
