import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { RefundPriceFreezeModal } from "./component";
import {
  refundHotelPriceFreeze,
  setRefundHotelPriceFreezeCallStateNotCalled,
} from "../../actions/actions";
import {
  refundHotelPriceFreezeCallStateSelector,
  hotelPriceFreezeVoucherSelector,
  hasSuccessfullyRefundedHotelPriceFreezeSelector,
  hotelPriceFreezeRefundOutcomePropertiesSelector,
  hotelPriceFreezeRefundChoicePropertiesSelector,
} from "../../reducer";
import { IStoreState } from "../../../../reducers/types";

const mapStateToProps = (state: IStoreState) => {
  return {
    voucher: hotelPriceFreezeVoucherSelector(state),
    refundHotelPriceFreezeCallState:
      refundHotelPriceFreezeCallStateSelector(state),
    hasSuccessfullyRefunded:
      hasSuccessfullyRefundedHotelPriceFreezeSelector(state),
    hotelPriceFreezeRefundOutcomeProperties:
      hotelPriceFreezeRefundOutcomePropertiesSelector(state),
    hotelPriceFreezeRefundChoiceProperties:
      hotelPriceFreezeRefundChoicePropertiesSelector(state),
  };
};

const mapDispatchToProps = {
  refundHotelPriceFreeze,
  setRefundHotelPriceFreezeCallStateNotCalled,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type RefundPriceFreezeModalConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedRefundPriceFreezeModal = connector(
  withRouter(RefundPriceFreezeModal)
);
