export const CANCEL = "Cancel";
export const DONE = "Done";
export const EMAIL_LABEL = "Email";
export const FAILED = "Your confirmation email did not send!";
export const INSTRUCTIONS =
  "Enter the email that you would like us to resend your confirmation to.";
export const RESEND = "Resend";
export const SUCCEEDED = "Your confirmation email has been resent.";
export const TITLE = "Resend confirmation email";
export const TRY_AGAIN = "Try Again";
