import dayjs from "dayjs";
import {
  AirlineCode,
  AirportCode,
  FlightNumber,
  LocalDateTime,
  Amount,
  RewardsPrice,
} from "../common";
import {
  TripAncillaryContracts,
  PolicyDetailsEnum,
  FlightItinerarySegment,
  FlightItinerarySlice,
} from "./itinerary";

///////////////////////
// Get Exercise Eligibility API
///////////////////////

export type GetExerciseEligibilitiesRequestV1 =
  GetExerciseEligibilitiesRequestV1ByItineraryIds;

export interface GetExerciseEligibilitiesRequestV1ByItineraryIds {
  itineraryIds: string[];
  GetExerciseEligibilitiesRequestV1: GetExerciseEligibilitiesRequestV1Enum.ByItineraryIds;
}

export enum GetExerciseEligibilitiesRequestV1Enum {
  ByItineraryIds = "ByItineraryIds",
}

export interface GetExerciseEligibilitiesResponseV1 {
  eligibilityByItinerary: { [key: string]: ExerciseEligibilityV1 };
}

export type ExerciseEligibilityV1 =
  | ExerciseEligibilityV1IsEligible
  | ExerciseEligibilityV1NotEligible;

export enum ExerciseEligibilityV1Enum {
  IsEligible = "IsEligible",
  NotEligible = "NotEligible",
}

export interface ExerciseEligibilityV1IsEligible {
  deadlineTime: string;
  slices: EligibilitySlice[];
  delayMinimumMinutes?: number;
  ExerciseEligibilityV1: ExerciseEligibilityV1Enum.IsEligible;
}

export interface ExerciseEligibilityV1NotEligible {
  deadlineTime: string;
  slices: EligibilitySlice[];
  delayMinimumMinutes?: number;
  ExerciseEligibilityV1: ExerciseEligibilityV1Enum.NotEligible;
}

export type EligibilitySlice =
  | EligibilitySliceIsEligible
  | EligibilitySliceNotEligible;

export interface EligibilitySliceIsEligible {
  segments: EligibilitySegment[];
  EligibilitySlice: EligibilitySliceEnum.IsEligible;
}

export interface EligibilitySliceNotEligible {
  segments: EligibilitySegment[];
  EligibilitySlice: EligibilitySliceEnum.NotEligible;
}

export enum EligibilitySliceEnum {
  IsEligible = "IsEligible",
  NotEligible = "NotEligible",
}

export type EligibilitySegment =
  | EligibilitySegmentIsEligible
  | EligibilitySegmentNotEligible;

export interface EligibilitySegmentIsEligible {
  disruption: FlightDisruption;
  status: FlightStatusResult;
  EligibilitySegment: EligibilitySegmentEnum.IsEligible;
}

export interface EligibilitySegmentNotEligible {
  disruption: FlightDisruption;
  status: FlightStatusResult;
  EligibilitySegment: EligibilitySegmentEnum.NotEligible;
}

export enum EligibilitySegmentEnum {
  IsEligible = "IsEligible",
  NotEligible = "NotEligible",
}

export enum FlightDisruptionEnum {
  NotDisrupted = "NotDisrupted",
  IsDisrupted = "IsDisrupted",
}

export type FlightDisruption =
  | FlightDisruptionNotDisrupted
  | FlightDisruptionIsDisrupted;

export interface FlightDisruptionNotDisrupted {
  FlightDisruption: FlightDisruptionEnum.NotDisrupted;
}

export type FlightDisruptionReason =
  | FlightDisruptedReasonDelayed
  | FlightDisruptedReasonMissedConnecion
  | FlightDisruptionReasonAirlineCancellation;

export enum FlightDisruptionReasonEnum {
  Delayed = "Delayed",
  MissedConnection = "MissedConnection",
  AirlineCancellation = "AirlineCancellation",
}

export interface FlightDisruptedReasonDelayed {
  inMinutes: number;
  delayMinimumMinutes?: number;
  Reason: FlightDisruptionReasonEnum.Delayed;
}

export interface FlightDisruptedReasonMissedConnecion {
  inMinutes: number;
  Reason: FlightDisruptionReasonEnum.MissedConnection;
}

export interface FlightDisruptionReasonAirlineCancellation {
  Reason: FlightDisruptionReasonEnum.AirlineCancellation;
}

export interface FlightDisruptionIsDisrupted {
  reasons: FlightDisruptionReason[];
  FlightDisruption: FlightDisruptionEnum.IsDisrupted;
}

export type FlightStatusResult = FlightStatusIsFound | FlightStatusNotFound;

export enum FlightStatusResultEnum {
  IsFound = "IsFound",
  NotFound = "NotFound",
}

export interface FlightStatusIsFound {
  scheduled: Scheduled;
  revised: Revised;
  // TODO: Add these fields for complete objects if necessary.
  // alert?: Alert;
  // previousAlert?: Alert;
  Result: FlightStatusResultEnum.IsFound;
}

export interface FlightStatusNotFound {
  scheduled: Scheduled;
  Result: FlightStatusResultEnum.NotFound;
}

export interface Scheduled {
  airlineCode: AirlineCode;
  flightNumber: FlightNumber;
  departureAirportCode: AirportCode;
  departureTimeLocal: LocalDateTime;
  arrivalAirportCode: AirportCode;
  arrivalTimeLocal?: LocalDateTime;
}

export interface Revised {
  departureTimeLocal?: LocalDateTime;
  arrivalTimeLocal?: LocalDateTime;
}

///////////////////////
// Prepare Disruption Flight Refund API
///////////////////////

export interface GetDisruptionRefundRequest {
  itineraryId: string;
}

export type GetDisruptionRefundResponse =
  | GetDisruptionRefundResponseSuccess
  | GetDisruptionRefundResponseNoDisruptionDetected
  | GetDisruptionRefundResponseNoDisruptionProtectionAttached;

export interface GetDisruptionRefundResponseSuccess {
  // TODO: Finalize the parameters in Success once BE is ready.
  // contract: Contract;
  rewardsCredit?: RewardsPrice;
  userCardCredit?: Amount;
  totalAmount?: Amount;
  preparedPayment: string;
  GetDisruptionRefundResponse: GetDisruptionRefundResponseEnum.Success;
}

export interface GetDisruptionRefundResponseNoDisruptionDetected {
  GetDisruptionRefundResponse: GetDisruptionRefundResponseEnum.NoDisruptionDetected;
}

export interface GetDisruptionRefundResponseNoDisruptionProtectionAttached {
  GetDisruptionRefundResponse: GetDisruptionRefundResponseEnum.NoDisruptionProtectionAttached;
}

export enum GetDisruptionRefundResponseEnum {
  Success = "Success",
  NoDisruptionProtectionAttached = "NoDisruptionProtectionAttached",
  NoDisruptionDetected = "NoDisruptionDetected",
}

///////////////////////
// Submit Disruption Flight Refund API
///////////////////////

export interface SubmitDisruptionRefundRequest {
  itineraryId: string;
  preparedPayment: string;
}

export interface SubmitDisruptionRefundResponseSuccess {
  SubmitDisruptionRefundResponse: SubmitDisruptionRefundResponseEnum.Success;
}

export interface SubmitDisruptionRefundResponseFailure {
  SubmitDisruptionRefundResponse: SubmitDisruptionRefundResponseEnum.Failure;
}

export type SubmitDisruptionRefundResponse =
  | SubmitDisruptionRefundResponseSuccess
  | SubmitDisruptionRefundResponseFailure;

export enum SubmitDisruptionRefundResponseEnum {
  Success = "Success",
  Failure = "Failure",
}

///////////////////////
// Internal Models
///////////////////////

export interface PreparedRefundInfo {
  rewardsCredit?: RewardsPrice;
  userCardCredit?: Amount;
  totalAmount?: Amount;
  preparedPayment: string;
}

export enum DisruptionFlightRefundResult {
  Success = "Success",
  Failure = "Failure",
}

export const getEligibleSliceFromEligibility = (
  eligibility: ExerciseEligibilityV1IsEligible
): { slice: EligibilitySliceIsEligible; index: 0 | 1 } | undefined => {
  const eligibleSliceIndex = eligibility.slices.findIndex((slice) => {
    switch (slice.EligibilitySlice) {
      case EligibilitySliceEnum.IsEligible:
        return true;
      case EligibilitySliceEnum.NotEligible:
      default:
        return false;
    }
  });

  if (eligibleSliceIndex === 0 || eligibleSliceIndex === 1) {
    return {
      // note: there is no need to add any safeguard here since the index is returned through findIndex
      slice: eligibility.slices[
        eligibleSliceIndex
      ] as EligibilitySliceIsEligible,
      index: eligibleSliceIndex,
    };
  }
  return undefined;
};

export const getDisruptionProductTypeFromAncillaries = (
  ancillaries: TripAncillaryContracts | undefined
) => {
  if (ancillaries) {
    const { delay, missedConnection } = ancillaries;
    return !!delay
      ? "delays"
      : !!missedConnection
      ? "missed_connections"
      : undefined;
  }

  return undefined;
};

export const getCoverageAndPremiumFromDisruptionAncillaries = (
  ancillaries: TripAncillaryContracts
): { coverage: Amount | undefined; premium: Amount } | undefined => {
  const delayPolicyDetails = ancillaries.delay?.policyDetails;
  const delayCoverageAndPremium = delayPolicyDetails
    ? {
        coverage: delayPolicyDetails.coverage,
        premium: delayPolicyDetails.premium,
      }
    : undefined;
  const missedConnectionPolicyDetails =
    ancillaries.missedConnection?.policyDetails;
  const missedConnectionCoverageAndPremium = (() => {
    switch (missedConnectionPolicyDetails?.PolicyDetails) {
      case PolicyDetailsEnum.FixedPolicyDetails:
        return {
          coverage: missedConnectionPolicyDetails.coverage,
          premium: missedConnectionPolicyDetails.premium,
        };
      /*
        note: the percentage model is not used in our BE workflow; in case we do receive a percentage response,
        this will make it return undefined rather than potentially displaying an incorrect value
      */
      case PolicyDetailsEnum.PercentagePolicyDetails:
      default:
        return undefined;
    }
  })();

  return delayCoverageAndPremium ?? missedConnectionCoverageAndPremium;
};

/*
  note: this helper is used to check weither it has passed the time window for rebooking the departure flight.
  This logic is currently relevant only in the agent portal workflow.
*/
export const hasPassedTheTimeWindowToRebookTheDepartureFlight = (
  lastDepartureSegment: FlightItinerarySegment
) =>
  dayjs().isAfter(
    /*
      note: The agent should be able to rebook the outbound flight until 24 hours after the scheduled arrival time
      and then it should switch to the return flight; see https://hopper-jira.atlassian.net/browse/BF-1270
    */
    dayjs(
      lastDepartureSegment.zonedScheduledArrival ??
        lastDepartureSegment.scheduledArrival
    ).add(24, "hours")
  );

export const getIsSelectingReturnFlightForRebook = ({
  eligibility,
  departureSlice,
  returnSlice,
}: {
  eligibility?: ExerciseEligibilityV1;
  departureSlice?: FlightItinerarySlice;
  returnSlice?: FlightItinerarySlice;
}): boolean => {
  const eligibleSliceIndex = eligibility?.slices.findIndex(
    (slice) => slice.EligibilitySlice === EligibilitySliceEnum.IsEligible
  );
  const firstReturnSegment = returnSlice?.segments[0];
  const lastDepartureSegment =
    departureSlice?.segments[departureSlice.segments.length - 1];

  if (eligibleSliceIndex === 0) {
    return false;
  } else if (eligibleSliceIndex === 1) {
    return true;
  } else {
    // We should only get here if we are in agent portal.
    // Even if there's no eligible slice, we still want agents to be able to exercise DP.
    // We display "return" in the flow if return flight exists and certain amount of time
    // has passed since departure flight.
    return (
      !!firstReturnSegment &&
      !!lastDepartureSegment &&
      hasPassedTheTimeWindowToRebookTheDepartureFlight(lastDepartureSegment)
    );
  }
};
