export const cancelTitles = Object.freeze({
  AIRLINE_MANAGED: "Please Contact Airline",
  CANCEL_FAILED: "Cancellation Failed",
  CANCEL_INFO: "Cancellation Information",
  CANCEL_REQUESTED: "Cancellation Requested",
  CANT_SELF_SERVICE:
    "Unfortunately, this type of fare cannot be cancelled via self-service. To cancel, please contact support",
  CONTACT_CUSTOMER_SUPPORT: "Please contact customer support",
  CONFIRMATION: "Confirm Cancellation",
  DEPARTED_TITLE: "This flight has departed",
  DEPARTED_SUBTITLE: "Please contact the airline for further assistance.",
  GET_INFO_ERROR: "Error while loading flight's cancellation info",
  LOADING: "Loading flight details and cancellation policy...",
  LOAD_FAILED: "We couldn't load your flight cancel information",
  PROCESSING: "Your cancellation is being processed...",
  PENDING_CANCELLATION: "We're processing your confirmation",
  BOOKING_CANCELLED:
    "Your booking has already been cancelled. A confirmation email will be sent and My Trips will be updated within 1-2 hours.",
  PROCESSING_SUBTITLE: "Please do not exit until the cancellation is complete.",
  SABRE_CONTACT_AIRLINE:
    "To cancel and claim your refund, you must contact the airline.",
  TRVK_OUTAGE_SUBTITLE:
    "We're experiencing technical difficulties, if your request is urgent please contact support at 844-422-6922.",
  TRVK_OUTAGE_TITLE: "Please try again in a few minutes",
  TRY_AGAIN: "Please try again or contact support",
  COMPLETE_CANCELLATION_FLIGHT:
    "Complete the cancellation of your flight for all travelers.",
});

export const TF = "travelfusion";
export const TF_PREFIX = "Z-";
export const SABRE = "sabre";
export const AMADEUS = "amadeus";
export const AMADEUS_PREFIX = "M-";

export const buttonText = {
  CANCEL: "Cancel Flight",
  REFRESH_POLICY: "Refresh Policy",
  CANCEL_ALL: "Cancel All Flights",
  CLOSE: "Close",
  CONFIRM_CANCEL: "Confirm Cancellation",
  CONTACT_AIRLINE: "Continue to the airline's website",
  CONTACT_SUPPORT: "Contact Support",
  DONE: "Done",
  GET_INFO: "Get Cancellation Info",
  TRY_AGAIN: "Try Again",
};
