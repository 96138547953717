import dayjs from "dayjs";
import React from "react";
import {
  Airport,
  BookedFlightItineraryWithDepartureTime,
  FlightItinerarySegment,
  getDepartureSlice,
  getReturnSlice,
  getSlicesFromTravelItinerary,
  HotelItinerary,
  PaymentBreakdown,
  PaymentLineItemEnum,
  PaymentLineItemRewards,
  PaymentLineItemUserCard,
  TravelItineraryEnum,
} from "redmond";
import { ILabel, IValues } from "../FlightCard/helpers";
import {
  formatFiatValue,
  formatRewardsValue,
} from "../../../../../../utils/helpers";
import { last } from "lodash-es";

export const CHECK_IN = "Check in";
export const CHECK_IN_ON = "Check in on";
export const CHECK_OUT = "Check out";
export const CHECKED_IN = "Checked in on";
export const CONFIRMATION = "Confirmation:";
export const PACKAGE_HEADER_TEXT = "Package";
export const VIEW_DETAILS = "View details";
export const VIEW_LESS = "View less";
export const DATE_FORMAT = "ddd, MMM D";
export const CANCELED = "Canceled";
export const CANCELED_RES = "This reservation has been canceled.";

export const CANCEL_FOR_ANY_REASON = "Cancel for Any Reason";

export const getHeaderText = (checkInDate?: string, checkOutDate?: string) => {
  return checkInDate ? (
    <>
      <strong>{PACKAGE_HEADER_TEXT}</strong>{" "}
      <span className="separator">|</span>{" "}
      {dayjs(checkInDate).format(DATE_FORMAT)} -{" "}
      {dayjs(checkOutDate).format(DATE_FORMAT)}
    </>
  ) : (
    <strong>{PACKAGE_HEADER_TEXT}</strong>
  );
};

export const getHotelInfoDetails = (
  { reservation }: HotelItinerary,
  nameAsTitle?: boolean
) => {
  const { lodgingData } = reservation;
  return {
    title: nameAsTitle
      ? lodgingData?.name
      : lodgingData
      ? `${lodgingData.city}, ${
          lodgingData.state?.code?.trim() || lodgingData.country
        }`
      : "",
    confirmationCode: `H-${reservation.reservationId}`,
    startDate: dayjs(reservation.checkInDate).format(DATE_FORMAT),
    endDate: dayjs(reservation.checkOutDate).format(DATE_FORMAT),
    subtitle: `${lodgingData?.city ? `${lodgingData?.city}, ` : ""}${
      lodgingData?.country || ""
    }`,
  };
};

export const DEPARTURE = "Departure";
export const RETURN = "Return";
export const CANCEL_FLIGHT = "Cancel Flight";
export const RESEND_CONFIRMATION = "Resend Confirmation";
export const OUTBOUND = "Outbound";

export const getItineraryDetailsHeader = (
  flight: BookedFlightItineraryWithDepartureTime,
  isOutgoing: boolean,
  airportMap: { [key: string]: Airport },
  multicitySliceIndex?: number
) => {
  const isMultiCity = multicitySliceIndex !== undefined;

  let tripSlice;
  if (isMultiCity) {
    const slices = getSlicesFromTravelItinerary(
      flight.bookedItinerary.travelItinerary
    );
    tripSlice = slices[multicitySliceIndex];
  } else {
    tripSlice = isOutgoing
      ? getDepartureSlice(flight.bookedItinerary)
      : getReturnSlice(flight.bookedItinerary);
  }

  const lastIndexOfSegments = tripSlice!.segments.length - 1;
  return ` to ${
    airportMap[
      tripSlice!.segments[lastIndexOfSegments].destination.locationCode
    ]?.cityName
  } ${isMultiCity ? "-" : ""}(${
    tripSlice!.segments[lastIndexOfSegments].destination.locationCode
  }) on ${dayjs(tripSlice!.segments[0]?.scheduledDeparture).format(
    DATE_FORMAT
  )}`;
};
export const FARE_DETAILS = "Fare Details";
export const SEAT_SELECTION = "Seat Selection";
export const SEAT_UPDATE_INSTR = {
  PREFIX:
    "As we cannot add or modify seat assignments once a booking has been made, please visit",
  SEPARATOR: "and",
  SUFFIX: "if you would like to do so.",
};
export const MAJOR_SCHEDULE_CHANGE_CTA_LABEL = "View schedule change";

export const getLabelsFromPaymentBreakdown = (breakdown: PaymentBreakdown) => {
  const labels: ILabel = {
    cardLabel: null,
    rewardLabel: null,
  };
  breakdown.payments.forEach((payment) => {
    switch (payment.PaymentLineItem) {
      case PaymentLineItemEnum.UserCard:
        labels.cardLabel = (
          payment as PaymentLineItemUserCard
        ).cardNumberDisplay;
        break;
      case PaymentLineItemEnum.Rewards:
        labels.rewardLabel = (
          payment as PaymentLineItemRewards
        ).accountDisplayName;
        break;
    }
  });
  return labels;
};

export const getValues = (breakdown: PaymentBreakdown) => {
  const values: IValues = {
    cardValue: null,
    rewardValue: null,
  };
  breakdown.payments.forEach((payment) => {
    switch (payment.PaymentLineItem) {
      case PaymentLineItemEnum.UserCard:
        const cardAmt = (payment as PaymentLineItemUserCard).amount;
        values.cardValue = `${cardAmt.currency} $${formatFiatValue(
          cardAmt.amount
        )}`;
        break;

      case PaymentLineItemEnum.Rewards:
        const rewardAmt = payment as PaymentLineItemRewards;
        values.rewardValue = `${formatRewardsValue(
          rewardAmt.amount.rewardsPrice.value
        )} ${rewardAmt.amount.rewardsPrice.currency}`;
        break;
    }
  });
  return values;
};

export const getFlightHeader = (
  flight: BookedFlightItineraryWithDepartureTime
) => {
  const { travelItinerary } = flight.bookedItinerary;
  let outboundSegment: FlightItinerarySegment;
  let returnSegment: FlightItinerarySegment;

  switch (travelItinerary.TravelItinerary) {
    case TravelItineraryEnum.SingleTravelItinerary: {
      const firstSlice = travelItinerary.slices[0];
      outboundSegment = firstSlice.segments[0];
      returnSegment = last(firstSlice.segments) ?? outboundSegment;
      break;
    }
    case TravelItineraryEnum.MultiTravelItinerary: {
      const firstSlice = travelItinerary.travelItineraries[0].slices[0];
      outboundSegment = firstSlice.segments[0];
      returnSegment = last(firstSlice.segments) ?? outboundSegment;
      break;
    }
    default:
      return "";
  }

  return `${outboundSegment.origin.locationCode} to ${returnSegment.destination.locationCode}`;
};

export const ROUND_TRIP = "Round-trip";
export const ONE_WAY = "One way";
