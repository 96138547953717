import React from "react";
import { HotelItinerary, VIEWED_CONTACT_MODAL } from "redmond";
import { ActionButton } from "halifax";
import { Box, Typography } from "@material-ui/core";
import { ChangeHotelModalContentConnectorProps } from "./container";
import * as constants from "./constants";
import "./styles.scss";
import dayjs from "dayjs";
import clsx from "clsx";
import { trackEvent } from "../../../../../../../../api/v1/analytics/trackEvent";
import { useEffect } from "react";
import { getViewedHotelContactModalProperties } from "../../../../../../reducer";
import { CONTACT_SUPPORT_URL } from "../../../../../../../../utils/paths";

export interface IChangeHotelModalContentProps
  extends ChangeHotelModalContentConnectorProps {
  hotel: HotelItinerary;
}

export const ChangeHotelModalContent = (
  props: IChangeHotelModalContentProps
) => {
  const { setOpenModal, hotel } = props;
  const agentCanChange: boolean = dayjs(hotel.reservation.checkInDate).isAfter(
    dayjs()
  );

  useEffect(() => {
    if (hotel) {
      trackEvent({
        eventName: VIEWED_CONTACT_MODAL,
        properties: {
          ...getViewedHotelContactModalProperties(hotel, "change_hotel"),
        },
      });
    }
  }, [hotel]);

  return (
    <Box className="change-hotel-modal-content">
      <Typography className="change-hotel-title" variant="h2">
        {constants.HEADING}
      </Typography>
      <Typography className="change-hotel-subtitle" variant="h5">
        {agentCanChange ? constants.SUBHEADING_AGENT : constants.SUBHEADING}
      </Typography>
      <Box className="change-hotel-buttons">
        {agentCanChange && (
          <ActionButton
            className="b2b"
            defaultStyle="h4r-primary"
            message={constants.CONTACT_SUPPORT}
            onClick={() => {
              window.open(CONTACT_SUPPORT_URL, "_blank")?.focus();
            }}
          />
        )}
        <ActionButton
          className={clsx({ b2b: !agentCanChange })}
          defaultStyle={agentCanChange ? "h4r-secondary" : "h4r-primary"}
          message={agentCanChange ? constants.CANCEL : constants.DONE}
          onClick={() => setOpenModal({ type: null, selectedItinerary: null })}
        />
      </Box>
    </Box>
  );
};
