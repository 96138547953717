import React from "react";
import { Box, Typography } from "@material-ui/core";
import { HotelItinerary } from "redmond";
import { Icon, IconName } from "halifax";

import "./styles.scss";
import * as textConstants from "./constants";
import clsx from "clsx";

interface ICfarItinerarySummary {
  hotel: HotelItinerary;
  className?: string;
}

export const CfarItinerarySummary = ({
  hotel,
  className,
}: ICfarItinerarySummary) => {
  if (!hotel) return null; // no summary if no hotel

  const reservationItems = [
    {
      title: textConstants.CHECK_IN,
      date: textConstants.getFormattedDate(hotel.reservation.checkInDate),
    },
    {
      title: textConstants.CHECK_OUT,
      date: textConstants.getFormattedDate(hotel.reservation.checkOutDate),
    },
  ];
  return (
    <Box className={clsx("cfar-reservation-summary-root", className)}>
      <Box className="title-section">
        <Typography variant="body1" className="reservation-summary-header">
          {hotel.reservation.lodgingData.name}
        </Typography>
        <Typography variant="body2" className="reservation-summary-subtitle">
          {textConstants.getFormattedAddress(
            hotel.reservation.lodgingData.address
          )}
        </Typography>
      </Box>
      <Box className="reservation-summary-details-section">
        {reservationItems.map((reservationItem) => {
          return (
            <Box
              key={`hotel_${reservationItem.title}`}
              className={clsx("reservation-item")}
            >
              <Box className="icon-section">
                <Icon
                  name={IconName.Calendar}
                  className={clsx("cfar-calendar-icon")}
                />
              </Box>
              <Box>
                <Typography
                  key={`title_${reservationItem.title}`}
                  variant="caption"
                >
                  {reservationItem.title}
                </Typography>
                <Typography
                  key={`date_${reservationItem.title}`}
                  variant="body1"
                >
                  {reservationItem.date}
                </Typography>
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};
