import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { IStoreState } from "../../reducers/types";
import { isHotelPriceFreezeLodgingNotAvailableSelector } from "./reducer";
import { setRefundHotelPriceFreezeCallStateNotCalled } from "./actions/actions";
import { PriceFreeze } from "./component";

const mapStateToProps = (state: IStoreState) => {
  return {
    isHotelPriceFreezeLodgingNotAvailable:
      isHotelPriceFreezeLodgingNotAvailableSelector(state),
  };
};

const mapDispatchToProps = {
  setRefundHotelPriceFreezeCallStateNotCalled,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PriceFreezeConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedPriceFreeze = connector(withRouter(PriceFreeze));
