import axios from "axios";
import { CancelScenario, CancellationInfoRequest } from "redmond";

import { config } from "../../config";
import { getHotelCancellationInfoPath } from "../paths";

/**
 * @description The first of two endpoints in the self serve cancel hotels flow
 * @param {CancellationInfoRequest} req Contains the reservation id
 * @return {CancelScenario} The cancellation info for the reservation passed in
 */
const getHotelCancellationInfo = (
  req: CancellationInfoRequest
): Promise<CancelScenario> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await axios.post(getHotelCancellationInfoPath, req, {
        baseURL: config.baseURL,
      });

      resolve(res.data.value);
    } catch (e) {
      reject(e);
    }
  });

export default getHotelCancellationInfo;
