import axios from "axios";
import { DenyScheduleChangeRequest, DenyScheduleChangeResponse } from "redmond";
import { airBookingApi } from "@b2bportal/air-booking-api";

export const denyScheduleChange = async (
  req: DenyScheduleChangeRequest
): Promise<DenyScheduleChangeResponse> => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await airBookingApi(
        axios
      ).apiV0ItineraryFlightDenyScheduleChangePost(req);

      resolve(res.data);
    } catch (e) {
      reject(e);
    }
  });
};
