import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { IStoreState } from "../../../../reducers/types";
import {
  populateTripQueryParams,
  setSelectedFlight,
  setSelectedHotel,
  setSelectedCar,
  fetchInitialUpcomingFlightCrossSell,
  setSelectedHome,
  fetchActiveHotelCrossSell,
  fetchMoreUpcomingFlightCrossSell,
  setSelectedPackageHotel,
  setSelectedPackageFlight,
  setSelectedPackage,
} from "../../actions/actions";
import ItineraryList from "./component";
import {
  getAirlinesMap,
  getAirportMap,
  itinerariesToDisplaySelector,
  getSelectedFlight,
  getSelectedHotel,
  getSelectedCar,
  hasFetchTripsError,
  getTripsFilter,
  getTripId,
  getFetchingFutureFlightSucceded,
  getFetchingFutureHotelsSucceded,
  getAvailableCrossSellHotels,
  getSelectedHome,
  getTripSearchQuery,
  getFirstCrossellOffer,
  getFetchHotelCrossSellCallState,
  getHotelAvailabilityNextPageToken,
  getSelectedPackageHotel,
  getSelectedPackageFlight,
} from "../../reducer";

const mapStateToProps = (state: IStoreState) => ({
  airlineMap: getAirlinesMap(state),
  airportMap: getAirportMap(state),
  selectedFlight: getSelectedFlight(state),
  selectedHotel: getSelectedHotel(state),
  selectedHome: getSelectedHome(state),
  selectedCar: getSelectedCar(state),
  selectedPackageHotel: getSelectedPackageHotel(state),
  selectedPackageFlight: getSelectedPackageFlight(state),
  tripsFilter: getTripsFilter(state),
  selectedTripId: getTripId(state),
  itinerariesToDisplay: itinerariesToDisplaySelector(state),
  hasError: hasFetchTripsError(state),
  flightFutureCallState: getFetchingFutureFlightSucceded(state),
  hotelFutureCallState: getFetchingFutureHotelsSucceded(state),
  lodgings: getAvailableCrossSellHotels(state),
  tripSearchQuery: getTripSearchQuery(state),
  firstCrossSellOffer: getFirstCrossellOffer(state),
  crossSellOfferCallState: getFetchHotelCrossSellCallState(state),
  nextPageToken: getHotelAvailabilityNextPageToken(state),
});

const mapDispatchToProps = {
  populateTripQueryParams,
  setSelectedFlight,
  setSelectedHotel,
  setSelectedCar,
  setSelectedHome,
  setSelectedPackage,
  setSelectedPackageHotel,
  setSelectedPackageFlight,
  fetchInitialUpcomingFlightCrossSell,
  fetchMoreUpcomingFlightCrossSell,
  fetchActiveHotelCrossSell,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type ItineraryListConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedItineraryList = connector(withRouter(ItineraryList));
