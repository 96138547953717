import { select, putResolve } from "redux-saga/effects";
import { MyTripsFilter } from "redmond";

import { IStoreState } from "../../../reducers/types";
import {
  getExistingStateVariables,
  parseQueryString,
} from "./populateTripQueryParamsSaga";
import { actions } from "../actions";

export interface ITripsParsedQuery {
  tripsFilter: string;
  tripId: string;
}

const isQueryParamOfTypeMyTripsFilter = (queryParamsTripFilter: string) =>
  [
    MyTripsFilter.PAST_TRIPS,
    MyTripsFilter.PRIZE_FREEZES,
    MyTripsFilter.UPCOMING_TRIPS,
    MyTripsFilter.TRAVEL_CREDITS,
    MyTripsFilter.WATCHED_TRIPS,
  ].includes(queryParamsTripFilter as MyTripsFilter);

export function* setUpMyTripsParams(
  action:
    | actions.IFetchHotels
    | actions.IFetchFlights
    | actions.IFetchCars
    | actions.IFetchHomes
) {
  const state: IStoreState = yield select();
  const { history } = action;

  const { tripsFilter, tripId } = getExistingStateVariables(state);

  const {
    tripId: queryParamsTripId,
    tripsFilter: queryParamsTripFilter,
  }: ITripsParsedQuery = parseQueryString(history);

  if (queryParamsTripFilter && queryParamsTripFilter !== tripsFilter) {
    if (isQueryParamOfTypeMyTripsFilter(queryParamsTripFilter)) {
      yield putResolve(
        actions.setTripsFilter(queryParamsTripFilter as MyTripsFilter)
      );
    } else {
      yield putResolve(actions.populateTripQueryParams(history));
    }
  }

  return {
    tripsFilter: queryParamsTripFilter || tripsFilter,
    tripId: queryParamsTripId || tripId,
  };
}
