import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../../../../../reducers/types";
import { getAirportMap, getAirlinesMap } from "../../../../reducer";
import { FlightCard } from "./FlightCard";

const mapStateToProps = (state: IStoreState) => {
  return {
    airportMap: getAirportMap(state),
    airlineMap: getAirlinesMap(state),
  };
};
const connector = connect(mapStateToProps);

export type FlightCardConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedFlightCard = connector(FlightCard);
