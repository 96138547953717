import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../../../reducers/types";
import {
  setTripsFilter,
  setSelectedCar,
  setSelectedFlight,
  setSelectedHotel,
} from "../../actions/actions";
import { getTripsFilter, getTripsCounts } from "../../reducer";
import { MobileFilterDropdown } from "./component";

const mapStateToProps = (state: IStoreState) => ({
  tripsFilter: getTripsFilter(state),
  tripsCounts: getTripsCounts(state),
});

const mapDispatchToProps = {
  setTripsFilter,
  setSelectedCar,
  setSelectedFlight,
  setSelectedHotel,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IMobileFilterDropdownConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedMobileFilterDropdown = connector(MobileFilterDropdown);
