import React, { useEffect, useState, useContext } from "react";
import { RouteComponentProps } from "react-router-dom";
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { ActionButton, useDeviceTypes, useFocusRef } from "halifax";
import clsx from "clsx";

import { DisruptionProtectionRefundConnectorProps } from "./container";
import * as constants from "./constants";
import "./styles.scss";
import { FlightSliceCard } from "../../../common/FlightSliceCard";
import {
  ExerciseEligibilityV1Enum,
  getRestrictionInfo,
  SingleTravelItinerary,
  SliceDirection,
  VIEWED_REFUND_CHOICE,
  CONFIRM_REFUND_CHOICE,
  Cap1DpExerciseFactsProperties,
} from "redmond";
import dayjs from "dayjs";
import dayjsDuration from "dayjs/plugin/duration";
import { FlightSliceDetailsPopover } from "../../../common/FlightSliceDetailsPopover";
import { refundPricesCombinationToString } from "./utils";
import {
  getSliceBasicInfo,
  getIsSelectingReturnFromTravelItinerary,
} from "../../../../utils/helpers";
import { isTFBooking, isViMultiTicket } from "../../../TripsList/utils";
import { pushToDisruptionOverview } from "../../../../utils/queryStringHelpers";
import { trackEvent } from "../../../../api/v1/analytics/trackEvent";
import { ClientContext } from "../../../../App";
import {
  EligibilitySliceEnum,
  SubmitDisruptionRefundRequest,
} from "redmond/trips-module/flightDisruption";
import { DisruptionProtectionRefundStatusPopup } from "../DisruptionProtectionRefundStatusPopup";

dayjs.extend(dayjsDuration);

export interface IDisruptionProtectionRefundProps
  extends DisruptionProtectionRefundConnectorProps,
    RouteComponentProps {}

export const DisruptionProtectionRefund = (
  props: IDisruptionProtectionRefundProps
) => {
  const {
    disruptedItinerary,
    prepareRefundInfo,
    flightDisruptions,
    airlineMap,
    airportMap,
    areSomeDisruptionInfoFetchingsInProcess,
    eligibilityDpExerciseProductRedeemChoice,
    disruptionOverviewEligibilityDpExerciseFactsProperties,
    submitDisruptionFlightRefund,
    history,
  } = props;
  const { matchesMobile } = useDeviceTypes();
  const [isFlightSliceDetailsPopoverOpen, setIsFlightSliceDetailsPopoverOpen] =
    useState(false);
  const clientContext = useContext(ClientContext);

  const initialFocusRef = useFocusRef([]);

  const { isAgentPortal } = clientContext;


  useEffect(() => {
    if (!disruptedItinerary?.bookedItinerary.id) {
      return;
    }

    if (areSomeDisruptionInfoFetchingsInProcess) {
      return;
    }

    const itineraryId = disruptedItinerary?.bookedItinerary.id;
    const isViFlight = isViMultiTicket(disruptedItinerary.bookedItinerary.multiTicketType);

    if (
      !isAgentPortal &&
      (!(
        flightDisruptions?.eligibilityByItinerary?.[itineraryId]
          ?.ExerciseEligibilityV1 === ExerciseEligibilityV1Enum.IsEligible
      ) || isViFlight)
    ) {
      pushToDisruptionOverview({
        history,
        itineraryId,
      });
    }
  }, [
    disruptedItinerary,
    flightDisruptions,
    prepareRefundInfo,
    areSomeDisruptionInfoFetchingsInProcess,
  ]);

  useEffect(() => {
    if (
      disruptionOverviewEligibilityDpExerciseFactsProperties &&
      eligibilityDpExerciseProductRedeemChoice &&
      prepareRefundInfo
    ) {
      trackEvent({
        eventName: VIEWED_REFUND_CHOICE,
        properties: {
          active_disruption:
            disruptionOverviewEligibilityDpExerciseFactsProperties?.active_disruption,
          product_redeem_choice:
            eligibilityDpExerciseProductRedeemChoice?.product_redeem_choice,
          disruption_refund_costs: prepareRefundInfo.totalAmount?.amount,
        } as Cap1DpExerciseFactsProperties,
      });
    }
  }, [
    disruptionOverviewEligibilityDpExerciseFactsProperties,
    eligibilityDpExerciseProductRedeemChoice,
    prepareRefundInfo,
  ]);

  if (!disruptedItinerary) {
    return null;
  }

  const itinerary = disruptedItinerary.bookedItinerary
    .travelItinerary as SingleTravelItinerary;

  const itineraryId = disruptedItinerary.bookedItinerary.id;

  const eligibility =
    flightDisruptions.eligibilityByItinerary &&
    flightDisruptions.eligibilityByItinerary[itineraryId];

  if (!eligibility && !isAgentPortal) {
    return null;
  }

  const disruptedSliceIndex = (() => {
    let sliceIndex = flightDisruptions.eligibilityByItinerary[
      itineraryId
    ].slices.findIndex(
      (s) => s.EligibilitySlice === EligibilitySliceEnum.IsEligible
    );

    // note: the agent should be able to refund even if there is no disruption detected
    if (sliceIndex === -1 && isAgentPortal) {
      const isSelectingReturn = getIsSelectingReturnFromTravelItinerary(
        disruptedItinerary.bookedItinerary.travelItinerary
      );
      sliceIndex = isSelectingReturn ? 1 : 0;
    }

    return sliceIndex;
  })();

  if (disruptedSliceIndex === -1) {
    return null;
  }

  // BF-1552 Support properties for multi-city flights once needed.
  // Instead of just passing along SliceDirection, we will likely have to pass along
  // both the slice index and whether the flight is multi-city to render correct messages.
  const disruptedFlightSliceDirection =
    disruptedSliceIndex === 0 ? SliceDirection.Outbound : SliceDirection.Return;

  const slice =
    disruptedFlightSliceDirection === SliceDirection.Outbound
      ? itinerary.slices[0]
      : itinerary.slices[1];

  const {
    destination,
    date,
    timeInterval,
    duration,
    stopCount,
    fareShelfBrandName,
    marketingAirlines,
  } = getSliceBasicInfo(slice, airportMap, airlineMap, "h:mm A");

  const button = {
    desktopText: constants.FLIGHT_SLICE_CARD_BUTTON_TEXT_VIEW_DETAILS,
    onClick: () => {
      setIsFlightSliceDetailsPopoverOpen(true);
    },
  };

  const disruptedFlightRefundAmount = {
    rewardsCredit: prepareRefundInfo?.rewardsCredit,
    userCardCredit: prepareRefundInfo?.userCardCredit,
  };

  const refundAmount = refundPricesCombinationToString(
    disruptedFlightRefundAmount
  );

  const pageSubtitle = constants.PAGE_SUBTITLE(refundAmount);

  const isTF = isTFBooking(itinerary);

  const restrictions = getRestrictionInfo(disruptedItinerary, isTF, slice, slice.fareShelf?.brandName);

  return (
    <>
      <Box
        className={clsx("disruption-protection-refund-root", {
          mobile: matchesMobile,
        })}
      >
        <Box className="disruption-protection-refund-container">
          <Box className="header-section">
            <Typography
              tabIndex={0}
              innerRef={initialFocusRef}
              className="title-copy"
              variant="h2"
            >
              {constants.PAGE_TITLE}
            </Typography>
            <Typography
              className="subtitle-copy"
              variant="subtitle2"
              dangerouslySetInnerHTML={{ __html: pageSubtitle }}
            />
          </Box>
          <Box className="flight-section">
            <FlightSliceCard
              sliceDirection={disruptedFlightSliceDirection}
              destination={destination}
              date={date}
              timeInterval={timeInterval}
              duration={duration}
              stopCount={stopCount}
              marketingAirlines={marketingAirlines}
              fareShelfBrandName={fareShelfBrandName}
              button={button}
            />
            <FlightSliceDetailsPopover
              slice={slice}
              sliceDirection={disruptedFlightSliceDirection}
              restrictions={restrictions}
              airportMap={airportMap}
              airlineMap={airlineMap}
              isMixedCabinClass={false}
              destination={destination}
              date={date}
              isOpen={isFlightSliceDetailsPopoverOpen}
              onClose={() => setIsFlightSliceDetailsPopoverOpen(false)}
            />
          </Box>
          <Box className="information-section">
            <Typography className="title-copy" variant="h3">
              {constants.IMPORTANT_INFORMATION_TITLE}
            </Typography>
            <List className="information-list" disablePadding>
              {constants.IMPORTANT_INFORMATION_TEXTS.map((line, index) => (
                <ListItem
                  key={index}
                  className="information-list-item"
                  disableGutters
                >
                  <ListItemIcon className="information-list-item-index">
                    {constants.IMPORTANT_INFORMATION_TEXT_BULLETS[index]}
                  </ListItemIcon>
                  <ListItemText
                    className="information-list-item-text"
                    primary={
                      <span dangerouslySetInnerHTML={{ __html: line }}></span>
                    }
                  />
                </ListItem>
              ))}
            </List>
            <ActionButton
              className="confirm-refund-button"
              defaultStyle="h4r-primary"
              message={constants.CONFIRM_REFUND_BUTTON_LABEL}
              onClick={() => {
                trackEvent({
                  eventName: CONFIRM_REFUND_CHOICE,
                  properties: {
                    active_disruption:
                      disruptionOverviewEligibilityDpExerciseFactsProperties?.active_disruption,
                    disruption_product:
                      disruptionOverviewEligibilityDpExerciseFactsProperties?.disruption_product,
                    product_redeem_choice:
                      eligibilityDpExerciseProductRedeemChoice?.product_redeem_choice,
                    disruption_refund_costs:
                      prepareRefundInfo?.totalAmount?.amount,
                  } as Cap1DpExerciseFactsProperties,
                });
                submitDisruptionFlightRefund({
                  itineraryId,
                  preparedPayment: prepareRefundInfo?.preparedPayment,
                } as SubmitDisruptionRefundRequest);
              }}
            />
          </Box>
        </Box>
      </Box>
      <DisruptionProtectionRefundStatusPopup />
    </>
  );
};
