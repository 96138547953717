export const SET_TRIPS_LIST_EXPERIMENTS =
  "tripsList/SET_TRIPS_LIST_EXPERIMENTS";
export type SET_TRIPS_LIST_EXPERIMENTS = typeof SET_TRIPS_LIST_EXPERIMENTS;

export const SET_MY_TRIPS_TYPE = "tripsList/SET_MY_TRIPS_TYPE";
export type SET_MY_TRIPS_TYPE = typeof SET_MY_TRIPS_TYPE;

export const SET_MY_TRIPS_FILTER = "tripsList/SET_MY_TRIPS_FILTER";
export type SET_MY_TRIPS_FILTER = typeof SET_MY_TRIPS_FILTER;

export const SET_SELECTED_FLIGHT = "tripsList/SET_SELECTED_FLIGHT";
export type SET_SELECTED_FLIGHT = typeof SET_SELECTED_FLIGHT;

export const FETCH_FLIGHTS = "tripsList/FETCH_FLIGHTS";
export type FETCH_FLIGHTS = typeof FETCH_FLIGHTS;

export const SET_FLIGHTS = "tripsList/SET_FLIGHTS";
export type SET_FLIGHTS = typeof SET_FLIGHTS;

export const FETCH_FLIGHTS_FAILED = "tripsList/FETCH_FLIGHTS_FAILED";
export type FETCH_FLIGHTS_FAILED = typeof FETCH_FLIGHTS_FAILED;

export const SET_MY_TRIPS_POSTBOOKING_OFFERS =
  "tripsList/SET_MY_TRIPS_POSTBOOKING_OFFERS";
export type SET_MY_TRIPS_POSTBOOKING_OFFERS =
  typeof SET_MY_TRIPS_POSTBOOKING_OFFERS;

export const SET_SELECTED_HOTEL = "tripsList/SET_SELECTED_HOTEL";
export type SET_SELECTED_HOTEL = typeof SET_SELECTED_HOTEL;

export const FETCH_HOTELS = "tripsList/FETCH_HOTELS";
export type FETCH_HOTELS = typeof FETCH_HOTELS;

export const SET_HOTELS = "tripsList/SET_HOTELS";
export type SET_HOTELS = typeof SET_HOTELS;

export const FETCH_HOTELS_FAILED = "tripsList/FETCH_HOTELS_FAILED";
export type FETCH_HOTELS_FAILED = typeof FETCH_HOTELS_FAILED;

export const FETCH_HOTEL_CFAR_CANCELLATION_POLICY =
  "tripsList/FETCH_HOTEL_CFAR_CANCELLATION_POLICY";
export type FETCH_HOTEL_CFAR_CANCELLATION_POLICY =
  typeof FETCH_HOTEL_CFAR_CANCELLATION_POLICY;

export const SET_HOTEL_CFAR_CANCELLATION_POLICY_SCENARIO =
  "tripsList/SET_HOTEL_CFAR_CANCELLATION_POLICY_SCENARIO";
export type SET_HOTEL_CFAR_CANCELLATION_POLICY_SCENARIO =
  typeof SET_HOTEL_CFAR_CANCELLATION_POLICY_SCENARIO;

export const RESET_HOTEL_CFAR_CANCELLATION =
  "tripsList/RESET_HOTEL_CFAR_CANCELLATION";
export type RESET_HOTEL_CFAR_CANCELLATION =
  typeof RESET_HOTEL_CFAR_CANCELLATION;

export const SET_HOTEL_CFAR_CANCELLATION_POLICY_CALL_STATE =
  "tripsList/SET_HOTEL_CFAR_CANCELLATION_POLICY_CALL_STATE";
export type SET_HOTEL_CFAR_CANCELLATION_POLICY_CALL_STATE =
  typeof SET_HOTEL_CFAR_CANCELLATION_POLICY_CALL_STATE;

export const FETCH_HOTEL_CFAR_CANCELLATION_CALL_STATE =
  "tripsList/FETCH_HOTEL_CFAR_CANCELLATION_CALL_STATE";
export type FETCH_HOTEL_CFAR_CANCELLATION_CALL_STATE =
  typeof FETCH_HOTEL_CFAR_CANCELLATION_CALL_STATE;

export const CONFIRM_HOTEL_CFAR_CANCELLATION =
  "tripsList/CONFIRM_HOTEL_CFAR_CANCELLATION";
export type CONFIRM_HOTEL_CFAR_CANCELLATION =
  typeof CONFIRM_HOTEL_CFAR_CANCELLATION;

export const SET_CONFIRM_HOTEL_CFAR_CANCELLATION_CALL_STATE =
  "tripsList/SET_CONFIRM_HOTEL_CFAR_CANCELLATION_CALL_STATE";
export type SET_CONFIRM_HOTEL_CFAR_CANCELLATION_CALL_STATE =
  typeof SET_CONFIRM_HOTEL_CFAR_CANCELLATION_CALL_STATE;

export const SET_HOTEL_CFAR_CANCELLED = "tripsList/SET_HOTEL_CFAR_CANCELLED";
export type SET_HOTEL_CFAR_CANCELLED = typeof SET_HOTEL_CFAR_CANCELLED;

export const SET_SELECTED_HOME = "tripsList/SET_SELECTED_HOME";
export type SET_SELECTED_HOME = typeof SET_SELECTED_HOME;

export const FETCH_HOMES = "tripsList/FETCH_HOMES";
export type FETCH_HOMES = typeof FETCH_HOMES;

export const SET_HOMES = "tripsList/SET_HOMES";
export type SET_HOMES = typeof SET_HOMES;

export const FETCH_HOMES_FAILED = "tripsList/FETCH_HOMES_FAILED";
export type FETCH_HOMES_FAILED = typeof FETCH_HOMES_FAILED;

export const SET_SELECTED_CAR = "tripsList/SET_SELECTED_CAR";
export type SET_SELECTED_CAR = typeof SET_SELECTED_CAR;

export const FETCH_CARS = "tripsList/FETCH_CARS";
export type FETCH_CARS = typeof FETCH_CARS;

export const SET_CARS = "tripsList/SET_CARS";
export type SET_CARS = typeof SET_CARS;

export const FETCH_CARS_FAILED = "tripsList/FETCH_CARS_FAILED";
export type FETCH_CARS_FAILED = typeof FETCH_CARS_FAILED;

export const UPDATE_WATCH = "tripsList/UPDATE_WATCH";
export type UPDATE_WATCH = typeof UPDATE_WATCH;

export const SET_UPDATE_WATCH_CALL_STATE =
  "tripsList/SET_UPDATE_WATCH_CALL_STATE";
export type SET_UPDATE_WATCH_CALL_STATE = typeof SET_UPDATE_WATCH_CALL_STATE;

export const DELETE_WATCH = "tripsList/DELETE_WATCH";
export type DELETE_WATCH = typeof DELETE_WATCH;

export const SET_DELETE_WATCH_CALL_STATE =
  "tripsList/SET_DELETE_WATCH_CALL_STATE";
export type SET_DELETE_WATCH_CALL_STATE = typeof SET_DELETE_WATCH_CALL_STATE;

export const LIST_WATCHES = "tripsList/LIST_WATCHES";
export type LIST_WATCHES = typeof LIST_WATCHES;

export const LIST_PRICE_FREEZE = "tripsList/LIST_PRICE_FREEZE";
export type LIST_PRICE_FREEZE = typeof LIST_PRICE_FREEZE;

export const SET_LIST_PRICE_FREEZE_CALL_STATE =
  "tripsList/SET_LIST_PRICE_FREEZE_CALL_STATE";
export type SET_LIST_PRICE_FREEZE_CALL_STATE =
  typeof SET_LIST_PRICE_FREEZE_CALL_STATE;

export const SET_PRICE_FREEZE = "tripsList/SET_PRICE_FREEZE";
export type SET_PRICE_FREEZE = typeof SET_PRICE_FREEZE;

export const LIST_HOTEL_PRICE_FREEZE = "tripsList/LIST_HOTEL_PRICE_FREEZE";
export type LIST_HOTEL_PRICE_FREEZE = typeof LIST_HOTEL_PRICE_FREEZE;

export const SET_LIST_HOTEL_PRICE_FREEZE_CALL_STATE =
  "tripsList/SET_LIST_HOTEL_PRICE_FREEZE_CALL_STATE";
export type SET_LIST_HOTEL_PRICE_FREEZE_CALL_STATE =
  typeof SET_LIST_HOTEL_PRICE_FREEZE_CALL_STATE;

export const SET_HOTEL_PRICE_FREEZES = "tripsList/SET_HOTEL_PRICE_FREEZES";
export type SET_HOTEL_PRICE_FREEZES = typeof SET_HOTEL_PRICE_FREEZES;

export const SET_LIST_WATCHES_CALL_STATE =
  "tripsList/SET_LIST_WATCHES_CALL_STATE";
export type SET_LIST_WATCHES_CALL_STATE = typeof SET_LIST_WATCHES_CALL_STATE;

export const SET_WATCHES = "tripsList/SET_WATCHES";
export type SET_WATCHES = typeof SET_WATCHES;

export const POPULATE_TRIP_QUERY_PARAMS =
  "tripsList/POPULATE_TRIP_QUERY_PARAMS";
export type POPULATE_TRIP_QUERY_PARAMS = typeof POPULATE_TRIP_QUERY_PARAMS;

export const SET_OPEN_MODAL = "tripsList/SET_OPEN_MODAL";
export type SET_OPEN_MODAL = typeof SET_OPEN_MODAL;

export const ACCEPT_SCHEDULE_CHANGE = "tripsList/ACCEPT_SCHEDULE_CHANGE";
export type ACCEPT_SCHEDULE_CHANGE = typeof ACCEPT_SCHEDULE_CHANGE;

export const SET_ACCEPT_SCHEDULE_CHANGE_CALL_STATE =
  "tripsList/SET_ACCEPT_SCHEDULE_CHANGE_CALL_STATE";
export type SET_ACCEPT_SCHEDULE_CHANGE_CALL_STATE =
  typeof SET_ACCEPT_SCHEDULE_CHANGE_CALL_STATE;

export const DENY_SCHEDULE_CHANGE = "tripsList/DENY_SCHEDULE_CHANGE";
export type DENY_SCHEDULE_CHANGE = typeof DENY_SCHEDULE_CHANGE;

export const SET_DENY_SCHEDULE_CHANGE_CALL_STATE =
  "tripsList/SET_DENY_SCHEDULE_CHANGE_CALL_STATE";
export type SET_DENY_SCHEDULE_CHANGE_CALL_STATE =
  typeof SET_DENY_SCHEDULE_CHANGE_CALL_STATE;

export const FETCH_FLIGHT_CFAR_CANCELLATION_INFO_V3 =
  "FETCH_FLIGHT_CFAR_CANCELLATION_INFO_V3";
export type FETCH_FLIGHT_CFAR_CANCELLATION_INFO_V3 =
  typeof FETCH_FLIGHT_CFAR_CANCELLATION_INFO_V3;

export const SET_FLIGHT_CFAR_CANCELLATION_INFO_V3_RESPONSE =
  "SET_FLIGHT_CFAR_CANCELLATION_INFO_V3_RESPONSE";
export type SET_FLIGHT_CFAR_CANCELLATION_INFO_V3_RESPONSE =
  typeof SET_FLIGHT_CFAR_CANCELLATION_INFO_V3_RESPONSE;

export const SET_FETCH_FLIGHT_CFAR_CANCELLATION_INFO_V3_CALL_STATE_FAILED =
  "SET_FETCH_FLIGHT_CFAR_CANCELLATION_INFO_V3_CALL_STATE_FAILED";
export type SET_FETCH_FLIGHT_CFAR_CANCELLATION_INFO_V3_CALL_STATE_FAILED =
  typeof SET_FETCH_FLIGHT_CFAR_CANCELLATION_INFO_V3_CALL_STATE_FAILED;

export const SET_FETCH_FLIGHT_CFAR_CANCELLATION_INFO_V3_CALL_STATE_NOT_CALLED =
  "SET_FETCH_FLIGHT_CFAR_CANCELLATION_INFO_V3_CALL_STATE_NOT_CALLED";
export type SET_FETCH_FLIGHT_CFAR_CANCELLATION_INFO_V3_CALL_STATE_NOT_CALLED =
  typeof SET_FETCH_FLIGHT_CFAR_CANCELLATION_INFO_V3_CALL_STATE_NOT_CALLED;

export const CONFIRM_FLIGHT_CFAR_CANCELLATION =
  "CONFIRM_FLIGHT_CFAR_CANCELLATION";
export type CONFIRM_FLIGHT_CFAR_CANCELLATION =
  typeof CONFIRM_FLIGHT_CFAR_CANCELLATION;

export const SET_CONFIRM_FLIGHT_CFAR_CANCELLATION_RESPONSE =
  "SET_CONFIRM_FLIGHT_CFAR_CANCELLATION_RESPONSE";
export type SET_CONFIRM_FLIGHT_CFAR_CANCELLATION_RESPONSE =
  typeof SET_CONFIRM_FLIGHT_CFAR_CANCELLATION_RESPONSE;

export const SET_CONFIRM_FLIGHT_CFAR_CANCELLATION_CALL_STATE_FAILED =
  "SET_CONFIRM_FLIGHT_CFAR_CANCELLATION_CALL_STATE_FAILED";
export type SET_CONFIRM_FLIGHT_CFAR_CANCELLATION_CALL_STATE_FAILED =
  typeof SET_CONFIRM_FLIGHT_CFAR_CANCELLATION_CALL_STATE_FAILED;

export const SET_CONFIRM_FLIGHT_CFAR_CANCELLATION_CALL_STATE_NOT_CALLED =
  "SET_CONFIRM_FLIGHT_CFAR_CANCELLATION_CALL_STATE_NOT_CALLED";
export type SET_CONFIRM_FLIGHT_CFAR_CANCELLATION_CALL_STATE_NOT_CALLED =
  typeof SET_CONFIRM_FLIGHT_CFAR_CANCELLATION_CALL_STATE_NOT_CALLED;

export const SET_SELECTED_REQUEST_PASSENGER_EDIT =
  "tripsList/SET_SELECTED_REQUEST_PASSENGER_EDIT";
export type SET_SELECTED_REQUEST_PASSENGER_EDIT =
  typeof SET_SELECTED_REQUEST_PASSENGER_EDIT;

export const FETCH_INITIAL_UPCOMING_FLIGHT_CROSS_SELL =
  "tripsList/FETCH_INITIAL_UPCOMING_LIGHT_CROSS_SELL";
export type FETCH_INITIAL_UPCOMING_FLIGHT_CROSS_SELL =
  typeof FETCH_INITIAL_UPCOMING_FLIGHT_CROSS_SELL;

export const FETCH_MORE_UPCOMING_FLIGHT_CROSS_SELL =
  "tripsList/FETCH_MORE_UPCOMING_FLIGHT_CROSS_SELL";
export type FETCH_MORE_UPCOMING_FLIGHT_CROSS_SELL =
  typeof FETCH_MORE_UPCOMING_FLIGHT_CROSS_SELL;

export const SET_CROSS_SELL_HOTEL_AVAILABILITY_RESULTS =
  "tripsList/SET_CROSS_SELL_OTEL_AVAILABILITY_RESULTS";
export type SET_CROSS_SELL_HOTEL_AVAILABILITY_RESULTS =
  typeof SET_CROSS_SELL_HOTEL_AVAILABILITY_RESULTS;

export const FETCH_UPCOMING_FLIGHT_CROSS_SELL_FAILED =
  "tripsList/FETCH_UPCOMING_FFLIGHT_CROSS_SELL_FAILED";
export type FETCH_UPCOMING_FLIGHT_CROSS_SELL_FAILED =
  typeof FETCH_UPCOMING_FLIGHT_CROSS_SELL_FAILED;

export const SET_SEARCHED_DATES = "tripsList/SET_SEARCHED_DATES";
export type SET_SEARCHED_DATES = typeof SET_SEARCHED_DATES;

export const SET_SEARCHED_LOCATION_RESULT =
  "tripsList/SET_SEARCHED_LOCATION_RESULT";
export type SET_SEARCHED_LOCATION_RESULT = typeof SET_SEARCHED_LOCATION_RESULT;

export const SET_SEARCHED_OCCUPANCY_COUNTS =
  "tripsList/SET_SEARCHED_OCCUPANCY_COUNTS";
export type SET_SEARCHED_OCCUPANCY_COUNTS =
  typeof SET_SEARCHED_OCCUPANCY_COUNTS;

export const SET_SELECTED_LODGING_INDEX =
  "tripsList/SET_SELECTED_LODGING_INDEX";
export type SET_SELECTED_LODGING_INDEX = typeof SET_SELECTED_LODGING_INDEX;

export const SET_FLIGHT_TO_HOTEL_XSELL_TRIP_CATEGORY =
  "tripsList/SET_FLIGHT_TO_HOTEL_XSELL_TRIP_CATEGORY";
export type SET_FLIGHT_TO_HOTEL_XSELL_TRIP_CATEGORY =
  typeof SET_FLIGHT_TO_HOTEL_XSELL_TRIP_CATEGORY;

export const LIST_PAYMENT_METHODS = "tripsList/LIST_PAYMENT_METHODS";
export type LIST_PAYMENT_METHODS = typeof LIST_PAYMENT_METHODS;

export const SET_PAYMENT_METHODS = "tripsList/SET_PAYMENT_METHODS";
export type SET_PAYMENT_METHODS = typeof SET_PAYMENT_METHODS;

export const SET_PAYMENT_METHODS_CALL_STATE_FAILED =
  "tripsList/SET_PAYMENT_METHODS_CALL_STATE_FAILED";
export type SET_PAYMENT_METHODS_CALL_STATE_FAILED =
  typeof SET_PAYMENT_METHODS_CALL_STATE_FAILED;

export const FETCH_PENDING_REQUESTS = "tripsList/FETCH_PENDING_REQUESTS";
export type FETCH_PENDING_REQUESTS = typeof FETCH_PENDING_REQUESTS;

export const SET_PENDING_REQUESTS = "tripsList/SET_PENDING_REQUESTS";
export type SET_PENDING_REQUESTS = typeof SET_PENDING_REQUESTS;

export const FETCH_PENDING_REQUESTS_FAILED =
  "tripsList/FETCH_PENDING_REQUESTS_FAILED";
export type FETCH_PENDING_REQUESTS_FAILED =
  typeof FETCH_PENDING_REQUESTS_FAILED;

export const SET_TRIP_SEARCH_QUERY = "tripsList/SET_TRIP_SEARCH_QUERY";
export type SET_TRIP_SEARCH_QUERY = typeof SET_TRIP_SEARCH_QUERY;

export const FETCH_ACTIVE_HOTEL_CROSS_SELL =
  "tripsList/FETCH_ACTIVE_HOTEL_CROSS_SELL";
export type FETCH_ACTIVE_HOTEL_CROSS_SELL =
  typeof FETCH_ACTIVE_HOTEL_CROSS_SELL;

export const SET_CROSS_SELL_OFFERS = "tripsList/SET_CROSS_SELL_OFFERS";
export type SET_CROSS_SELL_OFFERS = typeof SET_CROSS_SELL_OFFERS;

export const SET_FETCH_ACTIVE_HOTEL_CROSS_SELL_CALLSTATE =
  "tripsList/SET_FETCH_ACTIVE_HOTEL_CROSS_SELL_CALLSTATE ";
export type SET_FETCH_ACTIVE_HOTEL_CROSS_SELL_CALLSTATE =
  typeof SET_FETCH_ACTIVE_HOTEL_CROSS_SELL_CALLSTATE;

export const FETCH_PACKAGES = "tripsList/FETCH_PACKAGES";
export type FETCH_PACKAGES = typeof FETCH_PACKAGES;

export const SET_PACKAGES = "tripsList/SET_PACKAGES";
export type SET_PACKAGES = typeof SET_PACKAGES;

export const FETCH_PACKAGES_FAILED = "tripsList/FETCH_PACKAGESFAILED";
export type FETCH_PACKAGES_FAILED = typeof FETCH_PACKAGES_FAILED;

export const SET_SELECTED_PACKAGE = "tripsList/SET_SELECTED_PACKAGE";
export type SET_SELECTED_PACKAGE = typeof SET_SELECTED_PACKAGE;

export const SET_SELECTED_PACKAGE_HOTEL =
  "tripsList/SET_SELECTED_PACKAGE_HOTEL";
export type SET_SELECTED_PACKAGE_HOTEL = typeof SET_SELECTED_PACKAGE_HOTEL;

export const SET_SELECTED_PACKAGE_FLIGHT =
  "tripsList/SET_SELECTED_PACKAGE_FLIGHT";
export type SET_SELECTED_PACKAGE_FLIGHT = typeof SET_SELECTED_PACKAGE_FLIGHT;
