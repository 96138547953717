import { getRewardText, getTotalPriceText, twoDecimalFormatter } from "halifax";
import {
  FiatPrice,
  RewardsPrice,
  CfarEnum,
  GENERAL_SUPPORT_PHONE_NUMBER,
  RefundBreakdown,
} from "redmond";
import { CfarFlightModalStep } from "./types";

// Common
export const READ_TERMS_AND_CONDITIONS = "Read terms and conditions";
export const CANCEL_YOUR_FLIGHT_FOR_ANY_REASON_TITLE =
  "Cancel your flight for any reason";
export const CANCELLATION_INFORMATION_HEADER = "Cancellation Information";
export const CONTINUE_BUTTON_COPY = "Continue";
export const CONFIRM_CANCELLATION_TITLE = "Confirm cancellation";
export const CONFIRM_CANCELLATION_BUTTON_COPY = "Confirm Cancellation";
export const GO_BACK = "Go Back";
export const CONFIRM_CANCELLATION_TEXT =
  "Complete the cancellation of your round-trip flight for all travelers.";
export const CONTACT_SUPPORT_COPY = "Contact Support";

const getPricePointsString = (
  fiat: FiatPrice,
  reward: RewardsPrice | undefined,
  unbold?: boolean
) =>
  `${!unbold ? `<strong>` : ""}${getTotalPriceText({
    price: fiat,
    priceFormatter: twoDecimalFormatter,
  })}${!unbold ? `</strong>` : ""}${
    reward ? ` / ${getRewardText({ reward })}` : ""
  }`;

// CfarCash
// No Waiver and no Future Travel Credit Available = 80% Refund
export const getCfarCashSubtitle = (coveragePercent: number) =>
  `You’ll receive <strong>${coveragePercent}% of the original ticket price</strong> refunded to the payment method you used to book this flight.`;

export const getCfarCashTitleConfirmInformation = (timestamp: string) => [
  `You must cancel your booking before ${timestamp}.`,
  "All passengers will be canceled from this booking. Cancellations cannot be reversed.",
  "Refunds will be issued using the same method of payment as the original booking.",
  "Refunds cover the base fare and related taxes and fees. Any travel offers and paid add-ons such as baggage, seat selection and cancel for any reason are not included.",
  `If you have any questions about canceling your trip please contact us at ${GENERAL_SUPPORT_PHONE_NUMBER.boldText}.`,
];

const getRefundText = ({
  refundBreakdown,
}: {
  refundBreakdown: RefundBreakdown;
}) => {
  const rewardsText =
    refundBreakdown && refundBreakdown.rewards
      ? getRewardText({
          reward: refundBreakdown?.rewards?.rewardsAmount.rewardsPrice,
        })
      : undefined;

  const travelCredits =
    refundBreakdown && refundBreakdown.travelWalletCredits
      ? `${getTotalPriceText({
          price: {
            value: refundBreakdown.travelWalletCredits.amount,
            currencyCode: "USD",
            currencySymbol: "$",
          },
          priceFormatter: twoDecimalFormatter,
        })} in travel credit`
      : undefined;

  const cash =
    refundBreakdown && refundBreakdown.userCard
      ? getTotalPriceText({
          price: {
            value: refundBreakdown.userCard.amount,
            currencyCode: "USD",
            currencySymbol: "$",
          },
          priceFormatter: twoDecimalFormatter,
        })
      : undefined;
  const refundTypes = [rewardsText, travelCredits, cash].reduce((acc, cur) => {
    if (cur !== undefined) {
      return [...acc, cur];
    }
    return acc;
  }, [] as string[]);

  switch (refundTypes.length) {
    case 1:
      return refundTypes[0];
    case 2:
      return refundTypes[0] + " and " + refundTypes[1];
    case 3:
      const last = refundTypes.pop();
      return refundTypes.join(", ") + ", and " + last;
    default:
      return "";
  }
};

export const getCfarCashConfirmSubtitle = (
  fiat: FiatPrice,
  reward: RewardsPrice | undefined,
  refundBreakdown?: RefundBreakdown
) => {
  const refundText = refundBreakdown
    ? getRefundText({ refundBreakdown })
    : getPricePointsString(fiat, reward);
  const cashRefund = refundBreakdown?.userCard;
  const cashPresent = cashRefund && cashRefund.amount > 0;
  return `You’ll receive a refund of ${refundText}${
    cashPresent ? " to your original payment method" : ""
  }. You should receive your refund within the next 1-2 billing cycles, but in some cases it may take longer.`;
};

// CfarCashOrCfarFtcWithPenalty | CfarCashOrCfarFtc
// Future Travel Credit Available, With Waiver or Without Waiver = Choice between FTC and Refund
export const getChooseBetweenTravelCreditOrPaymentSubtitle = (
  coveragePercent: number,
  hasDifferentMarketingAirlines: boolean
) =>
  hasDifferentMarketingAirlines
    ? `Choose between airline travel credits for 100% of the cost from the airlines or ${
        coveragePercent == 80 ? "an" : "a"
      } ${coveragePercent}% refund to your original payment method.`
    : `Choose between an airline travel credit for 100% of the cost from the airline or ${
        coveragePercent == 80 ? "an" : "a"
      } ${coveragePercent}% refund to your original payment method.`;
export const getPartialCashRefundTitle = (coveragePercent: number) =>
  `${coveragePercent}% Refund`;

export const getPartialCashRefundSubtitle = (
  coveragePercent: number,
  fiat: FiatPrice,
  reward: RewardsPrice | undefined,
  refundBreakdown?: RefundBreakdown
) => {
  const refundText = refundBreakdown
    ? getRefundText({ refundBreakdown })
    : getPricePointsString(fiat, reward, true);
  const cashRefund = refundBreakdown?.userCard;
  const cashPresent = cashRefund && cashRefund.amount > 0;
  return `Receive ${
    coveragePercent == 80 ? "an" : "a"
  } <strong>${coveragePercent}% refund</strong> (${refundText}) of your ticket cost${
    cashPresent ? " back to your original payment method" : ""
  }. Your refund will be processed within the next 1-2 billing cycles.`;
};

export const getAirlineCreditTitle = (
  airlines: string[],
  hasDifferentMarketingAirlines: boolean
) =>
  hasDifferentMarketingAirlines
    ? `100% ${airlines[0]} and ${airlines[1]} Credits`
    : `100% ${airlines[0]} Credit`;
export const getAirlineCreditSubtitle = (
  fiat: FiatPrice,
  reward: RewardsPrice | undefined,
  airlines: string[],
  hasDifferentMarketingAirlines: boolean
) =>
  `Receive ${
    hasDifferentMarketingAirlines
      ? `<strong>airline travel credits worth 100%</strong>`
      : `an <strong>airline travel credit worth 100%</strong>`
  } (${getPricePointsString(
    fiat,
    reward,
    true
  )}) of your ticket cost. We’ll cover the cost of any rebooking fees or cancellation penalties. This credit can be used to book a future trip with ${
    airlines[0]
  }${
    hasDifferentMarketingAirlines ? ` and ${airlines[1]}` : ""
  } and must be used within the time frame set by the airline, which is usually within 1 year from purchase.`;

// Partial Cash Refund Confrim
export const getPartialCashRefundConfirmSubtitle = (
  fiat: FiatPrice,
  reward: RewardsPrice | undefined,
  refundBreakdown?: RefundBreakdown
) => {
  const refundText = refundBreakdown
    ? getRefundText({ refundBreakdown })
    : getPricePointsString(fiat, reward, true);
  const cashRefund = refundBreakdown?.userCard;
  const cashPresent = cashRefund && cashRefund.amount > 0;
  return `You'll receive a refund of ${refundText}${
    cashPresent ? " to your original payment method" : ""
  }. You should receive your refund within the next 1-2 billing cycles, but in some cases it may take longer.`;
};

export const getPartialCashRefundConfirmInformation = (timestamp: string) => [
  `You must cancel your booking before ${timestamp}.`,
  "All passengers will be canceled from this booking. Cancellations cannot be reversed.",
  "Refunds will be issued using the same method of payment as the original booking.",
  "Refunds cover the base fare and related taxes and fees. Any travel offers and paid add-ons such as baggage, seat selection and cancel for any reason are not included.",
  "You should receive your refund within the next 1-2 billing cycles, but in some cases it may take longer.",
  `If you have any questions about canceling your trip please contact us at ${GENERAL_SUPPORT_PHONE_NUMBER.boldText}.`,
];

// Airline Credit Confirm
export const getAirlineCreditConfirmSubtitle = (
  fiats: FiatPrice[],
  airlines: string[],
  hasDifferentMarketingAirlines: boolean
) =>
  hasDifferentMarketingAirlines
    ? [
        `You’ll receive an airline travel credit worth <strong>${getTotalPriceText(
          {
            price: fiats[0],
            priceFormatter: twoDecimalFormatter,
          }
        )}</strong> that you can use to book a trip in the future with ${
          airlines[0]
        } and an airline travel credit worth <strong>${getTotalPriceText({
          price: fiats[1],
          priceFormatter: twoDecimalFormatter,
        })}</strong> that you can use to book a trip in the future with ${
          airlines[1]
        }.`,
        `When you’re ready to use your travel credit to make a new reservation, go to My Trips to choose another itinerary.`,
      ]
    : [
        `You’ll receive an airline travel credit worth <strong>${getTotalPriceText(
          {
            price: fiats[0],
            priceFormatter: twoDecimalFormatter,
          }
        )}</strong> that you can use to book a trip in the future with ${
          airlines[0]
        }.`,
        `When you’re ready to use your travel credit to make a new reservation, go to My Trips to choose another itinerary.`,
      ];
export const getAirlineCreditConfirmInformation = (timestamp: string) => [
  `You must cancel your booking before ${timestamp}.`,
  "All passengers will be canceled from this booking. Cancellations cannot be reversed.",
  "Airline travel credits must be used within the time frame set by the airline, which is usually within 1 year from purchase of the original ticket. Be sure to check out your airline’s policy for details.",
  "Airline travel credits cover the base fare and related taxes and fees. Any paid add-ons, such as baggage, may be eligible for a travel credit by contacting the airline.",
  "You will receive a confirmation email outlining the detailed credit terms.",
  `If you have any questions about canceling your trip please contact us at ${GENERAL_SUPPORT_PHONE_NUMBER.boldText}.`,
];

// MISSING ENUM
// Partial Refund Waiver Available = Agent Support Queue
export const getPartialRefundSubtitle = (coveragePercent: number) =>
  `With Cancel For Any Reason, you will always be covered for at least an ${coveragePercent}% refund of your flight ticket cost. Your booking also qualifies for a partial refund provided by your airline, so in order to make sure you receive the highest refund, please call customer support at ${GENERAL_SUPPORT_PHONE_NUMBER.boldText}.`;
export const getPartialRefundCancellationInformation = (timestamp: string) => [
  `You must cancel your booking before ${timestamp}.`,
  "All passengers will be canceled from this booking. Cancellations cannot be reversed.",
  "Refunds cover the base fare and related taxes and fees. Any travel offers and paid add-ons such as baggage, seat selection and cancel for any reason are not included.",
  "Refunds will be issued using the same method of payment as the original booking.",
  "Airline travel credits must be used within the time frame set by the airline, which is usually within 1 year from purchase of the original ticket. Be sure to check out your airline’s policy for details.",
];

// AirlineFullRefund | AirlineComplexFullRefund
// Full Refund Waiver Available = 100% Refund
export const FULL_REFUND_SUBTITLE =
  "You’ll receive <strong>100% of the original ticket price</strong> refunded to the payment method you used to book this flight.";
export const getFullRefundCancellationInformation = (timestamp: string) => [
  `You must cancel your booking before ${timestamp}.`,
  "All passengers will be canceled from this booking. Cancellations cannot be reversed.",
  "Refunds cover the base fare and related taxes and fees. Any travel offers and paid add-ons such as baggage, seat selection and cancel for any reason are not included.",
  "Refunds will be issued using the same method of payment as the original booking.",
  `If you have any questions about canceling your trip please contact us at ${GENERAL_SUPPORT_PHONE_NUMBER.boldText}.`,
];

// TicketedVoid
// Cancellation Within Void Window
export const getTicketedVoidSubtitle = (timestamp: string) =>
  `Cancel your booking for a 100% refund of the original ticket price and get a full refund for the fee you paid to <strong>cancel for any reason</strong> until ${timestamp}.`;
export const VOID_WINDOW_CANCELLATION_INFORMATION = [
  "All passengers will be canceled from this booking. Cancellations cannot be reversed.",
  "Once canceled you will receive a confirmation email and the status of your trip will be updated.",
  "Refunds cover the base fare and related taxes and fees. Any travel offers and paid add-ons such as baggage, seat selection and cancel for any reason are not included.",
  "Refunds will be issued using the same method of payment as the original booking.",
  `If you have any questions about canceling your trip please contact us at ${GENERAL_SUPPORT_PHONE_NUMBER.boldText}.`,
];

// Default | AirlineComplexPartialRefund | AirlinePartialRefundOrCfarCash | TooCloseToDeparture
// Cancel Flight - Unsupported Ticket
export const UNSUPPORTED_TICKET_SUBTITLE = [
  `This flight is unique. In order to help you receive the best cancellation policy, please call customer support at ${GENERAL_SUPPORT_PHONE_NUMBER.boldText}.`,
  `With Cancel For Any Reason, you will always be covered for at least an <strong>80% refund</strong> of your flight ticket cost.`,
];
export const getUnsupportedTicketCancellationInformation = (
  timestamp: string
) => [
  `You must cancel your booking before ${timestamp}.`,
  "All passengers will be canceled from this booking. Cancellations cannot be reversed.",
  "Refunds will be issued using the same method of payment as the original booking.",
  "Refunds cover the base fare and related taxes and fees. Any travel offers and paid add-ons such as baggage, seat selection and cancel for any reason are not included.",
  "Airline travel credits must be used within the time frame set by the airline, which is usually within 1 year from purchase of the original ticket. Be sure to check out your airline’s policy for details.",
];

// Cancellation Pending
export const CLOSE_BUTTON_COPY = "Close";
export const PENDING_CANCELLATION_TITLE = "We're processing your confirmation";
export const PENDING_CANCELLATION_SUBTITLE =
  "Your booking has already been cancelled. A confirmation email will be sent and My Trips will be updated within 1-2 hours.";

/*
  note: ESTIMATED_XXX_HEIGHT's are upper bounds to the height of CFAR content; in the future, it can be removed if we were to find a better solution.
  I think something that uses the container query could be perfect for our needs, see https://www.npmjs.com/package/react-container-query.
*/
export const ESTIMATED_CFAR_CASH_OR_CFAR_FTC_HEIGHT_CHOICE = 600;
export const ESTIMATED_CFAR_CASH_OR_CFAR_FTC_HEIGHT_DETAILS = 780;
export const ESTIMATED_CFAR_CASH_OR_CFAR_FTC_WITH_PENALTY_HEIGHT_CHOICE = 600;
export const ESTIMATED_CFAR_CASH_OR_CFAR_FTC_WITH_PENALTY_HEIGHT_DETAILS = 780;
export const ESTIMATED_CFAR_TICKETED_VOID_HEIGHT_CHOICE = 725;
export const ESTIMATED_CFAR_TICKETED_VOID_HEIGHT_DETAILS = 505;
export const ESTIMATED_CFAR_AIRLINE_FULL_REFUND_HEIGHT_CHOICE = 710;
export const ESTIMATED_CFAR_AIRLINE_FULL_REFUND_HEIGHT_DETAILS = 505;
export const ESTIMATED_CFAR_AIRLINE_PARTIAL_REFUND_OR_CFAR_CASH_HEIGHT_CHOICE = 745;
export const ESTIMATED_CFAR_CASH_HEIGHT_CHOICE = 720;
export const ESTIMATED_CFAR_CASH_HEIGHT_DETAILS = 505;

/*
  note: DIALOG_MARGIN is the same as the margin in MuiDialog-paperScrollPaper, which comes from material-ui.Dialog;
  MuiDialog-paperScrollPaper has max-height set to calc(100% - 2 * 32px).
*/
export const DIALOG_MARGIN = 32;

export const getPartialScrollThreshold = (
  type: CfarEnum | undefined,
  step: CfarFlightModalStep
) => {
  let height = 750;

  switch (step) {
    case CfarFlightModalStep.Choice:
      switch (type) {
        case CfarEnum.CfarCashOrCfarFtc:
          height = ESTIMATED_CFAR_CASH_OR_CFAR_FTC_HEIGHT_CHOICE;
          break;
        case CfarEnum.CfarCashOrCfarFtcWithPenalty:
          height = ESTIMATED_CFAR_CASH_OR_CFAR_FTC_WITH_PENALTY_HEIGHT_CHOICE;
          break;
        case CfarEnum.TicketedVoid:
          height = ESTIMATED_CFAR_TICKETED_VOID_HEIGHT_CHOICE;
          break;
        case CfarEnum.AirlineFullRefund:
          height = ESTIMATED_CFAR_AIRLINE_FULL_REFUND_HEIGHT_CHOICE;
          break;
        case CfarEnum.AirlinePartialRefundOrCfarCash:
          height =
            ESTIMATED_CFAR_AIRLINE_PARTIAL_REFUND_OR_CFAR_CASH_HEIGHT_CHOICE;
          break;
        case CfarEnum.CfarCash:
          height = ESTIMATED_CFAR_CASH_HEIGHT_CHOICE;
          break;
        default:
          break;
      }
      break;
    case CfarFlightModalStep.Details:
      switch (type) {
        case CfarEnum.CfarCashOrCfarFtc:
          height = ESTIMATED_CFAR_CASH_OR_CFAR_FTC_HEIGHT_DETAILS;
          break;
        case CfarEnum.CfarCashOrCfarFtcWithPenalty:
          height = ESTIMATED_CFAR_CASH_OR_CFAR_FTC_WITH_PENALTY_HEIGHT_DETAILS;
          break;
        case CfarEnum.TicketedVoid:
          height = ESTIMATED_CFAR_TICKETED_VOID_HEIGHT_DETAILS;
          break;
        case CfarEnum.AirlineFullRefund:
          height = ESTIMATED_CFAR_AIRLINE_FULL_REFUND_HEIGHT_DETAILS;
          break;
        case CfarEnum.CfarCash:
          height = ESTIMATED_CFAR_CASH_HEIGHT_DETAILS;
          break;
        default:
          break;
      }
      break;
    default:
      break;
  }

  return `(max-height: ${height + 2 * DIALOG_MARGIN}px)`;
};
