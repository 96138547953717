import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { IStoreState } from "../../../../../../reducers/types";
import {
  fetchCars,
  setSelectedCar,
  populateTripQueryParams,
  setOpenModal,
} from "../../../../actions/actions";
import { CarCard } from "./component";
import { getSelectedCar, getTripsFilter } from "../../../../reducer";
import { MyTripsFilter } from "redmond";

const mapStateToProps = (state: IStoreState) => ({
  isPastTrips: getTripsFilter(state) === MyTripsFilter.PAST_TRIPS,
  selectedCar: getSelectedCar(state),
});

const mapDispatchToProps = {
  fetchCars,
  setSelectedCar,
  populateTripQueryParams,
  setOpenModal,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type CarCardConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedCarCard = connector(withRouter(CarCard));
