import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router-dom";
import { IStoreState } from "../../../../reducers/types";
import { getAirlinesMap, getAirportMap } from "../../../TripsList/reducer";
import { submitDisruptionFlightRefund } from "../../actions/actions";
import {
  getAreSomeDisruptionInfoFetchingsInProcess,
  selectedFlightDisruptionProtectionItinerarySelector,
  getPreparedRefundInfo,
  getFlightDisruptions,
  disruptionOverviewEligibilityDpExerciseFactsPropertiesSelector,
  eligibilityDpExerciseProductRedeemChoiceSelector,
} from "../../reducer/selectors";
import { DisruptionProtectionRefund } from "./component";

const mapStateToProps = (state: IStoreState) => {
  return {
    disruptedItinerary:
      selectedFlightDisruptionProtectionItinerarySelector(state),
    airportMap: getAirportMap(state),
    airlineMap: getAirlinesMap(state),
    flightDisruptions: getFlightDisruptions(state),
    prepareRefundInfo: getPreparedRefundInfo(state),
    areSomeDisruptionInfoFetchingsInProcess:
      getAreSomeDisruptionInfoFetchingsInProcess(state),
    eligibilityDpExerciseProductRedeemChoice:
      eligibilityDpExerciseProductRedeemChoiceSelector(state),
    disruptionOverviewEligibilityDpExerciseFactsProperties:
      disruptionOverviewEligibilityDpExerciseFactsPropertiesSelector(state),
  };
};

const mapDispatchToProps = {
  submitDisruptionFlightRefund,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type DisruptionProtectionRefundConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedDisruptionProtectionRefund = connector(
  withRouter(DisruptionProtectionRefund)
);
