import axios from "axios";
import { FlightCancelInfoResponse, CancellationInfoRequest } from "redmond";

import { config } from "../../config";
import { getFlightCancellationInfoPathV3 } from "../paths";

/**
 * @version v3
 * @description The first of two endpoints in the self serve cancel flight flow
 * @param {CancellationInfoRequest} req Contains the itinerary id
 * @return {FlightCancelInfoResponse} The cancellation info for the itinerary passed in
 */
const getFlightCancellationInfoV3 = (
  req: CancellationInfoRequest
): Promise<FlightCancelInfoResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await axios.post(getFlightCancellationInfoPathV3, req, {
        baseURL: config.baseURL,
      });

      resolve(res.data.value);
    } catch (e) {
      reject(e);
    }
  });

export default getFlightCancellationInfoV3;
