import { Typography, Box } from "@material-ui/core";
import dayjs from "dayjs";
import {
  NotificationBanner,
  BannerSeverity,
  Icon,
  IconName,
  MyTripsMobileCard,
  MyTripsCard,
  TripInfoDetails,
  ActionButton,
  getFullTimeString,
} from "halifax";
import React from "react";
import {
  PriceFreezeView,
  Airport,
  Airline,
  PriceFreezeStatusEnum,
  TripDetails,
} from "redmond";
import { formatFiatValue } from "../../../../utils/helpers";
import * as constants from "./constants";
import "./styles.scss";

export interface IFlightPriceFreezeCardProps {
  flightPriceFreeze: PriceFreezeView;
  airports: { [key: string]: Airport };
  airlines: { [key: string]: Airline };
  isMobile?: boolean;
  onFreezeAgain: (pf: PriceFreezeView) => void;
  onContinueToBooking: (id: string) => void;
}

export const FlightPriceFreezeCard = ({
  flightPriceFreeze,
  airports,
  airlines,
  isMobile,
  onFreezeAgain,
  onContinueToBooking,
}: IFlightPriceFreezeCardProps) => {
  const getPriceFreezeCardDetails = (
    tripDetails: TripDetails,
    formatDate: (date: string) => string,
    airports: { [key: string]: Airport },
    airlines: { [key: string]: Airline }
  ) => {
    // BF-1552 Support properties for multi-city flights once needed.
    const departureSlice = tripDetails.slices[0];
    const returnSlice = tripDetails.slices[1];
    const originCode = departureSlice.originCode;
    const destinationCode = departureSlice.destinationCode;
    const airlineCode = departureSlice.segmentDetails[0].airlineCode;
    const airlineName =
      airlines[departureSlice.segmentDetails[0].marketingAirline.code]
        ?.displayName ?? airlineCode;
    return {
      title: `${airports[originCode]?.cityName || originCode} to ${
        airports[destinationCode]?.cityName || destinationCode
      }`,
      startDate: formatDate(departureSlice.departureTime),
      endDate: returnSlice ? formatDate(returnSlice.departureTime) : "",
      subtitle: returnSlice ? constants.ROUND_TRIP : constants.ONE_WAY_TRIP,
      airlineCode,
      airlineName,
      flightNumber:
        airlineCode +
          " " +
          departureSlice.segmentDetails[0].flightNumber.toString() ?? "",
    };
  };

  const tripDetails = getPriceFreezeCardDetails(
    flightPriceFreeze.tripDetails,
    (date: string) => dayjs(date).format(constants.DATE_FORMAT),
    airports,
    airlines
  );

  const isExpired =
    flightPriceFreeze.status.Status === PriceFreezeStatusEnum.IsExpired;

  const formatExpiredDate = (date: string): string => {
    const dateObject = dayjs(date);

    return `${constants.EXPIRES_ON} ${getFullTimeString(dateObject)}`;
  };

  const renderExpirationBanner = (date: string) => {
    const dateObject = dayjs(date);

    return (
      <NotificationBanner
        className="status-banner"
        label={constants.formatBanner(dateObject)}
        severity={BannerSeverity.ERROR}
        icon={<Icon name={IconName.ErrorAlert} />}
      />
    );
  };

  const banner = isExpired
    ? renderExpirationBanner(flightPriceFreeze.priceFreeze.expiresAt)
    : undefined;
  const confirmationCodeClassName = isExpired ? "warning" : "";

  return isMobile ? (
    <MyTripsMobileCard
      key={flightPriceFreeze.priceFreeze.id}
      className="my-trips-mobile-card-flight"
      icon={IconName.FlightFunnelIcon}
      confirmationLabel={constants.CONFIRMATION}
      label={constants.FROZEN_HEADER_MOBILE_FLIGHT}
      confirmationCode={
        typeof flightPriceFreeze.priceFreeze.externalId === "string"
          ? flightPriceFreeze.priceFreeze.externalId
          : flightPriceFreeze.priceFreeze.externalId.value
      }
      confirmationCodeClassName={confirmationCodeClassName}
      banner={banner}
      onClick={() => {
        isExpired
          ? onFreezeAgain(flightPriceFreeze)
          : onContinueToBooking(flightPriceFreeze.priceFreeze.id);
      }}
      actions={
        !isExpired ? (
          <Typography variant="subtitle1" className="expire-label">
            {formatExpiredDate(flightPriceFreeze.priceFreeze.expiresAt)}
          </Typography>
        ) : undefined
      }
      shouldShowCtaIcon={!isExpired}
      {...tripDetails}
    />
  ) : (
    <MyTripsCard
      key={flightPriceFreeze.priceFreeze.id}
      className="my-trips-card-flight"
      actions={null}
      banner={banner}
      content={
        <TripInfoDetails
          confirmationCodeClassName={confirmationCodeClassName}
          titles={{
            confirmationLabel: constants.CONFIRMATION,
            startLabel: `${constants.DEPARTURE}:`,
            endLabel: `${constants.RETURN}:`,
            confirmationCode:
              typeof flightPriceFreeze.priceFreeze.externalId === "string"
                ? flightPriceFreeze.priceFreeze.externalId
                : flightPriceFreeze.priceFreeze.externalId.value,
            ...tripDetails,
          }}
        />
      }
      cta={
        <Box className="ctas-section">
          {!isExpired && (
            <Typography variant="subtitle1" className="expire-label">
              {formatExpiredDate(flightPriceFreeze.priceFreeze.expiresAt)}
            </Typography>
          )}
          {
            // TODO: get rid of !isExpired check when we're ready, see https://hopper-jira.atlassian.net/browse/CFTC-133
            !isExpired && (
              <ActionButton
                message={
                  !isExpired
                    ? constants.COMPLETE_BOOKING
                    : // TODO: this value might change once https://hopper-jira.atlassian.net/browse/CFTC-133
                      constants.freezeAgain(
                        formatFiatValue(
                          flightPriceFreeze.priceFreeze.offer.perPaxAmount.fiat
                            .value
                        )
                      )
                }
                defaultStyle="h4r-primary"
                onClick={() => {
                  isExpired
                    ? onFreezeAgain(flightPriceFreeze)
                    : onContinueToBooking(flightPriceFreeze.priceFreeze.id);
                }}
              />
            )
          }
        </Box>
      }
    />
  );
};
