import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { CancellationInfoRequest, HomesItinerary } from "redmond";

import getHomesCancelQuote from "../../../../../../../../api/v1/itinerary/getHomeCancellationPolicy";
import { Box, Divider, Typography } from "@material-ui/core";
import {
  ActionButton,
  B2BSpinnerWithText,
  Icon,
  ImportantInfoList,
  IconName,
  GenericModalContent,
} from "halifax";
import * as constants from "./constants";
import * as generalConstants from "../../constants";
import "./styles.scss";
import {
  selfServeHomesCancelFulfill,
  selfServeHomesCancelFulfillPoll,
} from "../../../../../../../../api/v1/itinerary/confirmHomeCancellation";
import { RouteComponentProps } from "react-router";
import { CancelHomeModalContentConnectorProps } from "./container";
import dayjs from "dayjs";
import {
  HomesCancelFulfillmentPollResponseEnum,
  HomesCancelFulfillRequest,
  HomesCancelFulfillResponseEnum,
  HomesCancelQuoteResponse,
  HomesCancelQuoteSuccess,
  HomesCancelScenarioEnum,
} from "@b2bportal/homes-api";
import { PATH_HOME } from "../../../../../../../../utils/paths";
import {
  ActiveExperiments,
  useExperiment,
} from "../../../../../../../../context/experiments";

export interface ICancelHomeModalContentProps
  extends CancelHomeModalContentConnectorProps,
    RouteComponentProps {
  home: HomesItinerary;
}

export enum CancelStep {
  CancellationInfo,
  ConfirmCancellation,
  LoadingPolicy,
  CancellationProcessing,
  CancellationSuccess,
  CancellationFailure,
}

const CancelHomeModalContent = (props: ICancelHomeModalContentProps) => {
  const { home, setOpenModal, history } = props;

  const selfServeHomesCancelExp = useExperiment(
    ActiveExperiments.SelfServeHomesCancel
  );

  const [cancelStep, setCancelStep] = useState<CancelStep>(
    CancelStep.LoadingPolicy
  );

  const closeModal = useCallback(
    () =>
      setOpenModal({
        type: null,
        selectedItinerary: null,
      }),
    [setOpenModal]
  );

  // Default cancel quote response if self serve cancel is unavailable.
  const defaultCustomerServiceCancelQuote: HomesCancelQuoteResponse = {
    cancelScenario: {
      cancelCopy: {
        title: constants.DEFAULT_CUSTOMER_SERVICE_CANCEL_TITLE,
        body: [constants.DEFAULT_CUSTOMER_SERVICE_CANCEL_BODY],
        importantInfo: [],
        callToAction: constants.BACK_TO_TRIPS,
      },
      HomesCancelScenario: "ContactCustomerService",
    },
    HomesCancelQuoteResponse: "HomesCancelQuoteSuccess",
  };

  // For non refundable bookings we'll redirect the user to customer service to cancel.
  const nonRefundableCancelQuote: HomesCancelQuoteResponse = {
    cancelScenario: {
      cancelCopy: {
        title: constants.CANCEL_VACATION_RENTAL_TITLE,
        body: [constants.NON_REFUNDABLE_CANCEL_BODY],
        importantInfo: [],
        callToAction: constants.BACK_TO_TRIPS,
      },
      HomesCancelScenario: "NonRefundable",
    },
    HomesCancelQuoteResponse: "HomesCancelQuoteSuccess",
  };

  const cancellationInfoRef = useRef<HomesCancelQuoteSuccess | null>();

  const POLL_INTERVAL_MS = 2000;
  const cancelFulfillPollIntervalIdRef = useRef<number>();

  const prepareCancellation = useCallback(() => {
    const { id } = home.reservation;
    getCancelQuote(id.value);
  }, [home.reservation]);

  const getCancelQuote = (customerReservationId: string) => {
    const req: CancellationInfoRequest = {
      customerReservationId,
    };
    setCancelStep(CancelStep.LoadingPolicy);
    if (selfServeHomesCancelExp) {
      getHomesCancelQuote(req).then((response) => {
        if (response.HomesCancelQuoteResponse === "HomesCancelQuoteSuccess") {
          cancellationInfoRef.current = response;
          setCancelStep(CancelStep.CancellationInfo);
        } else {
          console.error("Self-serve: Error getting cancellation info");
          cancellationInfoRef.current = defaultCustomerServiceCancelQuote;
          setCancelStep(CancelStep.CancellationInfo);
        }
      });
    } else {
      cancellationInfoRef.current = defaultCustomerServiceCancelQuote;
      setCancelStep(CancelStep.CancellationInfo);
    }
  };

  const displayCancelConfirmation = useCallback(() => {
    const { current: cancellationInfo } = cancellationInfoRef;

    if (
      cancellationInfo &&
      "cancelConfirmationCopy" in cancellationInfo?.cancelScenario
    ) {
      setCancelStep(CancelStep.ConfirmCancellation);
    }
  }, []);

  const pollCancelFulfillment = (fulfillmentSessionId: string) => {
    cancelFulfillPollIntervalIdRef.current = window.setInterval(async () => {
      const res = await selfServeHomesCancelFulfillPoll({
        fulfillmentSessionId,
      });

      if (
        res?.HomesCancelFulfillmentPollResponse ===
        HomesCancelFulfillmentPollResponseEnum.Success
      ) {
        clearCancelFulfillPollInterval();
        setCancelStep(CancelStep.CancellationSuccess);
      } else if (
        res?.HomesCancelFulfillmentPollResponse ===
          HomesCancelFulfillmentPollResponseEnum.Failure ||
        res?.HomesCancelFulfillmentPollResponse ===
          HomesCancelFulfillmentPollResponseEnum.Aborted
      ) {
        clearCancelFulfillPollInterval();
        setCancelStep(CancelStep.CancellationFailure);
      }
    }, POLL_INTERVAL_MS);
  };

  const clearCancelFulfillPollInterval = () => {
    if (cancelFulfillPollIntervalIdRef.current) {
      clearInterval(cancelFulfillPollIntervalIdRef.current);
      cancelFulfillPollIntervalIdRef.current = 0;
    }
  };

  const confirmCancellation = async () => {
    const { current: cancellationInfo } = cancellationInfoRef;

    if (
      cancellationInfo &&
      cancellationInfo.HomesCancelQuoteResponse === "HomesCancelQuoteSuccess" &&
      cancellationInfo?.quoteId
    ) {
      const selfServeCancelRequest: HomesCancelFulfillRequest = {
        quoteId: cancellationInfo.quoteId,
      };
      setCancelStep(CancelStep.CancellationProcessing);
      try {
        const cancelResp = await selfServeHomesCancelFulfill(
          selfServeCancelRequest
        );
        if (
          cancelResp.HomesCancelFulfillResponse ===
          HomesCancelFulfillResponseEnum.HomesCancelFulfillSuccess
        ) {
          // Kick off fulfillment poller to confirm that the cancellation was successful.
          pollCancelFulfillment(cancelResp.fulfillmentSessionId);
        } else if (
          cancelResp.HomesCancelFulfillResponse ===
          HomesCancelFulfillResponseEnum.HomesCancelFulfillFailure
        ) {
          setCancelStep(CancelStep.CancellationFailure);
        }
      } catch (err) {
        setCancelStep(CancelStep.CancellationFailure);
      }
    }
  };

  const renderLoading = useCallback(
    () => <B2BSpinnerWithText subtitle={constants.LOADING_POLICY} />,
    []
  );

  const renderCancellationProcessing = useCallback(
    () => <B2BSpinnerWithText subtitle={constants.PROCESSING_CANCEL} />,
    []
  );

  const PropertyInfo = () => {
    return (
      <Box className="property-info-container">
        <Typography variant="body1" className="property-name">
          {home.reservation.listing.name}
        </Typography>
        <Typography variant="body2" className="property-address">
          {home.reservation.listing.areaName}
          {home.reservation.listing.state
            ? `, ${home.reservation.listing.state}`
            : ""}
        </Typography>
        <Box className="booking-dates">
          <Box className="check-in">
            <Icon name={IconName.Calendar} />
            <Box>
              <Box className="booking-dates-header">Check-in</Box>
              <Box className="booking-dates-date">
                {dayjs(home.reservation.stayDates.from).format("ddd, MMM D")}
              </Box>
            </Box>
          </Box>
          <Box className="check-out">
            <Icon name={IconName.Calendar} />
            <Box>
              <Box className="booking-dates-header">Check-out</Box>
              <Box className="booking-dates-date">
                {dayjs(home.reservation.stayDates.until).format("ddd, MMM D")}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };
  const renderCancellationInfo = () => {
    const { current: cancellationInfo } = cancellationInfoRef;

    let onClickHandler: () => any = closeModal;
    // Only allow proceeding to cancellation confirmation if the booking is fully or partially refundable.
    // All other use cases will require customer service support.
    if (
      cancellationInfo?.cancelScenario.HomesCancelScenario ===
        HomesCancelScenarioEnum.FullyRefundable ||
      cancellationInfo?.cancelScenario.HomesCancelScenario ===
        HomesCancelScenarioEnum.PartiallyRefundable
    ) {
      onClickHandler = displayCancelConfirmation;
    }

    const resolvedCancellationInfo =
      cancellationInfo?.cancelScenario.HomesCancelScenario ===
      HomesCancelScenarioEnum.NonRefundable
        ? nonRefundableCancelQuote
        : cancellationInfo;

    return (
      <Box className="cancellation-info-container">
        <Typography variant="h2" className="modal-title">
          {constants.CANCEL_VACATION_RENTAL_TITLE}
        </Typography>
        {resolvedCancellationInfo?.cancelScenario.cancelCopy.body.map(
          (cancelInfo) => (
            <Typography
              variant="body2"
              dangerouslySetInnerHTML={{
                __html: cancelInfo,
              }}
            />
          )
        )}
        <Divider className="modal-divider" />
        <PropertyInfo />
        {resolvedCancellationInfo?.cancelScenario.cancelCopy.importantInfo
          .length ? (
          <Box className="info-items-container">
            <Divider className="modal-divider" />
            <ImportantInfoList
              infoItems={
                resolvedCancellationInfo?.cancelScenario.cancelCopy
                  .importantInfo
              }
              title={constants.CANCEL_INFO_TITLE}
            />
          </Box>
        ) : null}
        <Divider className="modal-divider" />
        <ActionButton
          className="cancel-button"
          defaultStyle="h4r-primary"
          message={
            resolvedCancellationInfo?.cancelScenario.cancelCopy.callToAction ??
            "Cancel vacation rental"
          }
          onClick={onClickHandler}
        />
      </Box>
    );
  };
  const renderConfirmCancellation = () => {
    const { current: cancellationInfo } = cancellationInfoRef;
    if (
      cancellationInfo?.cancelScenario &&
      "cancelConfirmationCopy" in cancellationInfo?.cancelScenario
    ) {
      return (
        <Box className="confirm-cancellation-container">
          <Typography className="modal-title">
            {cancellationInfo?.cancelScenario.cancelConfirmationCopy!.title}
          </Typography>
          {cancellationInfo?.cancelScenario.cancelConfirmationCopy!.body.map(
            (cancelInfo) => (
              <Typography variant="body2">{cancelInfo}</Typography>
            )
          )}

          <Divider className="modal-divider" />
          <PropertyInfo />
          <Divider className="modal-divider" />
          <ActionButton
            className="confirm-cancel-button"
            defaultStyle="h4r-primary"
            message={
              cancellationInfo?.cancelScenario.cancelConfirmationCopy!
                .callToAction ?? "Cancel vacation rental"
            }
            onClick={confirmCancellation}
          />
        </Box>
      );
    } else {
      return;
    }
  };

  const renderCancellationSuccess = (closeModal: () => void) => {
    return (
      <GenericModalContent
        className="homes-cancel-modal-success"
        title={generalConstants.CANCELLATION_SUCCESS_TITLE}
        subtitle={generalConstants.CANCELLATION_SUCCESS_COPY}
        image={<Icon className="success-icon" name={IconName.Checked} />}
        actions={
          <ActionButton
            defaultStyle="h4r-primary"
            message={constants.BACK_TO_TRIPS}
            onClick={closeModal}
          />
        }
      />
    );
  };

  const renderCancellationFailure = (closeModal: () => void) => {
    return (
      <Box className="cancellation-info-container">
        <Typography variant="h2" className="modal-title">
          {generalConstants.SOMETHING_WENT_WRONG}
        </Typography>
        <Typography variant="body2">
          {constants.DEFAULT_CUSTOMER_SERVICE_CANCEL_BODY}
        </Typography>
        <Divider className="modal-divider" />
        <PropertyInfo />
        <Divider className="modal-divider" />
        <ActionButton
          className="confirm-cancel-button"
          defaultStyle="h4r-primary"
          message={constants.BACK_TO_TRIPS}
          onClick={closeModal}
        />
      </Box>
    );
  };

  const ModalContent = useMemo(() => {
    switch (cancelStep) {
      case CancelStep.CancellationFailure:
        return renderCancellationFailure(() => {
          closeModal();
          window.location.reload();
        });
      case CancelStep.CancellationInfo:
        return renderCancellationInfo();
      case CancelStep.ConfirmCancellation:
        return renderConfirmCancellation();
      case CancelStep.LoadingPolicy:
        return renderLoading();
      case CancelStep.CancellationProcessing:
        return renderCancellationProcessing();
      case CancelStep.CancellationSuccess:
        return renderCancellationSuccess(() => {
          closeModal();
          history.push(
            `${PATH_HOME}?tripsFilter=Past%20Trips&tripId=${home.reservation.id.value}`
          );
          window.location.reload();
        });
      default:
        return null;
    }
  }, [cancelStep]);

  useEffect(() => {
    prepareCancellation();
  }, [prepareCancellation]);

  return <Box className="cancel-home-modal-content">{ModalContent}</Box>;
};

export default CancelHomeModalContent;
