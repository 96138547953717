import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { IStoreState } from "../../../../reducers/types";
import { PriceFreezeList } from "./component";
import {
  notPastTripEndDateCombinedPriceFreezesSortedSelector,
  getPriceFreezeAirlinesMap,
  getPriceFreezeAirportMap,
  getListHotelPriceFreezeCallState,
  getListPriceFreezeCallState,
  getTripsFilter,
  getPriceFreezeCounts,
} from "./../../reducer";

const mapStateToProps = (state: IStoreState) => ({
  notPastTripEndDateCombinedPriceFreezesSorted:
    notPastTripEndDateCombinedPriceFreezesSortedSelector(state),
  listPriceFreezeCallState: getListPriceFreezeCallState(state),
  listHotelPriceFreezeCallState: getListHotelPriceFreezeCallState(state),
  priceFreezeCounts: getPriceFreezeCounts(state),
  tripsFilter: getTripsFilter(state),
  airlines: getPriceFreezeAirlinesMap(state),
  airports: getPriceFreezeAirportMap(state),
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PriceFreezeListConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedPriceFreezeList = withRouter(connector(PriceFreezeList));
