import {
  CallState,
  DenyScheduleChangeResponse,
  UserActionScheduleChangeSuccessProperties,
  USER_ACTION_SCHEDULE_CHANGE_SUCCESS,
} from "redmond";
import { put, select } from "redux-saga/effects";
import { trackEvent } from "../../../api/v1/analytics/trackEvent";
import { denyScheduleChange } from "../../../api/v1/itinerary/denyScheduleChange";
import { actions } from "../actions";
import { getUserActionScheduleChangeSuccessProperties } from "../reducer";

export function* denyScheduleChangeSaga(action: actions.IDenyScheduleChange) {
  try {
    const response: DenyScheduleChangeResponse = yield denyScheduleChange(
      action.request
    );
    if (response.success) {
      yield put(actions.setDenyScheduleChangeCallState(CallState.Success));
      const properties: UserActionScheduleChangeSuccessProperties = yield select(
        getUserActionScheduleChangeSuccessProperties
      );
      yield trackEvent({
        eventName: USER_ACTION_SCHEDULE_CHANGE_SUCCESS,
        properties: {
          ...properties,
          user_action: "deny",
          action: "deny",
        },
      });
    } else {
      yield put(actions.setDenyScheduleChangeCallState(CallState.Failed));
    }
  } catch (e) {
    yield put(actions.setDenyScheduleChangeCallState(CallState.Failed));
    console.error(e);
  }
}
