import { HomesItineraryResponse } from "redmond";
import { put } from "redux-saga/effects";
import { fetchHomes } from "../../../api/v1/itinerary/fetchHomes";
import { actions } from "../actions";

export function* fetchHomesSaga(action: actions.IFetchHomes) {
  try {
    const homesItinerariesResponse: HomesItineraryResponse = yield fetchHomes(
      action.request
    );

    const {
      future: newFuture,
      canceled: newCanceled,
      past: newPast,
      present: newPresent,
    } = homesItinerariesResponse.itineraries;

    yield put(
      actions.setHomes(
        {
          present: newPresent ? newPresent.map((x) => x) : [],
          canceled: newCanceled ? newCanceled.map((x) => x) : [],
          past: newPast ? newPast.map((x) => x) : [],
          future: newFuture ? newFuture.map((x) => x) : [],
        },
        action.request.states
      )
    );
  } catch (e) {
    yield put(actions.fetchHomesFailed(action.request));
    console.error(e);
  }
}
