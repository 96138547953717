import dayjs from "dayjs";
import { CurrencyFormatters, isBetweenDays } from "halifax";
import {
  AirlineMap,
  BookedFlightItineraryWithDepartureTime,
  CfarContract,
  DelayContract,
  DisruptionContractStatusEnum,
  FlightItinerarySegment,
  FlightItinerarySlice,
  IPaymentAmountInfo,
  ItineraryEnum,
  ItineraryWithType,
  MissedConnectionContract,
  PaymentBreakdown,
  PaymentLineItemEnum,
  PaymentLineItemRewards,
  PaymentLineItemTravelWallet,
  TravelWalletCreditDetail,
  PaymentLineItemTravelWalletCredit,
  PaymentLineItemTravelWalletEnum,
  PaymentLineItemUserCard,
  PaymentTypeEnum,
  PortalItineraryStatusEnum,
  getDepartureSlice,
  getReturnSlice,
  PolicyDetailsEnum,
} from "redmond";

import {
  formatFiatValue,
  formatRewardsValue,
} from "../../../../../../utils/helpers";
import * as textConstants from "./constants";
import {
  DelayPolicyDetails,
  FixedPolicyDetails,
} from "redmond/trips-module/itinerary";

export const addFlightType = (
  flight: BookedFlightItineraryWithDepartureTime
): ItineraryWithType => ({ ...flight, type: ItineraryEnum.Flight });

export interface ILabel {
  cardLabel: string | null;
  rewardLabel: string | null;
}
export interface IValues {
  cardValue: string | null;
  rewardValue: string | null;
}

export interface ILineItem {
  label: string | null;
  amount: string | null;
}

export interface ILineItems {
  userCard: ILineItem | null;
  rewards: ILineItem | null;
  statementCredit: ILineItem | null;
  travelWalletOffer: ILineItem | null;
  travelWalletCredit: ILineItem[] | null;
}

export const MINIMUM_DIFFERENCE_HOURS = 24;

export const formatPaymentBreakdown = (paymentBreakdown: PaymentBreakdown) => {
  var lineItems = {} as ILineItems;
  paymentBreakdown.payments.forEach((lineItem) => {
    switch (lineItem.PaymentLineItem) {
      case PaymentLineItemEnum.UserCard:
        lineItems.userCard = getUserCard(lineItem as PaymentLineItemUserCard);
        return;
      case PaymentLineItemEnum.Rewards:
        lineItems.rewards = getRewards(lineItem as PaymentLineItemRewards);
        return;
      case PaymentLineItemEnum.TravelWallet:
        switch ((lineItem as PaymentLineItemTravelWallet).TravelWallet) {
          case PaymentLineItemTravelWalletEnum.TravelWalletOffer:
            lineItems.travelWalletOffer = getTravelWalletOffer(
              lineItem as PaymentLineItemTravelWallet
            );
            return;
          case PaymentLineItemTravelWalletEnum.TravelWalletCredit:
            const credit = lineItem as PaymentLineItemTravelWalletCredit;
            if (credit.breakdown && credit.breakdown.length > 0) {
              lineItems.travelWalletCredit = credit.breakdown.map((credit) =>
                getTravelWalletStatementCredit(credit)
              );
            } else {
              lineItems.travelWalletCredit = [getTravelWalletCredit(credit)];
            }
            return;
        }
    }
  });
  return lineItems;
};

export const getUserCard = (userCard: PaymentLineItemUserCard) => {
  return {
    label: userCard.cardNumberDisplay,
    amount: `
      ${userCard.amount.currency} 
      $${formatFiatValue(userCard.amount.amount)}
    `,
  };
};

export const getRewards = (rewards: PaymentLineItemRewards) => {
  return {
    label: rewards.accountDisplayName,
    amount: `
      ${formatRewardsValue(rewards.amount.rewardsPrice.value)}
      ${rewards.amount.rewardsPrice.currency}
    `,
  };
};

export const getTravelWalletOffer = (
  travelWalletOffer: PaymentLineItemTravelWallet
) => {
  return getTravelWallet(travelWalletOffer, textConstants.TRAVEL_OFFER_APPLIED);
};

export const getTravelWalletCredit = (
  travelWalletCredit: PaymentLineItemTravelWallet
) => {
  return getTravelWallet(
    travelWalletCredit,
    textConstants.TRAVEL_CREDIT_APPLIED
  );
};

export const getTravelWalletStatementCredit = (
  credit: TravelWalletCreditDetail
) => {
  return {
    label: !!credit.description
      ? credit.description
      : textConstants.TRAVEL_CREDIT_APPLIED,
    amount: `
      -${CurrencyFormatters.getSymbol(credit.amount.currency)}
      ${formatFiatValue(credit.amount.amount)}
    `.replace(/\s+/g, ""),
  };
};

export const getTravelWallet = (
  travelWallet: PaymentLineItemTravelWallet,
  label: string
) => {
  return {
    label: label,
    amount: `
      -${CurrencyFormatters.getSymbol(travelWallet.amount.currency)}
      ${formatFiatValue(travelWallet.amount.amount)}
    `.replace(/\s+/g, ""),
  };
};

export const getLabels = (paymentAmountInfo: IPaymentAmountInfo) => {
  const labels: ILabel = {
    cardLabel: null,
    rewardLabel: null,
  };
  switch (paymentAmountInfo?.PaymentAmountInfo) {
    case PaymentTypeEnum.FiatAmountInfo:
      labels.cardLabel = paymentAmountInfo.numberDisplay;
      break;
    case PaymentTypeEnum.SplitAmountInfo:
      labels.cardLabel = paymentAmountInfo.fiatInfo.numberDisplay;
      labels.rewardLabel = paymentAmountInfo.rewardsInfo.accountDisplayName;
      break;
    case PaymentTypeEnum.RewardsAmountInfo:
      labels.rewardLabel = paymentAmountInfo.accountDisplayName;
      break;
  }
  return labels;
};

export const getValues = (paymentAmountInfo: IPaymentAmountInfo) => {
  const values: IValues = {
    cardValue: null,
    rewardValue: null,
  };
  switch (paymentAmountInfo?.PaymentAmountInfo) {
    case PaymentTypeEnum.FiatAmountInfo:
      values.cardValue = `
        ${paymentAmountInfo.amount.currency} 
        $${formatFiatValue(paymentAmountInfo.amount.amount)}
      `;
      break;
    case PaymentTypeEnum.SplitAmountInfo:
      values.cardValue = `
        ${paymentAmountInfo.fiatInfo.amount.currency} 
        $${formatFiatValue(paymentAmountInfo.fiatInfo.amount.amount)}
      `;
      values.rewardValue = `
        ${formatRewardsValue(
          paymentAmountInfo.rewardsInfo.amount.rewardsPrice.value
        )} 
        ${paymentAmountInfo.rewardsInfo.amount.rewardsPrice.currency}
      `;
      break;
    case PaymentTypeEnum.RewardsAmountInfo:
      values.rewardValue = `
        ${formatRewardsValue(paymentAmountInfo.amount.rewardsPrice.value)}
        ${paymentAmountInfo.amount.rewardsPrice.currency}
      `;
      break;
  }
  return values;
};

export const hasCfarExpired = (cfar: CfarContract | undefined): boolean => {
  return !!cfar && dayjs().isAfter(dayjs(cfar.expired));
};

export const isFlightCanceled = (
  flight: BookedFlightItineraryWithDepartureTime
) => {
  return flight.status === PortalItineraryStatusEnum.Canceled;
};

export const getIsDpExercisedViaRebook = (
  delay: DelayContract | undefined,
  missedConnection: MissedConnectionContract | undefined
) =>
  (!!delay &&
    delay.status?.DisruptionContractStatus ===
      DisruptionContractStatusEnum.Rebook) ||
  (!!missedConnection &&
    missedConnection.status?.DisruptionContractStatus ===
      DisruptionContractStatusEnum.Rebook);

export const getIsDpExercisedViaRefund = (
  delay: DelayContract | undefined,
  missedConnection: MissedConnectionContract | undefined
) =>
  (!!delay &&
    delay.status?.DisruptionContractStatus ===
      DisruptionContractStatusEnum.Refund) ||
  (!!missedConnection &&
    missedConnection.status?.DisruptionContractStatus ===
      DisruptionContractStatusEnum.Refund);

export const getSliceSeatsLinks = (
  airlineMap: AirlineMap,
  slice?: FlightItinerarySlice
) => {
  const seatLinks: Record<string, string> = {};

  slice?.segments.forEach((seg) => {
    const airline = airlineMap[seg.marketingAirline.code];

    if (airline) {
      const {
        displayName,
        webLinks: { homePage, manageBooking, seatSelection },
      } = airline;
      const link = seatSelection || manageBooking || homePage || "";

      seatLinks[displayName] = link;
    }
  });

  return seatLinks;
};

const getHoursBeforeDeparture = (currentDate: Date, departureDate: Date) =>
  dayjs(departureDate).diff(currentDate, "hour");

const getHoursAfterArrival = (currentDate: Date, departureDate: Date) =>
  dayjs(currentDate).diff(departureDate, "hour");

const getSegmentTimes = (segments: FlightItinerarySegment[]) => {
  const today = new Date();
  const firstDepartureSegment = segments[0];
  const lastDepartureSegment = segments[segments.length - 1];
  const firstDepartureTime = dayjs
    .tz(
      firstDepartureSegment.scheduledDeparture,
      firstDepartureSegment.originTimeZone
    )
    .toDate();
  const lastArrivalTime = dayjs
    .tz(
      lastDepartureSegment.scheduledArrival,
      lastDepartureSegment.destinationTimeZone
    )
    .toDate();
  const hoursBeforeFirstDeparture = Math.abs(
    getHoursBeforeDeparture(today, firstDepartureTime)
  );
  const hoursAfterLastArrival = Math.abs(
    getHoursAfterArrival(today, lastArrivalTime)
  );
  const isBetweenSegments = isBetweenDays(
    today,
    firstDepartureTime,
    lastArrivalTime
  );
  return {
    hoursBeforeFirstDeparture,
    isBetweenSegments,
    hoursAfterLastArrival,
  };
};

export function sliceMeets24hConditions(
  slice: FlightItinerarySlice,
  isDisruptionProtection24hRuleEnabled: boolean,
  isAgentPortal: boolean | undefined
): boolean {
  /** Missed Connection coverage only applies to slices with two or more segments and where there is a self transfer */
  const hasSelfTransfer = isFlightMultiTicketType(slice);
  if (
    isDisruptionProtection24hRuleEnabled &&
    slice.segments.length >= 2 &&
    hasSelfTransfer
  ) {
    const departureHoursData = getSegmentTimes(slice.segments);

    // Agents get to bypass the 24h rule
    if (isAgentPortal) {
      return true;
    } else {
      return (
        departureHoursData.hoursBeforeFirstDeparture <=
          MINIMUM_DIFFERENCE_HOURS ||
        departureHoursData.isBetweenSegments ||
        departureHoursData.hoursAfterLastArrival <= MINIMUM_DIFFERENCE_HOURS
      );
    }
  } else {
    return false;
  }
}

/**
 * This function is used to determine whether a booked flight itinerary meets the 24-hour conditions
 *
 * @todo Should this be named "itineraryMeets24hConditions" since it's checking the whole itinerary instead of a single segment?
 */
export const flightMeetsMcpConditions = (
  flight: BookedFlightItineraryWithDepartureTime,
  isDisruptionProtection24hRuleEnabled: boolean,
  isAgentPortal: boolean | undefined,
  setSliceSelectedForMcpVi?: (val: number) => void
) => {
  const status =
    flight?.ancillaries?.missedConnectionVi?.status.DisruptionContractStatus;
  // if c1-fintech-disruption-24-hours-rule is enabled, we check the whole logic for 24 block
  if (isDisruptionProtection24hRuleEnabled) {
    const departureSlice = getDepartureSlice(flight.bookedItinerary);
    const returnSlice = getReturnSlice(flight.bookedItinerary);

    if (
      status == DisruptionContractStatusEnum.Purchase &&
      sliceMeets24hConditions(
        departureSlice,
        isDisruptionProtection24hRuleEnabled,
        isAgentPortal
      )
    ) {
      setSliceSelectedForMcpVi && setSliceSelectedForMcpVi(0);
      return true;
    }

    if (
      returnSlice &&
      (status == DisruptionContractStatusEnum.Purchase ||
        status == DisruptionContractStatusEnum.Rebook) &&
      sliceMeets24hConditions(
        returnSlice,
        isDisruptionProtection24hRuleEnabled,
        isAgentPortal
      )
    ) {
      setSliceSelectedForMcpVi && setSliceSelectedForMcpVi(1);
      return true;
    }

    return false;
  } else {
    // When flag is disable we set the first slice, for testing purposes
    setSliceSelectedForMcpVi && setSliceSelectedForMcpVi(0);
    return true;
  }
};

export const airlinesCountFlightItinerarySegment = (
  segmentDetails: FlightItinerarySegment[]
) => {
  const set = new Set(
    (segmentDetails ?? []).map((segment) => segment.marketingAirline.code)
  );
  return set.size - 1;
};

export const isFlightMultiTicketType = (slice: FlightItinerarySlice) =>
  slice.segments.some((s) => s?.isSelfTransferLayover);

export const getDP = (flight: BookedFlightItineraryWithDepartureTime) => {
  return flight?.ancillaries?.delay ?? flight?.ancillaries?.missedConnection;
};

export const hasDP = (flight: BookedFlightItineraryWithDepartureTime) => {
  return getDP(flight) !== undefined;
};

type PostBookingContract =
  | ((DelayContract | MissedConnectionContract) & {
      policyDetails?: DelayPolicyDetails | FixedPolicyDetails;
      postBookingPaymentId: string;
      formattedTotal: string;
    })
  | undefined;

export const getDPPostBookking = (
  flight: BookedFlightItineraryWithDepartureTime
): PostBookingContract => {
  const delay = flight?.ancillaries?.delay;
  if (delay?.postBookingPaymentId) {
    const total =
      (delay.policyDetails?.premium?.amount ?? 0) * (delay.paxCount ?? 1);
    return {
      ...delay,
      postBookingPaymentId: delay.postBookingPaymentId,
      formattedTotal: total.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
    };
  }
  const dp = flight?.ancillaries?.missedConnection;
  if (!dp?.postBookingPaymentId) return undefined;
  switch (dp?.policyDetails?.PolicyDetails) {
    case PolicyDetailsEnum.FixedPolicyDetails:
      const total = dp.policyDetails.premium.amount * (dp.paxCount ?? 1);
      return {
        ...dp,
        policyDetails: dp.policyDetails,
        postBookingPaymentId: dp.postBookingPaymentId,
        formattedTotal: total.toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
      };
    case PolicyDetailsEnum.PercentagePolicyDetails:
      return undefined;
    default:
      return {
        ...dp,
        policyDetails: dp.policyDetails,
        postBookingPaymentId: dp.postBookingPaymentId,
        formattedTotal: "",
      };
  }
};
