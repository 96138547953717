import { all, call, put } from "redux-saga/effects";
import fetchCorpUsersList, {
  CorpUsersListResponse,
} from "b2b-base/src/api/v1/user/fetchCorpUsersList";
import { buildCorpTravelers } from "b2b-base/src/utils/capone-corporate/travelersMappingUtils";
import { CallState, CorpSessionInfo, IPerson, SessionInfo } from "redmond";
import fetchUserInfo from "../../../../api/v1/user/fetchUserInfo";
import fetchUserPassengers from "../../../../api/v1/user/fetchUserPassengers";
import { setUserPassengers, setUserPassengersCallState } from "../../reducer";

export function* fetchCorpUserPassengersSaga() {
  try {
    const sessionInfo: SessionInfo = yield call(fetchUserInfo);
    const canViewUsers =
      "corporateInfo" in sessionInfo
        ? (sessionInfo as CorpSessionInfo).corporateInfo.permissions
            .canViewUsers
        : false;
    const travelerDefaultTravelerId =
      ("corporateInfo" in sessionInfo &&
        (sessionInfo as CorpSessionInfo).corporateInfo.defaultTravelerId) ??
      "";
    const [corpUsersList, userPassengers]: [CorpUsersListResponse, IPerson[]] =
      yield all([
        canViewUsers
          ? call(fetchCorpUsersList)
          : {
              users: [
                {
                  id: sessionInfo.userId,
                  firstName: sessionInfo.userInfo.firstName,
                  lastName: sessionInfo.userInfo.lastName,
                  defaultTravelerId: travelerDefaultTravelerId,
                },
              ],
            },
        call(fetchUserPassengers),
      ]);

    const hiddenTravelerIds =
      "corporateInfo" in sessionInfo
        ? (sessionInfo as CorpSessionInfo).corporateInfo.hiddenTravelerIds
        : [];

    const loggedInUserId = sessionInfo.userId;

    const corpUsersPayload = buildCorpTravelers(
      userPassengers,
      corpUsersList.users,
      hiddenTravelerIds,
      loggedInUserId
    );

    yield put(setUserPassengers(corpUsersPayload));
  } catch (e) {
    yield put(setUserPassengersCallState(CallState.Failed));
  }
}
