import dayjs from "dayjs";
import React from "react";
import { HotelItinerary } from "redmond";

export const CHECK_IN = "Check in";
export const CHECK_IN_ON = "Check in on";
export const CHECK_OUT = "Check out";
export const CHECKED_IN = "Checked in on";
export const CONFIRMATION = "Confirmation:";
export const HOTEL_HEADER_TEXT = "Hotel";
export const PREMIER_COLLECTION_HEADER_TEXT = "Premier Collection Hotel";
export const LIFESYLE_COLLECTION_HEADER_TEXT = "Lifestyle Collection Hotel";
export const VIEW_DETAILS = "View details";
export const VIEW_LESS = "View less";
export const DATE_FORMAT = "ddd, MMM D";
export const CANCELED = "Canceled";
export const CANCELED_RES = "This reservation has been canceled.";

export const CANCEL_FOR_ANY_REASON = "Cancel for Any Reason";

export const getHeaderText = (
  checkInDate?: string,
  checkOutDate?: string,
  isLuxuryCollection?: boolean,
  isLifestyleCollection?: boolean
) => {
  return checkInDate ? (
    <>
      <strong>
        {isLuxuryCollection
          ? PREMIER_COLLECTION_HEADER_TEXT
          : isLifestyleCollection
          ? LIFESYLE_COLLECTION_HEADER_TEXT
          : HOTEL_HEADER_TEXT}
      </strong>{" "}
      <span className="separator">|</span>{" "}
      {dayjs(checkInDate).format(DATE_FORMAT)} -{" "}
      {dayjs(checkOutDate).format(DATE_FORMAT)}
    </>
  ) : (
    <strong>
      {isLuxuryCollection
        ? PREMIER_COLLECTION_HEADER_TEXT
        : isLifestyleCollection
        ? LIFESYLE_COLLECTION_HEADER_TEXT
        : HOTEL_HEADER_TEXT}
    </strong>
  );
};

export const getHotelInfoDetails = (
  { reservation }: HotelItinerary,
  nameAsTitle?: boolean
) => {
  const { lodgingData } = reservation;
  return {
    title: nameAsTitle
      ? lodgingData?.name
      : lodgingData
      ? `${lodgingData.city}, ${
          lodgingData.state?.code?.trim() || lodgingData.country
        }`
      : "",
    confirmationCode: `H-${reservation.reservationId}`,
    startDate: dayjs(reservation.checkInDate).format(DATE_FORMAT),
    endDate: dayjs(reservation.checkOutDate).format(DATE_FORMAT),
    subtitle: `${lodgingData?.city ? `${lodgingData?.city}, ` : ""}${
      lodgingData?.country || ""
    }`,
  };
};
