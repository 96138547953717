import React from "react";
import {
  Airline,
  BookedFlightItineraryWithDepartureTime,
  MultiTravelItinerary,
  SingleTravelItinerary,
  TravelItineraryEnum,
  MultiTicketTypeEnum,
} from "redmond";
import { Typography, Box, Button } from "@material-ui/core";
import clsx from "clsx";
import { uniqWith, isEqual } from "lodash-es";

import { CheckInFlightModalContentConnectorProps } from "./container";
import "./styles.scss";
import { copyTextToClipboardPromise } from "../ConfirmationModalContent/helpers";
import * as textConstants from "./constants";
import { Icon, IconName, BannerSeverity, NotificationBanner } from "halifax";
interface ICheckInFlightModalContentProps
  extends CheckInFlightModalContentConnectorProps {
  flight: BookedFlightItineraryWithDepartureTime;
}

export interface IConfirmationNumber {
  label: string;
  locator: string;
  redirectUrl: string;
}

const onCopyAndGo = async (locator: string, redirectUrl: string) => {
  await copyTextToClipboardPromise(locator);
  window.open(redirectUrl, "_blank");
};

const filterDuplicates = (confirmationNumbers: IConfirmationNumber[]) =>
  uniqWith(confirmationNumbers, isEqual);

export const getConfirmationNumbers = ({
  flight,
  airlineMap,
}: {
  flight: BookedFlightItineraryWithDepartureTime;
  airlineMap: { [key: string]: Airline };
}): IConfirmationNumber[] => {
  const { bookedItinerary } = flight;
  const { travelItinerary } = bookedItinerary;

  const confirmationNumbers: IConfirmationNumber[] = [];

  if (
    travelItinerary.TravelItinerary ===
    TravelItineraryEnum.SingleTravelItinerary
  ) {
    const singleTravelItinerary = travelItinerary as SingleTravelItinerary;
    singleTravelItinerary.slices.forEach((slice, sliceIndex) => {
      slice.segments.forEach((segment) => {
        const confirmationNumber = textConstants.getConfirmationNumber({
          segment,
          airlineMap,
          isReturn: sliceIndex !== 0,
        });
        if (confirmationNumber) {
          confirmationNumbers.push(confirmationNumber);
        }
      });
    });
  }
  if (
    travelItinerary.TravelItinerary === TravelItineraryEnum.MultiTravelItinerary
  ) {
    const multipleTravelItinerary = travelItinerary as MultiTravelItinerary;

    if (multipleTravelItinerary.locators?.children) {
      multipleTravelItinerary.travelItineraries.forEach((itinerary) => {
        itinerary.slices.forEach((slice, sliceIndex) => {
          slice.segments.forEach((segment) => {
            const confirmationNumber = textConstants.getConfirmationNumber({
              segment,
              airlineMap,
              isReturn: sliceIndex !== 0,
            });
            if (confirmationNumber) {
              confirmationNumbers.push(confirmationNumber);
            }
          });
        });
      });
    }
  }
  return confirmationNumbers;
};

export const CheckInFlightModalContent = ({
  flight,
  airlineMap,
}: ICheckInFlightModalContentProps) => {
  const confirmationNumbers = filterDuplicates(
    getConfirmationNumbers({ flight, airlineMap })
  );
  const { bookedItinerary } = flight;
  const renderConfirmationNumbers = () => {
    return (
      <Box className="checkin-flight-container">
        {confirmationNumbers.map(({ label, locator, redirectUrl }, index) => (
          <Box
            key={`${locator}-${index}`}
            className={clsx("checkin-flight-details-container", {
              "container-border": index !== confirmationNumbers.length - 1,
            })}
          >
            <Box className="checkin-flight-details">
              <Typography className="checkin-flight-label" variant="body2">
                {label}
              </Typography>
              <Typography variant="body2" className="checkin-flight-locator">
                {locator}
              </Typography>
            </Box>
            <Button
              className="checkin-flight-copy-container"
              onClick={() => onCopyAndGo(locator, redirectUrl)}
              aria-label="Copy and Go"
            >
              <Typography variant="body2" className="copy-text">
                {textConstants.COPY_AND_GO}
              </Typography>
              <Icon className="copy-icon" name={IconName.ExternalLinkIcon} />
            </Button>
          </Box>
        ))}
      </Box>
    );
  };

  return (
    <Box className="checkin-flight-modal-content">
      <Box className="checkin-flight-title-container">
        <Typography variant="h4">
          {`${textConstants.CONFIRMATION_TITLE}`}
        </Typography>
        <Typography variant="body2" className="checkin-flight-text">
          {textConstants.MODAL_SUBTITLE}
        </Typography>
        {bookedItinerary.multiTicketType === MultiTicketTypeEnum.HackerFare && (
          <NotificationBanner
            className="status-banner"
            strongLabel={textConstants.NOTIFICATION_STRONG_LABEL}
            label={textConstants.NOTIFICATION_LABEL}
            severity={BannerSeverity.WARNING}
          />
        )}
      </Box>
      {renderConfirmationNumbers()}
    </Box>
  );
};
