import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../../../../../reducers/types";
import { MobilePackageHotelDetails } from "./component";
import {
  getSelectedPackage,
  getSelectedPackageHotel,
  getTripsFilter,
} from "../../../../reducer";
import {
  setOpenModal,
  setSelectedPackageHotel,
} from "../../../../actions/actions";
import { withRouter } from "react-router";
const mapStateToProps = (state: IStoreState) => ({
  packageHotel: getSelectedPackageHotel(state),
  tripsFilter: getTripsFilter(state),

  packageItinerary: getSelectedPackage(state),
});

const mapDispatchToProps = {
  setOpenModal,
  setSelectedPackageHotel,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MobilePackageHotelDetailsConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedMobilePackageHotelDetails = connector(
  withRouter(MobilePackageHotelDetails)
);
