import axios from "axios";
import {
  CancellationInfoRequest,
  HomesCancelAgentFullfillResponse,
} from "redmond";

import { config } from "../../config";
import { agentCancelHomeFulfillPath } from "../paths";
import {
  homesApi,
  HomesCancelFulfillmentPollRequest,
  HomesCancelFulfillmentPollResponse,
  HomesCancelFulfillRequest,
  HomesCancelFulfillResponse,
} from "@b2bportal/homes-api";

/**
 * @description sends cancellation request to agent queue to complete a manual cancellation
 * @param {CancellationInfoRequest} req Contains the customer reservation id
 * @return {HomesCancelAgentFullfillResponse} The response for agent cancel fulfillment
 */
export const agentConfirmHomeFulfill = (
  req: CancellationInfoRequest
): Promise<HomesCancelAgentFullfillResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await axios.post(agentCancelHomeFulfillPath, req, {
        baseURL: config.baseURL,
      });

      resolve(res.data);
    } catch (e) {
      reject(e);
    }
  });

/**
 * @description self-service cancel a home reservation using an in-policy cancellation quote.
 * @param req
 */
export const selfServeHomesCancelFulfill = (
  req: HomesCancelFulfillRequest
): Promise<HomesCancelFulfillResponse> => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await homesApi(axios).apiV0SelfserveHomesCancelFulfillPost(
        req,
        {
          baseURL: config.baseURL,
        }
      );
      resolve(res.data);
    } catch (e) {
      reject(e);
    }
  });
};

/**
 * @description poll the cancel fulfillment status
 * @param req
 */
export const selfServeHomesCancelFulfillPoll = (
  req: HomesCancelFulfillmentPollRequest
): Promise<HomesCancelFulfillmentPollResponse> => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await homesApi(
        axios
      ).apiV0SelfserveHomesCancelFulfillmentPollPut(req, {
        baseURL: config.baseURL,
      });
      resolve(res.data);
    } catch (e) {
      reject(e);
    }
  });
};
