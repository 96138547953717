import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "@material-ui/core";
import {
  ActionButton,
  ActionLink,
  B2BSpinnerWithText,
  FlightCancellationInfo,
  FlightSummaryPanel,
  GenericModalContent,
  IFlightSummaryPanelProps,
  Icon,
  IconName,
  formatInterval,
  removeTimezone,
} from "halifax";
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { isEmpty } from "lodash-es";
import { useDispatch, useSelector } from "react-redux";
import {
  BookedFlightItineraryWithDepartureTime,
  CancelScenarioEnum,
  CancellationInfoRequest,
  CancellationResponse,
  ExperimentVariant,
  FlightCancelInfoResponse,
  FlightCancellationRequest,
  NonCfarEnum,
  Policies,
  SelfServeEvents,
  getDepartureSlice,
} from "redmond";

import { trackEvent } from "../../../../../../../../api/v1/analytics/trackEvent";
import confirmFlightCancellation from "../../../../../../../../api/v1/itinerary/confirmFlightCancellation";
import getFlightCancellationInfoV3 from "../../../../../../../../api/v1/itinerary/getFlightCancellationInfoV3";
import {
  ActiveExperiments,
  useExperiment,
} from "../../../../../../../../context/experiments";
import {
  getSelfServeAirCancelProperties,
  getTrackingNonCfar,
} from "../../../../../../../../utils/events";
import { CONTACT_SUPPORT_URL } from "../../../../../../../../utils/paths";
import { setOpenModal } from "../../../../../../actions/actions";
import { getAirlinesMap, getAirportMap } from "../../../../../../reducer";
import { isTFBooking, isTravaBooking } from "../../../../../../utils";
import { ItinerarySummary } from "../../../ItinerarySummary";
import { BACK_TO_MY_TRIPS } from "../../constants";
import { buttonText, cancelTitles } from "./constants";
import { SelectFlightInfo } from "../SelectFlightInfo";
import {
  getAllSlicesFromTravelItinerary,
  getPlusDays,
  isMultiCityItinerary,
} from "redmond/trips-module/itinerary";
import clsx from "clsx";
import dayjs from "dayjs";

import "./styles.scss";
import { isCorpTenant } from "@capone/common";
import { config } from "../../../../../../../../api/config";

const cancelPolicyTimeout = 30000;

export enum CancelStep {
  CancellationError,
  CancellationPending,
  CancellationFlowComplete,
  CancellationInfo,
  CancellationHfv2ExtraStep,
  ConfirmCancellation,
  LoadingOrProcessing,
}

export interface ISelfServeCancelFlightModalContentProps {
  flight: BookedFlightItineraryWithDepartureTime;
}
const findPolicy = (
  id: string,
  policies?: Policies[]
): Policies | undefined => {
  if (!policies) return undefined;
  return policies.find((p) => p.agentLocator.unscopedValue === id);
};

/**
 * @description Dynamically render flight cancellation states
 * @param {ISelfServeCancelFlightModalContentProps} props
 * @return {JSX.Element}
 */
const SelfServeCancelFlightModalContent = (
  props: ISelfServeCancelFlightModalContentProps
) => {
  const { flight } = props;
  const dispatch = useDispatch();
  const forceCSExp = useExperiment(ActiveExperiments.SelfServeFlightForceCS);
  const forceTravaCSExp = useExperiment(
    ActiveExperiments.SelfServeFlightForceCSTrava
  );
  const tfCancelExp = useExperiment(
    ActiveExperiments.SelfServeFlightTFCS,
    ExperimentVariant.CONTROL
  );

  const cancelationInfoRef = useRef<FlightCancelInfoResponse>();
  const policyTimeoutRef = useRef<ReturnType<typeof setTimeout>>();
  const disclaimerRef = useRef("");
  const infoItemsRef = useRef<string[]>([]);
  const subtitlesRef = useRef<string | string[]>(cancelTitles.LOADING);
  const tcHelpTextRef = useRef<string[]>([]);
  const titleRef = useRef<ReactNode>("");
  const airlineMap = useSelector(getAirlinesMap);
  const airportMap = useSelector(getAirportMap);
  const [cancelStep, setCancelStep] = useState<CancelStep>(
    CancelStep.LoadingOrProcessing
  );
  const [isTimedOut, setIsTimedOut] = useState(false);

  const [checked, setChecked] = useState("");
  const [outgoingChecked, setOutgoingChecked] = useState(false);
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    isOutbound: boolean
  ) => {
    const { target } = e;
    setChecked(target.value);
    setOutgoingChecked(isOutbound);
  };

  const ItinerarySummaryRow = useMemo(() => {
    if (isMultiCityItinerary(flight.bookedItinerary, airportMap)) {
      let slices = getAllSlicesFromTravelItinerary(
        flight.bookedItinerary.travelItinerary
      );

      const getReviewCardHeaderTextMultiCity = (
        sliceIndex: number,
        date: string,
        origin: string,
        destination: string,
        originCode?: string,
        destinationCode?: string
      ) => ({
        bold: `Flight ${sliceIndex + 1}: ${origin}${
          originCode ? ` (${originCode})` : ""
        } to ${destination}${destinationCode ? ` (${destinationCode})` : ""}`,
        standard: `on ${dayjs(date).format("ddd, MMM D")}`,
      });

      const getStopsString = (stops: number) =>
        stops === 0 ? "Nonstop" : stops > 1 ? `${stops} stops` : "1 stop";

      const getMultiCityFlightDetails = () => {
        return slices.map((slice, sliceIndex) => {
          const segments = slice.segments;
          const departureTime = segments[0]?.scheduledDeparture;
          const arrivalTime = segments[segments.length - 1].scheduledArrival;
          const originCode = segments[0]?.origin.locationCode;
          const destinationCode =
            segments[segments.length - 1].destination.locationCode;
          const stops = segments.length - 1;
          //TODO this should come from the backend
          const totalDurationMinutes = dayjs(
            segments[segments.length - 1].scheduledArrival
          ).diff(dayjs(segments[0]?.scheduledDeparture), "minute");
          const { bold, standard } = getReviewCardHeaderTextMultiCity(
            sliceIndex,
            removeTimezone(departureTime),
            airportMap[originCode]
              ? airportMap[originCode].cityName
              : originCode,
            airportMap[destinationCode]
              ? airportMap[destinationCode].cityName
              : destinationCode,
            originCode,
            destinationCode
          );

          let airlineCode = segments[0]?.marketingAirline.code;
          return {
            className: "multi-city-cancel",
            airlineCode: airlineCode,
            airline: airlineMap[airlineCode]?.displayName || airlineCode,
            departureDescriptionBold: bold,
            departureDescription: standard,
            formattedDepartureTime: dayjs(removeTimezone(departureTime)).format(
              "h:mm A"
            ),
            formattedArrivalTime: dayjs(removeTimezone(arrivalTime)).format(
              "h:mm A"
            ),
            destinationCode: destinationCode,
            duration: formatInterval(totalDurationMinutes || 0),
            stopString: getStopsString(stops),
            plusDays: getPlusDays(slice),
            isMobile: false,
          } as IFlightSummaryPanelProps;
        });
      };
      return (
        <Box className={clsx("flight-details", "multi-city")}>
          {getMultiCityFlightDetails().map((slice) => {
            return <FlightSummaryPanel {...slice} />;
          })}
        </Box>
      );
    }

    if (checked) {
      return (
        <div className="itinerary-data-row">
          <ItinerarySummary
            isOutgoing={outgoingChecked}
            airlineMap={airlineMap}
            airportMap={airportMap}
            className="outgoing-itinerary"
            flight={flight}
          />
        </div>
      );
    }

    return (
      <div className="itinerary-data-row">
        <ItinerarySummary
          isOutgoing
          airlineMap={airlineMap}
          airportMap={airportMap}
          className="outgoing-itinerary"
          flight={flight}
        />
        <ItinerarySummary
          airlineMap={airlineMap}
          airportMap={airportMap}
          className="return-itinerary"
          flight={flight}
        />
      </div>
    );
  }, [flight, checked]);

  const clearPolicyTimeout = () => {
    const { current: policyTimeout } = policyTimeoutRef;

    if (policyTimeout) {
      clearTimeout(policyTimeout);
      setIsTimedOut(false);
      policyTimeoutRef.current = undefined;
    }
  };

  /**
   * @description Updates the redux store to close this modal
   */
  const closeModal = () => {
    const closedModal = { type: null, selectedItinerary: null };

    clearPolicyTimeout();
    dispatch(setOpenModal(closedModal));
  };

  /**
   * @description Step 2 of the self serve cancel flow on the BE.
   * Confirms the cancellation of the flight.
   */
  const confirmCancellation = () => {
    const { current: cancellationInfo } = cancelationInfoRef;

    clearPolicyTimeout();

    if (cancellationInfo) {
      const req: FlightCancellationRequest = {
        cancelScenario: Boolean(checked)
          ? {
              ...cancellationInfo,
              policies: cancellationInfo?.policies?.filter(
                (p) => p.agentLocator.unscopedValue === checked
              ),
            }
          : cancellationInfo,
        itineraryId: flight.bookedItinerary.id,
      };

      titleRef.current = cancelTitles.PROCESSING;
      subtitlesRef.current = cancelTitles.PROCESSING_SUBTITLE;
      setCancelStep(CancelStep.LoadingOrProcessing);

      trackEvent({
        eventName: SelfServeEvents.ConfirmCancellation,
        properties: getSelfServeAirCancelProperties(cancellationInfo, flight),
      });

      confirmFlightCancellation(req, flight)
        .then((cancelResponse: CancellationResponse) => {
          const { body, title } = cancelResponse.copy!;

          titleRef.current = title;
          subtitlesRef.current = body;

          setCancelStep(CancelStep.CancellationFlowComplete);
        })
        .catch((err: string[]) => {
          titleRef.current = cancelTitles.CANCEL_FAILED;
          subtitlesRef.current = err;

          setCancelStep(CancelStep.CancellationError);
        });
    }
  };

  const contactCustomerSupport = useCallback(() => {
    const { current: cancelationInfo } = cancelationInfoRef;

    if (cancelationInfo) {
      const { CancelScenario, NonCfar } = cancelationInfo;

      trackEvent({
        eventName: SelfServeEvents.ClickSupport,
        properties: {
          cancel_scenario: CancelScenario ?? "",
          non_cfar: getTrackingNonCfar(NonCfar) ?? "",
          product: "flight",
        },
      });
    }

    // open cap1 support page in a new tab
    window.open(CONTACT_SUPPORT_URL, "_blank")?.focus();
  }, []);

  const handleCancelationHfv2 = useCallback((selectedPolicy: Policies) => {
    clearPolicyTimeout();
    infoItemsRef.current = selectedPolicy.cancelCopy?.importantInfo ?? [];
    subtitlesRef.current = selectedPolicy.cancelCopy?.body;
    titleRef.current = selectedPolicy.cancelCopy?.title;
    policyTimeoutRef.current = setTimeout(() => {
      setCancelStep(CancelStep.CancellationInfo);
      setIsTimedOut(true);
    }, cancelPolicyTimeout);
    switch (selectedPolicy.policy) {
      // Cases where flow should reuse renderCancellationInfo
      case NonCfarEnum.ContactAirline:
      case NonCfarEnum.RefundableComplex:
      case NonCfarEnum.TicketlessVoid:
      case NonCfarEnum.NonCancellable:
        // Copied functionality from rendercancellationInfo
        setCancelStep(CancelStep.CancellationHfv2ExtraStep);
        break;

      case NonCfarEnum.ContactCustomerService:
        // contact customer service
        const { customerServiceCopy } = selectedPolicy;

        if (customerServiceCopy) {
          if (customerServiceCopy?.disclaimer) {
            disclaimerRef.current = customerServiceCopy.disclaimer;
          }
          infoItemsRef.current = customerServiceCopy.importantInfo ?? [];
          subtitlesRef.current = customerServiceCopy?.body;
          titleRef.current = customerServiceCopy?.title;
        }
        setCancelStep(CancelStep.CancellationHfv2ExtraStep);
        break;

      case NonCfarEnum.CancellationPending:
        titleRef.current = cancelTitles.PENDING_CANCELLATION;
        subtitlesRef.current = cancelTitles.BOOKING_CANCELLED;
        setCancelStep(CancelStep.CancellationPending);
        break;

      // Cases that are avail for cancellation
      case NonCfarEnum.AirlineRefund:
      case NonCfarEnum.FTC:
      case NonCfarEnum.FTCWithPenalty:
      case NonCfarEnum.MultiTicket:
      case NonCfarEnum.NonRefundable:
      case NonCfarEnum.PartialRefund:
      case NonCfarEnum.RefundableComplex:
      case NonCfarEnum.TicketedVoid:
        setCancelStep(CancelStep.ConfirmCancellation);
        break;
      default:
        subtitlesRef.current = cancelTitles.GET_INFO_ERROR;
        setCancelStep(CancelStep.CancellationError);
    }
  }, []);

  const displayCancelConfirmation = useCallback(() => {
    clearPolicyTimeout();

    const { current: cancellationInfo } = cancelationInfoRef;

    if (cancellationInfo) {
      const { cancelConfirmationCopy, NonCfar } = cancellationInfo;

      trackEvent({
        eventName: SelfServeEvents.ClickCancel,
        properties: getSelfServeAirCancelProperties(cancellationInfo, flight),
      });

      if (cancelConfirmationCopy) {
        const { body, title } = cancelConfirmationCopy;

        titleRef.current = title;
        subtitlesRef.current = body;

        setCancelStep(CancelStep.ConfirmCancellation);

        policyTimeoutRef.current = setTimeout(() => {
          setCancelStep(CancelStep.CancellationInfo);
          setIsTimedOut(true);
        }, cancelPolicyTimeout);
      }

      if (NonCfar === NonCfarEnum.MultiProvider) {
        titleRef.current = cancelTitles.CONFIRMATION;
        subtitlesRef.current = [cancelTitles.COMPLETE_CANCELLATION_FLIGHT];

        setCancelStep(CancelStep.ConfirmCancellation);

        policyTimeoutRef.current = setTimeout(() => {
          setCancelStep(CancelStep.CancellationInfo);
          setIsTimedOut(true);
        }, cancelPolicyTimeout);
      }
    }
  }, []);

  /**
   * @description Step 1 of the self serve cancel flow on the BE.
   * Requests the cancel info of the flight to be shown to the
   * user.
   */
  const getCancelInfo = useCallback(
    (itineraryId: string) => {
      const req: CancellationInfoRequest = {
        itineraryId,
      };

      titleRef.current = "";
      subtitlesRef.current = cancelTitles.LOADING;
      setCancelStep(CancelStep.LoadingOrProcessing);

      getFlightCancellationInfoV3(req)
        .then((cancellationInfo: FlightCancelInfoResponse) => {
          const { CancelScenario, NonCfar, copy } = cancellationInfo;
          let body, title;

          cancelationInfoRef.current = cancellationInfo; // save this off for confirm request

          trackEvent({
            eventName: SelfServeEvents.ViewedCancelModal,
            properties: getSelfServeAirCancelProperties(
              cancellationInfo,
              flight
            ),
          });

          switch (CancelScenario) {
            case CancelScenarioEnum.CancellationPending:
              titleRef.current = cancelTitles.PENDING_CANCELLATION;
              subtitlesRef.current = cancelTitles.BOOKING_CANCELLED;
              setCancelStep(CancelStep.CancellationPending);
              break;
            case CancelScenarioEnum.AirlineControl:
              // flight is within 3 hours - contact airline
              if (copy) {
                ({ body, title } = copy);

                titleRef.current = title;
                subtitlesRef.current = body;
                setCancelStep(CancelStep.CancellationError);
              }
              break;
            case CancelScenarioEnum.Departed:
              // flight is in the past
              titleRef.current = cancelTitles.DEPARTED_TITLE;
              subtitlesRef.current = cancelTitles.DEPARTED_SUBTITLE;
              setCancelStep(CancelStep.CancellationError);
              break;
            case CancelScenarioEnum.NonCfar:
              const { cancelCopy, customerServiceCopy } = cancellationInfo;
              let disclaimer, importantInfo, informativeSection;

              switch (NonCfar) {
                case NonCfarEnum.ContactCustomerService:
                  // contact customer service
                  if (customerServiceCopy) {
                    ({ body, disclaimer, importantInfo, title } =
                      customerServiceCopy);

                    if (disclaimer) {
                      disclaimerRef.current = disclaimer;
                    }

                    infoItemsRef.current = importantInfo;
                    subtitlesRef.current = body;
                    titleRef.current = title;
                  }
                  break;
                case NonCfarEnum.AirlineRefund:
                case NonCfarEnum.FTC:
                case NonCfarEnum.FTCWithPenalty:
                case NonCfarEnum.MultiTicket:
                case NonCfarEnum.NonRefundable:
                case NonCfarEnum.PartialRefund:
                case NonCfarEnum.RefundableComplex:
                case NonCfarEnum.TicketedVoid:
                default:
                  // self serve flight cancel
                  if (cancelCopy) {
                    ({
                      body,
                      disclaimer,
                      importantInfo,
                      informativeSection,
                      title,
                    } = cancelCopy);

                    infoItemsRef.current = importantInfo;
                    subtitlesRef.current = body;
                    titleRef.current = title;

                    if (disclaimer) {
                      disclaimerRef.current = disclaimer;
                    }

                    if (informativeSection) {
                      tcHelpTextRef.current = informativeSection.body;
                    }
                  }
              }

              setCancelStep(CancelStep.CancellationInfo);
              break;
            default:
              subtitlesRef.current = cancelTitles.GET_INFO_ERROR;
              setCancelStep(CancelStep.CancellationError);
          }
        })
        .catch(() => {
          subtitlesRef.current = cancelTitles.GET_INFO_ERROR;

          setCancelStep(CancelStep.CancellationError);
        });
    },
    [flight.bookedItinerary.id]
  );

  /**
   * @description Step 0 of the self serve cancel flow.
   * Intercepts requests if certain experiments are set.
   */
  const prepareCancelReq = useCallback(() => {
    const { id: itineraryId, travelItinerary } = flight.bookedItinerary;
    const isTF = isTFBooking(travelItinerary);
    const isTrava = isTravaBooking(travelItinerary);

    clearPolicyTimeout();
    if (forceCSExp || (tfCancelExp && isTF) || (forceTravaCSExp && isTrava)) {
      cancelationInfoRef.current = {
        CancelScenario: CancelScenarioEnum.TRVKOutage,
        NonCfar: NonCfarEnum.ContactCustomerService,
      };
      subtitlesRef.current = cancelTitles.TRVK_OUTAGE_SUBTITLE;
      titleRef.current = cancelTitles.TRVK_OUTAGE_TITLE;

      setCancelStep(CancelStep.CancellationError);
    } else {
      getCancelInfo(itineraryId);
    }
  }, [flight.bookedItinerary]);

  /**
   * @description Opens airline's website in a new browser tab
   */
  const redirectToAirline = useCallback(() => {
    const { current: cancelationInfo } = cancelationInfoRef;
    const eventProperties = {
      cancel_scenario: "",
      non_cfar: "",
      product: "flight",
    };
    let airlineWebLink;

    // multiple ways of getting web link just in case
    if (cancelationInfo) {
      const {
        CancelScenario,
        NonCfar,
        airlines = [],
        policies = [],
      } = cancelationInfo;
      const policy = findPolicy(checked, policies);
      airlineWebLink =
        checked && !isEmpty(policy)
          ? airlines?.[outgoingChecked ? 0 : 1]?.webLinks?.manageBooking
          : airlines?.[0]?.webLinks?.manageBooking;
      eventProperties.cancel_scenario = CancelScenario;
      eventProperties.non_cfar = getTrackingNonCfar(NonCfar);
    } else {
      const airline = getDepartureSlice(flight.bookedItinerary).segments[0]
        .marketingAirline.code;

      if (airlineMap[airline]) {
        airlineWebLink = airlineMap[airline]?.webLinks.manageBooking;
      }
    }

    if (airlineWebLink) {
      trackEvent({
        eventName: isCorpTenant(config.TENANT)
          ? SelfServeEvents.ClickRedirectToCarrier
          : SelfServeEvents.RedirectToAirline,
        properties: eventProperties,
      });

      window.open(airlineWebLink, "_blank");
      closeModal();
    }
  }, [airlineMap, checked]);

  /**
   * @description Renders icon, title, subtitle, actions in a column
   * @return {ReactNode}
   */
  const renderCancellationError = useCallback(() => {
    let PrimaryAction = null;

    switch (cancelationInfoRef.current?.CancelScenario) {
      case CancelScenarioEnum.AirlineControl:
      case CancelScenarioEnum.Departed:
        PrimaryAction = (
          <ActionLink
            className="b2b"
            content={
              <>
                {buttonText.CONTACT_AIRLINE}
                <FontAwesomeIcon icon={faExternalLinkAlt} />
              </>
            }
            onClick={redirectToAirline}
          />
        );
        break;
      case CancelScenarioEnum.TRVKOutage:
        PrimaryAction = (
          <ActionButton
            defaultStyle="h4r-primary"
            message={buttonText.CONTACT_SUPPORT}
            onClick={contactCustomerSupport}
          />
        );
        break;
      default:
        PrimaryAction = (
          <ActionButton
            defaultStyle="h4r-primary"
            message={buttonText.TRY_AGAIN}
            onClick={prepareCancelReq}
          />
        );
    }

    return (
      <GenericModalContent
        actions={PrimaryAction}
        image={<Icon className="error-icon" name={IconName.ErrorState} />}
        subtitle={subtitlesRef.current}
        title={titleRef.current}
      />
    );
  }, []);

  /**
   * @description Renders success icon, title, subtitle, success actions in a column
   * @return {ReactNode}
   */
  const renderCancellationFlowComplete = useCallback(
    () => (
      <GenericModalContent
        actions={
          <ActionButton
            className="done-btn"
            defaultStyle="h4r-primary"
            message={buttonText.DONE}
            onClick={closeModal}
          />
        }
        image={<Icon className="success-icon" name={IconName.Checked} />}
        subtitle={subtitlesRef.current}
        title={titleRef.current}
      />
    ),
    []
  );

  const renderCancellationInfoExtraStep = () => {
    let PrimaryAction = null;
    const { current: cancelationInfo } = cancelationInfoRef;

    const policy = findPolicy(checked, cancelationInfo!.policies);
    switch (policy?.policy) {
      case NonCfarEnum.ContactAirline:
        PrimaryAction = (
          <ActionLink
            className="b2b"
            content={
              <>
                {buttonText.CONTACT_AIRLINE}
                <FontAwesomeIcon icon={faExternalLinkAlt} />
              </>
            }
            onClick={redirectToAirline}
          />
        );
        break;
      case NonCfarEnum.ContactCustomerService:
      case NonCfarEnum.RefundableComplex:
      case NonCfarEnum.TicketlessVoid:
        PrimaryAction = (
          <ActionButton
            className="confirm-btn"
            defaultStyle="h4r-primary"
            message={buttonText.CONTACT_SUPPORT}
            onClick={contactCustomerSupport}
          />
        );
        break;
      case NonCfarEnum.NonCancellable:
        PrimaryAction = (
          <ActionLink
            className="confirm-btn"
            content={BACK_TO_MY_TRIPS}
            onClick={closeModal}
          />
        );
        break;
    }

    return (
      <FlightCancellationInfo
        ItinerarySummary={ItinerarySummaryRow}
        actions={PrimaryAction}
        infoItems={infoItemsRef.current}
        subtitles={subtitlesRef.current as string[]}
        tcHelpText={tcHelpTextRef.current}
        title={titleRef.current}
      />
    );
  };

  /**
   * @description Renders title, subtitle, itinerary summary, important info, and
   * actions in a left-justified column.
   * @return {ReactNode}
   */
  const renderCancellationInfo = () => {
    const { current: cancelationInfo } = cancelationInfoRef;
    const { current: subtitles } = subtitlesRef;

    if (!cancelationInfo) return null;

    const { NonCfar } = cancelationInfo;
    let PrimaryAction = null;

    const cancelButtonText =
      NonCfar === NonCfarEnum.MultiTicket
        ? buttonText.CANCEL_ALL
        : buttonText.CANCEL;

    if (!isTimedOut) {
      policyTimeoutRef.current = setTimeout(() => {
        setIsTimedOut(true);
      }, cancelPolicyTimeout);
    }

    switch (NonCfar) {
      case NonCfarEnum.ContactAirline:
        PrimaryAction = (
          <ActionLink
            className="b2b"
            content={
              <>
                {buttonText.CONTACT_AIRLINE}
                <FontAwesomeIcon icon={faExternalLinkAlt} />
              </>
            }
            onClick={redirectToAirline}
          />
        );
        break;
      case NonCfarEnum.ContactCustomerService:
      case NonCfarEnum.RefundableComplex:
      case NonCfarEnum.TicketlessVoid:
        PrimaryAction = (
          <ActionButton
            className="confirm-btn"
            defaultStyle="h4r-primary"
            message={buttonText.CONTACT_SUPPORT}
            onClick={contactCustomerSupport}
          />
        );
        break;
      case NonCfarEnum.AirlineRefund:
      case NonCfarEnum.FTC:
      case NonCfarEnum.FTCWithPenalty:
      case NonCfarEnum.MultiTicket:
      case NonCfarEnum.NonRefundable:
      case NonCfarEnum.PartialRefund:
      case NonCfarEnum.TicketedVoid:
        PrimaryAction = (
          <ActionButton
            className="confirm-btn"
            defaultStyle="h4r-primary"
            message={isTimedOut ? buttonText.REFRESH_POLICY : cancelButtonText}
            onClick={isTimedOut ? prepareCancelReq : displayCancelConfirmation}
          />
        );
        break;
      case NonCfarEnum.NonCancellable:
        PrimaryAction = (
          <ActionLink
            className="confirm-btn"
            content={BACK_TO_MY_TRIPS}
            onClick={closeModal}
          />
        );
        break;
      case NonCfarEnum.MultiProvider:
        const { current: cancelationInfo } = cancelationInfoRef;
        const policy = findPolicy(checked, cancelationInfo!.policies);
        PrimaryAction = (
          <ActionButton
            className="confirm-btn"
            defaultStyle="h4r-primary"
            disabled={!Boolean(checked)}
            message={isTimedOut ? buttonText.REFRESH_POLICY : cancelButtonText}
            onClick={
              isTimedOut
                ? prepareCancelReq
                : () => handleCancelationHfv2(policy!)
            }
          />
        );
        break;
      default:
    }

    if (typeof subtitles === "string") {
      subtitlesRef.current = [subtitles];
    }

    if (
      cancelationInfo?.policies?.length &&
      !isMultiCityItinerary(flight.bookedItinerary, airportMap)
    ) {
      return (
        <SelectFlightInfo
          airportMap={airportMap}
          checked={checked}
          onChange={handleChange}
          flight={flight}
          cancelationInfo={cancelationInfo}
          actions={PrimaryAction}
        />
      );
    }

    return (
      <FlightCancellationInfo
        ItinerarySummary={ItinerarySummaryRow}
        actions={PrimaryAction}
        disclaimer={disclaimerRef.current}
        infoItems={infoItemsRef.current}
        subtitles={subtitlesRef.current as string[]}
        tcHelpText={tcHelpTextRef.current}
        title={titleRef.current}
      />
    );
  };

  /**
   * @description Renders just the summary and prompts the user to confirm the
   * cancellation
   * @return {ReactNode}
   */
  const renderConfirmCancellation = useCallback(() => {
    // we need to this check here, because for hfv2, we have to wait until we select a flight to cancel,
    // so we can set the right policy, due we can choose diff airlines
    const { current: cancelationInfo } = cancelationInfoRef;
    const policy =
      !isMultiCityItinerary(flight.bookedItinerary, airportMap) &&
      (findPolicy(checked, cancelationInfo!.policies) as Policies);
    const textProps = {
      ...(policy ? { infoItems: policy?.cancelCopy?.importantInfo } : {}),
      ...(policy
        ? { tcHelpText: policy?.cancelCopy?.informativeSection?.body }
        : {}),
    };
    return (
      <FlightCancellationInfo
        ItinerarySummary={ItinerarySummaryRow}
        actions={
          <ActionButton
            className="confirm-cancellation-btn"
            defaultStyle="h4r-primary"
            message={buttonText.CONFIRM_CANCEL}
            onClick={confirmCancellation}
          />
        }
        subtitles={subtitlesRef.current as string[]}
        title={titleRef.current}
        {...textProps}
      />
    );
  }, [checked]);

  /**
   * @description Show a spinner, title, and subtitle when loading cancel info
   * or processing a cancellation request.
   */
  const renderLoadingOrProcessing = useCallback(
    () => (
      <B2BSpinnerWithText
        subtitle={subtitlesRef.current}
        title={titleRef.current}
      />
    ),
    []
  );

  /**
   * @description Renders modal for a pending cancellation response
   * @return {ReactNode}
   */
  const renderCancellationPending = useCallback(() => {
    return (
      <GenericModalContent
        actions={
          <ActionButton
            defaultStyle="h4r-primary"
            message={buttonText.CLOSE}
            onClick={closeModal}
          />
        }
        image={<Icon className="error-icon" name={IconName.ErrorState} />}
        title={titleRef.current}
        subtitle={subtitlesRef.current}
      />
    );
  }, []);

  const ModalContent = useMemo(() => {
    switch (cancelStep) {
      case CancelStep.CancellationError:
        return renderCancellationError();
      case CancelStep.CancellationPending:
        return renderCancellationPending();
      case CancelStep.CancellationFlowComplete:
        return renderCancellationFlowComplete();
      case CancelStep.CancellationInfo:
        return renderCancellationInfo();
      // This extra step will use the selected Policy for HFV2
      case CancelStep.CancellationHfv2ExtraStep:
        return renderCancellationInfoExtraStep();
      case CancelStep.ConfirmCancellation:
        return renderConfirmCancellation();
      case CancelStep.LoadingOrProcessing:
        return renderLoadingOrProcessing();
      default:
        return null;
    }
  }, [cancelStep, isTimedOut, checked]);

  // kick off the workflow when the itinerary changes
  useEffect(() => {
    prepareCancelReq();

    return () => {
      // before unmount
      clearPolicyTimeout();
    };
  }, [prepareCancelReq]);

  return (
    <Box className="self-serve-cancel-flight-modal-content">{ModalContent}</Box>
  );
};

export default SelfServeCancelFlightModalContent;
