export const CAP_ONE_LOGO_ALT = "Capital One Travel";
export const PRINT = "Print";
export const TITLE = "Itemized vacation rental receipt";
export const VR_NAME = "Vacation rental name";
export const GUEST_NAME = "Guest name";
export const CONFIRMATION_NUMBER = "Confirmation #";
export const INVOICE_DATE = "Invoice date";
export const CHECK_IN = "Check-in";
export const CHECKOUT = "Checkout";
export const ACCOMMODATION_PER_NIGHT = "Accommodation per night";
export const ACCOMMODATION_TOTAL = "Accommodation Total*";
export const YOUR_STAY = "Your Stay";
export const TOTAL_COST = "Total Cost";
export const TOTAL_COST_PER_NIGHT = "Total cost divided per night";
export const MMDDYYYY_FORMAT = "MM/DD/YYYY";
export const TAXES_AND_FEES = "Taxes and fees";
export const TAXES_AND_FEES_TOTAL = "Taxes and Fees Total";
export const FEES = "Additional Fees at Check-in";
export const PAYMENT_INFO = "Payment Info";
export const DEFAULT_DOC_ID = "itemized-receipt";
export const COPY1 =
  "*This total reflects your purchase through Capital One Travel. Your vacation rental may provide an itemized receipt for any charges arranged directly with them.";
export const PRINT_ON_DESKTOP =
  "Please visit the desktop version of the Capital One Travel Portal";
export const CARD_ENDING = "Card ending in...";
export const TRAVEL_CREDIT_APPLIED = "Capital One Travel Credit Applied";
export const TRAVEL_OFFER_APPLIED = "Capital One Travel Offer Applied";
export const TRAVEL_CREDITS_LINE_ITEM_APPLIED = "Travel credits applied";
export const TRAVEL_OFFER_LINE_ITEM_APPLIED = "Travel offer applied";
