import React from "react";
import {
  Airline,
  BookedFlightItineraryWithDepartureTime,
  HotelConfirmationCodeEnum,
  HotelItinerary,
  MultiTravelItinerary,
  PackageItinerary,
  SingleTravelItinerary,
  TravelItineraryEnum,
} from "redmond";
import { Typography, Box, Button } from "@material-ui/core";
import clsx from "clsx";
import { uniqWith, isEqual } from "lodash-es";
import { ConfirmationModalPackageContentConnectorProps } from "./container";
import "./styles.scss";
import * as textConstants from "./constants";
import { copyTextToClipboardPromise } from "./helpers";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface IConfirmationModalPackageContentProps
  extends ConfirmationModalPackageContentConnectorProps {
  packageItinerary: PackageItinerary;
}

export interface IConfirmationNumber {
  label: string;
  locator: string;
}

export interface IConfirmationNumberWithRedirect extends IConfirmationNumber {
  redirectUrl: string;
}

export const getCapOneHotelConfirmationNumber = ({
  hotel,
}: {
  hotel: HotelItinerary;
}): IConfirmationNumber | null => {
  return {
    label: textConstants.CAPITAL_ONE_HOTEL_LABEL,
    locator: "H-" + hotel.reservation.reservationId,
  };
};

export const getHotelConfirmationNumber = ({
  hotel,
}: {
  hotel: HotelItinerary;
}): IConfirmationNumber | null => {
  if (
    hotel.reservation.hotelConfirmationCode &&
    hotel.reservation.hotelConfirmationCode.HotelConfirmationCode ==
      HotelConfirmationCodeEnum.HotelConfirmationConfirmed
  ) {
    return {
      label: textConstants.HOTEL_CONFIRMATION_LABEL,
      locator: hotel.reservation.hotelConfirmationCode["confirmationCode"],
    };
  }
  return null;
};

const onCopyAndGo = async (locator: string, redirectUrl: string) => {
  await copyTextToClipboardPromise(locator);
  window.open(redirectUrl, "_blank");
};

const filterDuplicates = (
  confirmationNumbers: IConfirmationNumberWithRedirect[]
) => uniqWith(confirmationNumbers, isEqual);

export const getFlightConfirmationNumbers = ({
  flight,
  airlineMap,
}: {
  flight: BookedFlightItineraryWithDepartureTime;
  airlineMap: { [key: string]: Airline };
}): IConfirmationNumberWithRedirect[] => {
  const { bookedItinerary } = flight;
  const { travelItinerary } = bookedItinerary;
  const { TravelItinerary } = travelItinerary;

  const confirmationNumbers: IConfirmationNumberWithRedirect[] = [];

  if (
    (travelItinerary as SingleTravelItinerary)?.locators?.agent.unscopedValue
  ) {
    confirmationNumbers.push({
      label: textConstants.CAPITAL_ONE_FLIGHT_LABEL,
      locator:
        `H-${
          (travelItinerary as SingleTravelItinerary).locators?.agent
            .unscopedValue
        }` || "",
      redirectUrl: textConstants.CAPITAL_ONE_WEBSITE,
    });
  }

  if (TravelItinerary === TravelItineraryEnum.SingleTravelItinerary) {
    const singleTravelItinerary = travelItinerary as SingleTravelItinerary;
    singleTravelItinerary.slices.forEach((slice, sliceIndex) => {
      slice.segments.forEach((segment) => {
        const confirmationNumber = textConstants.getConfirmationNumber({
          segment,
          airlineMap,
          isReturn: sliceIndex !== 0,
        });
        if (confirmationNumber) {
          confirmationNumbers.push(confirmationNumber);
        }
      });
    });
  }
  if (TravelItinerary === TravelItineraryEnum.MultiTravelItinerary) {
    const multipleTravelItinerary = travelItinerary as MultiTravelItinerary;

    if (multipleTravelItinerary.locators?.children) {
      multipleTravelItinerary.travelItineraries.forEach((itinerary) => {
        itinerary.slices.forEach((slice, sliceIndex) => {
          slice.segments.forEach((segment) => {
            const confirmationNumber = textConstants.getConfirmationNumber({
              segment,
              airlineMap,
              isReturn: sliceIndex !== 0,
            });
            if (confirmationNumber) {
              confirmationNumbers.push(confirmationNumber);
            }
          });
        });
      });
    }
  }
  return confirmationNumbers;
};

export const getCapOneConfirmationNumber = ({
  packageItinerary,
}: {
  packageItinerary: PackageItinerary;
}): IConfirmationNumber | null => {
  return {
    label: textConstants.CAPITAL_ONE_LABEL,
    locator: "H-" + packageItinerary.customerConfirmationId,
  };
};

export const ConfirmationModalPackageContent = ({
  packageItinerary,
  airlineMap,
}: IConfirmationModalPackageContentProps) => {
  const confirmationNumberCapOne = getCapOneConfirmationNumber({
    packageItinerary,
  });
  const confirmationNumberCapOneHotel = getCapOneHotelConfirmationNumber({
    hotel: packageItinerary.hotel.itinerary,
  });
  const confirmationNumberHotel = getHotelConfirmationNumber({
    hotel: packageItinerary.hotel.itinerary,
  });

  const confirmationNumbersFlight = filterDuplicates(
    getFlightConfirmationNumbers({
      flight: packageItinerary.flight.itinerary,
      airlineMap,
    })
  );
  const renderConfirmationNumbers = (
    confirmationNumber: IConfirmationNumber | null
  ) => {
    if (confirmationNumber) {
      return (
        <Box className="confirmation-container">
          <Box
            key={`${confirmationNumber.locator}`}
            className={clsx(
              "confirmation-details-container container-border hotel"
            )}
          >
            <Box className="confirmation-details">
              <Typography className="confirmation-label" variant="body2">
                {confirmationNumber.label}
              </Typography>
              <Typography variant="body2" className="confirmation-locator">
                {confirmationNumber.locator}
              </Typography>
            </Box>
          </Box>
        </Box>
      );
    }
    return null;
  };

  const renderFlightConfirmationNumbers = () => {
    return (
      <Box className="confirmation-container">
        {confirmationNumbersFlight.map(
          ({ label, locator, redirectUrl }, index) => (
            <Box
              key={`${locator}-${index}`}
              className={clsx("confirmation-details-container", {
                "container-border":
                  index !== confirmationNumbersFlight.length - 1,
              })}
            >
              <Box className="confirmation-details">
                <Typography className="confirmation-label" variant="body2">
                  {label}
                </Typography>
                <Typography variant="body2" className="confirmation-locator">
                  {locator}
                </Typography>
              </Box>
              {index !== 0 && (
                <Button
                  className="confirmation-copy-container"
                  onClick={() => onCopyAndGo(locator, redirectUrl)}
                  aria-label="Copy and Go"
                >
                  <Typography variant="body2" className="copy-text">
                    {textConstants.COPY_AND_GO}
                  </Typography>
                  <FontAwesomeIcon icon={faEdit} className="copy-icon" />
                </Button>
              )}
            </Box>
          )
        )}
      </Box>
    );
  };

  return (
    <Box className="confirmation-modal-content">
      <Box className="confirmation-title-container">
        <Typography variant="h4">
          {`${textConstants.CONFIRMATION_TITLE}`}
        </Typography>
        <Typography variant="body2" className="confirmation-text">
          {textConstants.MODAL_SUBTITLE}
        </Typography>
      </Box>
      {renderConfirmationNumbers(confirmationNumberCapOne)}
      {renderConfirmationNumbers(confirmationNumberCapOneHotel)}
      {confirmationNumberHotel &&
        renderConfirmationNumbers(confirmationNumberHotel)}
      {renderFlightConfirmationNumbers()}
    </Box>
  );
};
