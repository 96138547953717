import {
  CallState,
  DeleteWatchAlertRequest,
  STOPPED_WATCH,
  WatchAlertViews,
} from "redmond";
import { put } from "redux-saga/effects";
import { trackEvent } from "../../../api/v1/analytics/trackEvent";
import { deleteWatch } from "../../../api/v1/price-watch/deleteWatch";
import { listWatches } from "../../../api/v1/price-watch/listWatches";

import { actions } from "../actions";

export function* deleteWatchSaga(action: actions.IDeleteWatch) {
  try {
    const request: DeleteWatchAlertRequest = {
      key: action.watch.key,
    };
    yield deleteWatch(request);
    const watches: WatchAlertViews = yield listWatches();
    yield put(actions.setWatches(watches));
    yield put(actions.setDeleteWatchCallState(CallState.Success));
    trackEvent({
      eventName: STOPPED_WATCH,
      properties: { success: true },
    });
  } catch (e) {
    trackEvent({
      eventName: STOPPED_WATCH,
      properties: { success: false },
    });
    yield put(actions.setDeleteWatchCallState(CallState.Failed));
  }
}
