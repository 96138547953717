import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { PATH_TERMS_PRICE_FREEZE_HOTEL } from "../../../../utils/paths";
import { getFullTimeString } from "halifax";
// access more date formatting options
dayjs.extend(advancedFormat);

export const HEADER_TITLE = (isMobile: boolean, status: boolean) =>
  `${isMobile ? "" : "Review your "}${
    isMobile ? (status ? "Active" : "Inactive") : status ? "active" : "inactive"
  } frozen price`;

export const HEADER_SUBTITLE = (date: string) => {
  if (!date) return "Expires on ...";
  const expiresOn = getFullTimeString(dayjs(date));
  return `Expires on ${expiresOn}`;
};
export const FROZEN_PRICES_LIST_LINK = "/trips/?tripsFilter=Frozen%20Prices";
export const HEADER_SUPPORT_TEXT = "Questions about your trip?";
export const CONTACT_SUPPORT = "Contact support";

export const OVERVIEW_TITLE = "Complete your booking now";
export const OVERVIEW_SUBTITLE = (expiration: string) => {
  const expiresOn = getFullTimeString(dayjs(expiration));
  return `The price freeze for this hotel expires on ${expiresOn}`;
};

export const ALTERNATE_ROOM_OVERVIEW_TITLE = "Review the price for your hotel";
export const ALTERNATE_ROOM_NOTIFICATION_BANNER_WARNING_TEXT =
  "Your room has been updated.";

export const FROZEN_PRICE = "Frozen price";
export const CURRENT_PRICE = "Current price";
export const TOTAL_FOR_NIGHTS = (duration: string) => {
  return `Total for ${duration}`;
};
export const TAXES_AND_FEES_NOT_INCLUDED = "Taxes and fees not included";

export const PRICES_INCREASED_HIT_CAP = (
  currentPrice: string,
  cap: string,
  sellingHotelPrice: string
) => {
  return `Your hotel's price increased to ${currentPrice} and you saved the maximum of ${cap} per booking. Your new price is ${sellingHotelPrice}.`;
};

export const PRICES_INCREASED_UNDER_CAP = (
  currentPrice: string,
  savings: string
) => {
  return `The price has increased to ${currentPrice}, so your price freeze saved you ${savings}!`;
};

export const PRICES_INCREASED_UNDER_CAP_UNDER_THRESHOLD = (
  currentPrice: string
) => {
  return `The price has increased to ${currentPrice}.`;
};

export const DEPOSIT_APPLIED_TO_BOOKING = (deposit: string) => {
  return `The ${deposit} you paid to freeze this price will be applied toward your booking at checkout.`;
};

export const PRICES_DROPPED = (
  currentPrice: string,
  priceDifference: string
) => {
  return `The price has dropped to ${currentPrice}, so you’ll pay ${priceDifference} less than your frozen price.`;
};

export const PRICES_DROPPED_UNDER_THRESHOLD = (currentPrice: string) => {
  return `The price has dropped to ${currentPrice}.`;
};

export const PRICE_FREEZE_CREDIT = (priceFreezeCredit: string) => {
  return `The ${priceFreezeCredit} you paid to freeze this price will be applied to your booking at checkout.`;
};

export const COMPLETE_BOOKING = "Complete booking";

export const SAVING_TEXT = (savings: string) => {
  return `You'll save ${savings} if you book now`;
};

export const FAQ_TITLE = "Questions about your frozen price?";
export const FAQ_SUBTITLE =
  "Read our support articles for help with your frozen price.";
export const FAQ_TERMS = "Terms and Conditions";
export const FAQ_TERMS_LINK = PATH_TERMS_PRICE_FREEZE_HOTEL;

export const OR_SEPARATOR = "or";

export const LOADING_HOTEL_FROZEN_PRICE_TEXT = "Loading frozen price...";
