import axios from "axios";
import { config } from "../../config";
import { ListPriceFreezeRequest, PriceFreezeView } from "redmond";
import { flightPriceFreezeList } from "../paths";

export const listPriceFreeze = async (
  body: ListPriceFreezeRequest
): Promise<Array<PriceFreezeView>> => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await axios.post(`${flightPriceFreezeList}`, body, {
        baseURL: config.baseURL,
      });
      resolve(res.data);
    } catch (e) {
      reject(e);
    }
  });
};
