import axios from "axios";
import { FlightItineraryRequest, FlightItineraryResponse } from "redmond";
import { airBookingApi } from "@b2bportal/air-booking-api";

export const fetchFlights = async (
  req: FlightItineraryRequest
): Promise<FlightItineraryResponse> => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await airBookingApi(axios).apiV0ItineraryFlightPut(req);

      resolve((res.data as any).value);
    } catch (e) {
      reject(e);
    }
  });
};
