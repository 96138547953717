export enum ShopSymbol {
  UNKNOWN = "Unknown",
  PURCHASABLE = "Purchasable",
  UNAVAILABLE = "Unavailable",
  INCLUDED = "Included",
}

export enum SliceDirection {
  Outbound = "Outbound",
  Return = "Return",
}

export enum TripGrouping {
  DATE = "DateGrouping",
}

export const PRICE_FREEZE_ID_QUERY_PARAM = "priceFreezeId";

export enum DisruptionProtectionQueryParam {
  ItineraryId = "itineraryId",
  SliceIndex = "sliceIndex",
  SegmentIndex = "segmentIndex",
  Origin = "origin",
  Destination = "destination",
  // note: these params are for event tracking only
  ProductRedeemChoice = "productRedeemChoice",
  ActiveDisruption = "activeDisruption",
  EmbeddedRoundTrip = "embeddedRoundTrip",
}
