import { Box, Typography } from '@material-ui/core';
import { useSelector } from "react-redux";
import React from 'react';
import * as constants from './constants';
import './styles.scss';
import { getAirportMap } from '../../TripsList/reducer';

interface Props {
  destinationCode: string,
  embeddedTrip: boolean,
}

export function KnowBeforeRebook({
  destinationCode,
  embeddedTrip,
}: Props) {
  const airports = useSelector(getAirportMap);
  const city = airports[destinationCode]?.cityName;
  return (
    <Box className="know-before-rebook-container">
      <Box className="know-before-rebook">
        <Box className="title">
          <Typography variant="body1">
            {constants.KNOW_BEFORE}
          </Typography>
        </Box>
        <Box className="description">
          <ul>
            <li>
              <Typography
                variant="body1"
                dangerouslySetInnerHTML={{
                  __html: embeddedTrip ? constants.EMBEDDED_FLIGHT : constants.ALL_REBOOKS(city, destinationCode)
                }}
              >
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                {constants.COLLECT_BAGGAGE}
              </Typography>
            </li>
          </ul>
        </Box>
      </Box>
    </Box>
  )
}
