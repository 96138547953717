import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import { IStoreState } from "../../../../reducers/types";
import {
  setTripsFilter,
  populateTripQueryParams,
  setSelectedFlight,
  setSelectedHotel,
  setSelectedCar,
} from "../../actions/actions";
import { getTripsFilter, getTripsCounts } from "../../reducer";
import { DesktopFilterTabs } from "./component";

const mapStateToProps = (state: IStoreState) => ({
  tripsFilter: getTripsFilter(state),
  tripsCounts: getTripsCounts(state),
});

const mapDispatchToProps = {
  populateTripQueryParams,
  setTripsFilter,
  setSelectedCar,
  setSelectedFlight,
  setSelectedHotel,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IDesktopFilterTabsConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedDesktopFilterTabs = connector(
  withRouter(DesktopFilterTabs)
);
