import { faBed, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import { getCancellationPolicyInfo, Icon, IconName } from "halifax";
import React from "react";
import {
  BookedRooms,
  CancellationPolicyEnum,
  Cfar,
  HotelCancellationPolicy,
  HotelCfarContract,
  LodgingAvailabilityCheckInInstructions,
  NonRefundable,
  Refundable,
  RoomProduct,
} from "redmond";
import { isCorpTenant, useMultiroomCountText } from "@capone/common";
import {
  CFAR_CANCELLATION_PRIMARY_TEXT_PARTIAL,
  CFAR_CANCELLATION_SECONDARY_TEXT_REFUND_AMOUNT,
  PREMIER_COLLECTION_BENEFITS_LIST,
  PREMIER_COLLECTION_BENEFITS_TITLE,
  ROOM_TYPE,
  CFAR_CANCELLATION_PRIMARY_TEXT_FULLY_REFUNDABLE,
  CHECK_IN_INSTRUCTIONS_TITLE,
  formattedCheckIn,
  formattedCheckOut,
} from "./constants";
import "./styles.scss";
import { config } from "../../../../../../../../api/config";

interface ICancellationText {
  primaryText: string;
  secondaryText?: string;
  iconName?: string;
}

const getCancellationText = ({
  cancellationPolicy,
  hotelCfar,
  refundAmount,
}: {
  cancellationPolicy: HotelCancellationPolicy;
  refundAmount: string;
  hotelCfar?: HotelCfarContract;
}): ICancellationText => {
  if (hotelCfar) {
    const { shouldRefundCfarPremium } = hotelCfar;
    return {
      iconName: IconName.CheckShieldBlue,
      primaryText: shouldRefundCfarPremium
        ? CFAR_CANCELLATION_PRIMARY_TEXT_FULLY_REFUNDABLE
        : CFAR_CANCELLATION_PRIMARY_TEXT_PARTIAL,
      secondaryText: CFAR_CANCELLATION_SECONDARY_TEXT_REFUND_AMOUNT(
        refundAmount,
        !!shouldRefundCfarPremium
      ),
    };
  }

  if (cancellationPolicy?.CancellationPolicy === CancellationPolicyEnum.Cfar) {
    const { primaryText } = cancellationPolicy as Cfar;
    return { primaryText };
  }

  if (
    cancellationPolicy?.CancellationPolicy ===
    CancellationPolicyEnum.NonRefundable
  ) {
    const { primaryText, secondaryText } = cancellationPolicy as NonRefundable;
    return { primaryText, secondaryText: secondaryText! };
  }

  if (
    cancellationPolicy?.CancellationPolicy === CancellationPolicyEnum.Refundable
  ) {
    const { primaryText, secondaryText } = cancellationPolicy as Refundable;
    return { primaryText, secondaryText: secondaryText! };
  }

  return { primaryText: "" };
};

export interface IHotelShopRoomTypePickerProps {
  bookedRooms: BookedRooms[];
  cancellationPolicy?: HotelCancellationPolicy;
  isLuxuryCollection?: boolean;
}

export interface IHotelShopRoomTypePickerState {
  panelExpanded: boolean;
}

// TODO: revisit this component once we have a better understanding on how to handle globalization;
// some texts will not likely be translatable simply by switching nightText alone (because of grammar differences)
export interface HotelShopInfoPartsTexts {
  nightText?: string;
  perNightText?: string;
}

export const RoomRefundability = ({
  product,
}: {
  product: RoomProduct;
}): React.ReactElement | null => {
  const cancellationClassAndTitle = getCancellationPolicyInfo(
    product.cancellationPolicy
  );

  if (!cancellationClassAndTitle) {
    return null;
  }

  return (
    <Box
      className={clsx(
        "hotel-description room-type-rate-type",
        cancellationClassAndTitle.className
      )}
    >
      <Typography variant="subtitle2" className="text-container">
        {cancellationClassAndTitle.tagText}
      </Typography>
    </Box>
  );
};

export const RoomName = ({
  name,
  count = 1,
}: {
  name?: string;
  count?: number;
}): React.ReactElement | null => {
  if (!name) return null;

  return (
    <Typography variant="body1" className="hotel-description room-type-name">
      {!isCorpTenant(config.TENANT) && (
        <strong>{useMultiroomCountText(count)}</strong>
      )}{" "}
      {name} {isCorpTenant(config.TENANT) && useMultiroomCountText(count)}
    </Typography>
  );
};

export const RoomCapacity = ({
  occupancy,
}: {
  occupancy?: number;
}): React.ReactElement | null => {
  if (!occupancy) {
    return null;
  }
  return (
    <Typography
      variant="body2"
      className="hotel-description room-type-capacity"
    >
      <FontAwesomeIcon icon={faUser} />
      Room fits {occupancy} {occupancy > 1 ? "guests" : "guest"}
    </Typography>
  );
};

export const RoomBedDescription = ({
  description,
}: {
  description?: string;
}): React.ReactElement => {
  return (
    <Typography
      variant="body2"
      className="hotel-description room-type-bed-description"
    >
      <FontAwesomeIcon icon={faBed} />
      {description}
    </Typography>
  );
};

export const HotelCancellation = ({
  hotelCfar,
  cancellationPolicy,
  refundAmount,
}: {
  hotelCfar?: HotelCfarContract;
  cancellationPolicy: HotelCancellationPolicy;
  refundAmount: string;
}) => {
  const { iconName, primaryText, secondaryText } = getCancellationText({
    cancellationPolicy,
    hotelCfar,
    refundAmount,
  });

  if (!primaryText) {
    return null;
  }

  return (
    <Box className="hotel-cancellation-text">
      <Box className="hotel-cancellation-title-line">
        {iconName && (
          <Icon className="cancellation-text-icon" name={iconName} />
        )}
        <Typography variant="body1" className="cancellation-title">
          {primaryText}
        </Typography>
      </Box>
      {secondaryText && (
        <Typography
          variant="body2"
          className="cancellation-subtitle"
          dangerouslySetInnerHTML={{ __html: secondaryText }}
        />
      )}
    </Box>
  );
};

export const HotelCheckInInstructions = ({
  fromDate,
  untilDate,
  checkInInstructions,
}: {
  fromDate: string;
  untilDate: string;
  checkInInstructions?: LodgingAvailabilityCheckInInstructions;
}) => {
  return (
    <Box className={clsx("hotel-check-in-text")}>
      <Typography variant="body1" className="hotel-check-in-title">
        {CHECK_IN_INSTRUCTIONS_TITLE}
      </Typography>
      <Typography
        variant="body2"
        dangerouslySetInnerHTML={{
          __html: formattedCheckIn(fromDate, checkInInstructions?.checkInTime),
        }}
      />
      <Typography
        variant="body2"
        dangerouslySetInnerHTML={{
          __html: formattedCheckOut(
            untilDate,
            checkInInstructions?.checkOutTime
          ),
        }}
      />
    </Box>
  );
};

export const PremierCollectionBenefits = ({
  isMobile,
}: {
  isMobile?: boolean;
}) => (
  <Box
    className={clsx("trips-premier-collection-benefits-container", {
      mobile: !!isMobile,
    })}
  >
    <Box className="benefits-icon-title-wrapper">
      <Icon name={IconName.StarOutline} />
      <Typography className="benefits-title" variant="body1">
        {PREMIER_COLLECTION_BENEFITS_TITLE}
      </Typography>
    </Box>
    {PREMIER_COLLECTION_BENEFITS_LIST}
  </Box>
);

export const HotelCardRoomTypeContent = ({
  bookedRooms,
  isLuxuryCollection,
}: IHotelShopRoomTypePickerProps): React.ReactElement | null => {
  if (!bookedRooms) {
    return null;
  }

  // we currently only allow booking multiroom with one room type
  const bookedRoom = bookedRooms[0];

  const { count, roomInfo } = bookedRoom;

  return (
    <Box className="hotel-shop-room-type-room-details-list">
      <Typography className="room-type-title" variant="body1">
        {ROOM_TYPE}
      </Typography>
      <RoomName name={roomInfo.name} count={count} />
      <RoomCapacity occupancy={roomInfo.maxAdults} />
      <RoomBedDescription description={roomInfo.beds.description} />
      {isLuxuryCollection && <PremierCollectionBenefits />}
    </Box>
  );
};
