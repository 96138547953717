import { SliceDirection } from "redmond";

export const FLIGHT_SLICE_CARD_HEADER = (
  sliceDirection: SliceDirection,
  destination: string,
  date: string
) => `<strong>${sliceDirection}</strong> to ${destination} on ${date}`;

export const FLIGHT_SLICE_CARD_LABEL = "Disrupted flight";
export const FLIGHT_SLICE_AIRLINE_FARE_SHELF_SEPARATOR = "/";
