import { Box, Divider, Typography } from "@material-ui/core";
import React, { useState, useCallback, useMemo } from "react";
import CapOneLogo from "../../../../../../../../../public/capone-logo.svg";
import * as textConstants from "./constants";
import { ActionLink, CurrencyFormatters } from "halifax";
import {
  HomesItinerary,
  PaymentTypeEnum,
  PaymentLineItemEnum,
  PaymentLineItemTravelWallet,
  PaymentLineItemTravelWalletEnum,
} from "redmond";
import dayjs from "dayjs";
import "./styles.scss";
import clsx from "clsx";

interface IHomeItemizedReceiptModalContentProps {
  homeItinerary: HomesItinerary;
  documentId?: string;
  isMobile?: boolean;
}

export const HomeItemizedReceiptModalContent = ({
  homeItinerary,
  documentId = textConstants.DEFAULT_DOC_ID,
  isMobile,
}: IHomeItemizedReceiptModalContentProps) => {
  const { reservation, paymentsAndCredits } = homeItinerary;
  const paymentBreakdown = homeItinerary.paymentBreakdown;
  const breakdownPayments =
    paymentBreakdown?.payments as PaymentLineItemTravelWallet[];
  const walletItemPayments = breakdownPayments.filter(
      (breakdownPayment) =>
        breakdownPayment.PaymentLineItem === PaymentLineItemEnum.TravelWallet
    );
  const { listing, createdAt, primaryGuest, id, stayDates } = reservation;
  const [printing, setPrinting] = useState(false);

  const guestName = `${primaryGuest.givenName} ${primaryGuest.surname}`;
  const stayLength = reservation.lengthOfStay;
  const basePrice = reservation.rate.detailedPrice.sellPrice.fiat.value;
  const basePricePerNight =
    reservation.rate.detailedPrice.sellPrice.fiat.value / stayLength;
  const feesAndTaxesTotal = reservation.rate.detailedPrice.fees.reduce(
    (acc, curr) => (acc += curr.amount.fiat.value),
    0
  );
  const feesAndTaxesPerNight = feesAndTaxesTotal / stayLength;
  const totalPrice = reservation.rate.detailedPrice.payNowTotal.fiat.value;
  const effectiveTotal = 
    totalPrice - 
    walletItemPayments.reduce((prev, curr) => prev + curr.amount.amount, 0);

  const addCurrency = (num: number) =>
    `${CurrencyFormatters.get(
      reservation.rate.detailedPrice.sellPrice.fiat.currencyCode
    ).format(Math.abs(num))}`;

  const invoiceDate = dayjs(createdAt, "YYYY-MM-DDTHH:mm:ss.SSS").format(
    textConstants.MMDDYYYY_FORMAT
  );

  const handlePrint = useCallback(() => {
    setPrinting(true);
    setTimeout(() => {
      const divContents = document.getElementById(documentId)?.innerHTML;
      const cssHeadContents = document.head?.getElementsByTagName("style");
      const cssBodyContents = document.body.getElementsByTagName("style");
      const cssBodyStyle = document.body.getAttribute("style");
      const a = window.open(" ", " ", "height=800, width=400");
      const doc = a?.document;
      if (doc) {
        doc.write("<html><head>");
        for (let i = 0; i < cssHeadContents.length; i++) {
          doc.write(
            `<style>${cssHeadContents.item(i)?.innerHTML || ""}</style>`
          );
        }

        doc.write(`</head><body style="${cssBodyStyle}" >${divContents || ""}`);

        for (let i = 0; i < cssBodyContents.length; i++) {
          doc.write(
            `<style>${cssBodyContents.item(i)?.innerHTML || ""}</style>`
          );
        }
        doc.write("</body></html>");
        if (a)
          a.document.title = `${guestName} - ${reservation.id.value} ${textConstants.TITLE}`;
        setTimeout(() => {
          a?.print();
          doc.close();
          setPrinting(false);
        }, 500);
      }
    }, 10);
  }, []);

  const paymentInfo = {
    numberDisplay: "",
    rewardsDisplay: "",
    creditApplied: breakdownPayments.some(
      (pmt) =>
        pmt.TravelWallet === PaymentLineItemTravelWalletEnum.TravelWalletCredit
    ),
    offerApplied: breakdownPayments.some(
      (pmt) =>
        pmt.TravelWallet === PaymentLineItemTravelWalletEnum.TravelWalletOffer
    ),
  };

  if (
    paymentsAndCredits?.PaymentAmountInfo === PaymentTypeEnum.FiatAmountInfo
  ) {
    paymentInfo.numberDisplay = paymentsAndCredits.numberDisplay;
  } else if (
    paymentsAndCredits?.PaymentAmountInfo === PaymentTypeEnum.RewardsAmountInfo
  ) {
    paymentInfo.numberDisplay = paymentsAndCredits.accountDisplayName;
  } else if (
    paymentsAndCredits?.PaymentAmountInfo === PaymentTypeEnum.SplitAmountInfo
  ) {
    paymentInfo.numberDisplay = paymentsAndCredits.fiatInfo.numberDisplay;
    paymentInfo.rewardsDisplay =
      paymentsAndCredits.rewardsInfo.accountDisplayName;
  }

  const renderPrintButton = useMemo(() => {
    if (printing) return null;

    return isMobile ? (
      <></>
    ) : (
      <ActionLink
        className="print-btn"
        content={textConstants.PRINT}
        onClick={handlePrint}
      />
    );
  }, [isMobile, printing]);

  return (
    <Box className="itemized-home-receipt-modal-container" id={documentId}>
      <Box className="itemized-home-receipt-modal-content">
        <Box className="itemized-home-receipt-logo-container">
          <img
            src={CapOneLogo}
            alt={textConstants.CAP_ONE_LOGO_ALT}
            role="img"
            aria-label={textConstants.CAP_ONE_LOGO_ALT}
          />
          {renderPrintButton}
        </Box>
        <Box className="itemized-home-receipt-title-container">
          <Typography variant="h2">{textConstants.TITLE}</Typography>
        </Box>
        <Box className="itemized-home-receipt-details">
          <Box className="line-item">
            <Typography variant="body1">{textConstants.VR_NAME}:</Typography>
            <Typography variant="body1" className="home-name bold">
              {listing.name}
            </Typography>
          </Box>
          <Box className="line-item">
            <Typography variant="body1">{textConstants.GUEST_NAME}:</Typography>
            <Typography variant="body1" className="bold">
              {guestName}
            </Typography>
          </Box>
          <Box className="line-item gutter-bottom">
            <Typography variant="body1">
              {textConstants.CONFIRMATION_NUMBER}:
            </Typography>
            <Typography variant="body1" className="bold">
              {id.value}
            </Typography>
          </Box>
          <Divider className="line-item gutter-bottom" />
          <Box className="line-item">
            <Typography variant="body1">
              {textConstants.INVOICE_DATE}:
            </Typography>
            <Typography variant="body1" className="bold">
              {invoiceDate}
            </Typography>
          </Box>
          <Box className="line-item">
            <Typography variant="body1">{textConstants.CHECK_IN}:</Typography>
            <Typography variant="body1" className="bold">
              {dayjs(stayDates.from).format(textConstants.MMDDYYYY_FORMAT)}
            </Typography>
          </Box>
          <Box className="line-item gutter-bottom">
            <Typography variant="body1">{textConstants.CHECKOUT}:</Typography>
            <Typography variant="body1" className="bold">
              {dayjs(stayDates.until).format(textConstants.MMDDYYYY_FORMAT)}
            </Typography>
          </Box>
          <Divider className="line-item gutter-bottom" />
          <Typography variant="body1" className="bold">
            {textConstants.TOTAL_COST_PER_NIGHT}
          </Typography>
          <Box
            className={clsx("line-item", {
              "gutter-bottom": feesAndTaxesTotal === 0,
            })}
          >
            <Typography variant="body1">
              {textConstants.ACCOMMODATION_PER_NIGHT}:
            </Typography>
            <Typography variant="body1">
              {addCurrency(basePricePerNight)}
            </Typography>
          </Box>
          {feesAndTaxesTotal > 0 ? (
            <Box className="line-item gutter-bottom">
              <Typography variant="body1">
                {textConstants.TAXES_AND_FEES}:
              </Typography>
              <Typography variant="body1">
                {addCurrency(feesAndTaxesPerNight)}
              </Typography>
            </Box>
          ) : undefined}
          <Box className="line-item">
            <Typography variant="body1" className="bold">
              {textConstants.ACCOMMODATION_TOTAL}:
            </Typography>
            <Typography variant="body1" className="bold">
              {addCurrency(basePrice)}
            </Typography>
          </Box>
          {feesAndTaxesTotal > 0 ? (
            <Box className="line-item">
              <Typography variant="body1" className="bold">
                {textConstants.TAXES_AND_FEES_TOTAL}:
              </Typography>
              <Typography variant="body1" className="bold">
                {addCurrency(feesAndTaxesTotal)}
              </Typography>
            </Box>
          ) : undefined}
          {walletItemPayments.map((breakdownPayment) =>
            "breakdown" in breakdownPayment &&
            breakdownPayment.breakdown?.length ? (
              breakdownPayment.breakdown.map((breakdownItem) => (
                <Box className="line-item positive">
                  <Typography variant="body1" className="bold">
                    {breakdownItem.description ||
                      textConstants.TRAVEL_CREDITS_LINE_ITEM_APPLIED}
                  </Typography>
                  <Typography variant="body1" className="bold">
                    -{addCurrency(Math.abs(breakdownItem.amount.amount))}
                  </Typography>
                </Box>
              ))
            ) : (
              <Box className="line-item positive">
                <Typography variant="body1" className="bold">
                  {breakdownPayment.TravelWallet ===
                  PaymentLineItemTravelWalletEnum.TravelWalletCredit
                    ? textConstants.TRAVEL_CREDITS_LINE_ITEM_APPLIED
                    : textConstants.TRAVEL_OFFER_LINE_ITEM_APPLIED}
                </Typography>
                <Typography variant="body1" className="bold">
                  -{addCurrency(Math.abs(breakdownPayment.amount.amount))}
                </Typography>
              </Box>
            )
          )}
          <Box className="line-item gutter-bottom">
            <Typography variant="body1" className="bold">
              {textConstants.TOTAL_COST}:
            </Typography>
            <Typography variant="body1" className="bold">
              {addCurrency(effectiveTotal)}
            </Typography>
          </Box>
          <Typography variant="caption" className="line-item gutter-bottom">
            {textConstants.COPY1}
          </Typography>
          <Divider className="line-item gutter-bottom" />
          {((paymentInfo.numberDisplay &&
            !paymentInfo.numberDisplay?.includes("Unavailable")) ||
            paymentInfo.rewardsDisplay || 
            paymentInfo.creditApplied ||
            paymentInfo.offerApplied) && (
            <Box>
              <Typography variant="body1" className="bold">
                {textConstants.PAYMENT_INFO}
              </Typography>

              {paymentInfo.numberDisplay && (
                <Typography variant="body1" className="gutter-bottom">
                  {`${
                    textConstants.CARD_ENDING
                  } ${paymentInfo.numberDisplay.slice(-4)}`}
                </Typography>
              )}

              {paymentInfo.rewardsDisplay && (
                <Typography variant="body1" className="gutter-bottom">
                  {`${
                    textConstants.CARD_ENDING
                  } ${paymentInfo.rewardsDisplay.slice(-4)}`}
                </Typography>
              )}

              {paymentInfo.creditApplied &&
                !paymentInfo.rewardsDisplay &&
                !paymentInfo.numberDisplay && (
                  <Typography variant="body1" className="gutter-bottom">
                    {textConstants.TRAVEL_CREDIT_APPLIED}
                  </Typography>
                )}

              {paymentInfo.offerApplied &&
                !paymentInfo.rewardsDisplay &&
                !paymentInfo.numberDisplay && (
                  <Typography variant="body1" className="gutter-bottom">
                    {textConstants.TRAVEL_OFFER_APPLIED}
                  </Typography>
                )}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};
