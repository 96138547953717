import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { IStoreState } from "../../../../reducers/types";
import { NoTripResults } from "./component";
import {
  getTripsFilter,
  hasFetchTripsError,
  hasTripsToDisplay,
} from "../../reducer";
import { getRewardsAccountWithLargestValue } from "../../../../modules/rewards/reducer";

const mapStateToProps = (state: IStoreState) => ({
  tripsFilter: getTripsFilter(state),
  hasError: hasFetchTripsError(state),
  hasTripsToDisplay: hasTripsToDisplay(state),
  largestValueAccount: getRewardsAccountWithLargestValue(state),
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type NoTripResultsConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedNoTripResults = withRouter(connector(NoTripResults));
