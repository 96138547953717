import axios from "axios";
import {
  CancellationRequest,
  CancellationResponse,
  CarReservation,
  SelfServeEvents,
} from "redmond";

import { trackEvent } from "../analytics/trackEvent";
import { config } from "../../config";
import { GROUND_PRODUCT } from "../../../pages/TripsList/components/ItineraryList/components/ItinerariesModal/constants";
import { cancelGroundByReservationPath } from "../paths";
import { getTrackingCarProvider } from "../../../utils/events";

/**
 * @description confirm cancellation of ground booking
 * @param {CancellationRequest} req Contains the reservation id and cancellation policy
 * @param {CarReservation} groundBooking Contains the `provider_name` for better tracking
 * @return {CancellationResponse} The cancellation info for the itinerary passed in
 */
const confirmGroundCancellation = (
  req: CancellationRequest,
  groundBooking: CarReservation
): Promise<CancellationResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const providerName = getTrackingCarProvider(groundBooking);
      const res = await axios.post(cancelGroundByReservationPath, req, {
        baseURL: config.baseURL,
      });

      const { Response, errors, value } = res.data;

      // call can succeed with a "Failure" Response so handle both cases
      if (Response === "Success") {
        trackEvent({
          eventName: SelfServeEvents.ResponseSuccess,
          properties: {
            product: GROUND_PRODUCT,
            provider_name: providerName,
          },
        });
        resolve(value);
      } else {
        let errString = "";

        if (typeof errors === "string") {
          errString = errors;
        } else if (Array.isArray(errors)) {
          errString = errors
            .map((err: any) => `${err.code} - ${err.details}`)
            .join(", ");
        }

        trackEvent({
          eventName: SelfServeEvents.ResponseFailure,
          properties: {
            product: GROUND_PRODUCT,
            provider_name: providerName,
            reason: errString,
          },
        });
        reject(errString);
      }
    } catch (e) {
      reject(e);
    }
  });

export default confirmGroundCancellation;
