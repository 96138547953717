import React from "react";
import { Box, Typography } from "@material-ui/core";
import { Icon, IconName, ActionButton, useDeviceTypes } from "halifax";
import clsx from "clsx";

import "./styles.scss";
import * as constants from "./constants";

interface ICompleteScreenProps {
  onClose: () => void;
  isFintechCsatEnabled?: boolean;
}

export const CompleteScreen = (props: ICompleteScreenProps) => {
  const { onClose, isFintechCsatEnabled } = props;
  const { matchesMobile } = useDeviceTypes();

  const renderCsatSurvey = () => {
    if (isFintechCsatEnabled) {
      (window as any).cempDataLayer["pageName"] = "HOTEL_CFAR";
      const SURVEY_ID =
        window.__mclean_env__.ENV === "production"
          ? "capitalonetravel-cancel"
          : "capitalonetravel-cancel-test";
      return <div id={SURVEY_ID} />;
    }
    return <></>;
  };

  React.useEffect(() => {
    (window as any).cempDigital.updatePageView();
  }, []);

  return (
    <Box
      className={clsx("hotel-cfar-model-content-complete-screen-root", {
        mobile: matchesMobile,
      })}
    >
      <Box className="hotel-cfar-model-content-complete-screen-container">
        <Box className="content-wrapper">
          <Icon className="cfar-complete-check-mark" name={IconName.Checked} />
          <Typography className={clsx("cfar-complete-text", "primary")}>
            {constants.CANCELLATION_COMPLETE}
          </Typography>
          <Typography className={clsx("cfar-complete-text", "secondary")}>
            {constants.BOOKING_HAS_BEEN_CANCELED_BODY}
          </Typography>
          <ActionButton
            className="cfar-complete-button"
            onClick={onClose}
            message={constants.DONE}
            defaultStyle="h4r-primary"
            ariaLabelText={constants.DONE_BUTTON_ARIA_LABEL}
          />
          {renderCsatSurvey()}
        </Box>
      </Box>
    </Box>
  );
};
