import { Box, Typography } from "@material-ui/core";
import { ButtonWrap, Icon, IconName } from "halifax";
import clsx from "clsx";
import React, { FC } from "react";
import { LEARN_MORE, SELF_CHECKIN } from "./../textConstants";
import "./../styles.scss";

interface SelfTransferBannerProps {
  isMobile?: boolean;
  onClick?: () => void;
  iconName?: IconName;
  content?: string;
}

export const SelfTransferBanner: FC<SelfTransferBannerProps> = ({
  isMobile = false,
  iconName,
  onClick,
  content,
}) => (
  <Box className={clsx("self-transfer-banner-wrapper", { mobile: isMobile })}>
    {iconName && <Icon name={iconName} className="self-transfer-icon" />}
    <Box className="title-wrapper">
      <Typography variant="subtitle2">
        <span
          className="self-transfer-banner-title"
          dangerouslySetInnerHTML={{ __html: content ?? SELF_CHECKIN }}
        />
      </Typography>
      {onClick && (
        <ButtonWrap onClick={onClick}>
          <Typography className="learn-more" variant="subtitle2">
            {LEARN_MORE}
          </Typography>
        </ButtonWrap>
      )}
    </Box>
  </Box>
);
