import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Popover, Typography } from "@material-ui/core";
import clsx from "clsx";
import {
  ActionLinks,
  BannerSeverity,
  IActionLink,
  Icon,
  IconName,
  NotificationBanner,
  StatusTag,
  getTwoLineAddresses,
} from "halifax";
import React, { useRef, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import {
  ContractStateEnum,
  HotelCfarContract,
  HotelConfirmationCodeEnum,
  HotelItineraryState,
  LodgingAddressExact,
  LodgingCollectionEnum,
  MyTripsFilter,
  MyTripsModalTypes,
  TagColors,
} from "redmond";

import { HOW_REFUNDS_WORK_LINK, HOW_REFUNDS_WORK_TEXT } from "../../constants";
import { ConfirmationSummary } from "../ConfirmationSummary";
import { getLabels, getValues } from "../FlightCard/helpers";
import {
  addHotelType,
  getPostalCode,
} from "../HotelCard/components/DesktopHotelCard/component";
import {
  HotelCancellation,
  HotelCardRoomTypeContent,
  HotelCheckInInstructions,
} from "../HotelCard/components/HotelInfoParts";
import { HotelMap } from "../HotelCard/components/HotelMap";
import * as textConstants from "../HotelCard/constants";
import { MobileActionLinks } from "../MobileActionLinks";
import { PaymentSummary } from "../PaymentSummary";
import { SummaryCard } from "../SummaryCard";
import { TravelersSummary } from "../TravelersSummary";
import { MobileHotelItineraryDetailsConnectorProps } from "./container";

import {
  ActiveExperiments,
  useExperiment,
} from "../../../../../../context/experiments";
import { getHotelStayDuration } from "../../../../utils/constants";
import { PremierCollectionBenefits } from "../HotelCard/components/HotelInfoParts/component";
import "./styles.scss";
import { AddOn, AddOnEnum, AddOnsSummary } from "../AddOnsSummary";
import dayjs from "dayjs";
import {
  calculateHotelTotals,
  getCurrencyString,
} from "../../../../../../utils/helpers";

export interface IMobileHotelItineraryDetailsProps
  extends RouteComponentProps,
    MobileHotelItineraryDetailsConnectorProps {}

export const MobileHotelItineraryDetails = ({
  hotel,
  history,
  setOpenModal,
  setSelectedHotel,
  tripsFilter,
}: IMobileHotelItineraryDetailsProps) => {
  const refundPopoverRef = useRef<HTMLButtonElement>(null);
  const [refundPopoverOpen, setRefundPopoverOpen] = useState(false);

  if (!hotel) {
    return null;
  }

  const { ancillaries } = hotel;

  // note: destructuring can be expanded in the future as we have more ancillaries
  const { cfar } = ancillaries;

  const { title, confirmationCode, startDate, endDate, subtitle } =
    textConstants.getHotelInfoDetails(hotel, true);
  const nights = getHotelStayDuration(hotel.reservation);
  const onOpenModal = (type: MyTripsModalTypes) =>
    setOpenModal({
      type,
      selectedItinerary: addHotelType(hotel),
    });
  const resState = hotel.reservation?.state?.ReservationState;
  const itemizedReceiptExp = useExperiment(
    ActiveExperiments.SelfServeHotelItemizedReceipt
  );
  const isCancelled = [
    HotelItineraryState.Canceled,
    HotelItineraryState.CancellationFailure,
    HotelItineraryState.Cancelled,
  ].includes(resState);
  const isPastTrips = tripsFilter === MyTripsFilter.PAST_TRIPS;

  const actions: IActionLink[] = [];
  const topActions: IActionLink[] = []; // render at the top of the page

  const closeRefundPopover = () => setRefundPopoverOpen(false);
  const openRefundPopover = () => setRefundPopoverOpen(true);

  const hasCfar = !!cfar;

  // note: hasAddOn can be expanded in the future as we have more features added in
  const hasAddOn = hasCfar;

  const hasCfarExpired = (cfar: HotelCfarContract | undefined): boolean => {
    return !!cfar && dayjs().isAfter(dayjs(cfar.expired));
  };

  const isCfarExercised = (cfar: HotelCfarContract | undefined): boolean => {
    return !!cfar && cfar.state?.ContractState === ContractStateEnum.Exercise;
  };

  if (!isCancelled && !isPastTrips) {
    actions.push({
      content: "Change Hotel Reservation",
      onClick: () => onOpenModal(MyTripsModalTypes.ChangeHotel),
    });

    if (hasCfar && !hasCfarExpired(cfar) && !isCfarExercised(cfar)) {
      actions.push({
        linkClassName: "mobile-cfar-link",
        content: (
          <>
            <Icon className="cfar-icon" name={IconName.CheckShieldBlue} />
            {textConstants.CANCEL_FOR_ANY_REASON}
          </>
        ),
        onClick: () => onOpenModal(MyTripsModalTypes.CfarHotel),
      });
    } else {
      actions.push({
        content: "Cancel Hotel Reservation",
        onClick: () => onOpenModal(MyTripsModalTypes.CancelHotel),
      });
    }
  } else if (isCancelled) {
    topActions.push({
      content: (
        <>
          {HOW_REFUNDS_WORK_LINK}
          <Icon className="info-icon" name={IconName.InfoCircle} />
        </>
      ),
      linkClassName: "how-refunds-work-link",
      linkRef: refundPopoverRef,
      onClick: openRefundPopover,
    });
  }

  actions.push({
    content: "Resend Confirmation",
    onClick: () => onOpenModal(MyTripsModalTypes.ResendConfirmation),
  });

  if (!isCancelled && itemizedReceiptExp) {
    actions.push({
      content: "View Itemized Receipt",
      onClick: () => onOpenModal(MyTripsModalTypes.ItemizedHotelReceipt),
    });
  }

  const { reservation, paymentAmountInfo, paymentBreakdown } = hotel;

  const { calculatedTripTotal, refundableAmount } = calculateHotelTotals({
    reservation,
    paymentBreakdown,
    cfar,
  });

  const hotelAddress = reservation.lodgingData?.address as
    | LodgingAddressExact
    | undefined;

  // parse address to only show the street, this could lead to potential bugs
  // because BE returns everything as a full address i.e: "15 Beacon St, Boston, MA, US"
  const hotelStreet = getTwoLineAddresses(hotelAddress).line1;

  const hotelCancellation = (
    <HotelCancellation
      hotelCfar={hotel.ancillaries.cfar}
      cancellationPolicy={hotel.reservation.cancellationPolicy}
      refundAmount={refundableAmount}
    />
  );

  return (
    <Box className="mobile-trip-details">
      <Box className="mobile-trip-details-header">
        <FontAwesomeIcon
          icon={faChevronLeft}
          onClick={() => {
            history.goBack();
            setSelectedHotel(null);
          }}
        />
        <Box className="header-info">
          <Typography variant="subtitle1">{title}</Typography>
          <Typography variant="caption">{nights}</Typography>
        </Box>
      </Box>
      {isCancelled && (
        <NotificationBanner
          className="status-banner"
          icon={<Icon name={IconName.ErrorAlert} />}
          label={textConstants.CANCELED_RES}
          severity={BannerSeverity.ERROR}
        />
      )}
      {topActions.length ? <ActionLinks actions={topActions} /> : null}
      <Box
        className={clsx("mobile-trip-card", "mobile-trip-summary-card", {
          "premier-collection":
            hotel.reservation.lodgingCollection ===
            LodgingCollectionEnum.Premier,
        })}
      >
        {hotel.reservation.lodgingCollection ===
          LodgingCollectionEnum.Premier && (
          <Icon name={IconName.PremierCollectionRibbon} />
        )}
        {reservation.lodgingCollection === LodgingCollectionEnum.Lifestyle && (
          <Icon name={IconName.LifestyleCollectionRibbon} />
        )}

        {isCancelled && (
          <StatusTag
            className="title-status-tag"
            tagInfo={{
              label: textConstants.CANCELED,
              type: TagColors.RED,
            }}
          />
        )}
        <Typography variant="body1" className="trips-title">
          {title}
        </Typography>
        {subtitle && <Typography variant="caption">{subtitle}</Typography>}
        <Box className="info-container">
          <Typography className="label" variant="caption">
            {textConstants.CONFIRMATION}
          </Typography>
          <Typography
            className={clsx("code", { warning: isCancelled })}
            variant="caption"
          >
            {confirmationCode}
          </Typography>
        </Box>
      </Box>
      <SummaryCard className="mobile-trip-card mobile-location-details">
        <Box className="mobile-location-details-container">
          <Box className="mobile-hotel-dates">
            <Typography variant="subtitle2" className="date-label">
              {`${textConstants.CHECK_IN}: `}
            </Typography>
            <Typography variant="subtitle2">{startDate}</Typography>
            <Typography variant="subtitle2">|</Typography>
            <Typography variant="subtitle2" className="date-label">
              {`${textConstants.CHECK_OUT}: `}
            </Typography>
            <Typography variant="subtitle2">{endDate}</Typography>
          </Box>
          <Box className="mobile-hotel-address">
            <Typography variant="body1" className="hotel-address-line">
              {hotelStreet}
            </Typography>
            <Typography variant="caption" className="address-details">
              {`${getPostalCode(reservation)}`}
            </Typography>
            {reservation.lodgingData?.phone && (
              <Typography variant="caption" className="hotel-phone">
                {reservation.lodgingData.phone}
              </Typography>
            )}
          </Box>
          <HotelMap lodging={reservation.lodgingData} hideLabel />
        </Box>
      </SummaryCard>
      <SummaryCard className="mobile-trip-card mobile-trip-room-type">
        <HotelCardRoomTypeContent bookedRooms={hotel.reservation.bookedRooms} />
      </SummaryCard>
      {hotel.reservation.lodgingCollection ===
        LodgingCollectionEnum.Premier && <PremierCollectionBenefits isMobile />}
      {hotelCancellation && (
        <SummaryCard className="mobile-trip-card cancellation-summary">
          {hotelCancellation}
        </SummaryCard>
      )}
      {hotel.reservation.checkInDate && hotel.reservation.checkOutDate && (
        <SummaryCard className="mobile-trip-card check-in-instructions">
          <HotelCheckInInstructions
            fromDate={hotel.reservation.checkInDate}
            untilDate={hotel.reservation.checkOutDate}
            checkInInstructions={
              hotel.reservation.lodgingData.checkInInstructions
            }
          />
        </SummaryCard>
      )}
      <SummaryCard
        className="mobile-trip-card travellers-summary"
        action={
          <FontAwesomeIcon
            className="mobile-right-chevron"
            onClick={() => onOpenModal(MyTripsModalTypes.TravelersModal)}
            icon={faChevronRight}
          />
        }
      >
        <TravelersSummary
          label="Guest Information"
          travelers={reservation.guests
            .map((person) => person?.givenName)
            .join(", ")}
        />
      </SummaryCard>
      {hasAddOn ? (
        <SummaryCard className="mobile-trip-card add-ons-summary">
          <AddOnsSummary
            addOns={(() => {
              const addOnItems: AddOn[] = [];
              if (hasCfar) {
                addOnItems.push({
                  expiryDate: cfar?.expired ?? "",
                  expiredTz: cfar?.expiredTz ?? "America/New_York", // TODO: Fall back to EST / EDT for now
                  onClick: () => onOpenModal(MyTripsModalTypes.CfarHotel),
                  isEnabled: !hasCfarExpired(cfar) && !isCancelled,
                  type: AddOnEnum.Cfar,
                });
              }
              return addOnItems;
            })()}
          />
        </SummaryCard>
      ) : null}
      <SummaryCard
        className="mobile-payment-summary mobile-trip-card"
        action={
          <FontAwesomeIcon
            className="mobile-right-chevron"
            onClick={() => onOpenModal(MyTripsModalTypes.PaymentModal)}
            icon={faChevronRight}
          />
        }
      >
        <PaymentSummary
          // TODO: Fix fields when hotels itinerary, payment field is completed
          tripTotalAmount={getCurrencyString({
            currencyCode: calculatedTripTotal.currencyCode,
            currencySymbol: calculatedTripTotal.currencySymbol,
            value: calculatedTripTotal.value,
          })}
          cardLabel={getLabels(paymentAmountInfo).cardLabel}
          cardAmount={getValues(paymentAmountInfo).cardValue}
          rewardsLabel={getLabels(paymentAmountInfo).rewardLabel}
          rewardsAmount={getValues(paymentAmountInfo).rewardValue}
          showCardLabel={Boolean(getLabels(paymentAmountInfo).cardLabel)}
          showRewardsLabel={Boolean(getLabels(paymentAmountInfo).rewardLabel)}
        />
      </SummaryCard>
      <SummaryCard
        className="mobile-trip-card confirmation-summary"
        action={
          hotel.reservation.hotelConfirmationCode &&
          hotel.reservation.hotelConfirmationCode.HotelConfirmationCode ==
            HotelConfirmationCodeEnum.HotelConfirmationConfirmed ? (
            <FontAwesomeIcon
              className="mobile-right-chevron"
              onClick={() => onOpenModal(MyTripsModalTypes.ConfirmationModal)}
              icon={faChevronRight}
            />
          ) : null
        }
      >
        <ConfirmationSummary confirmationCode={confirmationCode} />
      </SummaryCard>
      <MobileActionLinks actions={actions} />
      {isCancelled && (
        <Popover
          anchorEl={refundPopoverRef.current}
          anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
          className="how-refunds-work-popover"
          onClose={closeRefundPopover}
          open={refundPopoverOpen}
        >
          <Typography className="subtitle">{HOW_REFUNDS_WORK_TEXT}</Typography>
        </Popover>
      )}
    </Box>
  );
};
