import React, { useRef, useState } from "react";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Popover, Typography } from "@material-ui/core";
import clsx from "clsx";
import dayjs from "dayjs";
import {
  ActionLinks,
  BannerSeverity,
  IActionLink,
  Icon,
  IconName,
  NotificationBanner,
  StatusTag,
} from "halifax";
import { RouteComponentProps } from "react-router-dom";
import { MyTripsModalTypes, State, TagColors } from "redmond";

import { addCarType } from "../CarCard/components/DesktopCarCard/component";
import * as textConstants from "../CarCard/constants";
import { ConfirmationSummary } from "../ConfirmationSummary";
import { getLabels, getValues } from "../FlightCard/helpers";
import { MobileActionLinks } from "../MobileActionLinks";
import { PaymentSummary } from "../PaymentSummary";
import { SummaryCard } from "../SummaryCard";
import { MobileCarItineraryDetailsConnectorProps } from "./container";
import { getCurrencyString } from "../../../../../../utils/helpers";
import { HOW_REFUNDS_WORK_LINK, HOW_REFUNDS_WORK_TEXT } from "../../constants";

import "./styles.scss";

export interface IMobileCarItineraryDetailsProps
  extends RouteComponentProps,
    MobileCarItineraryDetailsConnectorProps {}

export const MobileCarItineraryDetails = ({
  car,
  history,
  setOpenModal,
  isPastTrips,
  setSelectedCar,
}: IMobileCarItineraryDetailsProps) => {
  const refundPopoverRef = useRef<HTMLButtonElement>(null);
  const [refundPopoverOpen, setRefundPopoverOpen] = useState(false);

  if (!car) {
    return null;
  }

  const { bookResult, paymentAmountInfo } = car;
  const { title, confirmationCode, startDate, endDate } =
    textConstants.getCarInfoDetails(car);
  const nights = textConstants.getCarDuration(car);
  const isCancelled = bookResult?.status?.State === State.Cancelled;
  const actions: IActionLink[] = [];
  const topActions: IActionLink[] = []; // renders at the top of the page

  const closeRefundPopover = () => setRefundPopoverOpen(false);

  const formatTime = (date: string) => dayjs(date).format("h:mm A");

  const onOpenModal = (type: MyTripsModalTypes) =>
    setOpenModal({
      type,
      selectedItinerary: addCarType(car),
    });

  const openRefundPopover = () => setRefundPopoverOpen(true);

  if (!isCancelled && !isPastTrips) {
    actions.push(
      {
        content: "Change Car Rental",
        onClick: () => onOpenModal(MyTripsModalTypes.ChangeCar),
      },
      {
        content: "Cancel Car Rental",
        onClick: () => onOpenModal(MyTripsModalTypes.CancelCar),
      }
    );
  } else if (isCancelled) {
    topActions.push({
      content: (
        <>
          {HOW_REFUNDS_WORK_LINK}
          <Icon className="info-icon" name={IconName.InfoCircle} />
        </>
      ),
      linkClassName: "how-refunds-work-link",
      linkRef: refundPopoverRef,
      onClick: openRefundPopover,
    });
  }

  actions.push({
    content: "Resend Confirmation",
    onClick: () => onOpenModal(MyTripsModalTypes.ResendConfirmation),
  });

  return (
    <Box className="mobile-trip-details">
      <Box className="mobile-trip-details-header">
        <FontAwesomeIcon
          icon={faChevronLeft}
          onClick={() => {
            history.goBack();
            setSelectedCar(null);
          }}
        />
        <Box className="header-info">
          <Typography variant="subtitle1">{title}</Typography>
          <Typography variant="caption">{nights}</Typography>
        </Box>
      </Box>
      {isCancelled && (
        <NotificationBanner
          className="status-banner"
          icon={<Icon name={IconName.ErrorAlert} />}
          label={textConstants.CANCELED_RES}
          severity={BannerSeverity.ERROR}
        />
      )}
      {topActions.length ? <ActionLinks actions={topActions} /> : null}
      <Box className="mobile-trip-card mobile-trip-summary-card">
        {isCancelled && (
          <StatusTag
            className="title-status-tag"
            tagInfo={{
              label: textConstants.CANCELED,
              type: TagColors.RED,
            }}
          />
        )}
        <Typography variant="body1" className="trips-title">
          {title}
        </Typography>
        <Box className="info-container">
          <Typography className="label" variant="caption">
            {textConstants.CONFIRMATION}
          </Typography>
          <Typography
            className={clsx("code", { warning: isCancelled })}
            variant="caption"
          >
            {confirmationCode}
          </Typography>
        </Box>
      </Box>

      <SummaryCard
        className="mobile-trip-card car-details"
        action={
          <FontAwesomeIcon
            className="mobile-right-chevron"
            onClick={() => onOpenModal(MyTripsModalTypes.BasicInfo)}
            icon={faChevronRight}
          />
        }
      >
        <Box className="car-details-container">
          <Typography variant="body2" className="title">
            {textConstants.CAR_DETAILS_MOBILE}
          </Typography>
          <Typography variant="subtitle2" className="content">
            {textConstants.CAR_DETAILS_MORE}
          </Typography>
        </Box>
      </SummaryCard>

      <SummaryCard
        className="mobile-trip-card car-details"
        action={
          <FontAwesomeIcon
            className="mobile-right-chevron"
            onClick={() => onOpenModal(MyTripsModalTypes.PickUp)}
            icon={faChevronRight}
          />
        }
      >
        <Box className="car-details-container">
          <Typography variant="body2" className="title">
            {`${textConstants.PICKED_UP_ON} ${startDate} | ${formatTime(
              bookResult.pickUp.dateTime
            )}`}
          </Typography>
          <Typography variant="subtitle2" className="content">
            {bookResult.pickUp.location.address}
          </Typography>
        </Box>
      </SummaryCard>

      <SummaryCard
        className="mobile-trip-card car-details"
        action={
          <FontAwesomeIcon
            className="mobile-right-chevron"
            onClick={() => onOpenModal(MyTripsModalTypes.DropOff)}
            icon={faChevronRight}
          />
        }
      >
        <Box className="car-details-container">
          <Typography variant="body2" className="title">
            {`${textConstants.DROP_OFF} on ${endDate} | ${formatTime(
              bookResult.dropOff.dateTime
            )}`}
          </Typography>
          <Typography variant="subtitle2" className="content">
            {bookResult.dropOff.location.address}
          </Typography>
        </Box>
      </SummaryCard>

      <SummaryCard className="mobile-trip-card car-details">
        <Box className="car-details-container">
          <Typography variant="body2" className="title">
            {textConstants.DRIVER_INFO}
          </Typography>
          <Typography variant="subtitle2" className="content">
            {`${bookResult.driver?.givenName} ${bookResult.driver?.surname}`}
          </Typography>
        </Box>
      </SummaryCard>

      <SummaryCard className="mobile-trip-card car-details">
        <Box className="car-details-container">
          <Typography variant="body2" className="title">
            {textConstants.CANCELLATION_POLICY}
          </Typography>
          <Typography variant="subtitle2" className="content">
            {bookResult.rentalRate.extensions.cancellation.desc}
          </Typography>
        </Box>
      </SummaryCard>

      <SummaryCard
        className="mobile-payment-summary mobile-trip-card"
        action={
          <FontAwesomeIcon
            className="mobile-right-chevron"
            onClick={() => onOpenModal(MyTripsModalTypes.PaymentModal)}
            icon={faChevronRight}
          />
        }
      >
        <PaymentSummary
          tripTotalAmount={getCurrencyString(bookResult.rentalRate.payNow.fiat)}
          cardLabel={getLabels(paymentAmountInfo).cardLabel}
          cardAmount={getValues(paymentAmountInfo).cardValue}
          rewardsLabel={getLabels(paymentAmountInfo).rewardLabel}
          rewardsAmount={getValues(paymentAmountInfo).rewardValue}
          showCardLabel={Boolean(getLabels(paymentAmountInfo).cardLabel)}
          showRewardsLabel={Boolean(getLabels(paymentAmountInfo).rewardLabel)}
        />
      </SummaryCard>

      <SummaryCard className="mobile-trip-card mobile-trip-card">
        <ConfirmationSummary
          confirmationCode={
            bookResult.confirmationInfo?.supplierBookingId ||
            bookResult.confirmationInfo?.providerBookingId ||
            bookResult.groundBookingId ||
            "No confirmation number"
          }
        />
      </SummaryCard>
      <MobileActionLinks actions={actions} />
      {isCancelled && (
        <Popover
          anchorEl={refundPopoverRef.current}
          anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
          className="how-refunds-work-popover"
          onClose={closeRefundPopover}
          open={refundPopoverOpen}
        >
          <Typography className="subtitle">{HOW_REFUNDS_WORK_TEXT}</Typography>
        </Popover>
      )}
    </Box>
  );
};
