export const LOADING_FLIGHT_DETAILS = "Fetching flight details";
export const HEADER_TITLE = "Flight disruption assistance";
export const HEADER_SUBTITLE = "Get help with your trip.";

export const BACK_TO_MY_TRIPS = "Back to my trips";
export const GO_BACK = "Go back";
export const DISRUPTION_PROTECTION = "Flight disruption assistance";
export const GET_HELP_WITH_TRIP = "Get help with your trip.";
export const MCP_HEADER_TITLE = "Missed connection guarantee";
export const MCR_HEADER_TITLE = "Missed connection rebooking";
export const MCP_HEADER_SUBTITLE = "Get help with your connecting flights";
