import axios from "axios";
import { CancellationInfoRequest, CancelScenario } from "redmond";

import { config } from "../../config";
import { getGroundCancellationInfoPath } from "../paths";

/**
 * @description get cancellation policy by bookingId
 * @param {CancellationInfoRequest} req Contains the reservation id
 * @return {CancelScenario} The cancellation info for the reservation passed in
 */
const getGroundCancellationInfo = (
  req: CancellationInfoRequest
): Promise<CancelScenario> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await axios.post(getGroundCancellationInfoPath, req, {
        baseURL: config.baseURL,
      });

      resolve(res.data.value);
    } catch (e) {
      reject(e);
    }
  });

export default getGroundCancellationInfo;
