import React, { ReactNode, useMemo } from "react";
import { Typography } from "@material-ui/core";
import { GenericModalContent, Icon, IconName, useDeviceTypes } from "halifax";
import clsx from "clsx";

import "./styles.scss";
import * as constants from "./constants";
import { trackEvent } from "../../../../../../../../../../api/v1/analytics/trackEvent";
import { MODAL_ALERT, ModalCategoryType, ModalScreens } from "redmond";

interface IErrorScreenProps {
  type: ErrorType;
}

export enum ErrorType {
  FailedToLoadCancellation, // Failed to load cancellation policy
  FailedToCancel, // Failed to cancel, call customer service
  FailedToSelfServeCancel, // Need to call customer service to cancel, can't do via self serve
}

const modalEventProperties = {
  screen: ModalScreens.MY_TRIPS,
  category: ModalCategoryType.TROUBLE,
};

export const ErrorScreen = (props: IErrorScreenProps) => {
  const { type } = props;
  const { matchesMobile } = useDeviceTypes();

  const content: {
    image: IconName;
    title: ReactNode;
    subtitle: ReactNode;
  } | null = useMemo(() => {
    switch (type) {
      case ErrorType.FailedToLoadCancellation:
        trackEvent({
          eventName: MODAL_ALERT,
          properties: {
            type: "cfar_cancel_policy_load_failure",
            ...modalEventProperties,
          },
        });
        return {
          image: IconName.ErrorState,
          title: constants.COULD_NOT_LOAD_CANCELLATION_TITLE,
          subtitle: (
            <Typography
              variant="inherit"
              dangerouslySetInnerHTML={{
                __html: constants.COULD_NOT_LOAD_CANCELLATION_SUBTITLE,
              }}
            />
          ),
        };
      case ErrorType.FailedToCancel:
        trackEvent({
          eventName: MODAL_ALERT,
          properties: {
            type: "cfar_cancel_failure",
            ...modalEventProperties,
          },
        });
        return {
          image: IconName.UnableToProcess,
          title: constants.CANCELLATION_FAILED_TITLE,
          subtitle: (
            <Typography
              variant="inherit"
              dangerouslySetInnerHTML={{
                __html: constants.CANCELLATION_FAILED_SUBTITLE,
              }}
            />
          ),
        };
      case ErrorType.FailedToSelfServeCancel:
        trackEvent({
          eventName: MODAL_ALERT,
          properties: {
            type: "cfar_cancel_failure",
            ...modalEventProperties,
          },
        });
        return {
          image: IconName.UnableToProcess,
          title: constants.COULD_NOT_SELF_SERVE_CANCEL_TITLE,
          subtitle: (
            <Typography
              variant="inherit"
              dangerouslySetInnerHTML={{
                __html: constants.COULD_NOT_SELF_SERVE_CANCEL_SUBTITLE,
              }}
            />
          ),
        };
      default:
        return null;
    }
  }, [type]);

  if (!content) {
    return null;
  }

  return (
    <GenericModalContent
      className={clsx("hotel-cfar-model-content-error-screen-root", {
        mobile: matchesMobile,
      })}
      title={content.title}
      subtitle={content.subtitle}
      image={<Icon className="error-icon" name={content.image} />}
    />
  );
};
