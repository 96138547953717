import { getCurrencySymbol, getPriceString, getRewardText, twoDecimalFormatter } from "halifax";
import { Amount, RewardsPrice } from "redmond";
import { REFUND_PRICES_FIAT_AND_REWARDS } from "./constants";

export const getCombinedRefundPricesString = (
  refundPricesInFiatOnly?: string,
  refundPricesInRewardsOnly?: string
) => {
  if (refundPricesInRewardsOnly && refundPricesInFiatOnly) {
    return REFUND_PRICES_FIAT_AND_REWARDS(
      refundPricesInFiatOnly,
      refundPricesInRewardsOnly
    );
  }

  if (refundPricesInRewardsOnly) {
    return refundPricesInRewardsOnly;
  }

  if (refundPricesInFiatOnly) {
    return refundPricesInFiatOnly;
  }

  // Note the impossible edge case of fiat value of 0 and also no rewards will also return "" as a placeholder.
  return null;
};

export const refundPricesCombinationToString = (prices: {
  rewardsCredit?: RewardsPrice;
  userCardCredit?: Amount;
}) => {
  let refundPricesInFiatOnly;
  let refundPricesInRewardsOnly;

  if (prices.userCardCredit) {
    refundPricesInFiatOnly = getPriceString({
      price: prices.userCardCredit.amount,
      currencySymbol: getCurrencySymbol(prices.userCardCredit.currency),
      priceFormatter: twoDecimalFormatter,
    });
  }

  if (prices.rewardsCredit) {
    refundPricesInRewardsOnly = getRewardText({ reward: prices.rewardsCredit });
  }

  return getCombinedRefundPricesString(
    refundPricesInFiatOnly,
    refundPricesInRewardsOnly
  );
};
