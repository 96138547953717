export enum DiscountType {
  PRICE_FREEZE = "PriceFreeze",
  PRICE_DROP = "PriceDrop",
  PRICE_DROP_AND_PRICE_FREEZE = "PriceDropAndPriceFreeze",
  CARROT_CASH = "CarrotCash",
  CHFAR_REDEMPTION = "ChfarRedemption",
}

export const PriceFreezeDiscountTypes = [
  DiscountType.PRICE_FREEZE,
  DiscountType.PRICE_DROP_AND_PRICE_FREEZE,
];

export enum TagType {
  Algomerch = "algomerch",
  PriceFreeze = "pricefreeze",
}

export enum AlgomerchTag {
  BestFlight = "BestFlight",
  Cheapest = "Cheapest",
  BestQuality = "BestQuality",
  Fastest = "Fastest",
}

export const FrozenPriceTag = "FrozenPrice";
export type FrozenPriceTag = typeof FrozenPriceTag;

export type TagValue = AlgomerchTag | FrozenPriceTag;
