import {
  HotelCfarCancellationRequest,
  HotelCfarCancellationResponse,
} from "redmond";
import { cancelCfarHotelPath } from "../paths";
import { config } from "../../config";
import axios from "axios";

export const confirmHotelCfarCancellation = (
  req: HotelCfarCancellationRequest
): Promise<HotelCfarCancellationResponse> => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await axios.post(cancelCfarHotelPath, req, {
        baseURL: config.baseURL,
      });
      resolve(res.data);
    } catch (e) {
      reject(e);
    }
  });
};
