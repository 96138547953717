import React, { useEffect, useMemo, useState } from "react";
import { Box, Divider, Typography } from "@material-ui/core";
import clsx from "clsx";
import {
  FlightDetailsSummary,
  ScheduleChangeForm,
  StatusTag,
  MixedCabinToolTip,
  ActionLink,
} from "halifax";
import { last } from "lodash-es";
import { RouteComponentProps } from "react-router-dom";
import {
  Airline,
  AirlineMap,
  BookedFlightItineraryWithDepartureTime,
  HotelItinerary,
  ItineraryEnum,
  MyTripsModalTypes,
  SCHEDULE_CHANGE_DECISION,
  ScheduleChangeFlow,
  ScheduleChangeUserActionEnum,
  TimeGroupingEnum,
  VIEWED_MAJOR_SCHEDULE_CHANGE,
  getDepartureSlice,
  getReturnSlice,
} from "redmond";

import { trackEvent } from "../../../../../../../../api/v1/analytics/trackEvent";
import { ScheduleChangeStateModal } from "./components/ScheduleChangeRequestedModal";
import * as constants from "./constants";
import { getIsMixedClass } from "../../../../../../constants";
import { ScheduleChangeModalContentConnectorProps } from "./container";
import {
  ActiveExperiments,
  useExperiment,
} from "../../../../../../../../context/experiments";
import { getAirlineName } from "../../../../../../utils";

import "./styles.scss";

export interface IScheduleChangeModalContentProps
  extends ScheduleChangeModalContentConnectorProps,
    RouteComponentProps {
  flight: BookedFlightItineraryWithDepartureTime;
  airlineMap: { [key: string]: Airline };
  isMobile: boolean;
  setSelectedFlight: (
    selectedFlight: BookedFlightItineraryWithDepartureTime | null
  ) => void;
  setSelectedHotel: (selectedHotel: HotelItinerary | null) => void;
}

export enum ScheduleChangeState {
  Display,
  InProgress,
  Requested,
  Accepted,
  Failure,
}

export const ScheduleChangeModalContent = (
  props: IScheduleChangeModalContentProps
) => {
  const {
    flight,
    airportMap,
    airlineMap,
    isMobile,
    setOpenModal,
    setSelectedFlight,
    setSelectedHotel,
    acceptScheduleChange,
    denyScheduleChange,
    history,
    acceptState,
    denyState,
    viewedMajorScheduleChangeProperties,
  } = props;
  const {
    OG_getFlightDetailsProps,
    getFlightDetailsProps,
    getItineraryDetailsHeader,
    getLatestRecommendedSegments,
    getTagInfo,
    getTimeChoice,
    isUpdated,
    statusIsYK,
  } = constants;
  const { bookedItinerary } = flight;
  const { id: itineraryId } = bookedItinerary;
  const scheduleChange = bookedItinerary.scheduleChange!;
  const ykExp = useExperiment(ActiveExperiments.SKCHNextDisplay);
  const [action, setAction] = useState<ScheduleChangeUserActionEnum | null>(
    null
  );
  const [isOutgoingMixedClass, setIsOutgoingMixedClass] = useState(false);
  const [isReturnMixedClass, setIsReturnMixedClass] = useState(false);
  const [scheduleChangeState, setScheduleChangeState] = useState(
    ScheduleChangeState.Display
  );
  const departureSlice = getDepartureSlice(bookedItinerary);
  const returnSlice = getReturnSlice(bookedItinerary);
  const isRoundTrip = Boolean(returnSlice);
  const outboundChanged = isUpdated(departureSlice);
  const returnChanged = returnSlice && isUpdated(returnSlice);
  const airlineName = getAirlineName(
    airlineMap as AirlineMap,
    departureSlice.segments
  );
  const withinContactAirlineLimit =
    scheduleChange.userDenyFlow.UserDenyFlow ===
    ScheduleChangeFlow.ReferToAirline;

  const [outboundOpts, returnOpts] = useMemo(() => {
    const { next: [nxtOutboundSlice, nxtReturnSlice] = [] } = scheduleChange;
    const origin = departureSlice.segments[0]?.origin.locationCode;
    const dest = last(departureSlice.segments)?.destination.locationCode;
    let outboundRec;

    // return original segments if using original strategy
    if (!ykExp)
      return [nxtOutboundSlice.segments, nxtReturnSlice?.segments ?? []];

    if (isRoundTrip) {
      // "Rx" = Recommendation
      const outboundRx = getLatestRecommendedSegments(
        nxtOutboundSlice.segments,
        origin,
        dest!
      );
      const returnRx = getLatestRecommendedSegments(
        nxtReturnSlice?.segments,
        dest!,
        origin
      );
      const returnRec = returnRx.length ? returnRx : returnSlice?.segments;
      outboundRec = outboundRx.length ? outboundRx : departureSlice.segments;

      return [outboundRec, returnRec ?? null];
    }

    const allSegments = [
      ...(nxtOutboundSlice?.segments ?? []),
      ...(nxtReturnSlice?.segments ?? []),
    ];
    const outboundRx = getLatestRecommendedSegments(allSegments, origin, dest!);
    outboundRec = outboundRx.length ? outboundRx : departureSlice.segments;

    return [outboundRec, null];
  }, [departureSlice, isRoundTrip, returnSlice, scheduleChange, ykExp]);

  const hasYKSegment = useMemo(() => {
    if (ykExp) {
      const allOpts = [...outboundOpts, ...(returnOpts ?? [])];

      return allOpts.some(statusIsYK);
    }

    const { next } = scheduleChange;
    let hasYK = false;

    if (next.length) {
      hasYK = next.some((slice) => slice.segments.some(statusIsYK));
    }

    return hasYK;
  }, [outboundOpts, returnOpts]);

  const outboundTagInfo = getTagInfo(outboundOpts, outboundChanged);
  const returnTagInfo = returnOpts && getTagInfo(returnOpts, returnChanged);

  useEffect(() => {
    trackEvent({
      eventName: VIEWED_MAJOR_SCHEDULE_CHANGE,
      properties: {
        ...viewedMajorScheduleChangeProperties,
      },
    });
  }, []);

  const onClose = () => {
    setSelectedHotel(null);
    setSelectedFlight(flight);
    setOpenModal({ type: null, selectedItinerary: null });
  };

  const handleScheduleChangeAction = (
    changeAction: ScheduleChangeUserActionEnum | null,
    outboundTimes: TimeGroupingEnum[],
    inboundTimes: TimeGroupingEnum[]
  ) => {
    if (changeAction) {
      setAction(changeAction);
      switch (changeAction) {
        case ScheduleChangeUserActionEnum.Accept:
          setScheduleChangeState(ScheduleChangeState.InProgress);
          trackEvent({
            eventName: SCHEDULE_CHANGE_DECISION,
            properties: {
              ...viewedMajorScheduleChangeProperties,
              decision_outcome: "accept",
            },
          });
          acceptScheduleChange(history, itineraryId);
          break;
        case ScheduleChangeUserActionEnum.Deny: {
          // sort to EARLY_MORNING, MORNING, AFTERNOON, EVENING order
          const sortedOutbound = outboundTimes.sort((a, b) => a - b);
          const sortedInbound = inboundTimes.sort((a, b) => a - b);

          setScheduleChangeState(ScheduleChangeState.InProgress);
          trackEvent({
            eventName: SCHEDULE_CHANGE_DECISION,
            properties: {
              ...viewedMajorScheduleChangeProperties,
              decision_outcome: "deny",
            },
          });
          denyScheduleChange(
            {
              itineraryId: itineraryId,
              outboundPreferences: sortedOutbound.map((val) =>
                getTimeChoice(val, scheduleChange.next[0])
              ),
              returnPreferences: sortedInbound.map((val) =>
                getTimeChoice(val, scheduleChange.next[1])
              ),
              additionalRemarks: "",
            },
            history
          );
          break;
        }
        default:
      }
    }
  };

  useEffect(() => {
    if (departureSlice) {
      setIsOutgoingMixedClass(getIsMixedClass(departureSlice));
    }
    if (returnSlice) {
      setIsReturnMixedClass(getIsMixedClass(returnSlice));
    }
  }, [flight]);

  return (
    <Box
      className={clsx("schedule-change-modal", {
        mobile: isMobile,
        "schedule-change-state":
          scheduleChangeState !== ScheduleChangeState.Display,
      })}
    >
      {scheduleChangeState === ScheduleChangeState.Display ? (
        <React.Fragment>
          <Typography className="schedule-change-modal-header" variant="h2">
            {constants.SCHEDULE_CHANGE_FORM_TITLES.headingCopy(
              airlineName,
              hasYKSegment
            )}
          </Typography>
          <Typography
            className="schedule-change-modal-description"
            variant="subtitle2"
          >
            {constants.SCHEDULE_CHANGE_FORM_TITLES.description(
              airlineName,
              hasYKSegment
            )}
          </Typography>
          <Typography className="subheading-two" variant="body1">
            {constants.SCHEDULE_CHANGE_FORM_TITLES.subheadingTwo(hasYKSegment)}
          </Typography>
          <ActionLink
            content={
              <Typography
                className="view-previous-itinerary"
                variant="body1"
                color="primary"
                onClick={onClose}
              >
                {constants.VIEW_PREVIOUS_ITINERARY}
              </Typography>
            }
          />
          <Box className="flight-summary-container">
            <Box className="outbound-itinerary">
              <Box className="slice-info-title">
                <StatusTag
                  className="slice-info-update-tag"
                  tagInfo={outboundTagInfo}
                />
                <Typography variant="subtitle2">
                  <span className="direction-label">{constants.OUTBOUND}</span>
                  {getItineraryDetailsHeader(scheduleChange!, true, airportMap)}
                  {isOutgoingMixedClass && <MixedCabinToolTip />}
                </Typography>
              </Box>
              {ykExp ? (
                <FlightDetailsSummary
                  className="outbound-details"
                  {...getFlightDetailsProps(
                    outboundOpts,
                    airportMap,
                    airlineMap
                  )}
                  isMixedCabinClass={isOutgoingMixedClass}
                />
              ) : (
                <FlightDetailsSummary
                  className="outbound-details"
                  {...OG_getFlightDetailsProps(
                    scheduleChange,
                    true,
                    airportMap,
                    airlineMap
                  )}
                  isMixedCabinClass={isOutgoingMixedClass}
                />
              )}
            </Box>
            {!ykExp && scheduleChange && scheduleChange.next.length > 1 && (
              <Box className="return-itinerary">
                <Box className="slice-info-title">
                  <StatusTag
                    className="slice-info-update-tag"
                    tagInfo={returnTagInfo!}
                  />
                  <Typography variant="subtitle2">
                    <span className="direction-label">{constants.RETURN}</span>
                    {getItineraryDetailsHeader(
                      scheduleChange,
                      false,
                      airportMap
                    )}
                    {isReturnMixedClass && <MixedCabinToolTip />}
                  </Typography>
                </Box>
                <FlightDetailsSummary
                  className="return-details"
                  {...OG_getFlightDetailsProps(
                    scheduleChange,
                    false,
                    airportMap,
                    airlineMap
                  )}
                  isMixedCabinClass={isReturnMixedClass}
                />
              </Box>
            )}
            {ykExp && returnOpts && (
              <Box className="return-itinerary">
                <Box className="slice-info-title">
                  <StatusTag
                    className="slice-info-update-tag"
                    tagInfo={returnTagInfo!}
                  />
                  <Typography variant="subtitle2">
                    <span className="direction-label">{constants.RETURN}</span>
                    {getItineraryDetailsHeader(
                      scheduleChange,
                      false,
                      airportMap
                    )}
                    {isReturnMixedClass && <MixedCabinToolTip />}
                  </Typography>
                </Box>
                <FlightDetailsSummary
                  className="return-details"
                  {...getFlightDetailsProps(returnOpts, airportMap, airlineMap)}
                  isMixedCabinClass={isReturnMixedClass}
                />
              </Box>
            )}
          </Box>
          <Divider className="schedule-change-modal-divider" />
          <ScheduleChangeForm
            timeGroupings={constants.TIMES}
            action={
              <ActionLink
                className="view-cancelation-button"
                onClick={() => {
                  return setOpenModal({
                    type: MyTripsModalTypes.SelfServeCancelFlight,
                    selectedItinerary: {
                      ...flight,
                      type: ItineraryEnum.Flight,
                    },
                  });
                }}
                content={constants.VIEW_CANCELATION_ELIGIBILITY}
              />
            }
            titles={{
              acceptButtonTitle:
                constants.SCHEDULE_CHANGE_FORM_TITLES.acceptButtonTitle(
                  hasYKSegment
                ),
              acceptDetails:
                constants.SCHEDULE_CHANGE_FORM_TITLES.acceptDetails(
                  hasYKSegment
                ),
              denyButtonTitle:
                constants.SCHEDULE_CHANGE_FORM_TITLES.denyButtonTitle(
                  hasYKSegment
                ),
              denyDetails: constants.SCHEDULE_CHANGE_FORM_TITLES.denyDetails(
                hasYKSegment,
                withinContactAirlineLimit
              ),
              headingCopy: constants.SCHEDULE_CHANGE_FORM_TITLES.headingTwoCopy(
                airlineName,
                hasYKSegment
              ),
              outboundTimePreferenceCopy:
                constants.SCHEDULE_CHANGE_FORM_TITLES.timePreferenceCopy(true),
              returnTimePreferenceCopy:
                constants.SCHEDULE_CHANGE_FORM_TITLES.timePreferenceCopy(false),
            }}
            outboundChanged={outboundChanged}
            returnChanged={returnChanged}
            isMobile={!!isMobile}
            onClick={handleScheduleChangeAction}
            withinContactAirlineLimit={withinContactAirlineLimit}
          />
        </React.Fragment>
      ) : (
        <ScheduleChangeStateModal
          acceptCallState={acceptState}
          action={action}
          denyCallState={denyState}
          isPassive={hasYKSegment}
          onClose={onClose}
          scheduleChangeState={scheduleChangeState}
          setScheduleChangeState={setScheduleChangeState}
          withinContactAirlineLimit={withinContactAirlineLimit}
        />
      )}
    </Box>
  );
};
