import { GENERAL_SUPPORT_PHONE_NUMBER, RewardsPrice, Amount } from "redmond";
import {
  getRewardText,
  twoDecimalFormatter,
  getPriceString,
  getCurrencySymbol,
} from "halifax";

// Common
export const READ_TERMS_AND_CONDITIONS = "Read terms and conditions";
export const CANCEL_FOR_ANY_REASON_TITLE = "Cancel for any reason";
export const CANCELLATION_INFORMATION_HEADER = "Cancellation Information";
export const CONTINUE_BUTTON_COPY = "Continue";
export const CONFIRM_CANCELLATION_TITLE = "Confirm cancellation";
export const CONFIRM_CANCELLATION_BUTTON_COPY = "Confirm cancellation";
export const GO_BACK = "Go Back";
export const CONFIRM_CANCELLATION_TEXT =
  "Complete the cancellation of your round-trip flight for all travelers.";
export const CONTACT_SUPPORT_COPY = "Contact Support";

type CfarCashSubtitleParam =
  | {
      coveragePercent?: number;
      copyVariant: "percent";
    }
  | {
      cash: Amount | undefined;
      travelCredit: Amount | undefined;
      reward: RewardsPrice | undefined;
      copyVariant: "amount";
    };

// CfarRefund
export const getCfarCashSubtitle = (
  cfarCashSubtitleParam: CfarCashSubtitleParam
) => {
  switch (cfarCashSubtitleParam.copyVariant) {
    case "percent":
      return `You'll receive a <strong>${
        /*
        note: when CfarRefundable isn't present, it should still display the subtitle copy with 100% refund;
        see https://hopper-jira.atlassian.net/browse/BF-1672
      */
        cfarCashSubtitleParam.coveragePercent ?? 100
      }%</strong> refund of your hotel base rate and taxes back to your original payment method.`;
    case "amount":
      const { cash, reward, travelCredit } = cfarCashSubtitleParam;
      const cfarAmountText = getCfarRefundAmountTextWithTravelCredit({
        cash,
        reward,
        travelCredit,
      });
      if (!cfarAmountText) {
        return "You'll receive a refund back to your original payment method.";
      }

      return `You'll receive <strong>a refund of ${cfarAmountText}</strong>${
        cash?.amount && cash.amount > 0
          ? " back to your original payment method"
          : ""
      }.`;
  }
};

export const getCfarRefundAmountSubtitle = ({
  cash,
  reward,
  travelCredit,
}: {
  cash: Amount | undefined;
  reward: RewardsPrice | undefined;
  travelCredit: Amount | undefined;
}) => {
  const cfarAmountText = getCfarRefundAmountTextWithTravelCredit({
    cash,
    reward,
    travelCredit,
  });

  if (!cfarAmountText) {
    return "You'll receive a refund back to your original payment method. You should receive your refund within the next 1-2 billing cycles, but in some cases it may take longer.";
  }

  return `You’ll receive a refund of ${getStrongText(cfarAmountText)}${
    cash?.amount && cash.amount > 0 ? " to your original payment method" : ""
  }. You should receive your refund within the next 1-2 billing cycles, but in some cases it may take longer.`;
};

export const getCfarRefundAmountText = ({
  cash,
  reward,
}: {
  cash: Amount | undefined;
  reward: RewardsPrice | undefined;
}) => {
  const cashText = `${
    cash !== undefined && cash.amount != 0
      ? getPriceString({
          price: cash.amount,
          currencySymbol: getCurrencySymbol(cash.currency),
          priceFormatter: twoDecimalFormatter,
        })
      : ""
  }`;

  const rewardsText = `${
    reward && reward.value != 0
      ? getRewardText({
          reward,
        })
      : ""
  }`;

  const cfarAmountText =
    cashText && rewardsText
      ? `${cashText} and ${rewardsText}`
      : cashText
      ? cashText
      : rewardsText
      ? rewardsText
      : "";

  return cfarAmountText;
};

export const getCfarRefundAmountTextWithTravelCredit = ({
  cash,
  reward,
  travelCredit,
}: {
  cash: Amount | undefined;
  reward: RewardsPrice | undefined;
  travelCredit: Amount | undefined;
}) => {
  const cashText = `${
    cash !== undefined && cash.amount != 0
      ? getPriceString({
          price: cash.amount,
          currencySymbol: getCurrencySymbol(cash.currency),
          priceFormatter: twoDecimalFormatter,
        })
      : ""
  }`;

  const travelCreditText = `${
    travelCredit !== undefined && travelCredit.amount != 0
      ? getPriceString({
          price: travelCredit.amount,
          currencySymbol: getCurrencySymbol(travelCredit.currency),
          priceFormatter: twoDecimalFormatter,
        }) + " in travel credit"
      : ""
  }`;

  const rewardsText = `${
    reward && reward.value != 0
      ? getRewardText({
          reward,
        })
      : ""
  }`;

  const refunds = [rewardsText, travelCreditText, cashText].filter((r) => !!r);

  switch (refunds.length) {
    case 0:
      return undefined;
    case 1:
      return refunds[0];
    case 2:
      return refunds.join(" and ");
    case 3:
      const lastItem = refunds.pop();
      return refunds.join(", ") + " and " + lastItem;
    default:
      return undefined;
  }
};

export const getStrongText = (html: string) => `<strong>${html}</strong>`;

export const getCfarConfirmInformation = () => [
  "You must cancel your booking before the check-in time on your reservation.",
  "Cancellations cannot be reversed.",
  "Refunds will be issued using the same method of payment as the original booking.",
  "Any travel offers and paid add-ons such as cancel for any reason are not included.",
  `If you have any questions about canceling your booking, please contact us at ${GENERAL_SUPPORT_PHONE_NUMBER.boldText}.`,
];

export const COMPLETE_CANCELLATION_SUBTITLE = `Complete the cancellation of your booking.`;

// Cancellation Pending
export const CLOSE_BUTTON_COPY = "Close";
export const PENDING_CANCELLATION_TITLE = "We're processing your confirmation";
export const PENDING_CANCELLATION_SUBTITLE =
  "Your booking has already been cancelled. A confirmation email will be sent and My Trips will be updated within 1-2 hours.";

/*
  note: DIALOG_MARGIN is the same as the margin in MuiDialog-paperScrollPaper, which comes from material-ui.Dialog;
  MuiDialog-paperScrollPaper has max-height set to calc(100% - 2 * 32px).
*/
export const DIALOG_MARGIN = 32;

export const getPartialScrollThreshold = () => {
  let height = 750;
  return `(max-height: ${height + 2 * DIALOG_MARGIN}px)`;
};
