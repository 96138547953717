const createPhoneNumberLink = (phoneNumber: string) =>
  `<a href="tel:${phoneNumber}" style="white-space: nowrap;">${phoneNumber}</a>`;

const createPhoneNumberBoldLink = (phoneNumber: string) =>
  `<b>${createPhoneNumberLink(phoneNumber)}</b>`;

const createPhoneNumberBoldText = (phoneNumber: string) =>
  `<b>${phoneNumber}</b>`;

const createPhoneNumber = (phoneNumber: string) => {
  return {
    text: phoneNumber,
    boldText: createPhoneNumberBoldText(phoneNumber),
    link: createPhoneNumberLink(phoneNumber),
    boldLink: createPhoneNumberBoldLink(phoneNumber),
  };
};

const DP_SUPPORT_PHONE_NUMBER_RAW = "833-265-4540";
export const DP_SUPPORT_PHONE_NUMBER = createPhoneNumber(
  DP_SUPPORT_PHONE_NUMBER_RAW
);

const GENERAL_SUPPORT_PHONE_NUMBER_RAW = "844-422-6922";
export const GENERAL_SUPPORT_PHONE_NUMBER = createPhoneNumber(
  GENERAL_SUPPORT_PHONE_NUMBER_RAW
);
