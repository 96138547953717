import React from "react";
import { CarReservation, DistKindEnum } from "redmond";
import { Typography, Box } from "@material-ui/core";
import { CarBasicInfo } from "halifax";
import { getCarSizeText } from "../../../CarCard/components/DesktopCarCard/helpers";
import { CAR_DETAILS_MOBILE } from "../../../CarCard/constants";
import {
  CarCancellationPolicy,
  FuelPolicy,
} from "../../../CarCard/components/CarInfoContent/component";
import "./styles.scss";

interface ICarBasicInfoModalContentProps {
  car: CarReservation;
}

export const CarBasicInfoModalContent = ({
  car,
}: ICarBasicInfoModalContentProps) => {
  const { bookResult } = car;
  const { rentalRate, vehicle } = bookResult;
  const { passengerQty, bagsInfo } = vehicle;
  const bags = Object.values(bagsInfo).reduce((a, b) => a + b, 0);
  const fuelPolicy = rentalRate.extensions.fuelPolicy?.desc;
  return (
    <Box className="car-basic-modal-content">
      <Box className="car-basic-title-container">
        <Typography variant="h4">{CAR_DETAILS_MOBILE}</Typography>
      </Box>
      <Box className="car-info-container">
        <CarBasicInfo
          key={car.bookResult.groundBookingId}
          carModelDetails={{
            carSize: getCarSizeText(vehicle.kind),
            // TODO: ask about where we get multi-car entries
            carModels: [vehicle.name],
          }}
          carDetailsText={{
            orText: "or",
            orSimilarText: "or similar",
            unlimitedMileageText: "Unlimited Mileage",
            freeCancellationText: "Free Cancellation",
            nonRefundableText: "Non refundable",
            selectButtonText: "Select",
          }}
          carInfoIconDetails={{
            passengerCapacity: passengerQty
              ? `${passengerQty} ${
                  passengerQty > 1 ? "Passengers" : "Passenger"
                }`
              : "0 Passengers",
            bagCapacity: `${bags} ${bags === 1 ? "Bag" : "Bags"}`,
            transmissionType: vehicle.transmissionType,
            airConditioning: vehicle.airConditioning
              ? "Air Conditioning"
              : undefined,
          }}
          carAvailabilityTagDetails={{
            freeCancellation: rentalRate.extensions.cancellation.free,
            hasUnlimitedMileage:
              rentalRate.extensions.distance.DistKind ===
              DistKindEnum.Unlimited,
          }}
          topRightElement={<></>}
        />
        <Box className="car-policy-container">
          <Box className="divider" />
          <CarCancellationPolicy car={car} />
        </Box>
        {fuelPolicy && (
          <Box className="car-policy-container">
            <Box className="divider" />
            <FuelPolicy car={car} />
          </Box>
        )}
      </Box>
    </Box>
  );
};
