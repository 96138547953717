import { actionTypes } from "../actions";
import { takeLatest } from "redux-saga/effects";
import { fetchFlightDisruptionsSaga } from "./fetchFlightDisruptionsSaga";
import { fetchDisruptionPreparedFlightRefundSaga } from "./fetchDisruptionPreparedFlightRefundSaga";
import { submitDisruptionFlightRefundSaga } from "./submitDisruptionFlightRefundSaga";

export function* watchFetchFlightDisruptionsSaga() {
  yield takeLatest(
    actionTypes.FETCH_FLIGHT_DISRUPTIONS,
    fetchFlightDisruptionsSaga
  );
}

export function* watchFetchDisruptionPreparedFlightRefundSaga() {
  yield takeLatest(
    actionTypes.FETCH_DISRUPTION_PREPARED_FLIGHT_REFUND,
    fetchDisruptionPreparedFlightRefundSaga
  );
}

export function* watchSubmitDisruptionFlightRefundSaga() {
  yield takeLatest(
    actionTypes.SUBMIT_DISRUPTION_FLIGHT_REFUND,
    submitDisruptionFlightRefundSaga
  );
}
