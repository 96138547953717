import dayjs from "dayjs";
import { LodgingAddress, LodgingAddressExact } from "redmond";

export const CHECK_IN = "Check-in";
export const CHECK_OUT = "Check-out";
export const DATE_FORMAT = "ddd MMM, DD";

export const getFormattedDate = (date: string) => {
  return dayjs(date).format(DATE_FORMAT);
};

export const getFormattedAddress = (address: LodgingAddress) => {
  const formatAddress = address as LodgingAddressExact;
  let result = "";
  if (formatAddress.line1) {
    result = formatAddress.line1;
    if (formatAddress.line2) {
      result += `, ${formatAddress.line2}`;
    }
  }
  return result;
};
