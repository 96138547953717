import { faCreditCard } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography } from "@material-ui/core";
import React from "react";
import { FiatPrice, HomesItinerary } from "redmond";
import {
  getTotalPriceText,
  Icon,
  IconName,
  twoDecimalFormatter,
} from "halifax";
import { formatPaymentBreakdown, ILineItem } from "../../../FlightCard/helpers";
import * as textConstants from "./constants";
import "./styles.scss";
import {
  calculateHomeTotals,
  getCurrencyString,
} from "../../../../../../../../utils/helpers";

interface IHomePaymentModalContentProps {
  homeItinerary: HomesItinerary;
}

export const HomePaymentModalContent = ({
  homeItinerary,
}: IHomePaymentModalContentProps) => {
  const { reservation, paymentBreakdown } = homeItinerary;
  const { rate } = reservation;
  const { payNowTotal } = rate.detailedPrice;

  const { paidTotal, calculatedTripTotal } = calculateHomeTotals(
    reservation,
    paymentBreakdown
  );

  const lineItems = formatPaymentBreakdown(paymentBreakdown);

  const renderPaymentForTravelers = () => {
    const paidAtTimeOfBooking: FiatPrice = paidTotal
      ? {
          currencyCode: paidTotal.currencyCode,
          currencySymbol: paidTotal.currencySymbol,
          value: paidTotal.value,
        }
      : payNowTotal.fiat;
    return (
      <Box className="home-payment-container">
        <Box className="home-payment-details-container">
          <Typography className="payment-price-breakdown" variant="h4">
            {textConstants.PRICE_BREAKDOWN}
          </Typography>
          <Box className="home-pricing-row">
            <Typography variant="body2">
              {`${reservation.lengthOfStay} night${
                reservation.lengthOfStay > 1 ? "s" : ""
              }`}
            </Typography>
            <Typography variant="body2">
              {getTotalPriceText({
                price: reservation.rate.detailedPrice.payNowTotal.fiat,
                priceFormatter: twoDecimalFormatter,
              })}
            </Typography>
          </Box>

          {rate.detailedPrice.fees.map((fee) => {
            return (
              <Box className="home-pricing-row">
                <Typography>{fee.name ?? fee.kind.Kind}</Typography>
                <Typography>
                  {getTotalPriceText({
                    price: fee.amount.fiat,
                    priceFormatter: twoDecimalFormatter,
                  })}
                </Typography>
              </Box>
            );
          })}

          <Box className="home-pricing-row">
            <Typography variant="body2" className="price-bold-row">
              {textConstants.PAID_AT_TIME_OF_BOOKING}
            </Typography>
            <Typography variant="body2" className="price-bold-row">
              {getTotalPriceText({
                price: paidAtTimeOfBooking,
                priceFormatter: twoDecimalFormatter,
              })}
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  };

  const renderTripTotal = () => {
    return (
      <Box className="trip-total-row">
        <Typography variant="body1" className="trip-total-text">
          {textConstants.TRIP_TOTAL}
        </Typography>
        <Typography variant="body1" className="trip-total-text">
          {getCurrencyString({
            currencyCode: calculatedTripTotal.currencyCode,
            currencySymbol: calculatedTripTotal.currencySymbol,
            value: calculatedTripTotal.value,
          })}
        </Typography>
      </Box>
    );
  };

  const renderRewards = () => {
    const rewards = lineItems.rewards;
    if (!rewards) {
      return null;
    }
    return (
      <Box className="rewards-container">
        <Typography variant="body2">{rewards.label}</Typography>
        <Typography variant="body1" className="rewards-points">
          {rewards.amount}
        </Typography>
      </Box>
    );
  };

  const renderPayment = () => {
    const userCard = lineItems.userCard;
    if (!userCard) {
      return null;
    }
    return (
      <Box className="payment-card-container">
        <Box className="payment-card-details-container">
          <FontAwesomeIcon icon={faCreditCard} className="credit-card-icon" />
          <Typography variant="body2" className="payment-card-details">
            {userCard.label}
          </Typography>
        </Box>
        <Typography variant="body1" className="payment-card-amount">
          {userCard.amount}
        </Typography>
      </Box>
    );
  };

  const renderTravelWallet = (
    travelWallet: ILineItem | null,
    icon: IconName
  ) => {
    if (!travelWallet) return null;
    return (
      <Box className="offers-container">
        <Box className="offers-icon-label">
          <Icon name={icon} />
          <Typography variant="body2">{travelWallet.label}</Typography>
        </Box>
        <Typography variant="body1" className="offer-amount">
          {travelWallet.amount}
        </Typography>
      </Box>
    );
  };

  const renderOffers = () =>
    renderTravelWallet(lineItems.travelWalletOffer, IconName.OfferTag);

  const renderCredits = () =>
    lineItems.travelWalletCredit?.map((credit) =>
      renderTravelWallet(credit, IconName.PiggyBank)
    );

  return (
    <Box className="home-payment-modal-content">
      <Box className="home-payment-title-container">
        <Typography variant="h4">{textConstants.TRIP_TOTAL}</Typography>
      </Box>
      {renderPaymentForTravelers()}
      {renderTripTotal()}
      {renderOffers()}
      {renderCredits()}
      {renderRewards()}
      {renderPayment()}
    </Box>
  );
};
