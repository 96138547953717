import { select } from "redux-saga/effects";

import { IStoreState } from "../../../reducers/types";
import { IPopulateTripQueryParams } from "../actions/actions";
import * as H from "history";
import queryStringParser from "query-string";
import { getTripId, getTripsFilter } from "../reducer";
import { PATH_HOME } from "../../../utils/paths";

export interface ITripParsedQuery {
  tripId: string;
  tripsFilter: string;
}

export function* populateTripQueryParamsSaga({
  history,
  queryParams,
}: IPopulateTripQueryParams) {
  const state: IStoreState = yield select();
  populateTripQueryParametersFromState(state, history, queryParams);
}

export const populateTripQueryParametersFromState = (
  state: IStoreState,
  history: H.History,
  queryParams?: { tripId?: string }
) => {
  const queryParamsTripId = queryParams?.tripId;

  const { tripId, tripsFilter } = getExistingStateVariables(state);

  const queryString = parseQueryString(history);

  if (stateDiffersFromQueryParams(state, queryString)) {
    const newQuery = { tripsFilter };
    if (queryParamsTripId || tripId) {
      newQuery["tripId"] = queryParamsTripId ?? tripId;
    }

    const location = {
      pathname: PATH_HOME,
      search: queryStringParser.stringify(newQuery),
    };

    history.push(location);
  }

  return queryString;
};

export function getExistingStateVariables(state: IStoreState) {
  return {
    tripsFilter: getTripsFilter(state),
    tripId: getTripId(state),
  };
}

export const parseQueryString = (history: H.History): ITripParsedQuery => {
  const queryString = history?.location?.search || "";

  const parsedQueryStringPrimitive = queryStringParser.parse(queryString);
  return {
    tripsFilter: parsedQueryStringPrimitive.tripsFilter as unknown as string,
    tripId: parsedQueryStringPrimitive.tripId as string,
  };
};

export function stateDiffersFromQueryParams(
  state: IStoreState,
  parsedQueryString: ITripParsedQuery
) {
  const { tripsFilter, tripId } = getExistingStateVariables(state);

  const diffTripsFilter =
    !parsedQueryString.tripsFilter ||
    parsedQueryString.tripsFilter !== tripsFilter;
  const diffTripId =
    !parsedQueryString.tripId || parsedQueryString.tripId !== tripId;

  return !!(diffTripsFilter || diffTripId);
}
