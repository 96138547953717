import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { RouteComponentProps } from "react-router-dom";
import { CarReservation } from "redmond";
import { IStoreState } from "../../../../../../../../reducers/types";
import { setOpenModal } from "../../../../../../actions/actions";
import ChangeGroundModalContent from "./component";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const mapStateToProps = (_: IStoreState) => ({});

const mapDispatchToProps = {
  setOpenModal,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type ChangeGroundModalContentConnectorProps = ConnectedProps<typeof connector>;

export interface IChangeGroundModalContentProps
  extends ChangeGroundModalContentConnectorProps,
    RouteComponentProps {
  groundBooking: CarReservation;
  isMobile?: boolean;
}

export const ConnectedGroundChangeModalContent = connector(
  withRouter(ChangeGroundModalContent)
);
