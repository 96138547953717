import { Box, Typography } from "@material-ui/core";
import clsx, { ClassValue } from "clsx";
import { Icon, IconName, useDeviceTypes } from "halifax";
import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import * as textConstants from "../../constants";
import { PostBookingOfferBannerConnectorProps } from "./container";
import { postBookingViewTakeoverPropteries } from "redmond";

import "./styles.scss";
import { trackEvent } from "../../../../../../../../api/v1/analytics/trackEvent";

interface IPostBookingOfferBannerProps
  extends PostBookingOfferBannerConnectorProps,
    RouteComponentProps {
  itineraryId: string;
  offerContainerModifier?: ClassValue;
}

export const PostBookingOfferBanner = (props: IPostBookingOfferBannerProps) => {
  const {
    itineraryId,
    myTripsPostBookingOffers,
    offerContainerModifier,
    history,
  } = props;

  const { matchesMobile } = useDeviceTypes();

  const [skipPostBookingOffer, _setSkipPostBookingOffer] = useState(
    sessionStorage.getItem(`skipMyTripsPostBookingOffer_${itineraryId}`) ===
      String(true)
  );
  const setSkipPostBookingOffer = (value: boolean) => {
    _setSkipPostBookingOffer(value);
    sessionStorage.setItem(
      `skipMyTripsPostBookingOffer_${itineraryId}`,
      String(value)
    );
  };

  const availablePostBookingOffer =
    !skipPostBookingOffer &&
    myTripsPostBookingOffers.find((offer) => offer.itineraryId === itineraryId);

  useEffect(() => {
    if (!availablePostBookingOffer) return;
    trackEvent(postBookingViewTakeoverPropteries("my_trips_homepage", 1, {}));
  }, [availablePostBookingOffer]);

  if (!availablePostBookingOffer) {
    return <> </>;
  }

  const viewAndAddClicked = () => {
    history.push(
      `${textConstants.PATH_DISRUPTION_OFFER}?${textConstants.ITINERARY_ID_QUERY_PARAM}=${availablePostBookingOffer.itineraryId}&referenceScreen=my_trips_homepage`
    );
  };

  const createBanner = (props: { title: string; body: string }) => {
    return (
      <Box
        onClick={viewAndAddClicked}
        className={clsx(
          "post-booking-offer-container",
          offerContainerModifier,
          {
            mobile: matchesMobile,
          }
        )}
      >
        {!matchesMobile && (
          <Icon
            className="post-booking-offer-icon"
            name={IconName.DisruptionProtectionDoubleLineCircle}
          />
        )}
        <Box className="post-booking-offer-content">
          <Box className="post-booking-offer-content-title-container">
            <Typography
              className="post-booking-offer-title"
              variant="subtitle2"
              dangerouslySetInnerHTML={{
                __html: props.title,
              }}
            />
            <Box
              onClick={() => {
                setSkipPostBookingOffer(true);
              }}
            >
              <Icon
                className="post-booking-offer-close"
                name={IconName.BlueX}
              />
            </Box>
          </Box>
          <Typography
            className="post-booking-offer-body"
            variant="subtitle2"
            dangerouslySetInnerHTML={{
              __html: props.body,
            }}
          />

          <Box className="post-booking-offer-view-view-container">
            <Typography variant="subtitle2" className="post-booking-offer-view">
              {textConstants.MY_TRIPS_POSTBOOKING_VIEW}
            </Typography>
            <Icon
              className="post-booking-offer-view-icon"
              name={IconName.ArrowCircle}
            />
          </Box>
        </Box>
      </Box>
    );
  };

  return matchesMobile
    ? createBanner({
        title: textConstants.MY_TRIPS_POSTBOOKING_ADD_FDA,
        body: textConstants.MY_TRIPS_POSTBOOKING_BODY_MOBILE,
      })
    : createBanner({
        title: textConstants.MY_TRIPS_POSTBOOKING_TITLE,
        body: textConstants.MY_TRIPS_POSTBOOKING_BODY,
      });
};
