import {
  EligibilitySliceIsEligible,
  TravelItineraryEnum,
  TravelItinerary,
  EligibilitySegment,
  EligibilitySegmentEnum,
  FlightItinerarySegment,
  FlightDisruptionEnum,
  FlightStatusResultEnum,
  TripSegment,
  SegmentShelf,
  Airport,
  Airline,
  getTripSegmentsFromItinerarySegment,
  getStopsFromSegments,
  getPlusDaysFromItinerarySegments,
} from "redmond";
import { IFlightSummaryData } from "halifax";
import { RebookFlightSummaryData, FlightSummaryType } from "./types";

const isDisruptedSegment = ({
  itinerarySegment,
  eligibleSegment,
}: {
  itinerarySegment: FlightItinerarySegment;
  eligibleSegment: EligibilitySegment | undefined;
}): boolean => {
  const disruptionStatus = eligibleSegment?.status;

  switch (disruptionStatus?.Result) {
    case FlightStatusResultEnum.IsFound:
    case FlightStatusResultEnum.NotFound:
      return (
        disruptionStatus.scheduled.departureAirportCode ===
        itinerarySegment.origin.locationCode
      );
    default:
      return false;
  }
};

const getLocationLabel = (name: string, code: string) => `${name} (${code})`;

const getFlightSummaryData = ({
  tripSegment,
  cabinClassNames,
  fareShelf,
  plusDays,
  stops,
  includeMixedCabinClass,
}: {
  tripSegment: TripSegment;
  cabinClassNames: (string | undefined)[];
  fareShelf?: SegmentShelf;
  plusDays?: number;
  stops?: number;
  includeMixedCabinClass?: boolean;
}): IFlightSummaryData => {
  return {
    fareShelfBrandName: fareShelf?.brandName,
    ...(includeMixedCabinClass
      ? {
          fareShelfRating: fareShelf?.rating,
          fareShelfShortBrandName: fareShelf?.shortBrandName,
        }
      : undefined),
    tripSlice: {
      segments: [tripSegment],
      cabinClassNames,
      airlineCode: tripSegment.airlineCode,
      airlineName: tripSegment.airlineName,
      departureTime: tripSegment.departureTime,
      arrivalTime: tripSegment.arrivalTime,
      originName: getLocationLabel(
        tripSegment.originName,
        tripSegment.originCode
      ),
      originCode: tripSegment.originCode,
      destinationName: getLocationLabel(
        tripSegment.destinationName,
        tripSegment.destinationCode
      ),
      destinationCode: tripSegment.destinationCode,
      plusDays,
      stops,
    },
  } as IFlightSummaryData;
};

// note: this helper function is added for RebookFlightSummary only; it's not meant to be consumed by other components
export const getRebookFlightSummaryData = ({
  sliceIndex,
  travelItinerary,
  eligibleSlice,
  airportMap,
  airlineMap,
  useSelectAll,
}: {
  // note: departure slice is at index 0, and return slice is at index 1
  sliceIndex: 0 | 1;
  travelItinerary: TravelItinerary;
  eligibleSlice?: EligibilitySliceIsEligible;
  airportMap: { [key: string]: Airport };
  airlineMap: { [key: string]: Airline };
  useSelectAll?: boolean;
}): RebookFlightSummaryData | undefined => {
  const travelItinerarySlice = (() => {
    switch (travelItinerary.TravelItinerary) {
      case TravelItineraryEnum.SingleTravelItinerary:
        return travelItinerary.slices[sliceIndex];
      case TravelItineraryEnum.MultiTravelItinerary:
        return travelItinerary.travelItineraries[sliceIndex]?.slices[0];
      default:
        return undefined;
    }
  })();
  const eligibleSegment = eligibleSlice?.segments.find(
    (segment) =>
      segment.EligibilitySegment === EligibilitySegmentEnum.IsEligible
  );

  if (!travelItinerarySlice || (!eligibleSegment && !useSelectAll)) {
    return undefined;
  }

  const fareShelf = travelItinerarySlice.fareShelf;
  const disruptionReason = (() => {
    switch (eligibleSegment?.disruption.FlightDisruption) {
      case FlightDisruptionEnum.IsDisrupted:
        return eligibleSegment.disruption.reasons[0]?.Reason;
      default:
        return undefined;
    }
  })();

  // note: only one segment will be IsEligible (for rebook); segments after that are rebookable as well
  const { nonRebookSegments, rebookSegments } = !useSelectAll
    ? travelItinerarySlice.segments.reduce(
        (collection, segment) => {
          if (
            collection.rebookSegments.length > 0 ||
            isDisruptedSegment({
              itinerarySegment: segment,
              eligibleSegment,
            })
          ) {
            collection.rebookSegments.push(segment);
          } else {
            collection.nonRebookSegments.push(segment);
          }
          return collection;
        },
        {
          nonRebookSegments: [] as FlightItinerarySegment[],
          rebookSegments: [] as FlightItinerarySegment[],
        }
      )
    : {
        nonRebookSegments: [],
        rebookSegments: travelItinerarySlice.segments,
      };

  // FlightSummaryType.Selection
  if (rebookSegments.length > 1) {
    const flightSummarySegments: IFlightSummaryData[] = rebookSegments.map(
      (segment) => {
        const tripSegment = getTripSegmentsFromItinerarySegment({
          segment,
          airportMap,
          airlineMap,
          useCityName: true,
        });

        return getFlightSummaryData({
          tripSegment,
          cabinClassNames: [segment.cabinClassName],
          fareShelf,
          plusDays: tripSegment.plusDays,
        });
      }
    );

    return {
      segments: flightSummarySegments,
      reason: disruptionReason,
      FlightSummaryType: FlightSummaryType.Selection,
    };
  }
  // FlightSummaryType.NonstopSlice
  else if (nonRebookSegments.length === 0) {
    // only has 1 segment since it's nonstop
    const rebookSegment = rebookSegments[0];
    const tripSegment = getTripSegmentsFromItinerarySegment({
      segment: rebookSegment,
      airportMap,
      airlineMap,
      useCityName: true,
    });
    const flightSummarySlice = getFlightSummaryData({
      tripSegment,
      cabinClassNames: rebookSegments.map((segment) => segment.cabinClassName),
      fareShelf,
      plusDays: getPlusDaysFromItinerarySegments(rebookSegments),
      stops: getStopsFromSegments(rebookSegments),
      includeMixedCabinClass: true,
    });

    return {
      slice: flightSummarySlice,
      reason: disruptionReason,
      FlightSummaryType: FlightSummaryType.NonstopSlice,
    };
  }
  // FlightSummaryType.SingleConnection
  else {
    // only has 1 segment since it's a single connection
    const rebookSegment = rebookSegments[0];
    const tripSegment = getTripSegmentsFromItinerarySegment({
      segment: rebookSegment,
      airportMap,
      airlineMap,
      useCityName: true,
    });
    const flightSummarySegment = getFlightSummaryData({
      tripSegment,
      cabinClassNames: [rebookSegment.cabinClassName],
      fareShelf,
      plusDays: tripSegment.plusDays,
    });

    return {
      segment: flightSummarySegment,
      reason: disruptionReason,
      FlightSummaryType: FlightSummaryType.SingleConnection,
    };
  }
};
