// Getters
export const FETCH_TRAVEL_WALLET_DETAILS =
  "myTripsTravelWallet/FETCH_TRAVEL_WALLET_DETAILS";
export type FETCH_TRAVEL_WALLET_DETAILS = typeof FETCH_TRAVEL_WALLET_DETAILS;

// Setters
export const SET_TRAVEL_WALLET_OFFERS =
  "myTripsTravelWallet/SET_TRAVEL_WALLET_OFFERS";
export type SET_TRAVEL_WALLET_OFFERS = typeof SET_TRAVEL_WALLET_OFFERS;

export const SET_FETCH_TRAVEL_WALLET_DETAILS_CALL_STATE_FAILED =
  "myTripsTravelWallet/SET_FETCH_TRAVEL_WALLET_DETAILS_CALL_STATE_FAILED";
export type SET_FETCH_TRAVEL_WALLET_DETAILS_CALL_STATE_FAILED =
  typeof SET_FETCH_TRAVEL_WALLET_DETAILS_CALL_STATE_FAILED;

export const SET_TRAVEL_WALLET_CREDIT =
  "myTripsTravelWallet/SET_TRAVEL_WALLET_CREDIT";
export type SET_TRAVEL_WALLET_CREDIT = typeof SET_TRAVEL_WALLET_CREDIT;
