import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Container } from "@material-ui/core";
import {
  TripsTravelerSelectWorkflow,
  TripsTravelerTypeTitles,
  TripsTravelerSelectStep,
  ActionLink,
} from "halifax";
import { useDispatch, useSelector } from "react-redux";
import {
  BookedFlightItineraryWithDepartureTime,
  IOpenModal,
  IPersonWithNameNumber,
  ItineraryEnum,
  MyTripsFilter,
  SelfServeEvents,
  IRequestTravelerEditFormEnum,
  MyTripsModalTypes,
  ItineraryWithType,
} from "redmond";

import {
  MultiProvider,
  PostTicketingStatus,
  SingleTravelItinerary,
} from "redmond/trips-module/itinerary";
import * as constants from "./constants";
import { trackEvent } from "../../../../api/v1/analytics/trackEvent";
import { getTripsFilter } from "../../reducer";
import { setSelectedRequestPassengerEdit } from "../../actions/actions";

import "./styles.scss";

export interface IPassengersModalContentProps {
  isMobile?: boolean;
  isReadOnly?: boolean;
  within24Hours?: boolean;
  bookedItinerary?: BookedFlightItineraryWithDepartureTime;
  locators: MultiProvider;
  itineraryType: ItineraryEnum;
  passengers: IPersonWithNameNumber[];
  setOpenModal: (openModal: IOpenModal) => void;
}

const defaultProps: Partial<IPassengersModalContentProps> = {
  isMobile: false,
  isReadOnly: false,
  within24Hours: false,
};

const PassengersModalContent = ({
  isMobile,
  isReadOnly,
  itineraryType,
  passengers,
  bookedItinerary,
  within24Hours,
  locators,
  setOpenModal,
}: IPassengersModalContentProps): JSX.Element => {
  const dispatch = useDispatch();
  const [progress, setProgress] = useState(
    TripsTravelerSelectStep.TravelerSelect
  );
  const tripsFilter = useSelector(getTripsFilter);

  const isCar = itineraryType === ItineraryEnum.Car;
  const isFlight = itineraryType === ItineraryEnum.Flight;
  const isHotel = itineraryType === ItineraryEnum.Hotel;
  const isTravelfusion = locators?.b2b?.startsWith("H-Z-");
  const travelItinerary = bookedItinerary?.bookedItinerary
    ?.travelItinerary as SingleTravelItinerary;
  const isTicketless =
    travelItinerary?.postTicketingStatus === PostTicketingStatus.Ticketed &&
    travelItinerary.ticketing?.length &&
    (!travelItinerary.ticketing[0]?.eTicketNumber ||
      !travelItinerary.ticketing[0]?.eTicketNumber?.number);

  const disableRequestEdit =
    isHotel ||
    isCar ||
    within24Hours ||
    isTicketless ||
    isTravelfusion ||
    tripsFilter === MyTripsFilter.PAST_TRIPS;

  useEffect(() => {
    trackEvent({
      eventName: SelfServeEvents.ViewedTravelerDetailModal,
      properties: {
        url: window.location.pathname,
      },
    });
  }, []);

  const onClose = useCallback((ev: any) => {
    if (ev?.stopPropagation) ev.stopPropagation();

    setOpenModal({ type: null, selectedItinerary: null });
  }, []);

  const onOpenRequestTravelerEdits = useCallback(
    (
      traveler?: IPersonWithNameNumber,
      field?: IRequestTravelerEditFormEnum
    ) => {
      trackEvent({
        eventName: SelfServeEvents.EditTravelerDetailModal,
        properties: {
          url: window.location.pathname,
        },
      });
      dispatch(
        setSelectedRequestPassengerEdit({
          traveler: traveler || passengers[0],
          field,
        })
      );
      setOpenModal({
        type: MyTripsModalTypes.RequestTravelerEdit,
        selectedItinerary: bookedItinerary as ItineraryWithType,
      });
    },
    [dispatch, setOpenModal, setSelectedRequestPassengerEdit]
  );

  const travelerTitles: TripsTravelerTypeTitles = useMemo(() => {
    let infoFormSubtitle;
    let infoTitle = constants.FLIGHT_FORM_TITLE;

    if (isCar) {
      infoFormSubtitle = constants.GROUND_FORM_SUBTITLE;
      infoTitle = constants.GROUND_FORM_TITLE;
    } else if (isHotel) {
      infoFormSubtitle = constants.HOTEL_FORM_SUBTITLE;
      infoTitle = constants.HOTEL_FORM_TITLE;
    } else if (isFlight) {
      infoFormSubtitle = (
        <Box className="passengers-modal-info-subtitle">
          <span>
            {
              // eslint-disable-next-line no-nested-ternary
              !disableRequestEdit
                ? constants.FLIGHT_FORM_SUBTITLE_MINOR_CORRECTIONS
                : tripsFilter === MyTripsFilter.PAST_TRIPS
                ? ""
                : constants.FLIGHT_FORM_SUBTITLE
            }
          </span>
          {disableRequestEdit ? null : (
            <ActionLink
              className="minor-corrections-action-link"
              content={constants.MINOR_CORRECTIONS}
              onClick={() => onOpenRequestTravelerEdits()}
            />
          )}
        </Box>
      );
    }

    return {
      additionalInfoTitle: "Additional Information",
      adultTitle: "Adult",
      childTitle: "Child",
      frequentFlyerTitle: "Frequent Flyer Details",
      infantLapTitle: "Infant",
      infantSeatTitle: "Infant",
      travelerInfoFormSubtitle: infoFormSubtitle,
      travelerInfoSubtitle: "",
      travelerInfoTitle: infoTitle,
    };
  }, [isCar, isFlight, isHotel, disableRequestEdit]);

  return (
    <Container className="trips-travelers-modal-content">
      <TripsTravelerSelectWorkflow
        tripsFilter={tripsFilter}
        disallowSelect
        showGenderField
        showNationalityField
        buttonClassName="b2b"
        isMobile={isMobile}
        travelerSelectRowButton
        fullScreenWithBanner={isMobile}
        progress={progress}
        disableRequestEdit={disableRequestEdit}
        isFlights={isFlight}
        readOnly={isReadOnly}
        onGoBack={onClose}
        onOpenRequestTravelerEdits={onOpenRequestTravelerEdits}
        setProgress={setProgress}
        showAdditionalInfoSection={isFlight}
        showDriverLicenseSection={isCar}
        showFrequentFlyerSection={isFlight}
        titles={travelerTitles}
        travelers={passengers}
      />
    </Container>
  );
};

PassengersModalContent.defaultProps = defaultProps;

export default PassengersModalContent;
