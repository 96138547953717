import { connect, ConnectedProps } from "react-redux";

import { withRouter } from "react-router-dom";
import { ScheduleChangeStateModal } from "./component";
import { fetchFlights } from "../../../../../../../../actions/actions";
import { IStoreState } from "../../../../../../../../../../reducers/types";
import { isPostBookingOfferMyTripsEnabledSelector } from "../../../../../../../../../DisruptionProtection/reducer";

const mapStateToProps = (state: IStoreState) => ({
  isPostBookingOfferMyTripsEnabled:
    isPostBookingOfferMyTripsEnabledSelector(state),
});

const mapDispatchToProps = {
  fetchFlights,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type ScheduleChangeStateModalContentConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedScheduleChangeStateModal = connector(
  withRouter(ScheduleChangeStateModal)
);
