import { DP_SUPPORT_PHONE_NUMBER } from "redmond";

export const PAGE_TITLE = "Get a refund";
export const PAGE_SUBTITLE = (refundAmount: string | null) =>
  `You’ll receive a refund${
    refundAmount ? ` of ${refundAmount}` : ""
  } to your original payment method. You should receive your refund within the next 1-2 billing cycles, but in some cases it may take longer.<p><strong>This refund will not cause any changes to your current itinerary.</strong></p>`;

export const REFUND_PRICES_FIAT_AND_REWARDS = (
  refundPricesInFiatOnly: string,
  refundPricesInRewardsOnly: string
) => `${refundPricesInFiatOnly} and ${refundPricesInRewardsOnly}`;

export const IMPORTANT_INFORMATION_TITLE = "Important information";

export const IMPORTANT_INFORMATION_TEXTS = [
  `In many cases, you will still be eligible to receive compensation or accommodations from the airline even if you rebook or get a refund through Capital One Travel. <strong>This action will not make any changes to your current itinerary.</strong>`,
  "Refunds will be issued using the same method of payment as the original booking.",
  "Refunds cover the base fare and related taxes and fees. Any travel offers and paid add-ons such as baggage, seat selection and cancel for any reason are not included.",
  "You should receive your refund within the next 1-2 billing cycles, but in some cases it may take longer.",
  `If you have any questions please contact us at ${DP_SUPPORT_PHONE_NUMBER.boldText}.`,
];

export const IMPORTANT_INFORMATION_TEXT_BULLETS = [
  "1.",
  "2.",
  "3.",
  "4.",
  "5.",
];

export const DISRUPTED_FLIGHT_LABEL = "Disrupted flight";

export const FLIGHT_SLICE_CARD_BUTTON_TEXT_VIEW_DETAILS = "View details";

export const CONFIRM_REFUND_BUTTON_LABEL = "Confirm refund";
