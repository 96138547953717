import { Typography, Box } from "@material-ui/core";
import dayjs from "dayjs";
import {
  NotificationBanner,
  BannerSeverity,
  Icon,
  IconName,
  MyTripsMobileCard,
  MyTripsCard,
  TripInfoDetails,
  ActionButton,
  getFullTimeString,
  getTwoLineAddresses,
} from "halifax";
import React from "react";
import {
  HotelPriceFreezeSummary,
  HotelPriceFreezeStateEnum,
  ResultEnum,
} from "redmond";
import * as constants from "./constants";
import "./styles.scss";

export interface IHotelPriceFreezeCardProps {
  hotelPriceFreeze: HotelPriceFreezeSummary;
  isMobile?: boolean;
  onFreezeAgain?: (pf: HotelPriceFreezeSummary) => void;
  onContinueToBooking: (id: string) => void;
}

export const HotelPriceFreezeCard = ({
  hotelPriceFreeze,
  isMobile,
  onFreezeAgain,
  onContinueToBooking,
}: IHotelPriceFreezeCardProps) => {
  const getPriceFreezeCardDetails = (
    hotelPriceFreeze: HotelPriceFreezeSummary,
    formatDate: (date: string) => string
  ) => {
    const nightCount = dayjs
      .duration(
        dayjs(hotelPriceFreeze.voucher.reservation.end).diff(
          hotelPriceFreeze.voucher.reservation.start
        )
      )
      .asDays();
    const lodging =
      hotelPriceFreeze.lodging.Result === ResultEnum.Ok
        ? hotelPriceFreeze.lodging.value.lodging
        : undefined;

    const address = getTwoLineAddresses(lodging?.address);

    return {
      title: lodging?.name ?? "",
      startDate: formatDate(hotelPriceFreeze.voucher.reservation.start),
      endDate: formatDate(hotelPriceFreeze.voucher.reservation.end),
      subtitle: nightCount + " " + (nightCount === 1 ? "night" : "nights"),
      streetAddress: address.line1,
      postalCode: address.line2,
      phoneNum: lodging?.phone,
    };
  };

  const tripDetails = getPriceFreezeCardDetails(
    hotelPriceFreeze,
    (date: string) => dayjs(date).format(constants.DATE_FORMAT)
  );

  const isExpired =
    hotelPriceFreeze.voucher.state.State === HotelPriceFreezeStateEnum.Expired;

  const formatExpiredDate = (date: string): string => {
    const dateObject = dayjs(date);

    return `${constants.EXPIRES_ON} ${getFullTimeString(dateObject)}`;
  };

  const renderExpirationBanner = (date: string) => {
    const dateObject = dayjs(date);

    return (
      <NotificationBanner
        className="status-banner"
        label={constants.formatBanner(dateObject)}
        severity={BannerSeverity.ERROR}
        icon={<Icon name={IconName.ErrorAlert} />}
      />
    );
  };

  const banner = isExpired
    ? renderExpirationBanner(hotelPriceFreeze.voucher.action.expiration)
    : undefined;

  return isMobile ? (
    <MyTripsMobileCard
      key={hotelPriceFreeze.voucher.id}
      className="my-trips-mobile-card-hotel"
      icon={IconName.HotelFunnelIcon}
      label={constants.FROZEN_HEADER_MOBILE_HOTEL}
      banner={banner}
      onClick={() => {
        isExpired
          ? onFreezeAgain
            ? onFreezeAgain(hotelPriceFreeze)
            : undefined
          : onContinueToBooking(hotelPriceFreeze.voucher.id);
      }}
      actions={
        !isExpired ? (
          <Typography variant="subtitle1" className="expire-label">
            {formatExpiredDate(hotelPriceFreeze.voucher.action.expiration)}
          </Typography>
        ) : undefined
      }
      shouldShowCtaIcon={!isExpired}
      {...tripDetails}
    />
  ) : (
    <MyTripsCard
      key={hotelPriceFreeze.voucher.id}
      className="my-trips-card-hotel"
      actions={null}
      banner={banner}
      content={
        <TripInfoDetails
          titles={{
            startLabel: `${constants.CHECK_IN}:`,
            endLabel: `${constants.CHECK_OUT}:`,
            confirmationLabel: constants.CONFIRMATION,
            confirmationCode: hotelPriceFreeze.confirmationNumber,
            ...tripDetails,
          }}
        />
      }
      cta={
        <Box className="ctas-section">
          {!isExpired && (
            <Typography variant="subtitle1" className="expire-label">
              {formatExpiredDate(hotelPriceFreeze.voucher.action.expiration)}
            </Typography>
          )}
          {!isExpired && (
            <ActionButton
              message={constants.COMPLETE_BOOKING}
              defaultStyle="h4r-primary"
              onClick={() => {
                isExpired
                  ? onFreezeAgain
                    ? onFreezeAgain(hotelPriceFreeze)
                    : undefined
                  : onContinueToBooking(hotelPriceFreeze.voucher.id);
              }}
            />
          )}
        </Box>
      }
    />
  );
};
