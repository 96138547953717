import React, { useEffect, useState } from "react";
import { RouteComponentProps, StaticContext } from "react-router";
import { BookedFlightItineraryWithDepartureTime, Itinerary } from "redmond";
import { Box, Divider } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import clsx from "clsx";

import { TravelCreditsListConnectorProps } from "./TCContainer";
import { FlightCard } from "./components/FlightCard";
import { ItinerariesModal } from "./components/ItinerariesModal";
import { PAGE_SIZE } from "./constants";
import { MissingItinerariesBanner } from "./components/MissingItinerariesBanner";

import "./styles.scss";

interface ITravelCreditsListProps
  extends TravelCreditsListConnectorProps,
    RouteComponentProps<{}, StaticContext, { prevPath?: string }> {
  isMobile?: boolean;
  showDisruptionProtectionElements?: boolean;
}

const defaultProps: Partial<ITravelCreditsListProps> = {
  showDisruptionProtectionElements: false,
  isMobile: false,
};

export const scrollTopWithOffset = (element: HTMLDivElement) => {
  const offset = 221; // header (211px) + padding below header (10px)
  const bodyRect = document.body.getBoundingClientRect().top;
  const elementRect = element.getBoundingClientRect().top;
  const elementPosition = elementRect - bodyRect;
  const offsetPosition = elementPosition - offset;

  window.scrollTo({
    top: offsetPosition,
    behavior: "smooth",
  });
};

const getItineraryId = (itinerary: Itinerary) => {
  const flight = itinerary as BookedFlightItineraryWithDepartureTime;
  return flight?.bookedItinerary?.id || "";
};

const TravelCreditsList = (props: ITravelCreditsListProps) => {
  const {
    history,
    isMobile,
    showDisruptionProtectionElements,
    populateTripQueryParams,
    selectedFlight,
    travelCredits,
    selectedTripId,
    tripsFilter,
    setSelectedFlight,
    hasError,
  } = props;

  const [expandedCard, setExpandedCard] = useState(selectedTripId);
  useEffect(() => {
    const newExpandedCard = selectedTripId;
    if (newExpandedCard !== expandedCard) {
      setExpandedCard(newExpandedCard);
    }
  }, [selectedTripId]);

  const onCloseCard = () => {
    setExpandedCard("");
    setSelectedFlight(null);
    populateTripQueryParams(history);
  };

  const onExpandCard = (itinerary: Itinerary) => {
    setExpandedCard(getItineraryId(itinerary));

    setSelectedFlight(itinerary as BookedFlightItineraryWithDepartureTime);
    populateTripQueryParams(history);
  };

  const onExpandToggleClick = (itinerary: Itinerary) => (cardId: string) =>
    cardId === expandedCard ? onCloseCard() : onExpandCard(itinerary);

  const renderFlightCard = (itinerary: Itinerary) => (
    <FlightCard
      isMobile={isMobile}
      onExpandCard={onExpandToggleClick(itinerary)}
      expandedCard={expandedCard}
      flight={itinerary as BookedFlightItineraryWithDepartureTime}
      showDisruptionProtectionElements={showDisruptionProtectionElements}
    />
  );

  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [itineriesToShow, setItinerariesToShow] = useState<Itinerary[]>([]);
  const handlePageChange = (
    _event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
    if (selectedFlight) setSelectedFlight(null);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const setTripsPage = () => {
    let selectedItinerary = null;
    if (selectedFlight) {
      selectedItinerary = { ...selectedFlight };
    }
    const selectedItineraryId =
      selectedItinerary && getItineraryId(selectedItinerary);
    const selectedIndex = travelCredits.findIndex(
      (itinerary) => getItineraryId(itinerary) === selectedItineraryId
    );
    const currentPage = Math.ceil((selectedIndex + 1) / PAGE_SIZE);
    if (selectedIndex !== -1 && page !== currentPage) {
      setPage(currentPage);
    }
  };

  const getCurrentItems = () => {
    const startIndex = (page - 1) * PAGE_SIZE;
    const endIndex = startIndex + PAGE_SIZE;
    return travelCredits.slice(startIndex, endIndex);
  };

  const getTotalPages = () => Math.ceil(travelCredits.length / PAGE_SIZE);

  useEffect(() => {
    setItinerariesToShow(getCurrentItems());
  }, [page]);

  useEffect(() => {
    setPage(1);
  }, [tripsFilter]);

  useEffect(() => {
    if (travelCredits.length > 0) {
      const totalPagesToUpdate = getTotalPages();
      if (totalPagesToUpdate !== totalPages) {
        setTotalPages(getTotalPages());
      }
      setTripsPage();
      setItinerariesToShow(getCurrentItems());
    } else {
      setItinerariesToShow([]);
    }
  }, [travelCredits]);

  return (
    <Box className={clsx({ mobile: isMobile }, "my-trips-list")}>
      {itineriesToShow.map((itinerary) => (
        <React.Fragment key={getItineraryId(itinerary)}>
          {renderFlightCard(itinerary)}
          {!isMobile && <Divider className="itinerary-card-divider" />}
        </React.Fragment>
      ))}
      <ItinerariesModal isMobile={isMobile} />
      {hasError && <MissingItinerariesBanner isMobile={isMobile} />}
      {totalPages > 1 && (
        <Box
          className={clsx("trips-pagination-container", { mobile: isMobile })}
        >
          <Pagination
            count={totalPages}
            page={page}
            onChange={handlePageChange}
          />
        </Box>
      )}
    </Box>
  );
};

TravelCreditsList.defaultProps = defaultProps;

export default TravelCreditsList;
