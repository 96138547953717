import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { IStoreState } from "../../../../../../reducers/types";
import {
  fetchHomes,
  setSelectedHome,
  populateTripQueryParams,
  setOpenModal,
} from "../../../../actions/actions";
import { HomeCard } from "./component";
import { getSelectedHome, getTripsFilter } from "../../../../reducer";
import { MyTripsFilter } from "redmond";

const mapStateToProps = (state: IStoreState) => ({
  isPastTrips: getTripsFilter(state) === MyTripsFilter.PAST_TRIPS,
  selectedHome: getSelectedHome(state),
});

const mapDispatchToProps = {
  fetchHomes,
  setSelectedHome,
  populateTripQueryParams,
  setOpenModal,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type HomeCardConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedHomeCard = connector(withRouter(HomeCard));
