import React from "react";
import {
  cardsToShowLastFourOnRewardsBanner,
  MyTripsFilter,
  RewardsAccount,
} from "redmond";

export const NO_UPCOMING_TRIPS = "You have no upcoming trips.";
export const NO_WATCHED_TRIPS = "You aren't watching any trips.";
export const NO_PRICE_FREEZES = "You haven't frozen any prices.";
export const NO_TRIPS_FOUND = "No Trips Found";
export const NO_TRAVEL_CREDITS = "You don't have any airline travel credits.";
export const NO_PAST_TRIPS = "You don't have any past trips.";
export const ERROR_TITLE = "We are having trouble loading this page";
export const ERROR_SUBTITLE = "Please try again";
export const TRY_AGAIN = "Try Again";
export const NO_TRIP_REQUESTS = "No trip requests";

export const NO_UPCOMING_TRIPS_SUBTITLE =
  "Looks like it's time to plan your next one!";

export const FLIGHTS_CTA = "Search Flights";
export const HOTELS_CTA = "Search Hotels";
export const CARS_CTA = "Search Cars";

export const PAST_TRIP_ERROR = "Unable to load past trips.";
export const TRAVEL_CREDIT_ERROR = "Unable to load airline travel credits.";
export const UPCOMING_TRIP_ERROR = "Unable to load upcoming trips.";
export const TRIP_REQUEST_ERROR = "Unable to load trip requests.";

export const getNoResultsTitle = (tripsFilter: MyTripsFilter) => {
  switch (tripsFilter) {
    case MyTripsFilter.UPCOMING_TRIPS:
      return NO_UPCOMING_TRIPS;
    case MyTripsFilter.WATCHED_TRIPS:
      return NO_WATCHED_TRIPS;
    case MyTripsFilter.PRIZE_FREEZES:
      return NO_PRICE_FREEZES;
    case MyTripsFilter.TRAVEL_CREDITS:
      return NO_TRAVEL_CREDITS;
    case MyTripsFilter.PAST_TRIPS:
      return NO_PAST_TRIPS;
    case MyTripsFilter.TRIP_REQUESTS:
      return NO_TRIP_REQUESTS;
    default:
      return NO_TRIPS_FOUND;
  }
};

export const getNoResultsSubtitle = (tripsFilter: MyTripsFilter) => {
  switch (tripsFilter) {
    case MyTripsFilter.UPCOMING_TRIPS:
      return NO_UPCOMING_TRIPS_SUBTITLE;
    default:
      return null;
  }
};

export const getEarnText = (account: RewardsAccount) => {
  const {
    earn: { flightsMultiplier, hotelsMultiplier },
    rewardsBalance: { currency },
    productDisplayName,
    lastFour,
  } = account;

  const multiplier = currency.includes("cash") ? "%" : "x";

  const lastFourText = cardsToShowLastFourOnRewardsBanner.includes(
    productDisplayName.toLowerCase()
  )
    ? ` ending in ${lastFour}`
    : ``;

  return flightsMultiplier ? (
    <>
      Earn{" "}
      <strong>
        {flightsMultiplier}
        {multiplier} {currency}
      </strong>{" "}
      on flights and{" "}
      <strong>
        {hotelsMultiplier}
        {multiplier} {currency}
      </strong>{" "}
      on hotels and car rentals when you book with your {productDisplayName}{" "}
      account{lastFourText}.
    </>
  ) : (
    <>
      Earn{" "}
      <strong>
        {hotelsMultiplier}
        {multiplier} {currency}
      </strong>{" "}
      on hotels and car rentals when you book with your {productDisplayName}{" "}
      account{lastFourText}.
    </>
  );
};

export const getErrorMessage = (tripsFilter: MyTripsFilter) => {
  switch (tripsFilter) {
    case MyTripsFilter.UPCOMING_TRIPS:
      return UPCOMING_TRIP_ERROR;
    case MyTripsFilter.PAST_TRIPS:
      return PAST_TRIP_ERROR;
    case MyTripsFilter.TRAVEL_CREDITS:
      return TRAVEL_CREDIT_ERROR;
    case MyTripsFilter.TRIP_REQUESTS:
      return TRIP_REQUEST_ERROR;
    case MyTripsFilter.WATCHED_TRIPS:
    case MyTripsFilter.PRIZE_FREEZES:
    default:
      return ERROR_TITLE;
  }
};
