import axios from "axios";
import { config } from "../../config";

import { HomesItineraryRequest, HomesItineraryResponse } from "redmond";
import { homesItinerariesApiPrefix } from "../paths";

export const fetchHomes = async (
  req: HomesItineraryRequest
): Promise<HomesItineraryResponse> => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await axios.put(`${homesItinerariesApiPrefix}`, req, {
        baseURL: config.baseURL,
      });
      resolve(res.data);
    } catch (e) {
      reject(e);
    }
  });
};
