import { ShopFilter } from "redmond";

export const TITLE = "My Trips";
export const SUBTITLE = "View and manage upcoming and past trips";
export const FAQ_TITLE = "Questions about your trip?";
export const FAQ_CTA = "View FAQs";
export const SUPPORT_CTA = "Contact Support";
export const UPCOMING = "Upcoming";
export const PAST = "Past Trips";
export const VIEW_ALL = "View All";
export const BOOKINGS = "My Bookings";
export const WATCHES = "My Watches";
export const PRICE_FREEZE = "Price Freeze";
export const NO_TRIPS_FOUND = "No Trips Found";
export const NO_WATCHED_TRIPS = "You haven't watched any trips";
export const NO_PRICE_FREEZES = "You haven't frozen any prices";
export const NO_BOOKINGS = "You haven't made any bookings yet";
export const NO_TRIPS = "You have no upcoming trips";
export const DEPARTURE = "Departure";
export const RETURN = "Return";
export const FLIGHT_WATCH = "Flight Watch";
export const CONFIRMATION = "Confirmation:";
export const BOOK_A_FLIGHT = "Book a Flight";
export const BOOK_A_HOTEL = "Book a Hotel";
export const RENT_A_CAR = "Rent a Car";
export const VIEW_DETAILS = "View details";
export const BOOK_NOW = "Book Now";
export const WAIT = "Wait";
export const NONSTOP = "Nonstop";
export const NO_BASIC_FARES = "Exclude basic fares";
export const NONSTOP_NO_BASIC = "Nonstop, Exclude basic fares";
export const WATCH_HEADER = "Flight Watch | Departs on";
export const VIEW_FLIGHTS = "View Flights";
export const DEPARTS_ON = "Departs on";
export const DATE_FORMAT = "ddd, MMM DD";

export const getFilterLabel = (shopFilter: ShopFilter) => {
  switch (shopFilter) {
    case ShopFilter.NonStop:
      return NONSTOP;
    case ShopFilter.NonStopNoLCC:
      return NONSTOP_NO_BASIC;
    case ShopFilter.NoLCC:
      return NO_BASIC_FARES;
    default:
      return "";
  }
};
