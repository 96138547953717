import axios from "axios";
import { config } from "../../config";
import {
  HotelCfarCancellationPolicyRequest,
  HotelCfarCancelScenario,
} from "redmond";
import { getCfarHotelCancellationPath } from "../paths";

export const getCfarHotelCancellationPolicy = async (
  request: HotelCfarCancellationPolicyRequest
): Promise<HotelCfarCancelScenario> => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await axios.post(`${getCfarHotelCancellationPath}`, request, {
        baseURL: config.baseURL,
      });
      resolve(res.data.value);
    } catch (e) {
      reject(e);
    }
  });
};
