import { formatDateTime, removeTimezone } from "halifax";
import { Airport, FlightItinerarySegment, TravelItinerary, TravelItineraryEnum } from "redmond";

export const RETURN = "Return";
export const OUTBOUND = "Outbound";
export const HOUR_FORMAT = "h:mm A";
export const SELECT_CANCEL_FLIGHT = "Select the flight you’d like to cancel."
export const DISABLED_FLIGHT =
  "One of the flights on this itinerary is not eligible for cancelation.";

const getAirportCityName = (
  airports: { [key: string]: Airport },
  code?: string
): string => (code ? airports[code]?.cityName ?? code : "");

export const getOriginSegment = (segments: FlightItinerarySegment[]) =>
  segments[0];

export const getDestinationSegment = (segments: FlightItinerarySegment[]) =>
  segments[segments.length - 1];

export const formatter = (date: string) =>
  formatDateTime(removeTimezone(date), HOUR_FORMAT);

export const getItineraryOutboundSegments = (travelItinerary: TravelItinerary) => {
  switch (travelItinerary?.TravelItinerary) {
    case TravelItineraryEnum.MultiTravelItinerary:
      return travelItinerary.travelItineraries[0].slices[0].segments;
    case TravelItineraryEnum.SingleTravelItinerary:
      return travelItinerary.slices[0].segments;
  }
}

export const getSliceDestination = (
  code: string,
  airportMap: { [key: string]: Airport }
) => `${getAirportCityName(airportMap, code)} (${code})`;

export const getTravelItinerary = (
  travelItinerary: TravelItinerary
) => {
  switch (travelItinerary?.TravelItinerary) {
    case TravelItineraryEnum.MultiTravelItinerary:
      return travelItinerary.travelItineraries;
  }
  return undefined
}
