import React, { useState } from "react";
import { Box, Button, Popover, Typography } from "@material-ui/core";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MyTripsFilter } from "redmond";

import { IMobileFilterDropdownConnectorProps } from "./container";
import "./styles.scss";
import { getTabs } from "../../utils/getTabs";

const VIEWING = "Viewing";

const defaultLinks = {
  links: [
    { label: MyTripsFilter.UPCOMING_TRIPS },
    { label: MyTripsFilter.TRIP_REQUESTS },
    { label: MyTripsFilter.WATCHED_TRIPS },
    { label: MyTripsFilter.PRIZE_FREEZES },
    { label: MyTripsFilter.TRAVEL_CREDITS },
    { label: MyTripsFilter.PAST_TRIPS },
  ],
};

export interface IMobileFilterDropdownProps
  extends IMobileFilterDropdownConnectorProps {}

export const MobileFilterDropdown = ({
  tripsFilter,
  setSelectedCar,
  setSelectedFlight,
  setSelectedHotel,
  tripsCounts,
  setTripsFilter,
}: IMobileFilterDropdownProps) => {
  const links = getTabs(defaultLinks.links);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "mobile-filter-popup" : undefined;

  const handleChange = (value: MyTripsFilter) => {
    if (tripsFilter !== value) {
      setSelectedCar(null);
      setSelectedFlight(null);
      setSelectedHotel(null);
    }
    setTripsFilter(value);
    handleClose();
  };

  return (
    <>
      <Button
        onClick={handleClick}
        classes={{ root: "mobile-filter-dropdown-anchor" }}
        aria-describedby="mobile-filter-dropdown"
        disableTouchRipple
        disableFocusRipple
        disableRipple
      >
        <Typography className="button-prefix">{`${VIEWING}:`}</Typography>
        <Box className="selected-trip-filter-container">
          <Typography className="selected-trip-filter">
            {`${tripsFilter}${
              tripsCounts[tripsFilter] !== undefined
                ? ` (${tripsCounts[tripsFilter]})`
                : ""
            }`}
          </Typography>
          <FontAwesomeIcon
            className="icon"
            icon={open ? faCaretUp : faCaretDown}
          />
        </Box>
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        classes={{ paper: "popover-paper-container" }}
      >
        <Box className="links-container">
          {links.map((link) => (
            <Box
              key={link.label}
              className="link-container"
              onClick={() => handleChange(link.label)}
            >
              <Typography className="filter-link">
                {`${link.label}${
                  tripsCounts[link.label] !== undefined
                    ? ` (${tripsCounts[link.label]})`
                    : ""
                }`}
              </Typography>
            </Box>
          ))}
        </Box>
      </Popover>
    </>
  );
};
