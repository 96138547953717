import axios from "axios";
import { config } from "../../config";

import { PendingRequestsResponse } from "redmond";
import { pendingRequestItinerariesApiPrefix } from "../paths";

export const fetchPendingRequests =
  async (): Promise<PendingRequestsResponse> => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await axios.put(
          `${pendingRequestItinerariesApiPrefix}`,
          {},
          {
            baseURL: config.baseURL,
          }
        );
        resolve(res.data);
      } catch (e) {
        reject(e);
      }
    });
  };
