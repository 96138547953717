import axios, { AxiosResponse } from "axios";
import { config } from "../../config";
import { ancillaryPostBookingMyTripsOfferPath } from "../paths";
import {
  AncillaryPostBookingMyTripsFetchOffersRequest,
  AncillaryPostBookingMyTripsFetchOffersResponse,
} from "redmond";

export const getPostBookingMyTripsOffers = (
  body: AncillaryPostBookingMyTripsFetchOffersRequest
): Promise<AncillaryPostBookingMyTripsFetchOffersResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const res: AxiosResponse<AncillaryPostBookingMyTripsFetchOffersResponse> =
        await axios.post(ancillaryPostBookingMyTripsOfferPath, body, {
          baseURL: config.baseURL,
        });

      resolve(res.data);
    } catch (e) {
      reject(e);
    }
  });

export default getPostBookingMyTripsOffers;
