import React from "react";
import {
  ActionLink,
  CloseButtonIcon,
  DesktopPopupModal,
  FlightDetailsSummary,
  MobilePopoverCard,
  useDeviceTypes,
  Restriction,
} from "halifax";
import {
  Airport,
  Airline,
  getItinerarySummaryPropsFromSlice,
  SliceDirection,
  FlightItinerarySlice,
  Restriction as RestrictionItem,
} from "redmond";
import "./styles.scss";
import { getIsMixedClass } from "../../TripsList/constants";
import { Box, Divider, Typography } from "@material-ui/core";
import {
  FLIGHT_DETAILS_POPOVER_FARE_DETAILS_HEADER,
  FLIGHT_DETAILS_POPOVER_HEADER,
} from "./constants";
import clsx from "clsx";

export interface IFlightSliceDetailsPopoverProps {
  // TODO: Make this component module-agnostic i.e. remove dependency on trips-module-specific FlightItinerarySlice.
  // Necessary information from FlightItinerarySlice should be extracted by caller and passed into this component.
  // To achive this, we need to create a module-agnostic version of <FlightDetailsSummary> first
  // since this component calls it downstream.
  slice: FlightItinerarySlice;

  sliceDirection: SliceDirection;
  restrictions: RestrictionItem[];
  airportMap: { [key: string]: Airport };
  airlineMap: { [key: string]: Airline };
  isMixedCabinClass: boolean;
  destination: string;
  date: string;
  isOpen: boolean;
  onClose: () => void;
}

export const FlightSliceDetailsPopover = (
  props: IFlightSliceDetailsPopoverProps
): JSX.Element => {
  const {
    slice,
    sliceDirection,
    restrictions,
    airportMap,
    airlineMap,
    destination,
    date,
    isOpen,
    onClose,
  } = props;
  const { matchesMobile } = useDeviceTypes();

  const isMixedCabinClass = getIsMixedClass(slice);

  const popoverContent = (
    <Box className="flight-slice-details-popover-content">
      <Box
        className="popover-header"
        dangerouslySetInnerHTML={{
          __html: FLIGHT_DETAILS_POPOVER_HEADER(
            sliceDirection,
            destination,
            date
          ),
        }}
      ></Box>
      <Box className="flight-details-summary-wrapper">
        <FlightDetailsSummary
          showTitle={false}
          {...getItinerarySummaryPropsFromSlice(slice, airportMap, airlineMap)}
          isMixedCabinClass={isMixedCabinClass}
        />
      </Box>
      <Divider className="divider" />
      <Typography className="fare-details-header" variant="subtitle1">
        {FLIGHT_DETAILS_POPOVER_FARE_DETAILS_HEADER}
      </Typography>
      <Box className="fare-details">
        {restrictions.map((restriction, index) => (
          <Restriction
            key={`restriction-${index}`}
            name={restriction.name}
            descriptionString={restriction.description}
            symbol={restriction.symbol}
          />
        ))}
      </Box>
    </Box>
  );

  const desktopPopupModal = (
    <DesktopPopupModal
      className="flight-slice-details-group"
      contentClassName="flight-slice-details-popover-content-wrapper"
      onClose={onClose}
      open={isOpen}
    >
      {popoverContent}
    </DesktopPopupModal>
  );

  const mobilePopoverCard = (
    <MobilePopoverCard
      className={clsx("flight-slice-details-group", "mobile")}
      contentClassName="flight-slice-details-popover-content-wrapper"
      onClose={onClose}
      open={isOpen}
      topRightButton={
        <ActionLink
          className="close-button"
          content={<CloseButtonIcon />}
          label="Close"
          onClick={onClose}
        />
      }
    >
      {popoverContent}
    </MobilePopoverCard>
  );

  return matchesMobile ? mobilePopoverCard : desktopPopupModal;
};
