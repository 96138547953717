import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { CallState, IPerson } from "redmond";

export interface TripsModulePassengersState {
  passengers: IPerson[];
  passengersCallState: CallState;
}

const initialState: TripsModulePassengersState = {
  passengers: [],
  passengersCallState: CallState.NotCalled,
};

export const passengersSlice = createSlice({
  initialState,
  name: "trips/passengers",
  reducers: {
    fetchUserPassengers: (state: TripsModulePassengersState) => {
      state.passengersCallState = CallState.InProcess;
    },
    setUserPassengers: (
      state: TripsModulePassengersState,
      action: PayloadAction<IPerson[]>
    ) => {
      state.passengers = action.payload;
      state.passengersCallState = CallState.Success;
    },
    setUserPassengersCallState: (
      state: TripsModulePassengersState,
      action: PayloadAction<CallState>
    ) => {
      state.passengersCallState = action.payload;
    },
  },
});

export const {
  fetchUserPassengers,
  setUserPassengers,
  setUserPassengersCallState,
} = passengersSlice.actions;

export default passengersSlice.reducer;
