import axios from "axios";
import { config } from "../../config";
import { WatchAlertViews } from "redmond";
import { watchApiPrefix } from "../paths";

export const listWatches = (): Promise<WatchAlertViews> => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await axios.put(
        `${watchApiPrefix}/list`,
        {},
        {
          baseURL: config.baseURL,
        }
      );
      resolve(res.data.value);
    } catch (e) {
      reject(e);
    }
  });
};
