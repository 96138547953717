import axios from "axios";
import { ListSummariesRequest, ListSummariesResult } from "redmond";
import { config } from "../../config";
import { hotelPriceFreezeListSummariesApi } from "../paths";

export const listHotelPriceFreezeVoucherSummaries = async (
  body: ListSummariesRequest
): Promise<ListSummariesResult> => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await axios.post(
        `${hotelPriceFreezeListSummariesApi}`,
        body,
        {
          baseURL: config.baseURL,
        }
      );
      resolve(res.data);
    } catch (e) {
      reject(e);
    }
  });
};
