import React from "react";
import {
  Airline,
  BookedFlightItineraryWithDepartureTime,
  MultiTravelItinerary,
  SingleTravelItinerary,
  TravelItineraryEnum,
} from "redmond";
import { Typography, Box, Button } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";
import { uniqWith, isEqual } from "lodash-es";

import { ConfirmationModalContentConnectorProps } from "./container";
import "./styles.scss";
import { copyTextToClipboardPromise } from "./helpers";
import * as textConstants from "./constants";

interface IConfirmationModalContentProps
  extends ConfirmationModalContentConnectorProps {
  flight: BookedFlightItineraryWithDepartureTime;
}

export interface IConfirmationNumber {
  label: string;
  locator: string;
  redirectUrl: string;
}

const onCopyAndGo = async (locator: string, redirectUrl: string) => {
  await copyTextToClipboardPromise(locator);
  window.open(redirectUrl, "_blank");
};

const filterDuplicates = (confirmationNumbers: IConfirmationNumber[]) =>
  uniqWith(confirmationNumbers, isEqual);

export const getConfirmationNumbers = ({
  flight,
  airlineMap,
}: {
  flight: BookedFlightItineraryWithDepartureTime;
  airlineMap: { [key: string]: Airline };
}): IConfirmationNumber[] => {
  const { bookedItinerary } = flight;
  const { travelItinerary } = bookedItinerary;
  const { TravelItinerary } = travelItinerary;

  const confirmationNumbers: IConfirmationNumber[] = [];

  if (
    (travelItinerary as SingleTravelItinerary)?.locators?.agent.unscopedValue
  ) {
    confirmationNumbers.push({
      label: textConstants.CAPITAL_ONE_LABEL,
      locator:
        `H-${(travelItinerary as SingleTravelItinerary).locators?.agent
          .unscopedValue
        }` || "",
      redirectUrl: textConstants.CAPITAL_ONE_WEBSITE,
    });
  }

  if (TravelItinerary === TravelItineraryEnum.SingleTravelItinerary) {
    const singleTravelItinerary = travelItinerary as SingleTravelItinerary;
    singleTravelItinerary.slices.forEach((slice, sliceIndex) => {
      slice.segments.forEach((segment) => {
        const confirmationNumber = textConstants.getConfirmationNumber({
          segment,
          airlineMap,
          isReturn: sliceIndex !== 0,
        });
        if (confirmationNumber) {
          confirmationNumbers.push(confirmationNumber);
        }
      });
    });
  }
  if (TravelItinerary === TravelItineraryEnum.MultiTravelItinerary) {
    const multipleTravelItinerary = travelItinerary as MultiTravelItinerary;

    if (multipleTravelItinerary.locators?.children) {
      multipleTravelItinerary.travelItineraries.forEach((itinerary) => {
        itinerary.slices.forEach((slice, sliceIndex) => {
          slice.segments.forEach((segment) => {
            const confirmationNumber = textConstants.getConfirmationNumber({
              segment,
              airlineMap,
              isReturn: sliceIndex !== 0,
            });
            if (confirmationNumber) {
              confirmationNumbers.push(confirmationNumber);
            }
          });
        });
      });
    }
  }
  return confirmationNumbers;
};

export const ConfirmationModalContent = ({
  flight,
  airlineMap,
}: IConfirmationModalContentProps) => {
  const confirmationNumbers = filterDuplicates(
    getConfirmationNumbers({ flight, airlineMap })
  );

  const renderConfirmationNumbers = () => {
    return (
      <Box className="confirmation-container">
        {confirmationNumbers.map(({ label, locator, redirectUrl }, index) => (
          <Box
            key={`${locator}-${index}`}
            className={clsx("confirmation-details-container", {
              "container-border": index !== confirmationNumbers.length - 1,
            })}
          >
            <Box className="confirmation-details">
              <Typography className="confirmation-label" variant="body2">
                {label}
              </Typography>
              <Typography variant="body2" className="confirmation-locator">
                {locator}
              </Typography>
            </Box>
            {index !== 0 && (
              <Button
                className="confirmation-copy-container"
                onClick={() => onCopyAndGo(locator, redirectUrl)}
                aria-label="Copy and Go"
              >
                <Typography variant="body2" className="copy-text">
                  {textConstants.COPY_AND_GO}
                </Typography>
                <FontAwesomeIcon icon={faEdit} className="copy-icon" />
              </Button>
            )}
          </Box>
        ))}
      </Box>
    );
  };

  return (
    <Box className="confirmation-modal-content">
      <Box className="confirmation-title-container">
        <Typography variant="h4">
          {`${textConstants.CONFIRMATION_TITLE} (${confirmationNumbers.length})`}
        </Typography>
        <Typography variant="body2" className="confirmation-text">
          {textConstants.MODAL_SUBTITLE}
        </Typography>
      </Box>
      {renderConfirmationNumbers()}
    </Box>
  );
};
