import React from "react";
import { Box, Typography } from "@material-ui/core";

import { CONFIRMATION } from "../FlightCard/constants";
import "./styles.scss";

interface IConfirmationSummary {
  className?: string;
  confirmationLabel?: string;
  confirmationCode: string;
}

export const ConfirmationSummary = ({
  confirmationLabel = CONFIRMATION,
  confirmationCode,
}: IConfirmationSummary) => (
  <Box className="confirmation-summary-container">
    <Typography variant="body2" className="confirmation-text">
      {confirmationLabel}
    </Typography>
    <Typography variant="subtitle2" className="confirmation-number">
      {confirmationCode}
    </Typography>
  </Box>
);
