import React, { Component, ErrorInfo, ReactNode } from "react";
import { Box } from "@material-ui/core";
import clsx from "clsx";
import { ActionButton, NoResults } from "halifax";
import { RouteComponentProps, withRouter } from "react-router";
import { TRIGGERED_ERROR_BOUNDARY } from "redmond";

import { trackEvent } from "../../api/v1/analytics/trackEvent";
import {
  ERR_BOUNDARY_SUBTITLE,
  ERR_BOUNDARY_TITLE,
  REFRESH,
} from "./constants";

import "./styles.scss";

export interface IErrorBoundaryProps extends RouteComponentProps {
  children: ReactNode;
  isMobile: boolean;
}

export interface IErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends Component<
  IErrorBoundaryProps,
  IErrorBoundaryState
> {
  static defaultProps: Partial<IErrorBoundaryProps> = {
    children: false,
    isMobile: false,
  };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  constructor(props: IErrorBoundaryProps) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const { location } = this.props;

    if (process.env.NODE_ENV !== "production") {
      console.trace(errorInfo.componentStack);
    }

    trackEvent({
      eventName: TRIGGERED_ERROR_BOUNDARY,
      properties: {
        component_stack: errorInfo.componentStack,
        error_msg: error.message,
        error_name: error.name,
        error_stack: error.stack,
        location: location.pathname,
        location_search: location.search,
      },
    });
  }

  render() {
    const { children, isMobile } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return (
        <Box
          className={clsx("trips-module-error-boundary", { mobile: isMobile })}
        >
          <NoResults
            className="no-results-copy"
            subtitle={ERR_BOUNDARY_SUBTITLE}
            title={ERR_BOUNDARY_TITLE}
          />
          <ActionButton
            className="fallback-action b2b"
            message={REFRESH}
            onClick={() => window.location.reload()}
          />
        </Box>
      );
    }

    return children;
  }
}

export default withRouter(ErrorBoundary);
