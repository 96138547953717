import React, { ReactNode, useMemo } from "react";
import {
  GenericModalContent,
  Icon,
  IconName,
  ActionButton,
  useDeviceTypes,
} from "halifax";
import clsx from "clsx";

import { CONTACT_SUPPORT_URL } from "../../../../../../../../../../utils/paths";
import "./styles.scss";
import * as constants from "./constants";

interface IErrorScreenProps {
  onRetry: () => void;
  onClose: () => void;
  type: ErrorType;
}

export enum ErrorType {
  FailedToLoadCancellation,
  FailedToCancel,
}

export const ErrorScreen = (props: IErrorScreenProps) => {
  const { onRetry, onClose, type } = props;
  const { matchesMobile } = useDeviceTypes();

  const handleContactSupport = () => {
    onClose();
    window.open(CONTACT_SUPPORT_URL, "_blank", "noopener")?.focus();
  };

  const content: {
    title: ReactNode;
    subtitle: ReactNode;
    actions: ReactNode[];
  } | null = useMemo(() => {
    switch (type) {
      case ErrorType.FailedToLoadCancellation:
        return {
          title: constants.COULD_NOT_LOAD_CANCELLATION_TITLE,
          subtitle: constants.COULD_NOT_LOAD_CANCELLATION_SUBTITLE,
          actions: [
            <ActionButton
              defaultStyle="h4r-primary"
              message={constants.TRY_AGAIN}
              onClick={onRetry}
            />,
            <ActionButton
              defaultStyle="h4r-secondary"
              message={constants.CONTACT_SUPPORT}
              onClick={handleContactSupport}
            />,
          ],
        };
      case ErrorType.FailedToCancel:
        return {
          title: constants.CANCELLATION_FAILED_TITLE,
          subtitle: constants.CANCELLATION_FAILED_SUBTITLE,
          actions: [
            <ActionButton
              defaultStyle="h4r-primary"
              message={constants.CONTACT_SUPPORT}
              onClick={handleContactSupport}
            />,
          ],
        };
      default:
        return null;
    }
  }, [type]);

  if (!content) {
    return null;
  }

  return (
    <GenericModalContent
      className={clsx("cfar-model-content-error-screen-root", {
        mobile: matchesMobile,
      })}
      title={content.title}
      subtitle={content.subtitle}
      image={<Icon className="error-icon" name={IconName.ErrorState} />}
      actions={content.actions}
    />
  );
};
