import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../../../../../reducers/types";
import { MobileHomeItineraryDetails } from "./component";
import {
  getOpenModal,
  getSelectedHome,
  getTripsFilter,
} from "../../../../reducer";
import { setSelectedHome, setOpenModal } from "../../../../actions/actions";
import { withRouter } from "react-router";

const mapStateToProps = (state: IStoreState) => ({
  home: getSelectedHome(state),
  tripsFilter: getTripsFilter(state),
  openModal: getOpenModal(state),
});

const mapDispatchToProps = {
  setOpenModal,
  setSelectedHome,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MobileHomeItineraryDetailsConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedMobileHomeItineraryDetails = connector(
  withRouter(MobileHomeItineraryDetails)
);
