import axios from "axios";
import {
  GetDisruptionRefundRequest,
  GetDisruptionRefundResponse,
} from "redmond";

import { config } from "../../config";
import { prepareFlightRefundPath } from "../paths";

/**
 * @description Get the prepared payment object along with refund amount given a disruption protection ID
 * @param {GetDisruptionRefundRequest} req Contains the disruption protection ID
 * @return {GetDisruptionRefundResponse} The refund amount and the prepared payment object to pass along to execute the refund
 */
const getDisruptionPrepareFlightRefund = (
  req: GetDisruptionRefundRequest
): Promise<GetDisruptionRefundResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await axios.post(prepareFlightRefundPath, req, {
        baseURL: config.baseURL,
      });

      resolve(res.data);
    } catch (e) {
      reject(e);
    }
  });

export default getDisruptionPrepareFlightRefund;
