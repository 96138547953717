import { Dayjs } from "dayjs";
import { getFullTimeString } from "halifax";

export const DEPARTS_ON = "Departs on";
export const DATE_FORMAT = "ddd, MMM DD";
export const COMPLETE_BOOKING = "Continue to Booking";
export const EXPIRES_ON = "Expires on";
export const FROZEN_HEADER_MOBILE_HOTEL = "Hotel";
export const CHECK_IN = "Check-in";
export const CHECK_OUT = "Check-out";
export const CONFIRMATION = "Confirmation:";
export const formatBanner = (dateObject: Dayjs): string =>
  `This frozen price expired on ${getFullTimeString(dateObject)}.`;
