import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../../../../../../../reducers/types";
import { getAirlinesMap, getAirportMap } from "../../../../../../reducer";
import { ItinerarySummaryModalContent } from "./component";

const mapStateToProps = (state: IStoreState) => ({
  airlineMap: getAirlinesMap(state),
  airportMap: getAirportMap(state),
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type ItinerarySummaryModalContentConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedItinerarySummaryModalContent = connector(
  ItinerarySummaryModalContent
);
