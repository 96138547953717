import { CallState, WatchAlertViews } from "redmond";
import { put } from "redux-saga/effects";

import { listWatches } from "../../../api/v1/price-watch/listWatches";
import { actions } from "../actions";

export function* fetchWatchesSaga() {
  try {
    const value: WatchAlertViews = yield listWatches();
    yield put(actions.setListWatchesCallState(CallState.Success));
    yield put(actions.setWatches(value));
  } catch (e) {
    yield put(actions.setListWatchesCallState(CallState.Failed));
  }
}
