import { Airline, FlightItinerarySegment } from "redmond";
import { IConfirmationNumber } from "./component";

// TODO: Remove capital one constants
export const CAPITAL_ONE_LABEL = "Capital One Travel";
export const CAPITAL_ONE_WEBSITE = "https://www.capitalonetravel.com/";
export const OUTBOUND_TEXT = "Outbound";
export const RETURN_TEXT = "Return";
export const COPY_AND_GO = "Copy & Go";
export const CONFIRMATION_TITLE = "Check-in For Your Flight";
export const MODAL_SUBTITLE = `Check in for your flight by clicking the copy & go link below. You will be redirected to the airlines website.`;
export const NOTIFICATION_STRONG_LABEL = "Separate check-in required.";
export const NOTIFICATION_LABEL =
  "You will need to individually check-in for each leg before your departure.";

interface IGetConfirmationNumberProps {
  segment: FlightItinerarySegment;
  airlineMap: {
    [key: string]: Airline;
  };
  isReturn?: boolean;
}

export const getConfirmationNumber = ({
  segment,
  airlineMap,
  isReturn,
}: IGetConfirmationNumberProps): IConfirmationNumber | false => {
  const selectedAirline = airlineMap[segment.marketingAirline.code];
  if (!selectedAirline) return false;
  return {
    label: `${selectedAirline.displayName} ${
      isReturn ? RETURN_TEXT : OUTBOUND_TEXT
    }: ${segment.origin.locationCode} to ${segment.destination.locationCode}`,
    locator: segment.marketingAirline.locator || "",
    redirectUrl: selectedAirline.webLinks.manageBooking || "",
  };
};
