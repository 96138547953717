import axios from "axios";
import {
  BookedFlightItineraryWithDepartureTime,
  FlightCancellationRequest,
  CancellationResponse,
  SelfServeEvents,
} from "redmond";

import { trackEvent } from "../analytics/trackEvent";
import { config } from "../../config";
import { confirmFlightCancellationPath } from "../paths";
import { getSelfServeAirCancelProperties } from "../../../utils/events";

/**
 * @version v2
 * @description The second endpoint in the self serve cancel flight flow
 * @param {FlightCancellationRequest} req The context of the cancellation
 * @param {BookedFlightItineraryWithDepartureTime} flight Contains the `provider_name` for better tracking
 * @return {CancellationResponse} The copy to show in the cancellation result state
 */
const confirmFlightCancellation = (
  req: FlightCancellationRequest,
  flight: BookedFlightItineraryWithDepartureTime
): Promise<CancellationResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await axios.post(confirmFlightCancellationPath, req, {
        baseURL: config.baseURL,
      });
      const { Response, errors, value } = res.data;
      const selfServeProperties = getSelfServeAirCancelProperties(
        req.cancelScenario,
        flight
      );

      // call can succeed with a "Failure" Response so handle both cases
      if (Response === "Success") {
        trackEvent({
          eventName: SelfServeEvents.ResponseSuccess,
          properties: selfServeProperties,
        });
        resolve(value);
      } else {
        let errString = "";

        if (typeof errors === "string") {
          errString = errors;
        } else if (Array.isArray(errors)) {
          errString = errors
            .map((err: any) => `${err.code} - ${err.details}`)
            .join(", ");
        }

        trackEvent({
          eventName: SelfServeEvents.ResponseFailure,
          properties: {
            ...selfServeProperties,
            reason: errString,
          },
        });
        reject(errString);
      }
    } catch (e) {
      reject(e);
    }
  });

export default confirmFlightCancellation;
