import React from "react";
import {
  ActionButton,
  GenericModalContent,
  Icon,
  IconName,
  MobileFloatingButton,
} from "halifax";

import * as constants from "../../../../../PassengersModalContent/constants";
import { buttonText } from "../SelfServeCancelFlightModalContent/constants";
import "./styles.scss";
import { Typography } from "@material-ui/core";

export interface IRequestTravelerEditSuccessProps {
  closeModal: () => void;
  isMobile: boolean;
}

const defaultProps: Partial<IRequestTravelerEditSuccessProps> = {};

const RequestTravelerEditSuccess = ({
  closeModal,
  isMobile,
}: IRequestTravelerEditSuccessProps): JSX.Element => {
  return (
    <GenericModalContent
      className="request-traveler-success-container"
      image={<Icon className="success-icon" name={IconName.Checked} />}
      title={constants.REVIEWING_REQUEST}
      subtitle={constants.REQUEST_TRAVELER_EDIT_SUCCESS_SUBTITLE}
      actions={
        isMobile ? (
          <MobileFloatingButton onClick={closeModal}>
            <Typography variant="h3">{buttonText.DONE}</Typography>
          </MobileFloatingButton>
        ) : (
          <ActionButton
            className="done-btn"
            defaultStyle="h4r-primary"
            message={buttonText.DONE}
            onClick={closeModal}
          />
        )
      }
    />
  );
};

RequestTravelerEditSuccess.defaultProps = defaultProps;

export default RequestTravelerEditSuccess;
