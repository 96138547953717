export const SET_SELECTED_FLIGHT_DISRUPTION_PROTECTION_ITINERARY_ID =
  "tripsList/SET_SELECTED_FLIGHT_DISRUPTION_PROTECTION_ITINERARY_ID";
export type SET_SELECTED_FLIGHT_DISRUPTION_PROTECTION_ITINERARY_ID =
  typeof SET_SELECTED_FLIGHT_DISRUPTION_PROTECTION_ITINERARY_ID;

export const FETCH_FLIGHT_DISRUPTIONS = "tripsList/FETCH_FLIGHT_DISRUPTIONS";
export type FETCH_FLIGHT_DISRUPTIONS = typeof FETCH_FLIGHT_DISRUPTIONS;

export const SET_FETCH_FLIGHT_DISRUPTIONS_CALL_STATE_FAILED =
  "tripsList/SET_FETCH_FLIGHT_DISRUPTIONS_CALL_STATE_FAILED";
export type SET_FETCH_FLIGHT_DISRUPTIONS_CALL_STATE_FAILED =
  typeof SET_FETCH_FLIGHT_DISRUPTIONS_CALL_STATE_FAILED;

export const SET_FLIGHT_DISRUPTIONS = "tripsList/SET_FLIGHT_DISRUPTIONS";
export type SET_FLIGHT_DISRUPTIONS = typeof SET_FLIGHT_DISRUPTIONS;

export const FETCH_DISRUPTION_PREPARED_FLIGHT_REFUND =
  "disruptionProtection/FETCH_DISRUPTION_PREPARED_FLIGHT_REFUND";
export type FETCH_DISRUPTION_PREPARED_FLIGHT_REFUND =
  typeof FETCH_DISRUPTION_PREPARED_FLIGHT_REFUND;

export const SET_PREPARED_REFUND_INFO =
  "disruptionProtection/SET_PREPARED_REFUND_INFO";
export type SET_PREPARED_REFUND_INFO = typeof SET_PREPARED_REFUND_INFO;

export const SET_FETCH_FLIGHT_DISRUPTION_PREPARED_FLIGHT_REFUND_CALL_STATE =
  "disruptionProtection/SET_FETCH_FLIGHT_DISRUPTION_PREPARED_FLIGHT_REFUND_CALL_STATE";
export type SET_FETCH_FLIGHT_DISRUPTION_PREPARED_FLIGHT_REFUND_CALL_STATE =
  typeof SET_FETCH_FLIGHT_DISRUPTION_PREPARED_FLIGHT_REFUND_CALL_STATE;

export const SUBMIT_DISRUPTION_FLIGHT_REFUND =
  "disruptionProtection/SUBMIT_DISRUPTION_FLIGHT_REFUND";
export type SUBMIT_DISRUPTION_FLIGHT_REFUND =
  typeof SUBMIT_DISRUPTION_FLIGHT_REFUND;

export const SET_DISRUPTION_FLIGHT_REFUND_RESULT =
  "disruptionProtecton/SET_DISRUPTION_FLIGHT_REFUND_RESULT";
export type SET_DISRUPTION_FLIGHT_REFUND_RESULT =
  typeof SET_DISRUPTION_FLIGHT_REFUND_RESULT;

export const SET_SUBMIT_DISRUPTION_FLIGHT_REFUND_CALL_STATE =
  "disruptionProtection/SET_SUBMIT_DISRUPTION_FLIGHT_REFUND_CALL_STATE";
export type SET_SUBMIT_DISRUPTION_FLIGHT_REFUND_CALL_STATE =
  typeof SET_SUBMIT_DISRUPTION_FLIGHT_REFUND_CALL_STATE;

export const SET_PRODUCT_REDEEM_CHOICE =
  "disruptionProtection/SET_PRODUCT_REDEEM_CHOICE";
export type SET_PRODUCT_REDEEM_CHOICE = typeof SET_PRODUCT_REDEEM_CHOICE;

export const SET_DISRUPTION_PROTECTION_EXPERIMENTS =
  "disruptionProtection/SET_DISRUPTION_PROTECTION_EXPERIMENTS";
export type SET_DISRUPTION_PROTECTION_EXPERIMENTS =
  typeof SET_DISRUPTION_PROTECTION_EXPERIMENTS;

export const SET_DISRUPTION_PROTECTION_24H_RULE_VARIANT =
  "flightAncillary/SET_DISRUPTION_PROTECTION_24H_RULE_VARIANT";
export type SET_DISRUPTION_PROTECTION_24H_RULE_VARIANT =
  typeof SET_DISRUPTION_PROTECTION_24H_RULE_VARIANT;

export const SET_SLICE_SELECTED_FOR_MCP_VI =
  "flightAncillary/SET_SLICE_SELECTED_FOR_MCP_VI";
export type SET_SLICE_SELECTED_FOR_MCP_VI =
  typeof SET_SLICE_SELECTED_FOR_MCP_VI;
