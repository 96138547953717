import axios from "axios";
import {
  LodgingPriceFreezeRefundRequest,
  LodgingPriceFreezeRefundResult,
} from "redmond";
import { config } from "../../config";
import { refundHotelPriceFreezeApiPath } from "../paths";

export const refundHotelPriceFreeze = (
  request: LodgingPriceFreezeRefundRequest
): Promise<LodgingPriceFreezeRefundResult> =>
  new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(
        refundHotelPriceFreezeApiPath,
        request,
        {
          baseURL: config.baseURL,
        }
      );

      resolve(response.data);
    } catch (e) {
      reject(e);
    }
  });
