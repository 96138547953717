import React, { ReactNode } from "react";
import { Typography } from "@material-ui/core";
import { IOption } from "halifax";

import {
  AirlineCode,
  IPersonWithNameNumber,
  IRequestTravelerEditFormEnum,
} from "redmond";

export const CANCEL_HOTEL = "Cancel Hotel";
export const CANCEL_HOTEL_HEADING = "Cancel Hotel Reservation";
export const CANCEL_HOTEL_FULL_TRAVEL_OFFER =
  "Your booking is cancelable, however please note the Travel Offer you applied is non-refundable and can only be used once.<br /><br />As such, <b>no refund will be issued for this booking.</b>";
export const CANCEL_HOTEL_FULL_TRAVEL_OFFER_CONFIRM =
  "Your booking will be canceled, and <b>no refund or credit will be issued.</b>";
export const CANCEL_HOTEL_PARTIAL_TRAVEL_OFFER =
  "As the Travel Offer you applied was non-refundable and single-use, a <b>refund will only be issued for the amount you originally paid.</b>";
export const CANCEL_HOTEL_PARTIAL_TRAVEL_OFFER_CONFIRM =
  "Your booking will be canceled, and <b>a refund will only be issued for the amount you originally paid.</b>";
export const CHANGE_HOTEL_HEADING = "Change Hotel Reservation";
export const MULTIROOM_CANCEL_INFO =
  "<strong>Canceling this reservation will cancel all rooms included in the booking.</strong>";

export const CANCEL_GROUND = "Cancel Booking";
export const CONFIRM_CANCEL = "Confirm Cancellation";
export const CLOSE = "Close";
export const TRY_AGAIN = "Try Again";
export const CANCELLATION_PENDING = "Cancellation Pending";
export const CANCELLATION_SUCCESS_COPY = [
  "A confirmation email will be sent shortly.",
  "'My Trips' will be updated within a few minutes.",
];
export const CANCELLATION_SUCCESS_TITLE = "Cancellation Complete";
export const LOADING_HOTEL_POLICY =
  "Loading hotel details and cancellation policy…";
export const LOADING_GROUND_CANCEL_POLICY =
  "Loading reservation and cancellation policy";
export const SUBHEADING_AGENT =
  "To change this hotel booking, please contact our customer support team and someone will assist you";
export const CONTACT_SUPPORT = "Contact Support";
export const SUBHEADING =
  "This booking is managed by the hotel. To cancel this reservation, please contact the hotel directly.";
export const CANCEL = "Cancel";
export const DONE = "Done";
export const CONTINUE_TO_HOTEL = "Continue to hotel website";
export const BACK_TO_MY_TRIPS = "Back to my trips";
export const HEADING = "Cancel Car Rental";
export const SUBHEADING_GROUND =
  "This rental is managed by the provider. To cancel this rental, please visit the carrier website to manage your booking.";
export const SUBHEADING_AGENT_GROUND =
  "To cancel this car rental, please contact our customer support team and someone will assist you.";
export const PROVIDER_CTA = "Continue to the provider website";
export const GROUND_PRODUCT = "ground";
export const SOMETHING_WENT_WRONG = "Something went wrong";
export const PICK_UP = "Pick-up";
export const PLEASE_TRY_AGAIN = "Please Try Again";
export const DROP_OFF = "Drop-off";
export const CONTINUE_TO_PROVIDER_WEBSITE = "Continue to provider's website";
export const REQUEST_TRAVELER_EDIT_TITLE = "Request Minor Correction";
export const PLEASE_CANCEL_PLACEHOLDER =
  "Name changes not permitted, please cancel free and rebook.";
export const REQUEST_TRAVELER_EDIT_SUBTITLE = (
  <>
    For name changes or name corrections&nbsp;<u>greater than 3 characters</u>,
    please contact the airline directly.
  </>
);
export const REQUEST_EDIT_VOIDABLE_SUBTITLE = (cta: ReactNode) => (
  <>
    <Typography variant="body2" component="span">
      While name changes are not permitted for this flight, you can
    </Typography>
    &nbsp;{cta}&nbsp;
    <Typography variant="body2" component="span">
      free of charge and rebook.
    </Typography>
  </>
);
export const CANCEL_YOUR_BOOKING = "cancel your booking";
export const REQUEST_EDIT_VOIDABLE =
  "You'll need to cancel today free of charge via My Trips and rebook";
export const REQUEST_EDIT_MULTI_TICKET =
  "Unfortunately name corrections are not permitted for this ticket";
export const SUBMITTING_REQUEST_EDIT = "We're submitting your request…";
export const SUBMITTING_REQUEST_EDIT_SUBTITLE =
  "Please do not exit until the submission is complete.";
export const SUBMIT_REQUEST = "Submit Request";
export const GATHERING_PASSENGER_INFO = "Gathering passenger information…";
export const SPECIAL_ASSISTANCE_OPTIONS = [
  { value: "NONE", label: "Select Assistance" },
  { value: "BLND", label: "Blind Passenger" },
  { value: "DEAF", label: "Deaf Passenger" },
  { value: "STCR", label: "Stretcher Assistance" },
  { value: "WCHR", label: "Wheelchair/Passenger can walk up stairs" },
  { value: "WCHS", label: "Wheelchair/Passenger can walk to seat" },
  { value: "WCHC", label: "Wheelchair/Passenger must be carried" },
  { value: "WCBD", label: "Wheelchair with Dry Cell Battery" },
  { value: "WCBW", label: "Wheelchair with Wet Cell Battery" },
  { value: "WCLB", label: "Wheelchair with Lithium Battery" },
  { value: "WCMP", label: "Wheelchair, manual power" },
  { value: "WCOB", label: "Wheelchair, On-Board" },
];
export const REQUEST_EDIT_ALLOWED_FIELDS = {
  [IRequestTravelerEditFormEnum.frequentFlyerNumber]: {
    initialValueFunc: (
      _: string,
      trav: IPersonWithNameNumber,
      airlineCode: AirlineCode
    ) => trav.frequentFlyer[airlineCode] || "",
    propKey: "frequentFlyer",
    label: "Frequent Flyer Number",
    placeholder: "Enter New Frequent Flyer Number and Program",
    options: null,
  },
  [IRequestTravelerEditFormEnum.tsaRedressNumber]: {
    initialValueFunc: (initialValue: string) => initialValue,
    propKey: "",
    label: "TSA Redress Number",
    placeholder: "Enter New TSA Redress Number",
    options: null,
  },
  [IRequestTravelerEditFormEnum.knownTravelerNumber]: {
    initialValueFunc: (initialValue: string) => initialValue,
    propKey: "",
    label: "Known Traveler Number",
    placeholder: "Enter Known Traveler Number and Issuing Country",
    options: null,
  },
  [IRequestTravelerEditFormEnum.name]: {
    initialValueFunc: (_: string, trav: IPersonWithNameNumber) =>
      `${trav.surname}/${trav.givenName}${
        trav.middleName ? ` ${trav.middleName}` : ""
      }`,
    propKey: "givenName",
    label: "Name Correction",
    placeholder: "Enter Last/First and Middle - max 3 character update allowed",
    options: null,
  },
  [IRequestTravelerEditFormEnum.birthdate]: {
    initialValueFunc: (initialValue: string) => {
      try {
        const formattedDate = new Date(initialValue);
        let month = "" + (formattedDate.getMonth() + 1);
        let day = "" + formattedDate.getDate();
        const year = formattedDate.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;
        return `${month}/${day}/${year}`;
      } catch {
        return initialValue;
      }
    },
    propKey: "dateOfBirth",
    label: "Birthdate",
    placeholder: "Enter New Birthdate (mm/dd/yyyy)",
    options: null,
  },
  [IRequestTravelerEditFormEnum.gender]: {
    initialValueFunc: (initialValue: string) => {
      if (initialValue?.toLowerCase().startsWith("f")) {
        return "Female";
      }
      if (initialValue?.toLowerCase().startsWith("m")) {
        return "Male";
      }
      return "";
    },
    propKey: "gender",
    label: "Gender",
    placeholder: "Enter New Gender",
    options: null,
  },
  [IRequestTravelerEditFormEnum.specialAssistance]: {
    initialValueFunc: (initialValue: string) => {
      const initialAssistance: IOption | undefined =
        SPECIAL_ASSISTANCE_OPTIONS.find(
          (sao) => initialValue && [sao.value, sao.label].includes(initialValue)
        );

      return initialAssistance
        ? initialAssistance.value
        : SPECIAL_ASSISTANCE_OPTIONS[0].value;
    },
    propKey: "assistance",
    label: "Special Assistance",
    placeholder: "Enter Requested Assistance",
    options: SPECIAL_ASSISTANCE_OPTIONS,
  },
};
export const ALREADY_CANCELED_TITLE = "This booking has already been canceled";
export const ALREADY_CANCELED_SUBTITLE =
  "Our records indicate that this booking has already been canceled. If this is incorrect, please contact our customer support team for assistance.";
