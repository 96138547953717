import dayjs from "dayjs";
import { Airport } from "redmond";

export const SELECT_THE_CONNECTING_FLIGHT = `Select the connecting flight you missed and we'll provide you with free rebooking options that get you to `;
export const COST_OF_REBOOKING =
  "The cost of this rebooking is covered under our ";
export const MISSED_CONNECTION_GUARANTEE = "missed connection guarantee";
/** @see https://hopper-jira.atlassian.net/browse/COTA-1093 */
export const MISSED_CONNECTION_REBOOKING = "missed connection rebooking";
export const KNOW_BEFORE = "Know before you rebook";
export const DATE_FORMAT = "ddd, MMM D";
export const IMPORTANT_INFORMATION = "Important information";
export const NO_AVAILABLE_SEGMENTS =
  "We could not find any segment available for rebook";
export const REBOOK_CONNECTING_FLIGHT = "Rebook connecting flight";
export const TRAVELERS = "Travelers";
export const TRAVELER = "Traveler";

const STEP_1 =
  "Online rebooking is only available if rebooking for all original travelers. If you would only like to rebook some of the passengers included in your original booking, please call us at 844-422-6922.";
const STEP_2 =
  "When rebooking, you’ll choose from flights that depart on the same day or following day of your original flight, arrive at the final intended destination as your original flight, and are the same or a lower fare class.";
const STEP_3 =
  "You can choose from flights that are within a $5,000 cap per traveler.";

export const IMPORTANT_INFO_LIST = [STEP_1, STEP_2, STEP_3];
export interface IPartialAirportMap {
  [airportCode: string]: Partial<Airport>;
}

interface arguments {
  airports: IPartialAirportMap;
  originCode: string;
  destinationCode: string;
  date: string;
}

export const getItineraryDetailsHeader = ({
  airports,
  originCode,
  destinationCode,
  date,
}: arguments) => {
  const { cityName: originCity } = airports[originCode];
  const { cityName: destinationCity } = airports[destinationCode];
  const formatedDate = dayjs(date).format(DATE_FORMAT);
  return `<b>${originCity}</b> (${originCode}) <b>to ${destinationCity} </b>(${destinationCode}) on ${formatedDate} `;
};
