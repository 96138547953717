import { ICoordinates } from "redmond";

export const convertICoordinatesToGoogleMapCoords: (
  coordinates: ICoordinates
) => google.maps.LatLng = (coordinates) => {
  return new google.maps.LatLng({
    lat: coordinates.lat,
    lng: coordinates.lon,
  });
};

export const convertGoogleMapCoordsToICoordinates: (
  googleMapCoords: google.maps.LatLng
) => ICoordinates = (googleMapCoords) => {
  return {
    lat: googleMapCoords.lat(),
    lon: googleMapCoords.lng(),
  };
};
