import React from "react";
import { Box, Typography } from "@material-ui/core";
import { CarReservation, LocationBookDateTime } from "redmond";
import { HotelMapPin } from "halifax";
import { GoogleMap, OverlayView } from "@react-google-maps/api";
import clsx from "clsx";
import _ from "lodash-es";
import dayjs from "dayjs";

import { convertICoordinatesToGoogleMapCoords } from "../../../../../../../../utils/googleMapsHelpers";
import * as constants from "./constants";
import "./styles.scss";

export interface ICarMapProps {
  car: CarReservation;
  hideLabel?: boolean;
}

export const CarMap = (props: ICarMapProps) => {
  const { car } = props;
  const { bookResult, samePickUpDropOff } = car;
  const { dropOff, pickUp } = bookResult;

  const isDropOffSameAsPickUp =
    samePickUpDropOff ?? _.isEqual(pickUp.location, dropOff.location);

  const pickUpCenter =
    pickUp.location.coordinates &&
    convertICoordinatesToGoogleMapCoords(pickUp.location.coordinates);

  const dropOffCenter =
    dropOff.location.coordinates &&
    convertICoordinatesToGoogleMapCoords(dropOff.location.coordinates);

  return (
    <Box className="small-car-map-container">
      <Box className="map-container">
        <Box
          className={clsx(
            isDropOffSameAsPickUp ? "main-content" : "left-content"
          )}
        >
          <Typography className="car-shop-map-label">
            {isDropOffSameAsPickUp
              ? constants.getTitle(constants.PICK_UP_AND_DROP_OFF)
              : constants.getTitle(constants.PICK_UP)}
          </Typography>
          <GoogleMap
            id="small-car-map"
            mapContainerStyle={{ width: "100%" }}
            options={{
              clickableIcons: false,
              zoomControl: true,
              streetViewControl: false,
              mapTypeControl: false,
              draggable: true,
              fullscreenControl: false,
              draggableCursor: "pointer",
            }}
            center={pickUpCenter}
            zoom={16}
          >
            {pickUpCenter ? <SmallHotelMapContent center={pickUpCenter} /> : null}
          </GoogleMap>
        </Box>
        {!isDropOffSameAsPickUp && (
          <Box className="right-content">
            <Typography className="car-shop-map-label">
              {constants.getTitle(constants.DROP_OFF)}
            </Typography>
            <GoogleMap
              id="small-car-map"
              mapContainerStyle={{ width: "100%" }}
              options={{
                clickableIcons: false,
                zoomControl: true,
                streetViewControl: false,
                mapTypeControl: false,
                draggable: true,
                fullscreenControl: false,
                draggableCursor: "pointer",
              }}
              center={dropOffCenter}
              zoom={16}
            >
              {dropOffCenter ? <SmallHotelMapContent center={dropOffCenter} /> : null}
            </GoogleMap>
          </Box>
        )}
      </Box>

      <Box className="time-place-container">
        <TimeAndPlaceDetails label={constants.PICK_UP} timeAndPlace={pickUp} />
        <TimeAndPlaceDetails
          label={constants.DROP_OFF}
          timeAndPlace={dropOff}
        />
      </Box>
    </Box>
  );
};

export const SmallHotelMapContent = ({
  center,
}: {
  center: google.maps.LatLng;
}) => (
   <>
    <OverlayView
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      position={center!}
    >
      <HotelMapPin />
    </OverlayView>
  </>
);

const TimeAndPlaceDetails = ({
  label,
  timeAndPlace,
}: {
  label: string;
  timeAndPlace: LocationBookDateTime | false;
}) => {
  if (!timeAndPlace) return null;
  const formattedDate = dayjs(timeAndPlace.dateTime).format(
    "ddd, MMM D | h:mm A"
  );

  return (
    <Box>
      <Typography className="time-place-label">{label}</Typography>
      <Typography className="time-place-date">{formattedDate}</Typography>
      <Typography className="time-place-address">
        {timeAndPlace.location.address}
      </Typography>
    </Box>
  );
};
