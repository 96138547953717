import React from "react";
import dayjs from "dayjs";
import { CarReservation } from "redmond";

export const RENTAL_CAR = "Rental Car";
export const PICK_UP = "Pick-up";
export const PICKED_UP = "Pick-up";
export const PICKED_UP_ON = "Pick-up on";
export const DROP_OFF = "Drop-off";
export const CONFIRMATION = "Confirmation:";
export const DAYS = "Days";
export const DAY = "Day";
export const PICK_UP_LOCATION = "Pick-up Location";
export const DROP_OFF_LOCATION = "Drop-off Location";
export const PICK_UP_TIME = "Pick-up time";
export const DROP_OFF_TIME = "Drop-off time";
export const VIEW_DETAILS = "View details";
export const VIEW_LESS = "View less";
export const DATE_FORMAT = "ddd, MMM D";
export const CANCELED = "Canceled";
export const CANCELED_RES = "This reservation has been canceled.";
export const CAR_DETAILS_MOBILE = "Car Details";
export const CAR_DETAILS_MORE = "Cancellation, fuel policy and more";
export const DRIVER_INFO = "Driver Information";
export const CANCELLATION_POLICY = "Cancellation Policy";

export const getHeaderText = (checkInDate?: string, checkOutDate?: string) => {
  return checkInDate ? (
    <>
      <strong>{RENTAL_CAR}</strong> <span className="separator">|</span>{" "}
      {dayjs(checkInDate).format(DATE_FORMAT)} -{" "}
      {dayjs(checkOutDate).format(DATE_FORMAT)}
    </>
  ) : (
    <strong>{RENTAL_CAR}</strong>
  );
};

export const getCarInfoDetails = ({ bookResult }: CarReservation) => ({
  title: `${bookResult.pickUp.location.city}, ${
    bookResult.pickUp.location.stateProv?.code ||
    bookResult.pickUp.location.country
  }`,
  confirmationCode: `H-${
    bookResult.confirmationInfo?.supplierBookingId ||
    bookResult.confirmationInfo?.providerBookingId ||
    bookResult.groundBookingId
  }`,
  startDate: dayjs(bookResult.pickUp.dateTime).format(DATE_FORMAT),
  endDate: dayjs(bookResult.dropOff.dateTime).format(DATE_FORMAT),
});

export const getCarDuration = (reservation: CarReservation) => {
  const days = dayjs(reservation.bookResult.dropOff.dateTime).diff(
    reservation.bookResult.pickUp.dateTime,
    "day"
  );

  return `${days} ${days ? DAYS : DAY}`;
};
