import React from "react";
import { RouteComponentProps, StaticContext } from "react-router";
import { Box, Typography } from "@material-ui/core";
import {
  BannerSeverity,
  Icon,
  IconName,
  MyTripsMobileCard,
  NotificationBanner,
} from "halifax";
import { CarReservation, State } from "redmond";
import clsx from "clsx";

import { CarCardConnectorProps } from "./container";
import * as textConstants from "./constants";
import "./styles.scss";
import { DesktopCarCard } from "./components/DesktopCarCard";
import { scrollTopWithOffset } from "../../component";
import { PersonalBusinessLabel } from "../../../capone-corporate/PersonalBusinessLabel";

interface ICarCardProps
  extends CarCardConnectorProps,
    RouteComponentProps<{}, StaticContext, { prevPath?: string }> {
  isMobile?: boolean;
  car: CarReservation;
  expandedCard: string;
  onExpandCard: (cardId: string) => void;
  isCorporate?: boolean;
}

export const CarCard = ({
  history,
  car,
  isMobile,
  isPastTrips,
  populateTripQueryParams,
  setSelectedCar,
  onExpandCard,
  expandedCard,
  setOpenModal,
  isCorporate,
}: ICarCardProps) => {
  const carRef = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    if (
      !isMobile &&
      carRef?.current &&
      expandedCard === car.bookResult.groundBookingId
    ) {
      scrollTopWithOffset(carRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expandedCard]);

  const { State: state } = car.bookResult.status;
  const statusBanner = getStatusBanner(state);
  const isCancel = [State.Cancelled, State.CancelledWithErrors].includes(state);
  const confirmationCodeClassName = isCancel ? "warning" : "";
  const driverName = [
    `${car.bookResult.driver?.givenName} ${car.bookResult.driver?.surname}`,
  ];

  return (
    <Box className={clsx({ mobile: isMobile }, "car-list")}>
      {isMobile ? (
        <MyTripsMobileCard
          onClick={() => {
            setSelectedCar(car);
            populateTripQueryParams(history);
          }}
          banner={statusBanner}
          confirmationCodeClassName={isCancel ? "warning" : ""}
          confirmationLabel={textConstants.CONFIRMATION}
          label={textConstants.RENTAL_CAR}
          {...textConstants.getCarInfoDetails(car)}
          icon={IconName.CarFunnelIcon}
          supplierIconUrl={car.bookResult.supplier.logo}
          travelerNames={driverName}
          personalBusinessLabel={
            <PersonalBusinessLabel isCorporate={isCorporate} />
          }
        />
      ) : (
        <div ref={carRef}>
          <Box className="car-card-icon-title">
            <Icon name={IconName.CarFunnelIcon} />
            <Typography variant="body1" className="summary-label">
              {textConstants.getHeaderText(
                car.bookResult.pickUp.dateTime,
                car.bookResult.dropOff.dateTime
              )}
              <PersonalBusinessLabel isCorporate={isCorporate} />
            </Typography>
          </Box>
          <DesktopCarCard
            banner={statusBanner}
            car={car}
            confirmationCodeClassName={confirmationCodeClassName}
            isCancel={isCancel}
            isMobile={isMobile}
            isPastTrips={isPastTrips}
            expandedCard={expandedCard}
            onExpandCard={onExpandCard}
            setOpenModal={setOpenModal}
            driverName={driverName}
          />
        </div>
      )}
    </Box>
  );
};

export const getStatusBanner = (status?: State) => {
  switch (status) {
    case State.Cancelled:
    case State.CancelledWithErrors:
      return (
        <NotificationBanner
          className="status-banner"
          label={textConstants.CANCELED_RES}
          severity={BannerSeverity.ERROR}
          icon={<Icon name={IconName.ErrorAlert} />}
        />
      );
    default:
      return undefined;
  }
};
