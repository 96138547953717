import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { IStoreState } from "../../../../reducers/types";
import { listWatches, setOpenModal } from "../../actions/actions";
import { WatchList } from "./component";
import { getWatchContext, watchesSelector } from "../../reducer";

const mapStateToProps = (state: IStoreState) => ({
  watches: watchesSelector(state),
  context: getWatchContext(state),
});

const mapDispatchToProps = {
  listWatches,
  setOpenModal,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type WatchListConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedWatchList = withRouter(connector(WatchList));
