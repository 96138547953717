import { connect, ConnectedProps } from "react-redux";

import { FlightToHotelCrossSell } from "./component";

import { withRouter } from "react-router";
import {
  getRewardsAccounts,
  getRewardsAccountWithLargestEarnForBanner,
} from "../../../../../../modules/rewards/reducer";
import { IStoreState } from "../../../../../../reducers/types";
import {
  getHotelAvailabilitySearchLocationResult,
  getHotelAvailabilityFromDate,
  getHotelAvailabilityUntilDate,
  getSearchedNightCount,
  getHotelShopParams,
  getSortedLodgingsByPricing,
  getHotelAvailabilityAdultsCount,
  getHotelAvailabilityChildren,
  getViewedHotelListProperties,
  getCrossSellLatency,
  getFirstCrossellOffer,
  getAvailableCrossSellHotels,
} from "../../../../reducer";
import { setSelectedLodgingIndex } from "../../../../actions/actions";

const mapStateToProps = (state: IStoreState) => ({
  latency: getCrossSellLatency(state),
  lodgings: getAvailableCrossSellHotels(state),
  searchLocationResult: getHotelAvailabilitySearchLocationResult(state),
  fromDate: getHotelAvailabilityFromDate(state),
  untilDate: getHotelAvailabilityUntilDate(state),
  largestValueAccount: getRewardsAccountWithLargestEarnForBanner(state),
  nightCount: getSearchedNightCount(state),
  isVentureX: !!getRewardsAccounts(state).find((acc) =>
    acc.productDisplayName.includes("Venture X")
  ),
  hotelShopParams: getHotelShopParams(state),
  sortedLodgings: getSortedLodgingsByPricing(state),
  adultsCount: getHotelAvailabilityAdultsCount(state),
  children: getHotelAvailabilityChildren(state),
  viewedHotelListProperties: getViewedHotelListProperties(state),
  firstCrossSellOffer: getFirstCrossellOffer(state),
});

const mapDispatchToProps = {
  setSelectedLodgingIndex,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type FlightToHotelCrossSellConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedFlightToHotelCrossSell = connector(
  withRouter(FlightToHotelCrossSell)
);
