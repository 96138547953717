import { actions } from "../actions";
import { put } from "redux-saga/effects";
import {
  AvailabilityResponse,
  ActiveCrossSellOffersResponse,
  AvailabilityRequestEnum,
  PlatformEnum,
  InitialSearch,
  IIdLodgings,
  CallState,
} from "redmond";
import dayjs from "dayjs";
import { fetchCrossSellHotelAvailability } from "../../../api/v1/cross-sell/fetchCrossSellHotelAvailability";
import { fetchTravelWalletDetails } from "../../../modules/travel-wallet/actions/actions";
import { fetchCrossSellActiveOffers } from "../../../api/v1/cross-sell/fetchCrossSellActiveOffers";
import { isMobile } from "../utils/userAgent";

export function* fetchActiveHotelCrossSellSaga() {
  try {
    const startTime = dayjs();

    const response: ActiveCrossSellOffersResponse =
      yield fetchCrossSellActiveOffers();
    yield put(actions.setCrossSellOffers(response.activeOffers));

    yield put(actions.setFetchActiveHotelCrossSellCallState(CallState.Success));

    if (response.activeOffers.length > 0) {
      const activeCrossSellOffer = response.activeOffers[0];
      const firstActiveOffer = activeCrossSellOffer.destinationDetail;

      if (firstActiveOffer.lodgingSelection) {
        const requestBody: InitialSearch = {
          lodgingSelection: firstActiveOffer.lodgingSelection,
          stayDates: {
            from: firstActiveOffer.stayDates.from,
            until: firstActiveOffer.stayDates.until,
          },
          guests: {
            adults: firstActiveOffer.guests.adults,
            children: firstActiveOffer.guests.children,
          },
          platform: isMobile() ? PlatformEnum.Mobile : PlatformEnum.Desktop,
          progressiveConfig: {},
          AvailabilityRequest: AvailabilityRequestEnum.InitialSearch,
        };

        yield put(
          actions.setSearchedDates(
            new Date(firstActiveOffer.stayDates.from.replace(/-/g, "/")), // If using `YYYY-MM-DD` format, it gives us prev date: https://stackoverflow.com/questions/7556591/is-the-javascript-date-object-always-one-day-off
            new Date(firstActiveOffer.stayDates.until.replace(/-/g, "/"))
          )
        );

        yield put(
          actions.setSearchedLocationResult({
            id: {
              lodgingSelection: firstActiveOffer.lodgingSelection,
            } as IIdLodgings,
            label:
              // TODO: should be using cityName but currently that's coming back as Miami instead of Miami, FL, USA
              firstActiveOffer.lodgingSelection["searchTerm"] ??
              firstActiveOffer.cityName,
          })
        );
        const availabilityResponse: AvailabilityResponse =
          yield fetchCrossSellHotelAvailability(requestBody);

        yield put(fetchTravelWalletDetails());

        const latency = dayjs().diff(startTime, "seconds", true);
        yield put(
          actions.setCrossSellHotelAvailabilityResults({
            payload: availabilityResponse,
            latency,
          })
        );
      }
    }
    return;
  } catch (e) {
    yield put(actions.setFetchActiveHotelCrossSellCallState(CallState.Failed));
    console.error(e);
  }
}
