import { useExperimentsById } from "@capone/experiments";
import { Box, Popover, Typography } from "@material-ui/core";
import clsx from "clsx";
import dayjs from "dayjs";
import {
  ActionLink,
  ActionLinks,
  IActionLink,
  Icon,
  IconName,
  MyTripsCard,
  TripInfoDetails,
  getTwoLineAddresses,
  useDeviceTypes,
} from "halifax";
import React, { useMemo, useRef, useState } from "react";
import {
  ContractStateEnum,
  HotelCfarContract,
  HotelConfirmationCodeEnum,
  HotelItinerary,
  IOpenModal,
  ItemizedReceiptEventsProperties,
  ItineraryEnum,
  ItineraryWithType,
  LodgingAddressExact,
  LodgingCollectionEnum,
  MyTripsFilter,
  MyTripsModalTypes,
  Reservation,
  VIEWED_ITEMIZED_RECEIPT,
} from "redmond";
import "./styles.scss";

import { isCorpTenant, useMultiroomCountText } from "@capone/common";
import { config } from "../../../../../../../../api/config";
import { trackEvent } from "../../../../../../../../api/v1/analytics/trackEvent";
import {
  ActiveExperiments,
  useExperiment,
} from "../../../../../../../../context/experiments";
import {
  calculateHotelTotals,
  getCurrencyString,
} from "../../../../../../../../utils/helpers";
import {
  DESKTOP_OFFSET_SCROLL,
  HOW_REFUNDS_WORK_LINK,
  HOW_REFUNDS_WORK_TEXT,
  MOBILE_OFFSET_SCROLL,
} from "../../../../constants";
import { AddOn, AddOnEnum, AddOnsSummary } from "../../../AddOnsSummary";
import { ConfirmationSummary } from "../../../ConfirmationSummary";
import { getLabels, getValues } from "../../../FlightCard/helpers";
import { PaymentSummary } from "../../../PaymentSummary";
import { SummaryCard } from "../../../SummaryCard";
import { TravelersSummary } from "../../../TravelersSummary";
import * as textConstants from "../../constants";
import { HotelCancellation, HotelCardRoomTypeContent } from "../HotelInfoParts";
import { HotelCheckInInstructions } from "../HotelInfoParts/component";
import { HotelMap } from "../HotelMap";
import { DesktopHotelCardConnectorProps } from "./container";

interface IDesktopHotelCardProps extends DesktopHotelCardConnectorProps {
  expandedCard: string;
  hotel: HotelItinerary;
  isPastTrips?: boolean;
  banner?: JSX.Element;
  confirmationCodeClassName?: string;
  isCancel?: boolean;
  isMobile?: boolean;
  onExpandCard: (cardId: string) => void;
  setOpenModal: (modalType: IOpenModal) => void;
  travelerNames?: string[];
  tripsFilter: MyTripsFilter;
}

export const addHotelType = (hotel: HotelItinerary): ItineraryWithType => ({
  ...hotel,
  type: ItineraryEnum.Hotel,
});

export const getPostalCode = (reservation: Reservation): string => {
  const { lodgingData } = reservation;
  const city = lodgingData?.city ? `${lodgingData.city},` : "";
  const state = lodgingData?.state?.code?.trim()
    ? `${lodgingData.state.code},`
    : "";
  return `${city} ${state} ${lodgingData?.country || ""}`;
};

export const DesktopHotelCard = (props: IDesktopHotelCardProps) => {
  const {
    expandedCard,
    hotel,
    isMobile,
    onExpandCard,
    setOpenModal,
    banner,
    isCancel,
    isPastTrips = false,
    confirmationCodeClassName,
    travelerNames,
    tripsFilter,
  } = props;
  const { reservation, paymentAmountInfo, paymentBreakdown, ancillaries } =
    hotel;

  // note: destructuring can be expanded in the future as we have more ancillaries
  const { cfar } = ancillaries;

  const { calculatedTripTotal, refundableAmount } = calculateHotelTotals({
    reservation,
    paymentBreakdown,
    cfar,
  });
  const { matchesMobile } = useDeviceTypes();
  const itemizedReceiptExp = useExperiment(
    ActiveExperiments.SelfServeHotelItemizedReceipt
  );
  const isCorpHotelItemizedReceiptsEnabled =
    useExperimentsById("corp-traveler-hotel-itemized-receipt")?.variant ===
    "available";

  const refundPopoverRef = useRef<HTMLButtonElement>(null);
  const [refundPopoverOpen, setRefundPopoverOpen] = useState(false);

  const hotelAddress = reservation.lodgingData?.address as
    | LodgingAddressExact
    | undefined;

  // parse addres to only show the street, this could lead to potential bugs
  // because BE returns everything as a full address i.e: "15 Beacon St, Boston, MA, US"
  const hotelStreet = getTwoLineAddresses(hotelAddress).line1;

  const closeRefundPopover = () => setRefundPopoverOpen(false);

  const onOpenModal = (type: MyTripsModalTypes) =>
    setOpenModal({
      type,
      selectedItinerary: addHotelType(hotel),
    });

  const openRefundPopover = () => setRefundPopoverOpen(true);

  const showCancelButton = !isPastTrips;

  const hasCfarExpired = (cfar: HotelCfarContract | undefined): boolean => {
    return !!cfar && dayjs().isAfter(dayjs(cfar.expired));
  };

  const isCfarExercised = (cfar: HotelCfarContract | undefined): boolean => {
    return !!cfar && cfar.state?.ContractState === ContractStateEnum.Exercise;
  };

  const hasCfar = !!cfar;

  const { roomInfo, count: roomsCount } = hotel.reservation.bookedRooms[0];
  const tripCardSubtitle = isCorpTenant(config.TENANT) ? (
    <Box>
      <Typography className="info-container" variant="body1">
        {hotel.reservation.lodgingData?.name}
      </Typography>
      <Typography variant="caption" color="inherit">
        {roomInfo.name} {useMultiroomCountText(roomsCount)}
      </Typography>
    </Box>
  ) : (
    hotel.reservation.lodgingData?.name
  );

  // note: hasAddOn can be expanded in the future as we have more features added in
  const hasAddOn = hasCfar;

  const actions = useMemo(() => {
    const actionsArr: IActionLink[] = [];

    if (!isCancel && showCancelButton) {
      actionsArr.push({
        content: "Change Hotel Reservation",
        onClick: () => onOpenModal(MyTripsModalTypes.ChangeHotel),
      });

      if (hasCfar && !hasCfarExpired(cfar) && !isCfarExercised(cfar)) {
        actionsArr.push({
          linkClassName: "desktop-cfar-link",
          content: (
            <>
              <Icon className="cfar-icon" name={IconName.CheckShieldBlue} />
              {textConstants.CANCEL_FOR_ANY_REASON}
            </>
          ),
          onClick: () => onOpenModal(MyTripsModalTypes.CfarHotel),
          disabled: isCancel,
        });
      } else {
        actionsArr.push({
          content: "Cancel Hotel Reservation",
          onClick: () => onOpenModal(MyTripsModalTypes.CancelHotel),
        });
      }
    } else if (isCancel) {
      actionsArr.push({
        content: (
          <>
            {HOW_REFUNDS_WORK_LINK}
            <Icon className="info-icon" name={IconName.InfoCircle} />
          </>
        ),
        linkClassName: "how-refunds-work-link",
        linkRef: refundPopoverRef,
        onClick: openRefundPopover,
      });
    }

    actionsArr.push({
      content: "Resend Confirmation",
      onClick: () => onOpenModal(MyTripsModalTypes.ResendConfirmation),
    });

    if (
      !isCancel &&
      (itemizedReceiptExp || isCorpHotelItemizedReceiptsEnabled)
    ) {
      actionsArr.push({
        content: "View Itemized Receipt",
        onClick: () => {
          onOpenModal(MyTripsModalTypes.ItemizedHotelReceipt);
          trackEvent({
            eventName: VIEWED_ITEMIZED_RECEIPT,
            properties: {
              trip_id: hotel.reservation.reservationId,
              travel_type: "hotel",
              active_tab: tripsFilter,
            } satisfies ItemizedReceiptEventsProperties,
          });
        },
      });
    }

    return actionsArr;
  }, [
    isCancel,
    isCorpHotelItemizedReceiptsEnabled,
    itemizedReceiptExp,
    refundPopoverRef,
    openRefundPopover,
    showCancelButton,
  ]);

  return (
    <Box
      id={reservation.reservationId}
      key={reservation.reservationId}
      className={clsx("hotel-trip-container", {
        mobile: isMobile,
        expanded: expandedCard === reservation.reservationId,
        "premier-collection":
          reservation.lodgingCollection === LodgingCollectionEnum.Premier,
      })}
    >
      {reservation.lodgingCollection === LodgingCollectionEnum.Premier && (
        <Icon name={IconName.PremierCollectionRibbon} />
      )}
      {reservation.lodgingCollection === LodgingCollectionEnum.Lifestyle && (
        <Icon name={IconName.LifestyleCollectionRibbon} />
      )}
      <MyTripsCard
        className="trip-card-title"
        banner={banner}
        content={
          <TripInfoDetails
            hideTitleTag={isCancel}
            confirmationCodeClassName={confirmationCodeClassName}
            titles={{
              confirmationLabel: textConstants.CONFIRMATION,
              startLabel: `${textConstants.CHECK_IN}:`,
              endLabel: `${textConstants.CHECK_OUT}:`,
              postalCode: getPostalCode(reservation),
              phoneNum: reservation.lodgingData?.phone || "",
              streetAddress: hotelStreet,
              ...textConstants.getHotelInfoDetails(hotel),
              subtitle: hotel.reservation.lodgingData?.name
                ? tripCardSubtitle
                : "",
            }}
          />
        }
        actions={<ActionLinks actions={actions} />}
        expandString={
          expandedCard === reservation.reservationId
            ? textConstants.VIEW_LESS
            : textConstants.VIEW_DETAILS
        }
        expandIcon={
          expandedCard === reservation.reservationId
            ? IconName.MinusBlueCircle
            : IconName.PlusBlueCircle
        }
        isExpanded={expandedCard === reservation.reservationId}
        topRightAction={null}
        onExpandClick={() => {
          onExpandCard(reservation.reservationId);
          setTimeout(() => {
            const OFFSET = matchesMobile
              ? MOBILE_OFFSET_SCROLL
              : DESKTOP_OFFSET_SCROLL;
            const cardTop =
              document
                ?.getElementById(reservation.reservationId)
                ?.getBoundingClientRect().top || 0;
            window.scrollBy({
              top: (cardTop as number) - OFFSET,
              behavior: matchesMobile ? "auto" : "smooth",
            });
          }, 100);
        }}
        travelers={travelerNames}
      />
      {reservation.reservationId === expandedCard && (
        <Box className="hotel-desktop-expanded-container">
          <Box className="hotel-summary-container">
            <HotelMap lodging={hotel.reservation.lodgingData} />
            <HotelCardRoomTypeContent
              bookedRooms={hotel.reservation.bookedRooms}
              isLuxuryCollection={
                hotel.reservation.lodgingCollection ===
                LodgingCollectionEnum.Premier
              }
            />
            <HotelCancellation
              hotelCfar={hotel.ancillaries.cfar}
              cancellationPolicy={hotel.reservation.cancellationPolicy}
              refundAmount={refundableAmount}
            />
            {hotel.reservation.checkInDate &&
              hotel.reservation.checkOutDate && (
                <HotelCheckInInstructions
                  fromDate={hotel.reservation.checkInDate}
                  untilDate={hotel.reservation.checkOutDate}
                  checkInInstructions={
                    hotel.reservation.lodgingData.checkInInstructions
                  }
                />
              )}
          </Box>
          <Box
            className={clsx("summary-info-container", {
              "two-column-view": hasAddOn,
            })}
          >
            <SummaryCard
              className="travellers-summary"
              action={
                <ActionLink
                  className="details-link"
                  onClick={() => onOpenModal(MyTripsModalTypes.TravelersModal)}
                  content="Details"
                />
              }
            >
              <TravelersSummary
                label={"Guest Information"}
                travelers={reservation.guests
                  .map((person) => person?.givenName)
                  .join(", ")}
              />
            </SummaryCard>
            <SummaryCard
              className="payment-summary"
              action={
                <ActionLink
                  className="details-link"
                  onClick={() => onOpenModal(MyTripsModalTypes.PaymentModal)}
                  content="Details"
                />
              }
            >
              <PaymentSummary
                tripTotalAmount={getCurrencyString({
                  currencyCode: calculatedTripTotal.currencyCode,
                  currencySymbol: calculatedTripTotal.currencySymbol,
                  value: calculatedTripTotal.value,
                })}
                cardLabel={getLabels(paymentAmountInfo).cardLabel}
                cardAmount={getValues(paymentAmountInfo).cardValue}
                rewardsLabel={getLabels(paymentAmountInfo).rewardLabel}
                rewardsAmount={getValues(paymentAmountInfo).rewardValue}
                showCardLabel={Boolean(getLabels(paymentAmountInfo).cardLabel)}
                showRewardsLabel={Boolean(
                  getLabels(paymentAmountInfo).rewardLabel
                )}
              />
            </SummaryCard>
            <SummaryCard
              className="confirmation-summary"
              action={
                hotel.reservation.hotelConfirmationCode &&
                hotel.reservation.hotelConfirmationCode.HotelConfirmationCode ==
                  HotelConfirmationCodeEnum.HotelConfirmationConfirmed ? (
                  <ActionLink
                    className="details-link"
                    onClick={() =>
                      onOpenModal(MyTripsModalTypes.ConfirmationModal)
                    }
                    content={`View all (2)`}
                  />
                ) : null
              }
            >
              <ConfirmationSummary
                confirmationCode={`H-${reservation.reservationId}`}
              />
            </SummaryCard>
            {hasAddOn ? (
              <SummaryCard className="add-ons-summary">
                <AddOnsSummary
                  addOns={(() => {
                    const addOnItems: AddOn[] = [];
                    if (hasCfar) {
                      addOnItems.push({
                        expiryDate: cfar?.expired ?? "",
                        expiredTz: cfar?.expiredTz ?? "America/New_York", // TODO: Fall back to EST / EDT for now
                        onClick: () => onOpenModal(MyTripsModalTypes.CfarHotel),
                        isEnabled: !hasCfarExpired(cfar) && !isCancel,
                        type: AddOnEnum.Cfar,
                      });
                    }
                    return addOnItems;
                  })()}
                />
              </SummaryCard>
            ) : null}
          </Box>
        </Box>
      )}
      {isCancel && (
        <Popover
          anchorEl={refundPopoverRef.current}
          anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
          className="how-refunds-work-popover"
          onClose={closeRefundPopover}
          open={refundPopoverOpen}
        >
          <Typography className="subtitle">{HOW_REFUNDS_WORK_TEXT}</Typography>
        </Popover>
      )}
    </Box>
  );
};
