export const FETCH_HOTEL_PRICE_FREEZE_DETAILS =
  "hotelPriceFreeze/FETCH_HOTEL_PRICE_FREEZE_DETAILS";
export type FETCH_HOTEL_PRICE_FREEZE_DETAILS =
  typeof FETCH_HOTEL_PRICE_FREEZE_DETAILS;

export const SET_HOTEL_PRICE_FREEZE_DETAILS =
  "hotelPriceFreeze/SET_HOTEL_PRICE_FREEZE_DETAILS";
export type SET_HOTEL_PRICE_FREEZE_DETAILS =
  typeof SET_HOTEL_PRICE_FREEZE_DETAILS;

export const SET_FETCH_HOTEL_PRICE_FREEZE_DETAILS_CALL_STATE_FAILED =
  "hotelPriceFreeze/SET_FETCH_HOTEL_PRICE_FREEZE_DETAILS_CALL_STATE_FAILED";
export type SET_FETCH_HOTEL_PRICE_FREEZE_DETAILS_CALL_STATE_FAILED =
  typeof SET_FETCH_HOTEL_PRICE_FREEZE_DETAILS_CALL_STATE_FAILED;

export const REFUND_HOTEL_PRICE_FREEZE =
  "hotelPriceFreeze/REFUND_HOTEL_PRICE_FREEZE";
export type REFUND_HOTEL_PRICE_FREEZE = typeof REFUND_HOTEL_PRICE_FREEZE;

export const SET_REFUND_HOTEL_PRICE_FREEZE_RESULT =
  "hotelPriceFreeze/SET_REFUND_HOTEL_PRICE_FREEZE_RESULT";
export type SET_REFUND_HOTEL_PRICE_FREEZE_RESULT =
  typeof SET_REFUND_HOTEL_PRICE_FREEZE_RESULT;

export const SET_REFUND_HOTEL_PRICE_FREEZE_CALL_STATE_FAILED =
  "hotelPriceFreeze/SET_REFUND_HOTEL_PRICE_FREEZE_CALL_STATE_FAILED";
export type SET_REFUND_HOTEL_PRICE_FREEZE_CALL_STATE_FAILED =
  typeof SET_REFUND_HOTEL_PRICE_FREEZE_CALL_STATE_FAILED;

export const SET_REFUND_HOTEL_PRICE_FREEZE_CALL_STATE_NOT_CALLED =
  "hotelPriceFreeze/SET_REFUND_HOTEL_PRICE_FREEZE_CALL_STATE_NOT_CALLED";
export type SET_REFUND_HOTEL_PRICE_FREEZE_CALL_STATE_NOT_CALLED =
  typeof SET_REFUND_HOTEL_PRICE_FREEZE_CALL_STATE_NOT_CALLED;

export const SET_PRICE_FREEZE_EXPERIMENTS =
  "hotelPriceFreeze/SET_PRICE_FREEZE_EXPERIMENTS";
export type SET_PRICE_FREEZE_EXPERIMENTS = typeof SET_PRICE_FREEZE_EXPERIMENTS;
