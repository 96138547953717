import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../../../../../reducers/types";
import { MobilePackageFlightDetails } from "./component";
import {
  getAirlinesMap,
  getAirportMap,
  getSelectedPackage,
  getSelectedPackageFlight,
  getTripsFilter,
} from "../../../../reducer";
import {
  setOpenModal,
  setSelectedPackageFlight,
} from "../../../../actions/actions";
import { withRouter } from "react-router";
const mapStateToProps = (state: IStoreState) => ({
  packageFlight: getSelectedPackageFlight(state),
  tripsFilter: getTripsFilter(state),
  packageItinerary: getSelectedPackage(state),
  airportMap: getAirportMap(state),
  airlineMap: getAirlinesMap(state),
});

const mapDispatchToProps = {
  setOpenModal,
  setSelectedPackageFlight,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MobilePackageFlightDetailsConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedMobilePackageFlightDetails = connector(
  withRouter(MobilePackageFlightDetails)
);
