import {
  CancellationFareRulesResponse,
  CancellationFareRulesResponseEnum,
  CancellationFareRulesFailure,
  CancellationFareRulesSuccess,
} from "redmond";
import { put } from "redux-saga/effects";
import flightCfarCancellationInfoV3 from "../../../api/v1/itinerary/flightCfarCancellationInfoV3";
import { actions } from "../actions";

export function* fetchFlightCfarCancellationInfoV3Saga(
  action: actions.IFetchFlightCfarCancellationInfoV3
) {
  try {
    const response: CancellationFareRulesResponse =
      yield flightCfarCancellationInfoV3({
        itineraryId: action.itineraryId,
      });

    if (
      (response as CancellationFareRulesFailure)?.Response ===
      CancellationFareRulesResponseEnum.Failure
    ) {
      yield put(actions.setFetchFlightCfarCancellationInfoV3CallStateFailed());
      return;
    }

    yield put(
      actions.setFlightCfarCancellationInfoV3Response({
        response: response as CancellationFareRulesSuccess,
      })
    );
  } catch (e) {
    yield put(actions.setFetchFlightCfarCancellationInfoV3CallStateFailed());
  }
}
