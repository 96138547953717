import React from "react";
import clsx from "clsx";
import { Box, Typography } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCreditCard } from "@fortawesome/free-solid-svg-icons";
import "./styles.scss";
import { twoDecimalFormatter } from "halifax";

interface IPaymentSummary {
  className?: string;
  tripTotalLabel?: string;
  tripTotalAmount: string;
  rewardsLabel?: string | undefined | null;
  rewardsAmount?: string | undefined | null;
  cardLabel: string | undefined | null;
  showCardLabel?: boolean;
  showRewardsLabel?: boolean;
  cardAmount: string | undefined | null;
}

export const PaymentSummary = ({
  className,
  tripTotalLabel = "Trip Total",
  tripTotalAmount,
  rewardsLabel,
  rewardsAmount,
  cardLabel,
  cardAmount,
  showCardLabel = true,
  showRewardsLabel = true,
}: IPaymentSummary) => {
  return (
    <Box className={clsx("payment-summary-container", className)}>
      <Box
        className={clsx("pricing-row", {
          "row-border": showCardLabel || showRewardsLabel,
        })}
      >
        <Typography
          variant="subtitle2"
          className={clsx("trip-total-label", "bold")}
        >
          {tripTotalLabel}
        </Typography>
        <Typography
          variant="body2"
          className={clsx("trip-total-details", "bold")}
        >
          {twoDecimalFormatter(tripTotalAmount)}
        </Typography>
      </Box>
      {/* Start of rewards display below */}
      {showRewardsLabel && (
        <Box className={clsx("pricing-row", { "row-border": showCardLabel })}>
          <Typography variant="subtitle2" className="reward-details-label">
            {rewardsLabel}
          </Typography>
          <Typography variant="body2" className="reward-details-amount">
            {rewardsAmount}
          </Typography>
        </Box>
      )}
      {/* End of rewards section */}
      {showCardLabel && (
        <Box className={"pricing-row"}>
          <Box className="payment-card-row-icon">
            <FontAwesomeIcon icon={faCreditCard} className="credit-card-icon" />
            <Typography variant="subtitle2" className="payment-card-details">
              {cardLabel}
            </Typography>
          </Box>
          <Typography variant="body2" className="pricing-details">
            {twoDecimalFormatter(cardAmount || "")}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
