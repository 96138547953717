import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../../../../../../reducers/types";
import {
  updateWatch,
  setUpdateWatchCallState,
} from "../../../../../actions/actions";
import { getOpenModal, getUpdateWatchCallState } from "../../../../../reducer";
import { ChangeEmailModalContent } from "./component";

const mapStateToProps = (state: IStoreState) => ({
  openModal: getOpenModal(state),
  updateWatchCallState: getUpdateWatchCallState(state),
});

const mapDispatchToProps = {
  updateWatch,
  setUpdateWatchCallState,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type ChangeEmailModalContentConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedChangeEmailModalContent = connector(
  ChangeEmailModalContent
);
