import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { IStoreState } from "../../../../reducers/types";
import {
  populateTripQueryParams,
  setSelectedFlight,
} from "../../actions/actions";
import TravelCreditsList from "./TravelCreditsList";
import {
  getAirlinesMap,
  getAirportMap,
  getSelectedFlight,
  hasFetchTripsError,
  getTravelCredits,
  getTripId,
  getTripsFilter,
} from "../../reducer";

const mapStateToProps = (state: IStoreState) => ({
  airlineMap: getAirlinesMap(state),
  airportMap: getAirportMap(state),
  selectedFlight: getSelectedFlight(state),
  travelCredits: getTravelCredits(state),
  hasError: hasFetchTripsError(state),
  selectedTripId: getTripId(state),
  tripsFilter: getTripsFilter(state),
});

const mapDispatchToProps = {
  populateTripQueryParams,
  setSelectedFlight,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type TravelCreditsListConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedTravelCreditsList = connector(
  withRouter(TravelCreditsList)
);
