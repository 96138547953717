import { CallState } from "redmond";
import { HotelCfarCancelScenario } from "redmond";
import { put } from "redux-saga/effects";
import { getCfarHotelCancellationPolicy } from "../../../api/v1/itinerary/getCfarCancellationPolicy";

import { actions } from "../actions";
import { IFetchHotelCfarCancellationPolicy } from "../actions/actions";

export function* fetchHotelCfarCancellationPolicySaga(
  action: IFetchHotelCfarCancellationPolicy
) {
  try {
    const { request } = action;

    const response: HotelCfarCancelScenario =
      yield getCfarHotelCancellationPolicy(request);

    yield put(actions.setHotelCfarCancellationPolicyScenario(response));
  } catch (e) {
    yield put(
      actions.setHotelCfarCancellationPolicyCallState(CallState.Failed)
    );
  }
}
