import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { IStoreState } from "../../../../../../../../reducers/types";
import { setOpenModal } from "../../../../../../actions/actions";
import { CancelHotelModalContent } from "./component";

const mapStateToProps = (_: IStoreState) => ({});

const mapDispatchToProps = {
  setOpenModal,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type CancelHotelModalContentConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedCancelHotelModalContent = connector(
  withRouter(CancelHotelModalContent)
);
