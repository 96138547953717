import React from "react";
import {
  ActionLink,
  CloseButtonIcon,
  DesktopPopupModal,
  MobilePopoverCard,
} from "halifax";
import { CallState, MyTripsModalTypes } from "redmond";
import clsx from "clsx";

import { StopWatchingModalContent } from "./components/StopWatchingModalContent";
import { ChangeEmailModalContent } from "./components/ChangeEmailModalContent";

import { WatchItineraryModalConnectorProps } from "./container";
import "./styles.scss";

interface IWatchItineraryModalProps extends WatchItineraryModalConnectorProps {
  isMobile?: boolean;
}

export const WatchItineraryModal = ({
  isMobile,
  openModal,
  setOpenModal,
  setDeleteWatchCallState,
  setUpdateWatchCallState,
}: IWatchItineraryModalProps) => {
  const onClose = () => {
    setOpenModal({ selectedItinerary: null, type: null });
    resetActionState();
  };

  const resetActionState = () => {
    switch (openModal.type) {
      case MyTripsModalTypes.StopWatching:
        return setDeleteWatchCallState(CallState.NotCalled);
      case MyTripsModalTypes.ChangeEmail:
        return setUpdateWatchCallState(CallState.NotCalled);
      default:
        return;
    }
  };

  const getDesktopModalClassName = (): string => {
    const desktopModalClasses = {
      [MyTripsModalTypes.StopWatching]: "stop-watching-modal",
      [MyTripsModalTypes.ChangeEmail]: "change-email-modal",
    };
    return openModal && openModal.type
      ? desktopModalClasses[openModal.type]
      : "";
  };

  const renderModalContent = () => {
    switch (openModal.type) {
      case MyTripsModalTypes.StopWatching:
        return <StopWatchingModalContent {...{ onClose }} />;
      case MyTripsModalTypes.ChangeEmail:
        return <ChangeEmailModalContent {...{ onClose }} />;
      default:
        onClose();
        return null;
    }
  };

  const renderDesktopModal = () => (
    <DesktopPopupModal
      className={clsx("my-trips-desktop-modal", getDesktopModalClassName())}
      open={true}
      onClose={onClose}
      invisibleBackdrop={false}
    >
      {renderModalContent()}
    </DesktopPopupModal>
  );

  const renderMobileModal = () => (
    <MobilePopoverCard
      open={true}
      onClose={onClose}
      centered={true}
      contentClassName={"my-trips-mobile-popover-content"}
      topRightButton={
        <ActionLink
          className="filter-close-button"
          content={<CloseButtonIcon />}
          label="Close"
          onClick={onClose}
        />
      }
    >
      {renderModalContent()}
    </MobilePopoverCard>
  );

  return openModal.selectedItinerary ? (
    <>{isMobile ? renderMobileModal() : renderDesktopModal()}</>
  ) : null;
};
