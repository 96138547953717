import React from "react";
import { BusinessCenterOutlined, PersonOutline } from "@material-ui/icons";
import { isCorpTenant } from "@capone/common";

import "./styles.scss";
import { config } from "../../../../../api/config";

export const PersonalBusinessLabel = (props: IPersonalBusinessLabelProps) => {
  const { isCorporate, color } = props;
  if (!isCorpTenant(config.TENANT)) {
    return <></>;
  }
  return (
    <div className="personal-business-label-root" style={{ color }}>
      {isCorporate ? (
        <BusinessCenterOutlined fontSize="inherit" />
      ) : (
        <PersonOutline fontSize="inherit" />
      )}

      <div className="personal-business-label-text">
        {isCorporate ? "Business" : "Personal"}
      </div>
    </div>
  );
};

interface IPersonalBusinessLabelProps {
  isCorporate?: boolean;
  color?: string;
}
