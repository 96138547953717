import React from "react";
import { PendingGroundItinerary, ApprovalStatus } from "redmond";
import dayjs from "dayjs";
import { Box, Typography } from "@material-ui/core";
import {
  ApprovalStatusChip,
  TripInfoDetails,
  MyTripsCardTraveler,
} from "halifax";
import { PersonalBusinessLabel } from "../../PersonalBusinessLabel";
import { SHORT_DATE_FORMAT, LONG_DATE_FORMAT } from "./constants";

type Props = {
  pendingItinerary: PendingGroundItinerary;
};

export const CarCard = ({ pendingItinerary }: Props) => {
  const pickUpTime = pendingItinerary.pickUp.time;
  const dropOffTime = pendingItinerary.dropOff.time;

  const titles = {
    confirmationLabel: "",
    startLabel: "Pick-up",
    endLabel: "Drop-off",
    title: (pendingItinerary.vehicle.name || "") as string,
    subtitle: `${dayjs(dropOffTime).diff(pickUpTime, "days")} Days`,
    startDate: dayjs(pickUpTime).format(SHORT_DATE_FORMAT),
    endDate: dayjs(dropOffTime).format(SHORT_DATE_FORMAT),
    streetAddress: pendingItinerary.pickUp.address,
  };
  const approvalStatus = pendingItinerary.isExpired
    ? "unavailable"
    : (pendingItinerary.approvalInfo.status.toLowerCase() as ApprovalStatus);

  const travelers = `${pendingItinerary.driver.givenName} ${pendingItinerary.driver.surname}`;

  return (
    <article className="pending-itinerary pending-itinerary-car">
      <Box className="pending-itinerary-title-row">
        <Typography className="pending-itinerary-title">
          <strong>Car</strong> | Requested on{" "}
          {dayjs(pendingItinerary.approvalInfo.requestedAt).format(
            LONG_DATE_FORMAT
          )}
        </Typography>
        <PersonalBusinessLabel isCorporate />
      </Box>
      <div className="pending-itinerary-card">
        <MyTripsCardTraveler travelers={[travelers]} isTripRequest />
        <TripInfoDetails titles={titles} />
        <ApprovalStatusChip approvalStatus={approvalStatus} />
      </div>
    </article>
  );
};
