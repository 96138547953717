import {
  ConfirmFlightCfarCancelRequest,
  CancellationResponse,
  CfarEnum,
} from "redmond";
import { put, select } from "redux-saga/effects";
import { IStoreState } from "../../../reducers/types";
import {
  getSelectedFlightCfarItineraryId,
  getSelectedFlightCfarInfo,
} from "../reducer/selectors";
import confirmFlightCfarCancellation from "../../../api/v1/itinerary/confirmFlightCfarCancellation";
import { actions } from "../actions";

export function* confirmFlightCfarCancellationSaga(
  action: actions.IConfirmFlightCfarCancellation
) {
  try {
    const { useCash } = action;
    const state: IStoreState = yield select();
    const itineraryId = getSelectedFlightCfarItineraryId(state);
    const cancelScenario = getSelectedFlightCfarInfo(state);

    if (!itineraryId || !cancelScenario) {
      yield put(actions.setConfirmFlightCfarCancellationCallStateFailed());
      return;
    }

    if (
      cancelScenario.Cfar === CfarEnum.CfarCashOrCfarFtc ||
      cancelScenario.Cfar === CfarEnum.CfarCashOrCfarFtcWithPenalty
    ) {
      cancelScenario.useCash = useCash;
    }

    const request: ConfirmFlightCfarCancelRequest = {
      itineraryId,
      cancelScenario,
    };
    const response: CancellationResponse = yield confirmFlightCfarCancellation(
      request
    );

    yield put(
      actions.setConfirmFlightCfarCancellationResponse({
        response,
      })
    );
  } catch (e) {
    yield put(actions.setConfirmFlightCfarCancellationCallStateFailed());
  }
}
