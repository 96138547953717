import React, { ReactNode } from "react";
import {
  BookedFlightItineraryWithDepartureTime,
  FortWorthSeat,
  AncillaryKindEnum,
} from "redmond";
import {
  Icon,
  IconName,
  getTotalPriceText,
  twoDecimalFormatter,
  getCurrencySymbol,
} from "halifax";
import { Typography, Box } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCreditCard } from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";

import "./styles.scss";
import * as textConstants from "./constants";
import { ILineItem, formatPaymentBreakdown } from "../../../FlightCard/helpers";
import { IFlightPaymentModelContentConnectorProps } from "./container";

interface IFlightPaymentModalContentProps
  extends IFlightPaymentModelContentConnectorProps {
  flight: BookedFlightItineraryWithDepartureTime;
}

const formatFiatValue = (value: number) =>
  value.toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

interface ISeatPrice {
  value: number;
  currencySymbol: string;
}

export const FlightPaymentModalContent = ({
  flight,
}: IFlightPaymentModalContentProps) => {
  const { bookedItinerary } = flight;
  const { sellingPricing, paymentBreakdown } = bookedItinerary;
  const { pricingByPassenger, totalPricing } = sellingPricing;
  const seatPricingByPassenger: { [key in string]: ISeatPrice } = {};
  if (bookedItinerary.seats && bookedItinerary.seats.seats.length > 0) {
    const populateSeatPricingByPassengerId = (id: string) => {
      const fortWorthSeats = bookedItinerary?.seats?.seats.filter(
        (s: FortWorthSeat) => s.personId === id
      );
      if (fortWorthSeats !== undefined && fortWorthSeats.length > 0) {
        seatPricingByPassenger[id] = {
          value: fortWorthSeats.reduce(
            (acc: number, seat: FortWorthSeat) => acc + seat.price,
            0
          ),
          currencySymbol: getCurrencySymbol(fortWorthSeats[0].currency),
        };
      }
    };
    bookedItinerary.passengers.alone.forEach((p) => {
      populateSeatPricingByPassengerId(p.person.id);
    });
    bookedItinerary.passengers.withLapInfants.forEach((p) => {
      populateSeatPricingByPassengerId(p.adult.person.id);
    });
  }

  const lineItems = formatPaymentBreakdown(paymentBreakdown);

  const renderPricingRow = ({
    label,
    price,
    className,
  }: {
    label: ReactNode;
    price: string;
    className?: string;
  }) => {
    return (
      <Box className={clsx("pricing-row", className)}>
        <Typography className="label" variant="body2">
          {label}
        </Typography>
        <Typography className="price" variant="body2">
          {price}
        </Typography>
      </Box>
    );
  };

  const renderPaymentForTravelers = () => {
    return (
      <Box className="payment-travelers-container">
        {pricingByPassenger.map(
          ({
            additionalMargin,
            baseWithoutMargin,
            person,
            taxes,
            passengerFee,
            ancillaries,
          }) => {
            const cfar = ancillaries.find(
              (ancillary) => ancillary.kind === AncillaryKindEnum.Cfar
            );
            const disruptionProtection = ancillaries.find(
              (ancillary) =>
                ancillary.kind === AncillaryKindEnum.Delay ||
                ancillary.kind === AncillaryKindEnum.MissedConnection
            );
            return (
              <Box key={person.id} className="payment-details-container">
                <Typography className="payment-traveler-name" variant="h4">
                  {`${person?.givenName} ${person?.surname}`}
                </Typography>
                {renderPricingRow({
                  label: textConstants.BASE_FARE,
                  price: getTotalPriceText({
                    price: baseWithoutMargin.fiat,
                    priceFormatter: twoDecimalFormatter,
                  }),
                })}
                {renderPricingRow({
                  label: textConstants.TAXES_AND_FEES,
                  price: `${
                    baseWithoutMargin.fiat.currencySymbol
                  }${formatFiatValue(
                    (additionalMargin ? additionalMargin.fiat.value : 0) +
                      passengerFee.fiat.value +
                      taxes.fiat.value
                  )}`,
                })}
                {seatPricingByPassenger[person?.id] &&
                  renderPricingRow({
                    label: textConstants.SEAT_SELECTION,
                    price: `${
                      seatPricingByPassenger[person?.id]?.currencySymbol
                    }${seatPricingByPassenger[person?.id]?.value.toFixed(2)}`,
                  })}
                {!!disruptionProtection &&
                  renderPricingRow({
                    className: "disruption-protection",
                    label: (
                      <>
                        <Box className="icon-wrapper">
                          <Icon
                            className={clsx(
                              "add-on-icon",
                              "disruption-protection"
                            )}
                            name={IconName.DisruptionProtectionBlue}
                          />
                        </Box>
                        {textConstants.DISRUPTION_PROTECTION}
                      </>
                    ),
                    price: getTotalPriceText({
                      price: disruptionProtection.premium.fiat,
                      priceFormatter: twoDecimalFormatter,
                    }),
                  })}
                {!!cfar &&
                  renderPricingRow({
                    className: "cfar",
                    label: (
                      <>
                        <Box className="icon-wrapper">
                          <Icon
                            className={clsx("add-on-icon", "cfar")}
                            name={IconName.CheckShieldBlue}
                          />
                        </Box>
                        {textConstants.CANCEL_FOR_ANY_REASON}
                      </>
                    ),
                    price: getTotalPriceText({
                      price: cfar.premium.fiat,
                      priceFormatter: twoDecimalFormatter,
                    }),
                  })}
              </Box>
            );
          }
        )}
      </Box>
    );
  };

  // TODO: move this logic to a selector
  const getTotalPrice = () => {
    const { total, currency } = totalPricing;
    const currencyPrefix = `${currency} ${total.fiat.currencySymbol}`;
    return `${currencyPrefix}${formatFiatValue(total.fiat.value)}`;
  };

  const renderTripTotal = () => (
    <Box className="trip-total-row">
      <Typography variant="body1" className="trip-total-text">
        {textConstants.TRIP_TOTAL}
      </Typography>
      <Typography variant="body1" className="trip-total-text">
        {getTotalPrice()}
      </Typography>
    </Box>
  );

  const renderRewards = () => {
    const rewards = lineItems.rewards;
    if (!rewards) {
      return null;
    }
    return (
      <Box className="rewards-container">
        <Typography variant="body2">{`${rewards.label}`}</Typography>
        <Typography variant="body1" className="rewards-points">
          {rewards.amount}
        </Typography>
      </Box>
    );
  };

  const renderPayment = () => {
    const userCard = lineItems.userCard;
    if (!userCard) {
      return null;
    }
    return (
      <Box className="payment-card-container">
        <Box className="payment-card-details-container">
          <FontAwesomeIcon icon={faCreditCard} className="credit-card-icon" />
          <Typography variant="body2" className="payment-card-details">
            {userCard.label}
          </Typography>
        </Box>
        <Typography variant="body1" className="payment-card-amount">
          {userCard.amount}
        </Typography>
      </Box>
    );
  };

  const renderTravelWallet = (
    travelWallet: ILineItem | null,
    icon: IconName
  ) => {
    if (!travelWallet) return null;
    return (
      <Box className="offers-container">
        <Box className="offers-icon-label">
          <Icon name={icon} />
          <Typography variant="body2">{travelWallet.label}</Typography>
        </Box>
        <Typography variant="body1" className="offer-amount">
          {travelWallet.amount}
        </Typography>
      </Box>
    );
  };

  const renderOffers = () =>
    renderTravelWallet(lineItems.travelWalletOffer, IconName.OfferTag);

  const renderCredits = () =>
    lineItems.travelWalletCredit?.map((credit) =>
      renderTravelWallet(credit, IconName.PiggyBank)
    );

  return (
    <Box className="flight-payment-modal-content">
      <Box className="flight-payment-title-container">
        <Typography variant="h4">{textConstants.TRIP_TOTAL}</Typography>
      </Box>
      {renderPaymentForTravelers()}
      {renderTripTotal()}
      {renderOffers()}
      {renderCredits()}
      {renderRewards()}
      {renderPayment()}
    </Box>
  );
};
