import axios from "axios";
import { GetHotelDetailRequest, GetHotelDetailResponse } from "redmond";
import { config } from "../../config";
import { fetchHotelPriceFreezeDetail } from "../../v1/paths";

export const fetchHotelPriceFreezeDetails = (
  request: GetHotelDetailRequest
): Promise<GetHotelDetailResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(fetchHotelPriceFreezeDetail, request, {
        baseURL: config.baseURL,
      });

      resolve(response.data);
    } catch (e) {
      reject(e);
    }
  });
