import { CallState, PriceFreezeView, TripSummaryContext } from "redmond";
import { put } from "redux-saga/effects";

import { listPriceFreeze } from "../../../api/v1/priceFreeze/listPriceFreeze";
import { actions } from "../actions";

export interface IPriceFreezeResponse {
  priceFreezes: Array<PriceFreezeView>;
  tripContext: TripSummaryContext;
}

export function* fetchPriceFreezeSaga(action: actions.IListPriceFreeze) {
  try {
    const value: IPriceFreezeResponse = yield listPriceFreeze({
      ListPriceFreezeRequest: action.requestType,
    });
    yield put(actions.setPriceFreeze(value));
  } catch (e) {
    yield put(actions.setListPriceFreezeCallState(CallState.Failed));
  }
}
