import React, { useContext, useEffect } from "react";
import { RouteComponentProps } from "react-router";
import { RefundHotelPriceFreezeModal, useDeviceTypes } from "halifax";
import {
  CallState,
  ModalAlertProperties,
  HOTEL_PRICE_FREEZE_REFUND_OUTCOME,
  HOTEL_PRICE_FREEZE_REFUND_CHOICE,
  MODAL_ALERT,
} from "redmond";
import { PATH_FROZEN_PRICES_LIST } from "../../../../utils/paths";
import { ClientContext } from "../../../../App";
import { trackEvent } from "../../../../api/v1/analytics/trackEvent";
import { RefundPriceFreezeModalConnectorProps } from "./container";

export interface IRefundPriceFreezeModalProps
  extends RefundPriceFreezeModalConnectorProps,
    RouteComponentProps {}

export const RefundPriceFreezeModal = (props: IRefundPriceFreezeModalProps) => {
  const {
    history,
    voucher,
    hasSuccessfullyRefunded,
    refundHotelPriceFreezeCallState,
    hotelPriceFreezeRefundOutcomeProperties,
    hotelPriceFreezeRefundChoiceProperties,
    refundHotelPriceFreeze,
    setRefundHotelPriceFreezeCallStateNotCalled,
  } = props;
  const { matchesMobile } = useDeviceTypes();
  const clientContext = useContext(ClientContext);
  const { isAgentPortal } = clientContext;

  useEffect(() => {
    switch (refundHotelPriceFreezeCallState) {
      case CallState.Success:
      case CallState.Failed: {
        trackEvent({
          eventName: HOTEL_PRICE_FREEZE_REFUND_OUTCOME,
          properties: hotelPriceFreezeRefundOutcomeProperties,
        });
        break;
      }
      default: {
        break;
      }
    }
  }, [refundHotelPriceFreezeCallState]);

  return (
    <RefundHotelPriceFreezeModal
      voucher={voucher}
      hasSuccessfullyRefunded={hasSuccessfullyRefunded}
      refundHotelPriceFreezeCallState={refundHotelPriceFreezeCallState}
      isAgentPortal={isAgentPortal}
      isMobile={matchesMobile}
      refundHotelPriceFreeze={refundHotelPriceFreeze}
      trackModalAlertEvent={(props: ModalAlertProperties) => {
        trackEvent({
          eventName: MODAL_ALERT,
          properties: props,
        });
      }}
      onFinish={() => history.push(PATH_FROZEN_PRICES_LIST)}
      onClose={() => setRefundHotelPriceFreezeCallStateNotCalled()}
      onRefund={() =>
        trackEvent({
          eventName: HOTEL_PRICE_FREEZE_REFUND_CHOICE,
          properties: hotelPriceFreezeRefundChoiceProperties,
        })
      }
      includeRefundStep
    />
  );
};
