import dayjs from "dayjs";
import { Reservation } from "redmond";

export const CAP_ONE_LOGO_ALT = "Capital One Travel";
export const NIGHTS = "Nights";
export const NIGHT = "Night";

export const getHotelStayDuration = (reservation: Reservation) => {
  const nights = dayjs(reservation.checkOutDate).diff(
    reservation.checkInDate,
    "day"
  );

  return `${nights} ${nights > 1 ? NIGHTS : NIGHT}`;
};
