import React, { useContext, useState } from "react";
import {
  ButtonWrap,
  DesktopPopupModal,
  TripsHeader,
  useDeviceTypes,
} from "halifax";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import { History } from "history";

import { ClientContext } from "../../../App";
import * as constants from "./constants";
import "./styles.scss";
import { BASE_PATH_HOME, CONTACT_SUPPORT_URL } from "../../../utils/paths";

export interface IContactSupportHeaderProps {
  history: History;
  title: string;
  subtitle: string;
  className?: string;
  isMobile?: boolean;
  isPositionFixed?: boolean;
}

export const ContactSupportHeader = ({
  history,
  title,
  subtitle,
  className,
  isMobile,
  isPositionFixed,
}: IContactSupportHeaderProps) => {
  const clientContext = useContext(ClientContext);
  const [openContactModal, setOpenContactModal] = useState(false);
  const { matchesDesktop } = useDeviceTypes();

  const ClientLogo = () => (
    <Box className="client-icon-wrapper">
      <ButtonWrap onClick={() => history.push(BASE_PATH_HOME)}>
        {clientContext.logo}
      </ButtonWrap>
    </Box>
  );

  const TripsHeaderContent = (
    <>
      <Typography variant="h2" className="content-header">
        {constants.CONTACT_SUPPORT_HEADER}
      </Typography>
      <Typography variant="h4" className="content-text">
        {constants.CONTACT_SUPPORT_TEXT}
      </Typography>
      <Typography variant="h2" className="content-phone">
        {constants.CONTACT_SUPPORT_NUMBER}
      </Typography>
    </>
  );

  return (
    <>
      <TripsHeader
        icon={<ClientLogo />}
        tripHeaderCopy={{
          title,
          subtitle,
          faqTitle: constants.FAQ_TITLE,
          faqCTA: constants.FAQ_CTA,
          supportCTA: constants.SUPPORT_CTA,
        }}
        className={clsx("contact-support-header-root", className, {
          "position-fixed": isPositionFixed,
        })}
        isMobile={isMobile}
        onFAQClick={() => {
          window.open(CONTACT_SUPPORT_URL, "_blank")?.focus();
        }}
        onSupportClick={() => {
          setOpenContactModal(true);
        }}
      />
      {matchesDesktop ? (
        <DesktopPopupModal
          open={openContactModal}
          className="desktop-contact-support-popup"
          onClose={() => setOpenContactModal(false)}
          invisibleBackdrop={false}
        >
          <Box className="desktop-contact-support-popup-content">
            {TripsHeaderContent}
          </Box>
        </DesktopPopupModal>
      ) : null}
    </>
  );
};
