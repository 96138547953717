import { getPortalName } from "@capone/common";

export const CAP_ONE_LOGO_ALT = "Capital One Travel";
export const PRINT = "Print";
export const TITLE = "Itemized hotel receipt";
export const HOTEL_NAME = "Hotel Name";
export const GUEST_NAME = "Guest Name";
export const CONFIRMATION_NUMBER = "Confirmation #";
export const INVOICE_DATE = "Invoice date";
export const CHECK_IN = "Check-in";
export const CHECKOUT = "Checkout";
export const ACCOMMODATION = "Accommodation";
export const ACCOMMODATION_TOTAL = "Accommodation Total*";
export const YOUR_STAY = "Your Stay";
export const ROOM = "Room";
export const ROOMS = "Rooms";
export const TOTAL_COST = "Total Cost";
export const TOTAL_COST_PER_NIGHT = "Total Cost Divided Per Night";
export const MMDDYYYY_FORMAT = "MM/DD/YYYY";
export const TAXES_AND_FEES = "Taxes and Fees";
export const TAXES_AND_FEES_TOTAL = "Taxes and Fees Total";
export const FEES = "Additional Fees at Check-in";
export const CANCEL_FOR_ANY_REASON = "Cancel For Any Reason";
export const PRICE_FREEZE_DEPOSIT_CREDIT = "Discount";
export const PAYMENT_INFO = "Payment Info";
export const DEFAULT_DOC_ID = "itemized-receipt";
export const COPY1 = `*This total reflects your purchase through ${getPortalName()}. Your hotel may provide an itemized receipt for any charges arranged directly with them.`;
export const PRINT_ON_DESKTOP =
  "Please visit the desktop version of the Capital One Travel Portal";
export const TRAVEL_CREDIT_APPLIED = "Capital One Travel Credit Applied";
export const TRAVEL_OFFER_APPLIED = "Capital One Travel Offer Applied";
export const TRAVEL_CREDITS_LINE_ITEM_APPLIED = "Travel credits applied";
export const TRAVEL_OFFER_LINE_ITEM_APPLIED = "Travel offer applied";
