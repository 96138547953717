import { Airline, FlightItinerarySegment } from "redmond";
import { IConfirmationNumberWithRedirect } from "./component";

// TODO: Remove capital one constants
export const CAPITAL_ONE_LABEL = "Capital One Travel";
export const CAPITAL_ONE_HOTEL_LABEL = "Capital One Travel Hotel Confirmation";
export const CAPITAL_ONE_FLIGHT_LABEL =
  "Capital One Travel Flight Confirmation";
export const HOTEL_CONFIRMATION_LABEL = "Hotel Confirmation";
export const CAPITAL_ONE_WEBSITE = "https://www.capitalonetravel.com/";
export const RETURN_TEXT = "Return";
export const CONFIRMATION_TITLE = "Confirmation numbers";
export const MODAL_SUBTITLE = `To manage your trip from your airlines website, click the link next to the flight to be redirected.`;
export const OUTBOUND_TEXT = "Outbound";

interface IGetConfirmationNumberProps {
  segment: FlightItinerarySegment;
  airlineMap: {
    [key: string]: Airline;
  };
  isReturn?: boolean;
}
export const getConfirmationNumber = ({
  segment,
  airlineMap,
  isReturn,
}: IGetConfirmationNumberProps): IConfirmationNumberWithRedirect | false => {
  const selectedAirline = airlineMap[segment.marketingAirline.code];
  if (!selectedAirline) return false;
  return {
    label: `${selectedAirline.displayName} ${
      isReturn ? RETURN_TEXT : OUTBOUND_TEXT
    }: ${segment.origin.locationCode} to ${segment.destination.locationCode}`,
    locator: segment.marketingAirline.locator || "",
    redirectUrl: selectedAirline.webLinks.manageBooking || "",
  };
};
export const COPY_AND_GO = "Copy & Go";
