import {
  MyTripsFilter,
  HotelItinerary,
  HotelItineraryState,
  HotelItineraryResponse,
} from "redmond";
import { call, put, select } from "redux-saga/effects";

import { fetchHotels } from "../../../api/v1/itinerary/fetchHotels";
import { actions } from "../actions";
import { getHotels } from "../reducer";
import { setUpMyTripsParams } from "./setUpTripsParams";

export function* fetchHotelsSaga(action: actions.IFetchHotels) {
  try {
    const { persist } = action;
    const { tripId } = yield call(setUpMyTripsParams, action);
    const hotelItinerariesResponse: HotelItineraryResponse = yield fetchHotels(
      action.request
    );
    const hotelItineraries: { [key: string]: HotelItinerary[] } = yield select(
      getHotels
    );
    const {
      future: newFuture,
      canceled: newCanceled,
      past: newPast,
      present: newPresent,
    } = hotelItinerariesResponse.itineraries;
    // update to use the state
    const future = getNewHotelItineraryArray(
      newFuture,
      [...hotelItineraries.future],
      !!persist?.find((state) => state === HotelItineraryState.Future)
    );
    const canceled = getNewHotelItineraryArray(
      newCanceled,
      [...hotelItineraries.canceled],
      !!persist?.find((state) => state === HotelItineraryState.Canceled)
    );
    const past = getNewHotelItineraryArray(
      newPast,
      [...hotelItineraries.past],
      !!persist?.find((state) => state === HotelItineraryState.Past)
    );
    const present = getNewHotelItineraryArray(
      newPresent,
      [...hotelItineraries.present],
      !!persist?.find((state) => state === HotelItineraryState.Present)
    );

    let selectedHotel = null;
    let isSelectedHotelInPastTrips = false;
    selectedHotel = [...future, ...present].find(
      (hotel: HotelItinerary) => hotel.reservation.reservationId === tripId
    );
    if (!selectedHotel) {
      const pastSelectedHotel = [...canceled, ...past].find(
        (hotel: HotelItinerary) => hotel.reservation.reservationId === tripId
      );
      selectedHotel = pastSelectedHotel;
      isSelectedHotelInPastTrips = !!pastSelectedHotel;
    }

    if (isSelectedHotelInPastTrips) {
      yield put(actions.setTripsFilter(MyTripsFilter.PAST_TRIPS));
      yield put(actions.populateTripQueryParams(action.history, { tripId }));
    }
    if (selectedHotel) {
      yield put(
        actions.setSelectedHotel({
          ...selectedHotel,
        })
      );
    }
    yield put(
      actions.setHotels(
        {
          ...hotelItineraries,
          present,
          canceled: [
            ...canceled,
            //include cancellation failures because these appear as successful
            //cancellations to the user (failure goes to queue for CS agents to act)
            ...future.filter(
              (h: HotelItinerary) =>
                h.reservation.state.ReservationState ===
                HotelItineraryState.CancellationFailure
            ),
          ],
          past,
          future: future.filter((h: HotelItinerary) => {
            return (
              h.reservation.state.ReservationState !==
              HotelItineraryState.CancellationFailure
            );
          }),
        },
        action.request.states
      )
    );
  } catch (e) {
    yield put(actions.fetchHotelsFailed(action.request));
    console.error(e);
  }
}

// note: array1 has higher priority over array2
const getNewHotelItineraryArray = (
  array1: HotelItinerary[] | undefined,
  array2: HotelItinerary[],
  persist: boolean
) =>
  persist ? mergeHotelItineraryArrays(array1 ?? [], array2) : array1 ?? array2;

const mergeHotelItineraryArrays = (
  array1: HotelItinerary[],
  array2: HotelItinerary[]
) => {
  const hotelItinerarySet = new Set();
  return array2
    .map((hotelItinerary) => {
      hotelItinerarySet.add(hotelItinerary.reservation.reservationId);
      return hotelItinerary;
    })
    .concat(
      array1.filter(
        (hotelItinerary) =>
          !hotelItinerarySet.has(hotelItinerary.reservation.reservationId)
      )
    );
};
