export const STOP_WATCHING_TITLE = "Stop watching this trip";
export const STOP_WATCHING_SUBTITLE =
  "Are you sure you want to stop watching this trip?";
export const STOP_WATCHING_SUCCESS = "You are no longer watching this trip!";
export const STOP_WATCHING_FAILURE_TITLE = "Something went wrong";
export const STOP_WATCHING_FAILURE_SUBTITLE = "Please try again";
export const STOP_WATCHING_CTA = "Yes, stop watching this trip";
export const RETRY_CTA = "Retry";
export const DONE_CTA = "Done";
export const CANCEL_CTA = "Cancel";
