import { VehicleKindEnum } from "redmond";

export const getCarSizeText = (kind: VehicleKindEnum) => {
  switch (kind) {
    case VehicleKindEnum.Truck:
      return "Truck";
    case VehicleKindEnum.Small:
      return "Small Car";
    case VehicleKindEnum.Mystery:
      return "Determined at pick-up";
    case VehicleKindEnum.SUV:
      return "SUV";
    case VehicleKindEnum.Mini:
      return "Mini Car";
    case VehicleKindEnum.Intermediate:
      return "Intermediate Car";
    case VehicleKindEnum.Van:
      return "Van";
    case VehicleKindEnum.Luxury:
      return "Luxury Car";
    case VehicleKindEnum.FullSize:
      return "Full-Size Car";
    default:
      return "";
  }
};
