import React from "react";
import clsx from "clsx";
import Box from "@material-ui/core/Box";

import "./styles.scss";

interface ISummaryCardProps {
  children: React.ReactNode;
  action?: React.ReactNode;
  className?: string;
}

export const SummaryCard = ({
  children,
  action,
  className,
}: ISummaryCardProps) => (
  <Box className={clsx("summary-card", className)}>
    {children}
    {action && <Box className="summary-card-action">{action}</Box>}
  </Box>
);
