import axios from "axios";
import { IPerson } from "redmond";

import { fetchUserPassengersPath } from "../paths";

const fetchUserPassengers = async () => {
  const res = await axios.put(fetchUserPassengersPath)
  const { Response, error, value } = res.data;

  if (Response === "Success") {
    return value as IPerson[];
  }

  throw new Error(`Failed to fetch user passengers - ${error}`);
};

export default fetchUserPassengers;
