import React, { useMemo } from "react";
import { Box, Typography } from "@material-ui/core";
import { B2BSpinner, LoadingText, useDeviceTypes } from "halifax";
import clsx from "clsx";

import "./styles.scss";
import * as constants from "./constants";

interface ILoadingScreenProps {
  type: LoadingType;
}

export enum LoadingType {
  FetchingPolicy,
  CancellationInProgress,
}

export const LoadingScreen = (props: ILoadingScreenProps) => {
  const { type } = props;
  const { matchesMobile } = useDeviceTypes();

  const content: { primary: string; secondary?: string } | null =
    useMemo(() => {
      switch (type) {
        case LoadingType.FetchingPolicy:
          return {
            primary: constants.LOADING_DETAILS_AND_POLICY,
          };
        case LoadingType.CancellationInProgress:
          return {
            primary: constants.CANCELLATION_IN_PROGRESS,
            secondary: constants.DO_NOT_EXIT,
          };
        default:
          return null;
      }
    }, [type]);

  if (!content) {
    return null;
  }

  return (
    <Box
      className={clsx("hotel-cfar-model-content-loading-screen-root", {
        mobile: matchesMobile,
      })}
    >
      <Box className="hotel-cfar-model-content-loading-screen-container">
        <Box className="content-wrapper">
          <B2BSpinner
            className="cfar-loading-spinner"
            style={{ width: "50px", height: "50px" }}
          />
          <LoadingText
            className={clsx("cfar-loading-text", {
              primary: type === LoadingType.CancellationInProgress,
            })}
            message={content.primary}
            initialFocusOnMessage
          />
          {content.secondary && (
            <Typography className="cfar-loading-secondary-text">
              {content.secondary}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};
