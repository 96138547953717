import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../../../../../../../reducers/types";
import { getAirlinesMap } from "../../../../../../reducer";
import { CheckInFlightModalContent } from "./component";

const mapStateToProps = (state: IStoreState) => ({
  airlineMap: getAirlinesMap(state),
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type CheckInFlightModalContentConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedCheckInFlightModalContent = connector(
  CheckInFlightModalContent
);
