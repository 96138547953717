import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import advancedFormat from "dayjs/plugin/advancedFormat";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

export const ADD_ONS_TITLE = "Add-ons";
export const CFAR_TITLE = "Cancel for any reason";
export const CFAR_BUTTON_ARIA_TEXT = "Open change-for-any-reason modal";
export const DISRUPTION_PROTECTION_TITLE = "Flight disruption assistance";

export const DISRUPTION_PROTECTION_BUTTON_ARIA_TEXT =
  "Open flight disruption assistance modal";

export const EXPIRY_DATE_COPY = (date: string, tz: string): string =>
  `Expires on ${dayjs(date).tz(tz).format("MMM DD, h:mm A z")}`;
