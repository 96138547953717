import axios from "axios";
import {
  NETWORK_CALL_FAILED,
  CancellationFareRulesRequest,
  CancellationFareRulesResponse,
  CancelScenarioEnum,
  CfarEnum,
} from "redmond";

import { config } from "../../config";
import { trackEvent } from "../analytics/trackEvent";
import { getFlightCfarCancellationInfoPathV3 } from "../paths";

/**
 * @version v3
 * @description The first of two endpoints in the self serve cancel flight flow
 * @param {CancellationFareRulesRequest} req Contains the itinerary id
 * @return {CancellationFareRulesResponse} The cancellation info for the itinerary passed in
 */
const flightCfarCancellationInfoV3 = (
  req: CancellationFareRulesRequest
): Promise<CancellationFareRulesResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await axios.post(getFlightCfarCancellationInfoPathV3, req, {
        baseURL: config.baseURL,
      });
      const { value } = res.data;

      if (value?.CancelScenario === CancelScenarioEnum.CancellationPending) {
        res.data = {
          value: {
            /*
              note: CancellationPending isn't a case that's defined in Tysons; it's converted from a CancellationPending scenario
              that is independent of whether it's Cfar or NonCfar. It's added so that the FE can easily extend the current switch statement.
            */
            Cfar: CfarEnum.CancellationPending,
          },
        };
      }

      resolve(res.data);
    } catch (e) {
      trackEvent({
        eventName: NETWORK_CALL_FAILED,
        properties: {
          url: window.location.pathname,
        },
      });
      reject(e);
    }
  });

export default flightCfarCancellationInfoV3;
