import { CallState, ListSummariesResult, ResultEnum } from "redmond";
import { put } from "redux-saga/effects";
import { listHotelPriceFreezeVoucherSummaries } from "../../../api/v1/hotelPriceFreeze/listHotelPriceFreezeVoucherSummaries";

import { actions } from "../actions";

export function* fetchHotelPriceFreezeSaga(
  action: actions.IListHotelPriceFreeze
) {
  try {
    const listSummariesResult: ListSummariesResult =
      yield listHotelPriceFreezeVoucherSummaries({
        ListSummariesRequest: action.requestType,
      });

    if (listSummariesResult.Result === ResultEnum.Ok) {
      yield put(
        actions.setHotelPriceFreezes(listSummariesResult.value.summaries)
      );
    } else {
      yield put(actions.setListHotelPriceFreezeCallState(CallState.Failed));
    }
  } catch (e) {
    yield put(actions.setListHotelPriceFreezeCallState(CallState.Failed));
  }
}
