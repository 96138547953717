import { GENERAL_SUPPORT_PHONE_NUMBER } from "redmond";

export const COULD_NOT_LOAD_CANCELLATION_TITLE =
  "Unable to load cancellation policy";
export const COULD_NOT_LOAD_CANCELLATION_SUBTITLE = `Unfortunately, this booking cannot be canceled via self-service. To cancel, please contact support at ${GENERAL_SUPPORT_PHONE_NUMBER.boldText} for further assistance.`;

export const CANCELLATION_FAILED_TITLE =
  "We were unable to process your cancellation. Please call us for assistance.";
export const CANCELLATION_FAILED_SUBTITLE = `Please contact support at ${GENERAL_SUPPORT_PHONE_NUMBER.boldText} for further assistance.`;

export const COULD_NOT_SELF_SERVE_CANCEL_TITLE =
  "We were unable to process your cancellation. Please call us for assistance.";
export const COULD_NOT_SELF_SERVE_CANCEL_SUBTITLE = `Please contact support at ${GENERAL_SUPPORT_PHONE_NUMBER.boldText} for further assistance.`;
