import { Box, Typography } from "@material-ui/core";
import { ActionLink, AirlineIcon, useDeviceTypes } from "halifax";
import React from "react";
import "./styles.scss";
import { getStopText } from "../../../utils/helpers";
import {
  FLIGHT_SLICE_AIRLINE_FARE_SHELF_SEPARATOR,
  FLIGHT_SLICE_CARD_HEADER,
  FLIGHT_SLICE_CARD_LABEL,
} from "./constants";
import { SliceDirection } from "redmond";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

export interface IFlightSliceCardProps {
  sliceDirection: SliceDirection;
  destination: string;
  date: string;
  timeInterval: string;
  duration: string;
  stopCount: number;
  marketingAirlines: {
    airlineCode: string;
    airlineName: string;
  }[];
  fareShelfBrandName: string;
  button?: {
    desktopText: string;
    onClick?: () => void;
  };
}

export const FlightSliceCard = (props: IFlightSliceCardProps) => {
  const {
    sliceDirection,
    destination,
    date,
    timeInterval,
    duration,
    stopCount,
    marketingAirlines,
    fareShelfBrandName,
    button,
  } = props;

  const { matchesMobile } = useDeviceTypes();

  // TODO: Finalize the display string for airlines when there are multiple marketing carriers.
  const singleAirlineDisplay =
    marketingAirlines.length > 0 ? (
      <Box className="flight-slice-card-single-airline-display">
        <Box className="flight-slice-card-airline-icon">
          <AirlineIcon
            airlineCode={marketingAirlines[0]?.airlineCode}
            size="1x"
          />
        </Box>
        <Box className="flight-slice-card-airline-name">
          {marketingAirlines[0]?.airlineName}
        </Box>
      </Box>
    ) : null;

  const airlineDisplay = (
    <Box className="flight-slice-card-airline-display">
      {singleAirlineDisplay}
      {singleAirlineDisplay && (
        <Box className="flight-slice-card-airline-fare-shelf-separator">
          {FLIGHT_SLICE_AIRLINE_FARE_SHELF_SEPARATOR}
        </Box>
      )}
      <Box className="flight-slice-card-fare-shelf">{fareShelfBrandName}</Box>
    </Box>
  );

  const buttonContent =
    button && !matchesMobile ? (
      <Typography className="flight-slice-card-button-text">
        {button.desktopText}
      </Typography>
    ) : (
      <FontAwesomeIcon className="mobile-right-chevron" icon={faChevronRight} />
    );

  return (
    <Box className={clsx("flight-slice-card", { mobile: matchesMobile })}>
      <Box
        className="flight-slice-card-title"
        dangerouslySetInnerHTML={{
          __html: FLIGHT_SLICE_CARD_HEADER(sliceDirection, destination, date),
        }}
      ></Box>
      <Box className="flight-slice-card-label">{FLIGHT_SLICE_CARD_LABEL}</Box>
      <Box className="flight-slice-card-time-interval">{timeInterval}</Box>
      {airlineDisplay}
      <Box className="flight-slice-card-duration">{duration}</Box>

      <Box className="flight-slice-card-stop-count">
        {getStopText(stopCount)}
      </Box>
      {button && (
        <ActionLink
          className="flight-slice-card-button"
          content={buttonContent}
          onClick={button.onClick}
        />
      )}
    </Box>
  );
};
