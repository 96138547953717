import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { HotelPriceFreezeOverview } from "./component";
import { fetchHotelPriceFreezeDetails } from "../../actions/actions";
import { IStoreState } from "../../../../reducers/types";
import {
  hotelPriceFreezeTotalCurrentPricingSelector,
  hotelPriceFreezeDetailsSelector,
  hotelPriceFreezeTotalFrozenPricingSelector,
  hotelPriceFreezeLodgingSelector,
  hotelPriceFreezeVoucherActionSelector,
  hotelPriceFreezeVoucherReservationSelector,
  hotelPriceFreezeVoucherSelector,
  hotelPriceFreezeRoomInfoSelector,
  fetchHotelPriceFreezeDetailsCallStateSelector,
  hotelPriceFreezeLodgingErrorSelector,
  hotelPriceFreezeRoomWithPricingErrorSelector,
  isHotelPriceFreezeLodgingNotAvailableSelector,
  hotelPriceFreezeNightlyFrozenPricingSelector,
} from "../../reducer/selectors";

const mapStateToProps = (state: IStoreState) => {
  return {
    hotelPriceFreezeDetails: hotelPriceFreezeDetailsSelector(state),
    hotelLodging: hotelPriceFreezeLodgingSelector(state),
    hotelVoucher: hotelPriceFreezeVoucherSelector(state),
    hotelNightlyFrozenPricing:
      hotelPriceFreezeNightlyFrozenPricingSelector(state),
    hotelTotalFrozenPricing: hotelPriceFreezeTotalFrozenPricingSelector(state),
    hotelAction: hotelPriceFreezeVoucherActionSelector(state),
    hotelReservation: hotelPriceFreezeVoucherReservationSelector(state),
    hotelTotalCurrentPricing:
      hotelPriceFreezeTotalCurrentPricingSelector(state),
    hotelRoomInfo: hotelPriceFreezeRoomInfoSelector(state),
    fetchHotelPriceFreezeDetailsCallState:
      fetchHotelPriceFreezeDetailsCallStateSelector(state),
    hotelLodgingError: hotelPriceFreezeLodgingErrorSelector(state),
    hotelRoomPricingError: hotelPriceFreezeRoomWithPricingErrorSelector(state),
    isHotelPriceFreezeLodgingNotAvailable:
      isHotelPriceFreezeLodgingNotAvailableSelector(state),
  };
};

const mapDispatchToProps = {
  fetchHotelPriceFreezeDetails,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type HotelPriceFreezeOverviewConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedHotelPriceFreezeOverview = connector(
  withRouter(HotelPriceFreezeOverview)
);
