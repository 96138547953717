import {
  FlightItinerarySlice,
  MyTripsFilter,
  getIsMixedClassByProps,
} from "redmond";

export const UPCOMING = "Upcoming";
export const PAST = "Past Trips";
export const VIEW_ALL = "View All";
export const BOOKINGS = "My Bookings";
export const WATCHES = "My Watches";
export const PRICE_FREEZE = "Price Freeze";
export const NO_TRIPS_FOUND = "No Trips Found";
export const NO_WATCHED_TRIPS = "You haven't watched any trips";
export const NO_PRICE_FREEZES = "You haven't frozen any prices";
export const NO_BOOKINGS = "You haven't made any bookings yet";
export const NO_TRIPS = "You have no upcoming trips";
export const DEPARTURE = "Departure";
export const RETURN = "Return";
export const FLIGHT = "Flight";
export const CONFIRMATION = "Confirmation";
export const BOOK_A_FLIGHT = "Book a Flight";
export const BOOK_A_HOTEL = "Book a Hotel";
export const THE_AIRLINE = "the Airline";
export const RENT_A_CAR = "Rent a Car";
export const VIEW_DETAILS = "View details";
export const LOADING_TRIPS = "Fetching trips";

export const getTabTitles = (
  tabFilter: MyTripsFilter,
  tripsCounts: {
    [MyTripsFilter.UPCOMING_TRIPS]: number;
    [MyTripsFilter.TRIP_REQUESTS]: number;
    [MyTripsFilter.PAST_TRIPS]: number;
    [MyTripsFilter.TRAVEL_CREDITS]: number;
    [MyTripsFilter.WATCHED_TRIPS]: number;
    [MyTripsFilter.PRIZE_FREEZES]: number;
  }
) =>
  ({
    [MyTripsFilter.UPCOMING_TRIPS]: {
      title: `Upcoming Trips (${tripsCounts[MyTripsFilter.UPCOMING_TRIPS]})`,
      subTitle:
        "Items in this list are ordered based on the most upcoming departure, check-in, and pickup dates.",
    },
    [MyTripsFilter.WATCHED_TRIPS]: {
      title: `Watched Trips (${tripsCounts[MyTripsFilter.WATCHED_TRIPS]})`,
      subTitle:
        "Items in this list are ordered based on the most upcoming departure dates",
    },
    [MyTripsFilter.TRIP_REQUESTS]: {
      title: "Trip requests",
      subTitle: "Items in this list are ordered based on date.",
    },
    [MyTripsFilter.PAST_TRIPS]: {
      title: `Past Trips (${tripsCounts[MyTripsFilter.PAST_TRIPS]})`,
      subTitle:
        "Items in this list are ordered based on the most recent past departure, check-in and pick up dates.",
    },
    [MyTripsFilter.PRIZE_FREEZES]: {
      title: `Frozen Prices (${tripsCounts[MyTripsFilter.PRIZE_FREEZES]})`,
      subTitle:
        "Items in this list are ordered based on the most upcoming departure dates.",
    },
    [MyTripsFilter.TRAVEL_CREDITS]: {
      title: `Airline Travel credits (${
        tripsCounts[MyTripsFilter.TRAVEL_CREDITS]
      })`,
      subTitle:
        "Items in this list are ordered based on the most recent original departure dates.",
    },
  }[tabFilter]);

// mixed cabin is true if the following conditions are met:
// - fareShelf rating of 3 or above AND one of the below:
//  - the fareShelf shortBrandName is PREMIUM ECONOMY AND one of the segment has a cabinClassName of ECONOMY
//  - the shortBrandName is FIRST or BUSINESS (not premium economy) AND one of the segment includes the word "Economy" (PREMIUM ECONOMY/ECONOMY)
// note: fareShelf checking using the shortBrandName instead of the cabinClassName because the cabinClassName mapping is currently incorrect in same cases (we were getting back a cabinClassName of FIRST instead of PREMIUM ECONOMY)

export const getIsMixedClass = (slice: FlightItinerarySlice): boolean => {
  const fareShelf = slice.fareShelf;

  return getIsMixedClassByProps({
    fareShelfRating: fareShelf?.rating,
    fareShelfBrandName: fareShelf?.brandName,
    fareShelfShortBrandName: fareShelf?.shortBrandName,
    cabinClassNames: slice.segments.map((segment) => segment.cabinClassName),
  });
};

export const FAQ_TITLE = "Questions about your trip?";
export const FAQ_CTA = "View FAQs";

export const TRAVEL_SALES_EVENT_ACTIVE_SUBTITLE =
  "Save up to $200 on select hotels with our first-ever travel sale.";
export const TRAVEL_SALES_EVENT_ACTIVE_CTA = "Explore destinations on sale";

export const TRAVEL_SALES_EVENT_LEADUP_SUBTITLE =
  "Our first-ever travel sale lands in";
export const TRAVEL_SALES_EVENT_LEADUP_CTA = "Explore upcoming sale";
