export const PICK_UP = "Pick-up";
export const DROP_OFF = "Drop-off";
export const PICK_UP_AND_DROP_OFF = `${PICK_UP} ${DROP_OFF}`;
export const PICK_UP_TITLE = `${PICK_UP} Location`;
export const DROP_OFF_TITLE = `${DROP_OFF} Location`;
export const PICK_UP_AND_DROP_OFF_TITLE = `${PICK_UP} and ${DROP_OFF} Location`;
export const PICK_UP_TIME = `${PICK_UP} time`;
export const DROP_OFF_TIME = `${DROP_OFF} time`;

export const getTitle = (type: string) =>
  ({
    [PICK_UP]: PICK_UP_TITLE,
    [DROP_OFF]: DROP_OFF_TITLE,
    [PICK_UP_AND_DROP_OFF]: PICK_UP_AND_DROP_OFF_TITLE,
  }[type]);
