import { IStoreState } from "../../../../reducers/types";

export const getTravelWalletOffers = (state: IStoreState) =>
  state.myTripsTravelWallet.offers;

export const getMyTripsTravelWalletCredit = (state: IStoreState) =>
  state.myTripsTravelWallet.credit;


  export const getMyTripsTravelWalletCreditBreakdown = (state: IStoreState) =>
  state.myTripsTravelWallet.creditBreakdown;
