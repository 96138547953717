import axios from "axios";
import { config } from "../../config";

import { UpdateWatchAlertRequest, UpdateWatchAlertResponse } from "redmond";
import { watchApiPrefix } from "../paths";

export const updateWatch = (
  request: UpdateWatchAlertRequest
): Promise<UpdateWatchAlertResponse> => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await axios.post(`${watchApiPrefix}/update`, request, {
        baseURL: config.baseURL,
      });
      resolve(res.data);
    } catch (e) {
      reject(e);
    }
  });
};
