import React, { useEffect } from "react";
import clsx from "clsx";
import {
  ArrowLink,
  HotelSummaryPanel,
  getRewardsString,
  getTotalPriceText,
  useDeviceTypes,
  LoadingIndicator,
  B2BSpinner,
  formatDurationNights,
  Header,
  ActionLink,
  getPriceString,
  NotificationBanner,
  BannerSeverity,
} from "halifax";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import * as textConstants from "./textConstants";
import background from "./hotelPriceFreezeOverview.png";
import "./styles.scss";
import {
  BOOK_HOTEL_PRICE_FREEZE,
  CurrentVersusCapEnum,
  GetHotelDetailRequestEnum,
  HOTEL_BOOK_TYPE,
  HotelBookType,
  PRICE_FREEZE_ID_QUERY_PARAM,
  Prices,
  VIEW_HOTEL_PRICE_FREEZE_ITINERARY,
  getViewHotelPriceFreezeItineraryProperties,
  HotelPriceFreezeStateEnum,
  CallState,
  FiatPrice,
  HOTEL_PRICE_FREEZE_PAY_NOTE_PRICE_DROP_SAVINGS_THRESHOLD,
} from "redmond";
import { HotelPriceFreezeOverviewConnectorProps } from "./container";
import { RouteComponentProps } from "react-router";
import dayjs from "dayjs";
import {
  HOTEL_PATH_BOOK,
  PATH_FROZEN_PRICES_LIST,
} from "../../../../utils/paths";
import queryStringParser from "query-string";
import { trackEvent } from "../../../../api/v1/analytics/trackEvent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { ContactSupportHeader } from "../../../../modules/common/ContactSupportHeader";
import { HotelPriceFreezeProgressBar } from "../HotelPriceFreezeProgressBar";

export interface HotelPriceFreezeOverviewProps
  extends HotelPriceFreezeOverviewConnectorProps,
    RouteComponentProps {
  voucherId: string;
}

export const HotelPriceFreezeOverview = (
  props: HotelPriceFreezeOverviewProps
) => {
  const {
    fetchHotelPriceFreezeDetails,
    fetchHotelPriceFreezeDetailsCallState,
    voucherId,
    hotelPriceFreezeDetails,
    hotelLodging,
    hotelVoucher,
    hotelAction,
    hotelReservation,
    hotelTotalFrozenPricing,
    hotelNightlyFrozenPricing,
    hotelTotalCurrentPricing,
    hotelRoomInfo,
    history,
    hotelLodgingError,
    hotelRoomPricingError,
    isHotelPriceFreezeLodgingNotAvailable,
  } = props;
  const { matchesMobile } = useDeviceTypes();

  const showHotelInfo =
    hotelPriceFreezeDetails &&
    hotelLodging &&
    hotelVoucher &&
    hotelAction &&
    hotelReservation &&
    hotelTotalFrozenPricing &&
    hotelLodgingError == undefined;

  const showPriceInfo =
    showHotelInfo &&
    hotelTotalCurrentPricing &&
    hotelRoomInfo &&
    hotelRoomPricingError == undefined;

  useEffect(() => {
    if (hotelPriceFreezeDetails) {
      trackEvent({
        eventName: VIEW_HOTEL_PRICE_FREEZE_ITINERARY,
        properties: getViewHotelPriceFreezeItineraryProperties(
          hotelVoucher,
          hotelNightlyFrozenPricing,
          hotelTotalFrozenPricing,
          hotelAction
        ),
      });
    }
  }, [hotelPriceFreezeDetails]);

  useEffect(() => {
    if (voucherId) {
      fetchHotelPriceFreezeDetails({
        id: voucherId,
        GetDetailRequest: GetHotelDetailRequestEnum.ByVoucherId,
      });
    }
  }, [voucherId]);

  useEffect(() => {
    if (
      fetchHotelPriceFreezeDetailsCallState === CallState.Success &&
      hotelVoucher?.state.State !== HotelPriceFreezeStateEnum.Valid
    ) {
      history.push(PATH_FROZEN_PRICES_LIST);
    }
  }, [fetchHotelPriceFreezeDetailsCallState, hotelVoucher]);

  // TODO: Move to common utils
  const shownPrices = (priceTotal: Prices) => {
    return (
      <>
        <span className="frozen-price-fiat-amount">
          <strong>{getTotalPriceText({ price: priceTotal.fiat })}</strong>
        </span>
        {priceTotal.rewards && (
          <>
            {" "}
            {textConstants.OR_SEPARATOR}{" "}
            {getRewardsString(Object.values(priceTotal.rewards)[0])}
          </>
        )}
      </>
    );
  };

  const FrozenPriceGridContent = ({
    displayPrice,
    duration,
  }: {
    displayPrice: Prices;
    duration: string;
  }) => {
    return (
      <div>
        <Typography
          variant="subtitle1"
          className="hotel-frozen-price-banner-title"
        >
          {textConstants.FROZEN_PRICE}
        </Typography>
        <Typography className="hotel-frozen-price-amount">
          {shownPrices(displayPrice)}
        </Typography>
        <Typography variant="body2">
          {textConstants.TOTAL_FOR_NIGHTS(duration)}
        </Typography>
        <Typography variant="body2" className="hotel-frozen-price-taxes">
          {textConstants.TAXES_AND_FEES_NOT_INCLUDED}
        </Typography>
      </div>
    );
  };

  const CurrentPriceGridContent = ({
    displayPrice,
    duration,
  }: {
    displayPrice: Prices;
    duration: string;
  }) => {
    return (
      <div>
        <Typography
          variant="subtitle1"
          className="hotel-frozen-price-banner-title"
        >
          {textConstants.CURRENT_PRICE}
        </Typography>
        <Typography
          className={clsx("hotel-frozen-price-amount", {
            //   "charged-price-amount": !hasReachedFareCap,
          })}
        >
          {shownPrices(displayPrice)}
        </Typography>
        <Typography variant="body2">
          {textConstants.TOTAL_FOR_NIGHTS(duration)}
        </Typography>
        <Typography variant="body2" className="hotel-frozen-price-taxes">
          {textConstants.TAXES_AND_FEES_NOT_INCLUDED}
        </Typography>
      </div>
    );
  };

  const HotelOverviewHeader = () => {
    return (
      <Grid
        container
        className={clsx("card", "hotel-price-freeze-overview-header")}
      >
        <Grid item xs={12}>
          <Grid
            container
            className={clsx("hotel-price-freeze-overview-header-banner")}
          >
            <Grid
              item
              xs={12}
              md={7}
              className="hotel-price-freeze-overview-header-content"
            >
              <Typography
                dangerouslySetInnerHTML={{
                  __html: textConstants.OVERVIEW_TITLE,
                }}
                variant="h4"
                className="hotel-price-freeze-overview-header-title"
              />
              {hotelAction && (
                <Typography
                  className="hotel-price-freeze-overview-header-subtitle"
                  variant="subtitle1"
                  dangerouslySetInnerHTML={{
                    __html: textConstants.OVERVIEW_SUBTITLE(
                      hotelAction.expiration
                    ),
                  }}
                  color="inherit"
                />
              )}
            </Grid>
            <Grid item xs={12} md={5}>
              <div
                className="overview-image"
                style={{ backgroundImage: `url(${background})` }}
              ></div>
            </Grid>
          </Grid>
        </Grid>
        {/* TODO: update boolean to notification banner when alternate rooms flow is implemented */}
        {false && (
          <Grid item xs={12}>
            <NotificationBanner
              label={
                textConstants.ALTERNATE_ROOM_NOTIFICATION_BANNER_WARNING_TEXT
              }
              severity={BannerSeverity.WARNING}
            />
          </Grid>
        )}
        {<HotelInfoContent />}
      </Grid>
    );
  };

  const HotelInfoContent = () => {
    if (showHotelInfo) {
      const checkIn = dayjs(hotelReservation.start).toDate();
      const checkOut = dayjs(hotelReservation.end).toDate();

      return (
        <Grid container className="hotel-price-freeze-overview-info">
          <Box className="frozen-hotel-details">
            <HotelSummaryPanel
              selectedLodging={hotelLodging}
              checkIn={checkIn}
              checkOut={checkOut}
              isMobile={matchesMobile}
              roomInfo={hotelRoomInfo ?? undefined}
              fullImage={true}
            />
          </Box>
        </Grid>
      );
    }
    return null;
  };

  const HotelPriceFreezeCheckout = ({
    checkIn,
    savingsNote,
  }: {
    checkIn: Date;
    savingsNote: string;
  }) => {
    if (isHotelPriceFreezeLodgingNotAvailable) {
      return null;
    }

    return (
      <Grid
        item
        xs={12}
        className={clsx("card-data", "frozen-price-button-container")}
      >
        <div className="frozen-price-button-wrapper">
          <Button
            onClick={() => {
              trackEvent({
                eventName: BOOK_HOTEL_PRICE_FREEZE,
                properties: {
                  price_freeze_id: voucherId,
                  price_freeze_flow: true,
                  advance: dayjs(checkIn).diff(dayjs(), "day"),
                  ...getViewHotelPriceFreezeItineraryProperties(
                    hotelVoucher,
                    hotelNightlyFrozenPricing,
                    hotelTotalFrozenPricing,
                    hotelAction
                  ),
                },
              });
              history.push({
                pathname: HOTEL_PATH_BOOK,
                search: queryStringParser.stringify({
                  [PRICE_FREEZE_ID_QUERY_PARAM]: voucherId,
                  [HOTEL_BOOK_TYPE]: HotelBookType.PRICE_FREEZE_EXERCISE,
                }),
              });
            }}
            className="continue-button"
            variant="contained"
          >
            {textConstants.COMPLETE_BOOKING}
          </Button>
          <Typography
            className="hotel-frozen-price-text"
            dangerouslySetInnerHTML={{
              __html: savingsNote,
            }}
          ></Typography>
        </div>
      </Grid>
    );
  };

  const HotelPriceInfo = () => {
    if (showHotelInfo) {
      const duration = formatDurationNights(
        dayjs.duration(hotelReservation.duration)
      );
      const checkIn = dayjs(hotelReservation.start).toDate();

      const frozenHotelPrice = hotelTotalFrozenPricing.frozen;
      const priceCap = hotelTotalFrozenPricing.cap;
      const priceFreezeCredit = hotelVoucher.deposit;

      if (showPriceInfo) {
        const currentHotelPrice = hotelTotalCurrentPricing.current;
        const sellingHotelPrice = hotelTotalCurrentPricing.baseRateWithSavings;

        // -1 if frozen < current
        // 0 if frozen == current
        // 1 if frozen > current
        const frozenVsCurrentPrice =
          frozenHotelPrice.fiat.value < currentHotelPrice.fiat.value
            ? -1
            : frozenHotelPrice.fiat.value > currentHotelPrice.fiat.value
            ? 1
            : 0;

        // If withinSameDollar the frozenVsCurrentPrice would be 0
        const withinSameDollar =
          getPriceString({ price: frozenHotelPrice.fiat.value }) ===
            getPriceString({ price: currentHotelPrice.fiat.value }) ||
          Math.trunc(frozenHotelPrice.fiat.value) ===
            Math.trunc(currentHotelPrice.fiat.value);

        const hitPriceCap =
          hotelTotalCurrentPricing.versusCap.CurrentVersusCap ==
          CurrentVersusCapEnum.OverCap;

        const savings = hotelTotalCurrentPricing.savings;

        const priceDropSavings: FiatPrice = {
          currencyCode: currentHotelPrice.fiat.currencyCode,
          currencySymbol: currentHotelPrice.fiat.currencySymbol,
          value: Math.abs(
            currentHotelPrice.fiat.value - frozenHotelPrice.fiat.value
          ),
        };

        const payPriceNote = (): { payNote: string; savingsNote: string } => {
          if (withinSameDollar) {
            // This would also cover the case if frozenVsCurrentPrice === 0
            return {
              payNote: "",
              savingsNote: textConstants.PRICE_FREEZE_CREDIT(
                getTotalPriceText({
                  price: priceFreezeCredit.fiat,
                })
              ),
            };
          } else if (frozenVsCurrentPrice < 0 && hitPriceCap) {
            return {
              payNote: textConstants.PRICES_INCREASED_HIT_CAP(
                getTotalPriceText({
                  price: currentHotelPrice.fiat,
                }),
                getTotalPriceText({
                  price: priceCap.fiat,
                }),
                getTotalPriceText({
                  price: sellingHotelPrice.fiat,
                })
              ),
              savingsNote: textConstants.PRICE_FREEZE_CREDIT(
                getTotalPriceText({
                  price: priceFreezeCredit.fiat,
                })
              ),
            };
          } else if (frozenVsCurrentPrice < 0 && !hitPriceCap) {
            const payNote =
              savings.fiat.value <
              HOTEL_PRICE_FREEZE_PAY_NOTE_PRICE_DROP_SAVINGS_THRESHOLD
                ? textConstants.PRICES_INCREASED_UNDER_CAP_UNDER_THRESHOLD(
                    getTotalPriceText({
                      price: currentHotelPrice.fiat,
                    })
                  )
                : textConstants.PRICES_INCREASED_UNDER_CAP(
                    getTotalPriceText({
                      price: currentHotelPrice.fiat,
                    }),
                    getTotalPriceText({
                      price: savings.fiat,
                    })
                  );
            return {
              payNote: payNote,
              savingsNote: textConstants.PRICE_FREEZE_CREDIT(
                getTotalPriceText({
                  price: priceFreezeCredit.fiat,
                })
              ),
            };
          } else if (frozenVsCurrentPrice > 0) {
            const payNote =
              priceDropSavings.value <
              HOTEL_PRICE_FREEZE_PAY_NOTE_PRICE_DROP_SAVINGS_THRESHOLD
                ? textConstants.PRICES_DROPPED_UNDER_THRESHOLD(
                    getTotalPriceText({
                      price: currentHotelPrice.fiat,
                    })
                  )
                : textConstants.PRICES_DROPPED(
                    getTotalPriceText({
                      price: currentHotelPrice.fiat,
                    }),
                    getTotalPriceText({
                      price: priceDropSavings,
                    })
                  );
            return {
              payNote: payNote,
              savingsNote: textConstants.PRICE_FREEZE_CREDIT(
                getTotalPriceText({
                  price: priceFreezeCredit.fiat,
                })
              ),
            };
          } else {
            return { payNote: "", savingsNote: "" };
          }
        };
        return (
          <Grid container className={clsx("card", "hotel-frozen-price-banner")}>
            <Grid
              item
              xs={12}
              className={clsx("card-data", "hotel-price-grid-first")}
            >
              {!showPriceInfo || frozenVsCurrentPrice >= 0 ? (
                <FrozenPriceGridContent
                  displayPrice={frozenHotelPrice}
                  duration={duration}
                />
              ) : (
                <CurrentPriceGridContent
                  displayPrice={currentHotelPrice}
                  duration={duration}
                />
              )}
            </Grid>
            <Grid
              item
              xs={12}
              className={clsx("card-data", "hotel-price-grid-second")}
            >
              {frozenVsCurrentPrice >= 0 && showPriceInfo ? (
                <CurrentPriceGridContent
                  displayPrice={currentHotelPrice}
                  duration={duration}
                />
              ) : (
                <FrozenPriceGridContent
                  displayPrice={frozenHotelPrice}
                  duration={duration}
                />
              )}
              <Typography
                className="hotel-price-pay-note"
                dangerouslySetInnerHTML={{
                  __html: payPriceNote().payNote,
                }}
              ></Typography>
            </Grid>
            <HotelPriceFreezeCheckout
              checkIn={checkIn}
              savingsNote={payPriceNote().savingsNote}
            />
          </Grid>
        );
      }

      return (
        <Grid container className={clsx("card", "hotel-frozen-price-banner")}>
          <Grid
            item
            xs={12}
            className={clsx("card-data", "hotel-price-grid-first")}
          >
            <FrozenPriceGridContent
              displayPrice={frozenHotelPrice}
              duration={duration}
            />
          </Grid>
          <Grid
            item
            xs={12}
            className={clsx("card-data", "hotel-price-grid-second", "empty")}
          ></Grid>
          <HotelPriceFreezeCheckout checkIn={checkIn} savingsNote="" />
        </Grid>
      );
    }

    return null;
  };

  const HotelPriceFreezeFAQ = () => {
    return (
      <Grid
        container
        className={clsx("card", "hotel-price-freeze-faq-container")}
      >
        <Grid item xs={12} className="hotel-price-freeze-faq-header">
          <Typography
            variant="h5"
            className="hotel-price-freeze-faq-header-title"
          >
            {textConstants.FAQ_TITLE}
          </Typography>
          <Typography variant="subtitle2">
            {textConstants.FAQ_SUBTITLE}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <ArrowLink
            content={
              <Typography className="hotel-price-freeze-faq">
                {textConstants.FAQ_TERMS}
              </Typography>
            }
            onClick={() => {
              window.open(textConstants.FAQ_TERMS_LINK, "_blank");
            }}
          />
        </Grid>
      </Grid>
    );
  };

  const HotelPriceFreezeOverviewContent = () => {
    const isLoading =
      fetchHotelPriceFreezeDetailsCallState === CallState.InProcess;

    if (isLoading) {
      return (
        <div className="hotel-price-freeze-loading-container">
          <LoadingIndicator
            indicatorSize="small"
            indicator={B2BSpinner}
            message={textConstants.LOADING_HOTEL_FROZEN_PRICE_TEXT}
          />
        </div>
      );
    }

    return (
      <Grid
        container
        className={clsx("hotel-price-freeze-overview-content", {
          mobile: matchesMobile,
        })}
      >
        <Grid item xs={12}>
          {<HotelOverviewHeader />}
        </Grid>
        <Grid item xs={12}>
          {<HotelPriceInfo />}
        </Grid>
        <Grid item xs={12}>
          {<HotelPriceFreezeFAQ />}
        </Grid>
      </Grid>
    );
  };

  const priceFreezeActiveStatus =
    hotelVoucher?.state.State == HotelPriceFreezeStateEnum.Valid;

  const renderHeader = (isMobile: boolean) => {
    if (showHotelInfo) {
      const getHeaderTitleMobile = () => {
        if (priceFreezeActiveStatus) {
          return textConstants.HEADER_TITLE(isMobile, priceFreezeActiveStatus);
        }
        return null;
      };
      const getHeaderSubtitleMobile = () => {
        return (
          <Typography
            variant="body2"
            dangerouslySetInnerHTML={{
              __html: textConstants.HEADER_SUBTITLE(hotelAction.expiration),
            }}
          />
        );
      };
      const onGoBackMobile = () =>
        history.push(textConstants.FROZEN_PRICES_LIST_LINK);

      if (isMobile) {
        return (
          <Header
            center={
              <div className="price-freeze-header-content">
                <Typography variant="body1">
                  {getHeaderTitleMobile()}
                </Typography>
                {getHeaderSubtitleMobile()}
              </div>
            }
            left={
              <ActionLink
                className={clsx("mobile-flight-search-header-go-back")}
                onClick={onGoBackMobile}
                content={<FontAwesomeIcon icon={faChevronLeft} />}
              />
            }
            isMobile={true}
            fullWidth={true}
          />
        );
      } else {
        return (
          <ContactSupportHeader
            title={
              priceFreezeActiveStatus
                ? textConstants.HEADER_TITLE(isMobile, priceFreezeActiveStatus)
                : ""
            }
            subtitle={textConstants.HEADER_SUBTITLE(hotelAction.expiration)}
            history={history}
          />
        );
      }
    } else {
      return null;
    }
  };

  return (
    <Box
      className={clsx("hotel-price-freeze-overview-root", {
        mobile: matchesMobile,
      })}
    >
      {renderHeader(matchesMobile)}
      <Box className="hotel-price-freeze-progress-bar-wrapper">
        <HotelPriceFreezeProgressBar voucherId={voucherId} />
      </Box>
      {<HotelPriceFreezeOverviewContent />}
    </Box>
  );
};
