import {
  AcceptScheduleChangeResponse,
  CallState,
  UserActionScheduleChangeSuccessProperties,
  USER_ACTION_SCHEDULE_CHANGE_SUCCESS,
} from "redmond";
import { put, select } from "redux-saga/effects";
import { trackEvent } from "../../../api/v1/analytics/trackEvent";
import { acceptScheduleChange } from "../../../api/v1/itinerary/acceptScheduleChange";
import { actions } from "../actions";
import { getUserActionScheduleChangeSuccessProperties } from "../reducer";

export function* acceptScheduleChangeSaga(
  action: actions.IAcceptScheduleChange
) {
  try {
    const response: AcceptScheduleChangeResponse = yield acceptScheduleChange({
      itineraryId: action.itineraryId,
    });
    if (response.success) {
      yield put(actions.setAcceptScheduleChangeCallState(CallState.Success));
      const properties: UserActionScheduleChangeSuccessProperties = yield select(
        getUserActionScheduleChangeSuccessProperties
      );
      yield trackEvent({
        eventName: USER_ACTION_SCHEDULE_CHANGE_SUCCESS,
        properties: {
          ...properties,
          user_action: "accept",
          action: "accept",
        },
      });
    } else {
      yield put(actions.setAcceptScheduleChangeCallState(CallState.Failed));
    }
  } catch (e) {
    yield put(actions.setAcceptScheduleChangeCallState(CallState.Failed));
    console.error(e);
  }
}
