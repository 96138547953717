import React from "react";
import * as constants from "./constants";

import { Box, Divider, Typography } from "@material-ui/core";
import { HomesItinerary } from "redmond";
import "./styles.scss";

export interface IContactHostHomesModalContentProps {
  home: HomesItinerary;
}

const ContactHostHomesModalContent = (
  props: IContactHostHomesModalContentProps
) => {
  const { home } = props;
  const formatPhoneNumber = (phoneNumber: string): string => {
    const cleaned = phoneNumber.replace(/\D/g, "");
    if (cleaned.length === 10) {
      return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(
        6
      )}`;
    } else if (cleaned.length === 11 && cleaned.startsWith("1")) {
      return `+1 (${cleaned.slice(1, 4)}) ${cleaned.slice(
        4,
        7
      )}-${cleaned.slice(7)}`;
    } else {
      return phoneNumber;
    }
  };
  const primaryOrFirstContact =
    home.reservation.hostInfo?.contacts?.find(
      (contact) => contact.type === "Primary"
    ) ||
    home.reservation.hostInfo?.contacts?.[0] ||
    null;

  const renderContent = () => {
    return (
      <Box className="contact-host-info-homes-container">
        <Typography variant="h2" className="modal-title">
          {constants.HOMES_CONTACT_INFO_TITLE}
        </Typography>
        <Typography variant="body2" className="modal-subtitle">
          {constants.HOMES_CONTACT_INFO_SUBTITLE}
        </Typography>
        <Divider className="modal-divider" />
        <Typography variant="h6" className="modal-subtitle">
          {home.reservation.hostInfo?.name}
        </Typography>
        {primaryOrFirstContact?.phoneNumber ? (
          <>
            <Typography variant="body2" className="modal-subtitle">
              {constants.HOMES_CONTACT_INFO_PHONE_NUMBER}
            </Typography>
            <Typography variant="body2" className="modal-action-link-text">
              <a
                href={`tel:${formatPhoneNumber(
                  primaryOrFirstContact.phoneNumber
                )}`}
              >
                {formatPhoneNumber(primaryOrFirstContact.phoneNumber)}
              </a>
            </Typography>
          </>
        ) : null}
        {primaryOrFirstContact?.email ? (
          <>
            <Typography variant="body2" className="modal-subtitle">
              {constants.HOMES_CONTACT_INFO_EMAIL}
            </Typography>
            <Typography variant="body2" className="modal-action-link-text">
              <a
                href={`mailto:${primaryOrFirstContact.email}`}
                className="modal-action-link-text"
              >
                {primaryOrFirstContact.email}
              </a>
            </Typography>
          </>
        ) : null}
      </Box>
    );
  };

  return (
    <Box className="contact-host-home-modal-content">{renderContent()}</Box>
  );
};

export default ContactHostHomesModalContent;
