import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../../../../../reducers/types";
import { MobileCarItineraryDetails } from "./component";
import { getSelectedCar, getTripsFilter } from "../../../../reducer";
import { setSelectedCar, setOpenModal } from "../../../../actions/actions";
import { withRouter } from "react-router";
import { MyTripsFilter } from "redmond";

const mapStateToProps = (state: IStoreState) => ({
  car: getSelectedCar(state),
  isPastTrips: getTripsFilter(state) === MyTripsFilter.PAST_TRIPS,
});

const mapDispatchToProps = {
  setOpenModal,
  setSelectedCar,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MobileCarItineraryDetailsConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedMobileCarItineraryDetails = connector(
  withRouter(MobileCarItineraryDetails)
);
