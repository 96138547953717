export const CHANGE_EMAIL_TITLE = "Change e-mail";
export const CHANGE_EMAIL_SUBTITLE =
  "Enter the email that you want to receive notifications from for this trip that you’re watching.";
export const CHANGE_EMAIL_INFO =
  "Changing your email will not have an impact on any of the other trips you’re watching. By watching this trip you are agreeing to receive email updates when the price of this trip changes. To manage your watched trips, visit My Trips.";
export const CHANGE_EMAIL_SUCCESS =
  "You have succesfully changed your email. Notifications will now be sent to";
export const CHANGE_EMAIL_FAILURE_TITLE = "Something went wrong";
export const CHANGE_EMAIL_FAILURE_SUBTITLE = "Please try again";
export const CHANGE_EMAIL_CTA = "Change email";
export const RETRY_CTA = "Retry";
export const DONE_CTA = "Done";
export const CANCEL_CTA = "Cancel";
