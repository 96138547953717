import { Box, Divider, Popover, Typography } from "@material-ui/core";
import clsx from "clsx";
import dayjs from "dayjs";
import {
  ActionLink,
  ActionLinks,
  FlightDetailsSummary,
  IActionLink,
  Icon,
  IconName,
  MixedCabinToolTip,
  MyTripsCard,
  Restriction,
  SelectedSeatsConfirmation,
  StatusTag,
  TripInfoDetails,
  useDeviceTypes,
} from "halifax";
import React, {
  MouseEvent,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import { RouteComponentProps } from "react-router-dom";
import {
  Airline,
  AirlineMap,
  Airport,
  AssociatedPassenger,
  BookedFlightItineraryWithDepartureTime,
  ExerciseEligibilityV1Enum,
  FlightItinerarySegment,
  FortWorthSeat,
  getCurrencyPrefix,
  getDepartureSlice,
  getFlightInfoDetails,
  getItinerarySummaryProps,
  getPriceWithDecimal,
  getRestrictionInfo,
  getReturnSlice,
  IOpenModal,
  IPerson,
  ItineraryEnum,
  ItinerarySeats,
  MyTripsFilter,
  MyTripsModalTypes,
  PortalItineraryStatusEnum,
  ScheduleChangeSeverity,
  SeatInfo,
  SelectedSeatsSegment,
  VIEWED_MISSED_CONNECTION_GUARANTEE_MODAL,
  VIEWED_SELF_TRANSFER_MODAL,
  VirtualInterlineEntryPoint,
  VirtualInterlineModalProperties,
  FlightItinerarySlice,
} from "redmond";
import {
  DisruptionProtectionRebookEnum,
  getSlicesFromTravelItinerary,
  isMultiCityItinerary,
} from "redmond/trips-module/itinerary";

import {
  ActiveExperiments,
  useExperiment,
} from "../../../../../../../../context/experiments";
import { pushToDisruptionOverview } from "../../../../../../../../utils/queryStringHelpers";
import { getIsMixedClass } from "../../../../../../constants";
import {
  getOriginalItineraryId,
  isTFBooking,
  isViMultiTicket,
  isWithin24Hours,
} from "../../../../../../utils";
import {
  DESKTOP_OFFSET_SCROLL,
  HOW_REFUNDS_WORK_LINK,
  HOW_REFUNDS_WORK_TEXT,
  MOBILE_OFFSET_SCROLL,
} from "../../../../constants";
import { AddOn, AddOnEnum, AddOnsSummary } from "../../../AddOnsSummary";
import { ConfirmationSummary } from "../../../ConfirmationSummary";
import { getConfirmationNumbers } from "../../../ItinerariesModal/components/ConfirmationModalContent/component";
import { PaymentSummary } from "../../../PaymentSummary";
import { RedeemFTCButton } from "../../../RedeemFTCButton";
import SeatUpdateInstructions from "../../../SeatUpdateInstructions/component";
import { SummaryCard } from "../../../SummaryCard";
import { TravelersSummary } from "../../../TravelersSummary";
import { getStatusTag, getTitleTag } from "../../component";
import * as textConstants from "../../constants";
import {
  addFlightType,
  airlinesCountFlightItinerarySegment,
  getIsDpExercisedViaRebook,
  getIsDpExercisedViaRefund,
  getLabels,
  getValues,
  hasCfarExpired,
  isFlightCanceled,
  flightMeetsMcpConditions,
  isFlightMultiTicketType,
  getDPPostBookking,
} from "../../helpers";
import { DesktopFlightCardConnectorProps } from "./container";

import "./styles.scss";
import { FlightVICombinationBanner } from "../../../FlightVICombinationBanner";
import {
  FlightMissedConnectionGuarantee,
  SelfTransferBanner,
} from "../../../FlightMissedConnectionGuarantee";
import {
  IVirtualInterliningVariant,
  VirtualIinterliningModal,
} from "../../../../../../../common/VirtualIinterliningModal";
import { trackEvent } from "../../../../../../../../api/v1/analytics/trackEvent";
import { POST_BOOKING_ADD_ONS } from "../../constants";
import { ClientContext } from "../../../../../../../../App";
import { PostBookingOfferBanner } from "../PostBookingOfferBanner";

interface IDesktopFlightCardProps
  extends DesktopFlightCardConnectorProps,
    RouteComponentProps {
  airlineMap: { [key: string]: Airline };
  airportMap: { [key: string]: Airport };
  expandedCard: string;
  flight: BookedFlightItineraryWithDepartureTime;
  banner?: JSX.Element;
  confirmationCodeClassName?: string;
  isPastTrips: boolean;
  isCanceled?: boolean;
  isMobile?: boolean;
  onExpandCard: (cardId: string) => void;
  setOpenModal: (modalType: IOpenModal) => void;
  tripsFilter: MyTripsFilter;
  hasDp: boolean;
  showDisruptionProtectionElements?: boolean;
}

export const DesktopFlightCard = (props: IDesktopFlightCardProps) => {
  const {
    airlineMap,
    airportMap,
    expandedCard,
    flight,
    isMobile,
    banner,
    confirmationCodeClassName,
    isPastTrips,
    isCanceled,
    flightDisruptions,
    upcomingItineraries,
    onExpandCard,
    setOpenModal,
    tripsFilter,
    setSelectedFlightDisruptionProtectionItineraryId,
    history,
    hasDp,
    showDisruptionProtectionElements,
    isDisruptionProtection24hRuleSatisfied,
    isDisruptionProtection24hRuleEnabled,
    paymentMethods,
  } = props;
  const {
    bookedItinerary,
    status,
    travelCredit,
    travelCreditCopy = "",
    ancillaries,
    departureTime,
  } = flight;
  const {
    id: itineraryId,
    passengers,
    paymentAmountInfo,
    scheduleChange,
    seats,
    travelItinerary,
  } = bookedItinerary;
  const clientContext = useContext(ClientContext);
  const { isAgentPortal } = clientContext;
  const departureSlice = getDepartureSlice(bookedItinerary);
  const { cfar, delay, missedConnection } = ancillaries;
  const passengerMap = {} as Record<string, IPerson>;

  passengers.alone.reduce((map, p) => {
    if (!map[p.person.id]) {
      map[p.person.id] = p.person;
    }
    return passengerMap;
  }, passengerMap);

  passengers.withLapInfants.reduce((map, p) => {
    if (!map[p.adult.person.id]) {
      map[p.adult.person.id] = p.adult.person;
    }
    return passengerMap;
  }, passengerMap);

  const travelerNames = Object.values(passengerMap).map(
    (person) => `${person?.givenName} ${person?.surname}`
  );

  const { matchesMobile } = useDeviceTypes();
  const cfarExp = useExperiment(ActiveExperiments.CFAR);
  const flightExchangeExp = useExperiment(ActiveExperiments.FlightExchange);
  const ftcPopoverRef = useRef<HTMLButtonElement>(null);
  const refundPopoverRef = useRef<HTMLButtonElement>(null);
  const [ftcPopoverOpen, setFTCPopoverOpen] = useState(false);
  const [refundPopoverOpen, setRefundPopoverOpen] = useState(false);
  const [variant, setVariant] = useState<IVirtualInterliningVariant | false>(
    false
  );
  const [expandedMulticitySliceIndex, setExpandedMulticitySliceIndex] =
    useState(0);
  const isTF = isTFBooking(travelItinerary);
  const hasCfar = cfarExp && !!cfar;
  const isDpExercisedViaRebook = getIsDpExercisedViaRebook(
    delay,
    missedConnection
  );

  const isDpExercisedViaRefund = getIsDpExercisedViaRefund(
    delay,
    missedConnection
  );

  const isDpExercised = isDpExercisedViaRebook || isDpExercisedViaRefund;
  const isViItinerary = isViMultiTicket(flight.bookedItinerary.multiTicketType);

  const hasAndShowDp = hasDp && !!showDisruptionProtectionElements;

  const hasAndShowEligibleDp =
    hasAndShowDp && isDisruptionProtection24hRuleSatisfied(departureTime);
  const sharedDpEligible = hasAndShowEligibleDp && !isDpExercised;
  const notCanceledDpEligible = sharedDpEligible && !isCanceled;
  const isCanceledDpEligible =
    sharedDpEligible &&
    isCanceled &&
    itineraryId !== undefined &&
    flightDisruptions?.eligibilityByItinerary[itineraryId]
      ?.ExerciseEligibilityV1 === ExerciseEligibilityV1Enum.IsEligible;

  const isRebookedItinerary =
    flight.disruptionProtectionRebook?.DisruptionProtectionRebook ===
    DisruptionProtectionRebookEnum.IsRebook;
  // note: hasAddOn can be expanded in the future as we have more features added in
  const hasAddOn = hasCfar || (hasAndShowEligibleDp && !isDpExercised);
  const hasFTC = !!travelCredit && !!travelCreditCopy;
  const showSeatsSection =
    tripsFilter === MyTripsFilter.UPCOMING_TRIPS || Boolean(seats);
  const showSeatsInstr = tripsFilter === MyTripsFilter.UPCOMING_TRIPS;

  const isMultiCity = isMultiCityItinerary(bookedItinerary, airportMap);
  const isRebookedFromMissedConnectionVi = getOriginalItineraryId(
    flight?.disruptionProtectionRebook
  );

  const closeFTCPopover = () => setFTCPopoverOpen(false);

  const closeRefundPopover = () => setRefundPopoverOpen(false);

  const goToExchange = (e?: MouseEvent) => {
    e?.stopPropagation();
    onOpenModal(MyTripsModalTypes.ExchangeFlight);
  };

  const goToDisruptionProtection = (e?: MouseEvent) => {
    const { flights } = upcomingItineraries;
    const eligibilities = flightDisruptions.eligibilityByItinerary;

    e?.stopPropagation();
    setSelectedFlightDisruptionProtectionItineraryId(itineraryId);
    pushToDisruptionOverview({
      history,
      itineraryId,
      tracking:
        flights && eligibilities
          ? {
              flights,
              eligibilities,
            }
          : undefined,
      path: isViItinerary ? "vi-rebook-connection" : "main",
    });
  };

  const openFTCPopover = () => setFTCPopoverOpen(true);

  const hasMissedConnectionGuarantee = ({
    isMultiTicketType,
    slice,
  }: {
    isMultiTicketType: boolean;
    slice: FlightItinerarySlice;
  }) => isMultiTicketType && isFlightMultiTicketType(slice);

  const openRefundPopover = () => setRefundPopoverOpen(true);

  const onOpenModal = (type: MyTripsModalTypes) =>
    setOpenModal({
      type,
      selectedItinerary: addFlightType(flight),
    });

  const showCancelButton = !isPastTrips;

  const getActions = () => {
    const actions: IActionLink[] = [];

    if (notCanceledDpEligible || isCanceledDpEligible) {
      actions.push({
        linkClassName: "disruption-protection-link",
        content: (
          <>
            <Icon
              className="disruption-protection-icon"
              name={IconName.DisruptionProtectionBlue}
            />
            {textConstants.GET_HELP_DISRUPTED_FLIGHT}
          </>
        ),
        onClick: goToDisruptionProtection,
      });
    }

    // VI rebook by mcp button
    // currently we have no control to know which departarure time to check, so we loop itineraries to find something that match 24hrs
    if (
      isViItinerary &&
      flightMeetsMcpConditions(
        flight,
        isDisruptionProtection24hRuleEnabled,
        isAgentPortal
      )
    ) {
      actions.push({
        content: textConstants.REBOOK_CONNECTING_FLIGHT,
        onClick: goToDisruptionProtection,
      });
    }

    if (!isCanceled && showCancelButton) {
      if (!isViItinerary && !isRebookedFromMissedConnectionVi) {
        actions.push({
          content: textConstants.CHANGE_FLIGHT,
          onClick: goToExchange,
        });
      }

      /*
        note: replace cancel-flight with cancel-for-any-reason when CFAR is included;
        when CFAR has expired, /cancelV3 will return a ContactCustomerService | AirlineControl cancellation scenario,
        and that can be consumed / addressed by the SelfServeCancelFlight modal.
      */
      if (hasCfar && !hasCfarExpired(cfar)) {
        actions.push({
          linkClassName: "desktop-cfar-link",
          content: (
            <>
              <Icon className="cfar-icon" name={IconName.CheckShieldBlue} />
              {textConstants.CANCEL_FOR_ANY_REASON}
            </>
          ),
          onClick: () => onOpenModal(MyTripsModalTypes.CfarFlight),
          disabled: isFlightCanceled(flight),
        });
      } else {
        if (!isRebookedFromMissedConnectionVi) {
          actions.push({
            content: textConstants.CANCEL_FLIGHT,
            onClick: () => onOpenModal(MyTripsModalTypes.SelfServeCancelFlight),
          });
        }
      }
    } else if (hasFTC) {
      // flight is cancelled && has travel credit
      actions.push({
        linkClassName: "how-ftc-works-link",
        content: (
          <>
            {textConstants.HOW_TC_WORKS}
            <Icon className="info-icon" name={IconName.InfoCircle} />
          </>
        ),
        linkRef: ftcPopoverRef,
        onClick: openFTCPopover,
      });
    } else if (isCanceled && !hasFTC) {
      actions.push({
        content: (
          <>
            {HOW_REFUNDS_WORK_LINK}
            <Icon className="info-icon" name={IconName.InfoCircle} />
          </>
        ),
        linkClassName: "how-refunds-work-link",
        linkRef: refundPopoverRef,
        onClick: openRefundPopover,
      });
    }

    actions.push({
      content: textConstants.RESEND_CONFIRMATION,
      onClick: () => onOpenModal(MyTripsModalTypes.ResendConfirmation),
    });

    if (
      !hasFTC &&
      tripsFilter !== MyTripsFilter.PAST_TRIPS &&
      !isCanceled &&
      isWithin24Hours(departureTime)
    ) {
      actions.push({
        content: (
          <>
            {textConstants.CHECK_IN_CTA_TEXT}
            <Icon name={IconName.ExternalLinkIcon} />
          </>
        ),
        onClick: () => onOpenModal(MyTripsModalTypes.CheckInFlight),
      });
    }

    return actions;
  };

  const renderViewScheduleLink = () => (
    <ActionLink
      className="view-update-link"
      onClick={() => {
        setOpenModal({
          type: MyTripsModalTypes.ScheduleChange,
          selectedItinerary: {
            ...flight,
            type: ItineraryEnum.Flight,
          },
        });
      }}
      content={textConstants.MAJOR_SCHEDULE_CHANGE_CTA_LABEL}
    />
  );

  const TopRightAction = useMemo(() => {
    if (
      status === PortalItineraryStatusEnum.Canceled &&
      hasFTC &&
      flightExchangeExp
    ) {
      return (
        <RedeemFTCButton onClick={goToExchange} travelCredit={travelCredit} />
      );
    }

    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flight, tripsFilter]);

  const hasMajorScheduleChange =
    scheduleChange?.severity === ScheduleChangeSeverity.Major;

  if (isMultiCity) {
    const tripSlices = getSlicesFromTravelItinerary(travelItinerary);

    return (
      <Box
        id={itineraryId}
        key={itineraryId}
        className={clsx("flight-trip-container", {
          mobile: isMobile,
          expanded: expandedCard === itineraryId,
        })}
      >
        {tripSlices.map((slice, index) => {
          const isExpanded =
            expandedCard === itineraryId &&
            expandedMulticitySliceIndex === index;
          const departureStatusTag = getStatusTag(
            flight,
            index,
            scheduleChange,
            airportMap,
            airlineMap as AirlineMap,
            isTF
          );
          const isMixedClass = getIsMixedClass(slice);
          const [sliceLocation, sliceDate] = textConstants
            .getItineraryDetailsHeader(flight, true, airportMap, index)
            .split("-");
          return (
            <>
              <MyTripsCard
                key={`trip-card-title-${index}`}
                className={clsx("trip-card-title", "multicity")}
                banner={banner}
                content={
                  <TripInfoDetails
                    confirmationCodeClassName={confirmationCodeClassName}
                    hideTitleTag={isCanceled || hasMajorScheduleChange}
                    titles={{
                      confirmationLabel: textConstants.CONFIRMATION,
                      startLabel: `${textConstants.DEPARTURE}:`,
                      endLabel: `${textConstants.RETURN}:`,
                      titleTag: getTitleTag(
                        flight,
                        airlineMap as AirlineMap,
                        scheduleChange?.severity ===
                          ScheduleChangeSeverity.Major
                      ),
                      ...getFlightInfoDetails(
                        flight.bookedItinerary.travelItinerary.locators,
                        slice,
                        undefined, // treat each slice as a oneway slice for multiCity
                        (date: string) =>
                          dayjs(date).format(textConstants.DATE_FORMAT),
                        airportMap,
                        airlineMap
                      ),
                    }}
                  />
                }
                actions={[]}
                isExpanded={isExpanded}
                expandString={
                  isExpanded
                    ? textConstants.VIEW_LESS
                    : textConstants.VIEW_DETAILS
                }
                expandIcon={
                  isExpanded
                    ? IconName.MinusBlueCircle
                    : IconName.PlusBlueCircle
                }
                topRightAction={TopRightAction}
                onExpandClick={() => {
                  if (expandedCard !== itineraryId || isExpanded)
                    onExpandCard(itineraryId);
                  setExpandedMulticitySliceIndex(index);
                  setTimeout(() => {
                    const OFFSET = matchesMobile
                      ? MOBILE_OFFSET_SCROLL
                      : DESKTOP_OFFSET_SCROLL;
                    const cardTop =
                      document
                        ?.getElementById(itineraryId)
                        ?.getBoundingClientRect().top || 0;
                    window.scrollBy({
                      top: (cardTop as number) - OFFSET,
                      behavior: matchesMobile ? "auto" : "smooth",
                    });
                  }, 100);
                }}
                travelers={index === 0 ? travelerNames : []}
              />
              {isExpanded && (
                <Box
                  className={clsx("desktop-expanded-container", "multicity")}
                  key={`desktop-expanded-container-${itineraryId}-${index}`}
                >
                  <Box className="details-info-container">
                    <Box className="slice-info">
                      <Box className="slice-info-title">
                        <Typography variant="subtitle2">
                          <span className="direction-label">
                            {textConstants.OUTBOUND + sliceLocation}
                          </span>
                          {sliceDate}
                          {isMixedClass && <MixedCabinToolTip />}
                        </Typography>
                        {departureStatusTag && (
                          <>
                            <StatusTag tagInfo={departureStatusTag} />
                            {hasMajorScheduleChange && renderViewScheduleLink()}
                          </>
                        )}
                      </Box>
                      <FlightDetailsSummary
                        className="outgoing"
                        showTitle={false}
                        {...getItinerarySummaryProps(
                          flight,
                          true,
                          airportMap,
                          airlineMap,
                          expandedMulticitySliceIndex
                        )}
                        isMixedCabinClass={isMixedClass}
                      />
                    </Box>
                    <Box className="slice-info">
                      <Typography
                        className="fare-details-header"
                        variant="subtitle1"
                      >
                        {textConstants.FARE_DETAILS}
                      </Typography>
                      <Box className="trip-itinerary-restrictions">
                        {getRestrictionInfo(
                          flight,
                          isTF,
                          departureSlice,
                          departureSlice.fareShelf?.brandName
                        ).map((restriction, index) => (
                          <Restriction
                            key={`restriction-${index}`}
                            name={restriction.name}
                            descriptionString={restriction.description}
                            symbol={restriction.symbol}
                          />
                        ))}
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    className={clsx("summary-info-container", {
                      "two-column-view": hasAddOn || isRebookedItinerary,
                    })}
                  >
                    <SummaryCard
                      className="travellers-summary"
                      action={
                        <ActionLink
                          className="details-link"
                          onClick={() =>
                            onOpenModal(MyTripsModalTypes.TravelersModal)
                          }
                          content="Details"
                        />
                      }
                    >
                      <TravelersSummary
                        travelers={passengers.withLapInfants
                          .reduce(
                            (acc, awli) => [
                              ...acc,
                              awli.adult as AssociatedPassenger,
                              awli.infant as AssociatedPassenger,
                            ],
                            [] as AssociatedPassenger[]
                          )
                          .concat(passengers.alone)
                          .map(
                            ({ person }: AssociatedPassenger) =>
                              person?.givenName
                          )
                          .join(", ")}
                      />
                    </SummaryCard>
                    <SummaryCard
                      className="payment-summary"
                      action={
                        <ActionLink
                          className="details-link"
                          onClick={() =>
                            onOpenModal(MyTripsModalTypes.PaymentModal)
                          }
                          content="Details"
                        />
                      }
                    >
                      <PaymentSummary
                        tripTotalAmount={`${getCurrencyPrefix(
                          flight
                        )}${getPriceWithDecimal(flight)}`}
                        cardLabel={getLabels(paymentAmountInfo).cardLabel}
                        showCardLabel={Boolean(
                          getLabels(paymentAmountInfo).cardLabel
                        )}
                        showRewardsLabel={Boolean(
                          getLabels(paymentAmountInfo).rewardLabel
                        )}
                        cardAmount={getValues(paymentAmountInfo).cardValue}
                        rewardsLabel={getLabels(paymentAmountInfo).rewardLabel}
                        rewardsAmount={getValues(paymentAmountInfo).rewardValue}
                      />
                    </SummaryCard>
                    <SummaryCard
                      className="confirmation-summary"
                      action={
                        <ActionLink
                          className="details-link"
                          onClick={() =>
                            onOpenModal(MyTripsModalTypes.ConfirmationModal)
                          }
                          content={`View all (${
                            getConfirmationNumbers({ flight, airlineMap })
                              .length
                          })`}
                        />
                      }
                    >
                      <ConfirmationSummary
                        confirmationCode={
                          `H-${travelItinerary.locators?.agent.unscopedValue}` ||
                          ""
                        }
                      />
                    </SummaryCard>
                  </Box>
                </Box>
              )}
            </>
          );
        })}
        <ActionLinks
          key={`multicity-action-links-${itineraryId}`}
          className="multicity"
          actions={getActions()}
        />
      </Box>
    );
  } else {
    const returnSlice = getReturnSlice(bookedItinerary);

    const isOutgoingMixedClass = getIsMixedClass(departureSlice);
    const isReturnMixedClass = !!returnSlice
      ? getIsMixedClass(returnSlice)
      : false;

    const isMultiTicketType = isViMultiTicket(bookedItinerary.multiTicketType);
    const airlinesCount =
      (isMultiTicketType &&
        airlinesCountFlightItinerarySegment(departureSlice.segments)) ||
      (returnSlice &&
        airlinesCountFlightItinerarySegment(returnSlice.segments));

    const departureStatusTag = getStatusTag(
      flight,
      0,
      scheduleChange,
      airportMap,
      airlineMap as AirlineMap,
      isTF
    );

    const returnStatusTag = getStatusTag(
      flight,
      1,
      scheduleChange,
      airportMap,
      airlineMap as AirlineMap,
      isTF
    );

    const postBooking = getDPPostBookking(flight);

    const dpAddOn = (addOnItems: AddOn[], forPostBooking: boolean) => {
      if (hasAndShowDp && !isDpExercised && forPostBooking == !!postBooking) {
        addOnItems.push({
          onClick: goToDisruptionProtection,
          isEnabled: !isFlightCanceled(flight),
          type: AddOnEnum.DisruptionProtection,
        });
      }
    };

    const addOnItems: AddOn[] = [];
    dpAddOn(addOnItems, false);
    if (hasCfar) {
      addOnItems.push({
        expiryDate: cfar?.expired ?? "",
        expiredTz: "America/New_York", // TODO: Fall back to EST / EDT for now
        onClick: () => onOpenModal(MyTripsModalTypes.CfarFlight),
        isEnabled: !hasCfarExpired(cfar) && !isFlightCanceled(flight),
        type: AddOnEnum.Cfar,
      });
    }

    const postBookingAddOnItems: AddOn[] = [];
    dpAddOn(postBookingAddOnItems, true);

    const addOnsSummary = (
      type: "post-booking" | "booking",
      addOns: AddOn[]
    ) => {
      function label() {
        switch (type) {
          case "post-booking":
            const dp = getDPPostBookking(flight);
            if (dp?.created) {
              return textConstants.POST_BOOKING_ADD_ON_DATE(dp.created);
            }
            return undefined;
          case "booking":
            return undefined;
        }
      }

      return (
        <SummaryCard className="add-ons-summary">
          <AddOnsSummary
            addOns={(() => {
              return addOns;
            })()}
            label={label()}
          />
        </SummaryCard>
      );
    };

    return (
      <Box
        id={itineraryId}
        key={itineraryId}
        className={clsx("flight-trip-container", {
          mobile: isMobile,
          expanded: expandedCard === itineraryId,
        })}
      >
        <MyTripsCard
          className="trip-card-title"
          banner={banner}
          content={
            <TripInfoDetails
              confirmationCodeClassName={confirmationCodeClassName}
              hideTitleTag={isCanceled || hasMajorScheduleChange}
              isMultiTicketType={isMultiTicketType}
              airlinesCount={airlinesCount}
              titles={{
                confirmationLabel: textConstants.CONFIRMATION,
                startLabel: `${textConstants.DEPARTURE}:`,
                endLabel: `${textConstants.RETURN}:`,
                titleTag: getTitleTag(
                  flight,
                  airlineMap as AirlineMap,
                  scheduleChange?.severity === ScheduleChangeSeverity.Major
                ),
                ...getFlightInfoDetails(
                  flight.bookedItinerary.travelItinerary.locators,
                  getDepartureSlice(flight.bookedItinerary),
                  getReturnSlice(flight.bookedItinerary),
                  (date: string) =>
                    dayjs(date).format(textConstants.DATE_FORMAT),
                  airportMap,
                  airlineMap
                ),
              }}
            />
          }
          actions={<ActionLinks actions={getActions()} />}
          isExpanded={expandedCard === itineraryId}
          expandString={
            expandedCard === itineraryId
              ? textConstants.VIEW_LESS
              : textConstants.VIEW_DETAILS
          }
          expandIcon={
            expandedCard === itineraryId
              ? IconName.MinusBlueCircle
              : IconName.PlusBlueCircle
          }
          topRightAction={TopRightAction}
          onExpandClick={() => {
            onExpandCard(itineraryId);
            setTimeout(() => {
              const OFFSET = matchesMobile
                ? MOBILE_OFFSET_SCROLL
                : DESKTOP_OFFSET_SCROLL;
              const cardTop =
                document?.getElementById(itineraryId)?.getBoundingClientRect()
                  .top || 0;
              window.scrollBy({
                top: (cardTop as number) - OFFSET,
                behavior: matchesMobile ? "auto" : "smooth",
              });
            }, 100);
          }}
          travelers={travelerNames}
        />
        {
          <PostBookingOfferBanner
            itineraryId={itineraryId}
            offerContainerModifier={{
              "expanded-details": expandedCard === itineraryId,
            }}
          />
        }
        {itineraryId === expandedCard && (
          <Box className="desktop-expanded-container">
            <Box className="details-info-container">
              <Box className="slice-info">
                <Box className="slice-info-title">
                  <Typography variant="subtitle2">
                    <span className="direction-label">
                      {textConstants.OUTBOUND}
                    </span>
                    {textConstants.getItineraryDetailsHeader(
                      flight,
                      true,
                      airportMap
                    )}
                    {isOutgoingMixedClass && <MixedCabinToolTip />}
                  </Typography>
                  {departureStatusTag && (
                    <>
                      <StatusTag tagInfo={departureStatusTag} />
                      {hasMajorScheduleChange && renderViewScheduleLink()}
                    </>
                  )}
                </Box>
                <FlightDetailsSummary
                  className="outgoing"
                  flightCombinationBanner={
                    airlinesCountFlightItinerarySegment(
                      departureSlice.segments
                    ) > 0 && (
                      <FlightVICombinationBanner isMobile={matchesMobile} />
                    )
                  }
                  missedConnectionGuarantee={
                    hasMissedConnectionGuarantee({
                      isMultiTicketType,
                      slice: departureSlice,
                    }) && (
                      <>
                        <FlightMissedConnectionGuarantee
                          travelItinerary={travelItinerary}
                          isMobile={matchesMobile}
                          onClick={() => {
                            trackEvent({
                              eventName:
                                VIEWED_MISSED_CONNECTION_GUARANTEE_MODAL,
                              properties: {
                                entry_point:
                                  VirtualInterlineEntryPoint.TripDetails,
                              } as VirtualInterlineModalProperties,
                            });
                            setVariant("missedConnectionGuarantee");
                          }}
                        />
                        <SelfTransferBanner
                          iconName={IconName.BookTravel}
                          onClick={() => {
                            trackEvent({
                              eventName: VIEWED_SELF_TRANSFER_MODAL,
                              properties: {
                                entry_point:
                                  VirtualInterlineEntryPoint.TripDetails,
                              } as VirtualInterlineModalProperties,
                            });
                            setVariant("selfCheck");
                          }}
                        />
                      </>
                    )
                  }
                  showTitle={false}
                  {...getItinerarySummaryProps(
                    flight,
                    true,
                    airportMap,
                    airlineMap
                  )}
                  isMixedCabinClass={isOutgoingMixedClass}
                />

                <Divider className="divider" />
                <Typography className="fare-details-header" variant="subtitle1">
                  {textConstants.FARE_DETAILS}
                </Typography>
                <Box className="trip-itinerary-restrictions">
                  {getRestrictionInfo(
                    flight,
                    isTF,
                    departureSlice,
                    departureSlice.fareShelf?.brandName
                  ).map((restriction, index) => (
                    <Restriction
                      key={`restriction-${index}`}
                      name={restriction.name}
                      descriptionString={restriction.description}
                      symbol={restriction.symbol}
                    />
                  ))}
                </Box>
                {showSeatsSection && (
                  <>
                    <Divider className="divider" />
                    <Box className="seat-selection">
                      <Typography
                        className="seat-selection-header"
                        variant="subtitle1"
                      >
                        {textConstants.SEAT_SELECTION}
                      </Typography>
                      {showSeatsInstr && (
                        <SeatUpdateInstructions
                          airlineMap={airlineMap as AirlineMap}
                          slice={departureSlice}
                        />
                      )}
                      {seats && (
                        <Box className="outbound-seat-selection">
                          <SelectedSeatsConfirmation
                            outboundSeatSegments={getSeatSegments(
                              seats,
                              departureSlice.segments,
                              passengerMap,
                              true
                            )}
                            outboundOriginCode={
                              departureSlice.segments[0]?.origin.locationCode
                            }
                            returnOriginCode={
                              returnSlice?.segments[0]?.origin.locationCode
                            }
                            airports={airportMap}
                          />
                        </Box>
                      )}
                    </Box>
                  </>
                )}
              </Box>
              {returnSlice && (
                <Box className="slice-info return">
                  <Box className="slice-info-title">
                    <Typography variant="subtitle2">
                      <span className="direction-label">
                        {textConstants.RETURN}
                      </span>
                      {textConstants.getItineraryDetailsHeader(
                        flight,
                        false,
                        airportMap
                      )}
                      {isReturnMixedClass && <MixedCabinToolTip />}
                    </Typography>
                    {returnStatusTag && (
                      <>
                        <StatusTag tagInfo={returnStatusTag} />
                        {hasMajorScheduleChange && renderViewScheduleLink()}
                      </>
                    )}
                  </Box>
                  <FlightDetailsSummary
                    showTitle={false}
                    flightCombinationBanner={
                      airlinesCountFlightItinerarySegment(
                        returnSlice.segments
                      ) > 0 && (
                        <FlightVICombinationBanner isMobile={matchesMobile} />
                      )
                    }
                    missedConnectionGuarantee={
                      hasMissedConnectionGuarantee({
                        isMultiTicketType,
                        slice: returnSlice,
                      }) && (
                        <>
                          <FlightMissedConnectionGuarantee
                            travelItinerary={travelItinerary}
                            isMobile={matchesMobile}
                            onClick={() => {
                              trackEvent({
                                eventName:
                                  VIEWED_MISSED_CONNECTION_GUARANTEE_MODAL,
                                properties: {
                                  entry_point:
                                    VirtualInterlineEntryPoint.TripDetails,
                                } as VirtualInterlineModalProperties,
                              });
                              setVariant("missedConnectionGuarantee");
                            }}
                          />
                          <SelfTransferBanner
                            iconName={IconName.BookTravel}
                            onClick={() => {
                              trackEvent({
                                eventName: VIEWED_SELF_TRANSFER_MODAL,
                                properties: {
                                  entry_point:
                                    VirtualInterlineEntryPoint.TripDetails,
                                } as VirtualInterlineModalProperties,
                              });
                              setVariant("selfCheck");
                            }}
                          />
                        </>
                      )
                    }
                    className="return"
                    {...getItinerarySummaryProps(
                      flight,
                      false,
                      airportMap,
                      airlineMap
                    )}
                    isMixedCabinClass={isReturnMixedClass}
                  />

                  <Divider className="divider" />
                  <Typography
                    className="fare-details-header"
                    variant="subtitle1"
                  >
                    {textConstants.FARE_DETAILS}
                  </Typography>
                  <Box className="trip-itinerary-restrictions">
                    {getRestrictionInfo(
                      flight,
                      isTF,
                      returnSlice,
                      returnSlice.fareShelf?.brandName
                    ).map((restriction, index) => (
                      <Restriction
                        key={`restriction-${index}`}
                        name={restriction.name}
                        descriptionString={restriction.description}
                        symbol={restriction.symbol}
                      />
                    ))}
                  </Box>
                  {showSeatsSection && (
                    <>
                      <Divider className="divider" />
                      <Box className="seat-selection">
                        <Typography
                          className="seat-selection-header"
                          variant="subtitle1"
                        >
                          {textConstants.SEAT_SELECTION}
                        </Typography>
                        {showSeatsInstr && (
                          <SeatUpdateInstructions
                            airlineMap={airlineMap as AirlineMap}
                            slice={returnSlice}
                          />
                        )}
                        {seats && (
                          <Box className="return-seat-selection">
                            <SelectedSeatsConfirmation
                              outboundSeatSegments={getSeatSegments(
                                seats,
                                returnSlice.segments,
                                passengerMap,
                                false
                              )}
                              outboundOriginCode={
                                departureSlice?.segments[0]?.origin.locationCode
                              }
                              returnOriginCode={
                                returnSlice?.segments[0]?.origin.locationCode
                              }
                              airports={airportMap}
                            />
                          </Box>
                        )}
                      </Box>
                    </>
                  )}
                </Box>
              )}
            </Box>
            <Box
              className={clsx("summary-info-container", {
                "two-column-view": addOnItems.length > 0 || isRebookedItinerary,
              })}
            >
              <SummaryCard
                className="travellers-summary"
                action={
                  <ActionLink
                    className="details-link"
                    onClick={() =>
                      onOpenModal(MyTripsModalTypes.TravelersModal)
                    }
                    content="Details"
                  />
                }
              >
                <TravelersSummary
                  travelers={passengers.withLapInfants
                    .reduce(
                      (acc, awli) => [
                        ...acc,
                        awli.adult as AssociatedPassenger,
                        awli.infant as AssociatedPassenger,
                      ],
                      [] as AssociatedPassenger[]
                    )
                    .concat(passengers.alone)
                    .map(({ person }: AssociatedPassenger) => person?.givenName)
                    .join(", ")}
                />
              </SummaryCard>
              {/* note: the payment section is hidden in rebooked itineraries; it's part of the business logic in the DP rebook flow */}
              {!isRebookedItinerary ? (
                <SummaryCard
                  className="payment-summary"
                  action={
                    <ActionLink
                      className="details-link"
                      onClick={() =>
                        onOpenModal(MyTripsModalTypes.PaymentModal)
                      }
                      content="Details"
                    />
                  }
                >
                  <PaymentSummary
                    tripTotalAmount={`${getCurrencyPrefix(
                      flight
                    )}${getPriceWithDecimal(flight)}`}
                    cardLabel={getLabels(paymentAmountInfo).cardLabel}
                    showCardLabel={Boolean(
                      getLabels(paymentAmountInfo).cardLabel
                    )}
                    showRewardsLabel={Boolean(
                      getLabels(paymentAmountInfo).rewardLabel
                    )}
                    cardAmount={getValues(paymentAmountInfo).cardValue}
                    rewardsLabel={getLabels(paymentAmountInfo).rewardLabel}
                    rewardsAmount={getValues(paymentAmountInfo).rewardValue}
                  />
                </SummaryCard>
              ) : null}
              <SummaryCard
                className="confirmation-summary"
                action={
                  <ActionLink
                    className="details-link"
                    onClick={() =>
                      onOpenModal(MyTripsModalTypes.ConfirmationModal)
                    }
                    content={`View all (${
                      getConfirmationNumbers({ flight, airlineMap }).length
                    })`}
                  />
                }
              >
                <ConfirmationSummary
                  confirmationCode={
                    `H-${travelItinerary.locators?.agent.unscopedValue}` || ""
                  }
                />
              </SummaryCard>
              {addOnItems.length > 0 && addOnsSummary("booking", addOnItems)}
            </Box>
            {postBookingAddOnItems.length > 0 && postBooking && (
              <Box>
                <Typography variant="h1" className="flight-add-on-title">
                  {POST_BOOKING_ADD_ONS}
                </Typography>
                <Box
                  className={clsx("summary-info-container", "two-column-view")}
                >
                  {addOnsSummary("post-booking", postBookingAddOnItems)}
                  <SummaryCard className="payment-summary no-gap">
                    <PaymentSummary
                      tripTotalLabel={textConstants.TOTAL_POST_BOOKING_ADD_ONS}
                      tripTotalAmount={`${getCurrencyPrefix(flight)}${
                        postBooking.formattedTotal
                      }`}
                      cardLabel={
                        paymentMethods.find(
                          (card) => card.id == postBooking?.postBookingPaymentId
                        )?.numberDisplay
                      }
                      showCardLabel={Boolean(
                        paymentMethods.find(
                          (card) => card.id == postBooking?.postBookingPaymentId
                        )?.numberDisplay
                      )}
                      showRewardsLabel={false}
                      cardAmount={`${getCurrencyPrefix(flight)}${
                        postBooking.formattedTotal
                      }`}
                    />
                  </SummaryCard>
                </Box>
              </Box>
            )}
          </Box>
        )}
        {isCanceled && hasFTC && (
          <Popover
            anchorEl={ftcPopoverRef.current}
            anchorOrigin={{
              horizontal: "left",
              vertical: "bottom",
            }}
            className="how-ftc-works-popover"
            onClose={closeFTCPopover}
            open={ftcPopoverOpen}
          >
            <Typography
              className="subtitle"
              component="span"
              dangerouslySetInnerHTML={{ __html: travelCreditCopy }}
            />
          </Popover>
        )}
        {isCanceled && !hasFTC && (
          <Popover
            anchorEl={refundPopoverRef.current}
            anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
            className="how-refunds-work-popover"
            onClose={closeRefundPopover}
            open={refundPopoverOpen}
          >
            <Typography className="subtitle">
              {HOW_REFUNDS_WORK_TEXT}
            </Typography>
          </Popover>
        )}
        {variant && (
          <VirtualIinterliningModal
            variant={variant}
            isMobile={matchesMobile}
            isOpen
            onClose={() => setVariant(false)}
          />
        )}
      </Box>
    );
  }
};

export const getSeatSegments = (
  itinerarySeats: ItinerarySeats,
  segments: FlightItinerarySegment[],
  passengerMap: { [key: string]: IPerson },
  isOutgoing: boolean
): SelectedSeatsSegment[] => {
  if (!itinerarySeats) return [];
  const filteredSeats = itinerarySeats.seats.filter(
    (s) => s.outgoing === isOutgoing
  );
  const seatSegmentMap = filteredSeats.reduce((map, seat) => {
    const key = `${seat.origin}-${seat.destination}-${seat.personId}`;
    map[key] = getSeatInfo(seat, passengerMap);
    return map;
  }, {});
  return segments.map((s: FlightItinerarySegment, idx: number) => {
    const key = `${s.origin.locationCode}-${s.destination.locationCode}`;
    const seatSegment: SelectedSeatsSegment = {
      originCode: s.origin.locationCode,
      flightNumber: s.marketingAirline.flightNumber.toString(),
      seats: {
        departureDate: s.scheduledDeparture,
        originCode: s.origin.locationCode,
        destinationCode: s.destination.locationCode,
        seats: Object.values(passengerMap).map((p) =>
          seatSegmentMap[`${key}-${p.id}`]
            ? seatSegmentMap[`${key}-${p.id}`]
            : getSeatNotSelected(p)
        ),
      },
      isOutgoing,
      idx,
    };
    return seatSegment;
  });
};

export const getSeatInfo = (
  seat: FortWorthSeat,
  passengerMap: { [key: string]: IPerson }
): SeatInfo => {
  const passenger = passengerMap[seat.personId];
  const name = `${passenger?.givenName} ${passenger?.surname}`;
  return {
    carrier: seat.carrier,
    display_name: name,
    pax: name,
    pax_id: seat.passengerId.value,
    person_id: seat.personId,
    seat_id: seat.seatId.value,
    total: {
      amount: seat.price,
      amountUsd: seat.price,
      currency: seat.currency,
      decimalPlaces: 1,
      display: seat.price.toString(),
      mantissa: seat.price,
      mantissaUsd: seat.price,
    },
  };
};

export const getSeatNotSelected = (p: IPerson) => ({
  carrier: "",
  display_name: "",
  pax: `${p?.givenName} ${p?.surname}`,
  pax_id: "",
  person_id: p?.id,
  seat_id: "",
  total: {
    amount: 0,
    amountUsd: 0,
    currency: "",
    decimalPlaces: 0,
    display: "",

    mantissa: 0,
    mantissaUsd: 0,
  },
});
