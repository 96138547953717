import { Box, Typography } from "@material-ui/core";
import React, { ReactNode } from "react";
import { FlightInfoCheckbox } from "halifax";
import clsx from "clsx";
import {
  Airport,
  BookedFlightItineraryWithDepartureTime,
  FlightCancelInfoResponse,
} from "redmond";
import { getItineraryDetailsHeader } from "../../../FlightCard/constants";
import {
  OUTBOUND,
  RETURN,
  getOriginSegment,
  getDestinationSegment,
  getSliceDestination,
  formatter,
  SELECT_CANCEL_FLIGHT,
  getTravelItinerary,
} from "./constants";
import "./styles.scss";

export interface ISelectFlightInfoProps {
  airportMap: { [key: string]: Airport };
  flight: BookedFlightItineraryWithDepartureTime;
  actions?: ReactNode | ReactNode[];
  onChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    isOutbound: boolean
  ) => void;
  checked: string;
  cancelationInfo: FlightCancelInfoResponse;
}

export const SelectFlightInfo = ({
  airportMap,
  flight,
  checked,
  onChange,
  actions,
  cancelationInfo,
}: ISelectFlightInfoProps): JSX.Element => {
  const { bookedItinerary } = flight;
  const { policies } = cancelationInfo;
  const itinerary = getTravelItinerary(bookedItinerary.travelItinerary);

  return (
    <Box className="select-fligth-info-checkbox">
      <Typography className="title" variant="h2">
        {SELECT_CANCEL_FLIGHT}
      </Typography>
      {itinerary?.map(({ slices, locators }, index) => {
        const isOutbound = index === 0;
        const locator = policies?.find(policy => policy.agentLocator.unscopedValue === locators?.agent.unscopedValue);
        const {
          origin: { locationCode },
          scheduledArrival,
          scheduledDeparture,
          marketingAirline,
        } = getOriginSegment(slices[0]!.segments);
        const {
          destination: { locationCode: destLocationCode },
        } = getDestinationSegment(slices[0]!.segments);
        return (
          <div key={locationCode}>
            <Typography className="flight-info-checkbox-outbound" variant="h4">
              <span>
                <b>{isOutbound ? OUTBOUND : RETURN}</b>{" "}
                {getItineraryDetailsHeader(flight, isOutbound, airportMap)}
              </span>
            </Typography>
            <FlightInfoCheckbox
              variant="radio"
              checked={checked === locator?.agentLocator.unscopedValue}
              onChange={(e) => onChange(e, isOutbound)}
              origin={getSliceDestination(locationCode, airportMap)}
              checkboxId={locator?.agentLocator.unscopedValue}
              destination={getSliceDestination(destLocationCode, airportMap)}
              departureDateTime={formatter(scheduledDeparture)}
              arrivalDateTime={formatter(scheduledArrival)}
              airlineCode={marketingAirline.code}
              flightNumber={`${marketingAirline.code} ${marketingAirline.flightNumber}`}
              className={clsx({
                "outbound-slice-card": isOutbound,
                "return-slice-card": !isOutbound,
              })}
            />
          </div>
        );
      })}
      <Box className="actions">{actions}</Box>
    </Box>
  );
};
