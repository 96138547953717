import axios from "axios";
import { IEditTravelerRequestSubmit } from "redmond";
import { config } from "../../config";
import { requestTravelerEditsPath } from "../paths";

export default (body: IEditTravelerRequestSubmit): Promise<void> =>
  axios
    .post(requestTravelerEditsPath, body, {
      baseURL: config.baseURL,
    })
    .then((res) => res.data);
