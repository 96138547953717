import { IVirtualInterliningVariant } from "./component";
import * as textConstants from "./textConstants";

export const getPropsForVariant = (variant: IVirtualInterliningVariant) => {
  const titles = {
    selfCheck: textConstants.SELF_CHECK,
    missedConnectionProtection: textConstants.MISSED_CONNECTION_PROTECTION,
    missedConnectionGuarantee: textConstants.MISSED_CONNECTION_REBOOKING,
  };
  return titles[variant];
};
